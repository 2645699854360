import React, { useRef, useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { restaurantService } from '../../services'
import { generalConstants } from '../../constants'
import { categoryActions } from '../../actions'

import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import TopNavbar from './main/TopNavBar'
import InitialText from './main/InitialText'
import StepperMain from './main/StepperMain'
import CompleteText from './main/CompleteText'
import ErrorText from './main/ErrorText'

import Loading from '../common/loading'

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    position: 'relative',
    padding: 0,
    backgroundColor: '#fff',
  },
  container: {
    ...flexColumn,
    width: '100%',
    padding: 0,
  },
  subContainer: {
    ...flexColumn,
    paddingTop: theme.spacing(12),
    width: '100%',
    height: 500,
    padding: 0,
  },
  header: {
    fontWeight: 400,
    marginTop: theme.spacing(25),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
}))

const path = process.env.PUBLIC_URL

const BusinessRegister = () => {
  const classes = useStyles()
  const topRef = useRef()
  let match = useRouteMatch(`${path}/business-register/:token?`)
  const { formatMessage: f } = useIntl()

  const dispatch = useDispatch()
  const getCategories = () => dispatch(categoryActions.getAll())

  const [completed, setCompleted] = useState('')
  const [tokenVerified, setTokenVerified] = useState(null)
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState(null)

  useEffect(() => {
    getCategories()

    let token = match?.params.token
    if (token) {
      setToken(token)
      restaurantService
        .verifyRegisterToken(token)
        .then((res) => {
          if (res.status === generalConstants.STATUS_OK) {
            setTokenVerified('approved')
          } else {
            setTokenVerified('declined')
          }
        })
        .catch((err) => {
          console.log('err', err)
          setTokenVerified('declined')
        })
      setLoading(false)
    } else {
      setTokenVerified('not-exists')
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleScroll = () => topRef.current.scrollIntoView({ behavior: 'smooth' })

  const startOver = () => setCompleted('')

  if (!tokenVerified) return <div />

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container maxWidth='lg' className={classes.container}>
        <Loading open={loading} />
        <TopNavbar />
        {tokenVerified === 'approved' ? (
          <div className={classes.subContainer}>
            {completed === 'completed' ? (
              <CompleteText />
            ) : completed === 'error' ? (
              <ErrorText startOver={startOver} />
            ) : (
              <>
                <InitialText ref={topRef} />
                <StepperMain handleScroll={handleScroll} setCompleted={setCompleted} token={token} />
              </>
            )}
          </div>
        ) : (
          <Typography variant='h4' align='center' className={classes.header}>
            {f({ id: 'token-not-valid' })}
          </Typography>
        )}
      </Container>
    </div>
  )
}

export default BusinessRegister
