import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import MobileOrderDetail from './MobileOrderDetail'
import MobileNoOrder from './tab-screen/MobileNoOrder'
import TabPanel from './TabPanel'
import useStyles from '../styles'
import clsx from 'clsx'
import { isEmpty } from 'lodash'

export const TabScreen = ({
  mobileTabValue,
  orders,
  i,
  handleOrderRating,
  handleOrderReceipt,
  openCancelDialog,
  storeDetails,
}) => {
  const classes = useStyles()

  //Global states
  const orderMenu = useSelector(({ orders }) => orders.cart.orderMenus, shallowEqual)

  return (
    <TabPanel value={mobileTabValue} index={i}>
      {!isEmpty(orders) ? (
        <div
          className={clsx(
            'vertical-scrolling-wrapper-flexbox',
            !isEmpty(orderMenu) ? classes.withCart : classes.withoutCart
          )}
        >
          <MobileOrderDetail
            index={i}
            orders={orders}
            rateOrder={handleOrderRating}
            handleOrderReceipt={handleOrderReceipt}
            openCancelDialog={openCancelDialog}
            storeDetails={storeDetails}
          />
        </div>
      ) : (
        <MobileNoOrder index={i} />
      )}
    </TabPanel>
  )
}

TabScreen.propTypes = {
  mobileTabValue: PropTypes.number,
  orders: PropTypes.array,
  i: PropTypes.number,
  handleOrderRating: PropTypes.func,
  handleOrderReceipt: PropTypes.func,
}

export default TabScreen
