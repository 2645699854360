import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Store from './store'
import MobileStore from './MobileStore'
import { isMobileOnly } from 'react-device-detect'

import StoreAddressSelection from './StoreAddressSelection'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
  },
})

export default function StoreMain() {
  const address = useSelector(({ search }) => search.searchQuery.address)

  const [openAddressDialog, setOpenAddressDialog] = useState(false)

  useEffect(() => {
    let active = true
    if (active) {
      !address && setOpenAddressDialog(true)
    }
    return () => {
      active = false
    }
  }, [address])

  const toggleAddressDialog = () => (prev) => !prev

  const classes = useStyles()
  return (
    <div className={classes.root}>
      {isMobileOnly ? (
        <div className='mobile full-width'>
          <MobileStore />
        </div>
      ) : (
        <div className='desktop' style={{ width: '100%' }}>
          <Store />
        </div>
      )}
      <StoreAddressSelection open={openAddressDialog} onClose={toggleAddressDialog} />
    </div>
  )
}
