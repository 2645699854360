import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'

import tabletLogo from '../../../../images/Tablet/CartLogo.png'
import { deliveryTypes } from '../../../../constants'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingBottom: 5,
    // boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    borderBottom: '1px solid #c4c4c4',
  },
  text: {
    fontSize: 26,
    fontWeight: 'bold',
    color: '#707070',
    textAlign: 'left',
    padding: 0,
  },
}))

const HeaderTablet = () => {
  const deliveryType = useSelector(({ search }) => search.searchQuery?.deliveryType)
  const classes = useStyles()

  const currentHeader = deliveryType === deliveryTypes.DELIVERY ? 'edit-details' : 'edit-pickup-details'

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        <FormattedMessage id={currentHeader} />
      </Typography>
      <img src={tabletLogo} alt='TookFresh' width='127' height='40.5' />
    </div>
  )
}

export default React.memo(HeaderTablet)
