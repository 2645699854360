import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import nothingThere from '../../../images/nothing-there.svg'

const commonFontStyles = {
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: 'normal',
  color: '#333333',
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
    height: 350,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  title: {
    ...commonFontStyles,
    fontSize: 34,
    fontWeight: 'bold',
    lineHeight: 1.18,
    textAlign: 'center',
    width: '80%',
  },
  text: {
    ...commonFontStyles,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1.38,
  },
}))

const MerchantNoResult = ({ text }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <img src={nothingThere} height='200' width='250' alt='Cart' />
      </div>
      <span className={classes.title}>
        <FormattedMessage id='nothing-there' />
      </span>
      <div className='grow' />
      <span className={classes.text}>
        <FormattedMessage id={text} />
      </span>
    </div>
  )
}

MerchantNoResult.propTypes = {
  text: PropTypes.string,
}

export default React.memo(MerchantNoResult)
