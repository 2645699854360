import { generalConstants } from '../constants/general.constants'

const INITIAL_STATE = {
  mainMenu: 'food',
}

export function general(state = INITIAL_STATE, action) {
  switch (action.type) {
    case generalConstants.MAIN_MENU:
      return {
        ...state,
        mainMenu: action.menu,
      }
    default:
      return state
  }
}
