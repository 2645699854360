import { sortBy } from '../../constants'

const obj = {
  store_nickname_s: {
    value: sortBy.NAME,
    order: sortBy.ASC,
    combined: sortBy.NAME_ASC,
  },
  rating: {
    value: sortBy.TOP_RATED,
    order: sortBy.DESC,
    combined: sortBy.RATE_DESC,
  },
  high_low: {
    value: sortBy.COST_HIGH_TO_LOW,
    order: sortBy.DESC,
    combined: sortBy.PRICE_DESC,
  },
  low_high: {
    value: sortBy.COST_LOW_TO_HIGH,
    order: sortBy.ASC,
    combined: sortBy.PRICE_ASC,
  },
}

const parameters = (param) => obj[param]

export { parameters }
