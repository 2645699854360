import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'

const CancelOrder = ({ id, onClick, order }) => {
  return (
    <Button fullWidth onClick={() => onClick(order)}>
      <FormattedMessage id={id} />
    </Button>
  )
}

export default React.memo(CancelOrder)
