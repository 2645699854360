import React from 'react'
import IntersectionVisible from 'react-intersection-visible'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import StoreItem from '../../search/store_item'
import Footer from '../../Footers'

import backIcon from '../../../../images/black-back.svg'

const height = window.innerHeight

const useStyles = makeStyles((theme) => ({
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  mobileContent: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    justifyContent: 'center',
    paddingTop: theme.spacing(),
    overflow: 'scroll',
    flex: '1 50 auto',
  },
  footer: {
    position: 'relative',
    zIndex: 20,
  },
  gridContainer: {
    paddingBottom: theme.spacing(2),
  },
  grid: {
    marginBottom: theme.spacing(),
  },
}))

const MobileCampaigns = ({
  history,
  currentCampaign,
  stores,
  getStores,
  favoriteHandler,
  favoriteStores,
  pricey,
  openStores,
}) => {
  const classes = useStyles()
  return (
    <div className={clsx('mobile', classes.mobileRoot)}>
      <div className='mobile-top-bar'>
        <IconButton size='small' className={classes.back} onClick={() => history.goBack()}>
          <img src={backIcon} alt='Back' />
        </IconButton>
        <Typography variant='h6'>{currentCampaign?.name}</Typography>
      </div>
      {/* <div className={clsx('vertical-scrolling-wrapper-flexbox', [classes.mobileContent])}> */}
      <div className={classes.mobileContent}>
        <Grid container className={classes.gridContainer}>
          {stores.map(
            (store, index) =>
              store !== [] && (
                <Grid item lg={4} md={6} sm={6} xs={12} key={index} className={classes.grid}>
                  <IntersectionVisible onShow={() => index === stores.length - 4 && getStores()}>
                    <StoreItem
                      data={store}
                      history={history}
                      onPress={favoriteHandler}
                      favorites={favoriteStores}
                      price={pricey}
                      storeOpen={openStores.includes(store.id)}
                    />
                  </IntersectionVisible>
                </Grid>
              )
          )}
        </Grid>
      </div>
      <div className={clsx('sticky-bottom', classes.footer)}>
        <Footer />
      </div>
    </div>
  )
}

MobileCampaigns.propTypes = {
  history: PropTypes.object,
  stores: PropTypes.array,
  currentCampaign: PropTypes.object,
  getStores: PropTypes.func,
  favoriteHandler: PropTypes.func,
  favoriteStores: PropTypes.array,
  pricey: PropTypes.number,
}

export default MobileCampaigns
