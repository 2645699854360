import React from 'react'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import AddCard from './addCard'

export default function InjectedAddCart({ handleClose }) {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => <AddCard elements={elements} stripe={stripe} handleClose={handleClose} />}
    </ElementsConsumer>
  )
}
