import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import useStyles from '../../styles'

const RowContainer = ({ children }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} className={classes.rowContainer}>
      {children}
    </Grid>
  )
}

RowContainer.propTypes = {
  children: PropTypes.node,
}

export default RowContainer
