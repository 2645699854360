import { merchantConstants } from '../constants/merchant.constants'
import { merchantService } from '../services/merchant.service'
import { alertActions } from './alert.actions'
import { generalConstants } from '../constants'

export const merchantActions = {
  signin,
  signout,
}

function signin(username, password) {
  return (dispatch) => {
    dispatch(request({ username }))

    merchantService.signin(username, password).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          localStorage.setItem(merchantConstants.MERCHANT, JSON.stringify(res.data))
          dispatch(success(res.data))
        }
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.response.data.error_message.toString()))
      }
    )
  }

  function request(merchant) {
    return { type: merchantConstants.SIGNIN_REQUEST, merchant }
  }
  function success(merchant) {
    return { type: merchantConstants.SIGNIN_SUCCESS, merchant }
  }
  function failure(error) {
    return { type: merchantConstants.SIGNIN_FAILURE, error }
  }
}

function signout() {
  merchantService.signout()
  return { type: merchantConstants.SIGNOUT }
}
