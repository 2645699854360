import { Chip, withStyles } from '@material-ui/core'

export const StyleChip = withStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(),
    fontSize: 14,
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
  colorPrimary: {
    backgroundColor: '#f7f7f7',
  },
  colorSecondary: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
}))(Chip)

export default StyleChip
