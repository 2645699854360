import React, { Fragment } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import Footer from '../Footers'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { setMainMenu } from '../../../actions/general.actions'
import userAvatarIcon from '../../../images/user_avatar.png'

import { bottomNavItems } from '../../../constants'
import { customerActions, orderActions, searchActions } from '../../../actions'

import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { accountMenuItems } from '../../../constants/account.constants'
import { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import MobileDialog from '../../common/mobile-dialog'
import { Transition } from '../../utils/tools'

const path = process.env.PUBLIC_URL
// const height = window.innerHeight

const useStyles = makeStyles((theme) => ({
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    overflow: 'scroll',
    flex: '1 50 auto',
    marginTop: theme.spacing(),
  },
  profileRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: '100%',
    width: '100%',
  },
  profileImage: {
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: '#f7f7f7',
  },
  buttons: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  button: {
    height: 55,
    textTransform: 'capitalize',
  },
  name: {
    marginLeft: 30,
    fontSize: 18,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
    textTransform: 'capitalize',
  },
  generalDialogRoot: {
    width: 315,
    height: 463,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '15px 10px 15px 10px',
  },
}))

const MenuButton = withStyles(() => ({
  root: {
    justifyContent: 'left',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    color: '#000',
    height: 55,
    textTransform: 'capitalize',
  },
  startIcon: {
    marginRight: 30,
  },
}))(Button)

const Account = ({ intl }) => {
  const classes = useStyles()
  const history = useHistory()

  const [showModal, setShowModal] = useState(false)

  const customer = useSelector(({ customer }) => customer.customerInfo, shallowEqual)
  const orderMenus = useSelector(({ orders }) => orders.cart.orderMenus, shallowEqual)

  const dispatch = useDispatch()
  const setMenu = (menu) => dispatch(setMainMenu(menu))
  const signout = async () => dispatch(customerActions.signout())
  const clearCart = () => dispatch(orderActions.clearCart())
  const resetSearchQuery = () => dispatch(searchActions.resetSearchQueryWhenSignout())

  useEffect(() => {
    !isMobile ? goToProfile() : setMenu(bottomNavItems.ACCOUNT)
  }, [])

  const goToProfile = () => history.push(`${path}/customer/account/profile`)

  const goToMobileProfile = () => history.push(`${path}/customer/account/profile`)

  const handleSignOut = async () => {
    clearCart()
    resetSearchQuery()
    await signout()
    history.push(`${path}/`)
  }

  const handleRedirect = (menuPath) => {
    if (!menuPath) {
      handleShowDialog()
    } else {
      const subPath = menuPath !== 'about-us' ? 'account/' : ''
      history.push(`${path}/customer/${subPath}${menuPath}`)
    }
  }

  const handleConfirmClose = () => setShowModal(false)

  const handleShowDialog = () => setShowModal(true)

  return (
    <div className={classes.mobileRoot}>
      <Divider light />
      <div className={classes.top}>
        <Button fullWidth onClick={goToMobileProfile}>
          <div className={clsx('centered-row', [classes.profileRow])}>
            <div className={classes.profileImage}>
              <img src={userAvatarIcon} height='70' width='70' alt='avatar' />
            </div>
            <Typography className={classes.name}>
              {customer.firstName} {customer.lastName}
            </Typography>
            <div className='grow' />
            <KeyboardArrowRightIcon />
          </div>
        </Button>
      </div>
      <Divider light />
      <div
        className={clsx('vertical-scrolling-wrapper-flexbox', [classes.buttons])}
        style={{ paddingBottom: orderMenus.length > 0 ? 120 : 60 }}
      >
        {accountMenuItems.map((menuItem, i) => (
          <Fragment key={i}>
            <MenuButton fullWidth startIcon={menuItem.icon} onClick={() => handleRedirect(menuItem.onClickPath)}>
              {<FormattedMessage id={`${menuItem.label}`} />}
            </MenuButton>
            {menuItem.bottomDivider ? <Divider light /> : null}
          </Fragment>
        ))}
      </div>
      <Dialog
        classes={{ paper: classes.generalDialogRoot }}
        open={showModal}
        onClose={handleConfirmClose}
        TransitionComponent={Transition}
      >
        <MobileDialog
          close={handleConfirmClose}
          closeIcon={false}
          header={'are-you-sure'}
          mainText={'about-to-signout'}
          // subText={this.state.address.formattedAddress}
          leftBtnAction={handleConfirmClose}
          rightBtnAction={handleSignOut}
          // id={address.id}
          sizeW={'200px'}
          sizeH={'200px'}
          img={'deleteAddressImage'}
          greenButton={false}
          leftButton={'cancel-label'}
          rightButton={'sign-out'}
        />
      </Dialog>
      <div className='sticky-bottom'>
        <Footer />
      </div>
    </div>
  )
}

export default React.memo(injectIntl(Account))
