import React from 'react'
import { Button, Grid, withStyles } from '@material-ui/core'
import { socialMedia } from '../../../../constants/footer/web-footer'

export const SocialButton = withStyles((theme) => ({
  root: {
    color: 'white',
    border: '1px solid white',
    minWidth: 0,
    padding: 6,
    borderRadius: 20,
    marginRight: theme.spacing(),
    boxShadow: 'none',
    '&:disabled': {
      color: 'gray',
    },
    '&:hover': {
      color: 'white',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
}))(Button)

const SocialMediaLinks = (props) => {
  return (
    <Grid item xs={12} md={3} className='centered-row' style={{ paddingTop: 40 }}>
      {socialMedia.map((item, i) => (
        <SocialButton key={i}>
          <img src={item.icon} alt={item.alt} height='25' width='25' />
        </SocialButton>
      ))}
    </Grid>
  )
}

export default SocialMediaLinks
