let newState

const addToCheckedState = (attr, state, value) => {
  const contains = stateContainsNewValue(state, attr)
  return contains ? handleExistingAttr(attr, state, value) : handleNewAttr(attr, state, value)
}

const handleNewAttr = (attr, state, value) => {
  newState = state
  newState[attr.id] = [value.id]
  return newState
}

const handleExistingAttr = (attr, state, value) => {
  newState = state
  const maxCount = attr.maximumCount
  let relatedObjValue = newState[attr.id]
  const length = relatedObjValue.length
  if (relatedObjValue.includes(value.id)) {
    const newArray = relatedObjValue.filter((item) => item !== value.id)
    newState[attr.id] = newArray
  } else {
    if (length < maxCount) {
      relatedObjValue.push(value.id)
    }
  }
  return newState
}

const stateContainsNewValue = (state, attr) => {
  let bool = Object.keys(state).includes(attr.id.toString())
  return bool
}

export { addToCheckedState }
