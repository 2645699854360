import React from 'react'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import CustomizedWebAddCard from './customized_web_add_card'

export default function InjectedCustomizedWebAddCart({ handleClose, handleDoneDialog }) {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CustomizedWebAddCard
          elements={elements}
          stripe={stripe}
          handleClose={handleClose}
          handleDoneDialog={handleDoneDialog}
        />
      )}
    </ElementsConsumer>
  )
}
