import { Typography } from '@material-ui/core'
import React from 'react'
import noOrder from '../../../../../../images/no-order.svg'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  noOrder: {
    height: '100%',
    width: '100%',
  },
}))

function MobileNoOrder({ index }) {
  const classes = useStyles()

  return (
    <div className={clsx('justify-content', [classes.noOrder])}>
      <div className='centered-column'>
        <img src={noOrder} alt='' width='210' height='205' />
        <Typography variant='h2' gutterBottom>
          <FormattedMessage id='orders-label' />
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          <FormattedMessage id={index === 0 ? 'no-previous-orders' : 'no-upcoming-orders'} />
        </Typography>
      </div>
    </div>
  )
}

export default MobileNoOrder
