import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { parseGoogleAPIAddress } from '../../../../utils/common'

const SelectAddress = ({ setParsedAddress, parsedAddress, error, otherAddress, component }) => {
  const { formatMessage: f } = useIntl()
  const autoCompleteRef = useRef(null)
  let autocomplete

  const onChange = (newAddress) => {
    let parsedAddress = parseGoogleAPIAddress(newAddress)
    setParsedAddress(parsedAddress)
  }

  const handlePlaceSelect = async (onChange) => {
    const addressObject = autocomplete.getPlace()
    if (addressObject) {
      onChange(addressObject)
    } else {
      window.alert('No details available for input')
      return
    }
  }

  useEffect(() => {
    let active = true
    if (active) {
      if (!autocomplete && window.google) {
        const options = {
          componentRestrictions: { country: 'us' },
          fields: ['address_components', 'formatted_address', 'geometry.location', 'utc_offset_minutes'],
          types: ['address'],
        }
        autocomplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, options)
        autocomplete.addListener('place_changed', () => handlePlaceSelect(onChange))
      }
      if (!autocomplete) {
        return undefined
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFocus = (event) => {
    if (event.target.autocomplete) event.target.autocomplete = 'none'
  }

  let currentValue = parsedAddress ? parsedAddress.formattedAddress : ''

  const requiredOptions = []

  return (
    <Autocomplete
      id='google-map-demo'
      onFocus={onFocus}
      options={requiredOptions}
      autoComplete
      value={currentValue}
      fullWidth
      open={false}
      disabled={!otherAddress && component === 'Check Fields' ? true : false}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder=''
          label={otherAddress ? f({ id: 'other-address' }) : f({ id: 'store-address' })}
          fullWidth
          variant='outlined'
          size='small'
          InputLabelProps={{ shrink: true }}
          // inputProps={{
          //   autoComplete: 'off',
          // }}
          required
          inputRef={autoCompleteRef}
          error={error && currentValue === ''}
        />
      )}
    />
  )
}

SelectAddress.propTypes = {
  parsedAddress: PropTypes.object,
  setParsedAddress: PropTypes.func,
  error: PropTypes.bool,
  otherAddress: PropTypes.bool,
  component: PropTypes.string,
}

export default SelectAddress
