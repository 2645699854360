import React from 'react'
import PropTypes from 'prop-types'
import StoreCardSmall from '../../common/StoreCardSmall'

const path = process.env.PUBLIC_URL

const MobileStoreItem = ({ data, category, onPress, favorites, storeOpen }) => {
  const isFavorite = favorites.some((favorite) => favorite.entityId === data.id)

  const location = {
    pathname: `${path}/customer/store/${data.id}`,
    state: { pathname: `${path}/customer/search`, category: category },
  }

  return (
    <StoreCardSmall
      data={data}
      isFavorite={isFavorite}
      onPress={onPress}
      direction={location}
      big
      show
      storeOpen={storeOpen}
    />
  )
}

MobileStoreItem.propTypes = {
  data: PropTypes.object.isRequired,
  storeOpen: PropTypes.bool,
}

export default MobileStoreItem
