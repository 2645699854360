const handleButtonText = (text, index, restaurant) => {
  if (text === 'upload') {
    if (index === 0) {
      if (restaurant.logo) {
        return 'change-logo'
      }
      return 'choose-logo'
    } else {
      if (restaurant.background) {
        return 'change-image'
      }
      return 'choose-image'
    }
  } else {
    if (index === 0) {
      return 'delete-logo'
    } else {
      return 'delete-image'
    }
  }
}

export default handleButtonText
