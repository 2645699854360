import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Employees from './Employees'
import AddEmployee from './AddEmployee'
import EditEmployee from './EditEmployee'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
// import MerchantNoMatch from '../common/MerchantNoMatch'
import AuthRequired from '../common/AuthRequired'
import queryString from 'query-string'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const EmployeesMain = ({ match, location }) => {
  const classes = useStyles()
  const subProp = useMemo(() => match.params.sub, [match.params.sub])
  const values = useMemo(() => queryString.parse(location.search), [location])
  //Global states
  const isRestaurant = useSelector(({ merchantStores }) => merchantStores.isRestaurantView)
  const merchant = useSelector(({ merchant }) => merchant.merchantInfo)
  const employees = useSelector(({ merchantStores }) => merchantStores.selectedStoreEmployees)
  const loading = useSelector(({ merchantStores }) => merchantStores.loading)
  const {
    values: { isRestaurantAdmin, isStoreAdmin },
  } = useMerchantHomeContext()

  const isEmployeeEditable = employees?.length > 0 && merchant.id === +values.employeeId
  const canAdminEdit = (isRestaurantAdmin && !isRestaurant) || isStoreAdmin

  const getContent = () => {
    switch (subProp) {
      case 'add':
        if (canAdminEdit) {
          return renderAddScreen()
        } else {
          return renderAuthRequired()
        }
      case 'edit':
        return renderEditEmployee()
      default:
        if (canAdminEdit) {
          return renderEmployeesScreen()
        } else {
          return renderAuthRequired()
        }
    }
  }

  const renderAddScreen = () => <AddEmployee />
  const renderEmployeesScreen = () => <Employees />
  const renderAuthRequired = () => <AuthRequired />
  const renderEditEmployee = () => {
    // prevent user to enter link with unauthorized store id
    if (!isEmployeeEditable) {
      return renderAuthRequired()
    }
    return <EditEmployee />
  }

  if (!canAdminEdit) return renderAuthRequired()

  return (
    <div>
      {getContent()}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

EmployeesMain.propTypes = {
  match: PropTypes.object,
}

export default withRouter(EmployeesMain)
