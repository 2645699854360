import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import NameField from './PaymentDetails/NameField'
import PhoneField from './PaymentDetails/PhoneField'
import EmailField from './PaymentDetails/EmailField'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentInfoHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    lineHeight: '1.4em',
  },
  textfields: {
    marginTop: theme.spacing(2),
    "&[data-content='name']": {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
  },
  phoneFieldFormControl: {
    marginBottom: theme.spacing(2),
  },
}))

const PaymentDetails = ({ handleChange, values, error, setValues }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <Container maxWidth='md' className={classes.root}>
      <Grid container spacing={2} className='full-width'>
        <Grid item md={12} sm={12} xs={12} className={classes.contentInfoHeader}>
          <Typography variant='h5' className={classes.header}>
            {f({ id: 'payment-contact-info-label' })}
          </Typography>
        </Grid>
        <PhoneField values={values} handleChange={handleChange} classes={classes} setValues={setValues} error={error} />
        <EmailField values={values} handleChange={handleChange} classes={classes} setValues={setValues} error={error} />
        <NameField values={values} handleChange={handleChange} classes={classes} setValues={setValues} error={error} />
      </Grid>
    </Container>
  )
}

PaymentDetails.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  error: PropTypes.bool,
  setValues: PropTypes.func,
}

export default PaymentDetails
