import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'

import { GreenRadio } from '../../../../utils/tools'

import { deliveryRole, valueConstants } from '../../../../businessRegister/businessConstants'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(3),
  },
}))

const CheckBoxArea = ({ store, setStore, editting }) => {
  const classes = useStyles()

  const deliveryByHandler = (e) => setStore({ ...store, deliveryBy: e.target.value })

  return (
    <FormControl fullWidth className={classes.formControl}>
      <FormGroup aria-label='position' row>
        {deliveryRole.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <GreenRadio
                disabled={!editting}
                checked={store.deliveryBy === item}
                onChange={deliveryByHandler}
                value={item}
              />
            }
            label={<Typography>{item === valueConstants.tookfresh ? 'TookFresh' : 'Business'}</Typography>}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

CheckBoxArea.propTypes = {
  store: PropTypes.object,
  setStore: PropTypes.func,
  editting: PropTypes.bool,
}

export default CheckBoxArea
