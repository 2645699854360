import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { BottomNavigation, BottomNavigationAction, makeStyles, withStyles } from '@material-ui/core'
import { tabBarMenu } from '../../../../constants/footer/mobile-footer'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  navbarIcon: {
    height: 32,
    width: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    margin: 'auto',
    left: 0,
    bottom: 0,
    right: 0,
  },
}))

const StyledBottomNavigationAction = withStyles((theme) => ({
  root: {
    borderTop: '1px solid #f7f7f7',
    color: '#292929',
    fontWeight: 300,
    minWidth: 30,
    fontSize: '0.7rem',
    '& $selected': {
      color: theme.palette.primary.main,
      fontWeight: 300,
      fontSize: '0.7rem',
    },
  },
  selected: {},
}))(BottomNavigationAction)

const BottomTabBar = ({ intl, openSignin, history }) => {
  const classes = useStyles()
  //Global states
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const mainMenu = useSelector(({ general }) => general.mainMenu)

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const tabClickHandler = (route, required) => {
    required ? signinCheck(route) : history.push(`${path}/customer/${route}`)
  }

  const signinCheck = (route) => {
    signedIn ? history.push(`${path}/customer/${route}`) : openSignin(route)
  }

  return (
    <BottomNavigation value={mainMenu} showLabels={true}>
      {tabBarMenu.map((item, i) => (
        <StyledBottomNavigationAction
          key={i}
          value={item.value}
          label={getLabel(item.label)}
          icon={
            <div className={classes.navbarIcon}>
              {mainMenu === item.value ? (
                <img src={item.iconActive} width='32' alt={item.path} />
              ) : (
                <img src={item.icon} alt={item.path} width='32' />
              )}
            </div>
          }
          onClick={() => tabClickHandler(item.path, item.isSigninRequired)}
        />
      ))}
    </BottomNavigation>
  )
}

BottomTabBar.propTypes = {
  intl: PropTypes.object,
  openSignin: PropTypes.func,
  history: PropTypes.object,
}

export default withRouter(injectIntl(BottomTabBar))
