import { days, deliveryTypes, initialTierInformation, initialAddressQuery, initialServiceHours } from '../../constants'
import { valueConstants } from '../../components/businessRegister/businessConstants'

const storeStateMaker = (store) => {
  let newState = {
    nickname: store.nickname,
    storeNumber: store.storeNumber,
    acceptSameDayOrder: store.acceptSameDayOrder,
    minOrderPrice: store.minOrderPrice,
    maxDeliveryDistance: store.maxDeliveryDistance,
    // deliveryFee: store.deliveryFee,
    pricey: store.pricey,
    deliveryTime: store.deliveryTime,
    active: store.active,
    deliveryBy: store.deliveryBy
      ? store.deliveryBy
      : store.tierInformations?.length > 0
      ? valueConstants.ownBusiness
      : valueConstants.tookfresh,
    notificationType: store.notificationType ? store.notificationType : [],
    orderConfirmationPhone: store.orderConfirmationPhone ? store.orderConfirmationPhone : '',
    orderConfirmationEmail: store.orderConfirmationEmail ? store.orderConfirmationEmail : '',
    minHeadCount: store.minHeadCount,
    categoryId: store.categories?.length > 0 ? store.categories[0] : '',
  }
  return newState
}

const handleTierInfo = (arr) => {
  let newObj = { ...initialTierInformation }
  arr?.length > 0 &&
    arr.sort((a, b) => {
      if (a.deliveryFee && b.deliveryFee) {
        return a.deliveryFee > b.deliveryFee ? 1 : -1
      } else {
        return a.deliveryFee !== undefined ? 1 : -1
      }
    })
  if (arr?.length > 0) {
    for (let i = 0; i < arr?.length; i++) {
      let obj = arr[i]
      obj.isActive = true
      newObj[i + 1] = obj
    }
  }
  return newObj
}

const handleBankData = (store) => {
  let { bankInformation: bI } = store
  let bankData = bI
  bankData.address = bI.checkAddress || initialAddressQuery
  bankData.businessName = store.legalBusinessName
  bankData.paymentMethod = bI.paymentMethod
    ? bI.paymentMethod
    : !bI.checkAddress
    ? valueConstants.ownBusiness
    : valueConstants.tookfresh
  delete bankData.checkAddress
  return bankData
}

const convertTiersToObject = (tierInfo) => {
  let tiers = initialTierInformation
  for (let i = 0; i < tierInfo?.length; i++) {
    tiers[i + 1] = tierInfo[i]
    tiers[i + 1].isActive = true
  }
  return tiers
}

export const checkTierValues = (tier) => {
  if (tier?.deliveryFee > 0 && tier?.minOrder > 0 && tier?.maxDistance > 0) {
    return true
  }
  return false
}

export const confirmTierValues = (tiers, prop) => {
  let former = tiers[prop - 1]
  let latter = tiers[prop]
  if (
    former?.deliveryFee <= latter?.deliveryFee &&
    former?.minOrder < latter?.minOrder &&
    former?.maxDistance < latter?.maxDistance
  ) {
    return true
  } else return false
}

const isPaymentContactInfoMissing = (bankInfo) => {
  let { paymentContactEmail, paymentContactPhone, paymentContactName } = bankInfo
  if (!paymentContactEmail || !paymentContactPhone || !paymentContactName) {
    return true
  }
  return false
}

const clearServiceHours = () => {
  let delivery = initialServiceHours.DELIVERY
  let takeout = initialServiceHours.TAKEOUT
  let DELIVERY = Object.fromEntries(Object.entries(delivery).map(([k, v]) => [k, []]))
  let TAKEOUT = Object.fromEntries(Object.entries(takeout).map(([k, v]) => [k, []]))
  let newObj = { DELIVERY, TAKEOUT }
  return newObj
}

const serviceHoursMaker = (store) => {
  let initialHours = clearServiceHours()
  if (store.storeInformation.workingHours) {
    let newState = {
      DELIVERY: {
        MONDAY: store.storeInformation.workingHours?.filter(
          (wh) => wh.orderType === deliveryTypes.DELIVERY && wh.day === days.MONDAY
        ),
        TUESDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.DELIVERY && wh.day === days.TUESDAY
        ),
        WEDNESDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.DELIVERY && wh.day === days.WEDNESDAY
        ),
        THURSDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.DELIVERY && wh.day === days.THURSDAY
        ),
        FRIDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.DELIVERY && wh.day === days.FRIDAY
        ),
        SATURDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.DELIVERY && wh.day === days.SATURDAY
        ),
        SUNDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.DELIVERY && wh.day === days.SUNDAY
        ),
      },
      TAKEOUT: {
        MONDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.TAKEOUT && wh.day === days.MONDAY
        ),
        TUESDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.TAKEOUT && wh.day === days.TUESDAY
        ),
        WEDNESDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.TAKEOUT && wh.day === days.WEDNESDAY
        ),
        THURSDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.TAKEOUT && wh.day === days.THURSDAY
        ),
        FRIDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.TAKEOUT && wh.day === days.FRIDAY
        ),
        SATURDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.TAKEOUT && wh.day === days.SATURDAY
        ),
        SUNDAY: store.storeInformation.workingHours.filter(
          (wh) => wh.orderType === deliveryTypes.TAKEOUT && wh.day === days.SUNDAY
        ),
      },
    }
    return newState
  }
  return initialHours
}

export {
  storeStateMaker,
  serviceHoursMaker,
  convertTiersToObject,
  handleTierInfo,
  handleBankData,
  isPaymentContactInfoMissing,
}
