import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from './Header'
import Content from './Content'
import PlaceOrderButton from './PlaceOrderButton'

const useStyles = makeStyles((theme) => ({
  root: {
    "&[tablet-view='true']": {
      width: '100%',
    },
    "&[tablet-view='false']": {
      flex: '1 1 503px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-beween',
    height: 500,
    paddingLeft: 28,
    paddingRight: 52,
    "&[tablet-view='true']": {
      padding: 0,
      height: 'auto',
    },
  },
}))

const RightPart = ({
  handleAddItem,
  itemCount,
  cart,
  handleQuantity,
  deleteMenuItem,
  cardElement,
  selectedPaymentMethod,
  selectedCard,
  signedIn,
  handlePhoneCheck,
  errorMessage,
  tabletView,
  warnActive,
  paymentError,
  paymentMethodWarning,
  showWarnBoxContainer,
  loading,
  tip,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root} tablet-view={(!!tabletView).toString()}>
      {!tabletView && <Header handleAddItem={handleAddItem} itemCount={itemCount} tabletView={tabletView} />}
      <div className={classes.container} tablet-view={(!!tabletView).toString()}>
        <Content
          handleQuantity={handleQuantity}
          deleteMenuItem={deleteMenuItem}
          itemCount={itemCount}
          cart={cart}
          errorMessage={errorMessage}
          tabletView={tabletView}
          warnActive={warnActive}
          paymentError={paymentError}
          paymentMethodWarning={paymentMethodWarning}
          showWarnBoxContainer={showWarnBoxContainer}
          loading={loading}
          tip={tip}
        />
        {!tabletView && (
          <PlaceOrderButton
            cardElement={cardElement}
            selectedCard={selectedCard}
            selectedPaymentMethod={selectedPaymentMethod}
            signedIn={signedIn}
            handlePhoneCheck={handlePhoneCheck}
            cart={cart}
          />
        )}
      </div>
    </div>
  )
}

export default RightPart
