import React from 'react'
import PhoneInput from 'react-phone-input-2'
import PropTypes from 'prop-types'

import 'react-phone-input-2/lib/material.css'
import { phoneRegex } from '../../../../utils/regex_collections'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    '&.react-tel-input .special-label': {
      fontSize: '0.75em',
    },
    '&.react-tel-input .form-control:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
      "&[iserror='true']": {
        borderColor: theme.palette.secondary.main,
        boxShadow: `0px 0px 0px 1px ${theme.palette.secondary.main}`,
      },
    },
    '&.react-tel-input .form-control:hover': {
      "&[isdisabled='true']": {
        borderColor: '#CACACA',
      },
    },
    '&.react-tel-input .form-control': {
      transition: 'none',
      fontSize: 'inherit',
      width: '100%',
      padding: '9.32px 10.5px 9.32px 50px',
    },
  },
}))

const CustomizedPhoneInput = ({ valueName, values, item, setValues, label, disabled, error }) => {
  const classes = useStyles()
  return (
    <PhoneInput
      specialLabel={label}
      country='us'
      containerClass={classes.phoneInput}
      value={values[item.value]}
      onChange={(phone) => setValues({ ...values, [valueName]: phone })}
      disableDropdown
      countryCodeEditable={false}
      disabled={disabled}
      isValid={(value) => {
        if (value.length > 1) {
          if (phoneRegex.test(value.substring(1))) {
            return true
          } else {
            return false
          }
        } else if (error) {
          return false
        } else {
          return true
        }
      }}
      inputProps={{
        isdisabled: disabled ? 'true' : 'false',
        iserror: values[item.value]?.length > 1 && values[item.value]?.length < 11 ? 'true' : 'false',
        name: 'phone',
        required: true,
      }}
      onlyCountries={['us']}
    />
  )
}

CustomizedPhoneInput.propTypes = {
  valueName: PropTypes.string,
  values: PropTypes.object,
  item: PropTypes.object,
  setValues: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
}

export default CustomizedPhoneInput
