import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import Menus from './Menus'
import AddMenuSectionItem from './AddMenuSectionItem'
import EditMenuSectionItem from './EditMenuSectionItem'
import { useSelector } from 'react-redux'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import queryString from 'query-string'
import AuthRequired from '../common/AuthRequired'
import EditAttributes from './EditAttributes'
import { useFetchSections } from '../hooks/useFetchSections'
import { storeService } from '../../../services'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const MenusMain = ({ match, location }) => {
  const {
    values: { isRestaurantAdmin, isStoreAdmin },
  } = useMerchantHomeContext()
  const classes = useStyles()
  const subProp = useMemo(() => match.params.sub, [match.params.sub])
  const values = useMemo(() => queryString.parse(location.search), [location])
  const isRestaurant = useSelector(({ merchantStores }) => merchantStores.isRestaurantView)
  const selectedStoreId = useSelector(({ merchantStores }) => merchantStores.selectedStore.storeId)
  const refresh = useSelector(({ merchantStores }) => merchantStores.refreshNeeded)

  const { result: sections, loading: isLoading } = useFetchSections(
    selectedStoreId,
    storeService.getAllSections,
    refresh
  )

  const loading = useSelector(({ merchantStores }) => merchantStores.loading)
  const canAddMenu = sections?.length > 0 && sections.some((i) => i.id === +values.sectionid)
  const relatedSection = sections?.find((e) => e.id === +values.sectionid)
  const canAdminEdit = (isRestaurantAdmin && !isRestaurant) || isStoreAdmin
  const isMenuEditable = canAdminEdit && canAddMenu && relatedSection.menus.some((i) => i.id === +values.itemid)

  const getContent = () => {
    switch (subProp) {
      case 'add':
        return renderAddMenu()
      case 'edit':
        return renderEditMenu()
      case 'editattribute':
        return renderEditAttribute()
      default:
        return renderMenu()
    }
  }

  // const refreshFetchCallback = () => setRefreshNeeded((prev) => prev + 1)

  const renderAddMenu = () => {
    if (canAdminEdit && canAddMenu) {
      return <AddMenuSectionItem sections={sections} />
    }
    return <AuthRequired />
  }
  const renderEditMenu = () => {
    // prevent user to enter link with unauthorized section id
    if (!isMenuEditable) {
      return <AuthRequired />
    }
    return <EditMenuSectionItem sections={sections} />
  }
  const renderMenu = () => {
    if (canAdminEdit) {
      return <Menus sections={sections} loading={isLoading} />
    }
    return <AuthRequired />
  }
  const renderEditAttribute = () => {
    if (canAdminEdit) {
      return <EditAttributes />
    }
    return <AuthRequired />
  }

  return (
    <div>
      {getContent()}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

MenusMain.propTypes = {
  match: PropTypes.object,
}

export default withRouter(MenusMain)
