import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { valueConstants } from '../Helpers'

import RadioButtons from './BankInfo/RadioButtons'
import DirectDepositFields from './BankInfo/DirectDepositFields'
import CheckFields from './BankInfo/CheckFields'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  text: {
    color: '#707070',
    marginBottom: theme.spacing(),
  },
}))

const BankInfo = ({
  values,
  handleChange,
  setValues,
  error,
  setCheckParsedAddress,
  checkParsedAddress,
  setUseStoreAddressForCheck,
  useStoreAddressForCheck,
  parsedAddress,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  useEffect(() => {
    let active = true
    if (active) {
      if (values.paymentMethod === valueConstants.check) {
        if (
          values.taxIdNumber.length > 0 ||
          values.accountNumber.length > 0 ||
          values.routingNumber.length > 0 ||
          values.confirmAccountNumber.length > 0
        ) {
          setValues({
            ...values,
            taxIdNumber: '',
            accountNumber: '',
            routingNumber: '',
            confirmAccountNumber: '',
            checkSuiteNo: '',
          })
        }
        setUseStoreAddressForCheck(true)
        setCheckParsedAddress(parsedAddress)
      } else {
        setCheckParsedAddress(null)
        setValues({
          ...values,
          checkSuiteNo: '',
        })
      }
    }
    return () => {
      active = false
    }
  }, [values.paymentMethod])

  useEffect(() => {
    let active = true
    if (active) {
      if (useStoreAddressForCheck) {
        if (values.paymentMethod === valueConstants.directDeposit) {
          setCheckParsedAddress(null)
        } else {
          setCheckParsedAddress(parsedAddress)
        }
        setValues({
          ...values,
          checkSuiteNo: '',
        })
      } else {
        setCheckParsedAddress(null)
      }
    }
    return () => {
      active = false
    }
  }, [useStoreAddressForCheck])

  return (
    <Container maxWidth='md' className={classes.root}>
      <Typography className={classes.text}>{f({ id: 'select-payment-method' })} </Typography>
      <RadioButtons values={values} handleChange={handleChange} />
      {values.paymentMethod === valueConstants.directDeposit ? (
        <DirectDepositFields values={values} handleChange={handleChange} error={error} />
      ) : (
        <CheckFields
          setCheckParsedAddress={setCheckParsedAddress}
          checkParsedAddress={checkParsedAddress}
          setUseStoreAddressForCheck={setUseStoreAddressForCheck}
          useStoreAddressForCheck={useStoreAddressForCheck}
          error={error}
          values={values}
          handleChange={handleChange}
        />
      )}
    </Container>
  )
}

BankInfo.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.bool,
  setCheckParsedAddress: PropTypes.func,
  checkParsedAddress: PropTypes.object,
  setUseStoreAddressForCheck: PropTypes.func,
  useStoreAddressForCheck: PropTypes.bool,
  parsedAddress: PropTypes.object,
}

export default BankInfo
