import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { deliveryTypes } from '../../../../../constants'
import clsx from 'clsx'
import addInstructionIcon from '../../../../../images/add-instruction.svg'
import addInstructionIconSelected from '../../../../../images/add-instruction-selected.svg'

const DeliveryInstructions = ({
  typ,
  instructions,
  subRow,
  icon,
  field,
  addInstruction,
  selectedInstruction,
  handleInstructions,
  label,
  placeholder,
}) => (
  <Grid item xs={12} className={subRow}>
    <img
      className={icon}
      src={instructions ? addInstructionIconSelected : addInstructionIcon}
      alt='where'
      height='20'
      width='20'
    />
    <div className='column full-width'>
      <Typography
        className={clsx({
          [addInstruction]: true,
          [selectedInstruction]: typ === deliveryTypes.DELIVERY,
        })}
        gutterBottom
      >
        {label}
      </Typography>
      <TextField
        id='instructions'
        value={instructions}
        disabled={typ !== deliveryTypes.DELIVERY}
        onChange={handleInstructions}
        fullWidth
        InputProps={{
          disableUnderline: true,
          classes: { input: field },
        }}
        placeholder={placeholder}
        multiline
        maxRows={3}
      />
    </div>
  </Grid>
)

DeliveryInstructions.propTypes = {
  typ: PropTypes.string.isRequired,
  instructions: PropTypes.string,
  subRow: PropTypes.string,
  icon: PropTypes.string,
  field: PropTypes.string,
  addInstruction: PropTypes.string,
  selectedInstruction: PropTypes.string,
  handleInstructions: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
}

export default React.memo(DeliveryInstructions)
