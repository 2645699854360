import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import { labelConstants, valueConstants } from '../Helpers'

const useStyles = makeStyles((theme) => ({
  field: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  grid: {
    margin: theme.spacing(),
    "&[zero-margin='true']": {
      margin: 0,
      marginTop: theme.spacing(),
    },
  },
}))

const NumberField_ = ({ values, prop, required, error, handleChange, gridVal }) => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  return (
    <Grid
      item
      xs={gridVal}
      className={classes.grid}
      zero-margin={prop === valueConstants.checkSuiteNo ? 'true' : 'false'}
    >
      <TextField
        size='small'
        value={values[prop]}
        variant='outlined'
        fullWidth
        id={prop}
        InputLabelProps={{ shrink: true }}
        label={f({ id: labelConstants[prop] })}
        onChange={handleChange(prop)}
        required={required}
        error={required ? error && values[prop].length === 0 : false}
        className={classes.field}
        type={'number'}
        inputProps={{
          maxLength: 100,
        }}
      />
    </Grid>
  )
}

NumberField_.propTypes = {
  values: PropTypes.object,
  prop: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  handleChange: PropTypes.func,
  gridVal: PropTypes.number,
}

export default NumberField_
