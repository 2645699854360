import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Grid, makeStyles, Menu, MenuItem, TextField } from '@material-ui/core'
import InputMask from 'react-input-mask'
import CountryCodeData from '../../../../constants/country_phone_list.json'

const useStyles = makeStyles((theme) => ({
  countryCode: {
    marginRight: theme.spacing(),
    width: 100,
  },
}))

const Phone = ({
  countryCode,
  phone,
  intl,
  isFocused,
  isInvalid,
  phoneHandler,
  editting,
  setCode,
  prop,
  gridVal,
  disableArea,
}) => {
  const [anchorElCode, setAnchorElCode] = useState(null)
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const countryCodeMenu = () => (
    <Menu id='country-code-menu' anchorEl={anchorElCode} open={Boolean(anchorElCode)} onClose={countryCodeSelected}>
      {CountryCodeData.map((data) => (
        <MenuItem key={data.dial_code + data.code} value={data.dial_code} onClick={countryCodeSelected}>
          {data.dial_code + data.name}
        </MenuItem>
      ))}
    </Menu>
  )

  const countryCodeSelected = (evt) => {
    setAnchorElCode(null)
    evt.target.value && setCode(`+${evt.target.value}`)
  }

  return (
    <Grid item className='row' sm={gridVal} style={{ width: gridVal === 12 && '100%' }}>
      <TextField
        size='small'
        className={classes.countryCode}
        variant='outlined'
        fullWidth
        id='code'
        autoComplete='code'
        value={countryCode}
        onClick={(event) => {
          if (editting && !disableArea) setAnchorElCode(event.target)
        }}
        inputProps={{
          style: { cursor: 'pointer' },
          readOnly: !editting || disableArea,
        }}
      />
      {countryCodeMenu()}
      <InputMask
        mask='999 999 9999'
        maskChar=' '
        value={phone}
        onChange={phoneHandler}
        disabled={!editting || disableArea}
      >
        {() => (
          <TextField
            size='small'
            autoComplete='phone'
            variant='outlined'
            required
            fullWidth
            id='phone'
            InputLabelProps={{ shrink: true }}
            inputProps={{ readOnly: !editting }}
            label={getLabel('phone-number')}
            error={isFocused[prop] && isInvalid[prop]}
            helperText={isFocused[prop] && isInvalid[prop] ? getLabel('invalid-phone') : ' '}
          />
        )}
      </InputMask>
    </Grid>
  )
}

Phone.propTypes = {
  countryCode: PropTypes.string,
  intl: PropTypes.object,
  phone: PropTypes.string,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  phoneHandler: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
  editting: PropTypes.bool,
  prop: PropTypes.string,
  gridVal: PropTypes.number,
  disableArea: PropTypes.bool,
}

export default injectIntl(Phone)
