import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

const SecondFile = ({ classes, pdfFile, removePdf, layers, addLayer, deleteLayer, selectPdf }) => {
  const { formatMessage: f } = useIntl()

  return (
    <Grid item md={12} sm={12} xs={12} className={classes.gridItem} input-container='true'>
      <div className={classes.buttonsContainer}>
        <label htmlFor='file-upload2' className={classes.uploadButton}>
          {f({ id: 'select-label' })}
        </label>
        <input
          id='file-upload2'
          type='file'
          onChange={selectPdf('second')}
          accept='application/pdf'
          className={classes.input}
          style={{ display: 'none' }}
        />
        <div className={classes.uploadButton} data-content='delete' onClick={() => removePdf('second')}>
          {f({ id: 'remove-label' })}
        </div>
      </div>
      {pdfFile.second && <Typography className={classes.fileName}>{pdfFile.second.name}</Typography>}
      {!layers?.third && (
        <div className={classes.iconButtonsContainer}>
          <IconButton
            color='inherit'
            size='small'
            className={classes.addButton}
            aria-label='add'
            onClick={() => addLayer('third')}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            color='inherit'
            size='small'
            className={classes.addButton}
            aria-label='delete'
            onClick={() => deleteLayer('second')}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </Grid>
  )
}

SecondFile.propTypes = {
  classes: PropTypes.object,
  selectPdf: PropTypes.func,
  pdfFile: PropTypes.object,
  removePdf: PropTypes.func,
  layers: PropTypes.object,
  addLayer: PropTypes.func,
  deleteLayer: PropTypes.func,
}

export default SecondFile
