import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { withRouter } from 'react-router'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { RedCheckbox } from '../../utils/tools'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  cell: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  paper: {
    width: '100%',
    // margin: theme.spacing(2),
  },
  pointer: {
    cursor: 'pointer',
  },
}))

const EmployeesTable = ({ headerData, bodyData, intl, aria, history, handleSelect, selected }) => {
  const classes = useStyles()

  //Global states
  const merchant = useSelector(({ merchant }) => merchant.merchantInfo)

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleClick = (elem) => {
    history.push(`${path}/merchant/employees/edit?employeeId=${elem.userId}`)
  }

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table className={classes.table} aria-labelledby={aria} size='medium' aria-label={`${aria} table`}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {headerData.map((item, i) => (
                <TableCell key={i}>{getLabel(item.label)}</TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyData?.map((elem, i) => {
              const editCondition = elem.userId === merchant?.id
              const conditionalClass = clsx(classes.cell, { [classes.pointer]: editCondition })
              return (
                <TableRow key={i} hover={editCondition}>
                  <TableCell className={conditionalClass}>
                    <RedCheckbox checked={selected === elem.id} onChange={(e) => handleSelect(e, elem)} />
                  </TableCell>
                  <TableCell className={conditionalClass} onClick={() => editCondition && handleClick(elem)}>
                    {elem.userFirstName}
                  </TableCell>
                  <TableCell className={conditionalClass} onClick={() => editCondition && handleClick(elem)}>
                    {elem.userLastName}
                  </TableCell>
                  <TableCell className={conditionalClass} onClick={() => editCondition && handleClick(elem)}>
                    {elem.userEmail}
                  </TableCell>
                  <TableCell className={conditionalClass} onClick={() => editCondition && handleClick(elem)}>
                    {getLabel(`${elem.roleName}-label`)}
                  </TableCell>
                  <TableCell
                    className={conditionalClass}
                    onClick={() => editCondition && handleClick(elem)}
                    align='right'
                  >
                    {editCondition && <KeyboardArrowRight />}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

EmployeesTable.propTypes = {
  headerData: PropTypes.array.isRequired,
  bodyData: PropTypes.array.isRequired,
  intl: PropTypes.object,
  aria: PropTypes.string,
  handleSelect: PropTypes.func,
  selected: PropTypes.number,
}

export default withRouter(injectIntl(EmployeesTable))
