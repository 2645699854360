let state, attr, value, relatedState

const attributeMaker = (state_, attr_, value_) => {
  ;[state, attr, value] = [state_, attr_, value_]
  const contains = stateContainsAttributeId(state, attr)
  return contains ? handleExistingAttribute() : handleNewAttribute()
}

// HANDLE NEW STATE
const handleNewAttribute = () => {
  let newState = {
    attributeId: attr.id,
    freeCount: attr.freeCount,
    attributeValueIds: [value.id],
    attributePrices: [{ [value.id]: value.price }],
    attributeNames: [
      {
        name: value.name,
        price: value.price,
      },
    ],
  }
  state = [...state, newState]
  return state
}

// HANDLE EXISTING STATE
const handleExistingAttribute = () => {
  const equalsToOne = maxCountCheck()
  relatedState = state.find((i) => i.attributeId === attr.id)
  return equalsToOne ? handleOneMaxCount() : handleMultiMaxCount()
}

// HANDLE EXISTING STATE WHICH HAS MAXCOUNT 1
const handleOneMaxCount = () => {
  relatedState.attributeValueIds = [value.id]
  relatedState.attributePrices = [{ [value.id]: value.price }]
  return state
}

// HANDLE EXISTING STATE WHICH HAS MORE THAN 1 MAXCOUNT
const handleMultiMaxCount = () => {
  const includes = stateIncludesAttributeValueId(relatedState, value.id)
  return includes ? handleExistingAttributeValueId() : handleNewAttributeValueId()
}

//HANDLE NEW ATTRIBUTE VALUE ID IN EXISTING STATE
const handleNewAttributeValueId = () => {
  relatedState.attributeValueIds.push(value.id)
  relatedState.attributePrices.push({ [value.id]: value.price })
  relatedState.attributeNames.push({
    name: value.name,
    price: value.price,
  })
  return state
}

// HANDLE EXISTING ATTRIBUTE VALUE ID IN EXISTING STATE
const handleExistingAttributeValueId = () => {
  // If attributeValueId has already been in relatedState, WE NEED TO REMOVE IT and its price
  const newState = relatedState.attributeValueIds.filter((e) => e !== value.id)
  const newPriceState = relatedState.attributePrices.filter((price) => Object.keys(price)[0] !== value.id)
  const newNameState = relatedState.attributeNames.filter((name) => name.name !== value.name)
  relatedState.attributeValueIds = newState
  relatedState.attributePrices = newPriceState
  relatedState.attributeNames = newNameState
  if (newState.length === 0) {
    state = state.filter((i) => i.attributeId !== relatedState.attributeId)
  }
  return state
}

const stateContainsAttributeId = (state, attr) => state.some((i) => i.attributeId === attr.id)

const maxCountCheck = () => attr.maximumCount === 1

const stateIncludesAttributeValueId = (state, valueId) => state.attributeValueIds.includes(valueId)

// Convert menuAttributes for addToCart function
const menuAttributesFormatter = (menuAttr) => {
  let newArray = []
  menuAttr.forEach((e) => {
    let obj = {
      attributeId: e.attributeId,
      attributeValueIds: e.attributeValueIds,
      attributeNames: e.attributeNames,
    }
    newArray.push(obj)
  })
  return newArray
}

export { attributeMaker, menuAttributesFormatter }
