import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  titleRow: {
    padding: theme.spacing(),
  },
}))

const Title = ({ searchResult, intl }) => {
  const classes = useStyles()
  if (searchResult.length === 0) return null

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <div className={clsx('centered-row', [classes.titleRow])}>
      <Typography variant='h6' gutterBottom className={classes.title}>
        {getLabel('places-near-you')}
      </Typography>
    </div>
  )
}

Title.propTypes = {
  searchResult: PropTypes.array.isRequired,
}

export default injectIntl(Title)
