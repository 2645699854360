import React from 'react'
import FirstMenuSection from './store-view/FirstMenuSection'
import SecondMenuSection from './store-view/SecondMenuSection'
import { Divider } from '@material-ui/core'

export const StoreView = (props) => {
  return (
    <>
      <FirstMenuSection />
      <Divider />
      <SecondMenuSection />
    </>
  )
}

export default StoreView
