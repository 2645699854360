import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { injectIntl } from 'react-intl'

const getMaxLength = {
  number: 11,
  text: 120,
  tax: 9,
  date: 10,
}

const extendErrorCodition = ['number', 'tax']

const BankInput = ({
  intl,
  isFocused,
  isInvalid,
  callback,
  value,
  label,
  text,
  type,
  type1,
  placeholder,
  editting,
}) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const standartError = isFocused[`${text}`] && isInvalid[`${text}`]
  const extendedError = standartError || (isFocused[`${text}`] && value.length < getMaxLength[type1])
  const errorCondition = extendErrorCodition.includes(type1) ? extendedError : standartError
  const isReadOnly = text.toString() === 'businessName'
  return (
    <TextField
      size='small'
      variant='outlined'
      placeholder={placeholder}
      required
      fullWidth
      id={label}
      label={getLabel(label)}
      value={value}
      onChange={callback}
      type={type ? type : null}
      // disabled={!editting}
      inputProps={{
        maxLength: getMaxLength[type1],
        readOnly: isReadOnly || !editting,
      }}
      InputLabelProps={{ shrink: true }}
      name={text}
      error={errorCondition}
      helperText={errorCondition ? getLabel(`enter-${text}`) : ' '}
    />
  )
}

BankInput.propTypes = {
  intl: PropTypes.object,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  callback: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  type1: PropTypes.string,
  placeholder: PropTypes.string,
  editting: PropTypes.bool,
}

export default injectIntl(BankInput)
