import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import AnimateHeight from 'react-animate-height'
import PropTypes from 'prop-types'

import AddressItem from './AddressItem'

const SavedAddresses = ({ addressArray, show, checked, handleCheck, hadleClickCheckedItem }) => {
  return (
    <AnimateHeight duration={300} height={show ? (addressArray.length * 70 > 210 ? 210 : addressArray.length * 70) : 0}>
      <div
        style={{
          maxHeight: 210,
          height: addressArray.length * 70,
        }}
      >
        <Scrollbars style={{ height: '100%' }}>
          {addressArray.length > 0 &&
            addressArray.map((address, index) => (
              <AddressItem
                address={address}
                checked={checked}
                handleCheck={handleCheck}
                key={index}
                fromAddressQuery={false}
                hadleClickCheckedItem={hadleClickCheckedItem}
              />
            ))}
        </Scrollbars>
      </div>
    </AnimateHeight>
  )
}

SavedAddresses.propTypes = {
  addressArray: PropTypes.array,
  show: PropTypes.bool,
  checked: PropTypes.bool,
  handleCheck: PropTypes.func,
  hadleClickCheckedItem: PropTypes.func,
}

export default SavedAddresses
