import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { Button, Typography, IconButton } from '@material-ui/core'
import closeIconButton from '../../images/close-icon.svg'
import deleteCardImage from '../../images/delete-card-image.svg'
import deleteAddressImage from '../../images/delete-address-image.svg'
import { GreenButton } from '../utils/tools'
import newOrderImage from '../../images/new-order-image.svg'
import noResponse from '../../images/no_response.svg'
import newPaymentMethod from '../../images/new-payment-method-image.svg'
import deleteFavoriteImage from '../../images/delete-favorite-image.svg'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: 200,
    marginTop: 0,
    marginBottom: 5,
  },
  headerContainer: {
    width: '100%',
    height: 'auto',
    minHeight: 85,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 5,
  },
  header: {
    width: '100%',
    height: 'auto',
    color: 'black',
    fontSize: 34,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  textContainer: {
    width: '100%',
    height: 'auto',
    minHeight: 75,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 20,
  },
  textContainer2: {
    width: '100%',
    height: 'auto',
    minHeight: 75,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 5,
  },
  text: {
    width: '100%',
    height: 'auto',
    color: 'black',
    textAlign: 'center',
    fontSize: 16,
    marginBottom: 5,
  },
  buttonContainer: {
    width: '100%',
    height: 21,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
  button: {
    width: 'auto',
    height: 21,
    padding: 0,
    color: '#707070',
    fontSize: 16,
    textAlign: 'center',
    marginRight: 15,
  },
  buttonRed: {
    width: 'auto',
    height: 21,
    padding: 0,
    color: '#ea4335',
    fontSize: 16,
    textAlign: 'center',
  },
  buttonGreen: {
    width: 'auto',
    height: 21,
    padding: 0,
    color: '#69e781',
    fontSize: 16,
    textAlign: 'center',
  },
  greenButton: {
    width: 263,
    height: 48,
    textTransform: 'capitalize',
    color: 'white',
  },
})

const modalImages = {
  deleteCardImage: deleteCardImage,
  deleteAddressImage: deleteAddressImage,
  newOrderImage: newOrderImage,
  noResponse: noResponse,
  newPaymentMethod: newPaymentMethod,
  deleteFavoriteImage: deleteFavoriteImage,
}

const MobileDialog = ({
  classes,
  close,
  closeIcon,
  header,
  mainText,
  subText,
  leftBtnAction,
  rightBtnAction,
  sizeW,
  sizeH,
  img,
  greenButton,
  leftButton,
  rightButton,
  id,
}) => {
  const redButton = ['delete-label', 'sign-out', 'proceed-label']

  return (
    <div className={classes.root}>
      {closeIcon && (
        <div className={classes.dialogIconButtonContainer}>
          <IconButton onClick={close} style={{ backgroundColor: 'transparent' }}>
            <img src={closeIconButton} alt='close-icon' height='20px' width='20px' />
          </IconButton>
        </div>
      )}
      <div className={classes.imgContainer}>
        <img src={modalImages[img] ?? null} alt='img' width={sizeW} height={sizeH} />
      </div>
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>
          <FormattedMessage id={header} />
        </Typography>
      </div>
      <div className={greenButton ? classes.textContainer2 : classes.textContainer}>
        <Typography className={classes.text}>
          <FormattedMessage id={mainText} />
        </Typography>
        {subText && (
          <Typography className={classes.text}>
            <FormattedMessage id={subText} />
          </Typography>
        )}
      </div>
      {greenButton ? (
        <GreenButton className={classes.greenButton} onClick={close}>
          {<FormattedMessage id={'continue-label'} />}
        </GreenButton>
      ) : (
        <div className={classes.buttonContainer}>
          <Button className={classes.button} style={{ backgroundColor: 'transparent' }} onClick={leftBtnAction}>
            <FormattedMessage id={leftButton} />
          </Button>
          <Button
            className={redButton.includes(rightButton) ? classes.buttonRed : classes.buttonGreen}
            style={{ backgroundColor: 'transparent' }}
            onClick={!id ? rightBtnAction : () => rightBtnAction(id)}
          >
            <FormattedMessage id={rightButton} />
          </Button>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(MobileDialog)
