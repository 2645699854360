import React from 'react'
import { useIntl } from 'react-intl'

import { Divider, Button } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const MoreDivider = ({ classes, set, more }) => {
  const { formatMessage: f } = useIntl()

  return (
    <div className={classes.moreDivider}>
      <Divider className='grow' />
      <Button onClick={() => set(!more)} className={classes.more} endIcon={more ? <ExpandLess /> : <ExpandMore />}>
        {more ? f({ id: 'show-less' }) : f({ id: 'show-more' })}
      </Button>
      <Divider className='grow' />
    </div>
  )
}

export default MoreDivider
