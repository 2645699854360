import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Box, makeStyles, Typography } from '@material-ui/core'
import SectionHeader from './SectionHeader'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const AddressContainer = ({ handleAddressChange }) => {
  const classes = useStyles()

  const searchQuery = useSelector(({ search }) => search.searchQuery)
  return (
    <Box className={classes.container} style={{ borderBottom: '1px solid #ededed' }}>
      <SectionHeader header='delivery-address' callback={handleAddressChange} />
      <Box mt={2} display='flex' className='centered-column' flexDirection='row' justifyContent='space-between'>
        <Box width='70%'>
          <Typography variant='subtitle1' style={{ fontSize: 14 }}>
            {searchQuery.address.formattedAddress}
          </Typography>
        </Box>
        <Box width='30%' style={{ textAlign: 'center' }}>
          <CheckIcon color='primary' fontSize='medium' />
        </Box>
      </Box>
    </Box>
  )
}

AddressContainer.propTypes = {
  props: PropTypes.object,
}

export default AddressContainer
