import { convertOrderMenus } from '../order-menu'
import { deliveryTimes } from '../../constants'
import moment from 'moment'

const createRequestBody = (query, customer, cart) => {
  const { orderAddress } = createOrderAddress(query, customer, cart)
  const { deliveryHour, deliveryDate } = deliveryHourDateMaker(query)
  const newOrderMenus = convertOrderMenus(cart.orderMenus)
  const requestBody = {
    storeId: cart.storeId,
    userId: customer?.id,
    orderType: cart.type,
    note: cart.note,
    deliveryHour,
    deliveryDate,
    orderAddress: orderAddress,
    orderMenus: newOrderMenus,
  }
  return requestBody
}

const deliveryHourDateMaker = (query) => {
  let [deliveryHour, deliveryDate] = [null, null]
  if (query.deliveryTime === deliveryTimes.ASAP) {
    const hourNow = moment(query.deliveryTimeDetail).format('HH')
    deliveryDate =
      hourNow === '23'
        ? moment(query.deliveryTimeDetail).add(1, 'days').format('YYYY-MM-DD')
        : moment(query.deliveryTimeDetail).format('YYYY-MM-DD')
    deliveryHour = moment(query.deliveryTimeDetail).add(1, 'hour').format('HH:mm')
  }
  if (query.deliveryTime === deliveryTimes.SCHEDULED) {
    deliveryDate = moment(query.deliveryTimeDetail).format('YYYY-MM-DD')
    deliveryHour = moment(query.deliveryTimeDetail).format('HH:mm')
  }
  return { deliveryHour, deliveryDate }
}

const createOrderAddress = (query, customer, cart) => {
  const { firstName, lastName, phone } = customer
  const { subType, instructions } = cart
  const { addressLine1, addressLine2, city, country, formattedAddress, state, zipCode } = query.address
  let orderAddress = {
    firstName,
    lastName,
    phone,
    country,
    state,
    stateName: '',
    city,
    district: '',
    zipCode,
    addressLine1,
    addressLine2,
    formattedAddress,
    deliveryOption: subType,
    deliveryInstruction: instructions,
  }
  return { orderAddress }
}

export { createOrderAddress, createRequestBody }
