import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { ratingService } from '../../../services'
import { generalConstants } from '../../../constants'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { periods, periodValues } from '../../../constants/reviews/select-button'
import { createStartDate } from '../../../helpers/reviews/select-button'
import StarIcon from '@material-ui/icons/Star'
import PersonIcon from '@material-ui/icons/Person'
import OverallCard from './reviews-main/OverallCard'
import RatingCard from './reviews-main/RatingCard'
import MerchantNoResult from '../common/MerchantNoResult'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginTop: 70,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  ratingsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
}))

const ReviewsMain = () => {
  const classes = useStyles()

  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()
  const storeId = useSelector(({ merchantStores }) => merchantStores.selectedStore.storeId)

  const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD')
  const endDate = moment().format('YYYY-MM-DD')
  const initialQuery = {
    storeId,
    startDate,
    endDate,
  }

  const [loading, setLoading] = useState(false)
  const [ratings, setRatings] = useState()
  const [query, setQuery] = useState(initialQuery)
  const [period, setPeriod] = useState(6)

  useEffect(() => {
    const init = async () => {
      await fetchStoreRatings(query)
    }
    init()
  }, [JSON.stringify(query)])

  const handlePeriod = (e) => {
    const { newStartDate } = createStartDate(e.target.value)
    setQuery({ ...query, startDate: newStartDate })
    setPeriod(e.target.value)
  }

  const fetchStoreRatings = async (payload) => {
    setLoading(true)
    await ratingService
      .getStoreRatings(payload)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setRatings(res.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const getPeriod = () => periods[period]

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      {ratings?.totalRatingCount === 0 ? (
        <Grid container direction='row' alignItems='center' justifyContent='center'>
          <MerchantNoResult text='no-customer-feedback' />
        </Grid>
      ) : (
        <>
          <Grid container direction='row' alignItems='center' justify={'flex-end'}>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel id='select-period-label'>Period</InputLabel>
              <Select
                labelId='select-period-label'
                id='select-period'
                value={period}
                onChange={handlePeriod}
                label='Period'
              >
                {periodValues.map((period) => (
                  <MenuItem key={`${period.value}${period.name}`} value={period.value}>
                    {period.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container spacing={2} className={classes.ratingsContainer}>
            <OverallCard
              getPeriod={getPeriod}
              title='Average Rating'
              avatar={<StarIcon fontSize='large' color='primary' />}
              action={ratings?.ratingSummary}
            />
            <OverallCard
              getPeriod={getPeriod}
              title='Number of Votes'
              avatar={<PersonIcon fontSize='large' color='primary' />}
              action={ratings?.totalRatingCount}
              count
            />
          </Grid>
          <Grid container spacing={2} className={classes.ratingsContainer}>
            {ratings?.ratingResponses?.map((rating) => (
              <RatingCard key={rating?.orderId} rating={rating} />
            ))}
          </Grid>
        </>
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

export default ReviewsMain
