import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

const ThirdFile = ({ classes, selectPdf, pdfFile, removePdf, deleteLayer }) => {
  const { formatMessage: f } = useIntl()

  return (
    <Grid item md={12} sm={12} xs={12} className={classes.gridItem} input-container='true'>
      <div className={classes.buttonsContainer}>
        <label htmlFor='file-upload3' className={classes.uploadButton}>
          {f({ id: 'select-label' })}
        </label>
        <input
          id='file-upload3'
          type='file'
          onChange={selectPdf('third')}
          accept='application/pdf'
          className={classes.input}
          style={{ display: 'none' }}
        />
        <div className={classes.uploadButton} data-content='delete' onClick={() => removePdf('third')}>
          {f({ id: 'remove-label' })}
        </div>
      </div>
      {pdfFile.third && <Typography className={classes.fileName}>{pdfFile.third.name}</Typography>}
      <div className={classes.iconButtonsContainer}>
        <IconButton
          color='inherit'
          size='small'
          className={classes.addButton}
          aria-label='delete'
          onClick={() => deleteLayer('third')}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </Grid>
  )
}

ThirdFile.propTypes = {
  classes: PropTypes.object,
  selectPdf: PropTypes.func,
  pdfFile: PropTypes.object,
  removePdf: PropTypes.func,
  deleteLayer: PropTypes.func,
}

export default ThirdFile
