const marksDistance = () => {
  let marks = []
  let values = [5, 15, 25, 35]

  for (let i = 0; i <= 3; i++) {
    marks.push({
      value: values[i],
      label: i === 3 ? '35+' : values[i].toString(),
    })
  }
  return marks
}

export { marksDistance }
