import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  textfield: {
    '& .MuiInputBase-input': {
      color: '#707070',
      '&:not(:focus)': {
        cursor: 'pointer',
      },
      '&::placeholder': {
        fontSize: 14,
        fontWeight: 300,
      },
    },
  },
}))

const PasswordField = ({
  showPassword,
  password,
  handleChange,
  isFocused,
  togglePasswordVisibility,
  name,
  placeholder,
  label,
  error,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const handleError = (val) => {
    if (val === 'newPassword' && error) {
      return true
    } else if (val === 'confirmNewPassword' && error) {
      return true
    }
    return false
  }

  const handleHelperText = (val) => {
    if (val === 'newPassword' && error) {
      return f({ id: 'pass-8-char' })
    } else if (val === 'confirmNewPassword' && error) {
      return f({ id: 'password-not-match' })
    }
  }

  const handlePaste = (e) => e.preventDefault()

  return (
    <TextField
      variant='outlined'
      margin='normal'
      fullWidth
      name={name}
      label={label}
      placeholder={placeholder}
      className={classes.textfield}
      id={name}
      autoComplete='current-password'
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={handleChange}
      error={isFocused ? handleError(name) : false}
      InputLabelProps={{ shrink: true }}
      helperText={handleHelperText(name)}
      onPaste={name === 'confirmNewPassword' ? handlePaste : null}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' style={{ marginRight: -10 }}>
            <IconButton
              style={{ height: 30, width: 30 }}
              aria-label='toggle password visibility'
              onClick={() => togglePasswordVisibility(name)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

PasswordField.propTypes = {
  showPassword: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  togglePasswordVisibility: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
}

export default PasswordField
