import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import ClickableRichTooltip from './clickable_tooltip'
import useWindowSize from '../../../../helpers/use_window_size'
import { isTablet } from 'react-device-detect'
import { getCardIcon } from '../../../../helpers/card-icons'

const styles = (theme) => ({
  rectTextContainer: {
    height: 21,
    width: isTablet ? '80.5%' : '72%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  edit: {
    width: 16,
    height: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginLeft: 5,
  },
  rectangle: {
    borderRadius: 5,
    border: 'solid 0.5px #c4c4c4',
    backgroundColor: '#ffffff',
    height: 48,
    marginTop: 8,
    marginLeft: 0,
    width: isTablet ? '80%' : '71.5%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '12px 0px 12px 12px',
  },
  subHeader: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333333',
  },
  rectangleContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  cardInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stars: {
    marginRight: 10,
    marginTop: 5,
    fontWeight: 'bold',
    fontSize: 20,
    color: '#ffffff',
  },
  starsGrey: {
    marginRight: 10,
    marginTop: 5,
    fontWeight: 'bold',
    fontSize: 20,
    color: '#c4c4c4',
  },
  text: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 3.13,
    letterSpacing: '3.2px',
    textAlign: 'left',
    color: '#ffffff',
  },
  textGrey: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 3.13,
    letterSpacing: '3.2px',
    textAlign: 'left',
    color: '#c4c4c4',
  },
  button: {
    textTransform: 'capitalize',
    height: 38,
    width: '100%',
    paddingLeft: 0,
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 90,
    width: '100%',
  },
  upButton: {
    height: 45,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: '1px solid #c4c4c4',
  },
  downButton: {
    height: 45,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonText: {
    fontSize: 14,
    textAlign: 'left',
    color: '#707070',
    width: '100%',
  },
})

const Card = ({ data, selectPaymentMethod, deleteCard, selectedPaymentMethod, intl, classes }) => {
  const [status, setStatus] = useState(true)
  const size = useWindowSize()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleDelete = () => {
    setStatus(false)
    let cardId = data.id
    deleteCard(cardId)
  }

  const handleSelect = () => {
    setStatus(false)
    let cardId = data.id
    selectPaymentMethod(cardId)
  }

  const Content = (
    <div className={classes.tooltipContent}>
      {selectedPaymentMethod !== data.id && (
        <div className={classes.upButton}>
          <Button style={{ backgroundColor: 'transparent' }} className={classes.button} onClick={() => handleSelect()}>
            <Typography className={classes.buttonText}>{getLabel('make-default')}</Typography>
          </Button>
        </div>
      )}
      <div className={classes.downButton}>
        <Button style={{ backgroundColor: 'transparent' }} className={classes.button} onClick={() => handleDelete()}>
          <Typography className={classes.buttonText}>{getLabel('delete-label')}</Typography>
        </Button>
      </div>
    </div>
  )

  const stars = '* * * *'
  const brand = data?.card.brand
  return (
    <Grid item xs={12} style={{ height: 90, marginBottom: 30 }}>
      <div className={classes.rectTextContainer}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Typography className={classes.subHeader}>{`${brand[0].toUpperCase()}${brand.substring(1)}`}</Typography>
          {selectedPaymentMethod === data.id && (
            <Typography className={classes.subHeader} style={{ marginLeft: 4 }}>
              ({getLabel('primary-label')})
            </Typography>
          )}
        </div>
        <ClickableRichTooltip
          content={Content}
          placement='bottom'
          defaultCard={selectedPaymentMethod}
          cardId={data.id}
          status={status}
        >
          <IconButton className={classes.edit} aria-label='edit' component='span'>
            <MoreHorizIcon />
          </IconButton>
        </ClickableRichTooltip>
      </div>
      <div
        className={classes.rectangle}
        id={data.id}
        style={{
          backgroundColor: selectedPaymentMethod === data.id ? '#69e781' : '#e0dedc',
        }}
      >
        <div className={classes.rectangleContentContainer}>
          <img src={getCardIcon(data?.card)} alt='visacard' width='45' height='45' />
          <div className={classes.cardInfoContainer}>
            {size.width > 890 && (
              <Typography variant='body1' className={classes.stars}>
                {stars}
              </Typography>
            )}
            {size.width > 700 && (
              <Typography variant='body1' className={classes.stars}>
                {stars}
              </Typography>
            )}
            {size.width > 530 && (
              <Typography variant='body1' className={classes.stars}>
                {stars}
              </Typography>
            )}
            <Typography variant='body1' className={classes.text} style={{ marginRight: 12 }}>
              {data.card.last4}
            </Typography>
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default withStyles(styles)(withRouter(injectIntl(Card)))
