import { emailRegex, phoneRegex, urlRegex } from '../../utils/regex_collections'
import { valueConstants } from '../businessConstants'
import { filter } from 'lodash'

const zerothStepRequiredFieldsFilled = (values) => {
  let phoneNoWithoutCountryCode = values.phoneNo.substring(1)
  if (
    values.firstName.length === 0 ||
    values.lastName.length === 0 ||
    values.email.length === 0 ||
    values.phoneNo.length === 0 ||
    !phoneRegex.test(phoneNoWithoutCountryCode) ||
    !emailRegex.test(values.email)
  ) {
    return false
  } else {
    return true
  }
}

const firstStepRequiredFieldsFilled = (values, parsedAddress) => {
  let storePhoneWithoutCountryCode = values.storePhone.substring(1)
  if (
    values.legalBusinessName.length === 0 ||
    values.storeName.length === 0 ||
    values.storePhone.length === 0 ||
    values.storeEmail.length === 0 ||
    values.categoryId.length === 0 ||
    values.minHeadCount.length === 0 ||
    !parsedAddress ||
    !phoneRegex.test(storePhoneWithoutCountryCode) ||
    !emailRegex.test(values.storeEmail)
  ) {
    return false
  } else {
    return true
  }
}

const secondStepRequiredFieldsFilled = (serviceHours) => {
  let deliveryArr = filter(serviceHours.DELIVERY, (sh) => sh.length > 0)
  let takeoutArr = filter(serviceHours.TAKEOUT, (sh) => sh.length > 0)

  if (deliveryArr.length > 0 || takeoutArr.length > 0) {
    return true
  }
  return false
}

const thirdStepRequiredFieldsFilled = (values, pdfData) => {
  if (values.menuLink === '' && !(pdfData.first || pdfData.second || pdfData.third)) {
    return false
  } else if (values.menuLink.length > 0) {
    if (!urlRegex.test(values.menuLink)) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}

const fourthStepRequiredFieldsFilled = (values) => {
  const { notificationType } = values
  if (notificationType.length === 0) return false
  if (notificationType.length === 2) {
    const resultPhone = handlePhoneTest(values)
    const resultEmail = handleEmailTest(values)
    return resultPhone && resultEmail
  } else {
    const resultPhone = handlePhoneTest(values)
    return resultPhone
  }
}

const handlePhoneTest = (values) => {
  let orderConfirmationPhoneWithoutCountryCode = values.orderConfirmationPhone.substring(1)
  if (!phoneRegex.test(orderConfirmationPhoneWithoutCountryCode)) {
    return false
  } else {
    return true
  }
}

const handleEmailTest = (values) => {
  if (!emailRegex.test(values.orderConfirmationEmail)) {
    return false
  } else {
    return true
  }
}

const fifthStepRequiredFieldsFilled = (values, secondTierState, thirdTierState) => {
  if (values.deliveryBy === valueConstants.tookfresh) {
    if (values.maxDeliveryDistance === '' || values.minOrderPrice === '') {
      return false
    } else {
      return true
    }
  } else {
    let tier1 =
      values.firstTierDeliveryFee !== '' && values.firstTierMinOrder !== '' && values.firstTierMaxDistance !== ''
    let tier2 =
      values.secondTierDeliveryFee !== '' && values.secondTierMinOrder !== '' && values.secondTierMaxDistance !== ''
    let tier3 =
      values.thirdTierDeliveryFee !== '' && values.thirdTierMinOrder !== '' && values.thirdTierMaxDistance !== ''

    if (!secondTierState && !thirdTierState && tier1) {
      return true
    } else if (!thirdTierState && secondTierState && tier2 && tier1) {
      return true
    } else if (thirdTierState && secondTierState && tier1 && tier2 && tier3) {
      return true
    } else {
      return false
    }
  }
}

const tierControl = (values) => {
  let deliverySelection = values.deliveryBy === valueConstants.ownBusiness

  let secondTierVals = values.secondTierMinOrder !== '' && values.secondTierMaxDistance !== ''
  let thirdTierVals = values.thirdTierMinOrder !== '' && values.thirdTierMaxDistance !== ''

  let isFirstSecondMinOrderValues = +values.firstTierMinOrder < +values.secondTierMinOrder
  let isFirstSecondMaxDistanceValues = +values.firstTierMaxDistance < +values.secondTierMaxDistance
  let isFirstSecondDeliveryFeeValues = +values.firstTierDeliveryFee <= +values.secondTierDeliveryFee

  let isSecondThirdMinOrderValues = +values.secondTierMinOrder < +values.thirdTierMinOrder
  let isSecondThirdMaxDistanceValues = +values.secondTierMaxDistance < +values.thirdTierMaxDistance
  let isSecondThirdDeliveryFeeValues = +values.secondTierDeliveryFee <= +values.thirdTierDeliveryFee

  if (deliverySelection) {
    if (thirdTierVals) {
      if (
        isSecondThirdMinOrderValues &&
        isSecondThirdMaxDistanceValues &&
        isFirstSecondMinOrderValues &&
        isFirstSecondMaxDistanceValues &&
        isFirstSecondDeliveryFeeValues &&
        isSecondThirdDeliveryFeeValues
      ) {
        return true
      } else {
        return false
      }
    } else if (secondTierVals) {
      if (isFirstSecondMinOrderValues && isFirstSecondMaxDistanceValues && isFirstSecondDeliveryFeeValues) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  } else {
    return true
  }
}

const sixthStepRequiredFieldsFilled = (values) => {
  let paymentContactPhoneWithoutCountryCode = values.paymentContactPhone.substring(1)
  if (
    !phoneRegex.test(paymentContactPhoneWithoutCountryCode) ||
    values.paymentContactName.length === 0 ||
    values.paymentContactEmail.length === 0
  ) {
    return false
  } else {
    return true
  }
}

const seventhStepRequiredFieldsFilled = (values, checkParsedAddress, taxDocument) => {
  if (!taxDocument) return false
  if (values.paymentMethod === valueConstants.directDeposit) {
    if (
      values.taxIdNumber.trim().length < 10 ||
      values.routingNumber.length < 9 ||
      values.accountNumber.length < 10 ||
      values.confirmAccountNumber.length < 10 ||
      values.accountNumber !== values.confirmAccountNumber
    ) {
      return false
    } else {
      return true
    }
  } else if (values.paymentMethod === valueConstants.check) {
    if (!checkParsedAddress) {
      return false
    } else {
      return true
    }
  }
}

export {
  zerothStepRequiredFieldsFilled,
  firstStepRequiredFieldsFilled,
  secondStepRequiredFieldsFilled,
  thirdStepRequiredFieldsFilled,
  fourthStepRequiredFieldsFilled,
  sixthStepRequiredFieldsFilled,
  fifthStepRequiredFieldsFilled,
  seventhStepRequiredFieldsFilled,
  tierControl,
}
