import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import leftArrow from '../../../images/left-arrow.svg'
import rightArrow from '../../../images/right-arrow.svg'
import Typography from '@material-ui/core/Typography'
import { searchService, storeService } from '../../../services'
import { generalConstants } from '../../../constants'
import StoreItem from '../search/store_item'
import Fab from '@material-ui/core/Fab'
import Carousel from 'nuka-carousel'
import Button from '@material-ui/core/Button'
import IntersectionVisible from 'react-intersection-visible'
import clsx from 'clsx'
import { customerActions } from '../../../actions'
import Loading from '../../common/loading'
import { isMobile, isTablet, isMobileOnly } from 'react-device-detect'
import SigninDialog from './SigninDialog'
import { checkScheduledOrAsap } from '../../../helpers/mobile-food/state-maker'
import { getOCStatusQuery } from '../../../helpers/store/getOCStatusQuery'

const path = process.env.PUBLIC_URL
const width = window.innerWidth

const flexCenter = {
  display: 'flex',
  alignItems: 'center',
}

const styles = (theme) => ({
  root: {
    height: width < 1280 ? (isMobileOnly ? 270 : 340) : 430,
    marginBottom: isMobileOnly ? theme.spacing(2) : theme.spacing(3),
  },
  titleContainer: {
    ...flexCenter,
    height: 66,
    width: '100%',
  },
  title: {
    fontSize: isTablet ? 35 : 50,
    fontWeight: 'bold',
    lineHeight: 1.32,
    color: '#000000',
    maxWidth: width - 250,
  },
  sectionDesc: {
    fontSize: 20,
    lineHeight: 2,
    color: 'rgba(51, 51, 51, 0.5)',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(),
  },
  mobileHeaderContainer: {
    ...flexCenter,
    width: width,
    height: '30px',
    justifyContent: 'center',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  mobileHeader: {
    ...flexCenter,
    width: '100%',
    height: 21,
    padding: 0,
    justifyContent: 'space-between',
  },
  mobileHeaderText: {
    width: width - 85,
    height: '100%',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.19,
    color: '#333333',
  },
  carouselControlContainer: {
    ...flexCenter,
    minWidth: 189,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  carouselControl: {
    ...flexCenter,
    width: '100%',
    height: 48,
    justifyContent: 'space-between',
  },
  carouselTextContainer: {
    width: 85,
    height: 21,
  },
  carouselText: {
    fontSize: '16px',
    lineHeight: '1.31',
    color: '#0d0d0d',
  },
  fabContainer: {
    width: 104,
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
  },
  fab: {
    ...flexCenter,
    maxWidth: 48,
    height: 48,
    justifyContent: 'center',
  },
  mobileCarouselContainer: {
    paddingLeft: 0,
  },
  storeItemContainer: {
    backgroundColor: '#ffffff',
    minHeight: 225,
  },
  viewAllButton: {
    minWidth: 70,
    textTransform: 'capitalize',
    height: 30,
  },
  seeAllButton: {
    textTransform: 'capitalize',
    height: 21,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  seeAllButtonText: {
    width: '100%',
    height: 16,
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 0.58,
    textAlign: 'right',
    color: '#69e781',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
})

class CampaignCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      stores: [],
      favoriteStores: [],
      slideIndex: 0,
      innerWidth: width,
      height: width < 1280 ? (isMobileOnly ? 270 : 320) : 420,
      //width minus item width and paddings divided by item width (amount of visible part of second item in viewport)
      slidesToShow: 1 + (width - 337) / 307,
      slidesToShowWeb: width > 1240 ? 3 : (width - 40) / 397.33,
      showLogin: false,
      storeIds: [],
      storeOpenStatus: [],
      query: {
        page: 0,
        size: 12,
        latitude: null,
        longitude: null,
        promotionIds: null,
        customOffset: 0,
      },
    }
    this.handleLoginDialogClose = this.handleLoginDialogClose.bind(this)
    this.handleSignin = this.handleSignin.bind(this)
    this.goToCampaigns = this.goToCampaigns.bind(this)
  }

  componentDidMount() {
    const { address } = this.props.searchQuery
    address && this.initializeDataFetch()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentDidUpdate(prevProps, prevState) {
    let { innerWidth, storeIds, stores } = this.state
    const { address, deliveryTimeDetail, deliveryTime } = this.props.searchQuery
    if (prevState.innerWidth !== innerWidth) {
      this.setState({
        slidesToShow: 1 + (innerWidth - 337) / 307,
        slidesToShowWeb: innerWidth > 1240 ? 3 : (innerWidth - 40) / 397.33,
        height: innerWidth < 1280 ? (isMobileOnly ? 270 : 320) : 420,
      })
    }
    if (prevProps.searchQuery?.address?.formattedAddress !== address?.formattedAddress) {
      this.initializeDataFetch()
    }
    if (
      prevProps.searchQuery.deliveryTime !== deliveryTime ||
      prevProps.searchQuery.deliveryTimeDetail !== deliveryTimeDetail
    ) {
      this.initializeDataFetch()
    }
    if (prevState.stores !== stores) {
      this.getStoreIds()
    }
    if (prevState.storeIds !== storeIds) {
      storeIds.length > 0 && this.getOCStatus(storeIds)
    }
  }
  // add for responsiveness regarding to manual width change
  updateDimensions = () => {
    this.setState({ innerWidth: window.innerWidth })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  getOCStatus = async (storeIds) => {
    const { searchQuery } = this.props
    const query = getOCStatusQuery(searchQuery, storeIds)
    const response = await storeService.getStoreOCStatus(query)
    response && this.getOpenStoreIdsFromResponse(response)
  }

  getStoreIds = () => {
    const { stores } = this.state
    let storeIds = stores.map((store) => store.id)
    this.setState({ storeIds })
  }

  getOpenStoreIdsFromResponse = (response) => {
    const rawStoreOpenStatus = response?.filter((res) => res.open)
    const storeOpenStatus = rawStoreOpenStatus.map((item) => item.storeId)
    this.setState({ storeOpenStatus })
  }

  initializeDataFetch = () => {
    const searchQuery = this.props.searchQuery
    const { query } = this.state
    const { address } = searchQuery
    const newQuery = checkScheduledOrAsap(searchQuery, query)
    const { id } = this.props
    this.setState(
      {
        query: {
          ...newQuery,
          latitude: address?.latitude,
          longitude: address?.longitude,
          promotionIds: id,
          customOffset: 0,
        },
      },
      () => this.fetchData()
    )
  }

  fetchData(index) {
    const { stores, query } = this.state
    const fetchMore = !index ? true : this.fetchMoreCheck(query.customOffset, stores)
    if (!fetchMore) return
    this.getCampaignStores(index)
  }

  fetchMoreCheck(offset, stores) {
    const { query } = this.state
    let fetchMore = true
    if (offset !== 0 && query.size > offset) {
      fetchMore = false
    }
    if (stores.length % query.size !== 0) {
      fetchMore = false
    }
    return fetchMore
  }

  getCampaignStores(index) {
    const { mobile } = this.props
    const { query, stores } = this.state
    this.setState({ loading: true })
    searchService
      .search(query)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          let result = res.data
          this.setState({
            res: true,
            stores: query.customOffset === 0 ? result.stores : [...stores, ...result.stores],
            query: {
              ...query,
              customOffset: result.stores.length > 0 ? result.customOffset : 0,
            },
            loading: false,
            slideIndex: index ? (mobile ? index - 1 : index - 2) : 0,
          })
        }
      })
      .catch((err) => {
        this.setState({ error: true, loading: false })
      })
  }

  favoriteHandler(id) {
    const { signedIn, history, deleteFavorite, saveFavorite, favoriteStores } = this.props
    if (!signedIn) {
      if (isMobile) {
        this.setState({ showLogin: true })
      } else {
        history.push(`${path}/customer/signin`, 'food')
      }
      return
    }
    let includes = false
    let storeId
    if (favoriteStores.length > 0) {
      favoriteStores.forEach((store) => {
        store.entityId === id && ([includes, storeId] = [true, store.id])
      })
    }
    includes ? deleteFavorite(storeId) : saveFavorite(id)
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  clickPrevious() {
    this.Carousel.previousSlide()
  }

  clickNext(e) {
    this.Carousel.nextSlide()
  }

  handleLoginDialogClose = () => {
    this.setState({ showLogin: false })
  }

  handleSignin = () => {
    this.setState({ showLogin: false })
    this.props.history.push(`${path}/customer/food`)
  }

  goToCampaigns = (id) => this.props.history.push(`${path}/customer/campaigns/${id}`)

  render() {
    const { classes, history, price, mobile, favoriteStores, globalLoading, campaignTitle, description, id } =
      this.props
    const { stores, slideIndex, slidesToShow, slidesToShowWeb, showLogin, storeOpenStatus } = this.state
    if (stores?.length === 0) {
      return <div style={{ height: !isMobile && 0 }}></div>
    } else {
      return (
        <div className={classes.root}>
          <Loading open={globalLoading} />
          <Grid container>
            {mobile ? (
              <Container className={classes.mobileHeaderContainer}>
                <div className={classes.mobileHeader}>
                  <Typography noWrap className={classes.mobileHeaderText}>
                    {campaignTitle}
                  </Typography>
                  <Button disableRipple className={classes.seeAllButton} onClick={() => this.goToCampaigns(id)}>
                    <Typography className={classes.seeAllButtonText}>{this.getLabel('see-all')}</Typography>
                  </Button>
                </div>
              </Container>
            ) : (
              <Grid item xs={12} className={classes.headerContainer}>
                <div>
                  <div className={classes.titleContainer}>
                    <Typography noWrap className={classes.title}>
                      {campaignTitle}
                    </Typography>
                  </div>
                  <Typography className={classes.sectionDesc}>{description}</Typography>
                </div>
                <div className={classes.carouselControlContainer}>
                  <div className={classes.carouselControl}>
                    <div className={classes.carouselTextContainer}>
                      <Button disableRipple className={classes.viewAllButton} onClick={() => this.goToCampaigns(id)}>
                        <Typography className={classes.carouselText}>{this.getLabel('view-all-label')}</Typography>
                      </Button>
                    </div>
                    <div className={classes.fabContainer}>
                      <Fab color='primary' id='1' className={classes.fab} onClick={() => this.clickPrevious()}>
                        <img src={leftArrow} alt='leftarrow' width='13px' height='23px' />
                      </Fab>
                      <Fab color='primary' id='1' className={classes.fab} onClick={() => this.clickNext()}>
                        <img src={rightArrow} alt='leftarrow' width='13px' height='23px' />
                      </Fab>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={12} className={classes.mobileCarouselContainer}>
              {mobile ? (
                <Carousel
                  transitionMode='scroll'
                  slideIndex={slideIndex}
                  slidesToShow={slidesToShow}
                  speed={500}
                  cellSpacing={10}
                  withoutControls
                  // beforeSlide={(e) => this.setState({ slideIndex: e })}
                  // ref={(el) => (this.Carousel = el)}
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  {stores?.map((store, index) => (
                    <div className={clsx('slider-slide', [classes.storeItemContainer])} key={index}>
                      <IntersectionVisible onShow={() => index === stores.length - 4 && this.fetchData(index)}>
                        <StoreItem
                          data={store}
                          history={history}
                          onPress={this.favoriteHandler.bind(this)}
                          favorites={favoriteStores}
                          price={price}
                          food={true}
                          mobile={true}
                          component='carousel'
                          storeOpen={storeOpenStatus.includes(store.id)}
                        />
                      </IntersectionVisible>
                    </div>
                  ))}
                </Carousel>
              ) : (
                <Carousel
                  transitionMode='scroll'
                  slideIndex={slideIndex}
                  speed={500}
                  slidesToShow={slidesToShowWeb}
                  cellSpacing={20}
                  slidesToScroll={isTablet ? 2 : 3}
                  withoutControls
                  // afterSlide={(e) => this.setState({ slideIndex: e })}
                  ref={(el) => (this.Carousel = el)}
                  style={{ height: width < 1280 ? 230 : 320 }}
                >
                  {stores?.map((store, index) => (
                    <div className='slider-slide' key={index}>
                      <IntersectionVisible onShow={() => index === stores.length - 4 && this.fetchData(index)}>
                        <StoreItem
                          data={store}
                          history={history}
                          onPress={this.favoriteHandler.bind(this)}
                          favorites={favoriteStores}
                          price={price}
                          food={true}
                          storeOpen={storeOpenStatus.includes(store.id)}
                        />
                      </IntersectionVisible>
                    </div>
                  ))}
                </Carousel>
              )}
            </Grid>
          </Grid>
          <SigninDialog
            showLogin={showLogin}
            handleLoginDialogClose={this.handleLoginDialogClose}
            handleSignin={this.handleSignin}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  const { search, customer } = state

  return {
    searchQuery: search.searchQuery,
    signedIn: customer.signedIn,
    favoriteStores: customer.favoriteStores,
    globalLoading: customer.loading,
  }
}

const actionCreators = {
  saveFavorite: customerActions.saveFavoriteStore,
  deleteFavorite: customerActions.deleteFavoriteStore,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(CampaignCarousel))))
