import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { orderActions, alertActions } from '../../../actions'
import { storeService } from '../../../services/store.service'
import { customerService } from '../../../services/customer.service'
import { generalConstants } from '../../../constants'
import { orderService } from '../../../services'
import { CardElement } from '@stripe/react-stripe-js'
import { createRequestBody } from '../../../helpers/order-menu/request-body'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import YourCart from './mobile-cart/YourCart'
import CheckOut from './mobile-cart/CheckOut'
import { cartDetailsHandler } from '../../../helpers/mobile-cart'
import DoneDialog from './mobile-cart/DoneDialog'
import Loading from '../../common/loading'
import { createNewOrder } from '../../../helpers/cart/create-new-order'
import { isEmpty } from 'lodash'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: 60,
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: -0.24,
    color: '#121212',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

const initialState = { isInvalid: false, isFocused: false }

export const MobileCart = ({ elements, stripe, handleClose, nextButton, setOrderFees, history }) => {
  const classes = useStyles()
  //Global states
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const customerInfo = useSelector(({ customer }) => customer.customerInfo, shallowEqual)
  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)
  const savedOrder = useSelector(({ orders }) => orders.savedOrder, shallowEqual)
  const searchQuery = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const orderFeeLoading = useSelector(({ orders }) => orders.loading)
  const savedOrderId = savedOrder?.id

  //Local states
  const [storeDetail, setStoreDetail] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState(null)
  const [logo, setLogo] = useState(null)
  const [itemCounter, setItemCounter] = useState(null)
  const [step, setStep] = useState(0)
  const [cards, setCards] = useState([])
  const [selectedCard, setSelectedCard] = useState(null)
  const [showCheckoutForm, setShowCheckoutForm] = useState(null)
  const [clientSecret, setClientSecret] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDoneDialog, setShowDoneDialog] = useState(false)
  const [sections, setSections] = useState([])

  //Actions
  const dispatch = useDispatch()
  const placeOrder = async (cart) => dispatch(orderActions.saveOrder(cart))
  const deleteOrder = async (id) => dispatch(orderActions.deleteOrder(id))
  const setQuantity = async (store, order) => dispatch(orderActions.selectMenuQuantity(store, order))
  const clearCart = () => dispatch(orderActions.clearCart())
  const removeMenu = (id) => dispatch(orderActions.removeMenuItem(id))
  const warningMsg = (msg) => dispatch(alertActions.warning(msg))
  const errorMsg = (msg) => dispatch(alertActions.error(msg))
  const clearOrderFees = () => dispatch(orderActions.clearOrderFees())
  const deleteSavedOrder = (id) => dispatch(orderActions.deleteOrder(id))

  const [tipAmount, setTipAmount] = useState({ value: 0, rate: 0, isCustom: false })
  const [customTipField, setCustomTipField] = useState(initialState)

  useEffect(() => {
    // Runs once during initial render
    const init = async () => {
      setLoading(true)
      const store_ = await storeService.findStore(cart.storeId)
      if (store_.status === generalConstants.STATUS_OK) {
        setStoreDetail(store_.data)
        setLogo(store_.data.restaurant.logo)
        getSections(cart.storeId)
      }
      setLoading(false)
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Runs everytime storeDetail change
    if (storeDetail) {
      getCartDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeDetail])

  useEffect(() => {
    savedOrderId && paymentIntent()
    return () => {
      setClientSecret(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedOrderId])

  useEffect(() => {
    !!tipAmount.value && clientSecret && handleSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret])

  useEffect(() => {
    if (storeDetail) {
      if (cart.storeId) {
        // placeOrder(cart)
        setOrderFees()
        getCartDetails()
      } else {
        savedOrderId && deleteOrder(savedOrder.id)
        getCartDetails()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cart.orderMenus), cart.storeId])

  const getSections = async (id) => {
    await storeService
      .getAllSectionsUi(id)
      .then((res) => {
        if (!isEmpty(res)) {
          setSections(res)
        }
        // setLoading(false)
      })
      .catch((err) => {
        // setError(true)
        // setLoading(false)
      })
  }

  const getCartDetails = () => {
    const { categoriesSelected, itemCounter } = cartDetailsHandler(cart, sections)
    setSelectedCategories(categoriesSelected)
    setItemCounter(itemCounter)
  }

  const userLoginCheck = () => {
    signedIn ? proceedToCheckout() : nextButton()
  }

  const proceedToCheckout = async () => {
    const phoneExistAndVerified = phoneCheck()
    if (phoneExistAndVerified) {
      setLoading(true)
      setStep(1)
      await saveOrderHandler()
      await customerService.getPaymentMethods().then(
        (res) => {
          if (res.status === generalConstants.STATUS_OK) {
            setCards(res.data.paymentMethods)
            setSelectedCard(res.data?.defaultPaymentMethod)
          }
        },
        (err) => {
          // console.log('getPaymentMethods err--', err)
        }
      )
      setLoading(false)
    } else {
      history.push(`${path}/customer/account/profile`)
      warningMsg('Please add phone number and/or verify before proceed to checkout')
    }
  }

  const saveOrderHandler = async () => {
    setLoading(true)
    let requestBody = createRequestBody(searchQuery, customerInfo, cart)
    requestBody.senpexApiToken = cart.senpexApiToken
    if (tipAmount?.value > 0) {
      requestBody.driverTipPrice = tipAmount?.value
    }
    await placeOrder(requestBody)
    setLoading(false)
  }

  const phoneCheck = () => {
    let bool = false
    const { phone, phoneVerified } = customerInfo
    phone && phoneVerified && (bool = true)
    return bool
  }

  const paymentIntent = async () => {
    if (savedOrder) {
      setLoading(true)
      await orderService.createPaymentIntent(savedOrder.id).then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setClientSecret(res.data.id)
        } else {
          // handle error
        }
        setLoading(false)
      })
    }
  }

  const goToStore = () => {
    if (history.location.pathname === `${path}/customer/store/${storeDetail.id}`) {
      handleClose()
    } else {
      history.push(`${path}/customer/store/${storeDetail.id}`)
    }
  }

  const toggleCheckoutForm = () => {
    setShowCheckoutForm((prev) => !prev)
    setSelectedCard(null)
  }

  const selectPaymentMethod = async (id) => {
    setLoading(true)
    await customerService.defaultPaymentMethod(id).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setShowCheckoutForm(false)
          setSelectedCard(id)
        }
      },
      (err) => {
        // console.log('defaultPaymentMethod err--', err)
      }
    )
    setLoading(false)
  }

  const handleSubmit = async () => {
    // Block native form submission.
    // event.preventDefault()
    setLoading(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    let paymentMethod
    const cardElement = elements.getElement(CardElement)
    cardElement ? (paymentMethod = { card: cardElement }) : (paymentMethod = selectedCard)

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod,
    })

    if (result.error) {
      setLoading(false)
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        orderService.paymentConfirmation(savedOrder.id).then(
          (res) => {
            if (res.status === generalConstants.STATUS_OK) {
              setShowDoneDialog(true)
            }
            setLoading(false)
          },
          (err) => {
            setLoading(false)
            errorMsg(err.response.error_message)
          }
        )
      }
    }
  }

  const proceedToOrders = () => {
    clearCart()
    setShowDoneDialog(false)
    history.push(`${path}/customer/orders`)
  }

  const deleteMenuItem = (index) => {
    setLoading(true)
    cart.orderMenus.length === 1 && clearCart()
    removeMenu(index)
    setLoading(false)
  }

  const handleQuantity = (e, index) => {
    const { newOrder } = createNewOrder(e, index, cart)
    const orderStore = { id: cart.storeId, nickname: cart.storeName }
    setQuantity(orderStore, newOrder)
    setTimeout(() => {
      setOrderFees()
    }, 500)
  }

  const checkTip = (event) => {
    event.preventDefault()
    tipAmount?.value === 0 ? handleSubmit() : saveOrderThenMakePayment()
  }

  const saveOrderThenMakePayment = async () => {
    await saveOrderHandler()
  }

  const handleAddressChange = () => {
    savedOrder?.id && deleteSavedOrder(savedOrder.id)
    clearOrderFees()
    history.push(`${path}/customer/account/addresses`)
  }

  const handleTip = (value) => {
    // This is to handle tip buttons
    let tipQuery = { isCustom: false }
    if (typeof value === 'string') {
      if (tipAmount.isCustom) {
        ;[tipQuery.value, tipQuery.rate, tipQuery.isCustom] = [0, 0, false]
      } else {
        ;[tipQuery.value, tipQuery.rate, tipQuery.isCustom] = [0, 0, true]
      }
    } else {
      setCustomTipField(initialState)
      if (tipAmount.rate === value) {
        ;[tipQuery.value, tipQuery.rate] = [0, 0]
      } else {
        tipQuery.value = +(cart?.finalSubtotalPrice * value).toFixed(2)
        tipQuery.rate = value
      }
    }
    setTipAmount(tipQuery)
  }

  const handleCustomTip = (e) => {
    // This is to handle custom tip input
    setCustomTipField({ isFocused: true, isInvalid: !e.target.value })
    setTipAmount({ ...tipAmount, value: e.target.value })
  }

  return (
    <div className={classes.root}>
      {step === 0 && (
        <YourCart
          classes={classes}
          logo={logo}
          loading={orderFeeLoading}
          handleClose={handleClose}
          storeDetail={storeDetail}
          selectedCategories={selectedCategories}
          itemCounter={itemCounter}
          goToStore={goToStore}
          deleteMenuItem={deleteMenuItem}
          userLoginCheck={userLoginCheck}
          handleQuantityChange={handleQuantity}
        />
      )}
      {step === 1 && (
        <CheckOut
          handleClose={handleClose}
          handleSubmit={checkTip}
          loading={loading}
          clientSecret={clientSecret}
          toggleCheckoutForm={toggleCheckoutForm}
          cards={cards}
          showCheckoutForm={showCheckoutForm}
          selectPaymentMethod={selectPaymentMethod}
          selectedCard={selectedCard}
          handleAddressChange={handleAddressChange}
          handleTip={handleTip}
          handleCustomTip={handleCustomTip}
          customTip={customTipField}
          tipValue={tipAmount.value}
          driverTipPrice={tipAmount}
        />
      )}
      <Loading open={orderFeeLoading || loading} />
      <DoneDialog showDoneDialog={showDoneDialog} proceedToOrders={proceedToOrders} />
    </div>
  )
}

MobileCart.propTypes = {
  store: PropTypes.number,
  elements: PropTypes.object,
  stripe: PropTypes.object,
  handleClose: PropTypes.func,
  nextButton: PropTypes.func,
  setOrderFees: PropTypes.func,
  history: PropTypes.object,
}

export default withRouter(MobileCart)
