import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import AddressSearch from '../../../../../common/addressSearch/addressSearch'

import closeIcon from '../../../../../../images/close-icon.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
  },
  textfield: {
    height: 44,
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input': {
      color: '#707070',
    },
  },
  addressContainer: {
    height: 40,
    width: '100%',
    marginBottom: theme.spacing(),
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderRadius: 4,
    backgroundColor: '#fff',
    "&[is-active='true']": {
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    '&:hover': {
      border: `1px solid black`,
    },
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  iconButton: {
    width: 30,
    height: 30,
  },
}))

const InputArea = ({
  newAddress,
  setName,
  handleBorderColorBack,
  handleBorderColor,
  active,
  addressChangeListener,
  handleDeliveryAddress,
  setAddressLine2,
  clearName,
  clearAddressLine2,
  noName,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const adornment = (func) => {
    return (
      <InputAdornment position='end'>
        <IconButton className={classes.iconButton} onClick={func}>
          <img src={closeIcon} alt='close' height='14px' width='14px' />
        </IconButton>
      </InputAdornment>
    )
  }

  return (
    <div className={classes.root}>
      {!noName && (
        <TextField
          variant='outlined'
          value={newAddress.name}
          margin='dense'
          id='name'
          autoComplete='address-name'
          label={f({ id: 'address-name' })}
          className={classes.textfield}
          InputLabelProps={{
            style: {
              color: '#c4c4c4',
            },
          }}
          fullWidth
          onChange={(event) => setName(event)}
          inputProps={{
            maxLength: 50,
          }}
          InputProps={{ endAdornment: adornment(clearName) }}
        />
      )}
      <ClickAwayListener onClickAway={handleBorderColorBack}>
        <div className={classes.addressContainer} onClick={handleBorderColor} is-active={active}>
          <AddressSearch
            contentType='addAddress'
            addressChangeListener={addressChangeListener}
            handleDeliveryAddress={handleDeliveryAddress}
          />
        </div>
      </ClickAwayListener>
      <TextField
        variant='outlined'
        margin='dense'
        autoComplete='off'
        id='apt'
        label={f({ id: 'apt-suite' })}
        fullWidth
        value={newAddress.addressLine2}
        className={classes.textfield}
        InputLabelProps={{
          style: {
            color: '#c4c4c4',
          },
        }}
        onChange={(event) => setAddressLine2(event)}
        inputProps={{
          maxLength: 50,
        }}
        InputProps={{ endAdornment: adornment(clearAddressLine2) }}
      />
    </div>
  )
}

InputArea.propTypes = {
  newAddress: PropTypes.object,
  setName: PropTypes.func,
  handleBorderColorBack: PropTypes.func,
  handleBorderColor: PropTypes.func,
  active: PropTypes.string,
  addressChangeListener: PropTypes.func,
  handleDeliveryAddress: PropTypes.func,
  setAddressLine2: PropTypes.func,
  clearName: PropTypes.func,
  clearAddressLine2: PropTypes.func,
  noName: PropTypes.bool,
}

export default InputArea
