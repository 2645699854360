import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import { Transition } from '../../utils/tools'
import BeMember from './BeMember'

export const BeMemberDialog = ({ isOpen, handleClose }) => {
  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
      <BeMember handleClose={handleClose} />
    </Dialog>
  )
}

BeMemberDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default BeMemberDialog
