import { categoryConstants } from '../constants'

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
}

export function categories(state = INITIAL_STATE, action) {
  switch (action.type) {
    case categoryConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case categoryConstants.CREATE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.category],
      }
    case categoryConstants.CREATE_FAILURE:
      return {
        error: action.error,
      }
    case categoryConstants.GET_FAILURE:
      return {
        error: action.error,
      }
    case categoryConstants.GETALL_SUCCESS:
      return {
        ...state,
        data: action.categories,
      }
    case categoryConstants.GETALL_FAILURE:
      return {
        error: action.error,
      }
    default:
      return state
  }
}
