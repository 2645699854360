import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import backIcon from '../../../images/black-back.svg'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { CardElement } from '@stripe/react-stripe-js'
import Box from '@material-ui/core/Box'
import { customerService } from '../../../services/customer.service'
import { generalConstants } from '../../../constants'
import Dialog from '@material-ui/core/Dialog'
import Loading from '../../common/loading'
import { Transition } from '../../utils/tools'
import MobileDialog from '../../common/mobile-dialog'
import { alertActions } from '../../../actions/alert.actions'
import { useDispatch } from 'react-redux'

const height = window.innerHeight

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  add: {
    marginTop: theme.spacing(3),
  },
  address: {
    marginTop: theme.spacing(5),
    borderRadius: 4,
    border: '1px solid rgb(195, 195, 195)',
    height: 39,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  addButton: {
    textTransform: 'capitalize',
    color: '#69e781',
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    borderRadius: 10,
    border: 'solid 1px #69e781',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: 'white',
    boxShadow: 'none',
    marginBottom: 50,
    marginTop: 20,
  },
  generalDialogRoot: {
    width: 315,
    height: 463,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '15px 10px 15px 10px',
  },
})

const AddCard = ({ intl, classes, handleClose, stripe, elements }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const errorMessage = (msg) => dispatch(alertActions.error(msg))

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleDialogClose = () => {
    setOpen(false)
    handleClose()
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    setLoading(true)
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: 'Jenny Rosen',
      },
    })

    if (result.error) {
      errorMessage(result.error.message)
      setLoading(false)
    } else {
      customerService.savePaymentMethod(result.paymentMethod.id).then(
        (res) => {
          if (res.status === generalConstants.STATUS_OK) {
            setOpen(true)
            setLoading(false)
          }
        },
        (err) => {
          errorMessage(err.response?.data?.error_message)
          setLoading(false)
        }
      )
    }
  }

  return (
    <div className={classes.root}>
      <div className='column grow'>
        <div className='mobile-top-bar'>
          <IconButton size='small' className={classes.back} onClick={() => handleClose()}>
            <img src={backIcon} alt='Back' />
          </IconButton>
          <Typography variant='h6'>{getLabel('add-payment')}</Typography>
        </div>
        <div className={clsx('column grow full-width', [classes.content])}>
          <Box mt={2}>
            <form onSubmit={handleSubmit} style={{ marginTop: 20 }}>
              <CardElement options={CARD_ELEMENT_OPTIONS} />
              <Button className={classes.addButton} type='submit' disabled={loading || open}>
                {getLabel('new-payment-method')}
              </Button>
            </form>
          </Box>
          <Loading open={loading} />
          <div></div>
        </div>
        <Dialog
          classes={{ paper: classes.generalDialogRoot }}
          open={open}
          onClose={handleDialogClose}
          TransitionComponent={Transition}
        >
          <MobileDialog
            close={handleDialogClose}
            closeIcon={false}
            header={'success-label'}
            mainText={'new-payment-method-added'}
            leftBtnAction={null}
            rightBtnAction={null}
            id={false}
            sizeW={'213px'}
            sizeH={'192px'}
            img={'newPaymentMethod'}
            greenButton={true}
            leftButton={null}
            rightButton={null}
          />
        </Dialog>
      </div>
    </div>
  )
}

AddCard.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(injectIntl(withRouter(AddCard)))
