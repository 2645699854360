import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import nothingThere from '../../../images/nothing-there.svg'

const styles = (theme) => ({
  root: {
    maxWidth: 350,
    height: 350,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontSize: 34,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#333333',
    lineHeight: 1.18,
    textAlign: 'center',
    width: '80%',
  },
  text: {
    fontSize: 16,
    fontWeight: 300,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    color: '#333333',
  },
})

export class NoResult extends Component {
  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleLabel = () => {
    const { label } = this.props
    switch (label) {
      case 'address':
        return this.getLabel('no-address-yet')
      case 'scrollComponent':
        return this.getLabel('still-have-fresh-food')
      case 'orders':
        return this.getLabel('no-orders-yet')
      case 'favorites':
        return this.getLabel('no-favorites-yet')
      default:
        return ''
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <div>
          <img src={nothingThere} height='200' width='250' alt='Cart' />
        </div>
        <span className={classes.title}>{this.getLabel('nothing-there')}</span>
        <div className='grow' />
        <span className={classes.text}>{this.handleLabel()}</span>
      </div>
    )
  }
}

export default withStyles(styles)(injectIntl(NoResult))
