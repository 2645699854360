import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'

export const SearchButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    borderRadius: 24,
    height: 48,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.3,
    letterSpacing: 'normal',
    textTransform: 'capitalize',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
  },
}))(Button)
