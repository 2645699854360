import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { searchActions } from '../../../../actions'
import Fabs from './common/fabs'

const Dietaries = () => {
  const dispatch = useDispatch()

  const dietaries = useSelector(({ dietaries }) => dietaries.data, shallowEqual)
  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const resetDietary = (id) => dispatch(searchActions.removeDietarySearchFilter(id))

  return (
    <>
      {dietaries?.map((dietary, index) =>
        searchData.dietaryIds.includes(dietary.id) ? (
          <Fabs key={index} text={dietary.name} onClick={() => resetDietary(dietary.id)} />
        ) : null
      )}
    </>
  )
}

export default Dietaries
