import axios from 'axios'
import { merchantHeader, uiHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL

export const attributeService = {
  createAttribute,
  updateAttribute,
  menuAttributes,
  getAllAttributes,
  deleteAttribute,
  createAttributeValue,
  updateAttributeValue,
  deleteAttributeValue,
  attachToMenu,
  detachFromMenu,
}

function getAllAttributes(storeId) {
  return axios.get(`${API_URL}/store/${storeId}/attribute?page=0&size=50`, merchantHeader()).then((res) => {
    return res
  })
}
function createAttribute(storeId, attribute) {
  return axios.post(`${API_URL}/store/${storeId}/attribute`, attribute, merchantHeader()).then((res) => {
    return res
  })
}
function updateAttribute(storeId, attributeId, attribute) {
  return axios.put(`${API_URL}/store/${storeId}/attribute/${attributeId}`, attribute, merchantHeader()).then((res) => {
    return res
  })
}

function menuAttributes(storeId, menuId) {
  return axios.get(`${API_URL}/store/${storeId}/menu/${menuId}/attribute/ui?page=0&size=50`, uiHeader()).then((res) => {
    return res
  })
}

function deleteAttribute(storeId, attributeId) {
  return axios.delete(`${API_URL}/store/${storeId}/attribute/${attributeId}`, merchantHeader()).then((res) => {
    return res
  })
}

function createAttributeValue(storeId, attributeId, value) {
  return axios
    .post(`${API_URL}/store/${storeId}/attribute/${attributeId}/value`, value, merchantHeader())
    .then((res) => {
      return res
    })
}

function updateAttributeValue(storeId, attributeId, valueId, value) {
  return axios
    .put(`${API_URL}/store/${storeId}/attribute/${attributeId}/value/${valueId}`, value, merchantHeader())
    .then((res) => {
      return res
    })
}

function deleteAttributeValue(storeId, attributeId, valueId) {
  return axios
    .delete(`${API_URL}/store/${storeId}/attribute/${attributeId}/value/${valueId}`, merchantHeader())
    .then((res) => {
      return res
    })
}

function attachToMenu(storeId, menuId, attributeId) {
  return axios
    .post(`${API_URL}/store/${storeId}/menu/${menuId}/attribute/${attributeId}`, null, merchantHeader())
    .then((res) => {
      return res
    })
}
function detachFromMenu(storeId, menuId, attributeId) {
  return axios
    .delete(`${API_URL}/store/${storeId}/menu/${menuId}/attribute/${attributeId}`, merchantHeader())
    .then((res) => {
      return res
    })
}
