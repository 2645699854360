export const customerConstants = {
  CUSTOMER: 'TKFRSH_CSTMR',
  SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'USERS_SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',

  SIGNIN_REQUEST: 'USERS_SIGNIN_REQUEST',
  SIGNIN_SUCCESS: 'USERS_SIGNIN_SUCCESS',
  SIGNIN_FAILURE: 'USERS_SIGNIN_FAILURE',

  SIGNOUT: 'USERS_SIGNOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  SET_ADDRESS: 'SET_ADDRESS',
  SET_GEOLOCATION: 'SET_GEOLOCATION',

  GETALL_ADDRESSES_REQUEST: 'GETALL_ADDRESSES_REQUEST',
  GETALL_ADDRESSES_SUCCESS: 'GETALL_ADDRESSES_SUCCESS',
  GETALL_ADDRESSES_FAILURE: 'GETALL_ADDRESSES_FAILURE',

  CREATE_ADDRESS_REQUEST: 'CREATE_ADDRESS_REQUEST',
  CREATE_ADDRESS_SUCCESS: 'CREATE_ADDRESS_SUCCESS',
  CREATE_ADDRESS_FAILURE: 'CREATE_ADDRESS_FAILURE',

  DELETE_ADDRESS_REQUEST: 'DELETE_ADDRESS_REQUEST',
  DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_FAILURE: 'DELETE_ADDRESS_FAILURE',

  UPDATE_ADDRESS_REQUEST: 'UPDATE_ADDRESS_REQUEST',
  UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
  UPDATE_ADDRESS_FAILURE: 'UPDATE_ADDRESS_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  GETALL_FAVORITE_REQUEST: 'GETALL_FAVORITE_REQUEST',
  GETALL_FAVORITE_SUCCESS: 'GETALL_FAVORITE_SUCCESS',
  GETALL_FAVORITE_FAILURE: 'GETALL_FAVORITE_FAILURE',

  SAVE_FAVORITE_REQUEST: 'SAVE_FAVORITE_REQUEST',
  SAVE_FAVORITE_SUCCESS: 'SAVE_FAVORITE_SUCCESS',
  SAVE_FAVORITE_FAILURE: 'SAVE_FAVORITE_FAILURE',

  DELETE_FAVORITE_REQUEST: 'DELETE_FAVORITE_REQUEST',
  DELETE_FAVORITE_SUCCESS: 'DELETE_FAVORITE_SUCCESS',
  DELETE_FAVORITE_FAILURE: 'DELETE_FAVORITE_FAILURE',

  EMAIL_VERIFICATION_SUCCESS: 'EMAIL_VERIFICATION_SUCCESS',
  PHONE_VERIFICATION_SUCCESS: 'PHONE_VERIFICATION_SUCCESS',

  EMAIL_UPDATE_SUCCESS: 'EMAIL_UPDATE_SUCCESS',
  PHONE_UPDATE_SUCCESS: 'PHONE_UPDATE_SUCCESS',
  NAME_UPDATE_SUCCESS: 'NAME_UPDATE_SUCCESS',

  DEFAULT_CARD_CHANGE: 'DEFAULT_CARD_CHANGE',

  CHANGE_ADDRESS_STATE: 'CHANGE_ADDRESS',
}
