import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { IconButton } from '@material-ui/core'
import clsx from 'clsx'
import backIcon from '../../../images/black-back.svg'
import noResult from '../../../images/no-order.svg'
import { customerService } from '../../../services'
import { generalConstants } from '../../../constants'
import IntersectionVisible from 'react-intersection-visible'
import StoreCard from '../../common/StoreCard'

const height = window.innerHeight

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
    flexWrap: 'nowrap',
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  content: {
    overflow: 'scroll',
    flex: '1 50 auto',
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  noResult: {
    height: '100%',
    width: '100%',
  },
  item: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing() / 2,
    marginTop: theme.spacing() / 2,
  },
})

const TextSearchResults = ({ classes, intl, goBack, showSignin, searchResult, fetchMore, openStores }) => {
  const [favoriteStores, setFavoriteStores] = useState([])

  const searchText = useSelector((state) => state.search.searchQuery.searchText)
  const orderMenus = useSelector((state) => state.orders.cart.orderMenus, shallowEqual)
  const signedIn = useSelector((state) => state.customer.signedIn)

  useEffect(() => {
    getFavorites()
  }, [signedIn])

  const getFavorites = () => {
    if (signedIn) {
      customerService.getAllFavoriteStores().then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          const { content } = res.data
          favoriteStateMaker(content)
        }
      })
    }
  }

  const favoriteStateMaker = (data) => {
    //here we create a new local state level favorites store array
    let newStateArray = []
    data.forEach((elem) => {
      let newState = {}
      newState['entityId'] = elem.store.id
      newState['id'] = elem.id
      newStateArray.push(newState)
    })
    setFavoriteStores(newStateArray)
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const favoriteHandler = (id) => {
    if (!signedIn) {
      showSignin()
      return
    }
    // if favorites array has already incoming id, delete it, else add to the list
    let includes = false
    let storeId
    if (favoriteStores.length > 0) {
      favoriteStores.forEach((store) => {
        store.entityId === id && ([includes, storeId] = [true, store.id])
      })
    }
    includes ? deleteFavorite(storeId) : saveFavorite(id)
  }

  const deleteFavorite = (id) => {
    customerService.deleteFavoriteStore(id).then((res) => {
      if (res === generalConstants.STATUS_OK) {
        getFavorites()
      }
    })
  }

  const saveFavorite = (id) => {
    customerService
      .saveFavoriteStore(id)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          getFavorites()
        }
      })
      .catch((e) => console.log('e ---', e.response))
  }

  return (
    <div className={clsx('column', [classes.root])}>
      <div className='mobile-top-bar'>
        <IconButton size='small' className={classes.back} onClick={goBack}>
          <img src={backIcon} alt='Back' />
        </IconButton>
        <Typography variant='h6'>
          {getLabel('search-results-for')}'{searchText}'
        </Typography>
      </div>
      <div className={classes.content} style={{ paddingBottom: orderMenus.length > 0 ? 120 : 60 }}>
        {searchResult?.length === 0 ? (
          <div className={clsx('justify-content', [classes.noResult])}>
            <div className='centered-column'>
              <img src={noResult} alt='' width='210' height='205' />
              <Typography variant='h5' gutterBottom>
                {getLabel('no-result-found')}
              </Typography>
            </div>
          </div>
        ) : (
          <Grid container className={'vertical-scrolling-wrapper-flexbox'}>
            {searchResult?.map((store, index) => (
              <Grid item className={classes.item} key={store.id}>
                <IntersectionVisible onShow={() => index === searchResult?.length - 1 && fetchMore()}>
                  <StoreCard
                    data={store}
                    onPress={favoriteHandler}
                    favorites={favoriteStores}
                    storeOpen={openStores?.includes(store.id)}
                  />
                </IntersectionVisible>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(injectIntl(withRouter(TextSearchResults)))
