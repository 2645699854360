import React, { useState } from 'react'
import { Grid, makeStyles, TextField, Typography, withStyles } from '@material-ui/core'
import { FormattedMessage, injectIntl } from 'react-intl'
import { emailRegex } from '../../../utils/regex_collections'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import ArrowForward from '@material-ui/icons/ArrowForward'
import PropTypes from 'prop-types'
import { alertActions } from '../../../../actions'
import { useDispatch } from 'react-redux'

const EmailTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#f0f0f0',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
})(TextField)

const useStyles = makeStyles((theme) => ({
  subscribeTitle: {
    fontSize: 18,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#fff',
    marginBottom: theme.spacing(),
    "&[is-tablet='true']": {
      textAlign: 'center',
    },
  },
  subscribeText: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#fff',
    marginBottom: theme.spacing(),
    "&[is-tablet='true']": {
      textAlign: 'center',
      lineHeight: 2.14,
    },
  },
  email: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: '#fff',
    backgroundColor: '#000',
  },
  subscribeContainer: {
    width: '100%',
    display: 'flex',
    marginTop: 30,
    justifyContent: 'center',
  },
}))

const Subscribe = ({ intl, tabletView, isTablet }) => {
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [focused, setFocused] = useState(false)
  const [invalid, setInvalid] = useState(false)

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const onChangeHandler = (e) => {
    setFocused(true)
    setInvalid(!emailRegex.test(e.target.value))
    setEmail(e.target.value)
  }

  const dispatch = useDispatch()

  const handleSubscribeButton = () => {
    dispatch(alertActions.success("You are successfully subscribed to get the latest news from us."))
  }

  return (
    <Grid item lg={3} md={3} className={tabletView && classes.subscribeContainer}>
      <div className='column' style={{ maxWidth: tabletView && 384.5 }}>
        <Typography className={classes.subscribeTitle} is-tablet={isTablet}>
          <FormattedMessage
            id='subscribe-via-email'
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </Typography>
        <Typography className={classes.subscribeText} is-tablet={isTablet}>
          <FormattedMessage id='fill-form' />
        </Typography>
        <EmailTextField
          fullWidth
          variant='outlined'
          size='small'
          value={email}
          onChange={onChangeHandler}
          error={invalid && focused}
          placeholder={getLabel('email-address')}
          className={classes.email}
          InputProps={{
            classes: { input: classes.email },
            endAdornment: email && (
              <InputAdornment position='end'>
                <IconButton color='inherit' size='small' disabled={invalid} onClick={() => handleSubscribeButton()}>
                  <ArrowForward />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </Grid>
  )
}

Subscribe.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.array,
}

export default injectIntl(Subscribe)
