import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'
import { FormattedMessage } from 'react-intl'
import RowContainer from './RowContainer'
import {
  getDetailedOrderInfo,
  getPreviousOrderStatusLabel,
  mapsSelector,
} from '../../../../../../helpers/orders/order-details'
import { SearchFab } from '../../../../pickup/mobile-pickup/SearchFab'
import locationIcon from '../../../../../../images/pickup_location.svg'
import { deliveryTypes } from '../../../../../../constants'

const StatusDetail = ({ order, index, storeDetails }) => {
  const classes = useStyles()
  const isDelivery = order && order?.orderType === deliveryTypes.DELIVERY
  return (
    <RowContainer>
      <Grid item className={classes.orderStatus}>
        <Typography variant='h6' component='p' className={classes.order}>
          <FormattedMessage id={index === 1 ? 'order-upcoming' : getPreviousOrderStatusLabel(order?.status?.name)} />
        </Typography>
        <Typography variant='h6' component='p' className={classes.orderDeliveryDetail}>
          {index === 0 ? getDetailedOrderInfo(order) : `Order Number ${order.orderNumber}`}
        </Typography>
      </Grid>
      <Grid item className={classes.iconBlock}>
        {index === 1 && !isDelivery && (
          <SearchFab
            color='primary'
            aria-label='location'
            onClick={() => mapsSelector(storeDetails, order)}
            className={classes.fabIcon}
          >
            <img src={locationIcon} alt='' />
          </SearchFab>
        )}
      </Grid>
    </RowContainer>
  )
}

StatusDetail.propTypes = {
  index: PropTypes.number,
  order: PropTypes.object,
}

export default React.memo(StatusDetail)
