import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { BlackButton, RedButton } from '../../../utils/tools'
import { injectIntl } from 'react-intl'

const DeleteDialog = ({ showConfirm, handleConfirmClose, handleDelete, item, intl, text }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Dialog
      open={showConfirm}
      onClose={handleConfirmClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='delete-dialog-title'>{getLabel('are-you-sure')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{getLabel(text)}</DialogContentText>
        <DialogContentText id='alert-dialog-description'>{item}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <BlackButton onClick={handleConfirmClose}>{getLabel('cancel-label')}</BlackButton>
        <RedButton onClick={handleDelete}>{getLabel('delete-label')}</RedButton>
      </DialogActions>
    </Dialog>
  )
}

DeleteDialog.propTypes = {
  showConfirm: PropTypes.bool,
  handleConfirmClose: PropTypes.func,
  handleDelete: PropTypes.func,
  item: PropTypes.string,
  text: PropTypes.string,
  intl: PropTypes.object,
}

export default injectIntl(DeleteDialog)
