import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import { BlackButton, GreenButton } from '../../../utils/tools'

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 120,
    marginLeft: theme.spacing(),
  },
}))

const ActionButtons = ({ disableStatus, intl, discardChanges }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <div className='column'>
      <Divider style={{ marginBottom: 30 }} />
      <Grid container className='full-width'>
        <Grid item sm={12} xs={12} className='row'>
          <div className='grow' />
          <BlackButton className={classes.button} onClick={discardChanges}>
            {getLabel('discard-label')}
          </BlackButton>
          <GreenButton className={classes.button} disabled={disableStatus} type='submit'>
            {getLabel('save-label')}
          </GreenButton>
        </Grid>
      </Grid>
    </div>
  )
}

ActionButtons.propTypes = {
  disableStatus: PropTypes.bool,
  intl: PropTypes.object,
  discardChanges: PropTypes.func.isRequired,
}

export default injectIntl(ActionButtons)
