import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
// import menuImage from '../../../images/dummy-menu-item.png'
import Chip from '@material-ui/core/Chip'
import menuAdd from '../../../images/mobile-menu-add.png'
import { Box } from '@material-ui/core'
import { getDietaryIcon } from '../../../helpers/dietary-icon'
import { getImage } from '../../../helpers/get-image'
import clsx from 'clsx'

const width = window.innerWidth
const scaledWidth = width * 0.95
const imageWidth = 120

const useStyles = makeStyles({
  root: {
    width: scaledWidth,
    height: imageWidth,
    backgroundColor: '#fff',
    borderRadius: 6,
    display: 'flex',
    border: '1.5 solid',
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.15)',
  },
  boxRoot: {
    width: '100%',
    borderRadius: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.3,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#707070',
    // maxWidth: '14rem',
  },
  details: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    width: scaledWidth,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: imageWidth,
    width: scaledWidth - imageWidth,
    justifyContent: 'space-between',
    padding: 5,
    paddingRight: 0,
  },
  contentWithoutImg: {
    width: '100%',
    paddingRight: 5,
  },
  media: {
    backgroundColor: '#f7f7f7',
    height: imageWidth,
    width: imageWidth,
    minWidth: imageWidth,
    flex: 'initial',
  },
  button: {
    width: 26,
    height: 26,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 26,
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  add: {
    width: 32,
    height: 32,
    borderRadius: 22,
    backgroundColor: '#c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  // desc: {
  //   overflowWrap: 'break-word',
  //   wordWrap: 'break-word',
  //   hyphens: 'auto',
  // },
  selectedItem: {
    borderWidth: 1,
    borderColor: 'black',
  },
  longMenuName: {
    fontSize: 16,
  },
  dietaryIcon: {
    width: 20,
    height: 20,
  },
})

export default function MobileMenuItem({ data, alreadyOrderedCount, menuItemStore, handleClick }) {
  const classes = useStyles()
  const dietaryExist = data.dietaries.length > 0
  const showCardBorder = alreadyOrderedCount > 0 && menuItemStore
  const menuImg = data.images.length > 0 && getImage(data.images[0], 'thumb')

  return (
    <Box className={classes.boxRoot} border={2} borderColor={showCardBorder ? 'primary.main' : 'white'}>
      <Card className={classes.root} variation='outlined'>
        <CardActionArea className={classes.details} onClick={handleClick}>
          <CardContent
            className={clsx(classes.content, {
              [classes.contentWithoutImg]: data.images.length === 0 || !data.images,
            })}
          >
            <div>
              <div className='centered-row'>
                <Typography className={classes.title} noWrap>
                  {data.name}
                </Typography>
              </div>
              {dietaryExist && (
                <div className='centered-row' style={{ marginTop: 5 }}>
                  {data.dietaries.map((dietary, index) => (
                    <img
                      alt=''
                      key={index}
                      src={getDietaryIcon(dietary.name)}
                      width='20'
                      height='20'
                      style={{ marginLeft: 5 }}
                    />
                  ))}
                </div>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <Typography variant='body2' color='textPrimary' component='p' className='elipsis'>
                {data.description}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='body2' color='textSecondary' component='p'>
                ${data.price}
              </Typography>
            </div>
          </CardContent>

          {data.images.length > 0 && <CardMedia className={classes.media} image={menuImg} title={data.name} />}

          <div className={classes.button}>
            {alreadyOrderedCount !== 0 && menuItemStore ? (
              <Chip color='primary' label={alreadyOrderedCount} style={{ margin: 3 }} />
            ) : (
              <img src={menuAdd} alt='add' />
            )}
          </div>
        </CardActionArea>
      </Card>
    </Box>
  )
}

MobileMenuItem.propTypes = {
  data: PropTypes.object.isRequired,
  alreadyOrderedCount: PropTypes.number,
  handleClick: PropTypes.func,
}
