import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import CustomTip from './TipPart/CustomTip'
import { webTipButtons } from '../../../../constants/tip.constants'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { deliveryTypes } from '../../../../constants'

const clickedButton = {
  borderWidth: 1.5,
  borderColor: '#c4c4c4',
  height: 48,
  color: 'white',
}

const borderTopBottom = {
  borderTop: '1.5px solid #c4c4c4',
  borderBottom: '1.5px solid #c4c4c4',
}

const firstItemRad = {
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
}

const lastItemRad = {
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
}

const flexAuto = {
  width: '100%',
  height: 'auto',
  display: 'flex',
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...flexAuto,
    flexDirection: 'column',
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#707070',
    marginBottom: theme.spacing(),
  },
  gridContainer: {
    ...flexAuto,
  },
  clicked: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    "&[which-button='0.05']": {
      ...clickedButton,
      borderLeft: '1.5px solid #c4c4c4',
      ...firstItemRad,
    },
    "&[which-button='0.1']": {
      ...clickedButton,
    },
    "&[which-button='0.15']": {
      ...clickedButton,
      borderRight: '1.5px solid #c4c4c4',
      ...lastItemRad,
    },
  },
  button: {
    ...borderTopBottom,
    height: 48,
    color: theme.palette.primary.main,
    "&[which-button='0.05']": {
      borderLeft: '1.5px solid #c4c4c4',
      ...firstItemRad,
    },
    "&[which-button='0.1']": {},
    "&[which-button='0.15']": {
      borderRight: '1.5px solid #c4c4c4',
      ...lastItemRad,
    },
  },
}))

const TipPart = ({ handleTip, handleCustomTip, cost, tip, clearCustomTip }) => {
  const classes = useStyles()
  const deliveryType = useSelector(({ orders }) => orders.cart.type)

  if (deliveryType === deliveryTypes.TAKEOUT) return null

  return (
    <Box className={classes.root}>
      <Typography className={classes.sectionTitle}>{<FormattedMessage id='add-tip' />}</Typography>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={9}>
          <ButtonGroup
            style={{ marginRight: 24 }}
            fullWidth
            aria-label='large outlined primary button group'
            disableElevation
          >
            {webTipButtons.map((button, i) => (
              <Button
                key={i}
                onClick={() => handleTip(button.value)}
                which-button={button.value?.toString()}
                className={clsx(classes.button, { [classes.clicked]: tip.rate === button.value })}
              >
                {button?.label}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
        <CustomTip clearTip={clearCustomTip} handleTip={handleTip} handleCustomTip={handleCustomTip} tip={tip} />
      </Grid>
    </Box>
  )
}

TipPart.propTypes = {
  handleTip: PropTypes.func,
  cost: PropTypes.number,
  tip: PropTypes.object,
}

export default TipPart
