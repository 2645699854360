import { attributeConstants, generalConstants } from '../constants'
import { attributeService } from '../services'
import { alertActions } from './alert.actions'

export const attributeActions = {
  createAttribute,
  updateAttribute,
  getAllAttributes,
  changeSelectedAttribute,
  deleteAttribute,
  createAttributeValue,
  updateAttributeValue,
  deleteAttributeValue,
  clearState,
}

function createAttribute(storeId, data) {
  return (dispatch) => {
    dispatch(request())
    attributeService.createAttribute(storeId, data).then(
      (attribute) => {
        dispatch(success(attribute.data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure())
      }
    )
  }

  function request() {
    return { type: attributeConstants.CREATE_ATTRIBUTE_REQUEST, payload: null }
  }
  function success(attribute) {
    return { type: attributeConstants.CREATE_ATTRIBUTE_SUCCESS, attribute }
  }
  function failure() {
    return { type: attributeConstants.CREATE_ATTRIBUTE_FAILURE, payload: null }
  }
}

function updateAttribute(storeId, attributeId, data) {
  return (dispatch) => {
    dispatch(request())
    attributeService.updateAttribute(storeId, attributeId, data).then(
      (res) => {
        dispatch(success(res.data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure())
      }
    )
  }

  function request() {
    return { type: attributeConstants.UPDATE_ATTRIBUTE_REQUEST, payload: null }
  }
  function success(attribute) {
    return { type: attributeConstants.UPDATE_ATTRIBUTE_SUCCESS, attribute }
  }
  function failure() {
    return { type: attributeConstants.UPDATE_ATTRIBUTE_FAILURE, payload: null }
  }
}

function getAllAttributes(storeId) {
  return (dispatch) => {
    dispatch(request())
    attributeService.getAllAttributes(storeId).then(
      (res) => {
        dispatch(success(res.data.content))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure())
      }
    )
  }

  function request() {
    return { type: attributeConstants.GET_ATTRIBUTE_REQUEST, payload: null }
  }
  function success(attributes) {
    return { type: attributeConstants.GET_ATTRIBUTE_SUCCESS, attributes }
  }
  function failure() {
    return { type: attributeConstants.GET_ATTRIBUTE_FAILURE, payload: null }
  }
}

function deleteAttribute(storeId, attributeId) {
  return (dispatch) => {
    dispatch(request())
    attributeService.deleteAttribute(storeId, attributeId).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          dispatch(success(attributeId))
          dispatch(alertActions.success(res.data.toString()))
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure())
      }
    )
  }

  function request() {
    return { type: attributeConstants.DELETE_ATTRIBUTE_REQUEST, payload: null }
  }
  function success(attributeId) {
    return { type: attributeConstants.DELETE_ATTRIBUTE_SUCCESS, attributeId }
  }
  function failure() {
    return { type: attributeConstants.DELETE_ATTRIBUTE_FAILURE, payload: null }
  }
}

function createAttributeValue(storeId, attributeId, data) {
  return (dispatch) => {
    dispatch(request())
    attributeService.createAttributeValue(storeId, attributeId, data).then(
      (res) => {
        dispatch(success(attributeId, res.data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure())
      }
    )
  }

  function request() {
    return { type: attributeConstants.CREATE_VALUE_REQUEST, payload: null }
  }
  function success(attributeId, value) {
    return { type: attributeConstants.CREATE_VALUE_SUCCESS, value, attributeId }
  }
  function failure() {
    return { type: attributeConstants.CREATE_VALUE_FAILURE, payload: null }
  }
}

function updateAttributeValue(storeId, attributeId, valueId, value) {
  return (dispatch) => {
    dispatch(request())
    attributeService.updateAttributeValue(storeId, attributeId, valueId, value).then(
      (res) => {
        dispatch(success(res.data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure())
      }
    )
  }

  function request() {
    return { type: attributeConstants.UPDATE_VALUE_REQUEST, payload: null }
  }
  function success(value) {
    return { type: attributeConstants.UPDATE_VALUE_SUCCESS, value }
  }
  function failure() {
    return { type: attributeConstants.UPDATE_VALUE_FAILURE, payload: null }
  }
}
function deleteAttributeValue(storeId, attributeId, valueId) {
  return (dispatch) => {
    dispatch(request())
    attributeService.deleteAttributeValue(storeId, attributeId, valueId).then(
      (res) => {
        dispatch(success(valueId))
        dispatch(alertActions.success(res.data.toString()))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure())
      }
    )
  }

  function request() {
    return { type: attributeConstants.DELETE_VALUE_REQUEST, payload: null }
  }
  function success(valueId) {
    return { type: attributeConstants.DELETE_VALUE_SUCCESS, valueId }
  }
  function failure() {
    return { type: attributeConstants.DELETE_VALUE_FAILURE, payload: null }
  }
}

function changeSelectedAttribute(item) {
  return { type: attributeConstants.CHANGE_SELECTED_ATTRIBUTE, item }
}

function clearState() {
  return { type: attributeConstants.CLEAR_STATE, payload: null }
}
