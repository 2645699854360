import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import { Backdrop, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import { addEmployeeIsFocused, addEmployeeIsInvalid, addEmployeeUser, generalConstants } from '../../../constants'
import clsx from 'clsx'
import { emailRegex } from '../../utils/regex_collections'
import { useDispatch, useSelector } from 'react-redux'
import { storeService } from '../../../services'
import { alertActions } from '../../../actions'
import TextInput from './add-employee/TextInput'
import AssignRole from './add-employee/AssignRole'
import ActionButtons from './add-employee/ActionButtons'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    padding: theme.spacing(3),
  },
  label: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#121212',
    margin: theme.spacing(),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const AddEmployee = ({ intl, history }) => {
  const classes = useStyles()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()

  //Global states
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const selectedRestaurant = useSelector(({ merchantStores }) => merchantStores.selectedRestaurant)

  //Local states
  const [user, setUser] = useState(addEmployeeUser)
  const [isFocused, setIsFocused] = useState(addEmployeeIsFocused)
  const [isInvalid, setIsInvalid] = useState(addEmployeeIsInvalid)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const requestParams = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      restaurantId: selectedRestaurant.restaurantId,
      stores: [selectedStore.storeId],
    }
    await storeService
      .inviteEmloyee(requestParams)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          dispatch(alertActions.success(res.data.toString()))
          setLoading(false)
          history.push(`${path}/merchant/employees`)
        }
      })
      .catch((err) => {
        setLoading(false)
        dispatch(alertActions.error(err.response.data.message.toString()))
      })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const invalidCondition = name === 'email' ? !emailRegex.test(value) : !value
    setIsFocused({ ...isFocused, [name]: true })
    setIsInvalid({ ...isInvalid, [name]: invalidCondition })
    setUser({ ...user, [name]: value })
  }

  const handleRoleChange = (e) => {
    setUser({ ...user, role: e.target.name })
  }

  const discardChanges = () => {
    setUser(addEmployeeUser)
    // history.push(`${path}/merchant/employees`)
  }

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <form onSubmit={onFormSubmit}>
        <div className='page-header' style={{ marginTop: 70, width: '100%' }}>
          <Typography variant='h4'>{getLabel('add-employee')}</Typography>
        </div>
        <Grid container spacing={2} className='full-width'>
          <Grid item sm={12}>
            <Typography className={classes.label}>{getLabel('employee-basic-info')}</Typography>
          </Grid>
          <TextInput propss={{ isFocused, isInvalid, user, handleChange, field: 'firstName' }} />
          <TextInput propss={{ isFocused, isInvalid, user, handleChange, field: 'lastName' }} />
          <TextInput propss={{ isFocused, isInvalid, user, handleChange, field: 'email' }} />
        </Grid>
        <AssignRole user={user} handleRoleChange={handleRoleChange} />
        <ActionButtons user={user} text='send-invite' discard={discardChanges} />
      </form>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

AddEmployee.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(injectIntl(AddEmployee))
