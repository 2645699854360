/* eslint-disable no-unused-expressions */
import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import bg from '../../../images/delivery_details_bg.png'
import DeliveryDetails from './DeliveryDetails'
import MoreFilters from './more_filters'
import PropTypes from 'prop-types'
import { useMaxWidth } from '../../../hooks/useMaxWidth'
import { useCurrentWidth } from '../../../hooks/useCurrentWidth'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxHeight: 160,
    height: 160,
    background: `url(${bg})`,
    backgroundSize: 'auto',
    position: 'relative',
  },
  container: {
    position: 'absolute',
    top: 0,
    margin: 'auto',
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function Banner(props) {
  const classes = useStyles()

  const searchQuery = useSelector(({ search }) => search.searchQuery)

  let width = useCurrentWidth()
  let max_width = useMaxWidth(width, searchQuery?.deliveryTime)

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth='lg'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DeliveryDetails navbar={false} banner max_width={max_width} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MoreFilters showMoreFilters={props.showMoreFilters} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

Banner.propTypes = {
  showMoreFilters: PropTypes.bool,
}

export default withRouter(injectIntl(Banner))
