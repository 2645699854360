import React from 'react'
import PropTypes from 'prop-types'
import RowContainer from './RowContainer'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import { orderStatuses } from '../../../../../../constants'
import Typography from '@material-ui/core/Typography'
import StarIcon from '@material-ui/icons/Star'

export const OrderRating = ({ index, order, rateOrder }) => {
  const handleOrderRating = () => rateOrder(order)
  const showRateOrderButton = order?.status?.name === orderStatuses.DELIVERED && !order.ratingStatus
  const showOrderRating = order.ratingAverage > 0

  if (index !== 0 || order.status.name !== orderStatuses.DELIVERED) return null
  return (
    <RowContainer>
      {showRateOrderButton && (
        <Button fullWidth disabled={order.ratingStatus} onClick={handleOrderRating}>
          <FormattedMessage id='rate-your-order' />
        </Button>
      )}
      {showOrderRating && (
        <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          <Typography>
            <FormattedMessage id='your-rating-for-order' />
          </Typography>
          <span style={{ fontSize: 15, fontWeight: 600 }}>{order.ratingAverage}</span>
          <StarIcon fontSize='small' style={{ fill: 'gold' }} />
        </div>
      )}
    </RowContainer>
  )
}

OrderRating.propTypes = {
  index: PropTypes.number,
  order: PropTypes.object,
  rateOrder: PropTypes.func,
}

export default OrderRating
