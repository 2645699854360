import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Fab } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(),
    textTransform: 'capitalize',
    wordBreak: 'keep-all',
    boxShadow: 'none',
    backgroundColor: 'white',
    borderRadius: 10,
    height: 40,
    "&[data-content='searchtext']": {
      '&.MuiFab-extended': {
        width: 'fit-content',
        maxWidth: 200,
      },
    },
  },
  filter: {
    fontSize: 18,
    fontWeight: 'normal',
    "&[data-content='searchtext']": {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  closeIcon: {
    width: 15,
    height: 15,
    marginLeft: 5,
    transition: `all 200ms ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      transform: 'scale(1.5)',
      transition: `all 200ms ${theme.transitions.easing.easeIn}`,
    },
    "&[content-role='clear']": {
      width: 20,
      height: 20,
      '&:hover': {
        transform: 'scale(1.2)',
        transition: `all 200ms ${theme.transitions.easing.easeIn}`,
      },
    },
  },
}))

const Fabs = ({ text, onClick, dataContent }) => {
  const classes = useStyles()

  return (
    <Fab variant='extended' className={classes.margin} data-content={dataContent}>
      <div className={classes.filter} data-content={dataContent}>
        {text}
      </div>
      <CloseIcon className={classes.closeIcon} onClick={onClick} />
    </Fab>
  )
}

export default Fabs
