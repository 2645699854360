import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import { Route, Switch, withRouter } from 'react-router-dom'
import FoodMain from './food/FoodMain'
import Welcome from './welcome/WelcomePage'
import FoodSearch from './search/search'
import StoreMain from './store/store_main'
// import Checkout from './checkout/checkout'
import { categoryActions, dietaryActions, customerActions } from '../../actions'
import NoMatch from './no_match'
import Orders from './orders/OrdersMain'
import PaymentMethods from './account/payment/payment_methods'
import FavoriteRestaurants from './favorite-restaurants/favorite-restaurants'
import Account from './account/AccountMain'
import WebAddresses from './account/address/WebAddresses'
import MobileAddresses from './account/address/MobileAddresses'
import Profile from './account/profile'
import WebProfile from './account/WebProfile'
import Pickup from './pickup/MobilePickup'
import MobileSearchMain from './mobile-search/SearchMain'
import { CustomerPrivateRoute } from '../../routes'
import SigninPage from '../authentication/signin_page'
import MobileSignup from '../authentication/mobile_signup'
import SignupPage from '../authentication/signup_page'
import Promotions from './promotions/Promotions'
import Notifications from './notifications/Notifications'
import InviteFriends from './invite-friends/InviteFriends'
import GiftCards from './gift-cards/GiftCards'
import { isMobileOnly } from 'react-device-detect'
import SplashScreen from './splash/splash_screen'
import MobilePaymentMethods from './account/payment/mobile_payment_methods'
import CampaignPage from './campaigns/CampaignPage'
import DesktopFooterPages from './footerPages/DesktopFooterPages'
import MobileFooterPages from './footerPages/MobileFooterPages'

import '../../styles/customer_global.scss'
import AboutUs from './about-us/AboutUs'
import WebInviteFriends from './invite-friends/WebInviteFriends'
import WebAboutUs from './about-us/WebAboutUs'

const innerHeight = window.innerHeight

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    maxWidth: '100%',
    height: '100vh',
  },
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: innerHeight,
  },
})

class CustomerHome extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      renderSplashscreen: true,
    }
  }

  async componentDidMount() {
    await this.props.getCategories()
    await this.props.getDietaries()
    this.props.signedIn && this.props.getFavorites()
    this.setState({ renderSplashscreen: false })
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.signedIn !== this.props.signedIn && this.props.signedIn) {
      await this.props.getFavorites()
    }
  }

  reload = () => window.location.reload()

  render() {
    const { classes } = this.props

    const path = this.props.match.path

    return (
      <div className={isMobileOnly ? classes.mobileRoot : classes.root}>
        {this.state.renderSplashscreen ? (
          <SplashScreen />
        ) : (
          <Switch>
            {/* <Route path={`${path}/signin`} component={isMobileOnly ? MobileSignin : SigninPage} /> */}
            <Route path={`${path}/signin`} component={SigninPage} />
            <Route path={`${path}/signup/:reference?`} component={isMobileOnly ? MobileSignup : SignupPage} />

            <CustomerPrivateRoute exact path={`${path}/account`} component={Account} />
            <CustomerPrivateRoute
              exact
              path={`${path}/account/addresses`}
              component={isMobileOnly ? MobileAddresses : WebAddresses}
            />
            <CustomerPrivateRoute
              exact
              path={`${path}/account/profile`}
              component={isMobileOnly ? Profile : WebProfile}
            />
            <CustomerPrivateRoute exact path={`${path}/account/favorite-restaurants`} component={FavoriteRestaurants} />
            <CustomerPrivateRoute
              exact
              path={`${path}/account/payment-methods`}
              component={isMobileOnly ? MobilePaymentMethods : PaymentMethods}
            />
            {/* <CustomerPrivateRoute
              exact
              path={`${path}/account/mobilepaymentmethods`}
              component={MobilePaymentMethods}
            /> */}
            <CustomerPrivateRoute exact path={`${path}/promotions`} component={Promotions} />
            <CustomerPrivateRoute exact path={`${path}/notifications`} component={Notifications} />
            {/* <CustomerPrivateRoute
              exact
              path={`${path}/invite-friends`}
              component={isMobileOnly ? InviteFriends : WebInviteFriends}
            /> */}
            <CustomerPrivateRoute exact path={`${path}/gift-cards`} component={GiftCards} />
            <CustomerPrivateRoute exact path={`${path}/orders`} component={Orders} />
            {/* <Route exact path={`${path}/checkout`} component={Checkout} /> */}
            <Route exact path={`${path}/campaigns/:id`} component={CampaignPage} />
            <Route exact path={`${path}/search`} component={isMobileOnly ? MobileSearchMain : FoodSearch} />
            <Route
              exact
              path={`${path}/food`}
              component={FoodMain}
              // component={() => (address ? <FoodMain /> : <Redirect to={`${path}`} />)}
            />
            <Route exact path={`${path}/pickup`} component={Pickup} />
            <Route exact path={`${path}/read-blog`} component={isMobileOnly ? MobileFooterPages : DesktopFooterPages} />
            <Route
              exact
              path={`${path}/buy-gift-card`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            <Route
              exact
              path={`${path}/create-business-account`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            <Route
              exact
              path={`${path}/add-your-restaurant`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            <Route
              exact
              path={`${path}/signup-to-delivery`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            <Route exact path={`${path}/read-faq`} component={isMobileOnly ? MobileFooterPages : DesktopFooterPages} />
            <Route
              exact
              path={`${path}/view-cities`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            <Route
              exact
              path={`${path}/view-countries`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            <Route
              exact
              path={`${path}/restaurants-near-me`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            <Route
              exact
              path={`${path}/support-center`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            <Route
              exact
              path={`${path}/privacy-policy`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            <Route
              exact
              path={`${path}/terms-and-conditions`}
              component={isMobileOnly ? MobileFooterPages : DesktopFooterPages}
            />
            {/* <Route exact path={`${path}/search`} component={MobileSearchMain} /> */}
            <Route exact path={`${path}/store/:id?`} component={withRouter(StoreMain)} />
            <Route exact path={`${path}/about-us`} component={isMobileOnly ? AboutUs : WebAboutUs} />
            <Route exact path={`${path}`} component={Welcome} />
            <Route path='*' component={NoMatch} />
          </Switch>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    address: state.search.searchQuery.address,
    signedIn: state.customer.signedIn,
  }
}

const actionCreators = {
  getCategories: categoryActions.getAll,
  getDietaries: dietaryActions.getAll,
  getFavorites: customerActions.getAllFavoriteStores,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(CustomerHome))))
