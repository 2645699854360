import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import TopNavbar from '../top_navbar'
import Container from '@material-ui/core/Container'
import Banner from '../delivery_banner/banner'
import SearchFilter from './search_filter'
import Typography from '@material-ui/core/Typography'
import Footer from '../Footers'
import FilterBox from './filterBox'
import ScrollComponent from './scroll_coponent'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import MenuIcon from '@material-ui/icons/Menu'
import Slide from '@material-ui/core/Slide'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#fff',
    height: '100vh',
  },
  search: {
    minWidth: 220,
    marginRight: 20,
  },
  contentText: {
    flexGrow: 1,
    paddingTop: 10,
  },
  drawer: {
    borderTopRightRadius: 8,
    width: 300,
    paddingRight: 20,
    paddingLeft: 20,
  },
})

class FoodSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      storeCount: '',
      innerWidth: window.innerWidth,
      openDrawer: false,
    }
  }

  handleResize = () => {
    this.setState({ innerWidth: window.innerWidth })
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  storeCountHandler(count) {
    this.setState({ storeCount: count })
  }

  toggleDrawer = () => this.setState({ openDrawer: !this.state.openDrawer })

  render() {
    const { classes } = this.props
    const { storeCount, innerWidth, openDrawer } = this.state
    return (
      <div className={classes.root}>
        <Container maxWidth='lg'>
          <TopNavbar cartVisible={true} />
        </Container>
        <Container maxWidth={false} disableGutters>
          <Banner />
        </Container>
        <Container maxWidth='lg'>
          <div className='row'>
            {innerWidth < 840 ? (
              <Slide direction='right' in={openDrawer} mountOnEnter unmountOnExit>
                <div className={classes.search}>
                  <SearchFilter />
                </div>
              </Slide>
            ) : (
              <div className={classes.search}>
                <SearchFilter />
              </div>
            )}
            <div className={classes.contentText}>
              {storeCount > 0 && (
                <div className='centered-row'>
                  {innerWidth < 840 &&
                    (openDrawer ? (
                      <IconButton onClick={this.toggleDrawer}>
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={this.toggleDrawer}>
                        <MenuIcon />
                      </IconButton>
                    ))}
                  <Typography
                    style={{
                      fontSize: openDrawer ? 20 : 40,
                      fontWeight: 'bold',
                      transition: 'font-size 0.3s linear',
                      transitionDelay: !openDrawer && '0.2s',
                    }}
                  >
                    {storeCount} matching store(s)
                  </Typography>
                </div>
              )}
              <FilterBox drawer={openDrawer} />
              <ScrollComponent
                storeCount={this.storeCountHandler.bind(this)}
                drawer={openDrawer}
                width={innerWidth < 840}
              />
            </div>
          </div>
        </Container>
        <div className='grow' />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { search, customer } = state

  return {
    searchResult: search.searchResult.stores,
    filtersApplied: search.searchQuery,
    signedIn: customer.signedIn,
  }
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps)(FoodSearch))))
