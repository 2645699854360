import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  suggestion: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
  },
  recent: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    color: '#707070',
    textTransform: 'none',
    textDecoration: 'underline',
  },
}))

const Suggestions = ({ keyword, search }) => {
  const classes = useStyles()

  if (keyword.length === 0) return null

  return (
    <div className={clsx('centered-row full-width', classes.suggestion)}>
      <Button className={classes.recent} onClick={search}>{`Search with ${keyword}`}</Button>
    </div>
  )
}

Suggestions.propTypes = {
  keyword: PropTypes.string,
  search: PropTypes.func,
}

export default Suggestions
