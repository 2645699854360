import axios from 'axios'
import { merchantHeader, uiHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL

export const categoryService = {
  create,
  getAll,
  get,
  update,
}

function create(name) {
  let config = merchantHeader()
  config.data = { name: name }

  return axios
    .post(`${API_URL}/category`, config)
    .then(handleResponse)
    .then((category) => {
      return category
    })
}

function getAll() {
  return axios.get(`${API_URL}/category/ui`, uiHeader()).then((categories) => {
    return categories.data
  })
}

function get(id) {
  return axios
    .get(`${API_URL}/category/${id}`, merchantHeader())
    .then(handleResponse)
    .then((category) => {
      return category
    })
}

function update(id, name) {
  let config = merchantHeader()
  config.data = { name: name }

  return axios
    .put(`${API_URL}/category/${id}`, config)
    .then(handleResponse)
    .then((category) => {
      return category
    })
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
