/* eslint-disable no-unused-expressions */
import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { searchActions } from '../../../../actions'
import PropTypes from 'prop-types'
import close from '../../../../images/close.svg'
import Chip from '@material-ui/core/Chip'
import { GreenButton } from '../../../utils/tools'
import clsx from 'clsx'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
    fontSize: 16,
  },
  selected: {
    color: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
})

class DietaryFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dietaryIds: [],
    }
  }

  componentDidMount() {
    this.setState({ dietaryIds: this.props.dietaryIds })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dietaryIds !== this.props.dietaryIds) {
      this.setState({ dietaryIds: this.props.dietaryIds })
    }
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleDietaryChange = (id) => {
    let ids = this.state.dietaryIds

    if (ids.some((d) => d === id)) {
      // eslint-disable-next-line eqeqeq
      this.setState({ dietaryIds: ids.filter((d) => d != id) })
    } else {
      this.setState({ dietaryIds: [...ids, id] })
    }
  }

  handleDietaryClose = () => {
    this.setState({ dietaryIds: this.props.dietaryIds })
    this.props.handleClose('dietary')
  }

  applyDietaryChange = () => {
    const { setDietaryFilters, handleClose, resetItems } = this.props
    setDietaryFilters(this.state.dietaryIds)
    handleClose('dietary')
    resetItems()
  }

  render() {
    const { classes, dietaries } = this.props
    const { dietaryIds } = this.state

    return (
      <Grid className={classes.root} container>
        <Grid item xs={12} className='flex-container flex-end'>
          <IconButton onClick={this.handleDietaryClose} size='small'>
            <img src={close} height='22' width='22' alt='close' />
          </IconButton>
        </Grid>
        <Grid item xs={12} className='flex-container flex-center'>
          <Typography align='center' variant='h3'>
            {this.getLabel('dietaries-label')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.row} style={{ marginTop: 20, marginBottom: 20 }}>
          {dietaries &&
            this.props.dietaries.map((dietary) => (
              <Chip
                clickable
                className={clsx(classes.chip, {
                  [classes.selected]: dietaryIds.includes(dietary.id),
                })}
                variant={dietaryIds.includes(dietary.id) ? 'default' : 'outlined'}
                color={dietaryIds.includes(dietary.id) ? 'primary' : 'default'}
                key={dietary.id}
                label={dietary.name}
                onClick={() => this.handleDietaryChange(dietary.id)}
              />
            ))}
        </Grid>
        <Grid item xs={12}>
          <GreenButton fullWidth onClick={this.applyDietaryChange}>
            {this.getLabel('view-label')}
          </GreenButton>
        </Grid>
      </Grid>
    )
  }
}

DietaryFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const { search, dietaries } = state
  const { dietaryIds } = search.searchQuery

  return {
    dietaries: dietaries.data,
    dietaryIds,
  }
}

const actionCreators = {
  setDietaryFilters: searchActions.setDietaryFilters,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(DietaryFilter))))
