import React, { useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Card, IconButton, Typography } from '@material-ui/core'

import closeIconButton from '../../../../../images/close-icon.svg'
import placeHolder from '../../../../../images/logo-bg-green.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 450,
    maxWidth: 450,
    height: 330,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '-webkit-box-shadow': 'inset 0 -20px 20px -10px rgba(0,0,0,.2)',
    '-moz-box-shadow': 'inset 0 -20px 20px -10px rgba(0,0,0,.2)',
    'box-shadow': 'inset  0 -20px 20px -10px rgba(0,0,0,.2)',
    userSelect: 'none',
  },
  dialogIconButtonContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  imageContainer: {
    width: '100%',
    maxHeight: 200,
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    contain: 'content',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    // backgroundColor: '#F8F9FA',
  },
  title: {
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: '#000',
    fontSize: 20,
  },
}))

const StoreMenuDialogSample = ({ img, item }) => {
  const classes = useStyles()
  const image = useMemo(
    () =>
      img ? (
        <img src={img} alt='img' width='450px' height='450px' />
      ) : (
        <img src={placeHolder} alt='placeholder' height='180px' />
      ),
    [img]
  )

  return (
    <Card className={classes.root}>
      <div className={classes.dialogIconButtonContainer}>
        <IconButton disabled>
          <img src={closeIconButton} alt='close-icon' height='20px' width='20px' />
        </IconButton>
      </div>
      <div className={classes.imageContainer}>{image}</div>
      <div
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          paddingLeft: 20,
          marginTop: 20,
        }}
      >
        <Typography variant='h5' component='h5' className={classes.title}>
          {item.name}
        </Typography>
      </div>
    </Card>
  )
}

export default StoreMenuDialogSample
