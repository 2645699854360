import { initialQuery } from '../../constants'

const hasFiltersChanged = (prevProps, nextProps) => {
  const prevPropsStringified = JSON.stringify(prevProps)
  const nextPropsStringified = JSON.stringify(nextProps)
  if (prevPropsStringified !== nextPropsStringified) {
    return true
  }
  return false
}

export { hasFiltersChanged }

export const isThereAnyDifference = (query) => {
  if (
    query.categoryIds.length === initialQuery.categoryIds.length &&
    query.dietaryIds.length === initialQuery.dietaryIds.length &&
    query.pricey === initialQuery.pricey &&
    query.rating === initialQuery.rating &&
    query.distance === initialQuery.distance &&
    query.orderByKey === initialQuery.orderByKey &&
    query.orderByValue === initialQuery.orderByValue &&
    query.deliveryType === initialQuery.deliveryType &&
    query.onlyOpen === initialQuery.onlyOpen
  ) {
    return false
  }
  return true
}
