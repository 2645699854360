import { makeStyles } from '@material-ui/core'
import { height } from '../../../helpers'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
  },
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
  generalDialogRoot: {
    width: 315,
    height: 463,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '15px 10px 15px 10px',
  },
}))
