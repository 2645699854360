import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Grid, TextField } from '@material-ui/core'

const Email = ({ storeInfo, isFocused, isInvalid, intl, emailHandler, editting }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item sm={6} xs={12}>
      <TextField
        size='small'
        autoComplete='email'
        variant='outlined'
        required
        fullWidth
        id='email'
        label={getLabel('email-label-short')}
        value={storeInfo.email ? storeInfo.email : ''}
        inputProps={{ readOnly: !editting }}
        onChange={emailHandler}
        error={isFocused.email && isInvalid.email}
        helperText={isFocused.email && isInvalid.email ? getLabel('invalid-email') : ' '}
      />
    </Grid>
  )
}

Email.propTypes = {
  storeInfo: PropTypes.object,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  intl: PropTypes.object,
  emailHandler: PropTypes.func.isRequired,
  editting: PropTypes.bool,
}

export default injectIntl(Email)
