const handleDeleteButtonDisableStatus = (index, restaurant) => {
  if (index === 0 && restaurant?.logo) {
    const { path, filename, extension } = restaurant?.logo
    if (path && filename && extension) return false
  } else if (index === 1 && restaurant?.background) {
    const { path, filename, extension } = restaurant?.background
    if (path && filename && extension) return false
  }
  return true
}

export { handleDeleteButtonDisableStatus }
