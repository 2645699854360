import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import TfSwitch from '../../../common/TfSwitch'

import { searchActions } from '../../../../actions'

const OpenFilter = () => {
  const dispatch = useDispatch()

  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)

  const toggleOpenFilter = () => dispatch(searchActions.toggleOpenFilter())

  const [onlyOpen, setOnlyOpen] = useState(false)
  useEffect(() => {
    let active = true
    if (active) {
      setOnlyOpen(searchData?.onlyOpen)
    }
    return () => {
      active = false
    }
  }, [searchData])

  const renderSwitch = useMemo(
    () => (
      <TfSwitch
        value={onlyOpen}
        firstLabel='all-label'
        secondLabel='only-open-stores'
        handleSwitch={toggleOpenFilter}
      />
    ),
    [onlyOpen]
  )

  return <>{renderSwitch}</>
}

export default OpenFilter
