import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Grid, TextField } from '@material-ui/core'
import { CustomFormatCurrency } from '../../../utils/tools'

const MinPrice = ({ store, isFocused, isInvalid, intl, minOrderHandler, editting }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item sm={6} xs={12}>
      <TextField
        size='small'
        variant='outlined'
        required
        fullWidth
        id='minorder'
        name='minOrderPrice'
        label={getLabel('min-order-price')}
        value={store.minOrderPrice}
        onChange={minOrderHandler}
        inputProps={{
          maxLength: 150,
          readOnly: !editting,
        }}
        InputProps={{
          inputComponent: CustomFormatCurrency,
        }}
        error={isFocused.minOrderPrice && isInvalid.minOrderPrice}
        helperText={isFocused.minOrderPrice && isInvalid.minOrderPrice ? getLabel('cannot-null') : ' '}
      />
    </Grid>
  )
}

MinPrice.propTypes = {
  store: PropTypes.object,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  intl: PropTypes.object,
  minOrderHandler: PropTypes.func.isRequired,
  editting: PropTypes.bool,
}

export default injectIntl(MinPrice)
