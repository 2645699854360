import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { isTablet } from 'react-device-detect'

const imageHeight = isTablet ? 60 : 66
const width = 316

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    display: 'flex',
    width: '100%',
  },
  imageContainer: {
    minWidth: isTablet ? 290 : 316,
    height: isTablet ? 183.5 : 200,
    margin: 0,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  imageHoldings: {
    display: 'flex',
    zIndex: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: imageHeight,
    paddingRight: 10,
    width: width,
    position: 'absolute',
    bottom: theme.spacing(1),
  },
  imageInfoContainer: {
    paddingRight: 10,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    height: imageHeight + 5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: imageHeight,
    minWidth: imageHeight,
    justifyContent: 'center',
  },
  description: {
    fontFamily: 'Roboto',
    fontSize: isTablet ? '12px' : '14px',
    lineHeight: 1.07,
    letterSpacing: '0.3px',
    textAlign: 'left',
    color: '#ffffff',
    paddingBottom: 0,
    marginBottom: -5,
  },
  storeName: {
    fontFamily: 'Roboto',
    fontSize: isTablet ? '28px' : '32px',
    fontWeight: 'bold',
    lineHeight: 1.25,
    textAlign: 'left',
    color: '#ffffff',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const ImageContainer = ({ restImage, restLogo, order }) => {
  const classes = useStyles()
  return (
    <div className={classes.imageContainer}>
      <img src={restImage} alt='background' width='100%' height='100%' style={{ filter: 'brightness(60%)' }} />

      <Grid xs={12} item className={classes.imageHoldings}>
        <Grid item xs={3} className={classes.logoContainer}>
          <img src={restLogo} alt='logo' width={imageHeight} height={imageHeight} />
        </Grid>
        <Grid item xs={9} className={classes.imageInfoContainer}>
          <div className={classes.fullWidth}>
            <Typography className={classes.storeName}>{order.store.nickname}</Typography>
          </div>
          <div className={classes.fullWidth}>
            <Typography className={classes.description}>{order?.restaurant?.description}</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(ImageContainer)
