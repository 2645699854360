import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import CustomizedPhoneInput from './Common/CustomizedPhoneInput'
import { emailRegex } from '../../../utils/regex_collections'

import { personalDetailsData, valueConstants, labelConstants } from '../../businessConstants'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  gridItem: {
    marginBottom: theme.spacing(2),
  },
}))
const PersonalDetails = ({ handleChange, values, error, setValues }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <Container maxWidth='md' className={classes.root}>
      <Grid container spacing={2} className='full-width'>
        {personalDetailsData.map((item, index) =>
          item.value === valueConstants.phoneNo ? (
            <Grid key={index} item md={item.width} sm={item.width} xs={12} className={classes.gridItem}>
              <CustomizedPhoneInput
                valueName={valueConstants.phoneNo}
                values={values}
                item={item}
                setValues={setValues}
                label={f({ id: labelConstants.phoneNoStar })}
                error={error}
              />
            </Grid>
          ) : (
            <Grid key={index} item md={item.width} sm={item.width} xs={12} className={classes.gridItem}>
              <TextField
                size='small'
                value={values[item.value]}
                variant='outlined'
                fullWidth
                id={item.value}
                InputLabelProps={{ shrink: true }}
                label={f({ id: item.label })}
                required={item.value !== valueConstants.businessRole && true}
                error={
                  item.value !== valueConstants.businessRole && error && values[item.value].length === 0
                    ? true
                    : item.value === valueConstants.email && values.email.length > 0
                    ? !emailRegex.test(values.email)
                    : false
                }
                onChange={handleChange(item.value)}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Grid>
          )
        )}
      </Grid>
    </Container>
  )
}

PersonalDetails.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  error: PropTypes.bool,
  setValues: PropTypes.func,
}

export default PersonalDetails
