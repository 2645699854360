import { uniqueItems } from './DeleteRepeateds'

const handleWorkingDays = (workingHours) => {
  let days = []
  if (workingHours) {
    for (const wh of workingHours) {
      days.push(wh.day)
    }
    const uniqElementsArray = uniqueItems(days)
    return sortWorkingDays(uniqElementsArray)
  }
}

const sortWorkingDays = (arr) => arr.sort((a, b) => map[a] - map[b])

const map = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
}

export { handleWorkingDays }
