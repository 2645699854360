import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputBase from '@material-ui/core/InputBase'
import Checkbox from '@material-ui/core/Checkbox'
import NumberFormat from 'react-number-format'
import Rating from '@material-ui/lab/Rating'
import Slider from '@material-ui/core/Slider'
import Fab from '@material-ui/core/Fab'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import StepConnector from '@material-ui/core/StepConnector'

export const GreenButton = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    color: 'white',
    height: 48,
    borderRadius: 10,
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    '&:disabled': {
      backgroundColor: '#f7f7f7',
      zIndex: 2,
    },
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
}))(Button)

export const RedButton = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    color: 'white',
    height: 48,
    borderRadius: 10,
    fontSize: 18,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: 'none',
    },
  },
}))(Button)

export const BlackButton = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    height: 48,
    borderRadius: 10,
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'black',
    boxShadow: 'none',
    '&:disabled': {
      backgroundColor: 'black',
      color: 'gray',
    },
    '&:hover': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: 'black',
      boxShadow: 'none',
    },
  },
}))(Button)

export const BlackActionButton = withStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'capitalize',
    color: 'white',
    backgroundColor: 'black',
    boxShadow: 'none',
    '&:disabled': {
      backgroundColor: 'black',
      color: 'gray',
    },
    '&:hover': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: 'black',
      boxShadow: 'none',
    },
  },
}))(Button)

export const RedActionButton = withStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'capitalize',
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none',
    '&:disabled': {
      backgroundColor: theme.palette.secondary.main,
      color: 'gray',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: 'none',
    },
  },
}))(Button)

export const WhiteButton = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    color: 'white',
    border: '1px solid white',
    boxShadow: 'none',
    '&:disabled': {
      color: 'gray',
    },
    '&:hover': {
      color: 'white',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
}))(Button)

export const LeftLabelButton = withStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.56,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#c4c4c4',
    textTransform: 'capitalize',
    height: 40,
    boxShadow: 'none',
    '&:focus': {
      boxShadow: 'none',
    },
  },
  label: {
    justifyContent: 'left',
  },
}))(Button)

export const GreenRadio = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props) => <Radio color='default' {...props} />)

export const GreenCheckbox = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color='default' {...props} />)

export const RedCheckbox = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.secondary.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color='default' {...props} />)

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    width: 70,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

export function CustomFormatCurrency(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      fixedDecimalScale={true}
      decimalScale={2}
      allowNegative={false}
      thousandSeparator
      isNumericString
      prefix='$'
    />
  )
}

CustomFormatCurrency.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
export function CustomFormatCurrencyNew(props) {
  const { inputRef, onChange, ...other } = props
  const MAX_VAL = 999

  const withValueLimit = ({ value }) => +value <= MAX_VAL

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalSeparator={false}
      allowNegative={false}
      isNumericString
      isAllowed={withValueLimit}
      allowEmptyFormatting
      prefix={'$'}
      allowLeadingZeros={false}
      type='text'
      displayType='input'
    />
  )
}

CustomFormatCurrencyNew.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export function CustomFormatNumber(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
      allowNegative={false}
      allowLeadingZeros={false}
      defaultValue={1}
    />
  )
}

CustomFormatNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const LeftTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />
})

export const GreenRating = withStyles((theme) => ({
  iconFilled: {
    color: theme.palette.primary.main,
  },
}))(Rating)

export const BlackFab = withStyles((theme) => ({
  root: {
    textTransform: 'capitalize',
    color: 'white',
    backgroundColor: '#000',
    boxShadow: 'none',
    '&:disabled': {
      backgroundColor: '#000',
    },
    '&:hover': {
      backgroundColor: '#121111',
    },
    '&:focus': {
      backgroundColor: '#121111',
      boxShadow: 'none',
    },
  },
}))(Fab)

export const TFSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 8,
    // "&[content-name-value='ratingnull']": {
    //   opacity: '.5',
    // },
  },
  thumb: {
    height: 24,
    width: 24,
    // backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: '#fff',
    height: 2,
    width: 2,
    marginTop: 3,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  markLabel: {
    marginTop: 8,
  },
}))(Slider)

export const TFDialog = withStyles((theme) => ({
  paper: {
    position: 'absolute',
    bottom: 0,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    margin: 0,
    width: '100%',
    maxWidth: '100%',
  },
}))(Dialog)

export const TFTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 10,
        // height: 48,
      },
    },
    '& .MuiOutlinedInput-input': {
      // padding: 13,
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
  },
})(TextField)

export const GreenConnector = withStyles({
  active: {
    '& $line': {
      borderColor: '#69e781',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#69e781',
    },
  },
  line: {
    borderColor: '#bdbdbd',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)
