const marksPrice = () => {
  let marks = []
  let img = '$'

  for (let i = 1; i <= 4; i++) {
    marks.push({
      value: i,
      label: img.repeat(i),
    })
  }
  return marks
}

export { marksPrice }
