import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { injectIntl } from 'react-intl'
import { appStoresMenu } from '../../../../constants/footer/web-footer'
import { WhiteButton } from '../../../utils/tools'
import footerLogo from '../../../../images/Tablet/FooterLogo.png'

const useStyles = makeStyles((theme) => ({
  appTitle: {
    fontSize: 28,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#fff',
    marginBottom: 30,
  },
  appColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 220,
    },
    "&[is-tablet='true']": {
      marginBottom: 29.6,
    },
  },
  appButton: {
    marginBottom: theme.spacing(),
    "&[is-tablet='true']": {
      width: 163,
      marginRight: 10,
    },
  },
}))

const AppStoresLink = ({ intl, isTablet, tabletView }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item lg={5} md={3}>
      <div className={classes.appColumn} is-tablet={isTablet}>
        {/* <Typography className={classes.appTitle}>{getLabel('order-food-in-app')}</Typography> */}
        <div
          style={{
            display: 'flex',
            flexDirection: tabletView ? 'row' : 'column',
            width: tabletView ? 337 : '100%',
          }}
        >
          {/* {appStoresMenu.map((store, i) => (
            <WhiteButton
              key={i}
              variant='outlined'
              className={classes.appButton}
              startIcon={<img src={store.icon} alt={store.alt} height='25' width='21' />}
              is-tablet={isTablet}
            >
              {getLabel(store.label)}
            </WhiteButton>
          ))} */}
        </div>
      </div>
    </Grid>
  )
}

export default injectIntl(AppStoresLink)
