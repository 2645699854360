import { storeConstants } from '../constants'
import { storeService } from '../services/store.service'
import { alertActions } from './alert.actions'
import { history } from '../helpers'

const path = process.env.PUBLIC_URL

export const storeActions = {
  // createStore,
  updateStore,
  // deleteStore,
  getAll,
  customerStoreSearch,
  findStore,
  findLocals,
  searchByCategory,
  searchWithNewCategory,
  removeCategorySearchFilter,
  searchWithNewDietary,
  removeDietarySearchFilter,
  setPriceyFilter,
  setRatingFilter,
  setDistanceFilter,
  setSearchSortBy,
  setSearchTextFilter,
  setMoreFilters,
  setDeliveryType,
  setWhenDetail,
  setWhenDetailDetail,
  setCurrentStore,
}

// function createStore(restaurantId, data) {
//   return (dispatch) => {
//     dispatch(request({ restaurantId }))

//     storeService
//       .create(restaurantId, data)
//       .then(
//         (store) => {
//           dispatch(success(store))
//         },
//         (error) => {
//           dispatch(failure(error.toString()))
//           dispatch(alertActions.error(error.toString()))
//         }
//       )
//       .then(() => history.push(`${path}/merchant/stores`))
//   }

//   function request(restaurantId) {
//     return { type: storeConstants.CREATE_REQUEST, restaurantId }
//   }
//   function success(store) {
//     return { type: storeConstants.CREATE_SUCCESS, store }
//   }
//   function failure(error) {
//     return { type: storeConstants.CREATE_FAILURE, error }
//   }
// }

function updateStore(restaurantId, data, storeId) {
  return (dispatch) => {
    dispatch(request({ restaurantId }))

    storeService
      .update(restaurantId, data, storeId)
      .then(
        (store) => {
          dispatch(success(store))
        },
        (error) => {
          dispatch(failure(error.toString()))
          dispatch(alertActions.error(error.toString()))
        }
      )
      .then(() => history.push(`${path}/merchant/stores`))
  }

  function request(restaurantId) {
    return { type: storeConstants.UPDATE_REQUEST, restaurantId }
  }
  function success(store) {
    return { type: storeConstants.UPDATE_SUCCESS, store }
  }
  function failure(error) {
    return { type: storeConstants.UPDATE_FAILURE, error }
  }
}

// function deleteStore(restaurantId, storeId) {
//   return (dispatch) => {
//     dispatch(request({ storeId }))

//     storeService.deleteStore(restaurantId, storeId).then(
//       (res) => {
//         if (res === generalConstants.STATUS_OK) dispatch(success(storeId))
//       },
//       (error) => {
//         dispatch(failure(error.toString()))
//         dispatch(alertActions.error(error.toString()))
//       }
//     )
//   }

//   function request(storeId) {
//     return { type: storeConstants.DELETE_STORE_REQUEST, storeId }
//   }
//   function success(storeId) {
//     return { type: storeConstants.DELETE_STORE_SUCCESS, storeId }
//   }
//   function failure(error) {
//     return { type: storeConstants.DELETE_STORE_FAILURE, error }
//   }
// }

function getAll(id, page, size) {
  return (dispatch) => {
    dispatch(request({ id }))

    return storeService.getAll(id, page, size).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(id) {
    return { type: storeConstants.GETALL_REQUEST, id }
  }
  function success(stores) {
    return { type: storeConstants.GETALL_SUCCESS, stores }
  }
  function failure(error) {
    return { type: storeConstants.GETALL_FAILURE, error }
  }
}

function findLocals(query) {
  return (dispatch) => {
    return storeService.findLocals(query).then(
      (res) => {
        dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(locals) {
    return { type: storeConstants.CUSTOMER_SEARCH_LOCAL_SUCCESS, locals }
  }
  function failure(error) {
    return { type: storeConstants.CUSTOMER_SEARCH_LOCAL_FAILURE, error }
  }
}

function customerStoreSearch(query) {
  return (dispatch) => {
    dispatch(request({ query }))

    return storeService.customerStoreSearch(query).then(
      (res) => {
        dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(query) {
    return { type: storeConstants.CUSTOMER_SEARCH_REQUEST, query }
  }
  function success(stores) {
    return { type: storeConstants.CUSTOMER_SEARCH_SUCCESS, stores }
  }
  function failure(error) {
    return { type: storeConstants.CUSTOMER_SEARCH_FAILURE, error }
  }
}

function setCurrentStore(store) {
  return {
    type: storeConstants.SET_CURRENT_STORE,
    store,
  }
}

function findStore(storeId) {
  return (dispatch) => {
    dispatch(request(storeId))
    return storeService.findStore(storeId).then(
      (res) => {
        dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(query) {
    return { type: storeConstants.FIND_STORE_REQUEST, query }
  }
  function success(store) {
    return { type: storeConstants.FIND_STORE_SUCCESS, store }
  }
  function failure(error) {
    return { type: storeConstants.FIND_STORE_FAILURE, error }
  }
}

function searchByCategory(categoryId) {
  return {
    type: storeConstants.SET_CATEGORY,
    categoryId,
  }
}

function searchWithNewCategory(categoryId) {
  return {
    type: storeConstants.ADD_CATEGORY_FILTER,
    categoryId,
  }
}

function removeCategorySearchFilter(categoryId) {
  return {
    type: storeConstants.REMOVE_CATEGORY_FILTER,
    categoryId,
  }
}

function searchWithNewDietary(dietaryId) {
  return {
    type: storeConstants.ADD_DIETARY_FILTER,
    dietaryId,
  }
}

function removeDietarySearchFilter(dietaryId) {
  return {
    type: storeConstants.REMOVE_DIETARY_FILTER,
    dietaryId,
  }
}

function setPriceyFilter(value) {
  return {
    type: storeConstants.SET_PRICEY_VALUE,
    value,
  }
}

function setRatingFilter(value) {
  return {
    type: storeConstants.SET_RATING_VALUE,
    value,
  }
}

function setDistanceFilter(value) {
  return {
    type: storeConstants.SET_DISTANCE_VALUE,
    value,
  }
}

function setSearchTextFilter(text) {
  return {
    type: storeConstants.SET_SEARCH_TEXT,
    text,
  }
}

function setSearchSortBy(value) {
  return {
    type: storeConstants.SET_SEARCH_SORTBY_VALUE,
    value,
  }
}

function setDeliveryType(how) {
  return {
    type: storeConstants.SET_DELIVERY_TYPE,
    how,
  }
}

function setWhenDetail(when) {
  return {
    type: storeConstants.SET_DELIVERYTIME,
    when,
  }
}

function setWhenDetailDetail(whenDetail) {
  return {
    type: storeConstants.SET_DELIVERYTIME_DETAIL,
    whenDetail,
  }
}

function setMoreFilters(dietaryIds, rating, pricey, distance) {
  return {
    type: storeConstants.SET_MORE_FILTERS,
    dietaryIds,
    rating,
    pricey,
    distance,
  }
}
