import React, { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import TopNavbar from '../top_navbar'
import Footer from '../Footers'
import IconButton from '@material-ui/core/IconButton'
import backIcon from '../../../images/black-back.svg'
import clsx from 'clsx'
import { templateHeader, footerPages } from '../../../helpers'

const path = process.env.PUBLIC_URL

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...flexColumn,
    minHeight: '100vh',
    height: '100%',
  },
  headerContainer: {
    ...flexColumn,
    minHeight: 160,
    backgroundColor: '#f7f7f7',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 34,
    lineHeight: 1.32,
    color: '#333333',
    textAlign: 'center',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentContainer: {
    minHeight: 600,
    height: 'auto',
    padding: theme.spacing(4),
  },
}))

const DesktopFooterPages = () => {
  const history = useHistory()
  const match = useRouteMatch(`${path}/customer/:relativePath`)
  const realtivePath = match.params.relativePath
  const classes = useStyles()
  const Component = footerPages[realtivePath]

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [realtivePath])

  return (
    <Box className={classes.root}>
      <Box className={clsx('grow desktop full-width')}>
        <Container maxWidth='lg'>
          <TopNavbar cartVisible={true} />
        </Container>
        <Box className={classes.headerContainer}>
          <Container maxWidth='lg' className={classes.header}>
            <IconButton color='inherit' aria-label='close' onClick={() => history.goBack()}>
              <img src={backIcon} alt='Back' />
            </IconButton>
            <Typography className={classes.headerText}>{templateHeader[realtivePath]}</Typography>
          </Container>
        </Box>
        <Container maxWidth='lg' className={classes.contentContainer}>
          {Component && <Component />}
        </Container>
        <Footer invisible={true} />
      </Box>
    </Box>
  )
}

export default DesktopFooterPages
