import React from 'react'
import priceWhite from '../images/price_white.svg'
import price from '../images/price.svg'
import priceRest from '../images/price_rest.svg'

const priceIcons = (i, size, background) => {
  let prices = new Array(5).fill(null)
  for (let k = 0; k < i; k++) {
    prices[k] = (
      <span key={k} style={{ marginLeft: 2.6 }}>
        <img style={{ width: size, height: size }} src={background ? priceWhite : price} alt='price' />
      </span>
    )
    for (let j = i; j <= 3; j++) {
      prices[j] = (
        <span key={j} style={{ marginLeft: 2.6 }}>
          <img width={size} height={size} src={priceRest} alt='pricerest' />
        </span>
      )
    }
  }
  return prices
}

const priceFilter = (i, size, component) => {
  const priceArray = []
  priceArray.push(
    <div key={i} className='row'>
      {priceIcons(i, size, component)}
    </div>
  )
  return priceArray
}

export default priceFilter
