import { customerConstants, generalConstants } from '../constants'
import { customerService } from '../services'
import { alertActions } from './alert.actions'
import { history } from '../helpers'
import { favoriteStateMaker } from '../helpers/favorite-store'
import { searchActions } from './search.actions'

const path = process.env.PUBLIC_URL

export const customerActions = {
  social,
  signin,
  signout,
  signup,
  getAll,
  setGeolocation,
  getAllSavedAddresses,
  createAddress,
  deleteAddress,
  updateAddress,
  resetPassword,
  updatePassword,
  requestEmailVerificationCode,
  requestPhoneVerificationCode,
  getAllFavoriteStores,
  saveFavoriteStore,
  deleteFavoriteStore,
  storeEmailVerification,
  errorEmailVerification,
  storePhoneVerification,
  errorPhoneVerification,
  storeUpdatedEmail,
  storeUpdatedPhone,
  storeUpdatedName,
  defaultCardChange,
  createAddressFromAddressPopUp,
  signinFromModal,
  changeAddressState,
  updateAddressFromAddressPopUp,
}

function signin(email, password) {
  return (dispatch) => {
    dispatch(request({ email }))

    customerService.signin(email, password).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          localStorage.setItem(customerConstants.CUSTOMER, JSON.stringify(res.data))
          dispatch(success(res.data))
          dispatch(getAllSavedAddresses())
        }
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.response.data.error_message.toString()))
      }
    )
  }

  function request(email) {
    return { type: customerConstants.SIGNIN_REQUEST, email }
  }
  function success(customer) {
    return { type: customerConstants.SIGNIN_SUCCESS, customer }
  }
  function failure(error) {
    return { type: customerConstants.SIGNIN_FAILURE, error }
  }
}

function social(provider, token) {
  return (dispatch) => {
    dispatch(request({ provider }))

    customerService.social(provider, token).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          localStorage.setItem(customerConstants.CUSTOMER, JSON.stringify(res.data))
          dispatch(success(res.data))
          dispatch(getAllSavedAddresses())
        }
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(provider) {
    return { type: customerConstants.SIGNIN_REQUEST, provider }
  }
  function success(customer) {
    return { type: customerConstants.SIGNIN_SUCCESS, customer }
  }
  function failure(error) {
    return { type: customerConstants.SIGNIN_FAILURE, error }
  }
}

function signout() {
  return async (dispatch) => {
    // Removing an item from localStorage is an async function
    await customerService.signout()
    return dispatch(removeCustomer())
  }
  function removeCustomer() {
    return { type: customerConstants.SIGNOUT }
  }
}

function signup(customer) {
  return (dispatch) => {
    dispatch(request(customer))

    customerService.signup(customer).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          dispatch(success(customer))
          history.push(`${path}/customer/signin`)
          dispatch(alertActions.success('Sign up successful'))
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(customer) {
    return { type: customerConstants.SIGNUP_REQUEST, customer }
  }
  function success(customer) {
    return { type: customerConstants.SIGNUP_SUCCESS, customer }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())

    customerService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    )
  }

  function request() {
    return { type: customerConstants.GETALL_REQUEST }
  }
  function success(users) {
    return { type: customerConstants.GETALL_SUCCESS, users }
  }
  function failure(error) {
    return { type: customerConstants.GETALL_FAILURE, error }
  }
}

function setGeolocation(latitude, longitude) {
  return {
    type: customerConstants.SET_GEOLOCATION,
    latitude,
    longitude,
  }
}

function getAllSavedAddresses() {
  return (dispatch) => {
    dispatch(request())

    customerService.getAllSavedAddresses().then(
      (addresses) => {
        dispatch(success(addresses))
        addresses.length > 0 ? dispatch(searchActions.populateQueryAddress(addresses)) : dispatch(changeAddressState())
      },
      (error) => dispatch(failure(error.toString()))
    )
  }

  function request() {
    return { type: customerConstants.GETALL_ADDRESSES_REQUEST }
  }
  function success(addresses) {
    return { type: customerConstants.GETALL_ADDRESSES_SUCCESS, addresses }
  }
  function failure(error) {
    return { type: customerConstants.GETALL_ADDRESSES_FAILURE, error }
  }
}

function createAddress(address) {
  return (dispatch) => {
    dispatch(request())

    customerService.createAddress(address).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request() {
    return { type: customerConstants.CREATE_ADDRESS_REQUEST }
  }
  function success(address) {
    return { type: customerConstants.CREATE_ADDRESS_SUCCESS, address }
  }
  function failure(error) {
    return { type: customerConstants.CREATE_ADDRESS_FAILURE, error }
  }
}

function createAddressFromAddressPopUp(address) {
  return { type: customerConstants.CREATE_ADDRESS_SUCCESS, address }
}

function deleteAddress(id) {
  return (dispatch) => {
    dispatch(request({ id }))

    customerService.deleteAddress(id).then(
      (res) => {
        if (res === generalConstants.STATUS_OK) dispatch(success(id))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(id) {
    return { type: customerConstants.DELETE_ADDRESS_REQUEST, id }
  }
  function success(id) {
    return { type: customerConstants.DELETE_ADDRESS_SUCCESS, id }
  }
  function failure(error) {
    return { type: customerConstants.DELETE_ADDRESS_FAILURE, error }
  }
}

function updateAddress(id, address) {
  return (dispatch) => {
    dispatch(request({ id }))

    customerService.updateAddress(id, address).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(id) {
    return { type: customerConstants.UPDATE_ADDRESS_REQUEST, id }
  }
  function success(address) {
    return { type: customerConstants.UPDATE_ADDRESS_SUCCESS, address }
  }
  function failure(error) {
    return { type: customerConstants.UPDATE_ADDRESS_FAILURE, error }
  }
}

function updateAddressFromAddressPopUp(address) {
  return { type: customerConstants.UPDATE_ADDRESS_SUCCESS, address }
}

function resetPassword(email) {
  return (dispatch) => {
    customerService.resetPassword(email).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(alertActions.success(res.data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }
}

function updatePassword(currentPass, newPass) {
  return (dispatch) => {
    customerService.updatePassword(currentPass, newPass).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(alertActions.success(res.data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }
}

function storeUpdatedName(firstName, lastName) {
  return (dispatch) => {
    dispatch({ type: customerConstants.NAME_UPDATE_SUCCESS, payload: { firstName, lastName } })
    dispatch(alertActions.success())
  }
}

function storeUpdatedEmail(email, emailVerified) {
  return (dispatch) => {
    dispatch({ type: customerConstants.EMAIL_UPDATE_SUCCESS, payload: { email, emailVerified } })
  }
}

function storeUpdatedPhone(phone, phoneVerified) {
  return (dispatch) => {
    dispatch({ type: customerConstants.PHONE_UPDATE_SUCCESS, payload: { phone, phoneVerified } })
  }
}

function requestEmailVerificationCode() {
  return (dispatch) => {
    customerService.requestEmailVerificationCode().then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(alertActions.success(res.data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }
}

function storeEmailVerification(data) {
  return (dispatch) => {
    dispatch({ type: customerConstants.EMAIL_VERIFICATION_SUCCESS })
    dispatch(alertActions.success(data))
  }
}

function errorEmailVerification(data) {
  return (dispatch) => {
    dispatch(alertActions.error(data))
  }
}

function requestPhoneVerificationCode(data) {
  return (dispatch) => {
    customerService.requestPhoneVerificationCode(data).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(alertActions.success(res.data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }
}

function storePhoneVerification(data) {
  return (dispatch) => {
    dispatch({ type: customerConstants.PHONE_VERIFICATION_SUCCESS })
    dispatch(alertActions.success(data))
  }
}

function errorPhoneVerification(data) {
  return (dispatch) => {
    dispatch(alertActions.error(data))
  }
}
function getAllFavoriteStores(page, size) {
  return (dispatch) => {
    dispatch(request())

    return customerService.getAllFavoriteStores(page, size).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          let stores = favoriteStateMaker(res.data.content)
          dispatch(success(stores))
        } else {
          dispatch(failure())
        }
      },
      (error) => {
        dispatch(failure(error.toString()))
      }
    )
  }

  function request() {
    return { type: customerConstants.GETALL_FAVORITE_REQUEST }
  }
  function success(stores) {
    return { type: customerConstants.GETALL_FAVORITE_SUCCESS, stores }
  }
  function failure(error) {
    return { type: customerConstants.GETALL_FAVORITE_FAILURE, error }
  }
}

function saveFavoriteStore(storeId) {
  return (dispatch) => {
    dispatch(request(storeId))

    customerService.saveFavoriteStore(storeId).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          let store = (({ entityId, id }) => ({ entityId, id }))(res.data)
          dispatch(success(store))
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request() {
    return { type: customerConstants.SAVE_FAVORITE_REQUEST, storeId }
  }
  function success(data) {
    return { type: customerConstants.SAVE_FAVORITE_SUCCESS, data }
  }
}

function deleteFavoriteStore(storeId) {
  return (dispatch) => {
    dispatch(request({ storeId }))

    customerService.deleteFavoriteStore(storeId).then(
      (res) => {
        if (res === generalConstants.STATUS_OK) {
          dispatch(success(storeId))
        }
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(storeId) {
    return { type: customerConstants.DELETE_FAVORITE_REQUEST, storeId }
  }
  function success(storeId) {
    return { type: customerConstants.DELETE_FAVORITE_SUCCESS, storeId }
  }
  function failure(error) {
    return { type: customerConstants.DELETE_FAVORITE_FAILURE, error }
  }
}

function defaultCardChange(id) {
  return (dispatch) => {
    dispatch({ type: customerConstants.DEFAULT_CARD_CHANGE, payload: id })
  }
}

function signinFromModal(customer) {
  return (dispatch) => {
    dispatch({ type: customerConstants.SIGNIN_SUCCESS, customer })
  }
}

function changeAddressState() {
  return { type: customerConstants.CHANGE_ADDRESS_STATE, payload: true }
}
