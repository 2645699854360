import React from 'react'
import { Box, ClickAwayListener, Fade, makeStyles, Paper, Popper } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  return {
    popoverRoot: {
      backgroundColor: '#f7f7f7',
      width: 143,
      height: 91,
      position: 'absolute',
      right: -13,
      top: 6,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: theme.shadows[4],
    },
    popoverRootWODefault: {
      backgroundColor: '#f7f7f7',
      width: 143,
      height: 45,
      position: 'absolute',
      right: -13,
      top: 6,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: theme.shadows[4],
    },
    content: {
      paddingRight: 0,
      paddingBottom: 10,
      paddingTop: 0,
      paddingLeft: 10,
    },
    popper: {
      // zIndex: 2000,
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0',
        },
      },
    },
    arrow: {
      overflow: 'hidden',
      width: '1em',
      height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: 'border-box',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 20,
        height: 20,
        backgroundColor: '#f7f7f7',
        transform: 'rotate(45deg)',
        position: 'absolute',
        left: 120,
        top: -10,
      },
    },
  }
})

const RichTooltip = ({
  placement = 'top',
  arrow = true,
  open,
  onClose = () => {},
  content,
  children,
  defaultCard,
  cardId,
}) => {
  const classes = useStyles()
  const [arrowRef, setArrowRef] = React.useState(null)
  const [childNode, setChildNode] = React.useState(null)

  return (
    <div>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
          arrow: {
            enabled: arrow,
            element: arrowRef,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <Paper className={defaultCard === cardId ? classes.popoverRootWODefault : classes.popoverRoot}>
                  {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
                  <Box className={classes.content}>{content}</Box>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default RichTooltip
