export const bankinfoFocusedState = {
  businessName: false,
  taxIdNumber: false,
  businessOwnerName: false,
  businessOwnerBirthDate: false,
  accountNumber: false,
  routingNumber: false,
  checkSuiteNo: false,
  paymentContactPhone: false,
  paymentContactEmail: false,
  paymentContactName: false,
}

export const bankinfoInvalidState = {
  businessName: false,
  taxIdNumber: false,
  businessOwnerName: false,
  businessOwnerBirthDate: false,
  accountNumber: false,
  routingNumber: false,
  checkSuiteNo: false,
  paymentContactPhone: false,
  paymentContactEmail: false,
  paymentContactName: false,
  paymentMethod: false,
}

export const bankinfoInitialState = {
  businessName: '',
  taxIdNumber: '',
  businessOwnerName: '',
  businessOwnerBirthDate: '',
  accountNumber: '',
  routingNumber: '',
  checkSuiteNo: '',
  paymentMethod: '',
  address: '',
  paymentContactPhone: '',
  paymentContactEmail: '',
  paymentContactName: '',
}
