import { roles } from '../../constants'

const canAddEmployee = [roles.REST_ADMIN, roles.STORE_ADMIN]
const canAddStore = [roles.REST_ADMIN]

const merchantRolesMaker = (merchant) => {
  const roles = []
  for (let role of merchant.roles) {
    roles.push(role.name)
  }
  return roles
}

const isButtonVisible = (to, role) => {
  let showButtons
  if (to === 'stores') {
    showButtons = canAddStore.includes(role)
  } else {
    showButtons = canAddEmployee.includes(role)
  }
  return showButtons
}

const hasAdminRole = (role) => canAddEmployee.includes(role)

export { merchantRolesMaker, isButtonVisible, hasAdminRole }
