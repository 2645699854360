import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import { GreenRadio } from '../../../utils/tools'
import { emailRegex } from '../../../utils/regex_collections'

import {
  valueConstants,
  labelConstants,
  emailConstants,
  notificationConstants,
} from '../../../businessRegister/businessConstants'

const EmailSelection = ({
  classes,
  orderConfirmation,
  handleCheck,
  store,
  setIsFocused,
  setIsInvalid,
  isFocused,
  isInvalid,
  setStore,
  valProp,
  editting,
}) => {
  const { formatMessage: f } = useIntl()

  const emailHandler = (e) => {
    let val = e.target.value
    setIsFocused({ ...isFocused, [valProp]: true })
    setIsInvalid({
      ...isInvalid,
      [valProp]: !emailRegex.test(val),
    })
    setStore({
      ...store,
      [valProp]: val,
    })
  }

  let disableArea = orderConfirmation === notificationConstants.storeEmail

  return (
    <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
      <FormControl fullWidth style={{ marginBottom: 16 }}>
        {emailConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            className={classes.label}
            control={<GreenRadio checked={orderConfirmation === item} onChange={handleCheck} value={item} />}
            label={<Typography>{item}</Typography>}
            disabled={!editting}
          />
        ))}
      </FormControl>
      <TextField
        size='small'
        value={store[valProp] || ''}
        variant='outlined'
        fullWidth
        id={valueConstants.notificationEmail}
        InputLabelProps={{ shrink: true }}
        label={f({ id: labelConstants[valProp] })}
        onChange={emailHandler}
        required
        error={isFocused[valProp] && isInvalid[valProp]}
        inputProps={{
          maxLength: 100,
          readOnly: !editting || disableArea,
        }}
      />
    </Grid>
  )
}

EmailSelection.propTypes = {
  classes: PropTypes.object,
  orderConfirmation: PropTypes.string,
  handleCheck: PropTypes.func,
  store: PropTypes.object,
  setIsFocused: PropTypes.func,
  setIsInvalid: PropTypes.func,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  setStore: PropTypes.func,
  editting: PropTypes.bool,
  valProp: PropTypes.string,
}

export default EmailSelection
