import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'
import Campaigns from './Campaigns'
import { useSelector } from 'react-redux'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import AuthRequired from '../common/AuthRequired'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

export const CampaignsMain = ({ match, classFromParent, openSwipeable, location }) => {
  const subProp = useMemo(() => match.params.sub, [match])
  // const values = useMemo(() => queryString.parse(location.search), [location])
  const classes = useStyles()
  const {
    values: { isRestaurantAdmin, isStoreAdmin },
  } = useMerchantHomeContext()

  const stores = useSelector(({ stores }) => stores)
  // const merchantStores = useSelector(({ merchantStores }) => merchantStores.stores, shallowEqual)
  const isRestaurant = useSelector(({ merchantStores }) => merchantStores.isRestaurantView)

  const canAdminSee = (isStoreAdmin && !isRestaurant) || isRestaurantAdmin

  const getContent = () => {
    switch (subProp) {
      // case 'add':
      //   if (canAdminSee) {
      //     return renderAddScreen()
      //   } else {
      //     return renderAuthRequired()
      //   }
      // case 'detail':
      //   return renderEditStore()
      default:
        if (canAdminSee) {
          return renderCampaignScreen()
        } else {
          return renderAuthRequired()
        }
    }
  }

  const renderCampaignScreen = () => <Campaigns />
  const renderAuthRequired = () => <AuthRequired />

  return (
    <div>
      {getContent()}
      <Backdrop className={classes.backdrop} open={stores.loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

CampaignsMain.propTypes = {
  match: PropTypes.object,
  classFromParent: PropTypes.object,
  openSwipeable: PropTypes.bool,
}

export default withRouter(CampaignsMain)
