import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'

import { valueConstants } from '../../businessConstants'

import { generalConstants } from '../../../../constants'

import { urlRegex } from '../../../utils/regex_collections'

import FirstFile from './MenuDetails/FirstFile'
import SecondFile from './MenuDetails/SecondFile'
import ThirdFile from './MenuDetails/ThirdFile'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    color: '#707070',
    fontWeight: 300,
    width: '100%',
    lineHeight: '2em',
    marginBottom: theme.spacing(2),
  },
  gridItem: {
    marginBottom: theme.spacing(2),
    "&[input-container='true']": {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  },
  uploadButton: {
    width: 120,
    height: 40,
    backgroundColor: '#69e781',
    borderRadius: 4,
    display: 'flex',
    padding: ' 6px 12px',
    cursor: 'pointer',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow: '0 3px 3px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    fontSize: 16,
    fontWeight: 500,
    "&[data-content='delete']": {
      backgroundColor: '#E63946 !important',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    '&:hover': {
      boxShadow: '0 5px 5px 0 rgba(0,0,0,0.2)',
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    "&[error-text='true']": {
      fontWeight: 300,
      marginTop: theme.spacing(2.5),
      color: theme.palette.secondary.main,
    },
  },
  addButton: {
    alignSelf: 'center',
    marginLeft: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    minWidth: 272,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  iconButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2.5),
    },
  },
}))

const MenuDetails = ({
  handleChange,
  values,
  warningMsg,
  error,
  setStepThreeError,
  pdfFile,
  setPdfFile,
  layers,
  setLayers,
  clicked,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  useEffect(() => {
    let active = true
    if (active) {
      if (values) {
        if (values.menuLink !== '') {
          if (urlRegex.test(values.menuLink)) {
            setStepThreeError(false)
          }
        } else if (!!(pdfFile.first || pdfFile.second || pdfFile.third)) {
          setStepThreeError(false)
        } else {
          clicked && !error && setStepThreeError(true)
        }
      }
    }
    return () => {
      active = false
    }
  }, [pdfFile, error, values?.menuLink])

  const selectPdf = (prop) => (e) => {
    let selectedFile = e.target.files[0]
    if (!selectedFile) return
    if (selectedFile.size > generalConstants.MAX_IMAGE_SIZE) {
      return warningMsg('Max file size 5mb')
    } else if (selectedFile) {
      setPdfFile({
        ...pdfFile,
        [prop]: selectedFile,
      })
    } else {
      console.log('Please select pdf file')
    }
  }

  const removePdf = (prop) => {
    setPdfFile({
      ...pdfFile,
      [prop]: null,
    })
  }

  const addLayer = (prop) => {
    setLayers({
      ...layers,
      [prop]: true,
    })
  }

  const deleteLayer = (prop) => {
    setLayers({
      ...layers,
      [prop]: false,
    })
    removePdf(prop)
  }

  return (
    <Container maxWidth='md' className={classes.root}>
      <Typography variant='h6' align='center' className={classes.text}>
        {f({ id: 'menu-details-text' })}{' '}
      </Typography>
      <Grid container spacing={2} className='full-width'>
        <Grid item md={12} sm={12} xs={12} className={classes.gridItem}>
          <TextField
            size='small'
            value={values.menuLink}
            variant='outlined'
            fullWidth
            id={valueConstants.menuLink}
            InputLabelProps={{ shrink: true }}
            label={f({ id: 'your-catering-menu' })}
            onChange={handleChange(valueConstants.menuLink)}
            error={values.menuLink.length === 0 ? false : urlRegex.test(values.menuLink) ? false : true}
          />
        </Grid>
        <FirstFile
          classes={classes}
          selectPdf={selectPdf}
          pdfFile={pdfFile}
          removePdf={removePdf}
          addLayer={addLayer}
          layers={layers}
        />
        {layers.second && (
          <SecondFile
            classes={classes}
            selectPdf={selectPdf}
            pdfFile={pdfFile}
            removePdf={removePdf}
            addLayer={addLayer}
            deleteLayer={deleteLayer}
            layers={layers}
          />
        )}
        {layers.third && (
          <ThirdFile
            classes={classes}
            selectPdf={selectPdf}
            pdfFile={pdfFile}
            removePdf={removePdf}
            addLayer={addLayer}
            deleteLayer={deleteLayer}
          />
        )}
      </Grid>
      {error && (
        <Typography className={classes.fileName} error-text='true'>
          {f({ id: 'menu-details-error' })}
        </Typography>
      )}
    </Container>
  )
}

MenuDetails.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  pdfFile: PropTypes.object,
  warningMsg: PropTypes.func,
  setPdfFile: PropTypes.func,
  error: PropTypes.bool,
  layers: PropTypes.object,
  setLayers: PropTypes.func,
}

export default MenuDetails
