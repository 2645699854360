import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import Fabs from './common/fabs'
import { searchActions } from '../../../../actions'

const SearchText = () => {
  const dispatch = useDispatch()

  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const removeSearchText = () => dispatch(searchActions.removeSearchTextFilter())

  return (
    <>
      {searchData?.searchText.length > 0 ? (
        <Fabs text={searchData.searchText} onClick={() => removeSearchText()} dataContent='searchtext' />
      ) : null}
    </>
  )
}

export default SearchText
