import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, makeStyles } from '@material-ui/core'
import MobileSignin from '../../authentication/MobileSignin'
import { TFDialog, Transition } from '../../utils/tools'
import { Elements } from '@stripe/react-stripe-js'
import InjectedMobileCart from '../cart/InjectedMobileCart'
import { loadStripe } from '@stripe/stripe-js'
import AddressDialog from '../../common/AddressDialog'

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const useStyles = makeStyles((theme) => ({}))

const MobileDialogs = (props) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.showMobileSigninDialog}
        onClose={props.handleSigninModalClose}
        TransitionComponent={Transition}
      >
        <MobileSignin handleClose={props.handleSigninModalClose} handleSignin={props.handleSignin} />
      </Dialog>
      <AddressDialog open={props.showAddressDialog} close={props.toggleAddressDialog} />
      <TFDialog
        fullWidth
        maxWidth={false}
        className={classes.dialog}
        open={props.showMobileCartDialog}
        onClose={props.handleMobileCartClose}
        TransitionComponent={Transition}
        // keepMounted
      >
        <Elements stripe={promise}>
          <InjectedMobileCart
            handleClose={props.handleMobileCartClose}
            nextButton={props.signinCheck}
            setOrderFees={props.viewCartHandler}
          />
        </Elements>
      </TFDialog>
    </React.Fragment>
  )
}

MobileDialogs.propTypes = {
  props: PropTypes.object,
}

export default MobileDialogs
