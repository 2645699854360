import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'

import { deliveryTypes } from '../../../../constants'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    marginBottom: 20,
  },
  title: {
    marginBottom: 15,
    display: 'block',
  },
  addButtonView: {
    textTransform: 'capitalize',
    borderRadius: 20,
  },
  container: {
    width: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  address: {
    fontSize: 14,
    fontWeight: 400,
    color: '#707070',
    maxWidth: '75%',
    "&[delivery-type='TAKEOUT']": {
      maxWidth: '100%',
    },
  },
}))

const path = process.env.PUBLIC_URL

const AddressPart = ({ parentClasses }) => {
  const classes = useStyles()
  const history = useHistory()
  const deliveryType = useSelector(({ search }) => search?.searchQuery.deliveryType)
  const storeAddress = useSelector(
    ({ stores }) => stores.customerCurrentStore.storeInformation?.address?.formattedAddress
  )
  const customerAddress = useSelector(({ search }) => search?.searchQuery?.address?.formattedAddress)

  let currentAddress =
    deliveryType && storeAddress && customerAddress
      ? deliveryType === deliveryTypes.DELIVERY
        ? customerAddress
        : storeAddress
      : ''

  const currentTitle = deliveryType && deliveryType === deliveryTypes.DELIVERY ? 'delivery-address' : 'store-address'

  return (
    <div className={classes.root}>
      <Typography className={clsx('ellipsis', parentClasses.sectionTitle)} delivery-type={deliveryType}>
        <FormattedMessage id={currentTitle} />
      </Typography>
      <div className={classes.container}>
        <Typography className={classes.address}>{currentAddress}</Typography>
        {deliveryType && deliveryType === deliveryTypes.DELIVERY && (
          <Button
            variant='outlined'
            onClick={() => history.push(`${path}/customer/account/addresses`)}
            color='primary'
            className={classes.addButtonView}
          >
            <Typography variant='h6' color='primary'>
              <FormattedMessage id='change-label' />
            </Typography>
          </Button>
        )}
      </div>
    </div>
  )
}

export default AddressPart
