import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Backdrop, CircularProgress } from '@material-ui/core'
import OrdersTable from './OrdersTable'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import DatePick from './DatePick'
import { orderService } from '../../../services'
import { generalConstants } from '../../../constants'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { alertActions } from '../../../actions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginTop: 70,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    height: 103,
    borderBottom: '1px solid #cdcdcd',
    marginBottom: theme.spacing(),
  },
  row: {
    cursor: 'pointer',
  },
  zeroPadding: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const OrdersMain = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()

  const startDate = moment().format('YYYY-MM-DD')
  const endDate = moment().add(7, 'days').format('YYYY-MM-DD')
  const storeId = useSelector(({ merchantStores }) => merchantStores.selectedStore.storeId)
  const initialQuery = {
    page: 0,
    rowsPerPage: 15,
    storeId,
    startDate,
    endDate,
  }

  const alertError = (msg) => dispatch(alertActions.error(msg))

  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const [query, setQuery] = useState(initialQuery)
  const [status, setStatus] = useState(0)
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    const init = async () => {
      await fetchStoreOrders(query)
      setInitialRender(false)
    }
    init()
  }, [status])

  useEffect(() => {
    const id = setInterval(() => {
      !initialRender && fetchStoreOrders(query)
    }, 60000)
    return () => clearInterval(id)
  }, [query])

  useEffect(() => {
    moment(endDate) < moment(startDate) || startDate.length !== endDate.length
      ? setDisableButton(true)
      : setDisableButton(false)
  }, [startDate, endDate])

  const handleStartDateChange = (date) => setQuery({ ...query, startDate: moment(date).format('YYYY-MM-DD') })

  const handleEndDateChange = (date) => setQuery({ ...query, endDate: moment(date).format('YYYY-MM-DD') })

  const handleSubmit = async () => {
    if (moment(endDate) > moment(startDate)) {
      await fetchStoreOrders(query)
    }
    return
  }

  const handleChangePage = async (event, newPage) => {
    let query_ = { ...query, page: newPage }
    setQuery(query_)
    await fetchStoreOrders(query_)
  }

  const handleChangeRowsPerPage = async (e) => {
    let query_ = { ...query, page: 0, rowsPerPage: +e.target.value }
    setQuery(query_)
    await fetchStoreOrders(query_)
  }

  const fetchStoreOrders = async (payload) => {
    setLoading(true)
    await orderService
      .getOrdersByStoreId(payload)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setOrders(res.data)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
      .finally(setLoading(false))
  }

  const changeOrderStatus = async (status, id) => {
    setLoading(true)
    const requestBody = {
      status,
      note: '',
    }
    orderService
      .orderApprovalStatus(id, requestBody)
      .then(
        (response) => {
          if (response === generalConstants.STATUS_OK) {
            setStatus((prev) => prev + 1)
          }
        },
        (err) => {
          alertError(err.response?.data?.error_message)
          fetchStoreOrders(query)
          setLoading(false)
        }
      )
      .finally(setLoading(false))
  }

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <DatePick
        handleSubmit={handleSubmit}
        startDate={query?.startDate}
        endDate={query?.endDate}
        disableButton={disableButton}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
      />
      <OrdersTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        query={query}
        orders={orders}
        changeOrderStatus={changeOrderStatus}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

export default OrdersMain
