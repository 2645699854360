import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Collapse, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { GreenButton } from '../../../../utils/tools'
import { isTablet } from 'react-device-detect'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  attributeListItem: {
    padding: '0px 0px 0px 16px',
    margin: 0,
  },
  menuPriceSection: {
    minHeight: 143.5,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  menuSection: {
    minHeight: 143.5,
    width: '80%',
  },
  menuContainer: {
    width: '100%',
    height: 'auto',
    minHeight: 40,
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  menuQuantityNameContainer: {
    width: '100%',
    minHeight: 19,
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 16,
  },
  menuQuantityContainer: {
    width: 16,
    height: 16,
    backgroundColor: '#69e781',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  menuQuantity: {
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontWeight: 'bold',
    lineHeight: 1.36,
    textAlign: 'center',
    color: '#ffffff',
  },
  menuNameContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 12.5,
  },
  menuName: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: 1.36,
    textAlign: 'left',
    color: '#000000',
  },
  detailContainer: {
    width: '42px',
    paddingLeft: 44.5,
    minHeight: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  detailText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#000000',
  },
  listItemText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#707070',
  },
  priceSectionContainer: {
    paddingTop: 28.5,
    minHeight: 143.5,
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  priceSection: {
    width: '100%',
    height: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  taxContainer: {
    width: '100%',
    height: 19,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subTotalContainer: {
    width: '100%',
    height: 19,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  priceSectionText: {
    fontFamily: 'Roboto',
    textAlign: 'left',
    color: '#000000',
  },
  totalContainer: {
    width: '100%',
    height: 21,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
    height: 50,
    marginTop: 28,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    height: 48,
    width: 111,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: isTablet ? '12px' : '16px',
    fontWeight: 'bold',
    lineHeight: 1.31,
    textAlign: 'center',
    color: '#ffffff',
  },
  bold: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  smallFont: {
    fontSize: 14,
  },
  collapse: {
    paddingLeft: 28.5,
  },
  marginBottom: {
    marginBottom: 2,
  },
}))

const MenuPriceContainer = ({ order, handleCollapse, selectedIndex, showReceiptCondition, handleOrderReceipt }) => {
  const classes = useStyles()
  return (
    <div className={classes.menuPriceSection}>
      <div className={classes.menuSection}>
        {order.orderDetails.map((order, index) => {
          const RelatedIconComponent = index === selectedIndex ? ExpandMoreIcon : NavigateNextIcon
          return (
            <div className={classes.menuContainer} key={index}>
              <div className={classes.menuQuantityNameContainer}>
                <div className={classes.menuQuantityContainer}>
                  <Typography className={classes.menuQuantity}>{order.quantity}</Typography>
                </div>
                <div className={classes.menuNameContainer}>
                  <Typography className={classes.menuName}>{order.orderMenu.name}</Typography>
                </div>
              </div>
              {(!isEmpty(order?.attributes) || !!order?.orderMenu?.specialInstructions) && (
                <>
                  <div onClick={() => handleCollapse(index)} className={classes.detailContainer}>
                    <Typography className={classes.detailText}>
                      <FormattedMessage id='details-label' />
                    </Typography>
                    <RelatedIconComponent className={classes.marginBottom} />
                  </div>

                  <Collapse in={index === selectedIndex} timeout='auto' unmountOnExit className={classes.collapse}>
                    <List component='div' disablePadding>
                      {order?.attributes.map((attribute, index) => (
                        <ListItem key={index} className={classes.attributeListItem}>
                          <ListItemText>
                            <Typography className={classes.listItemText}>{attribute?.attributeValueName}</Typography>
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                    {!!order?.orderMenu?.specialInstructions && (
                      <List component='div' disablePadding>
                        <ListItem className={classes.listItem}>
                          <ListItemText>
                            <Typography className={classes.detailText} noWrap>
                              <FormattedMessage id='special-instructions' />:
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                          <Typography className={classes.listItemText}>
                            {order?.orderMenu?.specialInstructions}
                          </Typography>
                        </ListItem>
                      </List>
                    )}
                  </Collapse>
                </>
              )}
            </div>
          )
        })}
      </div>
      <div className={classes.priceSectionContainer}>
        <div className={classes.priceSection}>
          <div className={classes.subTotalContainer}>
            <Typography className={clsx(classes.priceSectionText, classes.smallFont)}>
              <FormattedMessage id='subtotal-label' />
            </Typography>
            <Typography className={clsx(classes.priceSectionText, classes.smallFont)}>
              ${(+order.finalSubtotalPrice)?.toFixed(2)}
            </Typography>
          </div>
          <div className={classes.taxContainer}>
            <Typography className={clsx(classes.priceSectionText, classes.smallFont)}>
              <FormattedMessage id='tax-label' />
            </Typography>
            <Typography className={clsx(classes.priceSectionText, classes.smallFont)}>
              ${(+order.taxPrice)?.toFixed(2)}
            </Typography>
          </div>
          <div className={classes.totalContainer}>
            <Typography className={clsx(classes.priceSectionText, classes.bold)}>
              <FormattedMessage id='total-label' />
            </Typography>
            <Typography className={clsx(classes.priceSectionText, classes.bold)}>
              ${(+order.totalPrice)?.toFixed(2)}
            </Typography>
          </div>
        </div>
        {showReceiptCondition && (
          <div className={classes.buttonContainer}>
            <GreenButton className={classes.button} onClick={() => handleOrderReceipt(order)}>
              <FormattedMessage id='receipt-label' />
            </GreenButton>
          </div>
        )}
      </div>
    </div>
  )
}

MenuPriceContainer.propTypes = {
  order: PropTypes.object.isRequired,
  handleCollapse: PropTypes.func.isRequired,
  selectedIndex: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  showReceiptCondition: PropTypes.bool,
  handleOrderReceipt: PropTypes.func,
}

export default React.memo(MenuPriceContainer)
