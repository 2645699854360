const cartDetailsHandler = (cart, sections) => {
  let categoriesSelected = []
  let subTotal = 0
  let itemCounter = 0
  cart.orderMenus.forEach((orderMenu) => {
    subTotal += orderMenu.price * orderMenu.quantity
    itemCounter += orderMenu.quantity
    sections.forEach((section) => {
      section.menus.forEach((menu, i) => {
        menu.name === orderMenu.name && categoriesSelected.push(section)
      })
    })
  })
  categoriesSelected = [...new Set(categoriesSelected)]
  return { categoriesSelected, subTotal, itemCounter }
}

export { cartDetailsHandler }
