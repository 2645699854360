import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import BankInput from '../../common/BankInput'
import TaxInput from '../../common/TaxInput'

const DirectDeposit = ({ bankInfo, isFocused, isInvalid, onChangeHandler, editting }) => {
  return (
    <Grid container spacing={2} className='full-width'>
      <Grid item sm={6} xs={12}>
        <BankInput
          isFocused={isFocused}
          isInvalid={isInvalid}
          callback={onChangeHandler}
          value={bankInfo.businessName || ''}
          label='business-name'
          text='businessName'
          type1='text'
          editting={editting}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TaxInput
          isFocused={isFocused}
          isInvalid={isInvalid}
          callback={onChangeHandler}
          value={bankInfo.taxIdNumber || ''}
          label='taxId-number'
          text='taxIdNumber'
          type1='tax'
          editting={editting}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <BankInput
          isFocused={isFocused}
          isInvalid={isInvalid}
          callback={onChangeHandler}
          value={bankInfo.ownerName || ''}
          label='business-owner-name'
          text='ownerName'
          type1='text'
          editting={editting}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <BankInput
          isFocused={isFocused}
          isInvalid={isInvalid}
          callback={onChangeHandler}
          value={bankInfo.ownerBirthdate || ''}
          label='business-owner-birth'
          text='ownerBirthdate'
          placeholher='MM/DD/YYYY'
          type='date'
          type1='date'
          editting={editting}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <BankInput
          isFocused={isFocused}
          isInvalid={isInvalid}
          callback={onChangeHandler}
          value={bankInfo.accountNumber || ''}
          label='account-number'
          text='accountNumber'
          type1='number'
          editting={editting}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <BankInput
          isFocused={isFocused}
          isInvalid={isInvalid}
          callback={onChangeHandler}
          value={bankInfo.routingNumber || ''}
          label='routing-number'
          text='routingNumber'
          type1='number'
          editting={editting}
        />
      </Grid>
    </Grid>
  )
}

DirectDeposit.propTypes = {
  bankInfo: PropTypes.object,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  onChangeHandler: PropTypes.func,
  editting: PropTypes.bool,
}

export default DirectDeposit
