import { deliveryTimes } from '../../constants'

const handleMaxWidth = (width, time) => {
  if (time === deliveryTimes.ASAP) {
    if (width <= 1150) {
      return 311
    } else {
      return 214
    }
  } else {
    if (width <= 1150) {
      return 204.5
    } else {
      return 214
    }
  }
}

export { handleMaxWidth }
