import { searchConstants, initialQuery } from '../constants'

const initialState = {
  loading: false,
  error: null,
  searchQuery: initialQuery,
  recentSearchKeywords: [],
  searchResult: [],
  locals: [],
  data: {
    content: [],
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      pageNumber: 0,
      pageSize: 5,
      offset: 0,
      paged: true,
      unpaged: false,
    },
    totalElements: 0,
    totalPages: 0,
    last: true,
    first: true,
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    numberOfElements: 0,
    size: 25,
    number: 0,
    empty: false,
  },
  customerCurrentStore: null,
  merchantCurrentStore: null,
}

export function search(state = initialState, action) {
  switch (action.type) {
    case searchConstants.CUSTOMER_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case searchConstants.SET_CATEGORY:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          categoryIds: [action.categoryId],
        },
      }
    case searchConstants.ADD_CATEGORY_FILTER:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          categoryIds: [...state.searchQuery.categoryIds, action.categoryId],
        },
      }
    case searchConstants.REMOVE_CATEGORY_FILTER:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          categoryIds: [...state.searchQuery.categoryIds].filter((x) => x !== action.categoryId),
        },
      }
    case searchConstants.ADD_DIETARY_FILTER:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          dietaryIds: [...state.searchQuery.dietaryIds, action.dietaryId],
        },
      }
    case searchConstants.REMOVE_DIETARY_FILTER:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          dietaryIds: [...state.searchQuery.dietaryIds].filter((x) => x !== action.dietaryId),
        },
      }
    case searchConstants.SET_DIETARY_FILTERS:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          dietaryIds: action.dietaryIds,
        },
      }
    case searchConstants.SET_PRICEY_VALUE:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          pricey: action.value,
        },
      }
    case searchConstants.REMOVE_PRICEY_FILTER:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          pricey: initialQuery.pricey,
        },
      }
    case searchConstants.SET_ADDRESS:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          address: action.address,
        },
      }
    case searchConstants.SET_RATING_VALUE:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          rating: action.value,
        },
      }
    case searchConstants.REMOVE_RATING_FILTER:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          rating: initialQuery.rating,
        },
      }
    case searchConstants.SET_DISTANCE_VALUE:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          distance: action.value,
        },
      }
    case searchConstants.REMOVE_DISTANCE_FILTER:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          distance: initialQuery.distance,
        },
      }
    case searchConstants.SET_SEARCH_SORTBY_VALUE:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          orderByKey: action.value,
        },
      }
    case searchConstants.REMOVE_SORTBY_FILTER:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          orderByKey: initialQuery.orderByKey,
          orderByValue: initialQuery.orderByValue,
        },
      }
    case searchConstants.SET_SEARCH_SORTBY_ORDER:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          orderByKey: action.payload.value,
          orderByValue: action.payload.order,
        },
      }
    case searchConstants.SET_SEARCH_TEXT:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          searchText: action.text,
        },
      }
    case searchConstants.REMOVE_SEARCH_TEXT:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          searchText: initialQuery.searchText,
        },
      }
    case searchConstants.SET_DELIVERY_TYPE:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          deliveryType: action.deliveryType,
        },
      }
    case searchConstants.SET_DELIVERYTIME:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          deliveryTime: action.deliveryTime,
        },
      }
    case searchConstants.SET_DELIVERYTIME_DETAIL:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          deliveryTimeDetail: action.deliveryTimeDetail,
        },
      }
    case searchConstants.SET_MORE_FILTERS:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          dietaryIds: action.dietaryIds,
          rating: action.rating,
          pricey: action.pricey,
          distance: action.distance,
          onlyOpen: action.onlyOpen,
        },
      }
    case searchConstants.SET_MORE_FILTERS_MOBILE:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          categoryIds: action.categoryIds,
          dietaryIds: action.dietaryIds,
          rating: action.rating,
          pricey: action.pricey,
          distance: action.distance,
          orderByKey: action.orderByKey,
          orderByValue: action.orderByValue,
        },
      }
    case searchConstants.FIND_STORE_SUCCESS:
      return {
        ...state,
        customerCurrentStore: action.store,
      }
    case searchConstants.CUSTOMER_SEARCH_LOCAL_SUCCESS:
      return {
        ...state,
        locals: action.locals,
      }
    case searchConstants.RESET_SEARCH:
      const { address, deliveryTime, deliveryTimeDetail, deliveryType } = state.searchQuery
      const itemsToBeKept = { address, deliveryTime, deliveryTimeDetail, deliveryType }

      return {
        ...state,
        searchQuery: { ...initialQuery, ...itemsToBeKept },
      }
    case searchConstants.RESET_SEARCH_QUERY_WHEN_SIGNOUT:
      return {
        ...state,
        searchQuery: { ...initialQuery },
      }
    case searchConstants.SET_CATEGORY_FILTER:
      let newArray = [action.categoryId]
      const includes = state.searchQuery.categoryIds.includes(action.categoryId)
      if (includes) {
        newArray = state.searchQuery.categoryIds.filter((category) => category !== action.categoryId)
      }
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          categoryIds: newArray,
        },
      }
    case searchConstants.DO_SEARCH_SUCCESS:
      return {
        ...state,
        searchResult: action.result,
      }
    case searchConstants.TOGGLE_OPEN_FILTER:
      let currentValue = state.searchQuery.onlyOpen
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          onlyOpen: !currentValue,
        },
      }
    default:
      return state
  }
}
