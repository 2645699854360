import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Button, ButtonBase } from '@material-ui/core'
import clsx from 'clsx'
import { CardElement } from '@stripe/react-stripe-js'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { getCardIcon } from '../../../helpers/card-icons'
import CheckIcon from '@material-ui/icons/Check'
import { Scrollbars } from 'react-custom-scrollbars'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 300,
    maxWidth: '100%',
    // height: 400,
    overFlow: 'hidden',
  },
  rootMustVisible: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: 150,
    overFlow: 'hidden',
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  content: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
  add: {
    marginTop: theme.spacing(3),
  },
  address: {
    marginTop: theme.spacing(5),
    borderRadius: 4,
    border: '1px solid rgb(195, 195, 195)',
    height: 39,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  addButton: {
    textTransform: 'capitalize',
    color: '#69e781',
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    borderRadius: 10,
    border: 'solid 1px #69e781',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: 'white',
    boxShadow: 'none',
    marginBottom: 50,
    marginTop: 10,
  },
  StripeElement: {
    border: '1.5px solid #c4c4c4',
    borderRadius: 10,
    marginTop: -37,
    padding: 10,
    height: 48,
    minWidth: 300,
  },
  cartContainer: {
    width: '100%',
    marginBottom: theme.spacing(),
  },
  cart: {
    textTransform: 'capitalize',
    color: '#000',
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    borderRadius: 10,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 6,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
})

const WebAddCard = ({
  classes,
  intl,
  elements,
  getCardElement,
  selectCard,
  handlePaymentMethod,
  selectedCard,
  cards,
  currentWidth,
}) => {
  const [visible, setVisible] = useState(false)
  const [mustVisible, setMustVisible] = useState(false)

  useEffect(() => {
    cards.length > 0 ? setMustVisible(false) : setMustVisible(true)
  }, [cards, selectedCard])

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const showCardElementsArea = () => {
    if (visible) {
      setVisible(false)
      selectCard()
    } else {
      setVisible(true)
    }
  }

  const onChange = () => {
    if (elements) {
      const cardElement = elements.getElement(CardElement)
      cardElement.on('change', function (event) {
        if (event.complete) {
          getCardElement(cardElement)
        } else if (event.error) {
          selectCard()
        } else {
          selectCard()
        }
      })
    }
  }

  const handleCardSelect = (id) => {
    if (selectedCard === id) return
    setVisible(false)
    handlePaymentMethod(id)
  }

  return (
    <div className={mustVisible ? classes.rootMustVisible : classes.root}>
      <div className={clsx('column grow full-width', [classes.content])}>
        <Box mt={2} display='flex' flexDirection='column'>
          {visible || mustVisible ? (
            <div style={{ marginTop: 22, height: 90 }}>
              <CardElement
                options={CARD_ELEMENT_OPTIONS}
                className={classes.StripeElement}
                onChange={() => onChange()}
              />
            </div>
          ) : null}
          <Scrollbars style={{ height: 120, marginTop: !visible ? -32 : -80, paddingTop: -20 }}>
            {cards.length !== 0 && (
              <Box mt={2}>
                {cards.map((item, index) => (
                  <div key={index} className={classes.cartContainer}>
                    <ButtonBase className='full-width' onClick={() => handleCardSelect(item.id)}>
                      <div
                        className={classes.cart}
                        style={{
                          backgroundColor: selectedCard !== item.id ? '#e6e6e6' : null,
                        }}
                      >
                        <img src={getCardIcon(item.card)} alt='cart' width='45' height='45' />
                        {/* <Typography>{item.card.brand}</Typography> */}
                        <div className='centered-row'>
                          {Array(12)
                            .fill(0)
                            .map((e, i) => (
                              <div
                                key={i}
                                className=''
                                // variant='h4'
                                style={{
                                  color: 'white',
                                  marginTop: 10,
                                  marginRight: (i + 1) % 4 === 0 ? 10 : 0,
                                  fontSize: currentWidth < 750 ? 24 : 28,
                                }}
                              >
                                *
                              </div>
                            ))}
                          <Typography variant='h6' style={{ color: 'white' }}>
                            {item.card.last4}
                          </Typography>
                        </div>
                        {selectedCard && selectedCard === item.id ? (
                          <CheckIcon style={{ color: '#fff' }} />
                        ) : (
                          <CheckIcon style={{ color: 'transparent' }} />
                        )}
                      </div>
                    </ButtonBase>
                  </div>
                ))}
              </Box>
            )}
          </Scrollbars>
          {mustVisible ? null : (
            <div
              style={{
                marginTop: cards.length === 1 ? -55 : cards.length === 2 ? 0 : 5,
              }}
            >
              <Button className={classes.addButton} onClick={() => showCardElementsArea()}>
                {!visible ? getLabel('add-new-card') : getLabel('cancel-add-new-card')}
              </Button>
            </div>
          )}
        </Box>
      </div>
    </div>
  )
}

export default withStyles(styles)(injectIntl(withRouter(WebAddCard)))
