import { restaurantConstants, menuConstants } from '../constants'

const initialState = {
  loading: false,
  error: null,
  data: [],
  stores: [],
  selectedRestaurant: {},
  selectedStore: {},
}

export function restaurant(state = initialState, action) {
  switch (action.type) {
    case restaurantConstants.GET_REST_STORES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case restaurantConstants.GET_REST_STORES_SUCCESS:
      let selectedStore = action.stores
      return {
        ...state,
        loading: false,
        error: null,
        stores: action.stores,
        selectedStore: selectedStore,
      }
    case restaurantConstants.GET_REST_STORES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case restaurantConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case restaurantConstants.CREATE_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.restaurant,
        // sections: [],
      }
    case restaurantConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.restaurants,
        selectedRestaurant: action.restaurants[0],
        // selectedStore: action.restaurants[0].stores[0],
      }

    case menuConstants.DELETE_STORE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          stores: [...state.data.stores].filter((x) => x.id !== action.storeId),
        },
      }
    // case restaurantConstants.SAVE_LOGO_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   }

    // case restaurantConstants.SAVE_LOGO_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     data: {
    //       ...state.data,
    //       logo: action.logo,
    //     },
    //   }
    // case restaurantConstants.SAVE_LOGO_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error,
    //     loading: false,
    //   }
    // case restaurantConstants.SAVE_BACKGROUND_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   }
    // case restaurantConstants.SAVE_BACKGROUND_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     data: {
    //       ...state.data,
    //       background: action.background,
    //     },
    //     selectedRestaurant: {
    //       ...state.selectedRestaurant,
    //       background: action.background.background,
    //     },
    //   }
    // case restaurantConstants.SAVE_BACKGROUND_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error,
    //     loading: false,
    //   }
    // case restaurantConstants.CLEAR_BACKGROUND:
    //   return {
    //     ...state,
    //     selectedRestaurant: {
    //       ...state.selectedRestaurant,
    //       background: {},
    //     },
    //   }
    // case restaurantConstants.DELETE_LOGO_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   }
    // case restaurantConstants.DELETE_LOGO_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     data: {
    //       ...state.data,
    //       logo: null,
    //     },
    //   }
    // case restaurantConstants.DELETE_LOGO_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error,
    //     loading: false,
    //   }
    // case restaurantConstants.DELETE_BACKGROUND_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   }
    // case restaurantConstants.DELETE_BACKGROUND_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     data: {
    //       ...state.data,
    //       background: null,
    //     },
    //   }
    // case restaurantConstants.DELETE_BACKGROUND_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error,
    //     loading: false,
    //   }
    case restaurantConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case restaurantConstants.CLEAR_STATE:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
