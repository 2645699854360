import React from 'react'
import priceIcon from '../images/price.svg'
import starIcon from '../images/star.svg'

const priceMarks = [
  {
    value: 1,
    label: (
      <div className='row'>
        <img src={priceIcon} alt='' height='12' width='13' />
      </div>
    ),
  },
  {
    value: 2,
    label: (
      <div className='row'>
        <img src={priceIcon} alt='' height='12' width='13' />
        <img src={priceIcon} alt='' height='12' width='13' />
      </div>
    ),
  },
  {
    value: 3,
    label: (
      <div className='row'>
        <img src={priceIcon} alt='' height='12' width='13' />
        <img src={priceIcon} alt='' height='12' width='13' />
        <img src={priceIcon} alt='' height='12' width='13' />
      </div>
    ),
  },
  {
    value: 4,
    label: (
      <div className='row'>
        <img src={priceIcon} alt='' height='12' width='13' />
        <img src={priceIcon} alt='' height='12' width='13' />
        <img src={priceIcon} alt='' height='12' width='13' />
        <img src={priceIcon} alt='' height='12' width='13' />
      </div>
    ),
  },
]

const ratingMarks = [
  {
    value: -2,
    label: (
      <div className='row'>
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
      </div>
    ),
  },
  {
    value: -3,
    label: (
      <div className='row'>
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
      </div>
    ),
  },
  {
    value: -4,
    label: (
      <div className='row'>
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
      </div>
    ),
  },
  {
    value: -5,
    label: (
      <div className='row'>
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
        <img src={starIcon} alt='' height='12' width='13' />
      </div>
    ),
  },
]

const distanceMarks = [
  {
    value: 5,
    label: '5 mi',
  },
  {
    value: 15,
    label: '15 mi',
  },
  {
    value: 25,
    label: '25 mi',
  },
  {
    value: 35,
    label: '35+ mi',
  },
]

export { priceMarks, ratingMarks, distanceMarks }
