import React from 'react'
import { makeStyles } from '@material-ui/core'
import { getImage } from '../../../../helpers/get-image'
import storeMarkerIcon from '../../../../images/store-marker.svg'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  marker: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: 28,
    height: 44,
    transform: 'translate(-50%, 0%)',
  },
  biggerMarker: {
    width: 49,
    height: 76,
    zIndex: 10,
  },
  logo: {
    position: 'absolute',
    top: 2,
    left: 4,
    borderRadius: 10,
  },
  bigLogo: {
    top: 7,
    left: 9,
    borderRadius: 15,
    backgroundColor: 'green',
  },
}))

export const StoreMarkerComponent = ({ logo, onPress, index, slideIndex }) => {
  const classes = useStyles()

  const storeLogo = logo && getImage(logo, 'thumb')

  const onMarkerPress = (i) => onPress(i)

  const isEqual = index === slideIndex

  return (
    <div className={clsx(classes.marker, { [classes.biggerMarker]: isEqual })} onClick={() => onMarkerPress(index)}>
      {logo && (
        <img
          src={storeLogo}
          alt='logo'
          width={isEqual ? '30' : '20'}
          height={isEqual ? '30' : '20'}
          className={clsx(classes.logo, { [classes.bigLogo]: isEqual })}
        />
      )}
      <img src={storeMarkerIcon} alt='home' width={isEqual ? '49' : '28'} height={isEqual ? '76' : '44'} />
    </div>
  )
}
