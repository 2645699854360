import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, TextField } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { BlackButton, CustomFormatCurrency, GreenButton } from '../../../../utils/tools'
import { checkAddValueStatus, checkUpdateValueStatus } from '../../../../../helpers/merchant/button-status'

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
  row: {
    paddingBottom: theme.spacing(),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
    minWidth: 90,
  },
}))

const AddValueFields = ({ intl, attributeOptions, handleChange, onFormSubmit, isEditActive, onClose, benchmark }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const addButtonStatus = checkAddValueStatus(attributeOptions)
  const updateButtonStatus = checkUpdateValueStatus(attributeOptions, benchmark)

  return (
    <Grid container className={classes.root}>
      <Grid xs={12} item className={classes.row}>
        <TextField
          variant='outlined'
          required
          fullWidth
          id='name'
          name='name'
          value={attributeOptions.name}
          label={getLabel('name-label')}
          autoFocus
          onChange={handleChange}
          inputProps={{
            maxLength: 50,
          }}
        />
      </Grid>
      <Grid xs={12} item className={classes.row}>
        <TextField
          variant='outlined'
          fullWidth
          multiline={true}
          maxRows={5}
          id='description'
          name='description'
          value={attributeOptions.description}
          label={getLabel('description-label')}
          onChange={handleChange}
          helperText={250 - attributeOptions.description.length + ' remains'}
          inputProps={{
            maxLength: 250,
          }}
        />
      </Grid>
      <Grid xs={12} item className={classes.row}>
        <TextField
          variant='outlined'
          fullWidth
          required
          id='price'
          name='price'
          value={attributeOptions.price}
          label={getLabel('price-label')}
          onChange={handleChange}
          // helperText={error ? getLabel('enter-valid-price') : ''}
          // error={error}
          InputProps={{
            inputComponent: CustomFormatCurrency,
          }}
        />
      </Grid>
      <Grid xs={12} item className={classes.actionRow}>
        <BlackButton className={classes.button} onClick={onClose}>
          {getLabel('cancel-label')}
        </BlackButton>
        <GreenButton
          disabled={isEditActive ? updateButtonStatus : addButtonStatus}
          className={classes.button}
          onClick={onFormSubmit}
        >
          {getLabel(isEditActive ? 'update-label' : 'add-label')}
        </GreenButton>
      </Grid>
    </Grid>
  )
}

AddValueFields.propTypes = {
  onFormSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  onClose: PropTypes.func,
  intl: PropTypes.object,
  benchmark: PropTypes.object,
  attributeOptions: PropTypes.object,
  isEditActive: PropTypes.bool,
}

export default injectIntl(AddValueFields)
