import axios from 'axios'
import { uiHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

export const searchService = {
  search,
  pickupSearch,
  getTimeZoneOffset,
}

function search(query) {
  const queryString = require('query-string')
  const stringified = queryString.stringify(query, {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
  })

  return axios.get(`${API_URL}/find/search/ui?${stringified}`, uiHeader()).then((res) => {
    return res
  })
}

function pickupSearch(query) {
  const queryString = require('query-string')
  const stringified = queryString.stringify(query, {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
  })

  return axios.get(`${API_URL}/find/map/ui?${stringified}`, uiHeader()).then((res) => {
    return res
  })
}

function getTimeZoneOffset(lat, long) {
  let timestamp = Date.now() / 1000
  var config = {
    method: 'get',
    url: `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=${timestamp}&key=${GOOGLE_API_KEY}`,
    headers: {},
  }
  return axios(config).then((res) => {
    return res
  })
}
