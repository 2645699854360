import React from 'react'
import homeMarkerIcon from '../../../../images/home-marker.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  home: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: 24,
    height: 38,
    transform: 'translate(-50%, 0%)',
  },
}))

const HomeMarker = ({ lat, lng }) => {
  const classes = useStyles()

  return (
    <div className={classes.home}>
      <img src={homeMarkerIcon} alt='home' width='24' height='38' />
    </div>
  )
}

HomeMarker.propTypes = {}

export default HomeMarker
