import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Header from './Header'
// import HeaderTablet from '../TabletCart/HeaderTablet'
import Signin from '../../../authentication/signin'
import CartPayment from '../CartPayment'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    "&[tablet-view='true']": {
      width: '100%',
      marginBottom: 20,
    },
    "&[tablet-view='false']": {
      flex: '2 3 1141px',
      borderRight: '1px solid #c4c4c4',
    },
  },
  body: {
    paddingLeft: 27,
    paddingRight: 29.5,
    paddingBottom: 26,
    "&[tablet-view='true']": {
      padding: 0,
    },
    "&[signin-tablet='true']": {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  signinContainer: {
    display: 'flex',
    justifyContent: 'center',
    "&[tablet-view='true']": {
      maxWidth: 400,
    },
  },
}))

const LeftPart = ({
  selectCard,
  handlePaymentMethod,
  selectedCard,
  cards,
  getCardElement,
  elements,
  paymentMethodHandler,
  tabletView,
  handleTip,
  handleCustomTip,
  clearCustomTip,
  cost,
  tip,
}) => {
  const classes = useStyles()
  const signedIn = useSelector(({ customer }) => customer.signedIn)

  return (
    <div className={classes.root} tablet-view={tabletView ? 'true' : 'false'}>
      {!tabletView && <Header />}
      <div
        className={classes.body}
        tablet-view={tabletView ? 'true' : 'false'}
        signin-tablet={tabletView && !signedIn ? 'true' : 'false'}
      >
        {!signedIn && (
          <div className={classes.signinContainer} tablet-view={tabletView ? 'true' : 'false'}>
            {' '}
            <Signin login={false} modal='inModal' />
          </div>
        )}
        {signedIn && (
          <div>
            <CartPayment
              selectCard={selectCard}
              handlePaymentMethod={handlePaymentMethod}
              selectedCard={selectedCard}
              cards={cards}
              getCardElement={getCardElement}
              elements={elements}
              paymentMethodHandler={paymentMethodHandler}
              tabletView={tabletView}
              handleTip={handleTip}
              handleCustomTip={handleCustomTip}
              clearCustomTip={clearCustomTip}
              cost={cost}
              tip={tip}
            />
          </div>
        )}
      </div>
    </div>
  )
}

LeftPart.propTypes = {
  selectCard: PropTypes.func,
  handlePaymentMethod: PropTypes.func,
  selectedCard: PropTypes.string,
  cards: PropTypes.array,
  getCardElement: PropTypes.func,
  elements: PropTypes.object,
  paymentMethodHandler: PropTypes.func,
  tabletView: PropTypes.bool,
  handleTip: PropTypes.func,
  handleCustomTip: PropTypes.func,
  clearCustomTip: PropTypes.func,
  cost: PropTypes.number,
  tip: PropTypes.object,
}

export default LeftPart
