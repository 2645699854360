import React from 'react'
import PropTypes from 'prop-types'
import imgPlaceholder from '../../../../../images/image-placeholder.svg'
import { Box, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { getImage } from '../../../../../helpers/get-image'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 66,
    height: 66,
    backgroundColor: '#fff',
  },
  storeName: {
    fontSize: 34,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#000',
  },
  storeNameLong: {
    fontSize: 22,
  },
  categoryName: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: '0.34px',
    color: '#000',
  },
}))

const StoreData = ({ storeDetail, logo, selectedCategories }) => {
  const classes = useStyles()

  return (
    <Box mt={2} pb={1} ml={1} mr={1} display='flex' flexDirection='row' style={{ borderBottom: '1px solid #f4f4f4' }}>
      <Box textAlign='center' boxShadow={2} className={classes.paper}>
        <img src={getImage(logo, 'medium') || imgPlaceholder} height='66' width='66' alt='store-logo' />
      </Box>

      <Box ml={1} flexGrow={1} flexDirection='column'>
        <Box className='ellipsis'>
          <Typography
            variant={'h3'}
            className={clsx(classes.storeName, { [classes.storeNameLong]: storeDetail?.nickname.length > 15 })}
          >
            {storeDetail?.nickname}
          </Typography>
        </Box>
        <Box className='ellipsis'>
          {selectedCategories?.map((category, index) => (
            <React.Fragment key={index}>
              <Typography variant='caption' className={classes.categoryName}>
                {category.name}
              </Typography>
              {index !== selectedCategories.length - 1 && (
                <Typography variant='caption' className={classes.categoryName}>{` | `}</Typography>
              )}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

StoreData.propTypes = {
  storeDetail: PropTypes.object,
  selectedCategories: PropTypes.array,
}

export default StoreData
