import React, { useState, Suspense } from 'react'
import { Grid, Grow, IconButton, makeStyles, TextField, Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { BlackButton } from '../../utils/tools'
import CloseIcon from '@material-ui/icons/Close'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FormattedMessage } from 'react-intl'
import MenuSection from './MenuSection'
import { merchantStoreActions } from '../../../actions/merchant.store.actions'
import MerchantNoResult from '../common/MerchantNoResult'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { menuService, restaurantService } from '../../../services'
import { generalConstants } from '../../../constants'
import { useFetchAllStores } from '../hooks/useFetchAllStores'
import { useMemo } from 'react'
const MenuCopyDialog = React.lazy(() => import('./common/MenuCopyDialog'))

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
    margin: theme.spacing(),
    marginLeft: theme.spacing(2),
  },
  new: {
    width: 300,
    marginRight: theme.spacing(),
  },
  noResultContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}))

const Menus = ({ sections, loading }) => {
  const classes = useStyles()
  const selectedStoreInitialValues = { name: '', id: '' }
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()
  //Global states
  const selectedStoreId = useSelector(({ merchantStores }) => merchantStores.selectedStore.storeId)
  const selectedRestaurantId = useSelector(({ merchantStores }) => merchantStores.selectedRestaurant.restaurantId)
  //Local State
  const [newSection, setNewSection] = useState('')
  const [adding, setAdding] = useState(false)
  const [showMenuCopyDialog, setShowMenuCopyDialog] = useState(false)
  const [menuSelectedStore, setMenuSelectedStore] = useState(selectedStoreInitialValues)

  // Actions
  const dispatch = useDispatch()
  const createSection = async (id, data) => dispatch(merchantStoreActions.createSection(id, data))
  const menuCopySucess = () => dispatch(merchantStoreActions.menuCopySuccess())

  const { result: stores } = useFetchAllStores(
    selectedRestaurantId,
    restaurantService.getAllStoresOfRestaurant,
    showMenuCopyDialog
  )

  const formattedStores = useMemo(() => createFormattedStores(stores), [stores])

  function createFormattedStores(stores) {
    const formattedStoreArray = []
    if (stores) {
      stores?.map((store) => {
        if (store.id !== selectedStoreId) {
          let object = {}
          ;[object.id, object.name] = [store.id, store.nickname]
          formattedStoreArray.push(object)
        }
      })
    }
    return formattedStoreArray
  }

  const handleSelectChange = (e) => {
    let selectedStore = formattedStores.find((store) => store.id === e.target.value)
    setMenuSelectedStore(selectedStore)
  }

  const createNewSection = async () => {
    let data = {
      name: newSection,
      description: '...',
      order_: sections?.length,
    }
    await createSection(selectedStoreId, data)
    resetStates()
  }

  const resetStates = () => {
    setAdding((prev) => !prev)
    setNewSection('')
  }

  const handleCloseConfirm = () => {
    setShowMenuCopyDialog(false)
    resetMenuSelectedStore()
  }

  const handleOpenConfirm = () => setShowMenuCopyDialog(true)

  const resetMenuSelectedStore = () => {
    if (menuSelectedStore.name !== '') {
      setMenuSelectedStore(selectedStoreInitialValues)
    }
  }

  const handleCopyMenu = async () => {
    const response = await menuService.copyMenu(menuSelectedStore.id, selectedStoreId)
    if (response.status === generalConstants.STATUS_OK) {
      menuCopySucess()
      handleCloseConfirm()
    } else {
      // handle error here
    }
  }

  if (loading) return null

  return (
    <div
      className={clsx('grow', classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <Grid container style={{ marginTop: 70 }}>
        <Suspense fallback={<div>Loading...</div>}>
          <MenuCopyDialog
            open={showMenuCopyDialog}
            close={handleCloseConfirm}
            callback={handleCopyMenu}
            title='are-you-sure'
            text='copy-menu'
            rightButton='copy-label'
            selectedStore={menuSelectedStore}
            allStores={formattedStores}
            handleChange={handleSelectChange}
            alert={'only-once'}
          />
        </Suspense>

        <Grid item xs={12} className='centered-row'>
          <div className='grow' />
          <Grow in={adding}>
            <TextField
              id='new-section'
              value={newSection}
              onChange={(event) => setNewSection(event.target.value)}
              className={classes.new}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip title={<FormattedMessage id='cancel-label' />}>
                      <IconButton color='inherit' size='small' onClick={resetStates} aria-label='close'>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grow>
          {adding ? (
            <BlackButton disabled={newSection.length === 0} onClick={createNewSection}>
              {<FormattedMessage id='save-section' />}
            </BlackButton>
          ) : (
            <BlackButton onClick={() => setAdding((prev) => !prev)}>
              {<FormattedMessage id='add-section' />}
            </BlackButton>
          )}
        </Grid>
        {sections?.length > 0 ? (
          sections
            .sort((a, b) => a.order_ - b.order_)
            .map((section) => (
              <Grid item xs={12} className={classes.row} key={section.id}>
                <MenuSection section={section} />
              </Grid>
            ))
        ) : (
          <>
            <div className={classes.noResultContainer}>
              <MerchantNoResult text='no-section' />
            </div>
            {formattedStores?.length > 0 && sections?.length === 0 && (
              <div className={classes.noResultContainer}>
                <BlackButton onClick={handleOpenConfirm}>
                  <FormattedMessage id={'copy-menu-label'} />
                </BlackButton>
              </div>
            )}
          </>
        )}
      </Grid>
    </div>
  )
}

export default Menus
