import React from 'react'
import PropTypes from 'prop-types'
import ViewCart from './MobileFooter/ViewCart'
import BottomTabBar from './MobileFooter/BottomTabBar'
import { withRouter } from 'react-router'

const MobileFooter = ({ totalPrice, invisible, viewCartHandler, openSignin }) => {
  return (
    <div>
      {totalPrice > 0 && <ViewCart viewCartHandler={viewCartHandler} totalPrice={totalPrice} />}
      {!invisible && <BottomTabBar openSignin={openSignin} />}
    </div>
  )
}

MobileFooter.propTypes = {
  totalPrice: PropTypes.number,
  viewCartHandler: PropTypes.func,
  openSignin: PropTypes.func,
  invisible: PropTypes.bool,
}

export default withRouter(MobileFooter)
