import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  detailContainer: ({ mobile }) => ({
    width: '42px',
    paddingLeft: mobile ? 20 : 44.5,
    minHeight: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }),
  detailText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#000000',
  },
  bottomMargin: {
    marginBottom: 2,
  },
  listItem: {
    padding: '0px 0px 0px 16px',
    margin: 0,
  },
  instructionText: {
    fontWeight: 'bold',
  },
}))

export const AttributeDetails = React.memo(({ i, orderDetail, mobile }) => {
  const classes = useStyles({ mobile })
  const [selectedIndex, setSelectedIndex] = useState()

  const handleCollapse = (i) => {
    i === selectedIndex ? setSelectedIndex() : setSelectedIndex(i)
  }

  return (
    <>
      <div onClick={() => handleCollapse(i)} className={classes.detailContainer}>
        <Typography className={classes.detailText}>Details</Typography>
        {i === selectedIndex ? (
          <ExpandMoreIcon className={classes.bottomMargin} />
        ) : (
          <NavigateNextIcon className={classes.bottomMargin} />
        )}
      </div>

      <Collapse in={i === selectedIndex} timeout='auto' unmountOnExit style={{ paddingLeft: 28.5 }}>
        <List component='div' disablePadding>
          {orderDetail?.attributes.map((attr, ind) => (
            <ListItem key={ind} className={classes.listItem}>
              <ListItemText>
                <Typography variant={mobile ? 'caption' : 'body1'} className={classes.caption} noWrap>
                  {attr?.attributeValueName}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        {!!orderDetail?.orderMenu?.specialInstructions && (
          <List component='div' disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Typography variant={mobile ? 'caption' : 'body1'} className={classes.instructionText} noWrap>
                  Special Instructions:
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              {/* <ListItemText> */}
              <Typography variant={mobile ? 'caption' : 'body1'} className={classes.caption}>
                {orderDetail?.orderMenu?.specialInstructions}
              </Typography>
              {/* </ListItemText> */}
            </ListItem>
          </List>
        )}
      </Collapse>
    </>
  )
})

AttributeDetails.propTypes = {
  orderDetail: PropTypes.object,
  i: PropTypes.number,
  mobile: PropTypes.bool,
}

export default AttributeDetails
