import pickupIcon from '../../images/address-pick-up.svg'
import pickupIconSelected from '../../images/address-pick-up-selected.svg'
import deliveryIcon from '../../images/address-delivery.svg'
import deliveryIconSelected from '../../images/address-delivery-selected.svg'
import deliverDoorIcon from '../../images/deliver-door.svg'
import deliverDoorIconSelected from '../../images/deliver-door-selected.svg'
import meetOutsideIcon from '../../images/meet-outside.svg'
import meetOutsideIconSelected from '../../images/meet-outside-selected.svg'
import meetDoorIcon from '../../images/meet-door.svg'
import meetDoorIconSelected from '../../images/meet-door-selected.svg'

const deliveryTypesList = [
  {
    label: 'pick-up',
    type: 'TAKEOUT',
    callback: 'handlePickup',
    icon: pickupIcon,
    iconSelected: pickupIconSelected,
  },
  {
    label: 'delivery-label',
    type: 'DELIVERY',
    callback: 'handleDelivery',
    icon: deliveryIcon,
    iconSelected: deliveryIconSelected,
  },
]

const deliveryDetailsList = [
  {
    label: 'deliver-door',
    type: 'DELIVERY',
    subType: 'DELIVER_DOOR',
    icon: deliverDoorIcon,
    iconSelected: deliverDoorIconSelected,
  },
  {
    label: 'meet-door',
    type: 'DELIVERY',
    subType: 'MEET_DOOR',
    icon: meetDoorIcon,
    iconSelected: meetDoorIconSelected,
  },
  {
    label: 'meet-outside',
    type: 'DELIVERY',
    subType: 'MEET_OUTSIDE',
    icon: meetOutsideIcon,
    iconSelected: meetOutsideIconSelected,
  },
]

export { deliveryTypesList, deliveryDetailsList }
