import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { GreenCheckbox } from '../../../utils/tools'
import {
  valueConstants,
  notificationConstants,
  notificationValues,
  labelConstants,
} from '../../../businessRegister/businessConstants'

import PhoneSelection from '../common/PhoneSelection'
import EmailSelection from '../common/EmailSelection'
import { splitPhoneNo } from '../../../../helpers/merchant/splitPhoneNo'

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...flexColumn,
    width: '100%',
  },
  gridContainer: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(),
  },
  gridItem: {
    ...flexColumn,
    alignItems: 'flex-start',
  },
  label: {
    maxWidth: 150,
  },
  errorText: {
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    fontWeight: 300,
    marginTop: theme.spacing(2.5),
    color: theme.palette.secondary.main,
  },
  bankInfoText: {
    width: '100%',
    height: '100%',
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
}))

const Notifications = ({
  store,
  setStore,
  isInvalid,
  isFocused,
  setIsInvalid,
  setIsFocused,
  editting,
  storeInformation,
  orderConfirmPhone,
  orderConfirmCode,
  setOrderConfirmCode,
  setOrderConfirmPhone,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const [phoneVal, setPhoneVal] = useState('')
  const [emailVal, setEmailVal] = useState('')

  useEffect(() => {
    if (store.notificationType.length === 2 || store.notificationType.length === 0) {
      handlePhoneVal()
      handleEmailVal()
    }
    if (store.notificationType.includes(valueConstants.notificationPhone)) {
      handlePhoneVal()
    } else if (store.notificationType.includes(valueConstants.notificationEmail)) {
      handleEmailVal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, orderConfirmPhone])

  const handlePhoneVal = () => {
    let phonePart = splitPhoneNo(storeInformation.phone)[1]
    if (orderConfirmPhone.length > 0 && orderConfirmPhone === phonePart) {
      setPhoneVal(notificationConstants.storePhone)
    } else {
      setPhoneVal(notificationConstants.otherPhone)
    }
  }

  const handleEmailVal = () => {
    let { orderConfirmationEmail } = store
    if (orderConfirmationEmail.length > 0 && orderConfirmationEmail === storeInformation.email) {
      setEmailVal(notificationConstants.storeEmail)
    } else {
      setEmailVal(notificationConstants.otherEmail)
    }
  }

  const handlePhone = (value) => {
    if (value === notificationConstants.storePhone) {
      let [codePart, phonePart] = splitPhoneNo(storeInformation.phone)
      setOrderConfirmCode(codePart)
      setOrderConfirmPhone(phonePart)
    } else {
      setOrderConfirmPhone('')
      setOrderConfirmCode('+1')
    }
  }

  const selectPhone = (e) => {
    setIsInvalid({
      ...isInvalid,
      orderConfirmationPhone: false,
    })

    setPhoneVal(e.target.value)
    handlePhone(e.target.value)
  }

  const handleEmail = (value) => {
    if (value === notificationConstants.storeEmail) {
      setStore({
        ...store,
        orderConfirmationEmail: storeInformation.email,
      })
    } else {
      setStore({
        ...store,
        orderConfirmationEmail: '',
      })
    }
  }

  const selectEmail = (e) => {
    setEmailVal(e.target.value)
    handleEmail(e.target.value)
  }

  const handleChange = (prop) => (e) => {
    let val = e.target.value
    if (store.notificationType.includes(val)) {
      let prop2 =
        val === valueConstants.notificationPhone
          ? valueConstants.orderConfirmationPhone
          : valueConstants.orderConfirmationEmail
      if (prop2 === valueConstants.orderConfirmationEmail) {
        setStore({
          ...store,
          [prop]: store.notificationType.filter((nt) => nt !== val),
          orderConfirmationEmail: '',
        })
      } else {
        setStore({
          ...store,
          [prop]: store.notificationType.filter((nt) => nt !== val),
        })
        setOrderConfirmPhone('')
        setOrderConfirmCode('+1')
      }
    } else {
      setStore({
        ...store,
        [prop]: [...store.notificationType, val],
      })
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant='h5'>{f({ id: 'notifications-label' })}</Typography>
      </div>
      <FormControl fullWidth>
        <FormGroup aria-label='position' row>
          {notificationValues?.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <GreenCheckbox
                  checked={store?.notificationType?.includes(item)}
                  onChange={handleChange('notificationType')}
                  value={item}
                  disabled={!editting}
                />
              }
              label={
                <Typography>
                  {item === notificationValues[0]
                    ? f({ id: labelConstants.textMessage })
                    : f({ id: labelConstants.email })}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </FormControl>
      {store.notificationType.includes(valueConstants.notificationPhone) && (
        <Typography>{f({ id: 'available-phone-text-message' })}</Typography>
      )}
      <Grid container spacing={2} className={clsx('full-width', [classes.gridContainer])}>
        {store.notificationType.includes(valueConstants.notificationPhone) && (
          <PhoneSelection
            classes={classes}
            orderConfirmation={phoneVal}
            handleCheck={selectPhone}
            orderConfirmCode={orderConfirmCode}
            orderConfirmPhone={orderConfirmPhone}
            editting={editting}
            setOrderConfirmCode={setOrderConfirmCode}
            setOrderConfirmPhone={setOrderConfirmPhone}
            isFocused={isFocused}
            isInvalid={isInvalid}
            setIsInvalid={setIsInvalid}
            setIsFocused={setIsFocused}
            valProp={valueConstants.orderConfirmationPhone}
          />
        )}
        {store.notificationType.includes(valueConstants.notificationEmail) && (
          <EmailSelection
            classes={classes}
            orderConfirmation={emailVal}
            handleCheck={selectEmail}
            store={store}
            setStore={setStore}
            setIsInvalid={setIsInvalid}
            setIsFocused={setIsFocused}
            isFocused={isFocused}
            isInvalid={isInvalid}
            valProp={valueConstants.orderConfirmationEmail}
            editting={editting}
          />
        )}
      </Grid>
      {store?.notificationType.length === 0 && !editting && (
        <div className={classes.bankInfoText}>
          <Typography variant='h5' color='secondary'>
            {f({ id: 'select-notification-method' })}
          </Typography>
        </div>
      )}
    </div>
  )
}

Notifications.propTypes = {
  store: PropTypes.object,
  setStore: PropTypes.func,
  isInvalid: PropTypes.object,
  isFocused: PropTypes.object,
  setIsInvalid: PropTypes.func,
  setIsFocused: PropTypes.func,
  editting: PropTypes.bool,
  storeInformation: PropTypes.object,
  orderConfirmPhone: PropTypes.string,
  orderConfirmCode: PropTypes.string,
  setOrderConfirmCode: PropTypes.func,
  setOrderConfirmPhone: PropTypes.func,
}

export default Notifications
