import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  grid: {
    paddingLeft: 5,
    paddingRight: 10,
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 16,
    color: '#0d0d0d',
  },
  totalText: {
    marginBottom: 10,
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}))

const CartItem = ({ title, price, total, oldPrice }) => {
  const classes = useStyles()
  return (
    <Grid container direction='row' alignItems='center' className={classes.grid}>
      <Grid item>
        <Typography className={clsx(classes.text, { [classes.totalText]: total })} color='textPrimary'>
          {title}
        </Typography>
      </Grid>
      <Grid item className={classes.row}>
        {oldPrice && (
          <Typography
            className={classes.text}
            color='textSecondary'
            style={{ paddingRight: 10, textDecorationLine: 'line-through' }}
          >
            $ {oldPrice?.toFixed(2)}
          </Typography>
        )}
        <Typography className={clsx(classes.text, { [classes.totalText]: total })} color='textPrimary'>
          ${(+price).toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default React.memo(CartItem)
