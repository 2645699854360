import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: 2,
  },
}))

export const QuantityBox = ({ children }) => {
  const classes = useStyles()
  return (
    <Box width={16} height={16} bgcolor='#69e781' textAlign='center' className={classes.box}>
      <Typography variant='caption' style={{ color: '#fff', fontWeight: 'bold' }}>
        {children}
      </Typography>
    </Box>
  )
}

QuantityBox.propTypes = {
  children: PropTypes.number,
}

export default React.memo(QuantityBox)
