import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from '../utils/tools'
import Dialog from '@material-ui/core/Dialog'
import SetAddress from '../customer/mobile-address-set/SetAddress'

const AddressDialog = ({ open, close }) => (
  <Dialog fullScreen maxWidth={false} open={open} TransitionComponent={Transition}>
    <SetAddress goToScreen={close} />
  </Dialog>
)

AddressDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
}

export default AddressDialog
