import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { GreenRadio } from '../../../../utils/tools'
import { emailRegex } from '../../../../utils/regex_collections'

import { paymentDetailConstants, valueConstants, labelConstants, emailAreaConstants } from '../../../businessConstants'

const EmailField = ({ classes, values, handleChange, setValues, error }) => {
  const { formatMessage: f } = useIntl()

  const [emailField, setEmailField] = useState(emailAreaConstants[0])

  useEffect(() => {
    if (values) {
      if (values.paymentContactEmail === values.email) {
        setEmailField(emailAreaConstants[0])
      } else if (values.paymentContactEmail === values.storeEmail) {
        setEmailField(emailAreaConstants[1])
      } else {
        setEmailField(emailAreaConstants[2])
      }
    }
  }, [values])

  const handleEmailField = (e) => {
    setEmailField(e.target.value)
    handleContactEmail(e.target.value)
  }

  const handleContactEmail = (value) => {
    if (value === paymentDetailConstants.myEmail) {
      setValues({
        ...values,
        paymentContactEmail: values.email,
      })
    } else if (value === paymentDetailConstants.storeEmail) {
      setValues({
        ...values,
        paymentContactEmail: values.storeEmail,
      })
    } else {
      setValues({
        ...values,
        paymentContactEmail: paymentDetailConstants.empty,
      })
    }
  }

  return (
    <Grid item md={6} sm={12} xs={12} className={classes.gridItem}>
      <FormControl fullWidth>
        {emailAreaConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            control={<GreenRadio checked={emailField === item} onChange={handleEmailField} value={item} />}
            label={<Typography>{item}</Typography>}
          />
        ))}
      </FormControl>
      <TextField
        size='small'
        value={values.paymentContactEmail}
        variant='outlined'
        fullWidth
        id={valueConstants.paymentContactEmail}
        InputLabelProps={{ shrink: true }}
        label={f({ id: labelConstants.paymentContactEmail })}
        onChange={handleChange(valueConstants.paymentContactEmail)}
        className={classes.textfields}
        disabled={emailField !== emailAreaConstants[2]}
        error={
          error && values.paymentContactEmail.length === 0
            ? true
            : emailRegex.test(values.paymentContactEmail)
            ? false
            : true
        }
        inputProps={{
          maxLength: 100,
        }}
      />
    </Grid>
  )
}

EmailField.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  setValues: PropTypes.func,
  error: PropTypes.bool,
}

export default EmailField
