import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  text1: {
    lineHeight: '1.5em',
  },
  text2: {
    lineHeight: '1.5em',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  button: {
    minWidth: 85,
    "&[add-button='true']": {
      marginRight: theme.spacing(),
    },
  },
}))

const SubmissionPart = ({ handleReview, handleSubmit }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <div className={classes.root}>
      <Typography className={classes.text1} variant='h5'>
        {f({ id: 'thanks-provided-info' })}
      </Typography>
      <Typography className={classes.text2} variant='h6'>
        {f({ id: 'submit-or-review' })}
      </Typography>
      <div className={classes.buttonContainer}>
        <Button disableRipple variant='contained' add-button='true' onClick={handleReview} className={classes.button}>
          {f({ id: 'review-label' })}
        </Button>
        <Button disableRipple variant='contained' color='primary' onClick={handleSubmit} className={classes.button}>
          {f({ id: 'submit-label' })}
        </Button>
      </div>
    </div>
  )
}

SubmissionPart.propTypes = {
  handleReview: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export default SubmissionPart
