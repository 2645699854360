import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  main: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  secondary: {
    color: '#707070',
  },
}))

const Info = ({ main, secondary }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.main}>{main}</div>
      <div className={classes.secondary}>{secondary}</div>
    </div>
  )
}

export default Info
