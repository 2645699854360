import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import InputAdornment from '@material-ui/core/InputAdornment'
import PropTypes from 'prop-types'
import '../../styles/address_search.scss'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    paddingLeft: 7.5,
  },
  input: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
    width: '100%',
    fontSize: 13,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: '#707070',
    backgroundColor: '#fff',
  },
  inputNavbar: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#707070',
    marginTop: 5,
    width: '100%',
    fontStyle: 'normal',
  },
  inputAddAddress: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 3.13,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
    width: '100%',
    fontStyle: 'normal',
  },
  inputText: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    color: '#707070',
  },
}))

function SearchAddress(props) {
  const classes = useStyles()
  const [query, setQuery] = useState()
  const autoCompleteRef = useRef(null)

  useEffect(() => {
    setQuery(props.value)
  }, [props.value])

  // useEffect(() => {
  //   const loadScript = (src) => {
  //     const tag = document.createElement('script');
  //     tag.async = false;
  //     tag.src = src;
  //     const body = document.getElementsByTagName('body')[0];
  //     body.appendChild(tag);
  //   }

  //   loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE}&libraries=places`);
  // }, [])

  let autoComplete

  const handleScriptLoad = (onChange, handleClose, updateQuery, autoCompleteRef, typesRef) => {
    var options = {
      types: typesRef,
      componentRestrictions: { country: 'us' },
    }

    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, options)
    autoComplete.setFields(['address_components', 'formatted_address', 'geometry.location', 'utc_offset_minutes'])
    autoComplete.addListener('place_changed', () => handlePlaceSelect(onChange, handleClose, updateQuery))
  }

  const handlePlaceSelect = async (onChange, handleClose, updateQuery) => {
    const addressObject = autoComplete.getPlace()
    if (addressObject) {
      const query = addressObject.formatted_address
      updateQuery(query)
      onChange(addressObject)
    } else {
      window.alert('No details available for input')
      return
    }

    if (handleClose) handleClose()
  }

  useEffect(() => {
    handleScriptLoad(props.onChange, props.handleClose, setQuery, autoCompleteRef, props.typesRef)
  }, [])

  const addressSearch = (event) => {
    if (event.target.value) {
      props.hideCurrent && props.hideCurrent()
    } else {
      props.showCurrent && props.showCurrent()
    }
    setQuery(event.target.value)
    props.addAddress && props.onFocusListener(event)
  }

  const onFocus = (event) => {
    if (event.target.autocomplete) event.target.autocomplete = 'none'
    props.addAddress && props.onFocusListener(event)
  }

  return (
    <div className={classes.root}>
      <InputBase
        label='Address'
        readOnly={props.readOnly}
        onFocus={onFocus}
        autoFocus={props.component !== 'beMember'}
        inputProps={{
          autoComplete: 'off',
          form: {
            autoComplete: 'off',
          },
          className: props.addAddress ? classes.input : classes.inputText,
        }}
        startAdornment={
          props.startAdornment && (
            <InputAdornment position='start'>
              {props.navbar ? (
                <img src={props.startAdornment} alt='' height='20px' width='15px' style={{ marginLeft: 8 }} />
              ) : (
                <img src={props.startAdornment} alt='' height='16' />
              )}
            </InputAdornment>
          )
        }
        endAdornment={
          props.endAdornment && (
            <InputAdornment position='start'>
              <img src={props.endAdornment} alt='' height='16' />
            </InputAdornment>
          )
        }
        inputRef={autoCompleteRef}
        placeholder={props.placeholder}
        variant='outlined'
        fullWidth
        className={props.navbar ? classes.inputNavbar : props.addAddress ? classes.inputAddAddress : classes.input}
        onChange={(event) => addressSearch(event)}
        value={query ? query : ''}
      />
    </div>
  )
}

SearchAddress.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  handleClose: PropTypes.func,
  placeholder: PropTypes.string,
  typesRef: PropTypes.array,
  readOnly: PropTypes.bool,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  outlined: PropTypes.bool,
}

export default SearchAddress
