export const orderConstants = {
  GETALL_ORDERS_REQUEST: 'GETALL_ORDERS_REQUEST',
  GETALL_ORDERS_SUCCESS: 'GETALL_ORDERS_SUCCESS',
  GETALL_ORDERS_FAILURE: 'GETALL_ORDERS_FAILURE',

  // START_ORDER_REQUEST: 'START_ORDER_REQUEST',
  // START_ORDER_SUCCESS: 'START_ORDER_SUCCESS',
  // START_ORDER_FAILURE: 'START_ORDER_FAILURE',

  SAVE_ORDER_REQUEST: 'SAVE_ORDER_REQUEST',
  SAVE_ORDER_SUCCESS: 'SAVE_ORDER_SUCCESS',
  SAVE_ORDER_FAILURE: 'SAVE_ORDER_FAILURE',

  DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAILURE: 'DELETE_ORDER_FAILURE',

  CREATE_PAYMENT_INTENT_REQUEST: 'CREATE_PAYMENT_INTENT_REQUEST',
  CREATE_PAYMENT_INTENT_SUCCESS: 'CREATE_PAYMENT_INTENT_SUCCESS',
  CREATE_PAYMENT_INTENT_FAILURE: 'CREATE_PAYMENT_INTENT_FAILURE',

  ADD_SIGNEDIN_USERID: 'ADD_SIGNEDIN_USERID',

  ADD_MENU: 'ADD_MENU',
  REMOVE_MENU: 'REMOVE_MENU',
  REMOVE_MENU_ITEM: 'REMOVE_MENU_ITEM',
  CLEAR_ALL_MENUS: 'CLEAR_ALL_MENUS',

  SET_ORDER_ADDRESS: 'SET_ORDER_ADDRESS',
  SET_ORDER_TYPE: 'SET_ORDER_TYPE',

  SELECT_QUANTITY: 'SELECT_QUANTITY',

  SET_ORDERFEES_REQUEST: 'SET_ORDERFEES_REQUEST',
  SET_ORDERFEES_SUCCESS: 'SET_ORDERFEES_SUCCESS',
  SET_ORDERFEES_FAILURE: 'SET_ORDERFEES_FAILURE',

  CLEAR_CART: 'CLEAR_CART',
  CLEAR_ORDERFEES: 'CLEAR_ORDERFEES',

  SET_DELIVERY_SUBTYPE: 'SET_DELIVERY_SUBTYPE',
  SET_INSTRUCTIONS: 'SET_INSTRUCTIONS',

  SET_TIP: 'SET_TIP',
}
