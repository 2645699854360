export const styles = (theme) => ({
  root: {
    // marginTop: 80,
    width: '100%',
    // border: "1px solid lightgrey",
    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    userSelect: 'none',
  },
  menuImageCropMain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menuImageCropContainer: {
    position: 'relative',
    width: '100%',
    height: 220,
    background: '#fff',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 5,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    contain: 'content',
  },
  cropRightPartMain: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 20,
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#fff',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropActionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
    textTransform: 'capitalize',
    fontSize: 16,
    minWidth: 100,
    transition: '0.2s',
    "&[data-content='Delete']": {
      backgroundColor: '#E63946 !important',
    },
    '&:hover': {
      color: 'white',
    },
    "&:hover, &[data-content='Preview']": {
      backgroundColor: '#69e781',
    },
    "&:hover, &[data-content='Save']": {
      backgroundColor: '#69e781',
    },
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: 10,
  },
  sliderLabel: {
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
  input: {
    "&[type='file']": {
      display: 'none',
    },
    "&[type='submit']": {
      display: 'none',
    },
  },
  buttonMainContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  fileUploadButtonContainer: {
    marginTop: 30,
    width: '50%',
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  uploadButton: {
    width: 179,
    height: 50,
    border: '1px solid #69e781',
    backgroundColor: '#69e781',
    borderRadius: 4,
    display: 'flex',
    padding: ' 6px 12px',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow: '0 3px 3px 0 rgba(0,0,0,0.2)',
    transition: ' 0.3s',
    "&[data-content='delete']": {
      backgroundColor: '#E63946 !important',
      border: '1px solid #E63946',
    },
    '&:hover': {
      boxShadow: '0 5px 5px 0 rgba(0,0,0,0.2)',
      '& $buttonText': {
        color: 'white',
      },
      '& $cloudUploadIcon': {
        color: 'white',
      },
    },
  },
  menuImageChooseButton: {
    height: 40,
    fontSize: 14,
    minWidth: 130,
    transition: '0.2s',
    border: '1px solid #69e781',
    backgroundColor: '#69e781',
    borderRadius: 4,
    display: 'flex',
    padding: ' 6px 12px',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow: '0 3px 3px 0 rgba(0,0,0,0.2)',
    '&:hover': {
      boxShadow: '0 5px 5px 0 rgba(0,0,0,0.2)',
      '& $buttonText': {
        color: 'white',
      },
    },
  },
  buttonText: {
    transition: '0.2s',
    fontSize: 18,
    fontWeight: 500,
    "&[data-content='menu-upload']": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  cloudUploadIcon: {
    transition: '0.2s',
    color: 'black',
    "&[function='delete']": {
      fontSize: 25,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
  },
  // wiggle: {
  //   '&:hover': {
  //     animation: `$wiggle 3000ms ${theme.transitions.easing.easeInOut}`,
  //   },
  // },
  // "@keyframes wiggle": {
  //   "0%": {
  //     transform: "translate(5px, 0)"
  //   },
  //   "50%": {
  //     transform: "translate(-5px, 0)"
  //   },
  //   "100%": {
  //     transform: "translate(5px, 0)"
  //   }
  // },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
})
