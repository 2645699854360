import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import MenuSectionItem from '../MenuSectionItem'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: 180,
    top: 0,
    margin: 'auto',
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:hover': {
      border: 'solid 1px #69e781',
    },
  },
  addIcon: {
    height: 75,
    width: 75,
    marginTop: 8,
    '&:hover': {
      color: '#69e781',
    },
  },
  column: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const SectionBody = ({ expand, section }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Collapse in={expand}>
      <Grid container spacing={2}>
        {section.menus
          .filter((menu) => menu.active)
          .map((i) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={i.id}>
              <div className='centered-column'>
                <MenuSectionItem item={i} sectionId={section.id} />
              </div>
            </Grid>
          ))}

        <Grid item sm={4} xs={12}>
          <div
            className='centered-column'
            onClick={() => history.push(`${path}/merchant/menus/add?sectionid=${section.id}`)}
          >
            <Card className={classes.card} variant='outlined'>
              <CardContent>
                <div className={classes.column}>
                  <AddIcon className={classes.addIcon} />
                </div>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Collapse>
  )
}

SectionBody.propTypes = {
  expand: PropTypes.bool,
  section: PropTypes.object,
}

export default SectionBody
