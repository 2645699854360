import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const Promo = ({ promotion, pr = 1, pl = 1 }) => {
  return (
    <Box mt={1} pb={1} pr={pr} pl={pl} display='flex' flexDirection='row' alignItems='center'>
      <Box width='30%'>
        <Typography variant={'h6'} color='primary'>
          <FormattedMessage id='promo-label' />
        </Typography>
      </Box>
      <Box width='70%' textAlign='right'>
        <Typography variant={'h6'} style={{ fontWeight: 'bold' }} noWrap>
          {promotion?.name}
        </Typography>
      </Box>
    </Box>
  )
}

Promo.propTypes = {
  promotion: PropTypes.object,
}

export default React.memo(Promo)
