import React from 'react'
import { useIntl } from 'react-intl'

import { Typography, FormControlLabel, Radio } from '@material-ui/core'

import { idFinder } from '../../../../../helpers/labelForm/idFinder'
import { parameters } from '../../../../../helpers/sort/parameters'

const LabelForm = ({ classes, value }) => {
  const { formatMessage: f } = useIntl()
  const radioValue = parameters(value).combined
  return (
    <FormControlLabel
      value={radioValue}
      name={value}
      control={<Radio className={classes?.radio} color='primary' />}
      label={
        <Typography variant='body2' color='textSecondary'>
          {f({ id: idFinder(value) })}
        </Typography>
      }
    />
  )
}

export default LabelForm
