import { handleWorkingHours, handlePhoneNo, handleTierArray } from './Controls'
import { valueConstants } from './Constants'

export const handleSubmitQuery = (addressObj, checkAddressObj, useStoreAddressForCheck, values) => {
  const { directDeposit } = valueConstants
  const {
    suiteNo,
    checkSuiteNo,
    paymentMethod,
    storePhone,
    storeName,
    storeNumber,
    acceptSameDayOrder,
    minOrderPrice,
    maxDeliveryDistance,
    deliveryBy,
    notificationType,
    orderConfirmationEmail,
    storeEmail,
    taxIdNumber,
    accountNumber,
    routingNumber,
    paymentContactName,
    paymentContactEmail,
    minHeadCount,
    categoryId,
  } = values

  let address = {
    ...addressObj,
    addressLine2: suiteNo,
  }

  let checkAddress_ = {
    ...checkAddressObj,
    addressLine2: useStoreAddressForCheck ? suiteNo : checkSuiteNo,
  }

  let checkAddress = paymentMethod === directDeposit ? null : checkAddress_
  let checkSuiteNo_ = paymentMethod === directDeposit ? null : checkSuiteNo

  let phone = handlePhoneNo(storePhone)
  let paymentContactPhone = handlePhoneNo(values.paymentContactPhone)
  let orderConfirmationPhone = handlePhoneNo(values.orderConfirmationPhone)
  let workingHours = handleWorkingHours(values.workingHours)
  let tierInformations = handleTierArray(values)

  let query = {
    nickname: storeName,
    storeNumber,
    acceptSameDayOrder,
    minOrderPrice,
    maxDeliveryDistance,
    deliveryTime: null,
    active: false,
    deliveryBy,
    notificationType,
    orderConfirmationPhone,
    orderConfirmationEmail,
    minHeadCount,
    categoryId,
    storeInformation: {
      email: storeEmail,
      phone,
      address,
      workingHours,
    },
    tierInformations,
    bankInformation: {
      taxIdNumber,
      ownerName: null,
      ownerBirthdate: null,
      accountNumber,
      routingNumber,
      checkSuiteNo: checkSuiteNo_,
      paymentContactName,
      paymentContactPhone,
      paymentContactEmail,
      paymentMethod,
      checkAddress,
    },
  }

  return query
}
