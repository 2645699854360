import React from 'react'
import { useSelector } from 'react-redux'
import { useMerchantHomeContext } from '../../../../context/merchant/MerchantHome/main'
import RestaurantAdminView from './role-based/RestaurantAdminView'
import StoreView from './role-based/StoreView'

export const RoleBasedMenu = () => {
  const {
    values: { isRestaurantAdmin },
  } = useMerchantHomeContext()

  const isRestaurant = useSelector(({ merchantStores }) => merchantStores.isRestaurantView)

  return <>{isRestaurantAdmin && isRestaurant ? <RestaurantAdminView /> : <StoreView />}</>
}

export default RoleBasedMenu
