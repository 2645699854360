import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import { injectIntl } from 'react-intl'

const TextInput = ({ intl, propss }) => {
  const { isFocused, isInvalid, user, handleChange, field } = propss
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const helperText = field === 'email' ? getLabel(`enter-user-${field}`) : getLabel(`enter-${field}`)

  return (
    <Grid item sm={6} xs={12}>
      <TextField
        size='small'
        variant='outlined'
        required
        fullWidth
        id={field}
        name={field}
        label={getLabel(`${field}-label`)}
        value={user[field] || ''}
        onChange={handleChange}
        inputProps={{ maxLength: 150 }}
        error={isFocused[field] && isInvalid[field]}
        helperText={isFocused[field] && isInvalid[field] ? helperText : ' '}
      />
    </Grid>
  )
}

TextInput.propTypes = {
  propss: PropTypes.object.isRequired,
}

export default injectIntl(TextInput)
