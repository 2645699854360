import React from 'react'
import { withRouter } from 'react-router-dom'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { history } from '../../helpers'
import { MerchantPrivateRoute } from '../../routes'
import MerchantHome from './MerchantHome'
import MerchantSignin from './authentication/Login'

function MerchantMain() {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route history={history} exact path={`${path}/signin`}>
        <MerchantSignin />
      </Route>
      <MerchantPrivateRoute path={path} component={MerchantHome} />
    </Switch>
  )
}

export default withRouter(MerchantMain)
