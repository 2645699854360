import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import SelectedMenu from '../../WebCart/SelectedMenu'
import GreenSelect from '../../../../common/GreenSelect'

const useStyles = makeStyles((theme) => ({
  selectGrid: {
    minHeight: 40,
    borderBottom: '1px solid #f4f4f4',
    marginTop: 5,
    display: 'flex',
    height: 'auto',
    paddingRight: 0,
    overflowX: 'hidden',
  },
}))

const MenuDetails = ({ menu, index, loading, deleteMenuItem, handleQuantityChange }) => {
  const classes = useStyles()

  return (
    <Grid container key={index} className={classes.selectGrid}>
      <Grid xs={2} item>
        <GreenSelect loading={loading} menu={menu} index={index} handleQuantityChange={handleQuantityChange} />
      </Grid>
      <Grid item xs={10}>
        <SelectedMenu menu={menu} index={index} loading={loading} deleteMenuItem={deleteMenuItem} />
      </Grid>
    </Grid>
  )
}

MenuDetails.propTypes = {
  menu: PropTypes.object,
  deleteMenuItem: PropTypes.func,
}

export default MenuDetails
