import { deliveryTypes } from '../../constants'
import { checkScheduledOrAsap } from '../mobile-food/state-maker'

const getOCStatusQuery = (searchQuery, storeIds) => {
  let orderType = searchQuery?.deliveryType || deliveryTypes.DELIVERY
  let query = {
    storeIds,
    orderType,
  }
  checkScheduledOrAsap(searchQuery, query)
  return query
}

export { getOCStatusQuery }
