export const periodValues = [
  {
    value: 6,
    name: 'Last Week',
  },
  {
    value: 29,
    name: 'Last Month',
  },
  {
    value: 100,
    name: 'Overall',
  },
]

export const periods = {
  6: 'Last Week',
  29: 'Last Month',
  100: 'Overall',
}
