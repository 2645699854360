import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { tipButtons } from '../../../../../constants/tip.constants'
import { shallowEqual, useSelector } from 'react-redux'
import { CustomFormatCurrencyNew } from '../../../../utils/tools'
import { Grid, TextField } from '@material-ui/core'
import { deliveryTypes } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    marginTop: theme.spacing(1),
  },
  button: {
    '&[isselected=true]': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderRightColor: theme.palette.primary.main,
    },
  },
  label: {
    textTransform: 'capitalize',
  },
  customTipField: {
    marginTop: theme.spacing(1),
  },
}))

export const TipContainer = ({ handleTip, handleCustomTip, customTip, driverTipPrice }) => {
  const classes = useStyles()
  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)

  if (cart?.type !== deliveryTypes.DELIVERY) return null

  return (
    <Box className={classes.container}>
      <Typography variant='h6'>
        <FormattedMessage id='tip-label' />
      </Typography>
      <ButtonGroup
        fullWidth
        aria-label='large outlined primary button group'
        disableElevation
        classes={{ root: classes.root }}
      >
        {tipButtons.map((button, i) => (
          <Button
            key={i}
            isselected={(driverTipPrice?.isCustom
              ? driverTipPrice?.isCustom === button.isCustom
              : driverTipPrice?.rate === button.value
            ).toString()}
            onClick={() => handleTip(button.value)}
            classes={{ label: classes.label, root: classes.button }}
          >
            {i === tipButtons.length - 1 ? <FormattedMessage id={button.label} /> : button.label}
          </Button>
        ))}
      </ButtonGroup>
      {driverTipPrice?.isCustom && (
        <Grid item sm={6} xs={12}>
          <TextField
            autoFocus
            autoComplete={'off'}
            size='small'
            margin='normal'
            variant='outlined'
            required={driverTipPrice?.isCustom}
            fullWidth
            id='customTip'
            name='customTip'
            value={driverTipPrice?.value || ''}
            onChange={handleCustomTip}
            InputProps={{
              inputComponent: CustomFormatCurrencyNew,
            }}
            error={customTip.isFocused && customTip.isInvalid}
            // helperText={customTip.isFocused && customTip.isInvalid ? <FormattedMessage id='cannot-null' /> : ' '}
          />
        </Grid>
      )}
    </Box>
  )
}

// TipContainer.propTypes = {
//   second: PropTypes.third,
// }

export default React.memo(TipContainer)
