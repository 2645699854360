import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import verifiedIcon from '../../../../images/verified.svg'
import notVerifiedIcon from '../../../../images/not-verified.svg'

import { GreenButton } from '../../../utils/tools'

import Fields from './Fields'

const starsPass = '* * * * * * * *'

const RightSide = ({ classes, customer, handlePasswordChange, handlePhoneVerification, goToAddresses, loading }) => {
  const { formatMessage: f } = useIntl()
  const [defaultAddress, setDefaultAddress] = useState(null)

  useEffect(() => {
    let active = true
    if (active) {
      let defaultAddr = customer?.savedAddresses.filter((sa) => sa.default_ === true)
      setDefaultAddress(defaultAddr)
    }
    return () => {
      active = false
    }
  }, [customer])

  return (
    <Box className={classes.block}>
      <Fields label={f({ id: 'password-label' })} handleClick={handlePasswordChange}>
        <Typography className={classes.text} is-light='true' star-pass='true' noWrap>
          {starsPass}
        </Typography>
      </Fields>
      <Fields label={f({ id: 'phone-number-label' })} handleClick={handlePhoneVerification} isSpace>
        <Typography className={classes.text} is-light='true'>
          {customer?.customerInfo.phone}
        </Typography>
        <img
          src={customer?.customerInfo.phoneVerified ? verifiedIcon : notVerifiedIcon}
          alt=''
          height='18'
          width='18'
        />
      </Fields>
      {defaultAddress && defaultAddress.length > 0 ? (
        <Fields
          label={`${f({ id: 'address-label' })} (${defaultAddress[0]?.name})`}
          handleClick={goToAddresses}
          isSpace
        >
          <Typography className={classes.text} is-light='true'>
            {defaultAddress[0]?.formattedAddress}
          </Typography>
        </Fields>
      ) : customer?.savedAddresses.length > 0 ? (
        <Fields
          label={`${f({ id: 'address-label' })} (${customer?.savedAddresses[0]?.name})`}
          handleClick={goToAddresses}
          isSpace
        >
          <Typography className={classes.text} is-light='true'>
            {customer?.savedAddresses[0].formattedAddress}
          </Typography>
        </Fields>
      ) : (
        !loading && (
          <GreenButton fullWidth className={classes.greenButton} onClick={goToAddresses}>
            {f({ id: 'add-default-address' })}
          </GreenButton>
        )
      )}
    </Box>
  )
}

RightSide.propTypes = {
  classes: PropTypes.object,
  handlePasswordChange: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  handlePhoneVerification: PropTypes.func.isRequired,
  goToAddresses: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  lastFour: PropTypes.string,
}

export default RightSide
