import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { GreenRadio } from '../../../../../utils/tools'

import Textfield from '../../Fields/TextField_'

import { paymentDetailConstants, valueConstants, emailAreaConstants } from '../../Helpers'

const EmailField = ({ classes, values, handleChange, setValues, error }) => {
  const [emailField, setEmailField] = useState(emailAreaConstants[1])

  useEffect(() => {
    if (values) {
      if (values.paymentContactEmail === values.storeEmail) {
        setEmailField(emailAreaConstants[0])
      } else {
        setEmailField(emailAreaConstants[1])
      }
    }
  }, [values])

  const handleEmailField = (e) => {
    setEmailField(e.target.value)
    handleContactEmail(e.target.value)
  }

  const handleContactEmail = (value) => {
    if (value === paymentDetailConstants.storeEmail) {
      setValues({
        ...values,
        paymentContactEmail: values.storeEmail,
      })
    } else {
      setValues({
        ...values,
        paymentContactEmail: paymentDetailConstants.empty,
      })
    }
  }

  return (
    <Grid item md={6} sm={12} xs={12} className={classes.gridItem}>
      <FormControl fullWidth className={classes.phoneFieldFormControl}>
        {emailAreaConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            control={<GreenRadio checked={emailField === item} onChange={handleEmailField} value={item} />}
            label={<Typography>{item}</Typography>}
          />
        ))}
      </FormControl>
      <Textfield
        values={values}
        prop={valueConstants.paymentContactEmail}
        required
        error={error}
        handleChange={handleChange}
        gridVal={12}
        disabled={emailField !== emailAreaConstants[1]}
      />
    </Grid>
  )
}

EmailField.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  setValues: PropTypes.func,
  error: PropTypes.bool,
}

export default EmailField
