import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import { RestAdminView } from './selections/RestAdminView'
import { StoreAdminView } from './selections/StoreAdminView'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(2),
  },
}))

const Selections = (props) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.container}>
      <RestAdminView />
      <StoreAdminView />
    </Grid>
  )
}

export default Selections
