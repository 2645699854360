import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Textfield from './textField'
import WebAddCard from '../savecard/webAddCard'
import withWidth from '@material-ui/core/withWidth'
import { useCurrentWidth } from '../../../hooks/useCurrentWidth'

import PaymentMethods from './CartPayment/PaymentMethods'
import AddressPart from './CartPayment/AddressPart'
import TipPart from './CartPayment/TipPart'
// import PromoParts from './CartPayment/PromoParts'

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#707070',
    marginBottom: 8,
  },
})

const CartPayment = ({
  classes,
  intl,
  paymentMethodHandler,
  width,
  cards,
  handlePaymentMethod,
  selectedCard,
  selectCard,
  getCardElement,
  elements,
  tabletView,
  handleTip,
  handleCustomTip,
  clearCustomTip,
  cost,
  tip,
}) => {
  const currentWidth = useCurrentWidth()

  const [paymentSelection, setPaymentSelection] = useState('Card')
  // const [promoCode, setPromoCode] = useState('')
  // const [giftCode, setGiftCode] = useState('')
  const [otherPaymentMethod, setOtherPaymentMethod] = useState('')

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const selectPaymentMethod = (data) => {
    paymentMethodHandler(data)
    setPaymentSelection(data)
  }

  const renderTipPart = useMemo(() => {
    return (
      <TipPart
        handleTip={handleTip}
        clearCustomTip={clearCustomTip}
        handleCustomTip={handleCustomTip}
        cost={cost}
        tip={tip}
      />
    )
  }, [cost, tip])

  // const renderPromoParts = useMemo(() => {
  //   return (
  //     <PromoParts
  //       classes={classes}
  //       getLabel={getLabel}
  //       setPromoCode={setPromoCode}
  //       setGiftCode={setGiftCode}
  //       giftCode={giftCode}
  //       promoCode={promoCode}
  //     />
  //   )
  // }, [giftCode, promoCode])

  const paymentSelectionButtonsVisibility = () => {
    if (tabletView && currentWidth < 710) {
      return true
    } else if (width === 'md' && !tabletView) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <AddressPart parentClasses={classes} />
        <Grid item xs={12} style={{ flex: '6 6 auto' }}>
          {paymentSelectionButtonsVisibility() ? (
            <Grid item xs={7} style={{ minWidth: 300, marginBottom: 20 }}>
              <PaymentMethods
                classes={classes}
                selectPaymentMethod={selectPaymentMethod}
                paymentSelection={paymentSelection}
              />
            </Grid>
          ) : null}
          <Typography className={classes.sectionTitle} style={{ marginBottom: 15 }}>
            {getLabel('payment-label')}
          </Typography>
          <Grid container>
            <Grid item xs={5}>
              {paymentSelection === 'Card' ? (
                <div>
                  <WebAddCard
                    cards={cards}
                    handlePaymentMethod={handlePaymentMethod}
                    selectedCard={selectedCard}
                    selectCard={selectCard}
                    getCardElement={getCardElement}
                    elements={elements}
                    currentWidth={currentWidth}
                  />
                </div>
              ) : (
                <div style={{ minWidth: 300 }}>
                  <Textfield
                    placeholder={`Please Enter Your ${paymentSelection} info`}
                    value={otherPaymentMethod}
                    onClear={() => setOtherPaymentMethod('')}
                    onChange={(event) => setOtherPaymentMethod(event.target.value)}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={1}></Grid>
            {width === 'lg' || width === 'xl' || (tabletView && currentWidth >= 710) ? (
              <Grid item xs={6}>
                <PaymentMethods
                  classes={classes}
                  selectPaymentMethod={selectPaymentMethod}
                  paymentSelection={paymentSelection}
                />
              </Grid>
            ) : null}
            <Grid item xs={2}></Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className='centered-row'
          style={{
            flex: '1 1 auto',
            marginTop: paymentSelection !== 'Card' ? 20 : -30,
          }}
        >
          {renderTipPart}
        </Grid>
        {/* <Grid item xs={12} className='centered-row' style={{ flex: '1 1 0', marginTop: 15 }}>
          {renderPromoParts}
        </Grid> */}
      </Grid>
    </div>
  )
}

CartPayment.propTypes = {
  width: PropTypes.string,
  intl: PropTypes.object,
  classes: PropTypes.object,
  f: PropTypes.func,
  selectCard: PropTypes.func,
  handlePaymentMethod: PropTypes.func,
  selectedCard: PropTypes.string,
  cards: PropTypes.array,
  getCardElement: PropTypes.func,
  elements: PropTypes.object,
  paymentMethodHandler: PropTypes.func,
  customerAddress: PropTypes.string,
  storeAddress: PropTypes.string,
  deliveryType: PropTypes.string,
  tabletView: PropTypes.bool,
  handleTip: PropTypes.func,
  cost: PropTypes.number,
  tip: PropTypes.object,
}

export default withStyles(styles)(injectIntl(withRouter(withWidth()(CartPayment))))
