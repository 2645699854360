import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'

const useStyles = makeStyles((theme) => ({
  addButtonView: {
    textTransform: 'none',
  },
}))

const AddItems = ({ intl, itemCounter, goToStore }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <Box
      mt={1}
      pb={1}
      ml={1}
      mr={1}
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
      style={{ borderBottom: '1px solid #f4f4f4' }}
    >
      <Box width='60%'>
        <Typography variant='h6'>
          {getLabel('summary-items')} ({itemCounter})
        </Typography>
      </Box>
      <Box width='40%' display='flex' flexDirection='row' justifyContent='flex-end' flexWrap='noWrap'>
        <Button variant='text' classes={{ label: classes.addButtonView }} onClick={goToStore}>
          <Typography variant='h6' color='primary'>
            {getLabel('add-items')}
          </Typography>
          <AddCircleRoundedIcon style={{ marginLeft: 4 }} fontSize='small' color='primary' />
        </Button>
      </Box>
    </Box>
  )
}

AddItems.propTypes = {
  itemCounter: PropTypes.number,
  goToStore: PropTypes.func,
}

export default injectIntl(AddItems)
