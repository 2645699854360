import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'auto',
    paddingBottom: 15,
    paddingTop: 15,
    borderBottom: '1px solid #c4c4c4',
    "&[islast='true']": {
      borderBottom: 'none',
    },
    "&[isfirst='true']": {
      paddingTop: 0,
    },
  },
}))

const Section = ({ children, first, last }) => {
  const classes = useStyles()
  return (
    <div className={classes.root} islast={last && 'true'} isfirst={first && 'true'}>
      {children}
    </div>
  )
}

export default Section
