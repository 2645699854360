import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import { searchActions } from '../../../actions'
import { initialQuery } from '../../../constants'
import { showFilters } from '../../../helpers/filterBox/showFilters'
import Categories from './filterBox/categories'
import Dietaries from './filterBox/dietaries'
import Pricey from './filterBox/pricey'
import Sort from './filterBox/sort'
import Rating from './filterBox/rating'
import Distance from './filterBox/distance'
import SearchText from './filterBox/searchText'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  filtersBox: {
    width: '100%',
    height: 'auto',
    borderRadius: 10,
    backgroundColor: '#f7f7f7',
    padding: 10,
  },
  closeIcon: {
    width: 15,
    height: 15,
    marginLeft: 5,
    transition: `all 200ms ${theme.transitions.easing.easeInOut}`,
    '&:hover': {
      transform: 'scale(1.5)',
      transition: `all 200ms ${theme.transitions.easing.easeIn}`,
    },
    "&[content-role='clear']": {
      width: 20,
      height: 20,
      '&:hover': {
        transform: 'scale(1.2)',
        transition: `all 200ms ${theme.transitions.easing.easeIn}`,
      },
    },
  },
  clearContainer: {
    padding: '0px 16px 0px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  clear: {
    textTransform: 'capitalize',
    height: 40,
    // width: '93%',
    marginLeft: '5%',
    wordBreak: 'keep-all',
    fontSize: 15,
    borderRadius: 10,
  },
}))

const FilterBox = ({ drawer }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const resetSearch = () => dispatch(searchActions.resetSearch())

  const searchData = useSelector(({ search }) => search.searchQuery)

  const show = showFilters(searchData, initialQuery)

  return (
    <div>
      {show && (
        <div className={classes.filtersBox}>
          <Grid container spacing={3}>
            <Grid item xs={9} style={{ padding: 6 }}>
              <Categories />
              <Dietaries />
              <Pricey />
              <Sort />
              <Distance />
              <Rating />
              <SearchText />
            </Grid>
            <Grid item xs={3} className={classes.clearContainer}>
              <Button
                onClick={resetSearch}
                className={classes.clear}
                variant='contained'
                color='default'
                endIcon={<CloseIcon content-role='clear' className={classes.closeIcon} />}
              >
                {drawer ? f({ id: 'clear-label' }) : f({ id: 'clear-filters' })}
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default withRouter(FilterBox)
