import { makeStyles } from '@material-ui/core'
import { height, width } from '../../../../helpers'

export default makeStyles((theme) => ({
  //MobileOrders component styles
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: width,
    height: height,
    maxHeight: height,
  },
  header: {
    // backgroundColor: 'purple',
  },
  tabs: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  label: {
    textTransform: 'capitalize',
  },
  divider: {
    marginTop: theme.spacing(),
  },
  mobileContainer: {
    // width: '100%',
    overflow: 'scroll',
    flex: '1 50 auto',
    paddingBottom: 50,
  },
  //DesktopOrders component styles
  desktopRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
  },

  rootVerticalTab: {
    flexGrow: 1,
    display: 'flex',
    height: 100,
  },

  contentContainer: (props) => ({
    flexGrow: 1,
    paddingLeft: props.isTablet && theme.spacing(3),
    paddingRight: props.isTablet && theme.spacing(3),
  }),
  verticalTabsContainer: {
    borderRight: `1px solid white`,
  },
  verticalTabs: (props) => ({
    textTransform: 'capitalize',
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'left',
    maxWidth: props.isTablet && 140,
    padding: props.isTablet && 0,
    '&.MuiTab-root': {
      minWidth: 115,
    },
  }),
  full: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 0,
    // minHeight: generalConstants.minPageHeight,
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    minWidth: 700,
  },
  title: {
    fontSize: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
    marginBottom: 20,
  },
  pending: {
    color: '#ff9800',
    backgroundColor: 'rgba(255, 152, 0, 0.08)',
  },
  delivered: {
    color: '#4caf50',
    backgroundColor: 'rgba(76, 175 80, 0.08)',
  },
  completed: {
    color: '#4caf50',
    backgroundColor: 'rgba(76, 175, 80, 0.08)',
  },
  canceled: {
    color: '#f44336',
    backgroundColor: 'rgba(244, 67, 54, 0.08)',
  },
  new: {
    color: '#8a85ff',
    backgroundColor: 'rgba(138, 133, 255, 0.08)',
  },
  status: {
    cursor: 'default',
    height: 20,
    display: 'inline-flex',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    flexGrow: 0,
    fontSize: '0.75rem',
    minWidth: 20,
    alignItems: 'center',
    flexShrink: 0,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    borderRadius: 2,
    letterSpacing: 0.5,
    texTransform: 'uppercase',
    justifyContent: 'center',
  },
  noOrder: {
    height: '100%',
    width: '100%',
  },
  priceValues: {
    width: '10%',
    paddingRight: '4%',
  },
  footer: {
    width: '100%',
  },
  withCart: {
    marginBottom: 120,
  },
  withoutCart: {
    marginBottom: 60,
  },
  headerContainer: {
    minHeight: 160,
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 34,
    textAlign: 'left',
    lineHeight: 1.32,
    color: '#333333',
  },
  navbarContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  tabContainer: (props) => ({
    marginTop: 25,
    width: props.isTablet ? 120 : 150,
    height: 200,
  }),
  noResultContainer: {
    marginTop: 30,
    width: '100%',
    height: 350,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tabLabels: {
    textAlign: 'left',
    width: '100%',
    fontSize: 18,
    addingLeft: '10%',
  },
  //MobileOrderDetail styles
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: width,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderBottom: 'solid 1px #ededed',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  orderDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: width,
    borderBottom: 'solid 1px #ededed',
  },
  //RateOrder styles
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: width,
  },
  rootWeb: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    minHeight: 500,
    minWidth: 380,
    padding: 15,
  },
  rootReceiptWeb: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    minHeight: 640,
    maxHeight: '90%',
    minWidth: 500,
    padding: 15,
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  receiptHeader: {
    alignItems: 'flex-start',
  },
  receiptText: {
    paddingLeft: theme.spacing(6),
    fontSize: 20,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    lineHeight: 40,
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  receiptPriceContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconContainer: {
    height: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  close: {
    width: 30,
    height: 30,
  },
  closeImage: {
    opacity: '0.5',
  },
  questionContainer: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  starContainer: {
    marginTop: theme.spacing(1.5),
  },
  commentContainer: {
    marginTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  helperText: {
    display: 'flex',
    justifyContent: 'flex-end',
    userSelect: 'none',
  },
  helperTextRed: {
    display: 'flex',
    justifyContent: theme.palette.secondary.main,
    color: 'red',
    userSelect: 'none',
  },
  cartContainer: {
    width: '100%',
    marginBottom: theme.spacing(),
    backgroundColor: 'transparent',
    borderTop: 'solid 1px #ededed',
  },
  receiptBottomContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: theme.spacing(),
    backgroundColor: 'white',
    borderTop: 'solid 1px #ededed',
    zIndex: 10,
  },
  submitButtonContainer: {
    position: 'absolute',
    bottom: theme.spacing(),
    left: theme.spacing(),
    right: theme.spacing(),
  },
  cart: {
    textTransform: 'capitalize',
    color: '#000',
    zIndex: 100,
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    borderRadius: 10,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  cartText: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    color: 'white',
  },
  stars: {
    fontSize: 50,
  },
  submitButton: {
    height: 50,
    width: '100%',
    textTransform: 'none',
    color: 'white',
    borderRadius: 10,
  },
  receiptRow: {
    height: 66,
    marginLeft: 18,
    marginRight: 16,
    justifyContent: 'space-between',
    // backgroundColor: 'pink',
  },
  priceTitle: {
    lineHeight: 40,
    fontSize: 34,
  },
  logo: {
    paddingTop: 3,
  },
  greetingsContainer: {
    height: 40,
    paddingLeft: 18,
    paddingRight: 16,
    borderBottom: 'solid 1px #ededed',
    marginTop: 6,
    marginBottom: 10.5,
  },
  regularText: {
    fontSize: 13,
    fontFamily: 'Roboto',
  },
  caption: {
    fontSize: 12,
  },
  body: {
    fontSize: 14,
  },
  bold: {
    fontWeight: 'bold',
  },
  flexText: {
    flex: 4,
  },
  flexPrice: {
    flex: 1,
  },
  right: {
    justifyContent: 'flex-end',
  },
  receiptImage: {
    width: '100%',
    height: 212,
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: 16.5,
    marginBottom: theme.spacing(2),
  },
  deliveryContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 15,
    marginRight: 12,
    paddingTop: 22.5,
    paddingBottom: 19.5,
    borderTop: 'solid 1px #ededed',
    borderBottom: 'solid 1px #ededed',
    alignItems: 'space-between',
  },
  menuContainer: {
    marginTop: 15.5,
    marginLeft: 12,
    marginRight: 12,
  },
  storeName: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    lineHeight: 2,
  },
  menuTitle: {
    fontSize: 14,
    fontFamily: 'Roboto',
    lineHeight: 2,
  },
  attributes: {
    marginLeft: 16,
    paddingTop: theme.spacing(),
  },
}))
