import React from 'react'
import { connect } from 'react-redux'
import { customerActions } from '../../../../actions'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { injectIntl } from 'react-intl'
import { GreenButton } from '../../../utils/tools'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import InputAdornment from '@material-ui/core/InputAdornment'
import visibleIcon from '../../../../images/show.svg'
import visibleOff from '../../../../images/show-off.svg'
import backIcon from '../../../../images/black-back.svg'
import passwordReset from '../../../../images/password-resetted.svg'
import wrongCredential from '../../../../images/wrong-credential.png'
import Button from '@material-ui/core/Button'
import { customerService } from '../../../../services'
import { generalConstants, customerConstants } from '../../../../constants'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  back: {
    position: 'absolute',
    top: 69,
    left: theme.spacing(),
    height: 31,
  },
  dialogContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 465,
  },
  dialogContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 360,
    maxWidth: 315,
  },
  dialogContentText: {
    marginTop:30,
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    color: 'black',
  },
  dialogImageContainer: {
    width: '90%',
    marginLeft: '15%',
  },
  dialogContentText2: {
    fontWeight: 'normal',
    fontSize: 16,
    textAlign: 'center',
    color: '#333333',
  },
  dialogActions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogButton: {
    fontSize: 15,
    textTransform: 'capitalize',
    width: '100%',
    height: 48,
    borderRadius: 10,
    backgroundColor: '#69e781',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  mobileTopBar: {
    borderBottomColor: 'white',
    marginTop: 68,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  took: {
    fontSize: 23,
    fontWeight: 'bold',
  },
  fresh: {
    fontSize: 23,
  },
})

class ChangePasswordWeb extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showDoneDialog: false,
      showErrorDialog: false,
      anchorEl: null,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      newPasswordInvalid: true,
      confirmPasswordInvalid: true,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    }
  }

  handleCurrentPasswordChange = (event) => {
    this.setState({
      currentPassword: event.target.value,
    })
  }

  handleNewPasswordChange = (event) => {
    this.setState({
      newPassword: event.target.value,
      newPasswordFocused: true,
      newPasswordInvalid: event.target.value.length < 8 || (this.state.currentPassword.length > 0 && event.target.value == this.state.currentPassword),
      confirmPasswordInvalid: this.state.confirmPassword !== event.target.value,
    })
  }

  handleConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      confirmPasswordFocused: true,
      confirmPasswordInvalid: this.state.newPassword !== event.target.value,
    })
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleSubmit(e) {
    const { currentPassword, newPassword } = this.state
    e.preventDefault()
    customerService.updatePassword(currentPassword, newPassword).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          this.setState({ submitted: true, showDoneDialog: true })
          localStorage.setItem(customerConstants.CUSTOMER, JSON.stringify(res.data))
        }
      },
      (error) => {
        this.setState({ showErrorDialog: true })
      }
    )
  }

  render() {
    const { classes } = this.props
    const { showDoneDialog, currentPassword, newPassword, confirmPassword, showErrorDialog } = this.state
    return (
      <div className='column'>
        <div className='row'>
          <div
            className={classes.mobileTopBar}
            style={{
              width: '100%',
            }}
          >
            <IconButton
              color='inherit'
              aria-label='close'
              className={classes.back}
              onClick={() => this.props.handleClose()}
            >
              <img src={backIcon} alt='Back' />
            </IconButton>
            <div className={classes.headerContainer}>
              <div className='row'>
                <Typography className={classes.took}>Took</Typography>
                <Typography className={classes.fresh}>Fresh</Typography>
              </div>
            </div>
          </div>
        </div>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography variant='h4' gutterBottom className={classes.title}>
              {this.getLabel('change-password')}
            </Typography>
            <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
              <TextField
                style={{ marginBottom: 40 }}
                size='small'
                variant='outlined'
                margin='normal'
                fullWidth
                label={this.getLabel('current-password')}
                type={this.state.showCurrentPassword ? 'text' : 'password'}
                autoComplete='current-password'
                value={currentPassword}
                onChange={this.handleCurrentPasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        color='inherit'
                        size='small'
                        disabled={!currentPassword}
                        onClick={() =>
                          this.setState({
                            showCurrentPassword: !this.state.showCurrentPassword,
                          })
                        }
                      >
                        {this.state.showCurrentPassword ? (
                          <img src={visibleIcon} alt='' height='24' />
                        ) : (
                          <img src={visibleOff} alt='' height='24' />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                style={{ marginBottom: 10 }}
                size='small'
                variant='outlined'
                fullWidth
                label={this.getLabel('new-password')}
                type={this.state.showNewPassword ? 'text' : 'password'}
                autoComplete='new-password'
                value={newPassword}
                error={this.state.newPasswordFocused && this.state.newPasswordInvalid}
                onChange={this.handleNewPasswordChange}
                helperText={
                  this.state.newPasswordFocused && this.state.newPasswordInvalid
                    ? this.getLabel('either-not-eight-char-or-same')
                    : ' '
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        color='inherit'
                        size='small'
                        disabled={!newPassword}
                        onClick={() =>
                          this.setState({
                            showNewPassword: !this.state.showNewPassword,
                          })
                        }
                      >
                        {this.state.showNewPassword ? (
                          <img src={visibleIcon} alt='' height='24' />
                        ) : (
                          <img src={visibleOff} alt='' height='24' />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size='small'
                variant='outlined'
                fullWidth
                label={this.getLabel('confirm-password')}
                type={this.state.showConfirmPassword ? 'text' : 'password'}
                autoComplete='confirm-password'
                value={confirmPassword}
                error={this.state.confirmPasswordFocused && this.state.confirmPasswordInvalid}
                onChange={this.handleConfirmPasswordChange}
                helperText={
                  this.state.confirmPasswordFocused && this.state.confirmPasswordInvalid
                    ? this.getLabel('password-not-match')
                    : ' '
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        color='inherit'
                        size='small'
                        disabled={!confirmPassword}
                        onClick={() =>
                          this.setState({
                            showConfirmPassword: !this.state.showConfirmPassword,
                          })
                        }
                      >
                        {this.state.showConfirmPassword ? (
                          <img src={visibleIcon} alt='' height='24' />
                        ) : (
                          <img src={visibleOff} alt='' height='24' />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <GreenButton
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
                disabled={this.state.newPasswordInvalid || this.state.confirmPasswordInvalid}
                className={classes.button}
              >
                {this.getLabel('change-label')}
              </GreenButton>
            </form>
          </div>
        </Container>
        <div className={classes.dialogContainer}>
          <Dialog
            open={showDoneDialog}
            onClose={() => this.props.handleClose()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            style={{ width: '100%', padding: 10 }}
            PaperProps={{
              style: {
                borderRadius: 20,
                marginTop: 90,
                padding: 20,
                height: 465,
              },
            }}
          >
            <DialogContent className={classes.dialogContent}>
              <div className={classes.dialogImageContainer}>
                <img src={passwordReset} alt='doneImage' />
              </div>
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText}>
                {this.getLabel('success-label')}
              </DialogContentText>
               <DialogContentText id='alert-dialog-description' className={classes.dialogContentText2}>
                {this.getLabel('password-updated')}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={() => this.props.handleClose()} className={classes.dialogButton}>
                {this.getLabel('done-label')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className={classes.dialogContainer}>
          <Dialog
            open={showErrorDialog}
            onClose={() => this.props.handleClose()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            style={{ width: '100%', padding: 10 }}
            PaperProps={{
              style: {
                borderRadius: 20,
                marginTop: 90,
                padding: 20,
              },
            }}
          >
            <DialogContent className={classes.dialogContent}>
              <div style={{ marginBottom: 20 }}>
                <img src={wrongCredential} alt='errorImage' width='326' height='271'/>
              </div>
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText}>
                {this.getLabel('incorrect-credential')}
              </DialogContentText>
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText2}>
                {this.getLabel('incorrect-credential-text')}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={() => this.props.handleClose()} className={classes.dialogButton}>
                {this.getLabel('continue-label')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )
  }
}

const actionCreators = {
  updatePassword: customerActions.updatePassword,
}

ChangePasswordWeb.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(injectIntl(connect(null, actionCreators)(ChangePasswordWeb)))
