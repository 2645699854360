import React from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import MerchantNoMatch from '../common/MerchantNoMatch'
import RestInfo from './RestInfo'

import { roles } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const RestInfoMain = () => {
  const classes = useStyles()

  const merchantRoles = useSelector(({ merchant }) => merchant?.merchantInfo?.roles)
  const loading = useSelector(({ merchantStores }) => merchantStores.loading)

  const getContent = () => {
    let hasAdminRole = merchantRoles?.filter((mr) => mr.name === roles.REST_ADMIN)
    if (hasAdminRole.length > 0) {
      return <RestInfo />
    }
    return <MerchantNoMatch />
  }

  return (
    <div>
      {getContent()}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

export default RestInfoMain
