import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { merchantConstants, customerConstants } from './constants'

export const CustomerPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem(customerConstants.CUSTOMER) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/customer/signin', state: { from: props.location } }} />
      )
    }
  />
)

export const MerchantPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem(merchantConstants.MERCHANT) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/merchant/signin', state: { from: props.location } }} />
      )
    }
  />
)
