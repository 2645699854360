import React from 'react'
import { Divider } from '@material-ui/core'
import FirstMenuSection from './restaurant-view/FirstMenuSection'
import SecondMenuSection from './restaurant-view/SecondMenuSection'
// import PropTypes from 'prop-types'

export const RestaurantAdminView = () => {
  return (
    <>
      <FirstMenuSection />
      <Divider />
      <SecondMenuSection />
    </>
  )
}

export default RestaurantAdminView
