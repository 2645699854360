import React from 'react'
import { useIntl } from 'react-intl'
import MomentUtils from '@date-io/moment'
import { GreenButton } from '../../utils/tools'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '15px 15px 0px 15px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  container: {
    width: 'fit-content',
    minWidth: 500,
    height: 70,
    paddingBottom: 15,
  },
  datePicker: {
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      height: 20,
    },
    '& .MuiIconButton-root': {
      padding: 8,
      marginRight: -5,
    },
  },
  text: {
    marginRight: 10,
    marginTop: 15,
    marginBottom: 10,
    "&[data-content='to']": {
      marginLeft: 10,
    },
  },
  inputArea: {
    height: 45,
    width: 180,
    marginTop: 3,
  },
  button: {
    height: 40,
    width: 80,
    fontSize: 16,
    marginLeft: 40,
    marginTop: 5,
  },
}))

const DatePick = ({ handleSubmit, startDate, endDate, handleStartDateChange, handleEndDateChange, disableButton }) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()

  return (
    <Paper className={classes.root}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container justifyContent='flex-start' alignItems='flex-start' className={classes.container}>
          <Typography className={classes.text}>{format({ id: 'from-label' })} :</Typography>
          <KeyboardDatePicker
            inputVariant='outlined'
            disableToolbar
            variant='inline'
            format='MM/DD/YYYY'
            id='first-date-picker'
            value={startDate}
            onChange={handleStartDateChange}
            className={classes.datePicker}
            InputProps={{
              className: classes.inputArea,
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <Typography className={classes.text} data-content={'to'}>
            {format({ id: 'to-label-uppercase' })} :
          </Typography>
          <KeyboardDatePicker
            inputVariant='outlined'
            disableToolbar
            variant='inline'
            format='MM/DD/YYYY'
            id='second-date-picker'
            minDate={startDate}
            value={endDate}
            onChange={handleEndDateChange}
            className={classes.datePicker}
            InputProps={{
              className: classes.inputArea,
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <GreenButton
        className={classes.button}
        variant='contained'
        color='primary'
        type='submit'
        fullWidth
        onClick={handleSubmit}
        disabled={disableButton}
      >
        {format({ id: 'submit-label' })}
      </GreenButton>
    </Paper>
  )
}

export default DatePick
