import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { GreenRadio } from '../../../../../utils/tools'

import PhoneField from '../../Fields/PhoneField_'

import { valueConstants, labelConstants, notificationConstants, phoneConstants } from '../../Helpers'

const PhoneArea = ({ classes, phone, phoneSelection, values, setValues, error }) => {
  const { formatMessage: f } = useIntl()
  return (
    <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
      <FormControl fullWidth>
        {phoneConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            className={classes.label}
            control={<GreenRadio checked={phone === item} onChange={phoneSelection} value={item} />}
            label={<Typography>{item}</Typography>}
          />
        ))}
      </FormControl>
      <PhoneField
        prop={valueConstants.orderConfirmationPhone}
        values={values}
        setValues={setValues}
        label={f({ id: labelConstants.orderConfirmationPhone })}
        disabled={phone !== notificationConstants.otherPhone}
        error={error}
        gridVal={12}
      />
    </Grid>
  )
}

PhoneArea.propTypes = {
  values: PropTypes.object,
  classes: PropTypes.object,
  phone: PropTypes.string,
  phoneSelection: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.bool,
}

export default PhoneArea
