const menuItemsDeepCheck = (menu, benchmark) => {
  let isMenuChanged = false
  let isCategoriesChanged = arrayDeepCheck(menu.categories, benchmark.categories)
  let isDietariesChanged = arrayDeepCheck(menu.dietaries, benchmark.dietaries)
  let menuDescriptionChanged = menu.description !== benchmark.description
  let menuNameChanged = menu.name !== benchmark.name
  let menuPriceChanged = +menu.price !== benchmark.price
  let menuServingSizeChanged = menu.servingSize !== benchmark.servingSize
  let menuServingTypeChanged = menu.servingType !== benchmark.servingType
  if (
    menuDescriptionChanged ||
    menuNameChanged ||
    menuPriceChanged ||
    menuServingSizeChanged ||
    menuServingTypeChanged ||
    isCategoriesChanged ||
    isDietariesChanged
  ) {
    isMenuChanged = true
  }
  return isMenuChanged
}

const arrayDeepCheck = (arr1, arr2) => {
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return true

  arr1 = arr1.sort((a, b) => a - b)
  arr2 = arr2.sort((a, b) => a - b)

  // Check if all items exist and are in the same order
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return true
  }

  return false
}

export { menuItemsDeepCheck }
