import React from 'react'

import { makeStyles, Typography, Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Place from '@material-ui/icons/Place'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useSelector } from 'react-redux'
import HeaderTablet from '../TabletCart/HeaderTablet'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 15,
    flexDirection: 'column',
    paddingLeft: 28,
    paddingRight: 52,
    height: 90,
    "&[tablet-view='true']": {
      padding: 0,
      height: 'auto',
    },
  },
  upperBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #c4c4c4',
    padding: 0,
  },
  lowerBox: {
    borderBottom: '1px solid #c4c4c4',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 40,
  },
  store: {
    fontSize: 17,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.35,
    letterSpacing: 'normal',
    color: '#c4c4c4',
  },
  text: {
    fontSize: 25,
    fontWeight: 'bold',
    color: '#707070',
    textAlign: 'left',
    padding: 0,
  },
  button: {
    textTransform: 'capitalize',
    marginRight: -28,
    fontSize: 14,
    color: '#707070',
    "&[is-tablet='true']": {
      marginRight: 0,
    },
  },
  icon: {
    opacity: 0.3,
  },
}))

const Header = ({ handleAddItem, itemCount, tabletView }) => {
  const storeName = useSelector(({ orders }) => orders.cart?.storeName, shallowEqual)
  const classes = useStyles()
  return (
    <div className={classes.root} tablet-view={(!!tabletView).toString()}>
      {tabletView && <HeaderTablet />}
      <div className={classes.upperBox}>
        <Typography className={classes.text}>
          <FormattedMessage id='summary-items' /> {`${itemCount}`}
        </Typography>
        <Button
          disableRipple
          className={classes.button}
          style={{ backgroundColor: 'transparent' }}
          onClick={handleAddItem}
          is-tablet={tabletView ? 'true' : 'false'}
          endIcon={<AddCircleIcon style={{ color: 'lightgray', fontSize: 25 }} />}
        >
          <FormattedMessage id='add-item' />
        </Button>
      </div>
      <div className={classes.lowerBox}>
        <Place className={classes.icon} />
        <Typography className={classes.store}>
          <FormattedMessage id='from-label' /> {`${storeName}`}
        </Typography>
      </div>
    </div>
  )
}

export default Header
