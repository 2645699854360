import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Transition } from '../../../utils/tools'
import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import AddAttribute from './AddAttribute'

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 240,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogTitle: {
    fontSize: 23,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#000',
  },
}))

const AttributeDialog = ({ intl, showDialog, closeDialog, attribute }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <Dialog
      open={showDialog}
      onClose={closeDialog}
      TransitionComponent={Transition}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle className={classes.dialogTitle}>
        {getLabel(attribute ? 'edit-attribute' : 'add-attribute')}
      </DialogTitle>
      <DialogContent>
        <AddAttribute onClose={closeDialog} editAttribute={attribute} />
      </DialogContent>
    </Dialog>
  )
}

AttributeDialog.propTypes = {
  intl: PropTypes.object,
  showDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  attribute: PropTypes.object,
}

export default injectIntl(AttributeDialog)
