import React from 'react'
// import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import FoodCategoryMobile from './FoodCategoryMobile'
import { searchActions } from '../../../../actions'

const useStyles = makeStyles((theme) => ({
  categoryBar: {
    padding: 4,
    backgroundColor: '#fff',
    borderBottom: '1px solid #f7f7f7',
  },
}))

const CategoryBar = ({ resetItems }) => {
  const classes = useStyles()

  const categories = useSelector(({ categories }) => categories.data)
  const searchQuery = useSelector(({ search }) => search.searchQuery)

  const dispatch = useDispatch()
  const setCategorySearchFilter = (id) => dispatch(searchActions.setCategorySearchFilter(id))

  const handleCategoryFilterChange = (categoryId) => {
    resetItems()
    setCategorySearchFilter(categoryId)
  }

  return (
    <Grid item xs={12} className={classes.categoryBar}>
      <div className='scrolling-wrapper-flexbox' style={{ overflowY: 'hidden' }}>
        {categories?.map((category) => (
          <FoodCategoryMobile
            key={category.id}
            data={category}
            isSelected={searchQuery.categoryIds.includes(category.id)}
            onSelect={handleCategoryFilterChange}
          />
        ))}
      </div>
    </Grid>
  )
}

CategoryBar.propTypes = {
  // props: PropTypes,
}

export default CategoryBar
