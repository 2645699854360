import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { FormControlLabel, Grid } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'

const SwitchButton = ({ store, intl, callback, label, value }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  const activityLabel = store[value]

  const conditionalLabel =
    value === 'active' ? `${getLabel(label)}: ${activityLabel?.toString().toUpperCase()}` : getLabel(label)

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={<Switch checked={store[value] || false} onChange={callback} value={value} color='primary' />}
        label={conditionalLabel}
      />
    </Grid>
  )
}

SwitchButton.propTypes = {
  store: PropTypes.object,
  intl: PropTypes.object,
  callback: PropTypes.func.isRequired,
  label: PropTypes.string,
}

export default injectIntl(SwitchButton)
