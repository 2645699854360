import { Dialog } from '@material-ui/core'
import { TFDialog } from '../../components/utils/tools'

export const getRelatedComponent = (web) => {
  const components = [
    {
      isWeb: true,
      component: Dialog,
    },
    {
      isWeb: false,
      component: TFDialog,
    },
  ]
  const item = components.find((t) => t.isWeb === web)
  return item.component
}
