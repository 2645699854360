import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import creditImage from '../../../../images/tookfresh-credit-image.svg'

const styles = (theme) => ({
  tookfreshCreditText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333333',
    padding: 0,
    marginBottom: 8,
  },
  tookfreshCreditContainer: {
    width: '100%',
    height: 160,
    backgroundColor: '#69e781',
    padding: 0,
    objectFit: 'contain',
    borderRadius: 5,
    border: 'solid 0.5px #c4c4c4',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  creditContainerLeftPart: {
    width: '70%',
    padding: '20px 0px 12px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  creditContainerRightPart: {
    width: '30%',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  moneyContainer: {
    width: '100%',
    height: 45,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  money: {
    fontFamily: 'Roboto',
    fontSize: 34,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.44,
    letterSpacing: 6.8,
    textAlign: 'left',
    color: '#ffffff',
    padding: 0,
  },
  messageContainer: {
    width: '100%',
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 0,
  },
  message: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#ffffff',
    padding: 0,
  },
})

const TookFreshCredit = ({ classes, intl, balance }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <div>
      <Typography className={classes.tookfreshCreditText}>{getLabel('tookfresh-credit')}</Typography>
      <div className={classes.tookfreshCreditContainer}>
        <Container className={classes.creditContainerLeftPart}>
          <div className={classes.moneyContainer}>
            <Typography className={classes.money}>${balance?.toFixed(2)}</Typography>
          </div>
          <div className={classes.messageContainer}>
            <Typography className={classes.message}>{getLabel('tookfresh-credit-message')}</Typography>
          </div>
        </Container>
        <Container className={classes.creditContainerRightPart}>
          <img src={creditImage} alt='credit' height='152px' width='235.1px' />
        </Container>
      </div>
    </div>
  )
}

export default withStyles(styles)(withRouter(injectIntl(TookFreshCredit)))
