import axios from 'axios'
import { authCustomerHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL

export const ratingService = {
  getOrderQuestions,
  rateOrder,
  getStoreRatings,
}

function getOrderQuestions() {
  return axios.get(`${API_URL}/rating/question/`, authCustomerHeader()).then((res) => {
    return res
  })
}

function rateOrder(orderId, body) {
  const reqBody = JSON.stringify(body)
  return axios.post(`${API_URL}/rating/answer/${orderId}`, reqBody, authCustomerHeader()).then((res) => {
    return res
  })
}

function getStoreRatings(query) {
  const { startDate, endDate, storeId } = query
  return axios
    .get(`${API_URL}/rating/answer/${storeId}?start_date=${startDate}&end_date=${endDate}`, authCustomerHeader())
    .then((res) => {
      return res
    })
}
