import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import Fabs from './common/fabs'
import { searchActions } from '../../../../actions'

const Categories = () => {
  const dispatch = useDispatch()

  const categories = useSelector(({ categories }) => categories.data, shallowEqual)
  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const resetCategory = (id) => dispatch(searchActions.removeCategorySearchFilter(id))

  return (
    <>
      {categories?.map((category, index) =>
        searchData.categoryIds.includes(category.id) ? (
          <Fabs key={index} text={category.name} onClick={() => resetCategory(category.id)} />
        ) : null
      )}
    </>
  )
}

export default Categories
