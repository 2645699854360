import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import WhoWeAre from './WhoWeAre'
import Description from './Description'
import deliveryPickup from '../../../images/delivery-pickup.png'
import clsx from 'clsx'
import TopNavbar from '../top_navbar'
import { generalConstants } from '../../../constants'
import { FormattedMessage } from 'react-intl'
import Footer from '../Footers'

const width = window.innerWidth

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    flexGrow: 1,
  },
  headerContainer: {
    minHeight: 160,
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 34,
    textAlign: 'left',
    lineHeight: 1.32,
    color: '#333333',
  },
  container: {
    flexGrow: 1,
  },
  content: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    overflow: 'scroll',
    flex: '1 50 auto',
  },
  image: {
    width: width,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  beCustomerContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  desktopContent: {
    minHeight: generalConstants.minPageHeight,
  },
}))

const WebAboutUs = (props) => {
  const classes = useStyles()

  return (
    <div>
      <div className={clsx('grow desktop full-width', [classes.root])}>
        <Container maxWidth='lg'>
          <TopNavbar cartVisible={true} />
        </Container>
        <div className={classes.headerContainer}>
          <Container maxWidth='lg'>
            <Typography className={classes.headerText}>
              <FormattedMessage id='about-us' />
            </Typography>
          </Container>
        </div>
        <div className={classes.container}>
          <Container maxWidth='lg' className={classes.desktopContent}>
            <Description web />
            <Grid className={classes.imageContainer}>
              <img src={deliveryPickup} alt='tookfresh-delivery' width='60%' />
            </Grid>
            <WhoWeAre web />
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  )
}

WebAboutUs.propTypes = {
  // second: PropTypes.third,
}

export default React.memo(WebAboutUs)
