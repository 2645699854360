import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

const Information = ({ intl, text, align }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <Box
      mt={1}
      pb={1}
      pr={1}
      pl={1}
      display='flex'
      flexDirection='column'
      style={{ borderBottom: '1px solid #f4f4f4' }}
    >
      <Typography variant='caption' color='textSecondary' align={align}>
        {getLabel(text)}
      </Typography>
    </Box>
  )
}

Information.propTypes = {
  intl: PropTypes.object,
  text: PropTypes.string,
  align: PropTypes.string,
}

export default injectIntl(Information)
