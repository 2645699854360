export const merchantConstants = {
  MERCHANT: 'TKFRSH_MRCHNT',
  SIGNUP_REQUEST: 'MERCHANT_SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'MERCHANT_SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'MERCHANT_SIGNUP_FAILURE',

  SIGNIN_REQUEST: 'MERCHANT_SIGNIN_REQUEST',
  SIGNIN_SUCCESS: 'MERCHANT_SIGNIN_SUCCESS',
  SIGNIN_FAILURE: 'MERCHANT_SIGNIN_FAILURE',

  SIGNOUT: 'MERCHANT_SIGNOUT',

  GETALL_REQUEST: 'MERCHANT_GETALL_REQUEST',
  GETALL_SUCCESS: 'MERCHANT_GETALL_SUCCESS',
  GETALL_FAILURE: 'MERCHANT_GETALL_FAILURE',

  DELETE_REQUEST: 'MERCHANT_DELETE_REQUEST',
  DELETE_SUCCESS: 'MERCHANT_DELETE_SUCCESS',
  DELETE_FAILURE: 'MERCHANT_DELETE_FAILURE',
}
