import React from 'react'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  forgotLink: {
    color: '#212121',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  pointer: {
    cursor: 'pointer',
  },
  label: {
    margin: theme.spacing(2),
  },
  link: {
    paddingLeft: theme.spacing(1),
  },
  signinButton: {
    color: '#fff',
    textTransform: 'capitalize',
    fontSize: 18,
    fontWeight: 400,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    padding: 8,
    width: '100%',
    marginTop: theme.spacing(),
    backgroundColor: '#69e781',
    '&:hover': {
      backgroundColor: '#69e781',
    },
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
}))

const LinksAndButton = ({ togglePaswordDialog, toggleBeMemberDialog, onFormSubmit }) => {
  const classes = useStyles()

  return (
    <>
      <Link onClick={togglePaswordDialog} className={classes.forgotLink}>
        <FormattedMessage id='forgot-password' />
      </Link>
      <Button variant='contained' onClick={onFormSubmit} className={classes.signinButton}>
        <FormattedMessage id='sign-in' />
      </Button>
      <div className={classes.row}>
        <div className={classes.grow} />
        <Typography>
          <FormattedMessage id='not-have-account' />
        </Typography>
        <Link variant='body1' className={clsx(classes.link, classes.pointer)} onClick={toggleBeMemberDialog}>
          <FormattedMessage id='be-member' />
        </Link>
        <div className={classes.grow} />
      </div>
    </>
  )
}

LinksAndButton.propTypes = {
  togglePaswordDialog: PropTypes.func.isRequired,
  toggleBeMemberDialog: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
}

export default LinksAndButton
