import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { deliverySubTypes } from '../../../../../../../constants'

import yesIcon from '../../../../../../../images/yes.svg'
import meetOutsideIcon from '../../../../../../../images/meet-outside.svg'
import meetOutsideIconSelected from '../../../../../../../images/meet-outside-selected.svg'

const MeetOutSide = ({ subTyp, handleSubType, button, selectedButton, f }) => (
  <Grid item xs={4}>
    <div className='centered-row full-width'>
      <Button
        startIcon={
          <img
            src={subTyp === deliverySubTypes.MEET_OUTSIDE ? meetOutsideIconSelected : meetOutsideIcon}
            alt='where'
            height='20'
            width='20'
          />
        }
        endIcon={subTyp === deliverySubTypes.MEET_OUTSIDE && <img src={yesIcon} alt='where' height='16' width='16' />}
        className={clsx({
          [button]: true,
          [selectedButton]: subTyp === deliverySubTypes.MEET_OUTSIDE,
        })}
        onClick={() => handleSubType(deliverySubTypes.MEET_OUTSIDE)}
      >
        {f({ id: 'meet-outside' })}
      </Button>
    </div>
  </Grid>
)

MeetOutSide.propTypes = {
  subTyp: PropTypes.string.isRequired,
  handleSubType: PropTypes.func,
  button: PropTypes.string,
  selectedButton: PropTypes.string,
  f: PropTypes.func,
}

export default React.memo(MeetOutSide)
