import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'

import { GreenRadio } from '../../../utils/tools'
import { Transition } from '../../../utils/tools'

import AddIcon from '../../../../images/add-icon-green.svg'
import AddIconRed from '../../../../images/add-icon-red.svg'

import WebConfirmationDialog from '../../web-confirmation-dialog'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 70,
    width: '100%',
    backgroundColor: 'white',
    marginTop: 0,
    boxShadow: 'none',
    padding: 0,
  },
  actionArea: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 10,
    backgroundColor: 'fff',
    '&:hover': {
      backgroundColor: '#f4f4f4 !important',
    },
  },
  radioContainer: {
    width: '14%',
    minWidth: 45,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textPartContainer: {
    width: '86%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  labelMain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: 5,
  },
  addressText: {
    display: 'flex',
    flexDirection: 'column',
  },
  city: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#69e781',
    height: 25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 5,
  },
  state: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#707070',
    height: 25,
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingTop: 0,
  },
  iconButton: {
    width: 50,
    height: 50,
    padding: 0,
    marginRight: -5,
  },
  deleteAddressDialog: {
    width: '638px',
    height: ' 503px',
    padding: '20px 20px 20px 20px',
    backgroundColor: '#ffffff',
    borderRadius: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const AddressItem = ({
  address,
  checked,
  handleCheck,
  fromAddressQuery,
  addAddress,
  notReachedMax,
  hadleClickCheckedItem,
}) => {
  const classes = useStyles()
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const [warnDialog, setWarnDialog] = useState(false)
  //default checked
  const [def, setDef] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    if (checked === address?.id || fromAddressQuery) setDef(true)
  }, [checked, fromAddressQuery, address])

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const openWarnDialog = () => {
    setWarnDialog(true)
  }

  const closeWarnDialog = () => {
    setWarnDialog(false)
  }

  const handleClick = () => {
    //if already checked just close popup and refill, else setAddress
    if (def) {
      hadleClickCheckedItem()
    } else {
      handleCheck(address)
    }
  }

  return (
    <Card
      className={classes.root}
      style={{
        borderTop: '1px solid #E9ECEF',
      }}
    >
      <div className={classes.actionArea} onClick={handleClick}>
        <div className={classes.radioContainer}>
          <GreenRadio checked={!checked ? fromAddressQuery : checked === address.id} onChange={handleClick} />
        </div>
        <div className={classes.textPartContainer}>
          <div className={classes.labelMain}>
            <div className={classes.addressText} style={{ width: address?.id ? '100%' : '80%' }}>
              <Typography className={classes.city}>
                {address?.name && address?.name.length > 0 ? address.name : address?.city}
              </Typography>
              <Typography className={classes.state}>{address?.formattedAddress}</Typography>
            </div>
            {fromAddressQuery && signedIn && (
              <div className={classes.buttonContainer}>
                {notReachedMax ? (
                  <IconButton className={classes.iconButton} onClick={() => addAddress(address)}>
                    <img src={AddIcon} alt='addIcon' width='50px' height='50px' style={{ fontWeight: 'bold' }} />
                  </IconButton>
                ) : (
                  <IconButton className={classes.iconButton} onClick={() => openWarnDialog()}>
                    <img src={AddIconRed} alt='addIconRed' width='50px' height='50px' style={{ fontWeight: 'bold' }} />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        classes={{ paper: classes.deleteAddressDialog }}
        open={warnDialog}
        onClose={closeWarnDialog}
        TransitionComponent={Transition}
      >
        <WebConfirmationDialog
          close={closeWarnDialog}
          header={getLabel('too-many-addresses')}
          text={getLabel('reached-max-address')}
          text2={false}
          function1={closeWarnDialog}
          function2={false}
          img={'deleteAddressImage'}
          button1={getLabel('continue-label')}
          button2={false}
          id={false}
        />
      </Dialog>
    </Card>
  )
}

AddressItem.propTypes = {
  address: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  handleCheck: PropTypes.func,
  fromAddressQuery: PropTypes.bool,
  addAddress: PropTypes.func,
  notReachedMax: PropTypes.bool,
  hadleClickCheckedItem: PropTypes.func,
}

export default AddressItem
