import React from 'react'
import PropTypes from 'prop-types'
import close from '../../../../images/close.svg'
import backIcon from '../../../../images/black-back.svg'
import { IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import logoIcon from '../../../../images/logo_text_black.svg'

const useStyles = makeStyles((theme) => ({
  top: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  logo: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  bar: {
    height: 80,
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBackButton: {
    top: theme.spacing(2),
    left: theme.spacing(2),
    position: 'absolute',
  },
  rightBackButton: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
  },
}))

export const Header = ({ handleClose }) => {
  const classes = useStyles()

  return (
    <div className={clsx('centered-row', [classes.top])}>
      <IconButton size='small' className={clsx('mobile', [classes.leftBackButton])} onClick={handleClose}>
        <img src={backIcon} alt='' />
      </IconButton>
      <div className={classes.bar}>
        <div className='grow justify-content full-width'>
          <img src={logoIcon} alt='TookFresh' height='36' />
        </div>
      </div>
      <IconButton
        color='inherit'
        className={clsx('desktop', [classes.rightBackButton])}
        onClick={handleClose}
        aria-label='close'
      >
        <img src={close} height='22' width='22' alt='Back' />
      </IconButton>
    </div>
  )
}

Header.propTypes = {
  handleClose: PropTypes.func.isRequired,
}
