import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import noResponse from '../../../images/no-response-received.svg'
import { injectIntl } from 'react-intl'

const height = window.innerHeight

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: height,
    maxHeight: height,
    paddingTop: 100,
  },
  text: {
    textAlign: 'center',
    marginTop: 30,
    textDecoration: 'none',
    color: '#69e781',
    fontSize: 25,
    fontWeight: 500,
  },
}))

const MerchantNoMatch = ({ intl }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <div className={classes.root}>
      <img src={noResponse} alt='no-response' width='600px' height='416px' />
      <p className={classes.text}>{getLabel('not-found')}</p>
    </div>
  )
}

MerchantNoMatch.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(MerchantNoMatch)
