import React, { useMemo, useState, Suspense } from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/MoreHoriz'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { FormattedMessage } from 'react-intl'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import Confirmation from '../common/Confirmation'
import { merchantStoreActions } from '../../../actions/merchant.store.actions'
import { getImage } from '../../../helpers/get-image'
import MenuImageDialog from '../common/imageUpload/dialogs/MenuImageDialog'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 180,
    minWidth: 250,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  content: {
    flex: '1 0 auto',
    width: '50%',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    position: 'relative',
  },
  cover: {
    minWidth: 180,
    height: 180,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.4,
    color: '#252733',
  },
  desc: {
    fontSize: 15,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.4',
    color: '#9fa2b4',
  },
  price: {
    fontSize: 15,
    color: '#9fa2b4',
    position: 'absolute',
    bottom: theme.spacing(),
    left: theme.spacing(),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  buttonContainer: {
    position: 'absolute',
    right: -178,
    paddingTop: 3,
    paddingRight: 3,
    zIndex: 600,
    height: 30,
    width: 30,
    backgroundColor: 'transparent',
    marginRight: -3,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 180,
    height: '100%',
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    '&:hover': {
      '& $tooltip': {
        opacity: 1,
      },
      '& $img': {
        filter: 'opacity(10%)',
      },
    },
  },
  img: {
    transition: '0.3s linear',
  },
  tooltip: {
    opacity: 0,
    width: 130,
    padding: 5,
    transition: '0.3s linear',
    position: 'absolute',
    zIndex: 500,
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: 'white',
    cursor: 'default',
    userSelect: 'none',
    borderRadius: 10,
    textAlign: 'center',
  },
}))

const MenuSectionItem = ({ sectionId, item }) => {
  const classes = useStyles()
  const history = useHistory()
  // Global state
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const storeId = selectedStore.storeId

  // Actions
  const dispatch = useDispatch()
  const deleteMenu = async (storeId, sectionId, itemId) =>
    dispatch(merchantStoreActions.deleteMenu(storeId, sectionId, itemId))
  // Local states
  const [showConfirm, setShowConfirm] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const handleEditClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleEditClose = () => {
    setAnchorEl(null)
  }

  const handleConfirmClose = () => {
    setShowConfirm(false)
  }

  const handleDelete = async () => {
    handleConfirmClose()
    await deleteMenu(storeId, sectionId, item.id)
  }

  const handleImageClick = () => {
    setOpen(!open)
  }

  const menuImage = useMemo(() => item.images.length > 0 && getImage(item.images[0], 'medium'), [item.images])

  return (
    <>
      <Card className={classes.root} variant='outlined'>
        <div className={classes.container}>
          <CardContent className={classes.content}>
            <Typography className={classes.title}>{item.name}</Typography>
            <Typography className={classes.desc}>{item.description}</Typography>
            <Typography className={classes.price}>${item.price}</Typography>
          </CardContent>
          {/* {item.images.length > 0 && <CardMedia className={classes.cover} image={menuImage} title={item.name} />} */}
          <div className={classes.buttonContainer}>
            <IconButton size='small' onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          </div>
          <Menu id='edit-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleEditClose}>
            <MenuItem
              onClick={() => history.push(`${path}/merchant/menus/edit?sectionid=${sectionId}&itemid=${item.id}`)}
            >
              {<FormattedMessage id='edit-label' />}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null)
                setShowConfirm(true)
              }}
            >
              {<FormattedMessage id='delete-label' />}
            </MenuItem>
            <MenuItem onClick={() => history.push(`${path}/merchant/menus/editattribute?menuid=${item.id}`)}>
              {<FormattedMessage id='edit-attributes' />}
            </MenuItem>
          </Menu>
          <Confirmation
            open={showConfirm}
            close={handleConfirmClose}
            callback={handleDelete}
            item={item}
            title='are-you-sure'
            text='about-delete-menu'
            rightButton='delete-label'
          />
        </div>
        <div className={classes.imageContainer} onClick={handleImageClick}>
          {item.images.length > 0 && (
            <img className={classes.img} src={menuImage} alt={item.name} width='180px' height='180px' />
          )}
          <div className={classes.tooltip}>
            {item.images.length > 0 ? 'Click to update image' : 'Click to add image'}
          </div>
        </div>
      </Card>
      <Suspense fallback={<div></div>}>
        <MenuImageDialog open={open} handleClose={handleImageClick} item={item} sectionId={sectionId} />
      </Suspense>
    </>
  )
}

MenuSectionItem.propTypes = {
  intl: PropTypes.object,
  sectionId: PropTypes.number,
  item: PropTypes.object,
}

export default MenuSectionItem
