import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { initialAttributeState, initialError, limits } from '../../../../constants'
import { FormControlLabel, Grid, makeStyles, Switch, TextField } from '@material-ui/core'
import { BlackButton, GreenButton } from '../../../utils/tools'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { attributeActions } from '../../../../actions'
import { checkAddButton, checkUpdateButton } from '../../../../helpers/merchant/button-status'
import { useCallback } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
  row: {
    paddingBottom: theme.spacing(),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
    minWidth: 90,
  },
}))

const AddAttribute = ({ onClose, intl, editAttribute }) => {
  const classes = useStyles()
  const [attribute, setAttribute] = useState(editAttribute ? editAttribute : initialAttributeState)
  const [error, setError] = useState(initialError)

  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const dispatch = useDispatch()
  const createNewAttribute = async (storeId, data) => dispatch(attributeActions.createAttribute(storeId, data))
  const updateAttribute = async (storeId, attrId, data) =>
    dispatch(attributeActions.updateAttribute(storeId, attrId, data))

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleNumberChange = (e) => {
    const { name, value } = e.target
    if (value && value >= limits.MINCOUNT && value <= limits.MAXCOUNT) {
      error && setError({ ...error, [name]: false })
    } else {
      setError({ ...error, [name]: true })
    }
    setAttribute({ ...attribute, [name]: value })
  }

  const handleTextChange = (event) => {
    const { name, value } = event.target
    setAttribute({ ...attribute, [name]: value })
    setError({ ...error, [name]: !value })
  }

  const handleSwitch = (event) => {
    const checked = event.target.checked
    setAttribute({ ...attribute, required: checked })
  }

  const onFormSubmit = async () => {
    editAttribute ? attributeUpdate() : attributeCreate()
  }

  const attributeUpdate = async () => {
    await updateAttribute(selectedStore?.storeId, editAttribute?.id, attribute)
    onClose()
  }
  const attributeCreate = async () => {
    await createNewAttribute(selectedStore?.storeId, attribute)
    onClose()
  }

  const editDisableCondition = checkUpdateButton(editAttribute, attribute)

  const addDisableCondition = checkAddButton(attribute)

  const maximumCountErrorMsg = useCallback(() => {
    let errorMsg = ''
    if (error.maximumCount) {
      errorMsg = getLabel('enter-between')
    }
    if (+attribute.minimumCount > +attribute.maximumCount) {
      errorMsg = getLabel('max-be-greater-min')
    }
    return errorMsg
  }, [error.maximumCount, attribute.maximumCount])

  const freeCountErrorMsg = useCallback(() => {
    let errorMsg = ''
    if (error.freeCount) {
      errorMsg = getLabel('enter-between')
    }
    if (+attribute.freeCount > +attribute.maximumCount || +attribute.freeCount > +attribute.minimumCount) {
      errorMsg = getLabel('free-count-smaller-than')
    }
    return errorMsg
  }, [error.maximumCount, attribute.maximumCount])

  const isErrorMaximum = useMemo(() => {
    return Boolean(error.maximumCount || (attribute.maximumCount && +attribute.minimumCount > +attribute.maximumCount))
  }, [error.maximumCount, attribute.maximumCount, attribute.minimumCount])

  const isErrorFreeCount = useMemo(() => {
    return Boolean(
      error.freeCount ||
        (attribute.freeCount &&
          (+attribute.freeCount > +attribute.maximumCount || +attribute.freeCount > +attribute.minimumCount))
    )
  }, [error.freeCount, attribute.freeCount, attribute.maximumCount, attribute.minimumCount])

  return (
    <Grid container className={classes.root}>
      <Grid xs={12} item className={classes.row}>
        <TextField
          variant='outlined'
          required
          fullWidth
          id='name'
          name='name'
          value={attribute.name}
          label={getLabel('name-label')}
          autoFocus
          error={error.name}
          onChange={handleTextChange}
          inputProps={{
            maxLength: 50,
          }}
        />
      </Grid>
      <Grid xs={12} item className={classes.row}>
        <TextField
          variant='outlined'
          fullWidth
          multiline={true}
          maxRows={5}
          id='description'
          name='description'
          value={attribute.description}
          label={getLabel('description-label')}
          onChange={handleTextChange}
          helperText={250 - attribute.description.length + ' remains'}
          inputProps={{
            maxLength: 250,
          }}
        />
      </Grid>
      <Grid xs={12} item className={classes.row}>
        <TextField
          variant='outlined'
          fullWidth
          required
          id='minimumCount'
          name='minimumCount'
          value={attribute.minimumCount}
          label={getLabel('min-count')}
          onChange={handleNumberChange}
          type='number'
          error={error.minimumCount}
          inputProps={{
            min: limits.MINCOUNT,
            max: limits.MAXCOUNT,
          }}
          helperText={error.minimumCount ? getLabel('enter-between') : ''}
        />
      </Grid>
      <Grid xs={12} item className={classes.row}>
        <TextField
          variant='outlined'
          fullWidth
          required
          id='maximumCount'
          name='maximumCount'
          value={attribute.maximumCount}
          label={getLabel('max-count')}
          onChange={handleNumberChange}
          type='number'
          error={isErrorMaximum}
          inputProps={{
            min: limits.MINCOUNT,
            max: limits.MAXCOUNT,
          }}
          helperText={maximumCountErrorMsg()}
        />
      </Grid>
      <Grid xs={12} item className={classes.row}>
        <TextField
          variant='outlined'
          fullWidth
          required
          id='freeCount'
          name='freeCount'
          value={attribute.freeCount}
          label={getLabel('free-count')}
          onChange={handleNumberChange}
          type='number'
          error={isErrorFreeCount}
          inputProps={{
            min: limits.MINCOUNT,
            max: limits.MAXCOUNT,
          }}
          helperText={freeCountErrorMsg()}
        />
      </Grid>
      <Grid xs={12} item className={classes.row}>
        <FormControlLabel
          control={<Switch checked={attribute.required} onChange={handleSwitch} name='required' color='primary' />}
          label='Required'
        />
      </Grid>
      <Grid xs={12} item className={classes.actionRow}>
        <BlackButton className={classes.button} onClick={onClose}>
          {getLabel('cancel-label')}
        </BlackButton>
        <GreenButton
          disabled={editAttribute ? editDisableCondition : addDisableCondition}
          className={classes.button}
          onClick={onFormSubmit}
        >
          {getLabel(editAttribute ? 'update-label' : 'add-label')}
        </GreenButton>
      </Grid>
    </Grid>
  )
}

AddAttribute.propTypes = {
  intl: PropTypes.object,
  editAttribute: PropTypes.object,
  onClose: PropTypes.func,
}

export default injectIntl(AddAttribute)
