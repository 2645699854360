import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { merchantActions } from '../../../actions'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import BeMemberDialog from './BeMemberDialog'
import logoIcon from '../../../images/logo_text_black.svg'
import ForgotPasswordDialog from './forgotPasswordDialog'
import EmailField from './signin/EmailField'
import PasswordField from './signin/PasswordField'
import LinksAndButton from './signin/LinksAndButton'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

const path = process.env.PUBLIC_URL
const height = window.innerHeight

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#fff',
    minHeight: height,
  },
  bar: {
    height: 80,
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(6),
  },
  textField: {
    marginTop: theme.spacing(3),
  },
  logo: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}))

const MerchantSignin = ({ history }) => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()

  const signIn = (email, password) => dispatch(merchantActions.signin(email, password))
  const signedIn = useSelector(({ merchant }) => merchant.signedIn)

  const [showPasswordDialog, setPasswordDialog] = useState(false)
  const [showBeMemberDialog, setShowBeMemberDialog] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isFocused, setIsFocused] = useState({
    email: false,
    password: false,
  })
  const [values, setValues] = useState({
    email: '',
    password: '',
  })
  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
    setIsFocused({ ...isFocused, [name]: true })
  }

  const onFormSubmit = (event) => {
    event.preventDefault()
    const { email, password } = values
    if (email && password) {
      signIn(email, password)
    }
  }

  const togglePaswordDialog = () => setPasswordDialog(!showPasswordDialog)

  const toggleBeMemberDialog = () => setShowBeMemberDialog(!showBeMemberDialog)

  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  const handleRedirect = async () => {
    history.push(`${path}/`)
  }
  if (signedIn) {
    return <Redirect to='/merchant' />
  }
  return (
    <div className={clsx('desktop', [classes.root])}>
      <div className={classes.bar}>
        <div className='grow justify-content full-width'>
          <IconButton
            color='inherit'
            className={classes.logo}
            onClick={handleRedirect}
            disableFocusRipple
            disableRipple
            aria-label='back'
          >
            <img src={logoIcon} alt='TookFresh' height='36' />
          </IconButton>
        </div>
      </div>
      <Container maxWidth='xs'>
        <div className={classes.paper}>
          <Typography variant='h4'>{f({ id: 'merchant-signin' })}</Typography>
          <EmailField email={values.email} isFocused={isFocused.email} classes={classes} handleChange={handleChange} />
          <PasswordField
            showPassword={showPassword}
            password={values.password}
            handleChange={handleChange}
            isFocused={isFocused.password}
            togglePasswordVisibility={togglePasswordVisibility}
          />
          <LinksAndButton
            togglePaswordDialog={togglePaswordDialog}
            toggleBeMemberDialog={toggleBeMemberDialog}
            onFormSubmit={onFormSubmit}
          />
        </div>
        <ForgotPasswordDialog
          showForgotPasswordDialog={showPasswordDialog}
          handleForgotPasswordClose={togglePaswordDialog}
        />
        <BeMemberDialog isOpen={showBeMemberDialog} handleClose={toggleBeMemberDialog} />
      </Container>
    </div>
  )
}

MerchantSignin.propTypes = {
  history: PropTypes.object,
}

export default withRouter(MerchantSignin)
