import { starMarks } from '../../constants'
import { marksPrice } from '../searchFilter/marks-price'
import { marksDistance } from '../searchFilter/marks-distance'

const getSliderData = (name, searchData, sliderData) => {
  switch (name) {
    case 'price':
      return {
        label: 'price-label',
        value: searchData?.pricey,
        marks: marksPrice(),
        step: 1,
        min: 1,
        max: 4,
      }
    case 'distance':
      return {
        label: 'distance-label-mile',
        value: searchData?.distance,
        marks: marksDistance(),
        step: 5,
        min: 5,
        max: 35,
      }
    case 'rating':
      return {
        label: 'rating-label',
        value: +searchData?.rating * -1,
        marks: starMarks.slice(1),
        step: 0.5,
        min: -5,
        max: -2,
      }
    default:
      return sliderData
  }
}

export { getSliderData }
