import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'

import PopoverContent from './AddresSavePopover/PopoverContent'

const useStyles = makeStyles(() => ({
  root: {
    width: 300,
    height: 'auto',
    marginTop: 1,
    marginLeft: 5,
  },
}))

const AddressSavePopover = ({
  openAddressPopover,
  closeAddressPopover,
  anchor,
  currentAddress,
  addressHeader,
  addressQuery,
  addAddressToStore,
  setAddress,
  welcomePage,
  savedAddress,
  savedBefore,
  setUserDeliverySubTypes,
  setUserInstructions,
  setUserDeliveryType,
  setOpenAddressPopover,
  updateAddress,
  defaultAddressName,
}) => {
  const classes = useStyles()
  return (
    <Popover
      id='address-popover'
      disableRestoreFocus
      classes={{
        paper: classes.root,
      }}
      open={openAddressPopover}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <PopoverContent
        currentAddress={currentAddress}
        addressHeader={addressHeader}
        addressQuery={addressQuery}
        closeAddressPopover={closeAddressPopover}
        addAddressToStore={addAddressToStore}
        setAddress={setAddress}
        welcomePage={welcomePage}
        savedAddress={savedAddress}
        savedBefore={savedBefore}
        setUserDeliverySubTypes={setUserDeliverySubTypes}
        setUserInstructions={setUserInstructions}
        setUserDeliveryType={setUserDeliveryType}
        setOpenAddressPopover={setOpenAddressPopover}
        updateAddress={updateAddress}
        defaultAddressName={defaultAddressName}
      />
    </Popover>
  )
}

AddressSavePopover.propTypes = {
  openAddressPopover: PropTypes.bool,
  closeAddressPopover: PropTypes.func,
  anchor: PropTypes.object,
  currentAddress: PropTypes.string,
  addressHeader: PropTypes.string,
  addressQuery: PropTypes.object,
  addAddressToStore: PropTypes.func,
  setAddress: PropTypes.func,
  welcomePage: PropTypes.bool,
  savedAddress: PropTypes.object,
  savedBefore: PropTypes.bool,
  setUserDeliverySubTypes: PropTypes.func,
  setUserInstructions: PropTypes.func,
  setUserDeliveryType: PropTypes.func,
  setOpenAddressPopover: PropTypes.func,
  updateAddress: PropTypes.func,
  defaultAddressName: PropTypes.string,
}

export default AddressSavePopover
