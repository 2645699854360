const idLabels = {
  store_nickname_s: 'name-label',
  rating: 'rating-label',
  high_low: 'high-low-cost',
  low_high: 'low-high-cost',
}

const idFabs = {
  store_nickname_sasc: 'name-label',
  ratingdesc: 'rating-label',
  pricey_idesc: 'high-low-cost',
  pricey_iasc: 'low-high-cost',
}

const idFinder = (value) => idLabels[value]
const idFinderFabs = (value) => idFabs[value]

export { idFinder, idFinderFabs }
