import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import IntersectionVisible from 'react-intersection-visible'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { customerActions } from '../../../../actions'
import deliveryTimeIcon from '../../../../images/white-delivery-time.svg'
import rate from '../../../../images/white-rate.svg'
import notFavoritedIcon from '../../../../images/gray-favorite.svg'
import favoritedIcon from '../../../../images/green-favorite.svg'
import priceFilter from '../../../../helpers/price-icons'
import SigninModal from '../../../common/SigninModal'
import { getImage } from '../../../../helpers/get-image'
import backgroundPlaceholder from '../../../../images/restaurant-back.jpeg'
import { deliveryTypes, deliveryTimes } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  banner: {
    height: 350,
    display: 'flex',
    flexDirection: 'column',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: 240,
    paddingBottom: theme.spacing(),
    backgroundColor: '#fff',
    border: '1px solid #f4f4f4',
  },
  storeTitle: {
    fontSize: 50,
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    fontWeight: 'bold',
    lineHeight: 1.32,
    color: '#fff',
    textShadow: '2px 2px 4px #000000',
  },
  category: {
    fontSize: 20,
    lineHeight: 0.8,
    letterSpacing: 0.43,
    textAlign: 'left',
    color: '#ffffff',
    width: '100%',
    height: 26,
    textShadow: '2px 2px 4px #000000',
  },
  minHeadCount: {
    fontSize: 20,
    lineHeight: 0.8,
    letterSpacing: 0.43,
    textAlign: 'left',
    color: '#ffffff',
    width: '100%',
    height: 26,
    marginBottom: theme.spacing(5),
    fontWeight: 'bold',
    textShadow: '2px 2px 4px #000000',
  },
  iconContainer: {
    width: 25,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    filter: 'drop-shadow(2px 2px 4px #000000)',
  },
  storeDetails: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.32,
    color: '#fff',
    textShadow: '2px 2px 4px #000000',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(4),
    cursor: 'pointer',
  },
  priceIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(4),
  },
  close: {
    width: 'inherit',
    height: 100,
    marginBottom: -100,
    zIndex: 1,
    padding: '15px 0px 0px 15px',
    display: 'flex',
    justifyContent: 'flex-end',
    "&[store-closed='true']": {
      fontSize: 20,
      textShadow: '2px 2px 4px #000000',
      fontWeight: 'bold',
      background: 'linear-gradient(to top, rgba(30,87,153,0) 0%, black 90%, black 99%,rgb(255, 255, 255) 100%)',
      color: '#fff',
      justifyContent: 'space-between',
    },
  },
  favorite: {
    height: 40,
    width: 40,
    filter: 'drop-shadow(1px 1px 10px black)',
    marginRight: theme.spacing(),
    marginTop: -theme.spacing(),
  },
  storeInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: 150,
    },
  },
  grow: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: 0,
    },
  },
}))

const StoreBackground = ({ store, setSelectedSection, sections, format, openStoreMoreDialog, id, ocStatus }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()

  const [isFavorite, setIsFavorite] = useState(false)
  const [openSigninModal, setOpenSigninModal] = useState(false)

  const favorites = useSelector(({ customer }) => customer.favoriteStores)
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const deliveryType = useSelector(({ search }) => search.searchQuery.deliveryType)
  const deliveryTime = useSelector(({ search }) => search.searchQuery.deliveryTime)

  const saveFavorite = (id) => dispatch(customerActions.saveFavoriteStore(id))
  const deleteFavorite = (id) => dispatch(customerActions.deleteFavoriteStore(id))

  useEffect(() => {
    signedIn && favorites?.some((favorite) => favorite.entityId === +id) ? setIsFavorite(true) : setIsFavorite(false)
  }, [favorites, id])

  const handleFavoriteIconClick = () => {
    if (!signedIn) {
      handleSigninModalOpen()
    } else {
      let includes = false
      let storeId
      if (favorites?.length > 0) {
        favorites.forEach((store) => {
          store.entityId === +id && ([includes, storeId] = [true, store.id])
        })
      }
      includes ? deleteFavorite(storeId) : saveFavorite(id)
    }
  }

  const handleSigninModalOpen = () => setOpenSigninModal(true)
  const handleSigninModalClose = () => setOpenSigninModal(false)
  // THUMB IS GOOD ENOUGH FOR HQ IMAGES. CAN BE REPLACED BY MEDIUM
  const path = getImage(store.restaurant?.background, 'thumb') || backgroundPlaceholder
  const isDelivery = deliveryType === deliveryTypes.DELIVERY
  const isASAP = deliveryTime === deliveryTimes.ASAP

  return (
    <Grid container>
      <div className={classes.close} store-closed={ocStatus ? 'false' : 'true'}>
        {ocStatus ? '' : format({ id: 'closed-label' })}
        <IconButton className={classes.favorite} onClick={handleFavoriteIconClick}>
          <img src={isFavorite ? favoritedIcon : notFavoritedIcon} alt='' />
        </IconButton>
      </div>
      <Grid
        item
        xs={12}
        className={classes.banner}
        style={{
          backgroundImage: `url(${path})`,
        }}
      >
        <div className='grow' />
        <Typography className={classes.storeTitle}>{store.nickname}</Typography>
        <Typography className={classes.category}>
          {store?.categories?.map((cat, i) => (
            <span key={i}>
              {cat !== store.categories[store.categories.length - 1] ? `${cat.name} | ` : `${cat.name}`}
            </span>
          ))}
        </Typography>
        <Typography className={classes.minHeadCount}>
          {f({ id: 'min-head-count' })} : {store.minHeadCount}
        </Typography>
        <div className={classes.storeInfo}>
          {ocStatus && isDelivery && isASAP && (
            <div style={{ display: 'flex' }}>
              <div className={classes.iconContainer}>
                <img src={deliveryTimeIcon} alt='Delivery time' height='20' className={classes.icon} />
              </div>
              <Typography className={classes.storeDetails}>{store.deliveryTime} mins</Typography>
            </div>
          )}
          {store.ratingSummary && (
            <div style={{ display: 'flex' }}>
              <img src={rate} alt='Rate' height='20' className={classes.icon} />
              <Typography className={classes.storeDetails}>
                {store.ratingSummary.averageRating?.toFixed(1)} ({store.ratingSummary.totalRatingCount})
              </Typography>
            </div>
          )}
          <IntersectionVisible onShow={() => setSelectedSection(sections?.length > 0 && sections[0].name)}>
            <div className={classes.priceIcon}>
              <div style={{ height: 20 }}>{priceFilter(store.pricey, 20, true)}</div>
            </div>
          </IntersectionVisible>
          <div className={classes.grow} />
          {/* <RoomIcon className={classes.icon} style={{ color: 'white' }} /> */}
          {/* <Typography className={classes.storeDetails}>{store.storeInformation.address.addressLine1}</Typography> */}
          <Typography className={classes.storeDetails} onClick={() => openStoreMoreDialog()}>
            {f({ id: 'more-info' })}
          </Typography>
        </div>
      </Grid>
      <SigninModal open={openSigninModal} onClose={handleSigninModalClose} />
    </Grid>
  )
}

export default StoreBackground
