import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import CustomizedPhoneInput from '../Common/CustomizedPhoneInput'

import { GreenRadio } from '../../../../utils/tools'

import { paymentDetailConstants, valueConstants, labelConstants, phoneAreaConstants } from '../../../businessConstants'

const PhoneField = ({ classes, values, setValues, error }) => {
  const { formatMessage: f } = useIntl()

  const [phoneField, setPhoneField] = useState('')

  useEffect(() => {
    if (values) {
      if (values.paymentContactPhone === values.phoneNo) {
        setPhoneField(phoneAreaConstants[0])
      } else if (values.paymentContactPhone === values.storePhone) {
        setPhoneField(phoneAreaConstants[1])
      } else {
        setPhoneField(phoneAreaConstants[2])
      }
    }
  }, [values])

  const handlePhoneField = (e) => {
    setPhoneField(e.target.value)
    handleContactPhone(e.target.value)
  }

  const handleContactPhone = (value) => {
    if (value === paymentDetailConstants.myPhone) {
      setValues({
        ...values,
        paymentContactPhone: values.phoneNo,
      })
    } else if (value === paymentDetailConstants.storePhone) {
      setValues({
        ...values,
        paymentContactPhone: values.storePhone,
      })
    } else {
      setValues({
        ...values,
        paymentContactPhone: '1',
      })
    }
  }
  let customItem = { value: valueConstants.paymentContactPhone }

  return (
    <Grid item md={6} sm={12} xs={12} className={classes.gridItem} phone-field='true'>
      <FormControl fullWidth className={classes.phoneFieldFormControl}>
        {phoneAreaConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            control={<GreenRadio checked={phoneField === item} onChange={handlePhoneField} value={item} />}
            label={<Typography>{item}</Typography>}
          />
        ))}
      </FormControl>
      <CustomizedPhoneInput
        valueName={valueConstants.paymentContactPhone}
        values={values}
        item={customItem}
        setValues={setValues}
        label={f({ id: labelConstants.paymentContactPhone })}
        disabled={phoneField !== phoneAreaConstants[2]}
        error={error}
      />
    </Grid>
  )
}

PhoneField.propTypes = {
  values: PropTypes.object,
  classes: PropTypes.object,
  setValues: PropTypes.func,
  error: PropTypes.bool,
}

export default PhoneField
