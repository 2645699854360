import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import placeholder from '../../../../../../images/image-placeholder.svg'
import background from '../../../../../../images/restaurant-back.jpeg'
import clsx from 'clsx'
import { getImage } from '../../../../../../helpers/get-image'
import { imageHeight } from '../../../../../../helpers/orders'
import useStyles from './styles'
import { width } from '../../../../../../helpers'

const ImageHeader = ({ order }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} className={classes.container}>
      <Grid xs={12} item className={classes.subContainer}>
        <Grid item xs={3} className={classes.logoContainer}>
          <img
            src={getImage(order.restaurant?.logo, 'medium') || placeholder}
            alt='logo'
            width={imageHeight}
            height={imageHeight}
            className={classes.logo}
          />
        </Grid>
        <Grid item xs={9} className={classes.storeInfoContainer}>
          <div style={{ display: 'flex', width: '100%' }}>
            <Typography variant='h6' component='p' className={clsx('ellipsis', classes.storeName)}>
              {order.store.nickname}
            </Typography>
          </div>
          <div style={{ display: 'flex', width: '100%' }}>
            <Typography variant='h6' className={clsx('ellipsis', classes.sectionNames)}>
              {order?.restaurant?.description}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <img
        src={getImage(order.restaurant?.background, 'thumb') || background}
        alt='background'
        width={width}
        // height='120'
        className={classes.background}
      />
    </Grid>
  )
}

ImageHeader.propTypes = {
  order: PropTypes.object,
}

export default ImageHeader
