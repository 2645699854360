import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'

import TiersContainer from './DeliveryDetails/TiersContainer'
import TfDefault from './DeliveryDetails/TfDefault'

import { GreenRadio } from '../../../utils/tools'
import { deliveryRole, valueConstants } from '../../businessConstants'

const useStyles = makeStyles((theme) => ({
  tier: {
    marginTop: theme.spacing(4),
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    minWidth: 85,
    "&[add-button='true']": {
      marginRight: theme.spacing(),
    },
  },
  fileName: {
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    "&[error-text='true']": {
      fontWeight: 300,
      marginTop: theme.spacing(2.5),
      color: theme.palette.secondary.main,
    },
  },
  startAdornment: {
    fontWeight: 500,
    paddingTop: 2,
    opacity: '0.5',
  },
  endAdornment: {
    fontWeight: 500,
    opacity: '0.5',
  },
}))

const DeliveryDetails = ({
  handleChange,
  values,
  setValues,
  secondTierState,
  setSecondTier,
  thirdTierState,
  setThirdTier,
  error,
  tierError,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  useEffect(() => {
    if (values.deliveryBy === deliveryRole[0]) {
      removeAllTiers()
      removeTierStates()
    } else {
      setValues({
        ...values,
        [valueConstants.maxDeliveryDistance]: '',
        [valueConstants.minOrderPrice]: '',
      })
    }
  }, [values.deliveryBy])

  useEffect(() => {
    if (values.deliveryBy === deliveryRole[1]) {
      if (
        values.secondTierDeliveryFee.length > 0 ||
        values.secondTierMaxDistance.length > 0 ||
        values.secondTierMinOrder.length > 0
      ) {
        setSecondTier(true)
      } else return
    } else return
  }, [values.deliveryBy, values.secondTierDeliveryFee, values.secondTierMaxDistance, values.secondTierMinOrder])

  useEffect(() => {
    if (values.deliveryBy === deliveryRole[1]) {
      if (
        values.thirdTierDeliveryFee.length > 0 ||
        values.thirdTierMaxDistance.length > 0 ||
        values.thirdTierMinOrder.length > 0
      ) {
        setThirdTier(true)
      } else return
    } else return
  }, [values.deliveryBy, values.thirdTierDeliveryFee, values.thirdTierMaxDistance, values.thirdTierMinOrder])

  const addTier = () => (secondTierState ? setThirdTier(true) : setSecondTier(true))

  const removeTier = () => {
    if (thirdTierState) {
      setThirdTier(false)
      if (
        values?.thirdTierDeliveryFee.length > 0 ||
        values?.thirdTierMaxDistance.length > 0 ||
        values?.thirdTierMinOrder.length > 0
      ) {
        removeThirdTier()
      }
    } else {
      setSecondTier(false)
      if (
        values?.secondTierDeliveryFee.length > 0 ||
        values?.secondTierMaxDistance.length > 0 ||
        values?.secondTierMinOrder.length > 0
      ) {
        removeSecondTier()
      }
    }
  }

  const removeSecondTier = () => {
    setValues({
      ...values,
      secondTierDeliveryFee: '',
      secondTierMinOrder: '',
      secondTierMaxDistance: '',
    })
  }

  const removeThirdTier = () => {
    setValues({
      ...values,
      thirdTierDeliveryFee: '',
      thirdTierMinOrder: '',
      thirdTierMaxDistance: '',
    })
  }

  const removeAllTiers = () => {
    setValues({
      ...values,
      firstTierDeliveryFee: '',
      firstTierMinOrder: '',
      firstTierMaxDistance: '',
      secondTierDeliveryFee: '',
      secondTierMinOrder: '',
      secondTierMaxDistance: '',
      thirdTierDeliveryFee: '',
      thirdTierMinOrder: '',
      thirdTierMaxDistance: '',
    })
  }

  const removeTierStates = () => {
    setSecondTier(false)
    setThirdTier(false)
  }

  return (
    <Container maxWidth='md' className={classes.root}>
      <FormControl fullWidth>
        <FormGroup aria-label='position' row>
          {deliveryRole.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <GreenRadio
                  checked={values.deliveryBy === item}
                  onChange={handleChange(valueConstants.deliveryBy)}
                  value={item}
                />
              }
              label={<Typography>{item === valueConstants.tookfresh ? 'TookFresh' : 'Business'}</Typography>}
            />
          ))}
        </FormGroup>
      </FormControl>
      {values.deliveryBy === deliveryRole[1] ? (
        <TiersContainer
          values={values}
          handleChange={handleChange}
          secondTierState={secondTierState}
          thirdTierState={thirdTierState}
          addTier={addTier}
          removeTier={removeTier}
          classes={classes}
        />
      ) : (
        <TfDefault values={values} handleChange={handleChange} error={error} f={f} classes={classes} />
      )}
      <div>
        {error && values.deliveryBy === deliveryRole[1] && (
          <Typography className={classes.fileName} error-text='true'>
            {f({ id: 'delivery-details-error' })}
          </Typography>
        )}
        {tierError && values.deliveryBy === deliveryRole[1] && (
          <Typography className={classes.fileName} error-text='true'>
            {f({ id: 'tier-error' })}
          </Typography>
        )}
      </div>
    </Container>
  )
}

DeliveryDetails.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  secondTierState: PropTypes.bool,
  setSecondTier: PropTypes.func,
  thirdTierState: PropTypes.bool,
  setThirdTier: PropTypes.func,
  error: PropTypes.bool,
  tierError: PropTypes.bool,
}

export default DeliveryDetails
