import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import favoritedIcon from '../../../images/green-favorite.svg'
import notFavoritedIcon from '../../../images/gray-favorite.svg'

const useStyles = makeStyles((theme) => ({
  favorite: {
    height: 22,
    width: 22,
    filter: 'drop-shadow(1px 1px 5px gray)',
  },
  favoriteButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    height: 30,
    width: 30,
    zIndex: 10,
    // "&[store-open='false']": {
    //   opacity: '0.6',
    // },
  },
}))

const FavoriteButton = ({ data, onPress, show, isFavorite, storeOpen }) => {
  const classes = useStyles()

  if (!show) return null
  return (
    <IconButton
      store-open={(!!storeOpen).toString()}
      className={classes.favoriteButton}
      onClick={() => onPress(data.id)}
    >
      <img src={isFavorite ? favoritedIcon : notFavoritedIcon} alt='favorite' className={classes.favorite} />
    </IconButton>
  )
}

FavoriteButton.propTypes = {
  data: PropTypes.object,
  onPress: PropTypes.func,
  show: PropTypes.bool,
  isFavorite: PropTypes.bool,
  storeOpen: PropTypes.bool,
}

export default FavoriteButton
