import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Grid, TextField } from '@material-ui/core'

const StoreNumber = ({ store, intl, storeNumberHandler, editting }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item sm={6} xs={12}>
      <TextField
        size='small'
        variant='outlined'
        fullWidth
        id='storeNumber'
        label={getLabel('store-number')}
        value={store.storeNumber ? store.storeNumber : ''}
        onChange={storeNumberHandler}
        inputProps={{
          maxLength: 50,
          readOnly: !editting,
        }}
      />
    </Grid>
  )
}

StoreNumber.propTypes = {
  store: PropTypes.object,
  intl: PropTypes.object,
  storeNumberHandler: PropTypes.func.isRequired,
  editting: PropTypes.bool,
}

export default injectIntl(StoreNumber)
