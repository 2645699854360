import React from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import closeIcon from '../../../../images/close-icon.svg'

const ellipsis = {
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const useStyles = makeStyles((theme) => ({
  input: {
    ...ellipsis,
    width: '100%',
    height: 48,
    borderRadius: 5,
    fontSize: 16,
    fontWeight: 500,
    color: '#707070',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    userSelect: 'none',
    '&:hover $whenFilled, & .Mui-focused $whenFilled': {
      visibility: 'visible',
    },
    "&[component='store']": {
      height: 36,
      marginTop: theme.spacing(),
      '& .MuiInputBase-input': {
        padding: '1px 0px 7px',
      },
    },
  },
  inputText: {
    ...ellipsis,
    width: '100%',
    fontSize: 16,
    fontFamily: 'Roboto',
  },
  whenFilled: {},
  whenEmpty: {
    transition: 'visibility .5s ease-in-out',
    '-moz-transition': 'visibility .5s ease-in-out',
    visibility: 'hidden',
    height: 17,
    width: 17,
  },
}))

const InputArea = ({
  onFocus,
  onClickHandler,
  component,
  clearInputField,
  popoverAnchor,
  getInputProps,
  welcomePage,
  deliveryDetails,
  currentAddress,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <Input
      key='inputField'
      onFocus={onFocus}
      onClick={onClickHandler}
      component={component}
      onMouseDown={clearInputField}
      ref={popoverAnchor}
      {...getInputProps({
        placeholder: f({ id: 'address-zip' }),
        className: classes.input,
      })}
      inputProps={{
        className: classes.inputText,
      }}
      disableUnderline
      endAdornment={
        <InputAdornment position='end'>
          <IconButton
            style={{
              width: welcomePage && 40,
              height: welcomePage && 40,
            }}
            onClick={clearInputField}
            className={clsx(classes.whenEmpty, {
              [classes.whenFilled]: currentAddress?.length > 0,
            })}
          >
            <img
              src={closeIcon}
              alt='close'
              height={deliveryDetails ? '14px' : '20px'}
              width={deliveryDetails ? '14px' : '20px'}
            />
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

InputArea.propTypes = {
  onFocus: PropTypes.func,
  onClickHandler: PropTypes.func,
  component: PropTypes.string,
  clearInputField: PropTypes.func,
  popoverAnchor: PropTypes.object,
  getInputProps: PropTypes.func,
  welcomePage: PropTypes.bool,
  deliveryDetails: PropTypes.bool,
  currentAddress: PropTypes.string,
}

export default InputArea
