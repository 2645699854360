import review from '../../images/merchant/merchant-sidebar-reviews.svg'
import reports from '../../images/merchant/merchant-sidebar-reports.svg'
import menus from '../../images/merchant/merchant-sidebar-menus.svg'
import payments from '../../images/merchant/merchant-sidebar-payments.svg'
import stores from '../../images/merchant/merchant-sidebar-stores.svg'
import settings from '../../images/merchant/merchant-sidebar-settings.svg'
import signout from '../../images/merchant/merchant-sidebar-logout.svg'
import orders from '../../images/merchant/merchant-sidebar-order.svg'

const drawerWidth = 260

const firstMenuRestaurantView = [
  // Move bankinfo from restaurant to store

  {
    name: 'restaurant-info',
    image: payments,
    label: 'restaurant-info-label',
  },
  {
    name: 'payments',
    image: payments,
    label: 'payments-label',
  },
  {
    name: 'reports',
    image: reports,
    label: 'reports-label',
  },
  // {
  //   name: 'reviews',
  //   image: review,
  //   label: 'reviews-label',
  // },
  {
    name: 'stores',
    image: stores,
    label: 'stores-label',
  },
]
const firstMenuStoreView = [
  {
    name: 'store-info',
    image: stores,
    label: 'store-info-label',
  },
  {
    name: 'store-details',
    image: payments,
    label: 'store-details-label',
    inCollapse: true,
  },
  {
    name: 'delivery-options',
    image: payments,
    label: 'delivery-options-label',
    inCollapse: true,
  },
  {
    name: 'working-hours',
    image: payments,
    label: 'working-hours-label',
    inCollapse: true,
  },
  {
    name: 'bankinfo',
    image: payments,
    label: 'bankinfo-label',
    inCollapse: true,
  },
  {
    name: 'notifications',
    image: payments,
    label: 'notifications-label',
    inCollapse: true,
  },
  {
    name: 'payment-contact-info',
    image: payments,
    label: 'payment-contact-info-label',
    inCollapse: true,
  },
  {
    name: 'menus',
    image: menus,
    label: 'menus-label',
  },
  {
    name: 'payments',
    image: payments,
    label: 'payments-label',
  },
  {
    name: 'reports',
    image: reports,
    label: 'reports-label',
  },
  {
    name: 'reviews',
    image: review,
    label: 'reviews-label',
  },
  {
    name: 'employees',
    image: review,
    label: 'employees-label',
  },
  {
    name: 'orders',
    image: orders,
    label: 'orders-label',
    onlyAdminVisible: false,
  },
  {
    name: 'campaigns',
    image: review,
    label: 'promotions-label',
  },
]

const secondDrawerMenu = [
  {
    name: 'settings',
    image: settings,
    label: 'settings-label',
  },
  {
    name: 'signout',
    image: signout,
    label: 'sign-out',
  },
]

const storeInfoTabs = [
  'store-info',
  'store-details',
  'delivery-options',
  'bankinfo',
  'notifications',
  'payment-contact-info',
  'working-hours',
]

export { firstMenuRestaurantView, firstMenuStoreView, secondDrawerMenu, drawerWidth, storeInfoTabs }
