import React from 'react'
import { makeStyles } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import '../../../styles/merchant_global.css'
import { drawerWidth } from '../../../helpers/merchant/drawer-menu'
import DrawerHeader from './drawer-menu/DrawerHeader'
import Selections from './drawer-menu/Selections'
import clsx from 'clsx'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { UserEmail } from './drawer-menu/UserEmail'
import RoleBasedMenu from './drawer-menu/RoleBasedMenu'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: 'hidden',
  },
  subContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
}))

export const DrawerMenu = (props) => {
  const classes = useStyles()
  const {
    values: { openSwipeable },
    setters: { close },
  } = useMerchantHomeContext()
  return (
    <Drawer
      className={clsx('column', classes.drawer)}
      variant={isMobile ? null : 'persistent'}
      anchor='left'
      open={openSwipeable}
      onClose={(event, reason) => {
        reason === 'backdropClick' && close()
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <DrawerHeader />
      <Divider />
      <Selections />
      <Divider />
      <div className={classes.subContainer}>
        <RoleBasedMenu />
        <div className='grow' />
        <UserEmail />
      </div>
    </Drawer>
  )
}

export default DrawerMenu
