import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import { Backdrop, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import { addEmployeeIsFocused, addEmployeeIsInvalid, generalConstants } from '../../../constants'
import clsx from 'clsx'
import { emailRegex } from '../../utils/regex_collections'
import { useDispatch } from 'react-redux'
import { merchantService, customerService } from '../../../services'
import { alertActions } from '../../../actions'
import TextInput from './add-employee/TextInput'
import ActionButtons from './add-employee/ActionButtons'
import { editEmployeeButtonStatus } from '../../../helpers/merchant/button-status'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { useToggle } from '../../../hooks/useToggle'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  label: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#121212',
    margin: theme.spacing(),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const EditEmployee = ({ intl, history }) => {
  const classes = useStyles()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()

  //Local states
  const [user, setUser] = useState({})
  const [isFocused, setIsFocused] = useState(addEmployeeIsFocused)
  const [isInvalid, setIsInvalid] = useState(addEmployeeIsInvalid)
  const [loading, setLoading] = useToggle()
  const [discard, setDiscard] = useState(0)
  const [benchmark, setBenchmark] = useState()

  const dispatch = useDispatch()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discard])

  const getUserInfo = async () => {
    setLoading()
    const response = await customerService.getUser()
    if (response.status === generalConstants.STATUS_OK) {
      const newState = {
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
      }
      setBenchmark(response.data)
      setUser({ ...user, ...newState })
    }
    setLoading()
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setLoading()
    let data = {}
    if (user.firstName !== benchmark.firstName) data['firstName'] = user.firstName
    if (user.lastName !== benchmark.lastName) data['lastName'] = user.lastName
    if (user.email !== benchmark.email) data['email'] = user.email
    await merchantService
      .updateUser(data)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setLoading()
          history.push(`${path}/merchant/employees`)
        }
      })
      .catch((err) => {
        dispatch(alertActions.error(err.response.data.message.toString()))
        setLoading()
      })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const invalidCondition = name === 'email' ? !emailRegex.test(value) : !value
    setIsFocused({ ...isFocused, [name]: true })
    setIsInvalid({ ...isInvalid, [name]: invalidCondition })
    setUser({ ...user, [name]: value })
  }

  // const handleRoleChange = (e) => {
  //   setUser({ ...user, role: e.target.name })
  // }

  const discardChanges = () => {
    setDiscard((prev) => prev + 1)
  }

  const disableCondition = useMemo(() => {
    editEmployeeButtonStatus(user, benchmark)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <form onSubmit={onFormSubmit}>
        <div className='page-header' style={{ marginTop: 70, width: '100%' }}>
          <Typography variant='h4'>{getLabel('update-employee')}</Typography>
        </div>
        <Grid container spacing={2} className='full-width'>
          <Grid item sm={12}>
            <Typography className={classes.label}>{getLabel('employee-basic-info')}</Typography>
          </Grid>
          <TextInput propss={{ isFocused, isInvalid, user, handleChange, field: 'firstName' }} />
          <TextInput propss={{ isFocused, isInvalid, user, handleChange, field: 'lastName' }} />
          <TextInput propss={{ isFocused, isInvalid, user, handleChange, field: 'email' }} />
        </Grid>
        {/* <AssignRole user={user} handleRoleChange={handleRoleChange} disabled /> */}
        <ActionButtons user={user} text='update-label' discard={discardChanges} update disable={disableCondition} />
      </form>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

EditEmployee.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(injectIntl(EditEmployee))
