import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import ReactLoading from 'react-loading'
import { Grid } from '@material-ui/core'

export const Loading = ({ type, color, open }) => {
  return (
    <Dialog
      fullScreen
      fullWidth
      open={open}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <Grid
        container
        alignItems='center'
        direction='column'
        style={{ height: '100%', width: '100%', justifyContent: 'center' }}
      >
        <ReactLoading type='spinningBubbles' color='#69e781' height={80} width={80} />
      </Grid>
    </Dialog>
  )
}

export default Loading
