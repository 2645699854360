import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { ButtonBase, makeStyles, Typography } from '@material-ui/core'
import cartIcon from '../../../../images/black-cart.svg'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  cart: {
    textTransform: 'capitalize',
    color: '#000',
    zIndex: 100,
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    borderRadius: 10,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 6,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  cartText: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    color: '#000',
  },
  cartContainer: {
    width: '100%',
    marginBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    backgroundColor: 'transparent',
  },
}))

const ViewCart = ({ intl, viewCartHandler, totalPrice }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <div className={clsx('sticky-bottom-card', classes.cartContainer)}>
      <ButtonBase className='full-width' onClick={viewCartHandler}>
        <div className={classes.cart}>
          <img src={cartIcon} alt='cart' width='24' height='20' />
          <Typography className={classes.cartText}>{getLabel('view-cart')}</Typography>
          <Typography className={classes.cartText}>{`$${totalPrice}`}</Typography>
        </div>
      </ButtonBase>
    </div>
  )
}

ViewCart.propTypes = {
  viewCartHandler: PropTypes.func,
  totalPrice: PropTypes.number,
  intl: PropTypes.object,
}

export default injectIntl(ViewCart)
