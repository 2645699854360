/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { searchActions } from '../../../../actions'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import close from '../../../../images/close.svg'
import { LeftLabelButton, GreenButton } from '../../../utils/tools'
import { sortBy } from '../../../../constants'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
    color: '#b8bbc6',
    fontSize: 16,
  },
  sortButton: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.56,
    letterSpacing: -0.39,
    textAlign: 'left',
    color: '#c4c4c4',
    textTransform: 'capitalize',
    height: 40,
  },
  sortRow: {
    borderBottom: '1px solid #f7f7f7',
  },
  selected: {
    color: '#000',
    fontWeight: 600,
  },
  view: {
    paddingTop: theme.spacing(2),
  },
})

class SortBy extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      srt: '',
      order: '',
    }
  }

  componentDidMount() {
    this.setState({
      srt: this.props.orderByKey,
      order: this.props.orderByValue,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { orderByKey, orderByValue } = this.props
    if (prevProps.orderByKey !== orderByKey || prevProps.orderByValue !== orderByValue) {
      this.setState({
        srt: this.props.orderByKey,
        order: this.props.orderByValue,
      })
    }
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleSortByChange = () => {
    const { setSearchSortBy, handleClose, resetItems } = this.props
    setSearchSortBy(this.state.srt, this.state.order)
    handleClose('sortBy')
    resetItems()
  }

  handleSortByClose = () => {
    this.setState({ srt: this.props.orderByKey, order: this.props.orderByValue })
    this.props.handleClose('sortBy')
  }

  render() {
    const { classes } = this.props
    const { srt, order } = this.state

    return (
      <Grid className={classes.root} container>
        <Grid item xs={12} className='flex-container flex-end'>
          <IconButton onClick={this.handleSortByClose} size='small'>
            <img src={close} height='22' width='22' alt='close' />
          </IconButton>
        </Grid>
        <Grid item xs={12} className='flex-container flex-center'>
          <Typography align='center' variant='h3'>
            {this.getLabel('sort-by')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selected]: srt === sortBy.NAME && order === sortBy.ASC,
            })}
            onClick={() => this.setState({ srt: sortBy.NAME, order: sortBy.ASC })}
          >
            {this.getLabel('name-label')}
          </LeftLabelButton>
        </Grid>

        <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selected]: srt === sortBy.TOP_RATED && order === sortBy.DESC,
            })}
            onClick={() => this.setState({ srt: sortBy.TOP_RATED, order: sortBy.DESC })}
          >
            {this.getLabel('top-rated')}
          </LeftLabelButton>
        </Grid>
        {/* <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selected]: srt == sortBy.NEAREST,
            })}
            onClick={() => this.setState({ srt: sortBy.NEAREST })}
          >
            {this.getLabel('nearest-me')}
          </LeftLabelButton>
        </Grid> */}
        <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selected]: srt === sortBy.COST_HIGH_TO_LOW && order === sortBy.DESC,
            })}
            onClick={() => this.setState({ srt: sortBy.COST_HIGH_TO_LOW, order: sortBy.DESC })}
          >
            {this.getLabel('high-low-cost')}
          </LeftLabelButton>
        </Grid>
        <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selected]: srt === sortBy.COST_LOW_TO_HIGH && order === sortBy.ASC,
            })}
            onClick={() => this.setState({ srt: sortBy.COST_LOW_TO_HIGH, order: sortBy.ASC })}
          >
            {this.getLabel('low-high-cost')}
          </LeftLabelButton>
        </Grid>
        {/* <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selected]: srt == sortBy.MOST_POPULAR,
            })}
            onClick={() => this.setState({ srt: sortBy.MOST_POPULAR })}
          >
            {this.getLabel('most-popular')}
          </LeftLabelButton>
        </Grid> */}
        <Grid item xs={12} className={classes.view}>
          <GreenButton fullWidth onClick={this.handleSortByChange}>
            {this.getLabel('view-label')}
          </GreenButton>
        </Grid>
      </Grid>
    )
  }
}

SortBy.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const { search } = state
  const { orderByKey } = search.searchQuery
  const { orderByValue } = search.searchQuery

  return {
    orderByKey,
    orderByValue,
  }
}

const actionCreators = {
  setSearchSortBy: searchActions.setSearchSortByOrder,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(SortBy))))
