import React from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import whenAdornment from '../../../../images/when_adornment.svg'
import arrowDown from '../../../../images/arrow-down.svg'
import { deliveryTimes } from '../../../../constants'

const useStyles = makeStyles(() => ({
  whenInput: {
    minWidth: 110,
    fontSize: 16,
    color: '#707070',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#fff',
    },
    "&[located-in='navbar']": {
      minWidth: 80,
    },
  },
}))

const ArrowDown = () => (
  <img src={arrowDown} alt='down' width={9.4} height={6.1} style={{ marginRight: 8.5, marginLeft: 8.5 }} />
)

const WhenButton = ({ handleWhenClick, searchQuery, navbar }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <Button
      className={classes.whenInput}
      located-in={navbar ? 'navbar' : 'banner'}
      onClick={handleWhenClick}
      startIcon={
        navbar ? (
          <img src={whenAdornment} alt='whenAdornment' height='23px' width='23px' />
        ) : (
          <img src={whenAdornment} alt='whenAdornment' width='25px' height='25px' style={{ marginBottom: 3 }} />
        )
      }
      endIcon={<ArrowDown />}
      disableRipple
    >
      {searchQuery.deliveryTime === deliveryTimes.ASAP
        ? f({ id: 'asap-label' })
        : moment(searchQuery.deliveryTimeDetail).format('MMM Do, h:mm a')}
    </Button>
  )
}

WhenButton.propTypes = {
  handleWhenClick: PropTypes.func,
  searchQuery: PropTypes.object,
  navbar: PropTypes.bool,
}

export default WhenButton
