export const styles = (theme) => ({
  text: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'justify',
    "&[title-text='true']": {
      fontSize: 18,
      fontWeight: 600,
      margin: `${theme.spacing(2)}px 0px`,
    },
    "&[subtitle-text='true']": {
      fontWeight: 500,
    },
  },
  addressText: {
    margin: `${theme.spacing()}px 0px`,
    fontSize: 16,
    fontWeight: 400,
    "&[is-last='true']": {
      marginBottom: theme.spacing(3),
    },
  },
})
