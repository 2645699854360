import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { BlackButton, GreenButton } from '../../../utils/tools'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  actionRow: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    direction: 'rtl',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    minWidth: 120,
    marginRight: theme.spacing(3),
  },
}))

const ActionButtons = ({ menu, intl, history }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <div className={classes.actionRow}>
      <GreenButton
        className={classes.button}
        type='submit'
        disabled={menu.name === '' || menu.description === '' || menu.price === ''}
      >
        {getLabel('save-label')}
      </GreenButton>
      <BlackButton className={classes.button} onClick={() => history.push('/merchant/menus')}>
        {getLabel('discard-label')}
      </BlackButton>
    </div>
  )
}

ActionButtons.propTypes = {
  menu: PropTypes.object,
  intl: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(injectIntl(ActionButtons))
