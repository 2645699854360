import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import MerchantNoResult from '../../common/MerchantNoResult'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

const NoResult = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MerchantNoResult text='no-attribute' />
    </div>
  )
}

const AttributeList = ({ attributes, deleteDialog, valueDialog, editAttribute }) => {
  const classes = useStyles()
  if (attributes?.length === 0) return <NoResult />
  return (
    <div className={classes.demo}>
      <List>
        {attributes?.map((attribute, i) => (
          <React.Fragment key={i}>
            <ListItem key={i}>
              <ListItemText primary={attribute.name} secondary={attribute.required ? 'Required' : 'Optional'} />
              <ListItemSecondaryAction>
                <Tooltip title='Add Attribute Values'>
                  <IconButton aria-label='menu' onClick={() => valueDialog(attribute)}>
                    <ListIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Edit Attribute'>
                  <IconButton aria-label='edit' onClick={() => editAttribute(attribute)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Delete Attribute'>
                  <IconButton aria-label='delete' onClick={() => deleteDialog(attribute)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </div>
  )
}

AttributeList.propTypes = {
  attributes: PropTypes.array,
  deleteDialog: PropTypes.func,
  valueDialog: PropTypes.func,
  editAttribute: PropTypes.func,
}

export default injectIntl(AttributeList)
