import React from 'react'
import PropTypes from 'prop-types'
import Footer from '../../Footers'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TopNavbar from '../../top_navbar'
import NoResult from '../../search/no_result'
import WebOrdersMain from './desktop-orders/WebOrdersMain'
import { FormattedMessage } from 'react-intl'
import { a11yPropsVertical } from '../../../../helpers/orders/a11y'
import clsx from 'clsx'
import useStyles from './styles'
import { isTablet } from 'react-device-detect'

const DesktopOrders = ({
  loading,
  verticalTabValue,
  upcomingOrders,
  previousOrders,
  handleVerticalTabChange,
  handleOrderRating,
  getUserOrders,
  handleOrderReceipt,
  openCancelDialog,
  storeDetails,
}) => {
  const classes = useStyles({ isTablet })
  const ordersOnPage = verticalTabValue === 0 ? upcomingOrders : previousOrders

  return (
    <div className={clsx('grow desktop full-width', [classes.desktopRoot])}>
      <Container maxWidth='lg'>
        <TopNavbar cartVisible={true} />
      </Container>
      <div className={classes.headerContainer}>
        <Container maxWidth='lg'>
          <Typography className={classes.headerText}>
            <FormattedMessage id='orders-label' />
          </Typography>
        </Container>
      </div>
      <div className={classes.contentContainer}>
        <Container maxWidth='lg' className={clsx('desktop', [classes.full])}>
          <div className={classes.tabContainer}>
            <div className={classes.rootVerticalTab}>
              <Tabs
                orientation='vertical'
                indicatorColor='primary'
                value={verticalTabValue}
                onChange={handleVerticalTabChange}
                aria-label='Vertical tabs'
                className={classes.verticalTabsContainer}
              >
                <Tab
                  className={classes.verticalTabs}
                  label={
                    <Typography className={classes.tabLabels}>
                      <FormattedMessage id='upcoming-label' />
                    </Typography>
                  }
                  {...a11yPropsVertical(0)}
                />
                <Tab
                  className={classes.verticalTabs}
                  label={
                    <Typography className={classes.tabLabels}>
                      <FormattedMessage id='previous-label' />
                    </Typography>
                  }
                  {...a11yPropsVertical(1)}
                />
              </Tabs>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            {ordersOnPage?.length > 0
              ? ordersOnPage?.map((order, index) => (
                  <WebOrdersMain
                    order={order}
                    key={index}
                    previous={verticalTabValue === 1}
                    rate={handleOrderRating}
                    getOrders={getUserOrders}
                    handleOrderReceipt={handleOrderReceipt}
                    openCancelDialog={openCancelDialog}
                    storeDetails={storeDetails}
                  />
                ))
              : !loading && (
                  <div className={classes.noResultContainer}>
                    <NoResult label='orders' />
                  </div>
                )}
          </div>
        </Container>
      </div>
      <div className='grow' />
      <Footer />
    </div>
  )
}

DesktopOrders.propTypes = {
  loading: PropTypes.bool,
  verticalTabValue: PropTypes.number,
  upcomingOrders: PropTypes.array,
  previousOrders: PropTypes.array,
  handleVerticalTabChange: PropTypes.func,
  handleOrderRating: PropTypes.func,
  handleOrderReceipt: PropTypes.func,
  getUserOrders: PropTypes.func,
}

export default DesktopOrders
