import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CartItem from '../cartItem'
import SelectedMenu from '../WebCart/SelectedMenu'
import { useCurrentWidth } from '../../../../hooks/useCurrentWidth'
import WarnBox from './WarnBox'
import GreenSelect from '../../../common/GreenSelect'
import { deliveryTypes } from '../../../../constants'
import WebPromo from '../WebPromo'
import { checkDeliveryDiscount, checkSubtotalDiscount } from '../../../../helpers/promotion/delivery-discount'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  selectContainerGrid: {
    paddingTop: 10,
    paddingBottom: 4,
    paddingRight: 0,
    paddingLeft: 0,
  },
  selectContainer: {
    height: 420,
    overflow: 'auto',
    flexGrow: 1,
    marginBottom: 15,
    display: 'block',
    marginLeft: 0,
    paddingLeft: 0,
    marginRight: 0,
    paddingRight: 0,
    "&[content-visible='visible']": {
      height: 290,
    },
    "&[content-payment='show']": {
      height: 290,
    },
    "&[payment-error='error']": {
      height: 290,
    },
  },
  selectContainerTablet: {
    height: 'auto',
    minHeight: 200,
    maxHeight: 420,
    overflow: 'auto',
    marginBottom: 20,
    marginLeft: 0,
    paddingLeft: 0,
    marginRight: 0,
    paddingRight: 0,
  },
  selectGrid: {
    width: '100%',
    minHeight: 40,
    borderBottom: '1px solid #f4f4f4',
    marginTop: 5,
    paddingLeft: 0,
    display: 'flex',
    height: 'auto',
    "&[tablet-view='true']": {
      marginBottom: 20,
    },
  },
  select: {
    borderRadius: 10,
    marginTop: 5,
    objectFit: 'contain',
    border: 'solid 1px #69e781',
    fontSize: 16,
    textAlign: 'right',
    fontFamily: 'Roboto',
    width: 55,
    height: 30,
    color: 'white',
    backgroundColor: '#69e781',
    paddingLeft: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    '&:focus': {
      borderRadius: 10,
      background: '#69e781',
      borderColor: '#69e781',
    },
  },
  orderMenuGrid: {
    paddingTop: 4,
    paddingLeft: 6,
    minHeight: 55,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: '240px',
  },
  orderMenuPrice: {
    padding: 4,
    fontWeight: 'bold',
    fontSize: 16,
  },
  commissionText: {
    padding: 0,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
    width: '100%',
    fontSize: 15,
    color: '#0d0d0d',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.56,
    letterSpacing: 'normal',
  },
  cartItem: {
    borderBottom: '1px solid #f4f4f4',
  },
  paper: {
    borderRadius: 12,
    width: 55,
    marginTop: 5,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  icon: {
    color: 'white',
    top: 15,
    right: 0,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  list: {
    maxHeight: 110,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
    '& li': {
      paddingTop: 15,
      paddingBottom: 12,
      color: '#707070',
    },
  },
  warnBox: {
    width: '100%',
    height: 'auto',
    maxHeight: '100',
    backgroundColor: 'rgb(253,236,234)',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: '#707070',
    padding: '10px 15px 10px 15px',
    visibility: 'hidden',
    marginBottom: theme.spacing(),
    "&[content-visible='visible']": {
      visibility: 'visible',
    },
    "&[content-payment='show']": {
      visibility: 'visible',
    },
    "&[payment-error='error']": {
      visibility: 'visible',
    },
    "&[tablet-view='true']": {
      justifyContent: 'center',
    },
  },
  warnContainer: {
    height: 130,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 0,
    "&[tablet-view='true']": {
      height: 70,
    },
  },
  box: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
}))

const Content = ({
  cart,
  handleQuantity,
  deleteMenuItem,
  itemCount,
  errorMessage,
  tabletView,
  warnActive,
  paymentError,
  paymentMethodWarning,
  showWarnBoxContainer,
  loading,
  tip,
}) => {
  const classes = useStyles()
  const currentWidth = useCurrentWidth()

  let totalPrice_ = cart?.totalPrice + +tip?.value

  return (
    <Grid item xs={12} className={classes.selectContainerGrid}>
      <Container
        className={currentWidth < 960 ? classes.selectContainerTablet : classes.selectContainer}
        content-visible={warnActive}
        content-payment={paymentMethodWarning}
        payment-error={paymentError}
      >
        {/* <Scrollbars style={{ height: '100%' }} autoHeight={currentWidth < 960}> */}
        {cart.orderMenus.map((om, index) => (
          <div key={index} className={classes.selectGrid} tablet-view={tabletView ? 'true' : 'false'}>
            <div
              style={{
                width: '10%',
                marginRight: tabletView ? (currentWidth < 700 ? 10 : -5) : !tabletView && currentWidth < 1200 ? 30 : 25,
              }}
            >
              <GreenSelect loading={loading} menu={om} index={index} handleQuantityChange={handleQuantity} />
            </div>
            <div style={{ width: '90%' }}>
              <SelectedMenu menu={om} index={index} deleteMenuItem={deleteMenuItem} />
            </div>
            {/* <Grid item xs={9} sm container className={classes.orderMenuGrid}>
                <Typography style={{ fontSize: 16 }} gutterBottom>
                  {om.name}
                </Typography>
                {om.specialInstructions && (
                  <Box fontSize='14px' component='div' color='#707070' classes={{ root: classes.box }}>
                    {om.specialInstructions}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography gutterBottom className={classes.orderMenuPrice}>
                  ${(om.price * om.quantity).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label='delete' size='small' onClick={() => deleteMenuItem(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid> */}
          </div>
        ))}
        <div className={classes.cartItem}>
          {!!cart?.promotion && !!cart?.discountPrice && <WebPromo promotion={cart?.promotion} />}
          <CartItem
            title={`Subtotal - ${itemCount} item${itemCount > 1 ? 's' : ''}`}
            price={(+cart?.finalSubtotalPrice)?.toFixed(2)}
            oldPrice={checkSubtotalDiscount(cart)}
          />
          <CartItem title='Tax' price={cart?.tax?.toFixed(2)} />
          {cart?.type === deliveryTypes.DELIVERY && <CartItem title='Tip' price={(+tip?.value).toFixed(2)} />}
          {cart?.type === deliveryTypes.DELIVERY && (
            <CartItem
              title='Delivery Fee'
              price={cart?.deliveryFee?.toFixed(2)}
              oldPrice={checkDeliveryDiscount(cart)}
            />
          )}
          <CartItem title='Total' price={totalPrice_?.toFixed(2)} total />
        </div>
        {/* <Grid container direction='row'>
          <Grid item xs={12}>
            <Typography className={classes.commissionText}>
              <FormattedMessage id='commission-text' />
            </Typography>
          </Grid>
        </Grid> */}
        <div className='grow' />
        {/* </Scrollbars> */}
      </Container>
      {showWarnBoxContainer && !tabletView && !loading && (
        <WarnBox
          tabletView={tabletView}
          warnActive={warnActive}
          paymentMethodWarning={paymentMethodWarning}
          errorMessage={errorMessage}
          paymentError={paymentError}
        />
      )}
    </Grid>
  )
}

export default Content
