import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import TopNavbar from '../../top_navbar'
import plates from '../../../../images/plates.png'
import deliveryPickup from '../../../../images/delivery-pickup.png'
import AddressPopup from '../../../common/addressPopup/addressPopup'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Footer from '../../Footers'
import clsx from 'clsx'
import { isTablet } from 'react-device-detect'
import { useToggle } from '../../../../hooks/useToggle'
import StoreItem from '../../search/store_item'
import BeMemberDialog from '../../../merchant/authentication/BeMemberDialog'
// BeCustomer will be used later. Do not delete
// import BeCustomer from '../../../common/BeCustomer'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  addressBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addressColumn: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 578,
    },
  },
  favorite: {
    fontSize: 80,
    [theme.breakpoints.down('md')]: {
      fontSize: 50,
    },
    [theme.breakpoints.down('xs')]: {
      color: '#fff',
    },
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
  },
  inputMain: {
    minWidth: 400,
    maxWidth: 400,
    position: 'relative',
    zIndex: 100,
  },
  inputContainer: {
    maxWidth: 400,
    minWidth: 400,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderTopLeftRadius: 5,
    flexGrow: 1,
    borderBottomLeftRadius: 5,
    backgroundColor: '#fff',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    height: 50,
  },
  adornment: {
    minWidth: 50,
    maxWidth: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  platesBanner: {
    position: 'relative',
    height: 500,
    [theme.breakpoints.up('lg')]: {
      height: 700,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  plates: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${plates})`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  title: {
    fontSize: isTablet ? 35 : 50,
    fontWeight: 'bold',
    lineHeight: 1.32,
    color: '#000000',
    marginBottom: theme.spacing(2.5),
  },
  subTitle: {
    fontSize: isTablet ? 30 : 40,
    fontWeight: 'bold',
    lineHeight: 1.32,
    color: '#000000',
    marginBottom: theme.spacing(2.5),
  },
  local: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },
  pickupDelivery: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(13.5),
    },
  },
  pickDeliveryDesc: {
    fontSize: 16,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.45,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'rgba(51, 51, 51, 0.7)',
  },
  deliveryImage: {
    marginTop: theme.spacing(5),
    backgroundImage: `url(${deliveryPickup})`,
    position: 'relative',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    minHeight: 300,
  },
  footer: {
    minWidth: '100%',
  },
}))

const DesktopPage = ({ intl, locals, openStores }) => {
  const classes = useStyles()
  const history = useHistory()

  const [showBeMemberDialog, setShowBeMemberDialog] = useToggle()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <TopNavbar cartVisible={false} />
        <Grid container className='full-width'>
          <Grid item xs={12} md={6} lg={5} className={classes.addressBanner}>
            <div className={classes.addressColumn}>
              <Typography className={classes.favorite}>{getLabel('favorable-restaurants')}</Typography>
              <div className={clsx('centered-row', classes.inputMain)}>
                <div className={classes.inputContainer}>
                  <AddressPopup welcomePage />
                </div>
                <div className={classes.adornment}>
                  <LocationOnIcon />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={7} className={classes.platesBanner}>
            <div className={classes.plates} />
          </Grid>
        </Grid>
        {locals?.length > 0 && (
          <Grid container className='full-width' style={{ minHeight: 660 }}>
            <Grid item xs={12} className={classes.local}>
              <Typography className={classes.title}>{getLabel('local-favorites')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {locals.map((local) => (
                  <Grid item sm={6} md={4} xs={12} key={local.id}>
                    <StoreItem data={local} localFavorite history={history} storeOpen={openStores.includes(local.id)} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* <Grid container className='full-width'>
          <Grid item xs={6} className={classes.pickupDelivery}>
            <div className='column'>
              <Typography className={classes.subTitle}>{getLabel('pickup-delivery')}</Typography>
              <Typography className={classes.pickDeliveryDesc}>{getLabel('pickup-delivery-desc')}</Typography>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.deliveryImage}></Grid>
        </Grid> */}
      </Container>
      <div className={classes.footer}>
        <Footer />
      </div>
      {showBeMemberDialog && <BeMemberDialog isOpen={showBeMemberDialog} handleClose={setShowBeMemberDialog} />}
    </div>
  )
}

DesktopPage.propTypes = {
  locals: PropTypes.array,
  intl: PropTypes.object,
  openStores: PropTypes.array,
}

export default injectIntl(DesktopPage)
