import React from 'react'

import { Typography } from '@material-ui/core'

const Label = ({ data, id, name }) => {
  return (
    <Typography variant='body2' color={data.includes(id) ? 'textPrimary' : 'textSecondary'}>
      {name}
    </Typography>
  )
}

export default Label
