import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Button, Grid, makeStyles } from '@material-ui/core'
import yesIcon from '../../../../../images/yes.svg'
import clsx from 'clsx'
import { deliveryTypes } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  stepTwoRow: {
    borderBottom: '1px solid #f7f7f7',
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  stepTwoIcon: {
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
    marginRight: theme.spacing(2),
  },
  button: {
    fontSize: 15,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(198, 198, 198, 0.87)',
    textTransform: 'capitalize',
  },
  selectedButton: {
    color: 'rgba(112, 112, 112, 0.87)',
  },
}))

const DeliveryTypeList = ({ intl, item, details, callbacks }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item xs={12} className={classes.stepTwoRow}>
      <img
        className={classes.stepTwoIcon}
        src={details.deliveryType === deliveryTypes[`${item.type}`] ? item.iconSelected : item.icon}
        alt='where'
        height='20'
        width='20'
      />
      <div className='centered-row full-width' onClick={callbacks[`${item.callback}`]}>
        <Button
          className={clsx({
            [classes.button]: true,
            [classes.selectedButton]: details.deliveryType === deliveryTypes[`${item.type}`],
          })}
          style={{ textAlign: 'left' }}
          onClick={callbacks[`${item.callback}`]}
        >
          {getLabel(`${item.label}`)}
        </Button>
        <div className='grow' />
        {details.deliveryType === deliveryTypes[`${item.type}`] && (
          <img className={classes.stepTwoIcon} src={yesIcon} alt='where' height='16' width='16' />
        )}
      </div>
    </Grid>
  )
}

DeliveryTypeList.propTypes = {
  item: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  callbacks: PropTypes.object.isRequired,
}

export default injectIntl(DeliveryTypeList)
