import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const PaymentContactName = ({ bankInfo, isFocused, isInvalid, contactNameHandler, editting }) => {
  const { formatMessage: f } = useIntl()

  return (
    <Grid item sm={12} xs={12} lg={6}>
      <TextField
        size='small'
        variant='outlined'
        required
        fullWidth
        id='paymentContactName'
        label={f({ id: 'fullname-label' })}
        value={bankInfo.paymentContactName || ''}
        InputLabelProps={{ shrink: true }}
        onChange={contactNameHandler}
        inputProps={{
          maxLength: 150,
          readOnly: !editting,
        }}
        error={isFocused.paymentContactName && isInvalid.paymentContactName}
        helperText={isFocused.paymentContactName && isInvalid.paymentContactName ? f({ id: 'enter-fullname' }) : ' '}
      />
    </Grid>
  )
}

PaymentContactName.propTypes = {
  bankInfo: PropTypes.object,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  contactNameHandler: PropTypes.func.isRequired,
  editting: PropTypes.bool,
}

export default PaymentContactName
