import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { attributeService } from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { generalConstants } from '../../../constants'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import { useToggle } from '../../../hooks/useToggle'
import EditAttributeList from './edit-attributes/EditAttributeList'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import clsx from 'clsx'
import queryString from 'query-string'
import { withRouter } from 'react-router'
import { alertActions } from '../../../actions'
import Confirmation from '../common/Confirmation'
import { BlackButton } from '../../utils/tools'
import AttributeListDialog from './edit-attributes/AttributeListDialog'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  root: {
    marginTop: theme.spacing(2),
  },
}))

const EditAttributes = ({ intl, location }) => {
  const classes = useStyles()
  const value = useMemo(() => queryString.parse(location.search), [location.search])

  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()

  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)

  const [attributes, setAttributes] = useState()
  const [menuAttributes, setMenuAttributes] = useState()
  const [loading, setLoading] = useToggle()
  const [isChanged, setIsChanged] = useState(0)
  const [showConfirm, setShowConfirm] = useToggle()
  const [itemToDetach, setItemToDetach] = useState({})
  const [showAttributeDialog, setShowAttributeDialog] = useToggle()

  const dispatch = useDispatch()

  useEffect(() => {
    getAttributesOfMenu()
    getStoreAttributes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isChanged && getAttributesOfMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChanged])

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const getAttributesOfMenu = async () => {
    try {
      setLoading()
      const response = await attributeService.menuAttributes(selectedStore?.storeId, value.menuid)
      if (response.status === generalConstants.STATUS_OK) {
        setMenuAttributes(response.data.content)
      }
    } catch (e) {
      // console.log('getAttributesOfMenu -> e', e)
    } finally {
      setLoading()
    }
  }

  const getStoreAttributes = async () => {
    try {
      setLoading()
      const response = await attributeService.getAllAttributes(selectedStore?.storeId)
      if (response.status === generalConstants.STATUS_OK) {
        setAttributes(response.data.content)
      }
    } catch (e) {
      // console.log('getStoreAttributes -> e', e)
    } finally {
      setLoading()
    }
  }

  const handleAttach = async (id) => {
    setLoading()
    const response = await attributeService.attachToMenu(selectedStore?.storeId, value.menuid, id)
    if (response.status === generalConstants.STATUS_OK) {
      setIsChanged((prev) => prev + 1)
      dispatch(alertActions.success(getLabel('attach-success')))
    }
    setLoading()
  }

  const handleDetach = async () => {
    try {
      setLoading()
      const response = await attributeService.detachFromMenu(selectedStore?.storeId, value.menuid, itemToDetach.id)
      if (response.status === generalConstants.STATUS_OK) {
        setIsChanged((prev) => prev + 1)
        setShowConfirm()
        dispatch(alertActions.success(getLabel('detach-success')))
      }
    } catch (e) {
      // console.log('EditAttributes -> e', e)
    } finally {
      setLoading()
    }
  }

  const handleConfirm = (item) => {
    setItemToDetach(item)
    setShowConfirm()
  }

  const handleCloseConfirm = () => {
    setShowConfirm()
    setItemToDetach()
  }

  return (
    <div
      className={clsx('grow', classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <div style={{ marginTop: 70 }}>
        <div className='row'>
          <div className='grow' />
          <BlackButton onClick={setShowAttributeDialog}>{getLabel('add-new')}</BlackButton>
        </div>
        <EditAttributeList menuAttributes={menuAttributes} handleConfirm={handleConfirm} />
      </div>
      <Confirmation
        open={showConfirm}
        close={handleCloseConfirm}
        callback={handleDetach}
        title='are-you-sure'
        item={itemToDetach}
        text='remove-attribute'
        rightButton='detach-label'
      />
      <AttributeListDialog
        isOpen={showAttributeDialog}
        closeModal={setShowAttributeDialog}
        attributes={attributes}
        menuAttributes={menuAttributes}
        handleAttach={handleAttach}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

EditAttributes.propTypes = {
  intl: PropTypes.object,
}

export default withRouter(injectIntl(EditAttributes))
