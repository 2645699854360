import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'

import { Transition, GreenButton } from '../../utils/tools'
import { parseGoogleAPIAddress } from '../../utils/common'
import { deliveryTypes, deliverySubTypes, initialAddressQuery } from '../../../constants'

import InputArea from '../account/address/WebAddresses/AddAddressDialog/InputArea'
import DeliveryPart from '../account/address/WebAddresses/AddAddressDialog/DeliveryPart'

import { orderActions, searchActions } from '../../../actions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '638px',
    height: 'auto',
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: isMobileOnly ? 'hidden !important' : 'auto',
  },
  cdHeaderContainer: {
    width: '100%',
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  cdHeader: {
    width: '100%',
    height: 45,
    fontSize: 34,
    fontWeight: 'bold',
    lineHeight: 1.18,
    textAlign: 'center',
    color: '#333333',
  },
}))

const StoreAddressSelection = ({ open, onClose }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const dispatch = useDispatch()
  const setUserAddress = (address) => dispatch(searchActions.setAddress(address))
  const setUserDeliveryType = (type) => dispatch(searchActions.setDeliveryType(type))
  const setUserDeliverySubTypes = (type) => dispatch(orderActions.setDeliverySubTypes(type))
  const setUserInstructions = (inst) => dispatch(orderActions.setInstructions(inst))

  const [addressSelected, setAddressSelected] = useState(false)
  const [active, setActive] = useState('false')
  const [newAddress, setNewAddress] = useState(initialAddressQuery)
  const [details, setDetails] = useState({
    deliveryType: deliveryTypes.DELIVERY,
    deliverySubType: deliverySubTypes.DELIVER_DOOR,
  })

  useEffect(() => {
    let active = true
    if (active) {
      if (details.deliveryType === deliveryTypes.TAKEOUT) {
        setNewAddress({
          ...newAddress,
          deliveryInstruction: '',
        })
      }
    }
    return () => {
      active = false
    }
  }, [details])

  const addressChangeListener = (currentAddress) => {
    currentAddress.length > 0 ? setAddressSelected(true) : setAddressSelected(false)
  }

  const handleBorderColor = () => setActive('true')
  const handleBorderColorBack = () => setActive('false')

  const handleSwitch = (e) => {
    if (e.target.checked) {
      setDetails({
        deliveryType: deliveryTypes.TAKEOUT,
        deliverySubType: '',
      })
      setDeliveryOption('')
    } else {
      setDetails({
        deliverySubType: deliverySubTypes.DELIVER_DOOR,
        deliveryType: deliveryTypes.DELIVERY,
      })
      setDeliveryOption(deliverySubTypes.DELIVER_DOOR)
    }
  }

  const saveAddress = () => {
    setUserAddress(newAddress)
    setUserDeliveryType(details.deliveryType)
    setUserDeliverySubTypes(newAddress.deliverySubType)
    setUserInstructions(newAddress.instruction)
    onClose()
  }

  const handleDeliveryAddress = (address) => {
    let parsedAddress = parseGoogleAPIAddress(address)
    if (parsedAddress) {
      setNewAddress({
        ...newAddress,
        country: parsedAddress.country,
        city: parsedAddress.city,
        state: parsedAddress.state,
        zipCode: parsedAddress.zipCode,
        addressLine1: parsedAddress.addressLine1,
        formattedAddress: parsedAddress.formattedAddress,
        latitude: parsedAddress.latitude,
        longitude: parsedAddress.longitude,
      })
    }
  }

  const setAddressLine2 = (e) => {
    setNewAddress({
      ...newAddress,
      addressLine2: e.target.value,
    })
  }

  const clearAddressLine2 = () => {
    setNewAddress({
      ...newAddress,
      addressLine2: '',
    })
  }

  const setInstruction = (e) => {
    setNewAddress({
      ...newAddress,
      deliveryInstruction: e.target.value,
    })
  }

  const clearInstruction = () => {
    setNewAddress({
      ...newAddress,
      deliveryInstruction: '',
    })
  }

  const setDeliveryOption = (prop) => {
    setNewAddress({
      ...newAddress,
      deliveryOption: prop,
    })
  }

  return (
    <Dialog
      open={open}
      aria-labelledby='add-dialog-title'
      classes={{ paper: classes.root }}
      TransitionComponent={Transition}
    >
      <div className={classes.cdHeaderContainer}>
        <Typography className={classes.cdHeader}>{f({ id: 'select-address' })}</Typography>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
          overflowY: 'scroll',
        }}
      >
        <InputArea
          newAddress={newAddress}
          handleBorderColorBack={handleBorderColorBack}
          handleBorderColor={handleBorderColor}
          active={active}
          addressChangeListener={addressChangeListener}
          handleDeliveryAddress={handleDeliveryAddress}
          setAddressLine2={setAddressLine2}
          clearAddressLine2={clearAddressLine2}
          noName
        />
        <DeliveryPart
          handleSwitch={handleSwitch}
          handleSubType={setDeliveryOption}
          subTyp={newAddress.deliveryOption}
          instruction={newAddress.deliveryInstruction}
          handleInstruction={setInstruction}
          typ={details.deliveryType}
          clearInstruction={clearInstruction}
          newAddress={newAddress}
          setNewAddress={setNewAddress}
          details={details}
          setDetails={setDetails}
        />
        <GreenButton
          variant='contained'
          color='primary'
          type='submit'
          fullWidth
          disabled={!addressSelected}
          onClick={saveAddress}
        >
          Save
        </GreenButton>
      </div>
    </Dialog>
  )
}

StoreAddressSelection.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default StoreAddressSelection
