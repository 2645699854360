import React from 'react'
import PropTypes from 'prop-types'
import { ButtonBase, makeStyles } from '@material-ui/core'
import { getImage } from '../../../../../helpers/get-image'

const useStyles = makeStyles((theme) => ({
  category: {
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: '#e6e6e6',
    width: '100%',
    height: 170,
    '&:hover, &focusVisible': {
      '&imageBackdrop': {
        opacity: 0.15,
      },
      '&imageMarked': {
        opacity: 0,
      },
      '&imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  imageSrc: {
    borderRadius: 10,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  title: {
    position: 'absolute',
    bottom: theme.spacing(2),
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.3,
    letterSpacing: 'normal',
    color: '#fff',
    textShadow: '2px 2px 4px #000000',
  },
}))

const SearchCategory = ({ data, onSelected }) => {
  const classes = useStyles()

  let bgUrl = ''
  if (data.images.length > 0) {
    let image = data.images[0]
    bgUrl = getImage(image, 'thumb')
  }

  return (
    <ButtonBase focusRipple className={classes.category} onClick={onSelected}>
      <div className='centered-column'>
        {bgUrl && (
          <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),url(${bgUrl})`,
            }}
          />
        )}
        <span className={classes.title}>{data.name}</span>
      </div>
    </ButtonBase>
  )
}

SearchCategory.propTypes = {
  data: PropTypes.object,
  onSelected: PropTypes.func,
}

export default SearchCategory
