import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Footer from '../Footers'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import dot from '../../../images/dot.svg'
import Dialog from '@material-ui/core/Dialog'
import VerifyPhone from './verify-phone/verify-phone'
import VerifyEmail from './verify-email/verify-email'
import ChangePassword from './change-password/change-password'
import ChangeName from './change-name/change-name'
import { Transition } from '../../utils/tools'
import backIcon from '../../../images/black-back.svg'
import IconButton from '@material-ui/core/IconButton'
import verifiedIcon from '../../../images/verified.svg'
import notVerifiedIcon from '../../../images/not-verified.svg'
import VisaBlack from '../../../images/visa-black.svg'
import { customerService } from '../../../services/customer.service'
import { generalConstants } from '../../../constants'
import clsx from 'clsx'
import userAvatarIcon from '../../../images/user_avatar.png'
import Loading from '../../common/loading'

const path = process.env.PUBLIC_URL
const height = window.innerHeight

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
  paper: {
    borderRadius: 20,
    padding: theme.spacing(2),
    paddingTop: 40,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: 4,
    paddingRight: 4,
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fdfdfd',
    },
  },
  dot: {
    marginLeft: 6,
  },
  verification: {
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  user: {
    position: 'absolute',
    width: 114,
    height: 114,
    marginTop: -80,
    left: '50%',
    marginLeft: -57,
  },
  verified: {
    marginLeft: theme.spacing(),
  },
  primary: {
    fontSize: 8,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#333333',
  },
  stars: {
    marginRight: 10,
    marginTop: 5,
    fontWeight: 'bold',
  },
  placeholderImage: {
    width: 114,
    height: 114,
    marginBottom: 3,
    marginRight: 3,
  },
  circleStar: {
    position: 'absolute',
    width: 20,
    height: 20,
    marginLeft: '56%',
    marginTop: 10,
    zIndex: 100,
  },
  mobileTopBar: {
    marginBottom: 80,
    borderBottomColor: 'white',
  },
  text: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

class Profile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showVerifyPhoneDialog: false,
      showVerifyEmailDialog: false,
      showChangePasswordDialog: false,
      showChangeNameDialog: false,
      cards: [],
      selectedCard: null,
      loading: false,
    }
  }

  componentDidMount() {
    this.getPaymentMethods()
  }

  getPaymentMethods = () => {
    this.setState({ loading: true })
    customerService.getPaymentMethods().then(
      (res) => {
        res.status === generalConstants.STATUS_OK &&
          this.setState({
            cards: res.data.paymentMethods,
            selectedCard: res.data.paymentMethods.filter((card) => card.id === res.data.defaultPaymentMethod)[0],
            loading: false,
          })
      },
      (err) => {
        console.log('err--', err)
      }
    )
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleVerifyPhoneClose = () => {
    this.setState({ showVerifyPhoneDialog: false })
  }

  handleVerifyEmailClose = () => {
    this.setState({ showVerifyEmailDialog: false })
  }

  handleChangePasswordClose = () => {
    this.setState({ showChangePasswordDialog: false })
  }

  handleChangeNameClose = () => {
    this.setState({ showChangeNameDialog: false })
  }

  render() {
    const { classes, customer, defaultAddress } = this.props
    const {
      showVerifyPhoneDialog,
      showVerifyEmailDialog,
      showChangePasswordDialog,
      showChangeNameDialog,
      selectedCard,
      loading,
    } = this.state
    const stars = '* * * *'
    return (
      <div className={classes.root}>
        <Loading open={loading} />
        <div className='column grow'>
          {/* <div className='desktop'>
            <TopNavbar cartVisible={true} />
          </div> */}
          <div className={clsx('mobile-top-bar', [classes.mobileTopBar])}>
            <IconButton
              size='small'
              className={classes.back}
              onClick={() => this.props.history.push(`${path}/customer/account`)}
            >
              <img src={backIcon} alt='Back' />
            </IconButton>
            <Typography variant='h6'>{this.getLabel('profile-label')}</Typography>
          </div>
          <div className='grow justify-content full-width'>
            <div className='full-width' style={{ paddingTop: 0 }}>
              <div className={classes.user}>
                <img src={userAvatarIcon} height='114' width='114' alt='avatar' />
              </div>
              <Paper className={classes.paper} elevation={6}>
                <div className={classes.row}>
                  <div
                    className='centered-column full-width'
                    onClick={() => this.setState({ showChangeNameDialog: true })}
                  >
                    <Typography variant='h6' gutterBottom>
                      {`${customer.firstName} ${customer.lastName}`}
                    </Typography>
                  </div>
                </div>
                <div className={classes.row} onClick={() => this.setState({ showChangePasswordDialog: true })}>
                  <Typography variant='body1'>{this.getLabel('password-label')}</Typography>
                  <div className='grow' />
                  <div className='row'>
                    <img src={dot} className={classes.dot} height='4' width='4' alt='p' />
                    <img src={dot} className={classes.dot} height='4' width='4' alt='p' />
                    <img src={dot} className={classes.dot} height='4' width='4' alt='p' />
                    <img src={dot} className={classes.dot} height='4' width='4' alt='p' />
                    <img src={dot} className={classes.dot} height='4' width='4' alt='p' />
                    <img src={dot} className={classes.dot} height='4' width='4' alt='p' />
                    <img src={dot} className={classes.dot} height='4' width='4' alt='p' />
                    <img src={dot} className={classes.dot} height='4' width='4' alt='p' />
                  </div>
                </div>
                <Divider className='full-width' light />
                <div className={classes.row} onClick={() => this.setState({ showVerifyEmailDialog: true })}>
                  <Typography variant='body1'>{this.getLabel('email-address')}</Typography>
                  <div className='grow' />
                  <Typography variant='body1' style={{ color: '#c4c4c4' }}>
                    {customer.email}
                  </Typography>
                  {!customer.emailVerified ? (
                    <img src={notVerifiedIcon} className={classes.verified} alt='' height='18' width='18' />
                  ) : (
                    <img src={verifiedIcon} className={classes.verified} alt='' height='18' width='18' />
                  )}
                </div>
                <Divider className='full-width' light />
                <div className={classes.row} onClick={() => this.setState({ showVerifyPhoneDialog: true })}>
                  <Typography variant='body1'>{this.getLabel('phone-label')}</Typography>
                  <div className='grow' />
                  <Typography variant='body1' style={{ color: '#c4c4c4' }}>
                    {customer.phone}
                  </Typography>
                  {!customer.phoneVerified ? (
                    <img src={notVerifiedIcon} className={classes.verified} alt='' height='18' width='18' />
                  ) : (
                    <img src={verifiedIcon} className={classes.verified} alt='' height='18' width='18' />
                  )}
                </div>
                <Divider className='full-width' light />
                <div
                  className={classes.row}
                  onClick={() => this.props.history.push(`${path}/customer/account/payment-methods`)}
                >
                  <div className='column'>
                    <Typography variant='body1'>{this.getLabel('payment-label')}</Typography>
                  </div>
                  <div className='grow' />
                  {selectedCard ? (
                    <div className='row'>
                      <img src={VisaBlack} alt='visa-black' style={{ marginRight: 10 }} />
                      <Typography variant='body1' className={classes.stars}>
                        {stars}
                      </Typography>
                      <Typography variant='body1' className={classes.stars}>
                        {stars}
                      </Typography>
                      <Typography varian='body1' className={classes.stars}>
                        {stars}
                      </Typography>
                      <Typography variant='body1' style={{ marginTop: 2 }}>
                        {selectedCard?.card.last4}
                      </Typography>
                    </div>
                  ) : (
                    <Typography variant='body1'>{this.getLabel('new-payment-method')}</Typography>
                  )}
                </div>
                <Divider className='full-width' light />
                <div
                  className={classes.row}
                  onClick={() => this.props.history.push(`${path}/customer/account/addresses`)}
                >
                  <div className='column'>
                    <Typography variant='body1' gutterBottom>
                      {this.getLabel('address-label')}
                    </Typography>
                  </div>
                  <div className='grow' />
                  {defaultAddress.length > 0 ? (
                    <Typography variant='body1' gutterBottom className={classes.text}>
                      {defaultAddress[0].formattedAddress}
                    </Typography>
                  ) : (
                    <Typography style={{ color: '#c4c4c4' }} variant='body1' gutterBottom>
                      {this.getLabel('add-default-address')}
                    </Typography>
                  )}
                </div>
              </Paper>
            </div>
          </div>
        </div>
        <div className='sticky-bottom'>
          <Footer invisible />
        </div>
        <Dialog
          fullScreen
          open={showVerifyPhoneDialog}
          onClose={this.handleVerifyPhoneClose}
          TransitionComponent={Transition}
        >
          <VerifyPhone handleClose={this.handleVerifyPhoneClose} />
        </Dialog>
        <Dialog
          fullScreen
          open={showVerifyEmailDialog}
          onClose={this.handleVerifyEmailClose}
          TransitionComponent={Transition}
        >
          <VerifyEmail handleClose={this.handleVerifyEmailClose} />
        </Dialog>
        <Dialog
          fullScreen
          open={showChangePasswordDialog}
          onClose={this.handleChangePasswordClose}
          TransitionComponent={Transition}
        >
          <ChangePassword handleClose={this.handleChangePasswordClose} />
        </Dialog>
        <Dialog
          fullScreen
          open={showChangeNameDialog}
          onClose={this.handleChangeNameClose}
          TransitionComponent={Transition}
        >
          <ChangeName handleClose={this.handleChangeNameClose} />
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { customer } = state

  return {
    customer: customer.customerInfo,
    defaultAddress: customer.savedAddresses.filter((sa) => sa.default_ === true),
  }
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, null)(Profile))))
