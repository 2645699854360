import React from 'react'
import Button from '@material-ui/core/Button'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import facebookLogo from '../../images/facebook_logo.svg'
import { FACEBOOK_APP_ID } from '../../constants/general.constants'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { customerActions } from '../../actions'
import { isMobileOnly } from 'react-device-detect'

const useStyles = makeStyles((theme) => ({
  facebookButton: {
    color: '#fff',
    height: 48,
    borderRadius: 10,
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    padding: 8,
    textTransform: 'none',
    width: '100%',
    marginBottom: theme.spacing(),
    backgroundColor: '#3a589b',
    '&:hover': {
      backgroundColor: '#3a589b',
    },
  },
}))

const LoginButton = ({ facebookResponse, classes, label }) => {
  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      fields='name,email,picture'
      // when working on localhost, after successful login,
      // it redirects to this url
      redirectUri='http://tookfresh.com/'
      language='en_US'
      render={(renderProps) => {
        return (
          <Button
            onClick={renderProps.onClick}
            variant='contained'
            className={classes.facebookButton}
            startIcon={<img src={facebookLogo} alt='Facebook' />}
          >
            {label}
          </Button>
        )
      }}
      // xfbml={true}
      isMobile={isMobileOnly}
      callback={facebookResponse}
    />
  )
}

const FacebookAuth = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const social = async (platform, token) => dispatch(customerActions.social(platform, token))

  const facebookResponse = async (response) => {
    const token = response?.accessToken
    token && social('FACEBOOK', token)
  }

  return (
    <div style={{ width: '100%' }}>
      <LoginButton
        facebookResponse={facebookResponse}
        classes={classes}
        label={<FormattedMessage id='continue-facebook' />}
      />
    </div>
  )
}

export default FacebookAuth
