/* eslint-disable no-unused-expressions */
import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { searchActions } from '../../../../actions'
import PropTypes from 'prop-types'
import close from '../../../../images/close.svg'
import { TFSlider, GreenButton } from '../../../utils/tools'
import { distanceMarks } from '../../../../constants'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  slider: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
})

class DistanceFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      distanceRange: null,
    }
  }

  componentDidMount() {
    this.setState({ distanceRange: this.props.distance })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.distance !== this.props.distance) {
      this.setState({ distanceRange: this.props.distance })
    }
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleDistanceRangeChange = (event, newValue) => {
    this.setState({ distanceRange: newValue })
  }

  handleDistanceRangeClose = () => {
    this.setState({ distanceRange: this.props.distance })
    this.props.handleClose('distanceRange')
  }

  applyDistanceChange = () => {
    const { setDistanceFilter, handleClose, resetItems } = this.props
    setDistanceFilter(this.state.distanceRange)
    handleClose('distanceRange')
    resetItems()
  }

  render() {
    const { classes } = this.props
    const { distanceRange } = this.state
    return (
      <Grid className={classes.root} container>
        <Grid item xs={12} className='flex-container flex-end'>
          <IconButton onClick={this.handleDistanceRangeClose} size='small'>
            <img src={close} height='22' width='22' alt='close' />
          </IconButton>
        </Grid>
        <Grid item xs={12} className='flex-container flex-center'>
          <Typography align='center' variant='h3'>
            {this.getLabel('distance-label')}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 16, paddingBottom: 10 }}>
          <Typography align='center' variant='h5'>
            {`${this.getLabel('under-label')} ${distanceRange}mi`}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.slider}>
          <TFSlider
            value={distanceRange}
            aria-labelledby='distance-slider'
            onChange={this.handleDistanceRangeChange}
            step={5}
            min={5}
            max={25}
            marks={distanceMarks}
          />
        </Grid>
        <Grid item xs={12}>
          <GreenButton fullWidth onClick={this.applyDistanceChange}>
            {this.getLabel('view-label')}
          </GreenButton>
        </Grid>
      </Grid>
    )
  }
}

DistanceFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
  resetItems: PropTypes.func,
}

const mapStateToProps = (state) => {
  const { search } = state
  const { distance } = search.searchQuery

  return {
    distance,
  }
}

const actionCreators = {
  setDistanceFilter: searchActions.setDistanceFilter,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(DistanceFilter))))
