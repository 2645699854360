import React from 'react'
import PropTypes from 'prop-types'
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { injectIntl } from 'react-intl'
import trashIcon from '../../../../../images/trash-icon.svg'

const useStyles = makeStyles((theme) => ({
  searchRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 3,
    paddingBottom: 3,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'point',
  },
  content: {
    flexGrow: 1,
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  titleRow: {
    paddingTop: 6,
    paddingBottom: 6,
    borderBottom: '1px solid #f7f7f7',
  },
  recents: {
    paddingTop: 6,
  },
  trashIcon: {
    paddingLeft: 5,
    marginLeft: 5,
  },
}))

const Recents = ({ searchedKeywords, deleteRecents, search, intl }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  if (!searchedKeywords) return null

  return (
    <div className={classes.content}>
      <div className={clsx('centered-row full-width', classes.titleRow)}>
        <Typography variant='subtitle1'>{getLabel('recents-label')}</Typography>
        <IconButton size='small' onClick={deleteRecents} aria-label='delete' className={classes.trashIcon}>
          <img src={trashIcon} height='16' alt='' />
        </IconButton>
      </div>

      <div className={classes.recents}>
        <Grid container>
          {searchedKeywords.map((keyword, index) => (
            <Grid item xs={12} key={index} className={classes.searchRow} onClick={() => search(keyword)}>
              <Typography variant='body1'>{keyword}</Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

Recents.propTypes = {
  keyword: PropTypes.string,
  searchedKeywords: PropTypes.array,
}

export default injectIntl(Recents)
