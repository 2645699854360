import { combineReducers } from 'redux'
import { signup } from './signup.reducer'
import { customer } from './customer.reducer'
import { alert } from './alert.reducer'
import { general } from './general.reducer'
import { merchant } from './merchant.reducer'
import { restaurant } from './restaurant.reducer'
import { stores } from './store.reducer'
import { merchantStores } from './merchant.store.reducer'
import { attributes } from './attribute.reducer'
import { categories } from './category.reducer'
import { dietaries } from './dietary.reducer'
import { orders } from './order.reducer'
import { search } from './search.reducer'
import { menus } from './menu.reducer'
import { campaigns } from './campaign.reducer'

const rootReducer = combineReducers({
  customer,
  signup,
  alert,
  general,
  merchant,
  restaurant,
  stores,
  merchantStores,
  categories,
  dietaries,
  orders,
  search,
  attributes,
  menus,
  campaigns,
})

export default rootReducer
