import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Textfield from '../Fields/TextField_'
import SelectAddress from './BusinessDetails/SelectAddress'
import PhoneField from '../Fields/PhoneField_'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { useIntl } from 'react-intl'

import { labelConstants, valueConstants } from '../Helpers'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
}))

const BusinessDetails = ({ handleChange, values, setValues, error, setParsedAddress, parsedAddress }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const categories = useSelector(({ categories }) => categories.data)

  return (
    <Container maxWidth='md' className={classes.root}>
      <Grid container spacing={2}>
        <Textfield
          values={values}
          prop={valueConstants.storeName}
          required
          error={error}
          handleChange={handleChange}
          gridVal={6}
        />
        <Textfield
          values={values}
          prop={valueConstants.storeNumber}
          error={error}
          handleChange={handleChange}
          gridVal={6}
        />
        <Grid item xs={6}>
          <SelectAddress setParsedAddress={setParsedAddress} parsedAddress={parsedAddress} error={error} />
        </Grid>
        <Textfield
          values={values}
          prop={valueConstants.suiteNo}
          error={error}
          handleChange={handleChange}
          gridVal={6}
        />
        <Textfield
          values={values}
          prop={valueConstants.storeEmail}
          required
          error={error}
          handleChange={handleChange}
          gridVal={6}
        />
        <PhoneField
          prop={valueConstants.storePhone}
          values={values}
          setValues={setValues}
          label='Store Phone*'
          error={error}
          gridVal={6}
        />
        <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
          <TextField
            select
            defaultValue
            variant='outlined'
            size='small'
            fullWidth
            required
            value={values?.categoryId}
            onChange={handleChange(valueConstants.categoryId)}
            label={f({ id: labelConstants.categoryId })}
            error={error && values.categoryId.length === 0}
            name='category'
            InputLabelProps={{ shrink: true }}
          >
            {categories?.map((cat, index) => (
              <MenuItem key={index} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
          <TextField
            id={valueConstants.minHeadCount}
            variant='outlined'
            size='small'
            type='number'
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            fullWidth
            required
            value={values.minHeadCount}
            onChange={handleChange(valueConstants.minHeadCount)}
            label={f({ id: labelConstants.minHeadCount })}
            error={error && values.minHeadCount.length === 0}
            name='minHeadCount'
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

BusinessDetails.propTypes = {
  handleChange: PropTypes.func,
  values: PropTypes.object,
  setValues: PropTypes.func,
  error: PropTypes.bool,
  setParsedAddress: PropTypes.func,
  parsedAddress: PropTypes.object,
}

export default BusinessDetails
