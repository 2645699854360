import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'

import { GreenRadio } from '../../../../utils/tools'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(),
  },
  label: {
    maxWidth: 150,
  },
}))

const CheckFieldRadioButtons = ({ setUseStoreAddressForCheck, useStoreAddressForCheck }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item md={4} sm={4} xs={12}>
        <FormControl fullWidth>
          <FormControlLabel
            className={classes.label}
            control={
              <GreenRadio
                checked={useStoreAddressForCheck}
                onChange={() => setUseStoreAddressForCheck(true)}
                value={useStoreAddressForCheck}
              />
            }
            label={<Typography>{f({ id: 'store-address' })}</Typography>}
          />
        </FormControl>
      </Grid>
      <Grid item md={4} sm={4} xs={12}>
        <FormControl fullWidth>
          <FormControlLabel
            className={classes.label}
            control={
              <GreenRadio
                checked={!useStoreAddressForCheck}
                onChange={() => setUseStoreAddressForCheck(false)}
                value={!useStoreAddressForCheck}
              />
            }
            label={<Typography>{f({ id: 'other-label' })}</Typography>}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

CheckFieldRadioButtons.propTypes = {
  setUseStoreAddressForCheck: PropTypes.func,
  useStoreAddressForCheck: PropTypes.bool,
}

export default CheckFieldRadioButtons
