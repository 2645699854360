import { emailRegex, phoneRegex, urlRegex } from '../../../../utils/regex_collections'
import { valueConstants } from '../Helpers'
import { filter, mapValues } from 'lodash'

export const handleTextFieldError = (error, values, prop) => {
  if (error) {
    if (
      prop === valueConstants.storeEmail ||
      prop === valueConstants.orderConfirmationEmail ||
      prop === valueConstants.paymentContactEmail
    ) {
      if (emailRegex.test(values[prop])) {
        return false
      }
      return true
    } else {
      if (values[prop].length > 0) {
        return false
      }
      return true
    }
  }
  return false
}

export const isPhoneValid = (error, value) => {
  if (value.length > 1) {
    if (phoneRegex.test(value.substring(1))) {
      return true
    }
    return false
  } else if (error) {
    return false
  }
  return true
}

export const zerothStepRequiredFieldsFilled = (values, parsedAddress) => {
  let storePhoneWithoutCountryCode = values.storePhone.substring(1)
  if (
    values.storeName.length === 0 ||
    values.storePhone.length === 0 ||
    values.storeEmail.length === 0 ||
    values.categoryId.length === 0 ||
    values.minHeadCount.length === 0 ||
    !parsedAddress ||
    !phoneRegex.test(storePhoneWithoutCountryCode) ||
    !emailRegex.test(values.storeEmail)
  ) {
    return false
  }
  return true
}

export const firstStepRequiredFieldsFilled = (serviceHours) => {
  let deliveryArr = filter(serviceHours.DELIVERY, (sh) => sh.length > 0)
  let takeoutArr = filter(serviceHours.TAKEOUT, (sh) => sh.length > 0)

  if (deliveryArr.length > 0 || takeoutArr.length > 0) {
    return true
  }
  return false
}

export const StepRequiredFieldsFilled = (values, pdfData) => {
  const falseCondition = values.menuLink === '' && !(pdfData.first || pdfData.second || pdfData.third)
  const falseCondition2 = values.menuLink.length > 0 && !urlRegex.test(values.menuLink)
  if (falseCondition || falseCondition2) return false
  return true
}

export const secondStepRequiredFieldsFilled = (values) => {
  if (values.notificationType.length === 0) {
    return false
  } else {
    if (values.notificationType.length === 2) {
      let resultPhone = handlePhoneTest(values)
      let resultEmail = handleEmailTest(values)
      let result = resultPhone && resultEmail ? true : false
      return result
    } else if (values.notificationType.includes(valueConstants.notificationPhone)) {
      let resultPhone = handlePhoneTest(values)
      return resultPhone
    } else if (values.notificationType.includes(valueConstants.notificationEmail)) {
      let resultEmail = handleEmailTest(values)
      return resultEmail
    }
  }
}

const handlePhoneTest = (values) => {
  let orderConfirmationPhoneWithoutCountryCode = values.orderConfirmationPhone.substring(1)
  if (!phoneRegex.test(orderConfirmationPhoneWithoutCountryCode)) {
    return false
  }
  return true
}

const handleEmailTest = (values) => {
  if (!emailRegex.test(values.orderConfirmationEmail)) {
    return false
  }
  return true
}

export const thirdStepRequiredFieldsFilled = (values, secondTierState, thirdTierState) => {
  if (values.deliveryBy === valueConstants.tookfresh) {
    if (values.maxDeliveryDistance === '' || values.minOrderPrice === '') {
      return false
    }
    return true
  } else {
    let tier1 =
      values.firstTierDeliveryFee !== '' && values.firstTierMinOrder !== '' && values.firstTierMaxDistance !== ''
    let tier2 =
      values.secondTierDeliveryFee !== '' && values.secondTierMinOrder !== '' && values.secondTierMaxDistance !== ''
    let tier3 =
      values.thirdTierDeliveryFee !== '' && values.thirdTierMinOrder !== '' && values.thirdTierMaxDistance !== ''

    if (!secondTierState && !thirdTierState && tier1) {
      return true
    } else if (!thirdTierState && secondTierState && tier2 && tier1) {
      return true
    } else if (thirdTierState && secondTierState && tier1 && tier2 && tier3) {
      return true
    }
    return false
  }
}

export const tierControl = (values) => {
  let deliverySelection = values.deliveryBy === valueConstants.ownBusiness

  let secondTierVals =
    values.secondTierMinOrder !== '' && values.secondTierMaxDistance !== '' && values.secondTierDeliveryFee !== ''
  let thirdTierVals =
    values.thirdTierMinOrder !== '' && values.thirdTierMaxDistance !== '' && values.thirdTierDeliveryFee !== ''

  let isFirstSecondMinOrderValues = +values.firstTierMinOrder < +values.secondTierMinOrder
  let isFirstSecondMaxDistanceValues = +values.firstTierMaxDistance < +values.secondTierMaxDistance
  let isFirstSecondDeliveryFeeValues = +values.firstTierDeliveryFee <= +values.secondTierDeliveryFee

  let isSecondThirdMinOrderValues = +values.secondTierMinOrder < +values.thirdTierMinOrder
  let isSecondThirdMaxDistanceValues = +values.secondTierMaxDistance < +values.thirdTierMaxDistance
  let isSecondThirdDeliveryFeeValues = +values.secondTierDeliveryFee <= +values.thirdTierDeliveryFee

  if (deliverySelection) {
    if (thirdTierVals) {
      if (
        isSecondThirdMinOrderValues &&
        isSecondThirdMaxDistanceValues &&
        isFirstSecondMinOrderValues &&
        isFirstSecondMaxDistanceValues &&
        isFirstSecondDeliveryFeeValues &&
        isSecondThirdDeliveryFeeValues
      ) {
        return true
      }
      return false
    } else if (secondTierVals) {
      if (isFirstSecondMinOrderValues && isFirstSecondMaxDistanceValues && isFirstSecondDeliveryFeeValues) {
        return true
      }
      return false
    }
    return true
  }
  return true
}

export const fourthStepRequiredFieldsFilled = (values) => {
  let paymentContactPhoneWithoutCountryCode = values.paymentContactPhone.substring(1)
  if (
    !phoneRegex.test(paymentContactPhoneWithoutCountryCode) ||
    values.paymentContactName.length === 0 ||
    values.paymentContactEmail.length === 0
  ) {
    return false
  }
  return true
}

export const fifthStepRequiredFieldsFilled = (values, checkParsedAddress) => {
  if (values.paymentMethod === valueConstants.directDeposit) {
    if (
      values.taxIdNumber.trim().length < 9 ||
      values.routingNumber.length < 9 ||
      values.accountNumber.length < 10 ||
      values.confirmAccountNumber.length < 10 ||
      values.accountNumber !== values.confirmAccountNumber
    ) {
      return false
    }
    return true
  } else if (values.paymentMethod === valueConstants.check) {
    if (!checkParsedAddress) {
      return false
    }
    return true
  }
}

export const handlePhoneNo = (data) => {
  if (data.toString().length < 10) {
    return ''
  } else {
    let plus = '+'
    let newNo = plus.concat(data)
    return newNo
  }
}

export const handleWorkingHours = (data) => {
  let workingHoursArr = []
  let delivery = data.DELIVERY
  let takeout = data.TAKEOUT

  mapValues(delivery, function (item) {
    return item.length > 0 && workingHoursArr.push(item[0])
  })
  mapValues(takeout, function (item) {
    return item.length > 0 && workingHoursArr.push(item[0])
  })
  return workingHoursArr
}

export const handleTierArray = (values) => {
  let arr = []
  const {
    firstTierDeliveryFee,
    firstTierMinOrder,
    firstTierMaxDistance,
    secondTierDeliveryFee,
    secondTierMinOrder,
    secondTierMaxDistance,
    thirdTierDeliveryFee,
    thirdTierMinOrder,
    thirdTierMaxDistance,
  } = values
  if (firstTierDeliveryFee) {
    let obj1 = {
      deliveryFee: firstTierDeliveryFee,
      minOrder: firstTierMinOrder,
      maxDistance: firstTierMaxDistance,
    }
    arr.push(obj1)
  }
  if (secondTierDeliveryFee) {
    let obj2 = {
      deliveryFee: secondTierDeliveryFee,
      minOrder: secondTierMinOrder,
      maxDistance: secondTierMaxDistance,
    }
    arr.push(obj2)
  }
  if (thirdTierDeliveryFee) {
    let obj3 = {
      deliveryFee: thirdTierDeliveryFee,
      minOrder: thirdTierMinOrder,
      maxDistance: thirdTierMaxDistance,
    }
    arr.push(obj3)
  }

  return arr
}
