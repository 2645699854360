import { storeConstants, initialPagableList } from '../constants'

const initialState = {
  loading: false,
  error: null,
  locals: [],
  data: initialPagableList,
  customerCurrentStore: null,
  merchantCurrentStore: null,
}

export function stores(state = initialState, action) {
  switch (action.type) {
    case storeConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case storeConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case storeConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.stores,
      }
    case storeConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    // case storeConstants.DELETE_STORE_SUCCESS:
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       content: [...state.data.content].filter((x) => x.id !== action.storeId),
    //       numberOfElements: state.data.numberOfElements - 1,
    //     },
    //   }
    case storeConstants.FIND_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case storeConstants.FIND_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        customerCurrentStore: action.store,
        error: null,
      }
    case storeConstants.FIND_STORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case storeConstants.SET_CURRENT_STORE:
      return {
        ...state,
        customerCurrentStore: action.store,
      }
    case storeConstants.CUSTOMER_SEARCH_LOCAL_SUCCESS:
      return {
        ...state,
        locals: action.locals,
      }
    default:
      return state
  }
}
