const priceQtyMaker = (orderMenus) => {
  let [price, qty] = [0, 0]
  orderMenus.forEach((menu, index) => {
    price += menu.quantity * menu.price
    qty += menu.quantity
  })

  return { price, qty }
}

export { priceQtyMaker }
