import React from 'react'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import logoText from '../../../../images/logo_text_black.svg'
import logo from '../../../../images/tookfrsh_logo.svg'

import { deliveryTypes } from '../../../../constants'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingLeft: 27,
    paddingRight: 29.5,
    height: 90,
  },
  container: {
    borderBottom: '1px solid #c4c4c4',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  textContainer: {
    flex: '3 1 auto',
    paddingTop: 36,
    paddingRight: 0,
  },
  text: {
    fontSize: 25,
    fontWeight: 'bold',
    color: '#707070',
    textAlign: 'left',
    padding: 0,
  },
  logoContainer: {
    flex: '1 1 auto',
    paddingTop: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 0,
  },
  logoText: {
    marginLeft: 10,
  },
}))

const Header = () => {
  const classes = useStyles()

  const deliveryType = useSelector(({ search }) => search?.searchQuery.deliveryType)
  const currentHeader = deliveryType === deliveryTypes.DELIVERY ? 'edit-details' : 'edit-pickup-details'
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography className={classes.text}>
            <FormattedMessage id={currentHeader} />
          </Typography>
        </div>
        <div className={classes.logoContainer}>
          <img src={logo} alt='TookFresh' height='30' />
          <img src={logoText} alt='TookFresh' height='30' className={classes.logoText} />
        </div>
      </div>
    </div>
  )
}

export default Header
