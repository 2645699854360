import { authCustomerHeader, uiHeader, merchantHeader } from '../helpers/config'
import { customerConstants, entityTypes } from '../constants'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const customerService = {
  social,
  signin,
  signout,
  signup,
  getAllSavedAddresses,
  createAddress,
  deleteAddress,
  updateAddress,
  resetPassword,
  updatePassword,
  requestEmailVerificationCode,
  requestPhoneVerificationCode,
  getAllFavoriteStores,
  saveFavoriteStore,
  deleteFavoriteStore,
  verifyEmailCode,
  verifyPhoneCode,
  getPaymentMethods,
  savePaymentMethod,
  deletePaymentMethod,
  updateUser,
  defaultPaymentMethod,
  getUser,
  checkEmail,
  getUserBalance,
}

function getUserBalance() {
  return axios.get(`${API_URL}/user/account`, authCustomerHeader()).then((res) => {
    return res
  })
}

function signin(email, password) {
  return axios
    .post(
      `${API_URL}/user/sign-in`,
      {
        email: email,
        password: password,
      },
      authCustomerHeader()
    )
    .then((res) => {
      return res
    })
}

function getUser() {
  return axios.get(`${API_URL}/user`, merchantHeader()).then((res) => {
    return res
  })
}

function updateUser(data) {
  return axios
    .put(
      `${API_URL}/user`,
      {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
      },
      authCustomerHeader()
    )
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
}

function social(provider, token) {
  return axios
    .post(
      `${API_URL}/user/social/login`,
      {
        provider: provider,
        token: token,
      },
      authCustomerHeader()
    )
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
}

async function signout() {
  localStorage.removeItem(customerConstants.CUSTOMER)
}

function signup(customer) {
  return axios.post(`${API_URL}/user/sign-up`, customer, uiHeader()).then((res) => {
    return res
  })
}

function getAllSavedAddresses() {
  return axios.get(`${API_URL}/user/address`, authCustomerHeader()).then((res) => {
    return res.data
  })
}

function createAddress(address) {
  return axios.post(`${API_URL}/user/address`, address, authCustomerHeader()).then((res) => {
    return res
  })
}

function deleteAddress(id) {
  return axios.delete(`${API_URL}/user/address/${id}`, authCustomerHeader()).then((res) => {
    return res.status
  })
}

function updateAddress(id, address) {
  return axios.put(`${API_URL}/user/address/${id}`, address, authCustomerHeader()).then((res) => {
    return res
  })
}

function resetPassword(email) {
  return axios.post(`${API_URL}/user/reset/password/ui?email=${email}`, null, uiHeader()).then((res) => {
    return res
  })
}

function updatePassword(currentPass, newPass) {
  return axios
    .post(
      `${API_URL}/user/change/password`,
      {
        currentPassword: currentPass,
        newPassword: newPass,
      },
      authCustomerHeader()
    )
    .then((res) => {
      return res
    })
}

function requestEmailVerificationCode() {
  return axios.post(`${API_URL}/user/verification/email`, null, authCustomerHeader()).then((res) => {
    return res
  })
}

function verifyEmailCode(token) {
  return axios
    .post(`${API_URL}/user/verification/email/confirmation?token=${token}`, null, authCustomerHeader())
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
}

function requestPhoneVerificationCode(data) {
  return axios.post(`${API_URL}/user/verification/phone`, data, authCustomerHeader()).then((res) => {
    return res
  })
}

function verifyPhoneCode(token) {
  return axios
    .post(`${API_URL}/user/verification/phone/confirmation?token=${token}`, null, authCustomerHeader())
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
}

function getAllFavoriteStores(query) {
  const queryString = require('query-string')
  const stringified = queryString.stringify(query, {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
  })

  return axios.get(`${API_URL}/user/favorite/store?${stringified}`, authCustomerHeader()).then((favorites) => {
    return favorites
  })
}

function saveFavoriteStore(storeId) {
  let data = {
    entityId: storeId,
    entityType: entityTypes.store,
  }

  return axios.post(`${API_URL}/user/favorite`, data, authCustomerHeader()).then((res) => {
    return res
  })
}

function deleteFavoriteStore(storeId) {
  return axios.delete(`${API_URL}/user/favorite/${storeId}`, authCustomerHeader()).then((res) => {
    return res.status
  })
}

function getPaymentMethods() {
  return axios.get(`${API_URL}/user/payment-method`, authCustomerHeader()).then((res) => {
    return res
  })
}

function savePaymentMethod(id) {
  return axios.post(`${API_URL}/user/payment-method/attach?id=${id}`, null, authCustomerHeader()).then((res) => {
    return res
  })
}

function deletePaymentMethod(id) {
  return axios.delete(`${API_URL}/user/payment-method/detach?id=${id}`, authCustomerHeader()).then((res) => {
    return res
  })
}

function defaultPaymentMethod(id) {
  return axios.post(`${API_URL}/user/payment-method/default?id=${id}`, null, authCustomerHeader()).then((res) => {
    return res
  })
}

function checkEmail(email) {
  return axios.get(`${API_URL}/user/check/email/${email}`, uiHeader()).then((res) => {
    return res
  })
}
