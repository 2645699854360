import React from 'react'
import { useIntl } from 'react-intl'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import KeyboardArrowDown from '@material-ui/icons/ArrowDropDown'

import { deliveryTimes } from '../../../../constants'

moment.locale('en')

const useStyles = makeStyles((theme) => ({
  whenPopover: {
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
  },
}))

const WhenPopover = ({
  showWhenPopover,
  anchorEl,
  closeWhenPopover,
  deliveryTime,
  handleWhenChange,
  searchQuery,
  handleWhenDetailChange,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <Popover
      disablePortal
      id='when-popover'
      open={showWhenPopover}
      anchorEl={anchorEl}
      onClose={closeWhenPopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.whenPopover}>
        <RadioGroup
          aria-label='when-group'
          name='when'
          value={deliveryTime}
          row={true}
          onChange={handleWhenChange}
          style={{ margin: 20 }}
        >
          <FormControlLabel
            value={deliveryTimes.ASAP}
            control={<Radio color='primary' />}
            label={f({ id: 'asap-label' })}
          />
          <FormControlLabel
            value={deliveryTimes.SCHEDULED}
            control={<Radio color='primary' />}
            label={f({ id: 'scheduled-label' })}
          />
        </RadioGroup>
        {deliveryTime === deliveryTimes.SCHEDULED && (
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DateTimePicker
              fullWidth
              inputVariant='outlined'
              size='small'
              placeholder={f({ id: 'pick-date-time' })}
              value={searchQuery.deliveryTimeDetail}
              disablePast={true}
              minutesStep={15}
              animateYearScrolling
              onChange={(value) => handleWhenDetailChange(value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <KeyboardArrowDown />
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        )}
      </div>
    </Popover>
  )
}

WhenPopover.propTypes = {
  showWhenPopover: PropTypes.bool,
  anchorEl: PropTypes.object,
  closeWhenPopover: PropTypes.func,
  deliveryTime: PropTypes.string,
  handleWhenChange: PropTypes.func,
  searchQuery: PropTypes.object,
}

export default WhenPopover
