import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Backdrop, CircularProgress, makeStyles, Paper, Tab } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { TabList, TabPanel } from '@material-ui/lab'
import { BlackButton } from '../../utils/tools'
import TabContext from '@material-ui/lab/TabContext'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AttributeList from './store-settings/AttributeList'
import AttributeDialog from './store-settings/AttributeDialog'
import { useToggle } from '../../../hooks/useToggle'
import DeleteDialog from './store-settings/DeleteDialog'
import AttributeValueDialog from './store-settings/AttributeValueDialog'
import { attributeActions } from '../../../actions'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    flexGrow: 1,
    marginTop: 50,
  },
  tabPanel: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
  },
}))

const StoreSettings = ({ intl }) => {
  const classes = useStyles()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()

  const [selectedTab, setSelectedTab] = useState('0')
  const [showAttributeDialog, setShowAttributeDialog] = useToggle()
  const [showAttributeDeleteDialog, setShowAttributeDeleteDialog] = useToggle()
  const [showAddValueDialog, setShowAddValueDialog] = useToggle()

  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore, shallowEqual)
  const selectedAttribute = useSelector(({ attributes }) => attributes.selectedAttribute, shallowEqual)
  const attributes = useSelector(({ attributes }) => attributes.data)
  const loading = useSelector(({ attributes }) => attributes.loading)

  const dispatch = useDispatch()
  const getAllAttributes = async (id) => dispatch(attributeActions.getAllAttributes(id))
  const changeSelected = (obj) => dispatch(attributeActions.changeSelectedAttribute(obj))
  const deleteAttribute = (storeId, attrId) => dispatch(attributeActions.deleteAttribute(storeId, attrId))

  useEffect(() => {
    getAttributes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttribute?.values])

  const getAttributes = async () => {
    await getAllAttributes(selectedStore?.storeId)
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue)
  }
  const handleDeleteAttribute = async () => {
    deleteAttribute(selectedStore?.storeId, selectedAttribute?.id)
    changeSelected(null)
    setShowAttributeDeleteDialog()
  }
  const showDeleteDialogHandler = (item) => {
    changeSelected(item)
    setShowAttributeDeleteDialog()
  }
  const closeDeleteDialogHandler = () => {
    changeSelected(null)
    setShowAttributeDeleteDialog()
  }
  const showValueDialog = (item) => {
    changeSelected(item)
    setShowAddValueDialog()
  }
  const closeValueDialog = () => {
    changeSelected(null)
    setShowAddValueDialog()
  }
  const handleEditAttribute = (val) => {
    changeSelected(val)
    setShowAttributeDialog()
  }
  const handleAttributeDialogClose = () => {
    changeSelected(null)
    setShowAttributeDialog()
  }
  const handleShowAttributeDialog = () => {
    selectedAttribute && changeSelected(null)
    setShowAttributeDialog()
  }

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <Paper className={classes.paper}>
        <TabContext value={selectedTab}>
          <TabList onChange={handleTabChange} aria-label='settings tab' indicatorColor='primary' textColor='primary'>
            {/* HAVING ONLY ONE TAB ITEM UNDERNEATH OF THE TABLIST FIRES RED ALERT ON CONSOLE. WHEN WE ADD ANOTHER TAB, IT IS GOING TO DISAPPEAR */}
            <Tab label={getLabel('menu-attributes')} value='0' />
            <Tab label={getLabel('menu-attributes')} value='1' style={{ display: 'none' }} />
          </TabList>
          <TabPanel value='0' className={classes.tabPanel}>
            <div className='row'>
              <div className='grow' />
              <BlackButton onClick={handleShowAttributeDialog}>{getLabel('add-new')}</BlackButton>
            </div>
            <AttributeList
              attributes={attributes}
              deleteDialog={showDeleteDialogHandler}
              valueDialog={showValueDialog}
              editAttribute={handleEditAttribute}
            />
          </TabPanel>
          {/* <TabPanel value='1'>Menu Attributes - 1</TabPanel> */}
        </TabContext>
      </Paper>
      <AttributeDialog
        showDialog={showAttributeDialog}
        closeDialog={handleAttributeDialogClose}
        attribute={selectedAttribute}
      />
      <DeleteDialog
        showConfirm={showAttributeDeleteDialog}
        handleConfirmClose={closeDeleteDialogHandler}
        handleDelete={handleDeleteAttribute}
        item={selectedAttribute?.name}
        text='about-delete-attribute'
      />
      <AttributeValueDialog showDialog={showAddValueDialog} closeDialog={closeValueDialog} item={selectedAttribute} />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

StoreSettings.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(StoreSettings)
