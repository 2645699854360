import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import { injectIntl } from 'react-intl'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}))

export const Header = ({ intl }) => {
  const {
    values: { classesFromParent, firstActiveMenu, openSwipeable },
    setters: { openDrawer },
  } = useMerchantHomeContext()
  const classes_ = useStyles()
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <AppBar
      position='fixed'
      className={clsx(classesFromParent.appBar, {
        [classesFromParent.appBarShift]: openSwipeable,
      })}
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={openDrawer}
          edge='start'
          className={clsx(classes_.menuButton, openSwipeable && classes_.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant='h6' noWrap>
          {firstActiveMenu
            ? getLabel(`${firstActiveMenu}-label`).toUpperCase()
            : !openSwipeable && getLabel('tookfresh-dashboard').toUpperCase()}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(Header)
