import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import logo from '../../images/tookfrsh_logo.svg'
import Dialog from '@material-ui/core/Dialog'
import Fab from '@material-ui/core/Fab'
// import InjectedCart from './cart/injectedCart'
import { BlackButton, Transition } from '../utils/tools'
import logoText from '../../images/logo_text_black.svg'
import cartIcon from '../../images/cart.svg'
import downIcon from '../../images/down.svg'
import orderIcon from '../../images/orders-icon.svg'
import favoriteIcon from '../../images/favorite-icon.svg'
import paymentIcon from '../../images/payment-icon.svg'
import accountCheck from '../../images/account-check.svg'
import markerIcon from '../../images/marker-gray.svg'
import signoutIcon from '../../images/signout-icon.svg'
import profileIcon from '../../images/profile.svg'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { orderActions, customerActions, searchActions } from '../../actions'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
// import { Elements } from '@stripe/react-stripe-js'
import QuickCart from './cart/QuickCart'
import Popover from '@material-ui/core/Popover'
import DeliveryDetails from './delivery_banner/DeliveryDetails'
import clsx from 'clsx'
import WebConfirmationDialog from '../common/web-confirmation-dialog'
// import { isTablet } from 'react-device-detect'
import CartComponent from './cart/CartComponent'
import TabletCart from './cart/TabletCart'
import { useCurrentWidth } from '../../hooks/useCurrentWidth'
import AboutIcon from '@material-ui/icons/TouchApp'

// const CardComponent = lazy(() => import('./cart/CartComponent'))

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0,
  },
  mobileTitle: {
    fontSize: 18,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
  },
  mobileAppbar: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderShadow: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  desktopAppbar: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderShadow: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  toolbar: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobileMenuButton: {
    minWidth: 24,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1),
    },
  },
  iconButton: {
    margin: theme.spacing(),
    padding: 0,
  },
  backButton: {
    margin: theme.spacing(),
    marginLeft: 0,
    padding: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  dialog: {
    padding: 0,
  },
  mobileTypeIcon: {
    backgroundColor: '#e4e4e4',
    height: 40,
    maxHeight: 40,
    width: 60,
    minWidth: 60,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  button: {
    textTransform: 'capitalize',
    height: 40,
    margin: theme.spacing(),
  },
  signinButton: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    height: 40,
    marginLeft: theme.spacing(),
  },
  badge: {
    marginLeft: theme.spacing(4),
  },
  dialogPaper: {
    minHeight: 400,
    height: 620,
    maxHeight: '90vh',
    overflowY: 'unset',
  },
  dialogPaperEmptyBasket: {
    height: '50vh',
    width: '35vh',
  },
  profileButton: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.3,
    letterSpacing: 'normal',
    color: '#000',
    textTransform: 'capitalize',
    height: 40,
    paddingLeft: 0,
    borderRadius: 20,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  black: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    margin: 'auto',
    left: 0,
    bottom: 0,
    right: 0,
    marginRight: theme.spacing(),
  },
  down: {
    marginLeft: theme.spacing(),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 48,
    width: 48,
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItem: {
    color: '#000',
    fontSize: 18,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#acacac !important',
    },
  },
  popoverContent: {
    pointerEvents: 'auto',
    borderRadius: 15,
  },
  iconContainer: {
    height: 48,
    width: 48,
    borderRadius: '50%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconText: {
    fontSize: 16,
    color: '#333333',
    lineHeight: '21px',
    paddingLeft: 10,
  },
  deliveryDetails: {
    border: '1.5px solid #c4c4c4',
    borderRadius: 10,
    padding: 1,
    height: 48,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileAndBadge: {
    minWidth: 130,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  phonePaper: {
    width: '638px',
    height: '503px',
    padding: '20px 20px 34px 20px',
    backgroundColor: '#ffffff',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogCloseButton: {
    padding: '25px',
    position: 'absolute',
    color: '#ccc',
    right: '0',
    top: '0',
    borderRadius: '0 0 0 100%',
    '& svg': {
      fontSize: '24px',
    },
  },
  dialogCloseButtonIcon: {
    position: 'absolute',
    top: '8px',
    right: '7px',
  },
  customizedButton: {
    position: 'absolute',
    left: '98.2%',
    top: '-4%',
    backgroundColor: 'white',
    color: 'gray',
    '& svg': {
      transform: 'scale(1)',
      transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover': {
      '& svg': {
        transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
        transform: 'scale(1.3)',
      },
      backgroundColor: 'white',
    },
  },
}))

const StyledMenu = withStyles({
  paper: {
    width: 230,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const TopNavbar = ({ intl, history, cartVisible, deliveryDetails, component }) => {
  const [showBasket, setShowBasket] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openProfileMenu, setOpenProfileMenu] = useState(false)
  const [openQuickCart, setOpenQuickCart] = useState(false)
  const [verifyPhone, setVerifyPhone] = useState(false)
  const [addPhone, setAddPhone] = useState(false)
  const [showTabletBasket, setShowTabletBasket] = useState(false)
  const [showSignoutDialog, setShowSignoutDialog] = useState(false)

  const popoverAnchor = useRef()
  const classes = useStyles()
  const currentWidth = useCurrentWidth()

  const savedOrder = useSelector(({ orders }) => orders.savedOrder)
  const cart = useSelector(({ orders }) => orders.cart)
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const customerInfo = useSelector(({ customer }) => customer.customerInfo)

  const dispatch = useDispatch()
  const clearCart = () => dispatch(orderActions.clearCart())
  const deleteOrder = (id) => dispatch(orderActions.deleteOrder(id))
  const addUserId = (id) => dispatch(orderActions.addUserIdToCart(id))
  const signout = async () => dispatch(customerActions.signout())
  const clearOrderFees = () => dispatch(orderActions.clearOrderFees())
  const resetSearchQuery = () => dispatch(searchActions.resetSearchQueryWhenSignout())

  const closeBasket = async () => {
    showTabletBasket ? setShowTabletBasket(false) : setShowBasket(false)
    savedOrder?.id && (await deleteOrder(savedOrder.id))
    clearOrderFees()
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleShowBasket = async () => {
    if (cart.orderMenus?.length > 0) {
      currentWidth < 960 ? setShowTabletBasket(true) : setShowBasket(true)
      setOpenQuickCart(false)
      setAnchorEl(null)
      if (customerInfo) addUserId(customerInfo.id)
    } else return
  }

  const handleProfileClick = (event) => {
    setOpenProfileMenu(true)
    setAnchorEl(event.currentTarget)
  }

  const handleProfileMenuClose = () => {
    setOpenProfileMenu(false)
    setAnchorEl(null)
  }

  const handleSignout = async () => {
    setOpenProfileMenu(false)
    clearCart()
    resetSearchQuery()
    await signout()
    savedOrder?.id && deleteOrder(savedOrder.id)
    history.push(`${path}/`)
  }

  const openQuickCartFunction = (event) => {
    event.preventDefault()
    setOpenQuickCart(!openQuickCart)
    setAnchorEl(event.currentTarget)
  }

  const closeQuickCart = () => {
    setOpenQuickCart(false)
    setAnchorEl(null)
  }

  const openAddPhoneDialog = () => {
    setAddPhone(true)
  }

  const openVerifyDialog = () => {
    setVerifyPhone(true)
  }

  const closePhoneDialog = () => {
    addPhone ? setAddPhone(false) : setVerifyPhone(false)
  }

  const closePhoneDialogWithRouting = () => {
    addPhone ? setAddPhone(false) : setVerifyPhone(false)
    history.push(`${path}/customer/account/profile`)
  }

  const closeSignoutDialog = () => setShowSignoutDialog(false)
  const openSignoutDialog = () => setShowSignoutDialog(true)

  let itemCount = cart.orderMenus.reduce((total, menuItem) => total + menuItem.quantity, 0)

  return (
    <div className={classes.root}>
      <div className={clsx('centered-row full-width', [classes.logoContainer])}>
        <div>
          <IconButton
            color='inherit'
            className={classes.logo}
            onClick={() => history.push(`${path}/`)}
            disableFocusRipple
            disableRipple
            aria-label='back'
          >
            <img src={logo} alt='TookFresh' height='60' />
            <img src={logoText} alt='TookFresh' height='30' style={{ marginLeft: 10 }} />
          </IconButton>
        </div>
        {deliveryDetails ? (
          <div className={classes.deliveryDetails}>
            <DeliveryDetails navbar={deliveryDetails} component={component} />
          </div>
        ) : null}
        <div className={classes.profileAndBadge}>
          {signedIn ? (
            <div className='centered-row'>
              <Button
                aria-controls='profile-menu'
                aria-haspopup='true'
                className={classes.profileButton}
                size='small'
                onClick={handleProfileClick}
              >
                <div className={classes.black}>
                  <img src={profileIcon} alt='profile' height='20' />
                </div>
                {customerInfo.firstName}
                <img className={classes.down} src={downIcon} alt='arrow' height='7' width='10' />
              </Button>
              <StyledMenu
                id='profile-menu'
                anchorEl={anchorEl}
                keepMounted
                open={openProfileMenu}
                onClose={handleProfileMenuClose}
              >
                <MenuItem className={classes.listItem} onClick={() => history.push(`${path}/customer/account/profile`)}>
                  <ListItemIcon>
                    <Avatar alt={customerInfo.firstName} src='/broken-image.jpg' className={classes.avatar} />
                  </ListItemIcon>
                  <ListItemText primary={customerInfo.firstName} className={classes.iconText} />
                </MenuItem>

                <MenuItem className={classes.listItem} onClick={() => history.push(`${path}/customer/orders`)}>
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={orderIcon} alt='orders' height='25px' width='18px' />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('orders-label')} className={classes.iconText} />
                </MenuItem>

                <MenuItem
                  className={classes.listItem}
                  onClick={() => history.push(`${path}/customer/account/favorite-restaurants`)}
                >
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={favoriteIcon} alt='favorite' height='21px' width='24px' />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('favorites-label')} className={classes.iconText} />
                </MenuItem>

                <MenuItem
                  className={classes.listItem}
                  onClick={() => history.push(`${path}/customer/account/payment-methods`)}
                >
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={paymentIcon} alt='payment' height='25px' width='16px' />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('payment-label')} className={classes.iconText} />
                </MenuItem>

                <MenuItem
                  className={classes.listItem}
                  onClick={() => history.push(`${path}/customer/account/addresses`)}
                >
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={markerIcon} alt='marker' height='25px' width='25px' />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('addresses-label')} className={classes.iconText} />
                </MenuItem>

                {/* <MenuItem className={classes.listItem} onClick={() => history.push(`${path}/customer/invite-friends`)}>
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={accountCheck} alt='invite-friends' height='25px' width='25px' />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('invite-friends')} className={classes.iconText} />
                </MenuItem> */}

                <MenuItem className={classes.listItem} onClick={() => history.push(`${path}/customer/about-us`)}>
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <AboutIcon />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('about-us')} className={classes.iconText} />
                </MenuItem>

                <MenuItem className={classes.listItem} onClick={openSignoutDialog}>
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={signoutIcon} alt='signout' height='25px' width='25px' />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('sign-out')} className={classes.iconText} />
                </MenuItem>

                {/* <MenuItem
                  className={classes.listItem}
                  disabled
                  onClick={() => history.push(`${path}/customer/account/AccountMain`)}
                >
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={accountIcon} alt= 'account' height= '25px' width='25px' />
                    </div> 
                  </ListItemIcon>
                  <ListItemText primary={getLabel('account-label')} className={classes.iconText}/>
                </MenuItem> */}

                {/* <MenuItem
                  disabled
                  className={classes.listItem}
                  onClick={() => history.push(`${path}/customer/promotions`)}
                >
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={promotionIcon} alt='promotion' height='25px' width='25px' />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('promotions-label')} className={classes.iconText} />
                </MenuItem> */}
                {/* <MenuItem
                  disabled
                  className={classes.listItem}
                  onClick={() => history.push(`${path}/customer/notifications`)}
                >
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={notificationsIcon} alt='promotion' height='25px' width='25px' />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('notifications-label')} className={classes.iconText} />
                </MenuItem> */}

                {/* <MenuItem
                  disabled
                  className={classes.listItem}
                  onClick={() => history.push(`${path}/customer/gift-cards`)}
                >
                  <ListItemIcon>
                    <div className={classes.iconContainer}>
                      <img src={giftCardIcon} alt='gift-cards' height='25px' width='25px' />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={getLabel('gift-cards')} className={classes.iconText} />
                </MenuItem> */}
              </StyledMenu>
            </div>
          ) : (
            <BlackButton
              variant='contained'
              color='primary'
              className={classes.signinButton}
              onClick={() => history.push(`${path}/customer/signin`, history.location.pathname)}
            >
              {getLabel('sign-in')}
            </BlackButton>
          )}
          {cartVisible && (
            <div ref={popoverAnchor} aria-owns='click-to-open-popover' aria-haspopup='true'>
              <Badge
                color='secondary'
                overlap='circular'
                badgeContent={itemCount}
                className={classes.badge}
                showZero={false}
              >
                <Fab
                  color='primary'
                  aria-label='add'
                  size='small'
                  style={{ boxShadow: 'none' }}
                  onClick={openQuickCartFunction}
                >
                  <img src={cartIcon} alt='Basket' height='20' />
                </Fab>
              </Badge>
              <Popover
                id='quick-cart-popover'
                anchor={anchorEl}
                disableRestoreFocus
                classes={{
                  paper: classes.popoverContent,
                }}
                open={openQuickCart}
                onClose={() => closeQuickCart()}
                anchorEl={popoverAnchor.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <QuickCart onCheckout={handleShowBasket} onClose={closeQuickCart} />
              </Popover>
            </div>
          )}
        </div>
      </div>
      {/* <Suspense fallback={<div></div>}> */}
      <CartComponent
        classes={classes}
        showBasket={showBasket}
        closeBasket={closeBasket}
        Transition={Transition}
        openVerifyDialog={openVerifyDialog}
        openAddPhoneDialog={openAddPhoneDialog}
      />
      {/* </Suspense> */}
      <TabletCart
        showBasket={showTabletBasket}
        closeBasket={closeBasket}
        Transition={Transition}
        openVerifyDialog={openVerifyDialog}
        openAddPhoneDialog={openAddPhoneDialog}
      />
      <Dialog
        classes={{ paper: classes.phonePaper }}
        open={addPhone}
        onClose={closePhoneDialog}
        TransitionComponent={Transition}
      >
        <WebConfirmationDialog
          close={closePhoneDialog}
          header={getLabel('no-phone-header')}
          text={getLabel('no-phone-text')}
          text2={false}
          function1={closePhoneDialogWithRouting}
          function2={false}
          img={'noVerified'}
          button1={getLabel('go-to-profile')}
          button2={false}
          id={false}
        />
      </Dialog>
      <Dialog
        classes={{ paper: classes.phonePaper }}
        open={verifyPhone}
        onClose={closePhoneDialog}
        TransitionComponent={Transition}
      >
        <WebConfirmationDialog
          close={closePhoneDialog}
          header={getLabel('no-verified-header')}
          text={getLabel('no-verified-text')}
          text2={false}
          function1={closePhoneDialogWithRouting}
          function2={false}
          img={'noVerified'}
          button1={getLabel('go-to-profile')}
          button2={false}
          id={false}
        />
      </Dialog>
      <Dialog
        classes={{ paper: classes.phonePaper }}
        open={showSignoutDialog}
        onClose={closeSignoutDialog}
        TransitionComponent={Transition}
      >
        <WebConfirmationDialog
          close={closeSignoutDialog}
          header={getLabel('are-you-sure')}
          text={getLabel('about-to-signout')}
          // text2={data.formattedAddress}
          function1={closeSignoutDialog}
          function2={handleSignout}
          img={'deleteAddressImage'}
          button1={getLabel('cancel-label')}
          button2={getLabel('sign-out')}
          // id={data.id}
        />
      </Dialog>
    </div>
  )
}

TopNavbar.propTypes = {
  cartVisible: PropTypes.bool,
}

export default withRouter(injectIntl(TopNavbar))
