import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    padding: '5px 10px',
    fontSize: 14,
  },
}))

function StyledTooltip(props) {
  const classes = useStyles()

  return <Tooltip arrow classes={classes} {...props} />
}

export default StyledTooltip
