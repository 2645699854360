import React from 'react'
// import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { Name } from './restaurant-admin/Name'
import { SelectStore } from './restaurant-admin/SelectStore'
import { useMerchantHomeContext } from '../../../../../context/merchant/MerchantHome/main'

export const StoreAdminView = (props) => {
  const {
    values: { isRestaurantAdmin },
    setters: { handleStoreChange },
  } = useMerchantHomeContext()
  const stores = useSelector(({ merchantStores }) => merchantStores.stores)
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const selectedRestaurant = useSelector(({ merchantStores }) => merchantStores.selectedRestaurant)

  if (isRestaurantAdmin) return null

  return (
    <>
      <Grid item xs={12}>
        <Name name={selectedRestaurant?.restaurantName} />
      </Grid>
      <Grid item xs={12}>
        {stores.length > 1 ? (
          <SelectStore handleChange={handleStoreChange} stores={stores} selectedStore={selectedStore} />
        ) : (
          <Name name={selectedStore?.storeNickName} />
        )}
      </Grid>
    </>
  )
}
