import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import { handleTextFieldError, labelConstants } from '../Helpers'

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}))

const TextField_ = ({ values, prop, required, error, handleChange, gridVal, disabled }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  return (
    <Grid item xs={gridVal} className={classes.grid}>
      <TextField
        size='small'
        value={values[prop]}
        variant='outlined'
        fullWidth
        id={prop}
        InputLabelProps={{ shrink: true }}
        label={f({ id: labelConstants[prop] })}
        required={required}
        error={required ? handleTextFieldError(error, values, prop) : false}
        onChange={handleChange(prop)}
        disabled={disabled}
        inputProps={{
          maxLength: 150,
        }}
      />
    </Grid>
  )
}

TextField_.propTypes = {
  values: PropTypes.object,
  prop: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  handleChange: PropTypes.func,
  gridVal: PropTypes.number,
}

export default TextField_
