import React from 'react'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'

import { phoneRegex } from '../../../utils/regex_collections'

import { makeStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'

import 'react-phone-input-2/lib/bootstrap.css'

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    '&.react-tel-input .special-label': {
      fontSize: '0.75em',
    },
    '&.react-tel-input .form-control:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
      "&[iserror='true']": {
        borderColor: theme.palette.secondary.main,
        boxShadow: `0px 0px 0px 1px ${theme.palette.secondary.main}`,
      },
    },
    '&.react-tel-input .form-control:hover': {
      "&[isdisabled='true']": {
        borderColor: '#CACACA',
      },
    },
    '&.react-tel-input .form-control': {
      transition: 'none',
      fontSize: 'inherit',
      width: '100%',
      padding: '9.32px 10.5px 9.32px 50px',
      borderRadius: 10,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '&.react-tel-input .selected-flag:before': {
      transition: 'none',
      border: 'none',
      boxShadow: 'none',
    },
  },
}))

const PhoneInputField = ({ intl, propss }) => {
  const classes = useStyles()
  const { isFocused, isInvalid, state, handlePhone, field } = propss

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <PhoneInput
      specialLabel=''
      country='us'
      containerClass={classes.phoneInput}
      value={state[field] || ''}
      onChange={(phone) => handlePhone(phone)}
      placeholder={getLabel(field === 'aptSuite' ? 'apt-suite' : `${field}-label`)}
      disableDropdown
      countryCodeEditable={false}
      name={field}
      isValid={(value) => {
        if (value.length > 1) {
          if (phoneRegex.test(value.substring(1))) {
            return true
          } else {
            return false
          }
        } else if (isFocused[field] && isInvalid[field]) {
          return false
        } else {
          return true
        }
      }}
      inputProps={{
        iserror: isFocused[field] && isInvalid[field] ? 'true' : 'false',
        name: field,
      }}
      onlyCountries={['us']}
    />
  )
}

PhoneInputField.propTypes = {
  propss: PropTypes.object.isRequired,
}

export default injectIntl(PhoneInputField)
