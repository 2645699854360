import { initialServiceHours } from '../../../../../constants'

export const steps = [
  'Business Details',
  'Working Hours',
  'Notifications',
  'Delivery Details',
  'Payment Details',
  'Bank Info',
]

export const labelConstants = {
  email: 'email-label',
  businessRole: 'your-role-in-business',
  storeName: 'store-name',
  storeAddress: 'store-address',
  suiteNo: 'apt-suite',
  storePhone: 'store-phone',
  storeEmail: 'store-email',
  menuLink: 'your-catering-menu',
  orderConfirmationPhone: 'number-order-confirmation',
  orderConfirmationEmail: 'email-order-confirmation',
  firstTierDeliveryFee: 'delivery-fee-1',
  firstTierMinOrder: 'min-order-1',
  firstTierMaxDistance: 'max-distance-1',
  secondTierDeliveryFee: 'delivery-fee-2',
  secondTierMinOrder: 'min-order-2',
  secondTierMaxDistance: 'max-distance-2',
  thirdTierDeliveryFee: 'delivery-fee-3',
  thirdTierMinOrder: 'min-order-3',
  thirdTierMaxDistance: 'max-distance-3',
  legalBusinessName: 'legal-business-name',
  paymentContactName: 'fullname-label',
  paymentContactPhone: 'phone-number-label',
  paymentContactEmail: 'email-label',
  phoneNoStar: 'phone-no-star',
  check: 'check-label',
  directDeposit: 'direct-deposit-label',
  taxIdNumber: 'taxId-number',
  accountNumber: 'account-number',
  routingNumber: 'routing-number',
  confirmAccountNumber: 'account-number-confirm',
  maxDeliveryDistance: 'max-delivery-distance',
  minOrderPrice: 'min-order-price',
  storeNumber: 'store-number',
  textMessage: 'text-message',
  checkSuiteNo: 'apt-suite',
  categoryId: 'categories-label',
  minHeadCount: 'min-head-count',
}

export const valueConstants = {
  storeName: 'storeName',
  storeAddress: 'storeAddress',
  suiteNo: 'suiteNo',
  storePhone: 'storePhone',
  storeEmail: 'storeEmail',
  menuLink: 'menuLink',
  notificationType: 'notificationType',
  orderConfirmationPhone: 'orderConfirmationPhone',
  orderConfirmationEmail: 'orderConfirmationEmail',
  firstTierDeliveryFee: 'firstTierDeliveryFee',
  firstTierMinOrder: 'firstTierMinOrder',
  firstTierMaxDistance: 'firstTierMaxDistance',
  secondTierDeliveryFee: 'secondTierDeliveryFee',
  secondTierMinOrder: 'secondTierMinOrder',
  secondTierMaxDistance: 'secondTierMaxDistance',
  thirdTierDeliveryFee: 'thirdTierDeliveryFee',
  thirdTierMinOrder: 'thirdTierMinOrder',
  thirdTierMaxDistance: 'thirdTierMaxDistance',
  deliveryBy: 'deliveryBy',
  paymentContactName: 'paymentContactName',
  paymentContactPhone: 'paymentContactPhone',
  paymentContactEmail: 'paymentContactEmail',
  tookfresh: 'SENPEX',
  ownBusiness: 'OWN_BUSINESS',
  check: 'CHECK',
  directDeposit: 'DIRECT_DEPOSIT',
  paymentMethod: 'paymentMethod',
  taxIdNumber: 'taxIdNumber',
  accountNumber: 'accountNumber',
  routingNumber: 'routingNumber',
  confirmAccountNumber: 'confirmAccountNumber',
  minOrderPrice: 'minOrderPrice',
  maxDeliveryDistance: 'maxDeliveryDistance',
  storeNumber: 'storeNumber',
  notificationPhone: 'PHONE',
  notificationEmail: 'EMAIL',
  notificationBoth: 'BOTH',
  checkSuiteNo: 'checkSuiteNo',
  categoryId: 'categoryId',
  minHeadCount: 'minHeadCount',
}

export const notificationValues = [valueConstants.notificationPhone, valueConstants.notificationEmail]

export const notificationConstants = {
  storePhone: 'Store Phone',
  storeEmail: 'Store Email',
  otherPhone: 'Other Phone',
  otherEmail: 'Other Email',
}

export const phoneConstants = [notificationConstants.storePhone, notificationConstants.otherPhone]
export const emailConstants = [notificationConstants.storeEmail, notificationConstants.otherEmail]

export const tfDefault = [
  {
    label: labelConstants.minOrderPrice,
    value: valueConstants.minOrderPrice,
  },
  {
    label: labelConstants.maxDeliveryDistance,
    value: valueConstants.maxDeliveryDistance,
  },
]

export const deliveryRole = [valueConstants.tookfresh, valueConstants.ownBusiness]

export const firstTier = [
  {
    label: labelConstants.firstTierDeliveryFee,
    value: valueConstants.firstTierDeliveryFee,
  },
  {
    label: labelConstants.firstTierMinOrder,
    value: valueConstants.firstTierMinOrder,
  },
  {
    label: labelConstants.firstTierMaxDistance,
    value: valueConstants.firstTierMaxDistance,
  },
]

export const secondTier = [
  {
    label: labelConstants.secondTierDeliveryFee,
    value: valueConstants.secondTierDeliveryFee,
  },
  {
    label: labelConstants.secondTierMinOrder,
    value: valueConstants.secondTierMinOrder,
  },
  {
    label: labelConstants.secondTierMaxDistance,
    value: valueConstants.secondTierMaxDistance,
  },
]

export const thirdTier = [
  {
    label: labelConstants.thirdTierDeliveryFee,
    value: valueConstants.thirdTierDeliveryFee,
  },
  {
    label: labelConstants.thirdTierMinOrder,
    value: valueConstants.thirdTierMinOrder,
  },
  {
    label: labelConstants.thirdTierMaxDistance,
    value: valueConstants.thirdTierMaxDistance,
  },
]

export const phoneAreaConstants = ['Store phone', 'Other']

export const emailAreaConstants = ['Store email', 'Other']

export const paymentDetailConstants = {
  storePhone: 'Store phone',
  storeEmail: 'Store email',
  empty: '',
}

export const directDepositFields = [
  {
    label: labelConstants.taxIdNumber,
    value: valueConstants.taxIdNumber,
  },
  {
    label: labelConstants.routingNumber,
    value: valueConstants.routingNumber,
  },
  {
    label: labelConstants.accountNumber,
    value: valueConstants.accountNumber,
  },
  {
    label: labelConstants.confirmAccountNumber,
    value: valueConstants.confirmAccountNumber,
  },
]

export const initialValuesState = {
  storeName: '',
  storeNumber: '',
  storeAddress: '',
  suiteNo: '',
  storePhone: '1',
  storeEmail: '',
  minHeadCount: '',
  workingHours: initialServiceHours,
  menuLink: '',
  notificationType: [],
  orderConfirmationPhone: '',
  orderConfirmationEmail: '',
  deliveryBy: valueConstants.tookfresh,
  firstTierDeliveryFee: '',
  firstTierMinOrder: '',
  firstTierMaxDistance: '',
  secondTierDeliveryFee: '',
  secondTierMinOrder: '',
  secondTierMaxDistance: '',
  thirdTierDeliveryFee: '',
  thirdTierMinOrder: '',
  thirdTierMaxDistance: '',
  paymentContactName: '',
  paymentContactPhone: '1',
  paymentContactEmail: '',
  paymentMethod: valueConstants.directDeposit,
  taxIdNumber: '',
  routingNumber: '',
  accountNumber: '',
  confirmAccountNumber: '',
  checkAddress: '',
  checkSuiteNo: '',
  minOrderPrice: '',
  maxDeliveryDistance: '',
  acceptSameDayOrder: true,
  categoryId: '',
}
