import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { GreenCheckbox } from '../../../utils/tools'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#121212',
    margin: theme.spacing(),
  },
  checks: {
    maxWidth: 600,
  },
}))

const Dietaries = ({ dietaries, menu, intl, handleDietaryChange }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <>
      <Grid item sm={12}>
        <Typography className={classes.label}>{getLabel('dietaries-label')}</Typography>
      </Grid>
      <Grid item sm={12}>
        <div className={classes.checks}>
          <Grid container>
            {dietaries.map((dietary) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={dietary.id}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={menu.dietaries.includes(dietary.id)}
                      onChange={handleDietaryChange}
                      name={dietary.id.toString()}
                    />
                  }
                  label={dietary.name}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </>
  )
}

Dietaries.propTypes = {
  dietaries: PropTypes.array,
  menu: PropTypes.object,
  intl: PropTypes.object,
  handleDietaryChange: PropTypes.func,
}

export default injectIntl(Dietaries)
