import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import ForgotPassword from '../../authentication/forgot_password'
import { Transition } from '../../utils/tools'

const ForgotPasswordDialog = ({ showForgotPasswordDialog, handleForgotPasswordClose }) => {
  return (
    <Dialog
      fullScreen
      open={showForgotPasswordDialog}
      onClose={handleForgotPasswordClose}
      TransitionComponent={Transition}
    >
      <ForgotPassword handleClose={() => handleForgotPasswordClose()} />
    </Dialog>
  )
}

export default ForgotPasswordDialog
