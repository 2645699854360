import React from 'react'
import { connect } from 'react-redux'
import { customerActions, alertActions } from '../../../../actions'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { injectIntl } from 'react-intl'
import { GreenButton } from '../../../utils/tools'
import { emailRegex } from '../../../utils/regex_collections'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import { customerService } from '../../../../services'
import { generalConstants, customerConstants } from '../../../../constants'
import backIcon from '../../../../images/black-back.svg'
import doneImage from '../../../../images/verification-done.svg'
import wrongCode from '../../../../images/wrong-credential.png'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1,
  },

  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  resend: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  digitField: {
    backgroundColor: '#d6d6d6',
    borderRadius: 40,
    height: 60,
    width: 60,
    maxWidth: 60,
    position: 'relative',
    fontSize: 30,
    padding: 10,
  },
  filled: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  back: {
    position: 'absolute',
    top: 69,
    left: theme.spacing(),
    height: 31,
  },
  took: {
    fontSize: 23,
    fontWeight: 'bold',
  },
  fresh: {
    fontSize: 23,
  },
  mobileTopBar: {
    borderBottomColor: 'white',
    marginTop: 68,
  },
  sendEmail: {
    textAlign: 'center',
    fontSize: 16,
    color: '#707070',
  },
  emailLabel: {
    marginBottom: 60,
    textAlign: 'center',
    fontSize: 16,
    color: '#707070',
  },
  digitAreaGrid: {
    width: '100%',
    marginBottom: 70,
    fontSize: 16,
    color: '#707070',
  },
  notReceive: {
    fontSize: 16,
    fontWeight: 300,
    opacity: 0.8,
    marginBottom: -5,
  },
  orLogin: {
    fontSize: 14,
    color: '#707070',
    marginRight: 5,
    marginTop: 2,
    opacity: 0.8,
    fontWeight: 300,
  },
  socialNetwork: {
    fontSize: 16,
    color: '#69e781',
    fontWeight: 500,
  },
  dialogContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 390,
  },
  dialogContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 380,
    maxWidth: 275,
  },
  dialogErrorContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 380,
    maxWidth: 315,
  },
  dialogContentText: {
    fontWeight: 'bold',
    fontSize: 34,
    textAlign: 'center',
    color: 'black',
  },
  dialogContentText2: {
    fontWeight: 'normal',
    fontSize: 16,
    textAlign: 'center',
    color: '#333333',
  },
  dialogActions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogButton: {
    fontSize: 15,
    textTransform: 'capitalize',
    width: '100%',
    height: 48,
    borderRadius: 10,
    backgroundColor: '#69e781',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  headerContainer: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

class VerifyEmailWeb extends React.Component {
  constructor(props) {
    super(props)

    this.firstInput = React.createRef()
    this.secondInput = React.createRef()
    this.thirdInput = React.createRef()
    this.fourthInput = React.createRef()

    this.state = {
      showDoneDialog: false,
      showErrorDialog: false,
      email: '',
      anchorEl: null,
      activeStep: 0,
      firstDigit: '',
      secondDigit: '',
      thirdDigit: '',
      fourthDigit: '',
      regexp: /^[0-9\b]+$/,
      emailInvalid: null,
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.customer) {
  //     return {
  //       email: props.customer.customerInfo.email,
  //     }
  //   }
  //   return null
  // }

  componentDidMount() {
    if (this.props.customer) {
      this.setState({ email: this.props.customer.customerInfo.email })
    }
    return null
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  checkEmail = async (email) => {
    const response = await customerService.checkEmail(email)
    return response.data
  }

  async handleSubmit(e) {
    const { currentEmail, customer, storeUpdatedEmail, requestVerificationCode, showError } = this.props
    const { email } = this.state
    e.preventDefault()
    const data = {
      firstName: customer.customerInfo.firstName,
      lastName: customer.customerInfo.lastName,
      phone: customer.customerInfo.phone,
      email: email,
    }
    if (email !== currentEmail) {
      let isEmailAvailable = await this.checkEmail(email)
      if (isEmailAvailable !== 'Ok!') return showError(isEmailAvailable.toString())
      customerService.updateUser(data).then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          this.setState({ activeStep: 1 })
          requestVerificationCode()
          storeUpdatedEmail(res.data.email, res.data.emailVerified)
        } else this.setState({ showErrorDialog: true })
      })
    } else {
      this.setState({ activeStep: 1 })
      requestVerificationCode()
    }
  }

  sendVerificationCode(token) {
    customerService.verifyEmailCode(token).then((res) => {
      if (res.status === generalConstants.STATUS_OK) {
        const customerData = JSON.parse(localStorage.getItem(customerConstants.CUSTOMER))
        const verifiedCustomer = { ...customerData, emailVerified: true }
        localStorage.setItem(customerConstants.CUSTOMER, JSON.stringify(verifiedCustomer))
        this.setState({ showDoneDialog: true }, () => this.props.verifyEmail(res.data))
      } else {
        this.setState({ showErrorDialog: true }, () => this.props.errorEmailVerification(res.data))
      }
    })
  }

  handleZeroPad(num) {
    const zeroPad = String(num).padStart(4, '0')
    return zeroPad
  }

  handleVerificationCode() {
    const { firstDigit, secondDigit, thirdDigit, fourthDigit } = this.state
    const tokenStr = firstDigit.concat(secondDigit, thirdDigit, fourthDigit)
    this.sendVerificationCode(this.handleZeroPad(tokenStr))
  }

  handleFirstDigitChange(event) {
    let d = event.target.value

    if (d === '') {
      this.setState({ firstDigit: d })
    } else if (this.state.regexp.test(d)) {
      this.setState({ firstDigit: d })
      this.secondInput.current.focus()
    }
  }

  handleSecondDigitChange(event) {
    let d = event.target.value

    if (d === '') {
      this.setState({ secondDigit: d })
      this.firstInput.current.focus()
    } else if (this.state.regexp.test(d)) {
      this.setState({ secondDigit: d })
      this.thirdInput.current.focus()
    }
  }

  handleThirdDigitChange(event) {
    let d = event.target.value

    if (d === '') {
      this.setState({ thirdDigit: d })
      this.secondInput.current.focus()
    } else if (this.state.regexp.test(d)) {
      this.setState({ thirdDigit: d })
      this.fourthInput.current.focus()
    }
  }

  handleFourthDigitChange(event) {
    let d = event.target.value

    if (d === '') {
      this.setState({ fourthDigit: d })
      this.thirdInput.current.focus()
    } else if (this.state.regexp.test(d)) {
      this.setState({ fourthDigit: d }, () => this.handleVerificationCode())
    }
  }

  render() {
    const { classes, currentEmail, verified, requestVerificationCode } = this.props
    const { showDoneDialog, email, activeStep, firstDigit, secondDigit, thirdDigit, fourthDigit, showErrorDialog } =
      this.state

    return (
      <div className='column'>
        <div className='row'>
          <div className={classes.mobileTopBar}>
            <IconButton
              size='small'
              aria-label='close'
              className={classes.back}
              onClick={() => this.props.handleClose()}
            >
              <img src={backIcon} alt='Back' />
            </IconButton>
            <div className={classes.headerContainer}>
              <div className='row'>
                <Typography className={classes.took}>Took</Typography>
                <Typography className={classes.fresh}>Fresh</Typography>
              </div>
            </div>
          </div>
        </div>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          {activeStep === 0 && (
            <div className={classes.paper}>
              <Typography variant='h4' gutterBottom className={classes.title}>
                {currentEmail && email === currentEmail ? this.getLabel('verify-your') : this.getLabel('update-your')}
              </Typography>
              <Typography variant='h4' gutterBottom className={classes.title} style={{ marginBottom: 30 }}>
                {this.getLabel('email-address')}
              </Typography>
              <Typography variant='body1' gutterBottom className={classes.sendEmail}>
                {this.getLabel('sent-email-code')}
              </Typography>
              <Typography variant='body1' gutterBottom className={classes.emailLabel}>
                {email}
              </Typography>
              <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                <div className='row'>
                  <TextField
                    size='small'
                    variant='outlined'
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                    value={email}
                    onChange={(event) =>
                      this.setState({
                        emailFocused: true,
                        emailInvalid: !emailRegex.test(event.target.value),
                        email: event.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        cursor: 'pointer',
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: '#707070',
                      },
                    }}
                    error={this.state.emailInvalid && this.state.emailFocused}
                  />
                </div>
                {email === currentEmail ? (
                  <GreenButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    fullWidth
                    disabled={verified}
                    className={classes.button}
                    // onClick={() => this.setState({ activeStep: 1 })}
                  >
                    {this.getLabel('send-label')}
                  </GreenButton>
                ) : (
                  <GreenButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    fullWidth
                    disabled={this.state.emailInvalid}
                    className={classes.button}
                    // onClick={() => this.setState({ showDoneDialog: true })}
                  >
                    {this.getLabel('update-label')}
                  </GreenButton>
                )}
              </form>
            </div>
          )}
          {activeStep === 1 && (
            <div className={classes.paper}>
              <Typography variant='h4' gutterBottom className={classes.title} style={{ marginBottom: 20 }}>
                {this.getLabel('email-verification')}
              </Typography>
              <Typography variant='subtitle1' gutterBottom style={{ marginBottom: 50 }}>
                {this.getLabel('enter-otp')}
              </Typography>
              <Grid container className={classes.digitAreaGrid}>
                <Grid item xs={3} className='centered-column'>
                  <TextField
                    autoComplete='first'
                    inputRef={this.firstInput}
                    autoFocus
                    value={firstDigit}
                    onChange={this.handleFirstDigitChange.bind(this)}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      },
                      disableUnderline: true,
                      className: clsx(classes.digitField, {
                        [classes.filled]: firstDigit,
                      }),
                    }}
                  />
                </Grid>
                <Grid item xs={3} className='centered-column'>
                  <TextField
                    autoComplete='second'
                    inputRef={this.secondInput}
                    value={secondDigit}
                    onChange={this.handleSecondDigitChange.bind(this)}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      },
                      disableUnderline: true,
                      className: clsx(classes.digitField, {
                        [classes.filled]: secondDigit,
                      }),
                    }}
                  />
                </Grid>
                <Grid item xs={3} className='centered-column'>
                  <TextField
                    autoComplete='third'
                    inputRef={this.thirdInput}
                    value={thirdDigit}
                    onChange={this.handleThirdDigitChange.bind(this)}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      },
                      disableUnderline: true,
                      className: clsx(classes.digitField, {
                        [classes.filled]: thirdDigit,
                      }),
                    }}
                  />
                </Grid>
                <Grid item xs={3} className='centered-column'>
                  <TextField
                    autoComplete='fourth'
                    inputRef={this.fourthInput}
                    value={fourthDigit}
                    onChange={this.handleFourthDigitChange.bind(this)}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: 'center' },
                        maxLength: 1,
                      },
                      disableUnderline: true,
                      className: clsx(classes.digitField, {
                        [classes.filled]: fourthDigit,
                      }),
                    }}
                  />
                </Grid>
              </Grid>
              <Typography variant='subtitle2' gutterBottom className={classes.notReceive}>
                {this.getLabel('not-received-code')}
              </Typography>
              <Button className={classes.resend} onClick={() => requestVerificationCode()}>
                {this.getLabel('resend-code')}
              </Button>
            </div>
          )}
        </Container>
        <div className={classes.dialogContainer}>
          <Dialog
            open={showDoneDialog}
            onClose={() => this.props.handleClose()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            style={{ width: '100%', padding: 10 }}
            PaperProps={{
              style: {
                borderRadius: 20,
                marginTop: 90,
                padding: 20,
              },
            }}
          >
            <DialogContent className={classes.dialogContent}>
              <img src={doneImage} alt='doneImage' />
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText}>
                {this.getLabel('verification-done')}
              </DialogContentText>
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText2}>
                {this.getLabel('email-approved')}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={() => this.props.handleClose()} className={classes.dialogButton}>
                {this.getLabel('continue-label')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className={classes.dialogContainer}>
          <Dialog
            open={showErrorDialog}
            onClose={() => this.props.handleClose()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            style={{ width: '100%', padding: 10 }}
            PaperProps={{
              style: {
                borderRadius: 20,
                marginTop: 90,
                padding: 20,
                minWidth: 315,
              },
            }}
          >
            <DialogContent className={classes.dialogErrorContent}>
              <div style={{ marginBottom: 20 }}>
                <img src={wrongCode} alt='errorImage' width='326' height='271'/>
              </div>
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText2}>
                {this.getLabel('wrong-code')}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={() => this.setState({ showErrorDialog: false })} className={classes.dialogButton}>
                {this.getLabel('continue-label')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )
  }
}

function mapState(state) {
  const { customer } = state

  return {
    customer,
    currentEmail: customer.customerInfo.email,
    token: customer.customerInfo.token,
    verified: customer.customerInfo.emailVerified,
  }
}

const actionCreators = {
  resetPassword: customerActions.resetPassword,
  requestVerificationCode: customerActions.requestEmailVerificationCode,
  verifyEmail: customerActions.storeEmailVerification,
  errorEmailVerification: customerActions.errorEmailVerification,
  storeUpdatedEmail: customerActions.storeUpdatedEmail,
  showError: alertActions.error,
}

VerifyEmailWeb.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(injectIntl(connect(mapState, actionCreators)(VerifyEmailWeb)))
