import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core'
import { withRouter } from 'react-router'
import { GreenButton, RedButton } from '../../utils/tools'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  action: {
    maxWidth: 180,
    minWidth: 140,
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(),
  },
}))

const ActionButtons = ({ selected, action, history, intl, to, text }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <div className='row' style={{ width: '100%' }}>
      <div className='grow' />
      {selected && (
        <RedButton className={classes.action} onClick={() => action((prev) => !prev)}>
          {getLabel('delete-label')}
        </RedButton>
      )}
      <GreenButton className={classes.action} onClick={() => history.push(`${path}/merchant/${to}/add`)}>
        {getLabel(`add-${text}`)}
      </GreenButton>
    </div>
  )
}

ActionButtons.propTypes = {
  selected: PropTypes.number,
  action: PropTypes.func,
  history: PropTypes.object,
  intl: PropTypes.object,
}

export default withRouter(injectIntl(ActionButtons))
