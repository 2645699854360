import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const PasswordField = ({ showPassword, password, handleChange, isFocused, togglePasswordVisibility }) => {
  return (
    <TextField
      size='small'
      variant='outlined'
      margin='normal'
      fullWidth
      name='password'
      label='Password'
      id='password'
      autoComplete='current-password'
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={handleChange}
      error={isFocused && password.length < 8}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' style={{ marginRight: -10 }}>
            <IconButton
              style={{ height: 30, width: 30 }}
              aria-label='toggle password visibility'
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

PasswordField.propTypes = {
  showPassword: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  togglePasswordVisibility: PropTypes.func.isRequired,
}

export default PasswordField
