import React from 'react'

import Dialog from '@material-ui/core/Dialog'

import MobileSignin from '../../authentication/MobileSignin'
import { Transition } from '../../utils/tools'

const SigninDialog = ({ showLogin, handleLoginDialogClose, handleSignin }) => {
  return (
    <Dialog fullScreen open={showLogin} onClose={handleLoginDialogClose} TransitionComponent={Transition}>
      <MobileSignin handleClose={handleLoginDialogClose} handleSignin={handleSignin} />
    </Dialog>
  )
}

export default SigninDialog
