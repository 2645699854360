import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import backIcon from '../../../../images/black-back.svg'
import closeIcon from '../../../../images/close.svg'
import StarRatings from 'react-star-ratings'
import { ratingService } from '../../../../services/rating.services'
import { generalConstants } from '../../../../constants'
import { isMobileOnly } from 'react-device-detect'
import useStyles from './styles'
import Loading from '../../../common/loading'
import clsx from 'clsx'

const RateOrders = ({ handleClose, order, questions, rating, getOrders }) => {
  const classes = useStyles()
  const [ratings, setRatings] = useState(rating)
  const [isLoading, setIsLoading] = useState(false)
  const [comment, setComment] = useState('')

  const changeRating = (newRating, name, id) => {
    let relatedState = ratings[id]
    relatedState.rating = newRating
    let newState = { ...ratings, [id]: relatedState }
    setRatings(newState)
  }

  const submitRating = () => {
    setIsLoading(true)
    const ratingsArray = Object.values(ratings).filter((i) => i.rating !== 0)
    const reqBody = {
      orderComment: comment,
      ratingAnswers: ratingsArray,
    }
    ratingService
      .rateOrder(order.id, reqBody)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          handleClose()
          getOrders()
        }
      })
      .finally(() => setIsLoading(false))
  }

  const handleOrderComment = (txt) => setComment(txt)

  return (
    <div className={isMobileOnly ? classes.root : classes.rootWeb}>
      <Loading open={isLoading} />
      {isMobileOnly ? (
        <div className='mobile-top-bar'>
          <IconButton size='small' className={classes.back} onClick={handleClose}>
            <img src={backIcon} alt='Back' />
          </IconButton>
          <Typography variant='h6'>
            <FormattedMessage id='rate-order' />
          </Typography>
        </div>
      ) : (
        <div className={classes.iconContainer}>
          <IconButton aria-label='close' className={classes.close} onClick={handleClose}>
            <img src={closeIcon} alt='Close' width='30px' height='30px' className={classes.closeImage} />
          </IconButton>
        </div>
      )}
      <div className='vertical-scrolling-wrapper-flexbox'>
        {questions?.map((question, index) => (
          <Grid
            key={index}
            container
            direction='column'
            className={classes.questionContainer}
            style={{ marginTop: !isMobileOnly && 0, marginBottom: !isMobileOnly && 25 }}
          >
            <Typography variant='h6'>{question.name}</Typography>

            <div className={classes.starContainer}>
              <StarRatings
                name={question.name}
                rating={ratings && ratings[question.id].rating}
                starRatedColor='#69e781'
                starHoverColor='#69e781'
                id={question.id}
                changeRating={(rating, name) => changeRating(rating, name, question.id)}
                numberOfStart={5}
                starDimension='30px'
              />
            </div>
          </Grid>
        ))}
        <Grid item className={classes.commentContainer}>
          <TextField
            multiline
            fullWidth
            maxRows={4}
            label={<FormattedMessage id='comments-on-order' />}
            variant='outlined'
            value={comment}
            helperText={`${comment?.length}/250`}
            onChange={(event) => handleOrderComment(event.target.value)}
            inputProps={{
              maxLength: 250,
            }}
            FormHelperTextProps={{
              className: clsx(classes.helperText, {
                [classes.helperTextRed]: comment.length === 250,
              }),
            }}
          />
        </Grid>
      </div>
      <div className='grow' />
      <div className={isMobileOnly ? classes.submitButtonContainer : classes.cartContainer}>
        <Button
          variant='contained'
          className={classes.submitButton}
          color='primary'
          onClick={submitRating}
          disabled={ratings && Object.values(ratings).every((i) => i.rating === 0)}
        >
          <Typography className={classes.cartText}>
            <FormattedMessage id='submit-label' />
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default withRouter(RateOrders)
