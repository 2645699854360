import React from 'react'
// import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  getDetailedOrderInfo,
  getPreviousOrderStatusLabel,
  mapsSelector,
} from '../../../../../helpers/orders/order-details'
import CancelOrder from '../mobile-orders/mobile-order-detail/CancelOrder'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { deliveryTypes, orderStatuses } from '../../../../../constants'
import DeliveryDetails from '../mobile-orders/mobile-order-detail/DeliveryDetails'
import { SearchFab } from '../../../pickup/mobile-pickup/SearchFab'
import locationIcon from '../../../../../images/pickup_location.svg'
import StarIcon from '@material-ui/icons/Star'

const cancellableOrders = [orderStatuses.ORDER_RECEIVED]

const useStyles = makeStyles((theme) => ({
  orderHeaderContainer: {
    width: '97.5%',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'solid 1px #ededed',
    marginLeft: 16.5,
  },
  orderSubheaderContainer: {
    width: '100%',
    margin: '0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeaderText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.31,
    textAlign: 'left',
    color: '#000',
  },
  ratingText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: 1.31,
    textAlign: 'left',
    color: '#000',
  },
  rateButton: {
    marginRight: 0,
    textTransform: 'capitalize',
    fontSize: '16px',
  },
  orderInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  orderTime: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: 1.38,
    textAlign: 'left',
    color: '#000000',
  },
  fabIcon: {
    height: 36,
    width: 36,
    boxShadow: 'none',
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:active': {
      backgroundColor: '#fff',
      boxShadow: 'none',
    },
  },
}))
const OrderHeaderContainer = ({ previous, order, openCancelDialog, storeDetails, rate }) => {
  const classes = useStyles()
  const isDelivery = order && order?.orderType === deliveryTypes.DELIVERY

  const showRateOrderButton = previous && order?.status?.name === orderStatuses.DELIVERED && !order.ratingStatus
  const showOrderRating = previous && order.ratingAverage > 0

  return (
    <div className={classes.orderHeaderContainer} style={{ height: 85.5 }}>
      <div className={classes.orderSubheaderContainer} style={{ height: 50 }}>
        <Typography className={classes.subHeaderText}>
          <FormattedMessage id={!previous ? 'order-upcoming' : getPreviousOrderStatusLabel(order?.status?.name)} />
        </Typography>
        {!previous && cancellableOrders.includes(order?.status?.name) && (
          <div>
            <CancelOrder order={order} id='cancel-order' onClick={openCancelDialog} />
          </div>
        )}
        {showRateOrderButton && (
          <div>
            <Button disableRipple className={classes.rateButton} onClick={() => rate(order)}>
              <FormattedMessage id='rate-order' />
            </Button>
          </div>
        )}
        {showOrderRating && (
          <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <Typography className={classes.ratingText}>
              <FormattedMessage id='your-rating-for-order' />
            </Typography>
            <span style={{ fontSize: 15, fontWeight: 600 }}>{order.ratingAverage}</span>
            <StarIcon fontSize='small' style={{ fill: 'gold' }} />
          </div>
        )}
      </div>
      <Grid container className={classes.orderInfoContainer} style={{ height: 35.5 }}>
        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
          <div className={classes.orderTime}>
            {previous ? getDetailedOrderInfo(order) : `Order Number ${order?.orderNumber}`}
          </div>
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {previous && order?.orderType === deliveryTypes.DELIVERY ? (
            <DeliveryDetails order={order} web />
          ) : (
            !previous &&
            !isDelivery && (
              <SearchFab
                aria-label='location'
                onClick={() => mapsSelector(storeDetails, order)}
                className={classes.fabIcon}
                disableRipple={true}
                disableFocusRipple={true}
              >
                <img src={locationIcon} alt='' width='20' height='20' />
              </SearchFab>
            )
          )}
        </Grid>
      </Grid>
    </div>
  )
}

OrderHeaderContainer.propTypes = {
  // second: PropTypes.third,
}

export default React.memo(OrderHeaderContainer)
