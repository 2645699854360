import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { GreenCheckbox } from '../../../utils/tools'

import OrderConfirmation from './Notifications/OrderConfirmation'
import OrderConfirmationEmail from './Notifications/OrderConfirmationEmail'

import { valueConstants, notificationConstants, labelConstants } from '../../businessConstants'

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  label: {
    maxWidth: 150,
  },
  errorText: {
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    fontWeight: 300,
    marginTop: theme.spacing(2.5),
    color: theme.palette.secondary.main,
  },
  inputFields: {
    marginBottom: theme.spacing(2),
  },
  emailOptionalText: {
    fontStyle: 'italic',
    fontWeight: 300,
    marginLeft: theme.spacing(),
  },
  checkboxContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textMessageAvailable: {
    fontStyle: 'italic',
    fontWeight: 300,
    marginTop: theme.spacing(),
  },
}))

const Notifications = ({ handleChange, values, setValues, error, emailChecked, setEmailChecked }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const [orderConfirmation, setOrderConfirmation] = useState('')
  const [orderConfirmationEmail, setOrderConfirmationEmail] = useState('')

  useEffect(() => {
    const { notificationType } = values
    if (notificationType.length === 2) {
      handleOrderConfirmationPhone()
      handleOrderConfirmationEmail()
      setEmailChecked(true)
    } else {
      handleOrderConfirmationPhone()
    }
  }, [values.notificationType])

  useEffect(() => {
    if (emailChecked) return
    setValues({ ...values, orderConfirmationEmail: '' })
  }, [emailChecked])

  const handleOrderConfirmationPhone = () => {
    const { storePhone, otherPhone } = notificationConstants
    if (values.orderConfirmationPhone === values.storePhone) {
      setOrderConfirmation(storePhone)
    } else {
      setOrderConfirmation(otherPhone)
    }
  }

  const handleOrderConfirmationEmail = () => {
    const { storeEmail, otherEmail } = notificationConstants
    if (values.orderConfirmationEmail === values.storeEmail) {
      setOrderConfirmationEmail(storeEmail)
    } else {
      setOrderConfirmationEmail(otherEmail)
    }
  }

  const handleConfirmationPhone = (value) => {
    if (value === notificationConstants.storePhone) {
      setValues({
        ...values,
        orderConfirmationPhone: values.storePhone,
      })
    } else {
      setValues({
        ...values,
        orderConfirmationPhone: '1',
      })
    }
  }

  const handleOrderConfirmation = ({ target }) => {
    const val = target.value
    setOrderConfirmation(val)
    handleConfirmationPhone(val)
  }

  const handleConfirmationEmail = (value) => {
    if (value === notificationConstants.storeEmail) {
      setValues({
        ...values,
        orderConfirmationEmail: values.storeEmail,
      })
    } else {
      setValues({
        ...values,
        orderConfirmationEmail: '',
      })
    }
  }

  const handleEmailConfirmation = ({ target }) => {
    const val = target.value
    setOrderConfirmationEmail(val)
    handleConfirmationEmail(val)
  }

  const handleCheck = () => {
    if (values.notificationType.length === 2) {
      setEmailChecked(false)
      setValues({
        ...values,
        notificationType: [valueConstants.notificationPhone],
        orderConfirmationEmail: '',
      })
    } else {
      setEmailChecked(true)
      setValues({
        ...values,
        notificationType: [valueConstants.notificationPhone, valueConstants.notificationEmail],
      })
    }
  }

  return (
    <Container maxWidth='md' className={classes.root}>
      <Box className={classes.checkboxContainer}>
        <FormControl fullWidth>
          <FormControlLabel
            control={<GreenCheckbox checked={emailChecked} onChange={handleCheck} />}
            label={
              <Typography>
                {f({ id: labelConstants.email })}{' '}
                <span className={classes.emailOptionalText}>({f({ id: 'optional-email-address-text' })})</span>
              </Typography>
            }
          />
        </FormControl>
      </Box>
      <Grid container spacing={2} className='full-width'>
        <OrderConfirmation
          classes={classes}
          orderConfirmation={orderConfirmation}
          handleOrderConfirmation={handleOrderConfirmation}
          values={values}
          handleChange={handleChange}
          setValues={setValues}
          error={error}
        />
        <OrderConfirmationEmail
          classes={classes}
          orderConfirmation={orderConfirmationEmail}
          handleOrderConfirmation={handleEmailConfirmation}
          values={values}
          handleChange={handleChange}
          setValues={setValues}
          error={error}
          emailChecked={emailChecked}
        />
      </Grid>
      {error && <Typography className={classes.errorText}>{f({ id: 'provide-valid-credentials' })}</Typography>}
    </Container>
  )
}

Notifications.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.bool,
  emailChecked: PropTypes.bool,
  setEmailChecked: PropTypes.func,
}

export default Notifications
