import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { IconButton, makeStyles, Typography } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useMerchantHomeContext } from '../../../../context/merchant/MerchantHome/main'

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
  },
}))

const DrawerHeader = ({ intl }) => {
  const classes = useStyles()
  const {
    setters: { close },
  } = useMerchantHomeContext()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <div className={classes.drawerHeader}>
      <Typography variant='h4' className={classes.title}>
        {getLabel('tookfresh-dashboard')}
      </Typography>
      <IconButton onClick={close}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
  )
}

DrawerHeader.propTypes = {
  intl: PropTypes.object,
  classes: PropTypes.object,
}

export default injectIntl(DrawerHeader)
