let price
export const freeCountChecker = (menuAttr) => {
  let freeCount = menuAttr.freeCount
  let priceArrayLength = menuAttr.attributePrices.length
  if (freeCount > 0) {
    freeCount >= priceArrayLength ? (price = 0) : (price = priceMaker(menuAttr))
  } else {
    price = priceMaker(menuAttr)
  }
  return price
}

const priceMaker = (menuAttr) => {
  let priceArray = []
  menuAttr.attributePrices.forEach((price) => {
    let p = Object.values(price)
    priceArray.push(p[0])
  })
  priceArray.sort((a, b) => a - b)
  let newArray = priceArray.slice(menuAttr.freeCount)
  price = newArray.reduce((total, current) => total + current, 0)
  return price
}
