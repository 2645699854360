export const populateAddress = (addresses) => {
  const benchmarkAddress = addresses.find((a) => a.default_) || addresses[0]
  const populatedAddress = {
    addressLine1: benchmarkAddress.addressLine1,
    addressLine2: benchmarkAddress.addressLine2,
    city: benchmarkAddress.city,
    country: benchmarkAddress.country,
    default_: benchmarkAddress.default_,
    deliveryInstruction: benchmarkAddress.deliveryInstruction,
    deliveryOption: benchmarkAddress.deliveryOption,
    formattedAddress: benchmarkAddress.formattedAddress,
    latitude: benchmarkAddress.latitude,
    longitude: benchmarkAddress.longitude,
    name: benchmarkAddress.name,
    state: benchmarkAddress.state,
    stateName: benchmarkAddress.stateName,
    zipCode: benchmarkAddress.zipCode,
    timeZoneOffset: benchmarkAddress.timeZoneOffset,
  }
  return { populatedAddress }
}
