import { merchantConstants } from '../constants/merchant.constants'

let localMerchantData = JSON.parse(localStorage.getItem(merchantConstants.MERCHANT))

const initialState = {
  loading: false,
  error: null,
  signedIn: false,
  merchantInfo: localMerchantData ? localMerchantData : {},
  merchantRoles: [],
}

export function merchant(state = initialState, action) {
  switch (action.type) {
    case merchantConstants.SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantConstants.SIGNIN_SUCCESS:
      return {
        ...state,
        signedIn: true,
        loading: false,
        merchantInfo: action.merchant,
      }
    case merchantConstants.SIGNIN_FAILURE:
      return {
        ...state,
        signedIn: false,
        merchantInfo: {},
      }
    case merchantConstants.SIGNOUT:
      return {
        ...state,
        signedIn: false,
        merchantInfo: {},
      }
    default:
      return state
  }
}
