import React from 'react'
import PhoneInput from 'react-phone-input-2'
import PropTypes from 'prop-types'

import 'react-phone-input-2/lib/material.css'

import { isPhoneValid } from '../Helpers'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    '&.react-tel-input .special-label': {
      fontSize: '0.75em',
    },
    '&.react-tel-input .form-control:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
      "&[iserror='true']": {
        borderColor: theme.palette.secondary.main,
        boxShadow: `0px 0px 0px 1px ${theme.palette.secondary.main}`,
      },
    },
    '&.react-tel-input .form-control:hover': {
      "&[isdisabled='true']": {
        borderColor: '#CACACA',
      },
    },
    '&.react-tel-input .form-control': {
      transition: 'none',
      fontSize: 'inherit',
      width: '100%',
      padding: '9.32px 10.5px 9.32px 50px',
    },
  },
  grid: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}))

const PhoneField_ = ({ prop, values, setValues, label, disabled, error, gridVal }) => {
  const classes = useStyles()
  return (
    <Grid item xs={gridVal} className={classes.grid}>
      <PhoneInput
        specialLabel={label}
        country='us'
        containerClass={classes.phoneInput}
        value={values[prop]}
        onChange={(phone) => setValues({ ...values, [prop]: phone })}
        disableDropdown
        countryCodeEditable={false}
        disabled={disabled}
        isValid={(value) => isPhoneValid(error, value)}
        inputProps={{
          isdisabled: disabled ? 'true' : 'false',
          iserror: values[prop]?.length > 1 && values[prop]?.length < 11 ? 'true' : 'false',
          name: 'phone',
          required: true,
        }}
        onlyCountries={['us']}
      />
    </Grid>
  )
}

PhoneField_.propTypes = {
  prop: PropTypes.string,
  values: PropTypes.object,
  setValues: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
}

export default PhoneField_
