/* eslint-disable no-unused-expressions */
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import { sliderFilters } from '../../../constants/general.constants'

import { makeStyles } from '@material-ui/core/styles'
import Categories from './searchFilter/categories'
import MoreDivider from './searchFilter/moreDivider'
import Dietaries from './searchFilter/dietaries'
import SliderFilters from './searchFilter/sliderFilters'
import Sort from './searchFilter/sort'
import OpenFilter from './searchFilter/OpenFilter'

import { Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
  },
  groupTitle: {
    fontSize: 15,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: '#000',
  },
  check: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  radio: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  searchInput: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  formControl: {
    marginBottom: theme.spacing(),
  },
  to: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  moreDivider: {
    marginBottom: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  more: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    textTransform: 'capitalize',
    fontSize: 10,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  ratingStarIcon: {
    height: 8,
    width: 8,
    color: '#69e781',
    marginRight: 10,
    marginBottom: 15,
  },
  ratingStarBorderIcon: {
    height: 8,
    width: 8,
    color: '#c4c4c4',
    marginRight: 10,
    marginBottom: 15,
  },
}))

const SearchFilter = () => {
  const classes = useStyles()

  const [showMoreCategory, setShowMoreCategory] = useState(false)

  return (
    <div className={classes.paper}>
      <Categories classes={classes} more={showMoreCategory} />
      <MoreDivider classes={classes} set={setShowMoreCategory} more={showMoreCategory} />
      <Dietaries classes={classes} />
      <Divider className={classes.divider} />
      {sliderFilters.map((filter, index) => (
        <SliderFilters key={index} name={filter} classes={classes} />
      ))}
      <Sort classes={classes} />
      <Divider className={classes.divider} />
      <OpenFilter />
    </div>
  )
}

export default withRouter(SearchFilter)
