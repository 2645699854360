import { dietaryConstants } from '../constants'
import { dietaryService } from '../services'
import { alertActions } from './alert.actions'

export const dietaryActions = {
  getAll,
}

function getAll() {
  return (dispatch) => {
    dispatch(request())

    dietaryService.getAll().then(
      (dietaries) => {
        dispatch(success(dietaries))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request() {
    return { type: dietaryConstants.GETALL_REQUEST }
  }
  function success(dietaries) {
    return { type: dietaryConstants.GETALL_SUCCESS, dietaries }
  }
  function failure(error) {
    return { type: dietaryConstants.GETALL_FAILURE, error }
  }
}
