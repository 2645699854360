import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchActions } from '../../../actions'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { useIntl } from 'react-intl'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Input from '@material-ui/core/Input'
import markerIcon from '../../../images/marker-icon-filled.svg'
import { parseGoogleAPIAddress } from '../../utils/common'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Scrollbars } from 'react-custom-scrollbars'
import { searchService } from '../../../services'
import { setLocalStorageLatLong } from '../../../helpers/address/setLocalStorageItems'

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    height: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: 5,
    fontSize: 16,
    fontWeight: 500,
    color: '#707070',
    paddingLeft: 10,
    paddingRight: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    "&[data-content-type='addAddress']": {
      minWidth: '100%',
      width: '100%',
      fontSize: 14,
      color: '#707070',
      fontWeight: 'normal',
      height: 36,
    },
    "&[data-content-type='navbar']": {
      //   backgroundColor: "pink",
    },
  },
  cardMain: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
    borderRadius: 5,
    marginTop: 10,
    position: 'relative',
    zIndex: 100,
    boxShadow: '0 0 10px #c4c4c4',
  },
  cardSub: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'none',
  },
  suggetionContainer: {
    marginTop: 10,
    width: '95%',
    height: 'auto',
    marginBottom: 10,
    "&[data-content-type='addAddress']": {
      maxHeight: 100,
      overflowY: 'auto',
    },
  },
  suggestion: {
    backgroundColor: 'white',
    cursor: 'pointer',
    borderTop: '1px solid #E9ECEF',
    borderLeft: '1px solid #E9ECEF',
    borderRight: '1px solid #E9ECEF',
    height: 50,
    padding: 8,
    '&:hover': {
      backgroundColor: '#f8f8f8 !important',
    },
  },
  suggestionItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  suggestionText: {
    whiteSpace: 'nowrap',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontSize: 14,
    color: '#707070',
    marginLeft: 10,
  },
  whenFilled: {},
  whenEmpty: {
    transition: 'visibility .5s ease-in-out',
    '-moz-transition': 'visibility .5s ease-in-out',
    visibility: 'hidden',
    height: 17,
    width: 17,
  },
  inputText: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
}))

const AddressSearch = ({ contentType, addressChangeListener, handleDeliveryAddress }) => {
  const classes = useStyles()
  const intl = useIntl()

  const [currentAddress, setCurrentAddress] = useState('')
  const [clicked, setClicked] = useState(false)
  const [selected, setSelected] = useState(false)

  const addressQuery = useSelector(({ search }) => search.searchQuery.address)

  const dispatch = useDispatch()
  const setAddress = (data) => dispatch(searchActions.setAddress(data))

  useEffect(() => {
    if (contentType === 'addAddress') {
      setCurrentAddress('')
    } else {
      addressQuery && setCurrentAddress(addressQuery.formattedAddress)
    }
  }, [addressQuery])

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleChange = (address) => {
    setCurrentAddress(address)
    addressChangeListener && addressChangeListener(address)
  }

  const handleSelect = (address) => {
    setSelected(true)
    setCurrentAddress(address)
    geocodeByAddress(address)
      .then((results) => {
        let parsedAddress = parseGoogleAPIAddress(results[0])
        let lat = parsedAddress.latitude
        let long = parsedAddress.longitude
        searchService
          .getTimeZoneOffset(lat, long)
          .then((response) => {
            let { rawOffset, dstOffset } = response.data
            parsedAddress.formattedAddress = address
            parsedAddress.name = parsedAddress.city
            parsedAddress.timeZoneOffset = (rawOffset + dstOffset) / 60
            setAddress(parsedAddress)
            setLocalStorageLatLong(parsedAddress)
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch((error) => console.error('Error', error))
  }

  const addDeliveryAddress = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        handleDeliveryAddress(results[0])
      })
      .catch((error) => console.error('Error', error))
    setCurrentAddress(address)
  }

  const openPopup = () => {
    setClicked(true)
  }

  const closePopup = () => {
    setClicked(false)
  }

  const searchOptions = {
    componentRestrictions: { country: ['us'] },
  }

  const onFocus = (event) => {
    setClicked(true)
    setCurrentAddress('')
    addressChangeListener && addressChangeListener('')
    if (event.target.autocomplete) {
      event.target.autocomplete = 'none'
    }
  }

  const refill = () => {
    if (contentType === 'addAddress') {
      setCurrentAddress(currentAddress)
    } else {
      selected ? setCurrentAddress(currentAddress) : addressQuery && setCurrentAddress(addressQuery.formattedAddress)
      setSelected(false)
    }
  }

  const clearInputField = () => {
    setCurrentAddress('')
    addressChangeListener && addressChangeListener('')
  }

  return (
    <ClickAwayListener onClickAway={closePopup}>
      <div onClick={openPopup} style={{ minWidth: '100%', minHeight: 44 }}>
        <PlacesAutocomplete
          value={currentAddress}
          onChange={handleChange}
          onSelect={contentType === 'addAddress' ? addDeliveryAddress : handleSelect}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div>
              <ClickAwayListener onClickAway={refill}>
                <Input
                  data-content-type={contentType}
                  onFocus={onFocus}
                  onClick={clearInputField}
                  {...getInputProps({
                    placeholder: getLabel('address-zip'),
                    className: classes.input,
                  })}
                  inputProps={{
                    className: classes.inputText,
                  }}
                  disableUnderline
                />
              </ClickAwayListener>
              {clicked && (
                <Card elevation={1} className={classes.cardMain}>
                  <Card className={classes.cardSub} style={{ height: suggestions?.length === 0 ? 0 : null }}>
                    <div
                      className={clsx('autocomplete-dropdown-container', [classes.suggetionContainer])}
                      data-content-type={contentType}
                      style={{
                        borderBottom: suggestions.length > 0 && '1px solid #E9ECEF',
                      }}
                    >
                      {suggestions.map((suggestion, index) => {
                        const className = classes.suggestion
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              // style,
                            })}
                            key={index}
                          >
                            <Scrollbars style={{ height: '100%' }}>
                              <div className={classes.suggestionItem}>
                                <img src={markerIcon} alt='marker' height='30px' width='20px' />
                                <Typography className={classes.suggestionText}>{suggestion.description}</Typography>
                              </div>
                            </Scrollbars>
                          </div>
                        )
                      })}
                    </div>
                  </Card>
                </Card>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    </ClickAwayListener>
  )
}

export default AddressSearch
