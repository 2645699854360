import React from 'react'
import { useDispatch } from 'react-redux'

import { useIntl } from 'react-intl'

import { merchantRestaurantActions } from '../../../../../actions/merchant.restaurant.actions'

import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

import { Transition } from '../../../../utils/tools'
import { svgType } from '../../../../../constants/general.constants'

import StoreBackgroundSample from '../sampleVisuals/storeBackgroundSample'

const useDialogStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogTitle: {
    flex: 1,
    fontSize: 18,
  },
  saveButton: {
    marginRight: 15,
    width: 100,
    height: 40,
    textTransform: 'capitalize',
    fontSize: 18,
    backgroundColor: 'white',
    transition: ' 0.3s',
    '&:hover': {
      backgroundColor: 'inherit',
      color: 'white',
      border: '1px solid white',
    },
  },
}))

const ImgDialog = ({ img, onClose, dialogOpen, onDialogClose, restaurantId, index, file, imageType }) => {
  const classes = useDialogStyles()
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()

  const saveBackground = () => dispatch(merchantRestaurantActions.saveBackground(restaurantId, file, imageType))
  const clearBackground = () => dispatch(merchantRestaurantActions.clearBackground())
  const saveLogo = () => dispatch(merchantRestaurantActions.saveLogo(restaurantId, file, imageType))
  const clearLogo = () => dispatch(merchantRestaurantActions.clearLogo())

  const onSave = () => {
    if (index === 1) {
      clearBackground()
      saveBackground()
    } else {
      clearLogo()
      saveLogo()
    }
    onClose()
  }

  const closeDialog = () => {
    if (imageType === svgType) {
      onDialogClose()
      onClose()
      clearBackground()
    }
    onDialogClose()
  }

  return (
    <Dialog fullScreen open={dialogOpen} onClose={onClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color='inherit' onClick={closeDialog} aria-label='Close'>
            <CloseIcon />
          </IconButton>
          <Typography color='inherit' className={classes.dialogTitle}>
            {f({ id: 'store-background-preview' })}
          </Typography>
        </Toolbar>
        <Button onClick={onSave} disableRipple className={classes.saveButton}>
          {f({ id: 'save-label' })}
        </Button>
      </AppBar>
      <StoreBackgroundSample img={img} preview index={index} />
    </Dialog>
  )
}

export default ImgDialog
