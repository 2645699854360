import axios from 'axios'
import { customerActions } from '../actions'
import { store } from '../helpers/store'
import { generalConstants } from '../constants'

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const {
      response: { status },
    } = error
    if (status === generalConstants.AUTH_ERROR) {
      store.dispatch(customerActions.signout())
    }
    return Promise.reject(error)
  }
)

export * from './category.service'
export * from './dietary.service'
export * from './menu.service'
export * from './merchant.service'
export * from './order.service'
export * from './restaurant.service'
export * from './store.service'
export * from './customer.service'
export * from './search.service'
export * from './attribute.service'
export * from './campaign.service'
export * from './rating.services'
