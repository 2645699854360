import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import tag from '../../../../../images/tag.svg'
import { injectIntl } from 'react-intl'

const AddPromo = ({ intl }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Box
      mt={1}
      pb={1}
      pl={1}
      display='flex'
      flexDirection='row'
      alignItems='center'
      style={{ borderBottom: '1px solid #f4f4f4' }}
    >
      <Box flexGrow={0.5}>
        <Box width={16} height={16}>
          <img src={tag} alt='tag icon' width='16' height='16' />
        </Box>
      </Box>
      <Box flexGrow={5}>
        <Typography variant='h6' style={{ color: '#69e781' }}>
          {getLabel('app-promo')}
        </Typography>
      </Box>
      <Box flexGrow={1} textAlign='center'></Box>
    </Box>
  )
}

AddPromo.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(AddPromo)
