import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { serviceHourIntervals } from '../../../../constants/service_hours'

import StoreServiceHours from '../common/StoreServiceHours'

const WorkingHours = ({
  classes,
  setServiceHours,
  setIsServiceHoursChanged,
  showServiceHours,
  setShowServiceHours,
  serviceHours,
  editting,
}) => {
  const { formatMessage: f } = useIntl()

  const addServiceHour = (serviceType, serviceDay) => {
    let item = {
      fromTime: '09:00',
      toTime: '18:00',
      orderType: serviceType,
      day: serviceDay,
    }

    let prevItem = serviceHours[serviceType][serviceDay].slice(-1)[0]

    if (prevItem) {
      var moment = require('moment')

      let nextItems = serviceHourIntervals.filter(function (h) {
        return moment(h.label, 'HH:mm a').isAfter(moment(prevItem.toTime, 'HH:mm a'))
      })

      if (nextItems.length >= 2) {
        item.fromTime = nextItems[0].value
        item.toTime = nextItems[1].value

        let _serviceHours = serviceHoursHandler(serviceType, serviceDay, item)
        setServiceHours(_serviceHours)
        setIsServiceHoursChanged(true)
      }
    } else {
      let _serviceHours = serviceHoursHandler(serviceType, serviceDay, item)
      setServiceHours(_serviceHours)
      setIsServiceHoursChanged(true)
    }
  }

  const serviceHoursHandler = (serviceType, serviceDay, item) => {
    let newServiceHours = Object.assign({}, serviceHours)
    newServiceHours[serviceType][serviceDay] = serviceHours[serviceType][serviceDay].concat(item)
    return newServiceHours
  }

  const removeServiceHour = (hour) => {
    let _serviceHours = Object.assign({}, serviceHours)
    _serviceHours[hour.orderType][hour.day] = serviceHours[hour.orderType][hour.day].filter(function (h) {
      return h !== hour
    })
    setServiceHours(_serviceHours)
    setIsServiceHoursChanged(true)
  }

  const updateServiceHour = (hour, newHour) => {
    let _serviceHours = Object.assign({}, serviceHours)
    let hours = _serviceHours[hour.orderType][hour.day]
    let currentHour = hours.find((h) => h === hour)
    Object.assign(currentHour, newHour)
    _serviceHours[hour.orderType][hour.day] = hours
    setServiceHours(_serviceHours)
    setIsServiceHoursChanged(true)
  }

  return (
    <>
      <div className={classes.header}>
        <Typography variant='h5'>{f({ id: 'working-hours-label' })}</Typography>
      </div>
      <Grid container spacing={2} className='full-width'>
        <StoreServiceHours
          showServiceHours={showServiceHours}
          serviceHours={serviceHours}
          showHours={setShowServiceHours}
          addHour={addServiceHour}
          removeHour={removeServiceHour}
          updateHour={updateServiceHour}
          editting={editting}
          workingHours
        />
      </Grid>
    </>
  )
}

WorkingHours.propTypes = {
  classes: PropTypes.object,
  editting: PropTypes.bool,
  showServiceHours: PropTypes.bool,
  setShowServiceHours: PropTypes.func,
  serviceHours: PropTypes.object,
  setServiceHours: PropTypes.func,
  setIsServiceHoursChanged: PropTypes.func,
}

export default WorkingHours
