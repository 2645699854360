import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { initialQuery } from '../../../../constants'
import { searchActions } from '../../../../actions'
import Fabs from './common/fabs'

const Distance = () => {
  const dispatch = useDispatch()

  const resetDistance = () => dispatch(searchActions.removeDistanceSearchFilter())
  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)

  return (
    <>
      {searchData.distance && searchData.distance !== initialQuery.distance && (
        <Fabs text={`Distance ${searchData.distance} ml`} onClick={resetDistance} />
      )}
    </>
  )
}

export default Distance
