export const categoryConstants = {
  CREATE_REQUEST: 'CREATE_CATEGORY_REQUEST',
  CREATE_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_FAILURE: 'CREATE_CATEGORY_FAILURE',

  UPDATE_REQUEST: 'UPDATE_CATEGORY_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_CATEGORY_FAILURE',

  GET_REQUEST: 'GET_CATEGORY_REQUEST',
  GET_SUCCESS: 'GET_CATEGORY_SUCCESS',
  GET_FAILURE: 'GET_CATEGORY_FAILURE',

  GETALL_REQUEST: 'GETALL_CATEGORY_REQUEST',
  GETALL_SUCCESS: 'GETALL_CATEGORY_SUCCESS',
  GETALL_FAILURE: 'GETALL_CATEGORY_FAILURE',
}
