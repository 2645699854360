import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { firstMenuStoreView, storeInfoTabs } from '../../../../../../helpers/merchant/drawer-menu'
import { useMerchantHomeContext } from '../../../../../../context/merchant/MerchantHome/main'

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(105, 231, 129, 0.3)',
    },
  },
  active: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  menuText: {
    color: '#292929',
    fontWeight: 300,
    fontSize: 19,
  },
  inCollapse: {
    paddingLeft: theme.spacing(6),
  },
}))

const FirstMenuSection = ({ intl }) => {
  const classes = useStyles()
  const {
    values: { firstActiveMenu, expand },
    setters: { listItemHandleClick },
  } = useMerchantHomeContext()
  const { formatMessage } = intl

  const detailArr = ['store-info', 'store-details']

  return (
    <List>
      {firstMenuStoreView.map((menu, index) =>
        menu.inCollapse ? (
          <Collapse in={expand} key={index}>
            <ListItem
              button
              // key={menu.name}
              className={clsx({
                [classes.listItem]: true,
                [classes.inCollapse]: menu.inCollapse,
                [classes.active]:
                  menu.name === 'store-details' ? detailArr.includes(firstActiveMenu) : firstActiveMenu === menu.name,
              })}
              onClick={() => listItemHandleClick(menu.name)}
            >
              <ListItemIcon>
                <img src={menu.image} height='22' width='22' alt={menu.name} />
              </ListItemIcon>
              <ListItemText className={classes.menuText} primary={formatMessage({ id: menu.label })} />
            </ListItem>
          </Collapse>
        ) : (
          <ListItem
            button
            key={menu.name}
            className={clsx({
              [classes.listItem]: true,
              [classes.active]:
                menu.name === 'store-info' ? storeInfoTabs.includes(firstActiveMenu) : firstActiveMenu === menu.name,
            })}
            onClick={() => listItemHandleClick(menu.name)}
          >
            <ListItemIcon>
              <img src={menu.image} height='22' width='22' alt={menu.name} />
            </ListItemIcon>
            <ListItemText className={classes.menuText} primary={formatMessage({ id: menu.label })} />
            {menu.name === 'store-info' && (
              <ListItemIcon>{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</ListItemIcon>
            )}
          </ListItem>
        )
      )}
    </List>
  )
}

FirstMenuSection.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(FirstMenuSection)
