import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import TopNavbar from '../top_navbar'
import Footer from '../Footers'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { alertActions } from '../../../actions'
import { generalConstants } from '../../../constants'
import trackOrder from '../../../images/track-order.svg'
import IconButton from '@material-ui/core/IconButton'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Input from '@material-ui/core/Input'
import clsx from 'clsx'

const columnFlex = {
  display: 'flex',
  flexDirection: 'column',
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
  },
  full: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
    minHeight: generalConstants.minPageHeight,
  },
  grid: {
    ...columnFlex,
    alignItems: 'space-evenly',
  },

  headerContainer: {
    ...columnFlex,
    minHeight: 160,
    width: '100%',
    backgroundColor: '#f7f7f7',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 34,
    textAlign: 'left',
    lineHeight: 1.32,
    color: '#333333',
  },
  imageContainer: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
  },
  upperImage: {
    height: 250,
    width: '100%',
  },
  centeredContainer: {
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  centeredRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },

  centeredText: {
    textAlign: 'center',
    paddingTop: theme.spacing(),
    lineHeight: 1.6,
  },
  title: {
    fontSize: 34,
  },
  subText: {
    fontSize: 20,
  },
  grayContainer: {
    backgroundColor: '#e6e1de',
    height: 60,
    borderRadius: 5,
    width: '75%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  input: {
    fontSize: 20,
    width: '100%',
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
}))

const WebInviteFriends = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const textAreaRef = useRef(null)

  const reference = useSelector(({ customer }) => customer.customerInfo.referenceCode)

  const handleCopyToClipboard = (e) => {
    const copiedText = textAreaRef.current.value
    navigator.clipboard.writeText(copiedText)
    dispatch(alertActions.success('Copied successfully'))
  }

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <TopNavbar cartVisible={true} />
      </Container>
      <div className={classes.headerContainer}>
        <Container maxWidth='lg' className={classes.header}>
          <Typography className={classes.headerText}>
            <FormattedMessage id='invite-friends' />
          </Typography>
        </Container>
      </div>
      <Container maxWidth='lg' className={classes.full}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} className={classes.imageContainer}>
            <img src={trackOrder} alt='invite-friend' className={classes.upperImage} />
          </Grid>
          <Grid item xs={12} className={classes.centeredContainer}>
            <Typography variant='h5' className={clsx(classes.centeredText, classes.bold, classes.title)}>
              <FormattedMessage id='invite-your-friend' />
            </Typography>
            <Typography variant='body1' className={clsx(classes.centeredText, classes.subText)}>
              <FormattedMessage id='lorem-ipsum' />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.centeredRowContainer}>
            <Grid item className={classes.grayContainer}>
              <IconButton size='medium' className={classes.iconButton} onClick={handleCopyToClipboard}>
                <FileCopyOutlinedIcon fontSize='large' />
              </IconButton>
              <Grid item style={{ width: '80%' }}>
                <Input
                  inputRef={textAreaRef}
                  inputProps={{
                    className: classes.input,
                  }}
                  disableUnderline
                  fullWidth
                  defaultValue={`http://tookfresh.com/customer/signup/${reference}`}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div className='grow' />
      <Footer />
    </div>
  )
}

export default WebInviteFriends
