/* eslint-disable react/prop-types */
import React, { useState, useEffect, useImperativeHandle } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useRouter from '../../../helpers/useRouter'
import { makeStyles } from '@material-ui/core/styles'
import { orderActions, customerActions } from '../../../actions'
import { orderService } from '../../../services'
import { customerService } from '../../../services/customer.service'
import { generalConstants } from '../../../constants'
import Loading from '../../common/loading'
import LeftPart from './LeftPart/LeftPart'
import RightPart from './RightPart/RightPart'
import PlaceOrderButton from './RightPart/PlaceOrderButton'
import WarnBox from './RightPart/WarnBox'
import { showWarnBox } from '../../../helpers/cart/showWarnBox'
import { createNewOrder } from '../../../helpers/cart/create-new-order'
import DoneDialog from './mobile-cart/DoneDialog'
import { CardElement } from '@stripe/react-stripe-js'
import { createRequestBody } from '../../../helpers/order-menu/request-body'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%', //560
    width: '100%', //900
    display: 'flex',
    minHeight: 200,
    overflow: 'auto',
    "&[tablet-view='true']": {
      height: 'auto',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingBottom: 30,
    },
    "&[tablet-view='false']": {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  subContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    "&[is-tablet='true']": {
      flexDirection: 'column-reverse',
    },
  },
  footer: {
    position: 'absolute',
    bottom: 15,
    left: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

const Cart = (props, ref) => {
  const { stripe, elements, closeBasket, openAddPhoneDialog, openVerifyDialog, tabletView, showBasket } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { history } = useRouter()

  const defaultCard = useSelector(({ customer }) => customer.defaultCard)
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const customerInfo = useSelector(({ customer }) => customer.customerInfo, shallowEqual)
  const query = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const orderMenus = useSelector(({ orders }) => orders.cart.orderMenus, shallowEqual)
  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)
  const order = useSelector(({ orders }) => orders.savedOrder, shallowEqual)
  const store = useSelector(({ stores }) => stores.customerCurrentStore, shallowEqual)
  const savedOrderId = useSelector(({ orders }) => orders?.savedOrder?.id)
  const orderPayments = useSelector(({ orders }) => orders?.savedOrder?.orderPayments)

  const removeMenu = (index) => dispatch(orderActions.removeMenuFromOrder(index))
  const setOrderAddress = (data) => dispatch(orderActions.setOrderAddress(data))
  const selectMenuQuantity = (store, order) => dispatch(orderActions.selectMenuQuantity(store, order))
  const clearCart = () => dispatch(orderActions.clearCart())
  const deleteOrder = (id) => dispatch(orderActions.deleteOrder(id))
  const clearMenus = () => dispatch(orderActions.clearMenus())
  const defaultCardChange = (card) => dispatch(customerActions.defaultCardChange(card))
  const setOrderFees = async (data) => dispatch(orderActions.setOrderFees(data))
  const clearOrderFees = () => dispatch(orderActions.clearOrderFees())
  const placeOrder = async (cart) => dispatch(orderActions.saveOrder(cart))

  const [selectedCard, setSelectedCard] = useState(defaultCard)
  const [elementCard, setElementCard] = useState(null)
  const [showDoneDialog, setShowDoneDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Card')
  const [cards, setCards] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [tipAmount, setTipAmount] = useState({ value: 0, rate: 0, isCustom: false })
  const [clientSecret, setClientSecret] = useState(null)

  useEffect(() => {
    showBasket && previewOrder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(orderMenus)])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      if (orderMenus?.length > 0) {
        signedIn && (await getPaymentMethods())
      } else {
        onClose()
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    signedIn && getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn])

  useEffect(() => {
    if (query.address) {
      setOrderAddress(query.address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query.address)])

  const onClose = () => {
    clearOrderFees()
    closeBasket()
  }

  useImperativeHandle(ref, () => ({
    onClose: () => {
      onClose()
    },
  }))

  const previewOrder = async () => {
    setLoading(true)
    const previewQuery = createRequestBody(query, customerInfo, cart)
    await setOrderFees(previewQuery)
    setLoading(false)
  }

  const getPaymentMethods = async () => {
    await customerService.getPaymentMethods().then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setCards(res.data.paymentMethods)
          setSelectedCard(res.data.defaultPaymentMethod)
        }
      },
      (err) => {
        console.log('err--', err)
      }
    )
    setLoading(false)
  }

  const handlePhoneCheck = (e) => {
    e.preventDefault()
    if (!cart?.distanceSuccess && !cart?.senpexSuccess) return
    setLoading(true)
    const { phone, phoneVerified } = customerInfo
    if (phone) {
      if (phoneVerified) {
        saveOrder()
      } else {
        onClose()
        setTimeout(openVerifyDialog(), 500)
      }
    } else {
      onClose()
      setTimeout(openAddPhoneDialog(), 500)
    }
  }

  const checkPaymentType = () => {
    const paymentTypes = orderPayments?.map((payment) => payment.type.name)
    return paymentTypes?.includes('STRIPE')
  }
  const isStripePayment = checkPaymentType()

  useEffect(() => {
    if (!isStripePayment && savedOrderId) {
      confirmPayment()
      return
    } else if (isStripePayment && savedOrderId) {
      paymentIntent()
    }
  }, [savedOrderId, isStripePayment])

  useEffect(() => {
    savedOrderId && clientSecret && handlePlaceOrder()
  }, [clientSecret])

  const saveOrder = async () => {
    const previewQuery = createRequestBody(query, customerInfo, cart)
    const queryWithTip = { ...previewQuery, driverTipPrice: tipAmount?.value }
    await placeOrder(queryWithTip)
  }

  const paymentIntent = async () => {
    setLoading(true)
    await orderService
      .createPaymentIntent(savedOrderId)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setClientSecret(res.data.id)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    setLoading(false)
  }

  const handlePaymentMethod = async (id) => {
    setLoading(true)
    await customerService.defaultPaymentMethod(id).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setSelectedCard(id)
          setLoading(false)
          defaultCardChange(id)
        }
      },
      (err) => {
        console.log('err--', err)
      }
    )
  }

  const paymentMethodHandler = (data) => {
    setSelectedPaymentMethod(data)
  }

  const handleAddItem = () => {
    history.push(`/customer/store/${cart?.storeId}`)
    onClose()
  }

  const getCardElement = (data) => {
    setElementCard(data)
    setSelectedCard(null)
  }

  const selectCard = () => {
    setElementCard(null)
    if (cards.length === 0) {
      setSelectedCard(null)
    } else {
      setSelectedCard(defaultCard)
    }
  }

  const confirmPayment = async () => {
    await orderService
      .paymentConfirmation(savedOrderId)
      .then((res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setShowDoneDialog(true)
        } else {
          console.log(res.error)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setErrorMessage(error.response?.data?.error_message.toString())
      })
  }

  const handlePlaceOrder = async () => {
    // event.preventDefault()
    setLoading(true)
    if (signedIn) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      let paymentMethod
      const cardElement = elements.getElement(CardElement)
      cardElement ? (paymentMethod = { card: cardElement }) : (paymentMethod = selectedCard)
      try {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod,
        })
        if (result.paymentIntent.status === 'succeeded') {
          confirmPayment()
        }
      } catch (error) {
        setLoading(false)
        // setErrorMessage(error.message)
        // console.log(error.message)
        setErrorMessage("We're sorry, but something went wrong. Please try again.")
      }
    } else {
      setLoading(false)
      history.push(`${path}/signin`, 'fromCart')
    }
  }

  const proceedToOrders = () => {
    clearCart()
    setShowDoneDialog(false)
    history.push(`${path}/customer/orders`)
  }

  const handleQuantity = (e, index) => {
    const { newOrder } = createNewOrder(e, index, cart)
    selectMenuQuantity(store, newOrder)
  }

  const deleteMenuItem = (index) => {
    removeMenu(index)
    if (orderMenus.length === 1) {
      clearMenus()
      clearOrderFees()
      onClose()
      if (order?.id) {
        deleteOrder(order.id)
        onClose()
      }
    }
  }

  const handleTip = (value) => {
    // This is to handle tip buttons
    let tipQuery = { isCustom: false }
    if (typeof value === 'string') {
      if (tipAmount.isCustom) {
        ;[tipQuery.value, tipQuery.rate, tipQuery.isCustom] = [0, 0, false]
      } else {
        ;[tipQuery.value, tipQuery.rate, tipQuery.isCustom] = [0, 0, true]
      }
    } else {
      if (tipAmount.rate === value) {
        ;[tipQuery.value, tipQuery.rate] = [0, 0]
      } else {
        tipQuery.value = +(cart?.finalSubtotalPrice * value).toFixed(2)
        tipQuery.rate = value
      }
    }
    setTipAmount(tipQuery)
  }

  const handleCustomTip = (e) => {
    // This is to handle custom tip input
    setTipAmount({ ...tipAmount, value: e.target.value })
  }

  const clearCustomTip = () => setTipAmount({ ...tipAmount, value: 0 })

  let itemCount = cart.orderMenus.reduce((total, menuItem) => total + menuItem.quantity, 0)

  const warnActive = showWarnBox(cart)
  const paymentError = errorMessage ? 'error' : 'notError'
  const paymentMethodWarning =
    ((!elementCard && !selectedCard) || selectedPaymentMethod !== 'Card') && signedIn ? 'show' : 'hide'
  const showWarnBoxContainer = warnActive === 'visible' || paymentMethodWarning === 'show' || errorMessage

  return (
    <div className={classes.root} tablet-view={(!!tabletView).toString()}>
      <Loading open={loading} />
      {/* {tabletView && <HeaderTablet />} */}
      <div className={classes.subContainer} is-tablet={(!!tabletView).toString()}>
        <LeftPart
          selectCard={selectCard}
          handlePaymentMethod={handlePaymentMethod}
          selectedCard={selectedCard}
          cards={cards}
          getCardElement={getCardElement}
          elements={elements}
          paymentMethodHandler={paymentMethodHandler}
          tabletView={tabletView}
          handleTip={handleTip}
          handleCustomTip={handleCustomTip}
          clearCustomTip={clearCustomTip}
          cost={cart?.subTotal}
          tip={tipAmount}
        />
        <RightPart
          itemCount={itemCount}
          handleAddItem={handleAddItem}
          cart={cart}
          handleQuantity={handleQuantity}
          deleteMenuItem={deleteMenuItem}
          cardElement={elementCard}
          selectedPaymentMethod={selectedPaymentMethod}
          selectedCard={selectedCard}
          signedIn={signedIn}
          handlePhoneCheck={handlePhoneCheck}
          errorMessage={errorMessage}
          tabletView={tabletView}
          warnActive={warnActive}
          paymentError={paymentError}
          paymentMethodWarning={paymentMethodWarning}
          showWarnBoxContainer={showWarnBoxContainer}
          loading={loading}
          tip={tipAmount}
        />
        {tabletView && (
          <div className={classes.footer}>
            <PlaceOrderButton
              cardElement={elementCard}
              selectedCard={selectedCard}
              selectedPaymentMethod={selectedPaymentMethod}
              signedIn={signedIn}
              handlePhoneCheck={handlePhoneCheck}
              cart={cart}
              tipAmount={tipAmount}
            />
          </div>
        )}
      </div>
      {showWarnBoxContainer && tabletView && (
        <WarnBox
          tabletView={tabletView}
          warnActive={warnActive}
          paymentMethodWarning={paymentMethodWarning}
          errorMessage={errorMessage}
          paymentError={paymentError}
        />
      )}

      <DoneDialog showDoneDialog={showDoneDialog} proceedToOrders={proceedToOrders} />
    </div>
  )
}

export default React.forwardRef(Cart)
