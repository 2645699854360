import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import PhoneSelection from '../common/PhoneSelection'
import EmailSelection from '../common/EmailSelection'
import PaymentContactName from '../common/PaymentContactName'

import { valueConstants, notificationConstants } from '../../../businessRegister/businessConstants'

import { bankinfoFocusedState } from '../../../../constants/bank-info/states'

import { splitPhoneNo } from '../../../../helpers/merchant/splitPhoneNo'

import { isPaymentContactInfoMissing } from '../../../../helpers/merchant/edit-store'

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...flexColumn,
    width: '100%',
  },
  gridContainer: {
    marginTop: theme.spacing(0),
  },
  gridItem: {
    ...flexColumn,
    alignItems: 'flex-start',
  },
  label: {
    maxWidth: 150,
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  bankInfoText: {
    width: '100%',
    height: '100%',
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const PaymentContactInfo = ({
  bankInfo,
  setBankInfo,
  editting,
  storeInformation,
  paymentContactCode,
  paymentContactPhone,
  setPaymentContactCode,
  setPaymentContactPhone,
  bankInfoInvalid,
  setBankInfoInvalid,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const [phoneVal, setPhoneVal] = useState('')
  const [emailVal, setEmailVal] = useState('')
  const [isFocused, setIsFocused] = useState(bankinfoFocusedState)
  const [missingInfo, setMissingInfo] = useState(false)

  useEffect(() => {
    let active = true
    if (active) {
      let isMissing = isPaymentContactInfoMissing(bankInfo)
      setMissingInfo(isMissing)
      let [codePart, phonePart] = splitPhoneNo(storeInformation.phone)
      bankInfo.paymentContactEmail === storeInformation.email
        ? setEmailVal(notificationConstants.storeEmail)
        : setEmailVal(notificationConstants.otherEmail)
      paymentContactPhone === phonePart
        ? setPhoneVal(notificationConstants.storePhone)
        : setPhoneVal(notificationConstants.otherPhone)
    }
    return () => {
      active = false
    }
  }, [bankInfo, storeInformation])

  const selectPhone = (e) => {
    let val = e.target.value
    setBankInfoInvalid({
      ...bankInfoInvalid,
      paymentContactPhone: false,
    })
    setPhoneVal(val)
    handlePhone(val)
  }

  const selectEmail = (e) => {
    let val = e.target.value
    setEmailVal(val)
    handleEmail(val)
  }

  const handleEmail = (value) => {
    if (value === notificationConstants.storeEmail) {
      setBankInfo({
        ...bankInfo,
        paymentContactEmail: storeInformation.email,
      })
    } else {
      setBankInfo({
        ...bankInfo,
        paymentContactEmail: '',
      })
    }
  }

  const handlePhone = (value) => {
    if (value === notificationConstants.storePhone) {
      let [codePart, phonePart] = splitPhoneNo(storeInformation.phone)
      setPaymentContactCode(codePart)
      setPaymentContactPhone(phonePart)
    } else {
      setPaymentContactPhone('')
      setPaymentContactCode('+1')
    }
  }

  const contactNameHandler = (e) => {
    setIsFocused({ ...isFocused, paymentContactName: true })
    setBankInfoInvalid({ ...bankInfoInvalid, paymentContactName: !e.target.value })
    setBankInfo({ ...bankInfo, paymentContactName: e.target.value })
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant='h5'>{f({ id: 'payment-contact-info-label' })}</Typography>
      </div>
      <Grid container spacing={2} className={clsx('full-width', [classes.gridContainer])}>
        <PhoneSelection
          classes={classes}
          orderConfirmation={phoneVal}
          handleCheck={selectPhone}
          orderConfirmCode={paymentContactCode}
          orderConfirmPhone={paymentContactPhone}
          editting={editting}
          setOrderConfirmCode={setPaymentContactCode}
          setOrderConfirmPhone={setPaymentContactPhone}
          isFocused={isFocused}
          isInvalid={bankInfoInvalid}
          setIsInvalid={setBankInfoInvalid}
          setIsFocused={setIsFocused}
          valProp={valueConstants.paymentContactPhone}
        />
        <EmailSelection
          classes={classes}
          orderConfirmation={emailVal}
          handleCheck={selectEmail}
          store={bankInfo}
          setStore={setBankInfo}
          setIsInvalid={setBankInfoInvalid}
          setIsFocused={setIsFocused}
          isFocused={isFocused}
          isInvalid={bankInfoInvalid}
          valProp={valueConstants.paymentContactEmail}
          editting={editting}
        />
      </Grid>
      <PaymentContactName
        bankInfo={bankInfo}
        isFocused={isFocused}
        isInvalid={bankInfoInvalid}
        contactNameHandler={contactNameHandler}
        editting={editting}
      />
      {missingInfo && !editting && (
        <div className={classes.bankInfoText}>
          <Typography variant='h5' color='secondary'>
            {f({ id: 'select-contact-info' })}
          </Typography>
        </div>
      )}
    </div>
  )
}

PaymentContactInfo.propTypes = {
  bankInfo: PropTypes.object,
  setBankInfo: PropTypes.func,
  editting: PropTypes.bool,
  storeInformation: PropTypes.object,
  paymentContactCode: PropTypes.string,
  paymentContactPhone: PropTypes.string,
  setPaymentContactCode: PropTypes.func,
  setPaymentContactPhone: PropTypes.func,
  bankInfoInvalid: PropTypes.object,
  setBankInfoInvalid: PropTypes.func,
}

export default PaymentContactInfo
