import React, { useState } from 'react'
import { customerActions } from '../../../../actions'
import Typography from '@material-ui/core/Typography'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import backIcon from '../../../../images/black-back.svg'
import clsx from 'clsx'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { GreenButton } from '../../../utils/tools'
import SearchAddress from '../../../utils/address_search'
import addressSearchIcon from '../../../../images/address-search.svg'
import CloseIcon from '@material-ui/icons/Close'
import { parseGoogleAPIAddress } from '../../../utils/common'
import { handleAddressName } from '../../../../helpers/address-header'
import { isSavedBefore } from '../../../../helpers/address/checkIfAddressSavedBefore'

import { makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useToggle } from '../../../../hooks/useToggle'
import { TextInput } from './add-address/TextInput'
import DeliveryType from '../../../common/addressPopup/AddresSavePopover/DeliveryType'
import { deliveryTypes, deliverySubTypes } from '../../../../constants'

const height = window.innerHeight

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: height,
    maxHeight: height,
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  add: {
    marginTop: theme.spacing(3),
  },
  address: {
    marginTop: theme.spacing(),
    borderRadius: 4,
    border: '1px solid rgb(195, 195, 195)',
    height: 39,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  deliveryTypeContainer: {
    marginTop: theme.spacing(3),
  },
}))

export const AddAddress = ({ intl, handleClose }) => {
  const classes = useStyles()
  //Global states
  const addresses = useSelector(({ customer }) => customer.savedAddresses)

  //Local states
  const [name, setName] = useState('')
  const [nameInvalid, setNameInvalid] = useState(false)
  const [isDefault, setIsDefault] = useToggle()
  const [address, setAddress] = useState(null)
  const [aptSuite, setAptSuite] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [deliveryType, setDeliveryType] = useState(deliveryTypes.DELIVERY)
  const [subType, setSubType] = useState(deliverySubTypes.DELIVER_DOOR)
  const [instruction, setInstruction] = useState('')
  const [savedBefore, setSavedBefore] = useState(false)
  const [savedAddress, setSavedAddress] = useState(null)

  //actions
  const dispatch = useDispatch()
  const addAddress = (addr) => dispatch(customerActions.createAddress(addr))
  const updateAddress = (id, addr) => dispatch(customerActions.updateAddress(id, addr))

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleDeliveryAddress = (newAddress) => {
    let parsedAddress = parseGoogleAPIAddress(newAddress)
    let [savedBefore_, savedAddress_] = isSavedBefore(addresses, parsedAddress.formattedAddress)
    setSavedBefore(savedBefore_)
    setSavedAddress(savedAddress_)
    setAddress(parsedAddress)
  }

  const handleAddAddress = () => {
    let newAddress = address
    newAddress.addressLine2 = aptSuite
    newAddress.default_ = isDefault
    newAddress.name = name ? name : handleAddressName(addresses)
    newAddress.deliveryOption = subType
    newAddress.deliveryInstruction = instruction
    savedBefore ? updateAddress(savedAddress.id, newAddress) : addAddress(newAddress)
    // addAddress(newAddress)
    if (handleClose) handleClose()
  }

  const onNameChange = (e) => {
    setNameInvalid(!name)
    setSubmitted((prev) => !prev)
    setName(e.target.value)
  }

  const aptSuiteChange = (e) => {
    setAptSuite(e.target.value)
  }

  const handleSwitch = (e) => {
    if (e.target.checked) {
      setDeliveryType(deliveryTypes.TAKEOUT)
      setSubType('')
      setInstruction('')
    } else {
      setDeliveryType(deliveryTypes.DELIVERY)
      setSubType(deliverySubTypes.DELIVER_DOOR)
    }
  }

  const handleSubType = (prop) => setSubType(prop)
  const handleInstruction = (e) => setInstruction(e.target.value)
  const clearInstruction = () => setInstruction('')

  return (
    <div className={classes.root}>
      <div className='mobile-top-bar'>
        <IconButton size='small' className={classes.back} onClick={handleClose}>
          <img src={backIcon} alt='Back' />
        </IconButton>
        <Typography variant='h6'>{getLabel('add-address')}</Typography>
      </div>
      <div className={clsx('column grow full-width', [classes.content])}>
        <TextInput
          name
          onNameChange={onNameChange}
          value={name}
          submitted={submitted}
          nameInvalid={nameInvalid}
          label='name-label'
        />
        <div className={classes.address}>
          <SearchAddress
            outlined={true}
            value={address ? address.formattedAddress : ''}
            startAdornment={addressSearchIcon}
            placeholder={getLabel('search-address')}
            onChange={handleDeliveryAddress}
            typesRef={['address']}
          />
          {address && (
            <IconButton size='small' aria-label='del' onClick={() => setAddress(null)}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <TextInput value={aptSuite} aptSuiteChange={aptSuiteChange} label='apt-suite' />
        <div className={classes.deliveryTypeContainer}>
          <DeliveryType
            handleSwitch={handleSwitch}
            deliveryType={deliveryType}
            handleSubType={handleSubType}
            subType={subType}
            instruction={instruction}
            handleInstruction={handleInstruction}
            clearInstruction={clearInstruction}
            setDeliveryType={setDeliveryType}
            mobileAddressSave
          />
        </div>
        <FormControlLabel
          control={<Checkbox checked={isDefault} onChange={setIsDefault} name='checkedDefault' color='primary' />}
          label={getLabel('set-as-default')}
        />
        <GreenButton fullWidth disabled={!address} className={classes.add} onClick={handleAddAddress}>
          {getLabel('add-label')}
        </GreenButton>
      </div>
    </div>
  )
}

AddAddress.propTypes = {
  handleClose: PropTypes.func,
}

export default injectIntl(AddAddress)
