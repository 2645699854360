import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Button, Grid, makeStyles } from '@material-ui/core'
import yesIcon from '../../../../../images/yes.svg'
import clsx from 'clsx'
import { deliverySubTypes, deliveryTypes } from '../../../../../constants'

const useStyles = makeStyles((theme) => ({
  stepTwoRow: {
    borderBottom: '1px solid #f7f7f7',
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  stepTwoSubRow: {
    borderBottom: '1px solid #f7f7f7',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  stepTwoIcon: {
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
    marginRight: theme.spacing(2),
  },
  button: {
    fontSize: 15,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(198, 198, 198, 0.87)',
    textTransform: 'capitalize',
  },
  selectedButton: {
    color: 'rgba(112, 112, 112, 0.87)',
  },
}))

const DeliverySubList = ({ intl, item, details, setDetails }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const deliverySubTypeHandler = (text) => {
    if (details.deliveryType === deliveryTypes.DELIVERY) {
      setDetails({ ...details, deliverySubType: deliverySubTypes[text] })
    }
  }

  return (
    <Grid item xs={12} className={classes.stepTwoSubRow}>
      <img
        className={classes.stepTwoIcon}
        src={details.deliverySubType === deliverySubTypes[`${item.subType}`] ? item.iconSelected : item.icon}
        alt='where'
        height='20'
        width='20'
      />
      <div className='centered-row full-width' onClick={() => deliverySubTypeHandler(`${item.subType}`)}>
        <Button
          disabled={details.deliveryType !== deliveryTypes.DELIVERY}
          className={clsx({
            [classes.button]: true,
            [classes.selectedButton]: details.deliverySubType === deliverySubTypes[`${item.subType}`],
          })}
          onClick={() => deliverySubTypeHandler(`${item.subType}`)}
        >
          {getLabel(`${item.label}`)}
        </Button>
        <div className='grow' />
        {details.deliverySubType === deliverySubTypes[`${item.subType}`] && (
          <img className={classes.stepTwoIcon} src={yesIcon} alt='where' height='16' width='16' />
        )}
      </div>
    </Grid>
  )
}

DeliverySubList.propTypes = {
  item: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  setDetails: PropTypes.func.isRequired,
}

export default injectIntl(DeliverySubList)
