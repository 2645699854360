import React, { useEffect, useMemo, useState } from 'react'
import Container from '@material-ui/core/Container'
import { orderStatuses } from '../../../../../constants'
import placeholder from '../../../../../images/image-placeholder.svg'
import { makeStyles } from '@material-ui/core'
import { getImage } from '../../../../../helpers/get-image'
import background from '../../../../../images/restaurant-back.jpeg'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import ImageContainer from './ImageContainer'
import OrderHeaderContainer from './OrderHeaderContainer'
import MenuPriceContainer from './MenuPriceContainer'

const hideReceipt = [orderStatuses.CANCELED, orderStatuses.ORDER_REJECTED]

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '250px',
  },
  contentContainer: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 250,
    height: 'auto',
    padding: 0,
    borderBottom: 'solid 1px #ededed',
  },
  subContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 0,
    marginTop: 25,
    marginBottom: 25,
  },
  rightPartContainer: {
    width: '100%',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}))

const WebOrdersMain = ({ order, getOrders, previous, openCancelDialog, storeDetails, handleOrderReceipt, rate }) => {
  const history = useHistory()
  const classes = useStyles()

  const signedIn = useSelector(({ customer }) => customer.signedIn)

  const [selectedIndex, setSelectedIndex] = useState('')

  useEffect(() => {
    if (!signedIn) {
      history.push(`${path}/customer/signin`)
    }
  }, [signedIn])

  const handleCollapse = (i) => {
    setSelectedIndex(selectedIndex === i ? '' : i)
  }

  const restImage = useMemo(
    () => getImage(order?.restaurant?.background, 'medium') || background,
    [order?.restaurant?.background?.id]
  )
  const restLogo = useMemo(
    () => getImage(order?.restaurant?.logo, 'medium') || placeholder,
    [order?.restaurant?.logo?.id]
  )

  const showReceiptCondition = useMemo(() => !hideReceipt?.includes(order?.status?.name), [order?.status?.name])

  return (
    <div className={classes.root}>
      <Container maxWidth='md' className={classes.contentContainer}>
        <Container className={classes.subContainer}>
          <ImageContainer restImage={restImage} restLogo={restLogo} order={order} />
          <div className={classes.rightPartContainer}>
            <OrderHeaderContainer
              previous={previous}
              order={order}
              openCancelDialog={openCancelDialog}
              storeDetails={storeDetails}
              getOrders={getOrders}
              rate={rate}
            />
            <MenuPriceContainer
              order={order}
              handleCollapse={handleCollapse}
              selectedIndex={selectedIndex}
              showReceiptCondition={showReceiptCondition}
              handleOrderReceipt={handleOrderReceipt}
            />
          </div>
        </Container>
      </Container>
    </div>
  )
}

export default WebOrdersMain
