import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: -0.24,
    color: '#121212',
    marginBottom: theme.spacing(),
  },
  text: {
    fontSize: 14,
    fontWeight: 300,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
  },
}))

const EmtpyCart = ({ title, subTitle }) => {
  const classes = useStyles()
  return (
    <Box
      mt={1}
      pb={1}
      pr={1}
      pl={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      style={{ borderBottom: '1px solid #f4f4f4' }}
    >
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.text}>{subTitle}</Typography>
    </Box>
  )
}

EmtpyCart.propTypes = {
  title: PropTypes.string,
}

export default React.memo(EmtpyCart)
