import { generalConstants } from '../constants'

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(generalConstants.TOOKFRESH)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(generalConstants.TOOKFRESH, serializedState)
  } catch (err) {}
}
