import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import { useIntl } from 'react-intl'

const MinHeadCount = ({ value, handleChange, isFocused, isInvalid, editting }) => {
  const { formatMessage: f } = useIntl()

  return (
    <Grid item md={6} sm={6} xs={12}>
      <TextField
        id='minHeadCount'
        variant='outlined'
        size='small'
        type='number'
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', readOnly: !editting }}
        fullWidth
        required
        value={value}
        onChange={handleChange}
        label={f({ id: 'min-head-count' })}
        error={isFocused.minHeadCount && isInvalid.minHeadCount}
        name='minHeadCount'
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
  )
}

export default MinHeadCount
