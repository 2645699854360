import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import noResponse from '../../images/no-response-received.svg'
import { isMobile } from 'react-device-detect'
// import classes from '*.module.css'

const styles = (theme) => ({
  // root: {
  //   width: '100%',
  //   display: 'flex',
  //   [theme.breakpoints.down('md')]: {
  //     flexDirection: 'column',
  //   },
  // },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 350,
    paddingTop: 100,
  },
})

const NoMatch = ({ classes, intl }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const imageForDeviceWidth = () => {
    let img = null
    if (isMobile) {
      img = <img src={noResponse} alt='no-response' width='300px' height='200px' />
    } else {
      img = <img src={noResponse} alt='no-response' width='500px' height='400px' />
    }
    return img
  }

  return (
    <div className={classes.root}>
      {imageForDeviceWidth()}
      <p style={{ textAlign: 'center', marginTop: 30 }}>
        <Link
          to='/'
          style={{
            textDecoration: 'none',
            color: '#69e781',
            fontSize: 25,
            fontWeight: 500,
          }}
        >
          {getLabel('go-home')}
        </Link>
      </p>
    </div>
  )
}

export default withStyles(styles)(withRouter(injectIntl(NoMatch)))
