import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { injectIntl } from 'react-intl'

export const TextFields = ({ intl, value, label, name, submitted, nameInvalid, onNameChange, aptSuiteChange }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <TextField
      size='small'
      variant='outlined'
      margin='normal'
      fullWidth
      placeholder={getLabel(label)}
      value={value}
      onChange={(e) => (name ? onNameChange(e) : aptSuiteChange(e))}
      error={submitted && nameInvalid}
    />
  )
}

TextFields.propTypes = {
  value: PropTypes.string,
  name: PropTypes.bool,
  submitted: PropTypes.bool,
  nameInvalid: PropTypes.bool,
  label: PropTypes.string,
}

const TextInput = injectIntl(TextFields)

export { TextInput }
