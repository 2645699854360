import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  addButtonView: {
    textTransform: 'none',
  },
}))

const SectionHeader = ({ intl, header, callback }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Box display='flex' className='centered-column' flexDirection='row' justifyContent='space-between'>
      <Box width='70%'>
        <Typography variant='h6'>{getLabel(header)}</Typography>
      </Box>
      <Box width='30%' style={{ textAlign: 'center' }}>
        <Button
          variant='outlined'
          classes={{ label: classes.addButtonView }}
          onClick={callback}
          disabled={callback ? false : true}
          color='primary'
          style={{ borderRadius: 20 }}
        >
          <Typography variant='h6' color='primary'>
            {getLabel('change-label')}
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

SectionHeader.propTypes = {
  header: PropTypes.string,
  callback: PropTypes.func,
  intl: PropTypes.object,
}

export default injectIntl(SectionHeader)
