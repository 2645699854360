import { categoryConstants } from '../constants'
import { categoryService } from '../services'
import { alertActions } from './alert.actions'

export const categoryActions = {
  create,
  getAll,
  get,
  update,
}

function create(name) {
  return (dispatch) => {
    dispatch(request({ name }))

    categoryService.create(name).then(
      (category) => {
        dispatch(success(category))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(name) {
    return { type: categoryConstants.CREATE_REQUEST, name }
  }
  function success(category) {
    return { type: categoryConstants.CREATE_SUCCESS, category }
  }
  function failure(error) {
    return { type: categoryConstants.CREATE_FAILURE, error }
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request())

    categoryService.getAll().then(
      (categories) => {
        dispatch(success(categories))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request() {
    return { type: categoryConstants.GETALL_REQUEST }
  }
  function success(categories) {
    return { type: categoryConstants.GETALL_SUCCESS, categories }
  }
  function failure(error) {
    return { type: categoryConstants.GETALL_FAILURE, error }
  }
}

function get(id) {
  return (dispatch) => {
    dispatch(request({ id }))

    categoryService.get(id).then(
      (category) => {
        dispatch(success(category))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(id) {
    return { type: categoryConstants.GET_REQUEST, id }
  }
  function success(category) {
    return { type: categoryConstants.GET_SUCCESS, category }
  }
  function failure(error) {
    return { type: categoryConstants.GET_FAILURE, error }
  }
}

function update(id) {
  return (dispatch) => {
    dispatch(request({ id }))

    categoryService.update(id).then(
      (category) => {
        dispatch(success(category))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(id) {
    return { type: categoryConstants.UPDATE_REQUEST, id }
  }
  function success(category) {
    return { type: categoryConstants.UPDATE_SUCCESS, category }
  }
  function failure(error) {
    return { type: categoryConstants.UPDATE_FAILURE, error }
  }
}
