export const createNewOrder = (e, ind, cart) => {
  const menuItem = cart.orderMenus.find((_, i) => i === ind)
  const newMenuItem = {
    ...menuItem,
    quantity: parseInt(e.target.value),
  }
  const newOrder = cart.orderMenus
  newOrder[ind] = newMenuItem
  return { newOrder }
}
