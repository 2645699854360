import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { injectIntl } from 'react-intl'
import logoText from '../../images/logo_text_black.svg'
import Signup from './signup'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import backIcon from '../../images/black-back.svg'

const height = window.innerHeight

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
  back: {
    top: theme.spacing(),
    left: 0,
    position: 'absolute',
    color: '#000',
  },
  paper: {
    overflow: 'scroll',
    flex: '1 50 auto',
    padding: theme.spacing(2),
  },
  top: {
    padding: theme.spacing(2),
    position: 'relative',
  },
})

class MobileSignup extends React.Component {
  render() {
    const { classes, handleClose } = this.props

    return (
      <div className={classes.root}>
        <div className={clsx('centered-column', [classes.top])}>
          <IconButton
            color='inherit'
            className={clsx('mobile', [classes.back])}
            onClick={() => {
              handleClose ? handleClose() : this.props.history.goBack()
            }}
            aria-label='close'
          >
            <img src={backIcon} alt='Back' />
          </IconButton>
          <img src={logoText} alt='TookFresh' height='45' className={'mobile-logo'} />
        </div>
        <div className={classes.paper}>
          <Signup />
        </div>
      </div>
    )
  }
}

MobileSignup.propTypes = {
  handleClose: PropTypes.func,
}

export default withStyles(styles)(withRouter(injectIntl(MobileSignup)))
