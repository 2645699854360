import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination } from '@material-ui/core'
import { ordersHeader } from '../../../helpers/merchant/table-header'
import { deliveryTypes, PENDING_PAYMENT, rowsPerPageOptions } from '../../../constants'
import { ActionButtons } from './orders-table/ActionButtons'
import OrderDialog from './OrderDialog'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    margin: theme.spacing(0),
  },
  label: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {},
  cell: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    '&[data-clickable=true]': {
      cursor: 'pointer',
      color: '#69e781',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}))

const StoresTable = ({ query, handleChangePage, handleChangeRowsPerPage, orders, changeOrderStatus }) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()

  const [openDialog, setOpenDialog] = useState(false)
  const [target, setTarget] = useState(null)

  const onOpen = (e) => {
    setTarget(e.target.innerText)
    setOpenDialog(true)
  }

  const onClose = () => setOpenDialog(false)

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table className={classes.table} aria-labelledby='Stores' size='medium' aria-label='stores table'>
          <TableHead>
            <TableRow>
              {ordersHeader.map((header, i) => (
                <TableCell key={header.label} align='center'>
                  {format({ id: header.label })}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.content
              ?.filter(
                (order) => !(order.orderType === deliveryTypes.DELIVERY && order.status.label === PENDING_PAYMENT)
              )
              .map((order, i) => {
                const deliveryTime =
                  order.orderType === deliveryTypes.DELIVERY
                    ? moment(order.deliveryTime, 'HH:mm').subtract(1, 'hours').format('HH:mm')
                    : order.deliveryTime
                return (
                  <TableRow key={i} className={classes.row} hover>
                    <TableCell
                      className={classes.cell}
                      onClick={onOpen}
                      data-clickable={true}
                      align='center'
                      width={'10%'}
                    >
                      {order.orderNumber}
                    </TableCell>
                    <TableCell className={classes.cell} align='center' width={'10%'}>
                      {order.orderType}
                    </TableCell>
                    <TableCell className={classes.cell} align='center' width={'15%'}>
                      {order.deliveryDate}
                    </TableCell>
                    <TableCell className={classes.cell} align='center' width={'10%'}>
                      {deliveryTime}
                    </TableCell>
                    <TableCell className={classes.cell} align='center' width={'15%'}>
                      {`${order.customerFirstName} ${order.customerLastName.charAt(0)}`}
                    </TableCell>
                    <TableCell className={classes.cell} align='center' width={'15%'}>
                      {order.status.label}
                    </TableCell>
                    <TableCell className={classes.cell} align='center' width={'20%'}>
                      ${(+order.totalPrice)?.toFixed(2)}
                    </TableCell>
                    <TableCell className={classes.cell} align='center' width={'15%'}>
                      <ActionButtons order={order} changeOrderStatus={changeOrderStatus} />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={orders?.totalElements || 0}
        rowsPerPage={query?.rowsPerPage || 15}
        page={query?.page || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <OrderDialog data={orders?.content || []} onClose={onClose} openDialog={openDialog} target={target} />
    </Paper>
  )
}

export default StoresTable
