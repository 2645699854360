import React from 'react'
import StarRateIcon from '@material-ui/icons/StarRate'

const stars = (searchData, classes) => {
  let stars = []
  for (let i = 1; i <= searchData.rating; i++) {
    stars.push(
      <span key={i} className={classes.starRateIcon}>
        <StarRateIcon />
      </span>
    )
  }
  return stars
}

export { stars }
