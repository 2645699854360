import React, { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  button: {
    width: 150,
    textTransform: 'none',
    height: 24,
    fontSize: 11,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: 'white',
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    color: 'white',
    fontWeight: 500,
  },
}))

export const InProgressButtons = ({ order, changeOrderStatus, nextStatus, label }) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()
  const [action, setAction] = useState('')

  const onClickHandler = (e) => {
    setAction(e.target.innerText)
    const status = e.currentTarget.getAttribute('status')
    changeOrderStatus(status, order.orderId)
  }

  return (
    <Button
      variant='outlined'
      size='small'
      onClick={onClickHandler}
      status={nextStatus}
      className={clsx(classes.button, {
        [classes.actionButton]: action === format({ id: label }),
      })}
    >
      {format({ id: label })}
    </Button>
  )
}
