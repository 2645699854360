import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DeleteIcon from '@material-ui/icons/Delete'

import { customerActions, searchActions, orderActions } from '../../../../actions'
import { customerService } from '../../../../services'
import { Transition } from '../../../utils/tools'
import { handleAddressQuery } from '../../../../helpers/address/handleAddressQuery'
import { generalConstants, deliveryTypes } from '../../../../constants'

import backIcon from '../../../../images/black-back.svg'
import yesIcon from '../../../../images/yes.svg'

import AddAddress from './AddAddress'
import { setLocalStorageLatLong } from '../../../../helpers/address/setLocalStorageItems'

const path = process.env.PUBLIC_URL
// const height = window.innerHeight

const text = {
  textTransform: 'capitalize',
  fontSize: 14,
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.36,
  letterSpacing: 'normal',
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // height: height,
    // maxHeight: height,
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  add: {
    color: theme.palette.primary.main,
    ...text,
  },
  row: {
    height: 70,
    borderBottom: '1px solid #f7f7f7',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subRow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
    justifyContent: 'center',
    paddingTop: theme.spacing(),
    overflow: 'scroll',
    flex: '1 50 auto',
  },
  primary: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(),
    width: 16,
    height: 16,
  },
  name: {
    ...text,
  },
  deleteButton: {
    padding: 0,
    zIndex: 1,
  },
}))

export const MobileAddresses = ({ handleClose }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const history = useHistory()

  //Global states
  const addresses = useSelector(({ customer }) => customer.savedAddresses)
  //Local states
  const [showAddDialog, setShowAddDialog] = useState(false)

  //Actions
  const dispatch = useDispatch()
  const deleteAddress = async (id) => dispatch(customerActions.deleteAddress(id))
  const setUserAddress = (address) => dispatch(searchActions.setAddress(address))
  const setUserDeliveryType = (type) => dispatch(searchActions.setDeliveryType(type))
  const setUserDeliverySubTypes = (type) => dispatch(orderActions.setDeliverySubTypes(type))
  const setUserInstructions = (inst) => dispatch(orderActions.setInstructions(inst))
  const updateAddress = (data) => dispatch(customerActions.updateAddressFromAddressPopUp(data))

  const handlePageClose = () => {
    if (handleClose) {
      handleClose()
    } else {
      history.push(`${path}/customer/account`)
    }
  }

  const handleAddAddressClose = () => {
    setShowAddDialog(false)
  }

  const handleDeleteAddress = async (addr) => {
    await deleteAddress(addr.id)
  }

  const handleAddressPress = (e, data) => {
    e.persist()
    setLocalStorageLatLong(data)
    let query = handleAddressQuery(data)
    if (query.default_) {
      setUserAddress(query)
      handleHowDetails(query)
    } else {
      setAsDefaultAddress(data)
    }
  }

  const setAsDefaultAddress = (data) => {
    let address = data
    address.default_ = true
    customerService
      .updateAddress(data.id, address)
      .then((res) => {
        let addressQuery = handleAddressQuery(res.data)
        if (res.status === generalConstants.STATUS_OK) {
          updateAddress(res.data)
          setUserAddress(addressQuery)
          handleHowDetails(addressQuery)
        } else {
          console.log(res)
        }
      })
      .catch((err) => console.log(err))
  }

  const handleHowDetails = (query) => {
    setUserInstructions(query.deliveryInstruction)
    setUserDeliverySubTypes(query.deliveryOption)
    if (query.deliveryOption === '') {
      setUserDeliveryType(deliveryTypes.TAKEOUT)
    } else {
      setUserDeliveryType(deliveryTypes.DELIVERY)
    }
    history.push(`${path}/customer/food`)
  }

  return (
    <div className={classes.root}>
      <div className='mobile-top-bar'>
        <IconButton size='small' className={classes.back} onClick={handlePageClose}>
          <img src={backIcon} alt='Back' />
        </IconButton>
        <Typography variant='h6'>{f({ id: 'saved-addresses' })}</Typography>
      </div>
      <div className={classes.content}>
        {addresses?.length > 0 &&
          addresses
            .sort((a, b) => b.default_ - a.default_)
            .map((address, index) => (
              <div className={classes.row} key={index}>
                <div className={classes.subRow} onClick={(e) => handleAddressPress(e, address)}>
                  <div className='column'>
                    <Typography variant='body1' className={classes.name}>
                      {`${address.name}`}
                    </Typography>
                    <Typography variant='body1' color={address.default_ ? 'textPrimary' : 'textSecondary'}>
                      {`${address.addressLine1} ${address.addressLine2}`}
                    </Typography>
                    <Typography variant='body1' color={address.default_ ? 'textPrimary' : 'textSecondary'}>
                      {`${address.city}, ${address.state} ${address.zipCode || ''}`}
                    </Typography>
                  </div>
                  <div className='grow' />
                  <div className='centered-row'>
                    {address.default_ && (
                      // <Typography className={classes.primary}>{getLabel('default-label')}</Typography>
                      <img src={yesIcon} alt='yes' className={classes.primary} />
                    )}
                  </div>
                </div>
                <div className='column' style={{ width: 30 }}>
                  <IconButton onClick={() => handleDeleteAddress(address)} className={classes.deleteButton}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
        <div className={classes.row}>
          <Button onClick={() => setShowAddDialog(true)} className={classes.add}>
            {f({ id: 'add-new-address' })}
          </Button>
        </div>
      </div>
      <Dialog fullScreen open={showAddDialog} onClose={handleAddAddressClose} TransitionComponent={Transition}>
        <AddAddress handleClose={handleAddAddressClose} />
      </Dialog>
    </div>
  )
}

MobileAddresses.propTypes = {
  handleClose: PropTypes.func,
}

export default MobileAddresses
