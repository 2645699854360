import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { deliveryTypes } from '../../../../constants'
import ExpandIcon from '@material-ui/icons/KeyboardArrowDown'
import CollapseIcon from '@material-ui/icons/KeyboardArrowUp'
import { ServiceHours } from '../service_hours'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    // fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
    margin: theme.spacing(),
  },
  grow: {
    flexGrow: 1,
  },
  smallButton: {
    margin: theme.spacing(),
    padding: 0,
  },
}))

const StoreServiceHours = ({
  showServiceHours,
  serviceHours,
  showHours,
  intl,
  addHour,
  removeHour,
  updateHour,
  editting,
  workingHours,
}) => {
  const classes = useStyles()
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <>
      <div className={clsx(classes.section, classes.grow)}>
        {!workingHours && (
          <div className='grow row'>
            <Typography variant='h5' className={classes.subtitle}>
              {getLabel('service-hours')}
            </Typography>
            <div className={classes.grow} />
            <IconButton
              edge='end'
              color='inherit'
              className={classes.smallButton}
              aria-label='service-hours'
              onClick={() => showHours((prev) => !prev)}
            >
              {showServiceHours ? <CollapseIcon /> : <ExpandIcon />}
            </IconButton>
          </div>
        )}
        <Divider />
      </div>

      <Collapse in={showServiceHours} className='full-width'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='column'>
            <ServiceHours
              variant={deliveryTypes.DELIVERY}
              data={serviceHours.DELIVERY}
              add={addHour}
              remove={removeHour}
              update={updateHour}
              editting={editting}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='column'>
            <ServiceHours
              variant={deliveryTypes.TAKEOUT}
              data={serviceHours.TAKEOUT}
              add={addHour}
              remove={removeHour}
              update={updateHour}
              editting={editting}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  )
}

StoreServiceHours.propTypes = {
  showServiceHours: PropTypes.bool,
  serviceHours: PropTypes.object,
  intl: PropTypes.object,
  showHours: PropTypes.func.isRequired,
  addHour: PropTypes.func.isRequired,
  removeHour: PropTypes.func.isRequired,
  updateHour: PropTypes.func.isRequired,
  editting: PropTypes.bool,
  workingHours: PropTypes.bool,
}

export default injectIntl(StoreServiceHours)
