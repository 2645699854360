import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import notFavoritedIcon from '../../../images/gray-favorite.svg'
import backgroundPlaceholder from '../../../images/restaurant-back.jpeg'
import favoritedIcon from '../../../images/green-favorite.svg'
import deliveryTimeIcon from '../../../images/delivery-time.svg'
// import deliveryFee from '../../../images/delivery-fee.svg'
import rate from '../../../images/white-rate.svg'
import clsx from 'clsx'
import priceFilter from '../../../helpers/price-icons'
import { withRouter } from 'react-router'
import { getImage } from '../../../helpers/get-image'
import { useSelector } from 'react-redux'
import { deliveryTypes, deliveryTimes } from '../../../constants'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    borderRadius: 6,
    height: 321,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: 221,
    },
  },
  rootFood: {
    width: '100%',
    minWidth: 521,
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    borderRadius: 6,
    height: 321,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: 221,
    },
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  media: {
    [theme.breakpoints.down('md')]: {
      height: 150,
    },
    width: '100%',
    height: 208,
    backgroundColor: '#f4f4f4',
    // "&[store-open='false']": {
    //   opacity: '0.5',
    // },
  },
  favorite: {
    height: 40,
    width: 40,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    filter: 'drop-shadow(1px 1px 5px black)',
    // "&[store-open='false']": {
    //   opacity: '0.8',
    // },
  },
  content: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingTop: 2,
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#333',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 1.29,
    },
  },
  rating: {
    position: 'absolute',
    left: 10,
    top: 10,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    width: 40,
    height: 20,
    zIndex: 2,
    filter: 'drop-shadow(1px 1px 5px black)',
    // "&[store-open='false']": {
    //   opacity: '0.8',
    // },
  },
  rateIcon: {
    marginLeft: 5,
  },
  storeRating: {
    fontSize: 10,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.7,
    letterSpacing: 'normal',
    color: '#fff',
    marginLeft: 3,
  },
  deliveryBlock: {
    justifyContent: 'space-between',
  },
  icons: {
    width: 13.9,
    height: 13.9,
  },
  close: {
    color: 'rgb(97, 26, 21)',
    fontSize: 14,
    fontWeight: 500,
  },
}))

const StoreItem = (props) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()
  const { background } = props.data.restaurant
  const deliveryType = useSelector(({ search }) => search.searchQuery.deliveryType)
  const deliveryTime = useSelector(({ search }) => search.searchQuery.deliveryTime)

  const isDelivery = deliveryType === deliveryTypes.DELIVERY
  const isASAP = deliveryTime === deliveryTimes.ASAP
  const isFavorite = props.favorites.some((favorite) => favorite.entityId === props.data.id)

  return (
    <Card className={props.food ? classes.rootFood : classes.root} variant='outlined' style={{ border: 0 }}>
      {props.data.ratingSummary && (
        <div store-open={(!!props.storeOpen).toString()} className={clsx('centered-row', classes.rating)}>
          <img src={rate} width='14.7' height='13.7' className={classes.rateIcon} alt='rating' />
          <Typography className={classes.storeRating}>{props.data.ratingSummary.averageRating.toFixed(1)}</Typography>
        </div>
      )}
      <IconButton
        store-open={(!!props.storeOpen).toString()}
        className={classes.favorite}
        onClick={() => props.onPress(props.data.id)}
      >
        <img src={isFavorite ? favoritedIcon : notFavoritedIcon} height='20' alt='' />
      </IconButton>
      <CardActionArea
        className={classes.actionArea}
        onClick={() => props.history.push(`${path}/customer/store/${props.data.id}`)}
      >
        <CardMedia
          component={'img'}
          className={classes.media}
          image={getImage(background, 'thumb') || backgroundPlaceholder}
          title={props.data.nickname}
          store-open={(!!props.storeOpen).toString()}
        />

        <CardContent className={classes.content}>
          <div className={clsx('centered-row', classes.deliveryBlock)} style={{ marginTop: 5 }}>
            <Typography className={clsx('ellipsis', classes.title)}>{props.data.nickname}</Typography>
            <div className='centered-row'>{priceFilter(props.data.pricey, 15, false)}</div>
          </div>
          <div className='grow' />

          {/* <div className='centered-row'>
              <Typography variant='body2' color='textSecondary' component='p'>
                {props.data.deliveryTime}
              </Typography>
              <div className='grow' />
              <Typography variant='body2' color='textSecondary' component='p'>
                {props.data.deliveryFee}
              </Typography>
            </div> */}
          <div className={clsx('centered-row', classes.deliveryBlock)}>
            {props.data.open ? (
              props.data.deliveryTime !== -1 &&
              isDelivery &&
              isASAP && (
                <div className='centered-row'>
                  <img
                    src={deliveryTimeIcon}
                    alt='Delivery time'
                    width='13.9'
                    height='13.9'
                    className={classes.icon}
                    style={{ opacity: 0.5, marginRight: 5 }}
                  />
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {`${props.data.deliveryTime} mins`}
                  </Typography>
                </div>
              )
            ) : (
              <Typography variant='body2' component='p' className={classes.close}>
                {format({ id: 'closed-label' })}
              </Typography>
            )}

            {/* <div className='centered-row'>
              <img
                src={deliveryFee}
                alt='Delivery fee'
                width='20'
                className={classes.icon}
                style={{ opacity: 0.5, marginRight: 5 }}
              />
              <Typography variant='body2' color='textSecondary' component='p'>
                {props.data.deliveryFee} delivery fee
              </Typography>
            </div> */}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

StoreItem.propTypes = {
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withRouter(StoreItem)
