import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { thirdTier, valueConstants } from '../../Helpers'
import TierField from '../../Fields/TierField_'

const ThirdTier = ({ classes, handleChange, removeTier, values }) => {
  const { formatMessage: f } = useIntl()

  return (
    <div className={classes.tier}>
      <Grid container spacing={2} className='full-width'>
        {thirdTier.map((tier, index) => (
          <Grid key={index} item md={4} sm={6} xs={12} className={classes.gridItem}>
            <TierField
              prop={tier.value}
              gridVal={12}
              handleChange={handleChange}
              startAdornment_={
                tier.value === valueConstants.thirdTierDeliveryFee || tier.value === valueConstants.thirdTierMinOrder
              }
              endAdornment_={tier.value === valueConstants.thirdTierMaxDistance}
              values={values}
            />
          </Grid>
        ))}
      </Grid>
      <div className={classes.buttonContainer}>
        <Button disableRipple variant='contained' color='secondary' onClick={removeTier} className={classes.button}>
          {f({ id: 'remove-label' })}
        </Button>
      </div>
    </div>
  )
}

ThirdTier.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  removeTier: PropTypes.func,
}

export default ThirdTier
