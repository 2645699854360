import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import useStyles from '../styles'

export const ReceiptDates = React.memo(({ date, hour, title, creation }) => {
  const classes = useStyles()
  const format = creation ? 'MMMM D, YYYY HH:mm' : 'LL'
  return (
    <div className='centered-column'>
      <Typography variant='body1' className={clsx(classes.regularText, classes.bold)}>
        <FormattedMessage id={title} />
      </Typography>
      <div className='centered-row'>
        <Typography variant='body1' className={classes.regularText}>
          {moment(date).format(format)}
        </Typography>{' '}
        {!creation && (
          <Typography variant='body1' className={classes.regularText} style={{ paddingLeft: 5 }}>
            {hour}
          </Typography>
        )}
      </div>
    </div>
  )
})

ReceiptDates.propTypes = {
  date: PropTypes.string.isRequired,
  hour: PropTypes.string,
  title: PropTypes.string.isRequired,
  creation: PropTypes.bool,
}
