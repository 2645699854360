import { orderStatuses } from '../../constants'

const orderDeliveryMessages = {
  [orderStatuses.ORDER_RECEIVED]: 'restaurant-approval',
  [orderStatuses.ORDER_APPROVED]: 'prepare-order',
  [orderStatuses.DRIVER_PICKED_UP]: 'delivery-by',
  [orderStatuses.CANCELED]: 'order-canceled',
  [orderStatuses.ORDER_REJECTED]: 'order-rejected',
  [orderStatuses.DELIVERED]: 'order-delivered',
}

export const getRelatedMessage = (order) => {
  return orderDeliveryMessages[order?.status?.name] || 'loading-message'
}
