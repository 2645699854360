import { merchantStoreContants, initialPagableList } from '../constants'

const initialState = {
  loading: false,
  error: null,
  data: initialPagableList,
  selectedStore: null,
  selectedStoreRole: null,
  selectedStoreEmployees: null,
  selectedRestaurant: null,
  roles: null,
  // sections: [],
  business: [],
  stores: [],
  isRestaurantView: true,
  refreshNeeded: 1,
}

export function merchantStores(state = initialState, action) {
  switch (action.type) {
    // IMAGE REDUCERS
    case merchantStoreContants.DELETE_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.DELETE_IMAGE_SUCCESS:
      // const deletedImageSection = deleteMenuImage(state.sections, action.sectionId, action.menuId, action.imageId)
      return {
        ...state,
        loading: false,
        error: null,
        // sections: deletedImageSection,
        refreshNeeded: +state.refreshNeeded + 1,
      }
    case merchantStoreContants.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.SAVE_MENU_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.SAVE_MENU_IMAGE_SUCCESS:
      // const newUpdatedMenu = updateMenu(state.sections, action.sectionId, action.menu)
      return {
        ...state,
        loading: false,
        error: null,
        // sections: newUpdatedMenu,
        refreshNeeded: +state.refreshNeeded + 1,
      }
    case merchantStoreContants.SAVE_MENU_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    //BASIC IDENTITY
    case merchantStoreContants.GET_BASIC_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.GET_BASIC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        business: action.business,
        selectedRestaurant: !state.selectedRestaurant
          ? action.business[0]
          : {
              ...state.selectedRestaurant,
              ...action.business.find((i) => i.restaurantId === state.selectedRestaurant?.restaurantId),
            },
        stores: !state.selectedRestaurant
          ? action.business[0]?.stores
          : action.business.find((i) => i.restaurantId === state.selectedRestaurant?.restaurantId).stores,
        selectedStore: !state.selectedStore
          ? action.business[0]?.stores[0]
          : action.business
              .find((i) => i.restaurantId === state.selectedRestaurant?.restaurantId)
              .stores.find((e) => e.storeId === state.selectedStore.storeId),
      }
    case merchantStoreContants.GET_BASIC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.CHANGE_RESTAURANT_ADMIN_VIEW:
      return {
        ...state,
        isRestaurantView: action.bool,
      }

    // MENU REDUCERS
    case merchantStoreContants.CREATE_MENU_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.CREATE_MENU_SUCCESS:
      // let createMenu = []
      // state.sections.forEach((section) => {
      //   if (section.id !== +action.sectionId) {
      //     createMenu.push(section)
      //   } else {
      //     let isExists = section.menus.find((menu) => menu.id === action.menu.id)
      //     if (isExists) {
      //       createMenu.push(section)
      //     } else {
      //       let newMenu = { ...section, menus: [...section.menus, action.menu] }
      //       createMenu.push(newMenu)
      //     }
      //   }
      // })
      return {
        ...state,
        loading: false,
        error: null,
        // sections: createMenu,
        refreshNeeded: +state.refreshNeeded + 1,
      }
    case merchantStoreContants.CREATE_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.DELETE_MENU_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.DELETE_MENU_SUCCESS:
      // const { sections } = deleteMenu(state.sections, action.sectionId, action.menuId)
      // console.log('merchantStores -> sections', sections)
      return {
        ...state,
        loading: false,
        error: null,
        // sections: sections,
        refreshNeeded: +state.refreshNeeded + 1,
      }
    case merchantStoreContants.DELETE_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.UPDATE_MENU_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.UPDATE_MENU_SUCCESS:
      // const updatedSectionMenu = updateMenu(state.sections, action.sectionId, action.menu)
      return {
        ...state,
        loading: false,
        error: null,
        // sections: updatedSectionMenu,
        refreshNeeded: +state.refreshNeeded + 1,
      }
    case merchantStoreContants.UPDATE_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    // SECTION REDUCERS
    case merchantStoreContants.CREATE_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.CREATE_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // sections: [...state.sections, action.section],
        refreshNeeded: +state.refreshNeeded + 1,
      }
    case merchantStoreContants.CREATE_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.DELETE_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.DELETE_SECTION_SUCCESS:
      // let newSectionState = state.sections.filter((section) => section.id !== action.sectionId)
      return {
        ...state,
        loading: false,
        error: null,
        // sections: newSectionState,
        refreshNeeded: +state.refreshNeeded + 1,
      }
    case merchantStoreContants.DELETE_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.UPDATE_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.UPDATE_SECTION_SUCCESS:
      // const { updateSectionState } = updateSection(state.sections, action.section)
      return {
        ...state,
        loading: false,
        error: null,
        // sections: updateSectionState,
        refreshNeeded: +state.refreshNeeded + 1,
      }
    case merchantStoreContants.UPDATE_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.GETALL_SECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.GETALL_SECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sections: action.sections,
      }
    case merchantStoreContants.GETALL_SECTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    // EMPLOYEE REDUCERS
    case merchantStoreContants.GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        selectedStoreEmployees: action.employees,
      }
    case merchantStoreContants.GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.DELETE_EMPLOYEE_SUCCESS:
      const newState = state.selectedStoreEmployees.filter((employee) => employee.id !== action.employeeId)
      return {
        ...state,
        loading: false,
        error: null,
        selectedStoreEmployees: newState,
      }
    case merchantStoreContants.DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    //STORE REDUCERS
    case merchantStoreContants.CREATE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.CREATE_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case merchantStoreContants.CREATE_STORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.DELETE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.DELETE_STORE_SUCCESS:
      // const newStoresState = state.stores
      return {
        ...state,
        loading: false,
      }
    case merchantStoreContants.DELETE_STORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.UPDATE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.UPDATE_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        // as we redirect user to /merchant/stores screen,
        // this screen fires getStores action and fill this data immediately after redirection [ahmet]
        // data: action.stores,
      }
    case merchantStoreContants.UPDATE_STORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.CHANGE_SELECTED_STORE:
      return {
        ...state,
        selectedStore: action.store,
      }
    case merchantStoreContants.CHANGE_SELECTED_RESTAURANT:
      return {
        ...state,
        selectedRestaurant: action.restaurant,
        stores: action.restaurant.stores,
        selectedStore: action.restaurant.stores[0],
      }
    case merchantStoreContants.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      }
    case merchantStoreContants.GET_STORES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.GET_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.stores,
      }
    case merchantStoreContants.GET_STORES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case merchantStoreContants.SAVE_LOGO_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.SAVE_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        selectedRestaurant: {
          ...state.selectedRestaurant,
          logo: action.logo,
        },
      }
    case merchantStoreContants.SAVE_LOGO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case merchantStoreContants.DELETE_LOGO_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.DELETE_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        selectedRestaurant: {
          ...state.selectedRestaurant,
          logo: null,
        },
      }
    case merchantStoreContants.DELETE_LOGO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case merchantStoreContants.CLEAR_LOGO:
      return {
        ...state,
        selectedRestaurant: {
          ...state.selectedRestaurant,
          logo: {},
        },
      }
    case merchantStoreContants.SAVE_BACKGROUND_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.SAVE_BACKGROUND_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        selectedRestaurant: {
          ...state.selectedRestaurant,
          background: action.background,
        },
      }
    case merchantStoreContants.SAVE_BACKGROUND_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case merchantStoreContants.DELETE_BACKGROUND_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case merchantStoreContants.DELETE_BACKGROUND_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        selectedRestaurant: {
          ...state.selectedRestaurant,
          background: null,
        },
      }
    case merchantStoreContants.DELETE_BACKGROUND_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case merchantStoreContants.CLEAR_BACKGROUND:
      return {
        ...state,
        selectedRestaurant: {
          ...state.selectedRestaurant,
          background: {},
        },
      }
    case merchantStoreContants.MENU_COPY_SUCCESS:
      return {
        ...state,
        refreshNeeded: +state.refreshNeeded + 1,
      }
    default:
      return state
  }
}
