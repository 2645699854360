import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import RoomIcon from '@material-ui/icons/Room'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import imagePlaceHolder from '../../../../../images/image-placeholder.svg'
import deliveryTime from '../../../../../images/white-delivery-time.svg'
import rate from '../../../../../images/white-rate.svg'
import priceFilter from '../../../../../helpers/price-icons'

const useStoreSampleStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
  },
  banner: {
    height: 350,
    display: 'flex',
    flexDirection: 'column',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: 240,
    paddingBottom: 10,
    backgroundColor: '#fff',
    border: '1px solid #f4f4f4',
    boxShadow: '0 5px 5px 0 rgba(0,0,0,0.2)',
  },
  storeTitle: {
    fontSize: 50,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#fff',
    textShadow: '2px 2px 4px #000000',
  },
  category: {
    fontSize: 20,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.8,
    letterSpacing: 0.43,
    textAlign: 'left',
    color: '#ffffff',
    width: '100%',
    height: 26,
    marginBottom: theme.spacing(5),
  },
  iconContainer: {
    width: 25,
    height: 25,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    filter: 'drop-shadow(2px 2px 4px #000000)',
  },
  storeDetails: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#fff',
    textShadow: '2px 2px 4px #000000',
    marginLeft: 5,
    marginRight: 25,
  },
  priceIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paper: {
    width: 200,
    height: 200,
    backgroundColor: '#fff',
    marginTop: -100,
    border: '1px solid #f4f4f4',
    boxShadow: '0 5px 5px 0 rgba(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sections: {
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
  },
  img: {
    backgroundColor: '#fff',
    borderRadius: 4,
    height: 200,
    width: 200,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
  },
}))

const StoreBackgroundSample = ({ img, preview, index }) => {
  const classes = useStoreSampleStyles()

  const restaurant = useSelector(({ merchantStores }) => merchantStores.selectedRestaurant)
  const logoFile = restaurant?.logo
  const bgFile = restaurant?.background

  const [path, setPath] = useState('')
  const [logo, setLogo] = useState(imagePlaceHolder)

  useEffect(() => {
    setPathData()
    setLogoData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant])

  useEffect(() => {
    if (!logoFile) setLogo(imagePlaceHolder)
    if (!bgFile) setPath('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoFile, bgFile])

  const setPathData = () => {
    if (restaurant.background) {
      const { path, filename, extension } = restaurant?.background
      if (path && filename && extension) {
        setPath(
          restaurant.background.path + '/big/' + restaurant.background.filename + '.' + restaurant.background.extension
        )
      } else setPath('')
    } else setPath('')
  }

  const setLogoData = () => {
    if (restaurant?.logo) {
      const { path, filename, extension } = restaurant?.logo
      if (path && filename && extension) {
        setLogo(restaurant.logo.path + '/big/' + restaurant.logo.filename + '.' + restaurant.logo.extension)
      } else setLogo(imagePlaceHolder)
    } else setLogo(imagePlaceHolder)
  }

  const handleBackground = () => {
    if (preview) {
      if (index === 1) {
        return `url(${img})`
      }
    }
    return `url(${path})`
  }

  const handleLogo = () => {
    if (preview) {
      if (index === 0) {
        return img
      }
    }
    return logo
  }

  return (
    <Container maxWidth='lg' className={classes.root}>
      <div className='column'>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.banner}
            style={{
              backgroundImage: handleBackground(),
            }}
          >
            <div className='grow' />
            <Typography className={classes.storeTitle}>Store Name</Typography>
            <Typography className={classes.category}>Asian | Burger | Pizza</Typography>
            <div className='centered-row'>
              <div className={classes.iconContainer}>
                <img src={deliveryTime} alt='Delivery time' height='20' className={classes.icon} />
              </div>
              <Typography className={classes.storeDetails}>30 mins.</Typography>
              <img src={rate} alt='Rate' height='20' className={classes.icon} />
              <Typography className={classes.storeDetails}>4.3 (300)</Typography>
              <div className={classes.priceIcon}>
                <div style={{ height: 20 }}>{priceFilter(3, 20, true)}</div>
              </div>
              <div className='grow' />
              <RoomIcon className={classes.icon} style={{ color: 'white' }} />
              <Typography className={classes.storeDetails}>Store Address Info</Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.sections}>
            <Paper className={classes.paper}>
              <img alt='' src={handleLogo()} className={classes.img} />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default StoreBackgroundSample
