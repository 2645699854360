import PrivacyPolicy from '../../components/customer/footerPages/pages/PrivacyPolicy'
import TermsAndConditions from '../../components/customer/footerPages/pages/TermsAndConditions'
import ViewCountries from '../../components/customer/footerPages/pages/ViewCountries'
import ViewCities from '../../components/customer/footerPages/pages/ViewCities'

export const footerPages = {
  // 'read-blog': 'Read Our Blog',
  // 'buy-gift-card': 'Buy Gift Card',
  // 'create-business-account': 'Create Business Account',
  // 'add-your-restaurant': 'Add Your Restaurant',
  // 'signup-to-delivery': 'Signup to Delivery',
  // 'read-faq': 'Read FAQs',
  'view-cities': ViewCities,
  'view-countries': ViewCountries,
  // 'restaurants-near-me': 'Restaurants Near Me',
  // 'support-center': 'Supports Center',
  'privacy-policy': PrivacyPolicy,
  'terms-and-conditions': TermsAndConditions,
}
