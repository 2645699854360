import React from 'react'
import ContentLoader from 'react-content-loader'

const CartPricesCL = () => {
  return (
    <ContentLoader viewBox='0 0 300 80'>
      <rect x='5' y='0' rx='4' ry='4' width='150' height='10' />
      <rect x='255' y='0' rx='4' ry='4' width='40' height='10' />
      <rect x='5' y='20' rx='4' ry='4' width='150' height='10' />
      <rect x='255' y='20' rx='4' ry='4' width='40' height='10' />
      <rect x='5' y='40' rx='4' ry='4' width='150' height='10' />
      <rect x='255' y='40' rx='4' ry='4' width='40' height='10' />
      <rect x='0' y='60' rx='4' ry='4' width='155' height='10' />
      <rect x='255' y='60' rx='4' ry='4' width='40' height='10' />
    </ContentLoader>
  )
}

export default CartPricesCL
