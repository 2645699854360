const scrollHeight = (signedIn, warnActive, minHeadCountFailure, ocStatus) => {
  return signedIn
    ? warnActive === 'visible' || minHeadCountFailure === 'visible' || !ocStatus
      ? 263
      : 323
    : warnActive === 'visible' || minHeadCountFailure === 'visible'
    ? 290
    : 350
}

export { scrollHeight }
