import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../../images/tookfrsh_logo.svg'
import logoText from '../../../images/logo_text_black.svg'
import bg from '../../../images/mobile-signin-bg.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${bg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      paddingTop: theme.spacing(3),
    },
  },
}))

function SplashScreen() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className='grow justify-center'>
        <div className='centered-column'>
          <img src={logo} alt='TookFresh' height='60' />
          <img src={logoText} alt='TookFresh' height='45' />
        </div>
      </div>
    </div>
  )
}

export default SplashScreen
