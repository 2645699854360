import React from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { restaurantActions, merchantRestaurantActions } from '../../../actions'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import UploadImage from '../common/imageUpload/UploadImage'
import { restaurantService } from '../../../services/restaurant.service'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    minHeight: '100vh',
  },
  paper: {
    width: '100%',
    flexGrow: 1,
    marginTop: 50,
  },
  justified: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  dialog: {
    width: 240,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogTitle: {
    fontSize: 23,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#000',
  },
})

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function withContextHook(Component) {
  return function WrappedComponent(props) {
    const contextHook = useMerchantHomeContext()
    return <Component {...props} contextHook={contextHook} />
  }
}

class Settings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: 0,
      logoFile: null,
      backgroundFile: null,
      showAttributeDialog: false,
      imageType: null,
      logotype: null,
    }

    this.onDropLogo = this.onDropLogo.bind(this)
    this.onDropBackground = this.onDropBackground.bind(this)
  }

  componentDidMount() {
    const { restaurantId } = this.props
    this.getImages(restaurantId)
  }

  getImages = (id) => {
    const { saveBackgroundSuccess, saveLogoSuccess } = this.props
    restaurantService.getImageData(id).then((res) => {
      const { background, logo } = res.data
      if (background.id) saveBackgroundSuccess(background)
      if (logo.id) saveLogoSuccess(logo)
    })
  }

  onDropLogo(pictureFiles) {
    this.setState({ logoFile: pictureFiles[0], logoType: pictureFiles[0].type })
  }

  onDropBackground(pictureFiles) {
    this.setState({ backgroundFile: pictureFiles[0], imageType: pictureFiles[0].type })
  }

  saveLogo = () => {
    const { logoFile, logoType } = this.state
    const { saveLogo, restaurant } = this.props
    if (logoFile) saveLogo(restaurant.id, logoFile, logoType)
  }

  saveBackground = () => {
    const { backgroundFile, imageType } = this.state
    const { saveBackground, restaurantId } = this.props
    if (backgroundFile) saveBackground(restaurantId, backgroundFile, imageType)
  }

  deleteLogo = () => {
    this.props.deleteLogo(this.props.restaurant.id)
  }

  deleteBackground = () => {
    this.props.deleteBackground(this.props.restaurant.id)
  }

  handleAttributeDialogClose = () => {
    this.setState({ showAttributeDialog: false })
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  render() {
    const { classes, contextHook } = this.props
    const { selectedTab } = this.state
    const {
      values: { classesFromParent, openSwipeable },
    } = contextHook

    return (
      <div
        className={clsx(classes.root, classesFromParent.pageContent, {
          [classesFromParent.pageContentShift]: openSwipeable,
        })}
      >
        <Paper className={classes.paper}>
          <Tabs
            value={selectedTab}
            indicatorColor='primary'
            textColor='primary'
            onChange={(event, newValue) => this.setState({ selectedTab: newValue })}
            aria-label='settings tab'
          >
            <Tab label={this.getLabel('logo-label')} />
            <Tab label={this.getLabel('background-label')} />
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <UploadImage index={0} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <UploadImage index={1} />
          </TabPanel>
        </Paper>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { restaurant, merchantStores } = state
  return {
    restaurant: restaurant.data,
    restaurantId: merchantStores.selectedRestaurant?.restaurantId,
  }
}

const actionCreators = {
  saveLogo: restaurantActions.saveLogo,
  deleteLogo: restaurantActions.deleteLogo,
  saveBackground: restaurantActions.saveBackground,
  deleteBackground: restaurantActions.deleteBackground,
  saveBackgroundSuccess: merchantRestaurantActions.successBackgroundSave,
  saveLogoSuccess: merchantRestaurantActions.successLogoSave,
}

export default withStyles(styles)(
  withRouter(injectIntl(connect(mapStateToProps, actionCreators)(withContextHook(Settings))))
)
