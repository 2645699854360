import React from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField, Typography } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { servingTypes } from '../../../../constants'
import InputAdornment from '@material-ui/core/InputAdornment'
import { CustomFormatNumber } from '../../../utils/tools'

const MenuSize = ({ menu, intl, handleChange }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const getServingLabel = (servingType) => {
    if (servingType === servingTypes.WEIGHT) return getLabel('weight-lb')
    else if (servingType === servingTypes.PORTION) return getLabel('weight-person')
    else if (servingType === servingTypes.BOTTLE) return getLabel('weight-bottle')
    else if (servingType === servingTypes.GLASS) return getLabel('weight-glass')
    else return ''
  }

  return (
    <Grid item sm={12} xs={12}>
      <TextField
        variant='outlined'
        required
        style={{ width: 200 }}
        id='servingSize'
        name='servingSize'
        value={menu.servingSize}
        label={getLabel('serving-size')}
        onChange={handleChange}
        InputProps={{
          inputComponent: CustomFormatNumber,
          endAdornment: (
            <InputAdornment position='end'>
              <Typography>{getServingLabel(menu.servingType)}</Typography>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  )
}

MenuSize.propTypes = {
  menu: PropTypes.object,
  handleChange: PropTypes.func,
}

export default injectIntl(MenuSize)
