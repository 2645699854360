import React from 'react'
import AnimateHeight from 'react-animate-height'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

import markerIcon from '../../../../images/marker-icon-filled.svg'

const ellipsis = {
  whiteSpace: 'nowrap',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const useStyles = makeStyles((theme) => ({
  cardSub: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'none',
  },
  suggetionContainer: {
    marginTop: theme.spacing(),
    width: '95%',
    height: 'auto',
    marginBottom: theme.spacing(),
  },
  suggestion: {
    backgroundColor: 'white',
    cursor: 'pointer',
    borderTop: '1px solid #E9ECEF',
    borderLeft: '1px solid #E9ECEF',
    borderRight: '1px solid #E9ECEF',
    height: 50,
    padding: theme.spacing(),
    '&:hover': {
      backgroundColor: '#f8f8f8 !important',
    },
  },
  suggestionItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  suggestionText: {
    ...ellipsis,
    width: '100%',
    fontSize: 14,
    color: '#707070',
    marginLeft: theme.spacing(),
  },
}))

const Suggestions = ({ openSuggestions, suggestions, getSuggestionItemProps }) => {
  const classes = useStyles()
  return (
    <AnimateHeight duration={300} height={openSuggestions ? suggestions.length * 54 + 10 : 0} delay={300}>
      <Card
        className={classes.cardSub}
        style={{
          height: suggestions?.length === 0 ? 0 : null,
        }}
      >
        <div
          className={clsx('autocomplete-dropdown-container', [classes.suggetionContainer])}
          style={{
            borderBottom: suggestions.length > 0 && '1px solid #E9ECEF',
          }}
        >
          {suggestions.map((suggestion, index) => {
            return (
              <div {...getSuggestionItemProps(suggestion)} key={index} className={classes.suggestion}>
                <div className={classes.suggestionItem}>
                  <img src={markerIcon} alt='marker' height='30px' width='20px' />
                  <Typography className={classes.suggestionText}>{suggestion.description}</Typography>
                </div>
              </div>
            )
          })}
        </div>
      </Card>
    </AnimateHeight>
  )
}

Suggestions.propTypes = {
  openSuggestions: PropTypes.bool.isRequired,
  suggestions: PropTypes.array.isRequired,
  getSuggestionItemProps: PropTypes.func.isRequired,
}

export default Suggestions
