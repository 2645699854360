import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import logo from '../../../images/tookfrsh_logo.svg'
import logoText from '../../../images/logo_text_black.svg'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&.MuiAppBar-colorPrimary': {
      color: 'none',
      backgroundColor: '#fff',
    },
    '&.MuiAppBar-root': {
      flexDirection: 'row',
      // boxShadow: "none",
    },
  },
  logo: {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  container: {
    width: '100%',
    minWidth: 900,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
  },
}))

const path = process.env.PUBLIC_URL

const TopNavbar = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <AppBar className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <IconButton
          color='inherit'
          className={classes.logo}
          onClick={() => history.push(`${path}/`)}
          disableFocusRipple
          disableRipple
          aria-label='back'
        >
          <img src={logo} alt='TookFresh' height='60' />
          <img src={logoText} alt='TookFresh' height='30' style={{ marginLeft: 10 }} />
        </IconButton>
      </Container>
    </AppBar>
  )
}

export default React.memo(TopNavbar)
