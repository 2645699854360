const ratingCreator = (questions) => {
  let object = {}
  questions.forEach((question) => {
    object[question.id] = {
      rating: 0,
      questionId: question.id,
      comment: '',
    }
  })
  return object
}

const imageHeight = 66

export { ratingCreator, imageHeight }
