import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(() => ({
  warnContainer: {
    minHeight: 130,
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: 0,
    "&[tablet-view='true']": {
      height: 70,
    },
  },
  warnBox: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'rgb(253,236,234)',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: '#707070',
    padding: '10px 15px 10px 15px',
    visibility: 'hidden',
    marginBottom: 10,
    "&[content-visible='visible']": {
      visibility: 'visible',
    },
    "&[content-payment='show']": {
      visibility: 'visible',
    },
    "&[payment-error='error']": {
      visibility: 'visible',
    },
    "&[tablet-view='true']": {
      justifyContent: 'center',
      height: '48px',
    },
  },
}))

const WarnBox = ({ tabletView, warnActive, paymentMethodWarning, errorMessage, paymentError }) => {
  const classes = useStyles()
  return (
    <Container className={classes.warnContainer} tablet-view={tabletView ? 'true' : 'false'}>
      {warnActive === 'visible' && (
        <div className={classes.warnBox} content-visible={warnActive} tablet-view={tabletView ? 'true' : 'false'}>
          <FormattedMessage id='senpex-delivery-warn-text' />
        </div>
      )}
      {paymentMethodWarning === 'show' && (
        <div
          className={classes.warnBox}
          content-payment={paymentMethodWarning}
          tablet-view={tabletView ? 'true' : 'false'}
        >
          <FormattedMessage id='payment-info-warning' />
        </div>
      )}
      {errorMessage && (
        <div className={classes.warnBox} payment-error={paymentError} tablet-view={tabletView ? 'true' : 'false'}>
          {errorMessage}
        </div>
      )}
    </Container>
  )
}

export default WarnBox
