import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    width: 'fit-content',
    height: 'auto',
    display: 'flex',
    "&[content-align='right']": {
      width: '80%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  col1: {
    fontWeight: 'bold',
    marginRight: '25px',
    color: 'black',
  },
}))

const Text = ({ col1, col2, align }) => {
  const classes = useStyles()
  return (
    <div className={classes.root} content-align={align}>
      {col1 && <div className={classes.col1}>{col1}</div>}
      <div>{col2}</div>
    </div>
  )
}

export default Text
