import { orderConstants, generalConstants } from '../constants'
import { orderService } from '../services'
import { alertActions } from './alert.actions'

export const orderActions = {
  getAllOrders,
  addMenuToOrder,
  removeMenuFromOrder,
  removeMenuItem,
  saveOrder,
  deleteOrder,
  createPaymentIntent,
  paymentConfirmation,
  clearMenus,
  addUserIdToCart,
  setOrderAddress,
  selectMenuQuantity,
  setOrderFees,
  clearCart,
  setDeliverySubTypes,
  setInstructions,
  clearOrderFees,
  setOrderPreviewData,
  setOrderSaveData,
  setPaymentIntentData,
  setOrderType,
  setTip,
}

function getAllOrders() {
  return (dispatch) => {
    dispatch(request())

    orderService.getAllOrders().then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request() {
    return { type: orderConstants.GETALL_ORDERS_REQUEST }
  }
  function success(orders) {
    return { type: orderConstants.GETALL_ORDERS_SUCCESS, orders }
  }
  function failure(error) {
    return { type: orderConstants.GETALL_ORDERS_FAILURE, error }
  }
}

function addMenuToOrder(id, nickname, order) {
  return {
    type: orderConstants.ADD_MENU,
    id: id,
    nickname: nickname,
    orderMenu: order,
  }
}
function setOrderType(type) {
  return {
    type: orderConstants.SET_ORDER_TYPE,
    orderType: type,
  }
}

function selectMenuQuantity(store, order) {
  return {
    type: orderConstants.SELECT_QUANTITY,
    store: store,
    orderMenu: order,
  }
}

function removeMenuFromOrder(index) {
  return {
    type: orderConstants.REMOVE_MENU,
    index,
  }
}
function removeMenuItem(index) {
  return {
    type: orderConstants.REMOVE_MENU_ITEM,
    index,
  }
}

function clearMenus() {
  return {
    type: orderConstants.CLEAR_ALL_MENUS,
  }
}

function clearCart() {
  return {
    type: orderConstants.CLEAR_CART,
  }
}

function createPaymentIntent(orderId) {
  return (dispatch) => {
    dispatch(request({ orderId }))

    orderService.createPaymentIntent(orderId).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(orderId) {
    return { type: orderConstants.CREATE_PAYMENT_INTENT_REQUEST, orderId }
  }
  function success(secretKey) {
    return { type: orderConstants.CREATE_PAYMENT_INTENT_SUCCESS, secretKey }
  }
  function failure(error) {
    return { type: orderConstants.CREATE_PAYMENT_INTENT_FAILURE, error }
  }
}

function setPaymentIntentData(secretKey) {
  return { type: orderConstants.CREATE_PAYMENT_INTENT_SUCCESS, secretKey }
}

function paymentConfirmation(orderId) {
  return (dispatch) => {
    dispatch(request({ orderId }))

    orderService.paymentConfirmation(orderId).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data))
      },
      (error) => {
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(orderId) {
    return { type: orderConstants.CONFIRM_PAYMENT_REQUEST, orderId }
  }
  function success(intent) {
    return { type: orderConstants.CONFIRM_PAYMENT_SUCCESS, intent }
  }
}

function saveOrder(order, mobile = false) {
  return (dispatch) => {
    dispatch(request({ order }))

    orderService.saveOrder(order).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(order) {
    return { type: orderConstants.SAVE_ORDER_REQUEST, order }
  }
  function success(order) {
    return { type: orderConstants.SAVE_ORDER_SUCCESS, order }
  }
  function failure(error) {
    return { type: orderConstants.SAVE_ORDER_FAILURE, error }
  }
}

function setOrderSaveData(order) {
  return { type: orderConstants.SAVE_ORDER_SUCCESS, order }
}

function setOrderAddress(address) {
  return {
    type: orderConstants.SET_ORDER_ADDRESS,
    address,
  }
}

function deleteOrder(orderId) {
  return (dispatch) => {
    dispatch(request({ orderId }))

    orderService.deleteOrder(orderId).then(
      (res) => {
        if (res === generalConstants.STATUS_OK) dispatch(success(orderId))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(orderId) {
    return { type: orderConstants.DELETE_ORDER_REQUEST, orderId }
  }
  function success(orderId) {
    return { type: orderConstants.DELETE_ORDER_SUCCESS, orderId }
  }
  function failure(error) {
    return { type: orderConstants.DELETE_ORDER_FAILURE, error }
  }
}

function addUserIdToCart(customerId) {
  return {
    type: orderConstants.ADD_SIGNEDIN_USERID,
    customerId,
  }
}

function setOrderFees(orderDetail) {
  return (dispatch) => {
    dispatch(request())

    orderService.orderPreview(orderDetail).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) dispatch(success(res.data))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.response.data.error_message?.toString()))
      }
    )
  }

  function request() {
    return { type: orderConstants.SET_ORDERFEES_REQUEST }
  }
  function success(orderDetail) {
    return { type: orderConstants.SET_ORDERFEES_SUCCESS, orderDetail }
  }
  function failure(error) {
    return { type: orderConstants.SET_ORDERFEES_FAILURE, error }
  }
}

function clearOrderFees() {
  return {
    type: orderConstants.CLEAR_ORDERFEES,
  }
}

function setOrderPreviewData(orderDetail) {
  return { type: orderConstants.SET_ORDERFEES_SUCCESS, orderDetail }
}

function setDeliverySubTypes(subType) {
  return {
    type: orderConstants.SET_DELIVERY_SUBTYPE,
    subType,
  }
}

function setInstructions(instructions) {
  return {
    type: orderConstants.SET_INSTRUCTIONS,
    instructions,
  }
}

function setTip(query) {
  return {
    type: orderConstants.SET_TIP,
    query,
  }
}
