import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { GreenRadio } from '../../../../utils/tools'

import CustomizedPhoneInput from '../Common/CustomizedPhoneInput'

import { valueConstants, labelConstants, notificationConstants, phoneConstants } from '../../../businessConstants'

const OrderConfirmation = ({ classes, orderConfirmation, handleOrderConfirmation, values, setValues, error }) => {
  const { formatMessage: f } = useIntl()
  let customItem = { value: valueConstants.orderConfirmationPhone }
  return (
    <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
      <FormControl fullWidth className={classes.inputFields}>
        {phoneConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            className={classes.label}
            control={
              <GreenRadio checked={orderConfirmation === item} onChange={handleOrderConfirmation} value={item} />
            }
            label={<Typography>{item}</Typography>}
          />
        ))}
      </FormControl>
      <CustomizedPhoneInput
        valueName={valueConstants.orderConfirmationPhone}
        values={values}
        item={customItem}
        setValues={setValues}
        label={f({ id: labelConstants.orderConfirmationPhone })}
        disabled={orderConfirmation !== notificationConstants.otherPhone}
        error={error}
      />
      <Typography className={classes.textMessageAvailable}>{f({ id: 'available-phone-text-message' })}</Typography>
    </Grid>
  )
}

OrderConfirmation.propTypes = {
  values: PropTypes.object,
  classes: PropTypes.object,
  orderConfirmation: PropTypes.string,
  handleOrderConfirmation: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.bool,
}

export default OrderConfirmation
