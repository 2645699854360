import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

import { tfDefault, valueConstants } from '../../../businessConstants'

const TfDefault = ({ values, handleChange, error, f, classes }) => {
  return (
    <Grid container spacing={2} className='full-width' style={{ marginTop: 16 }}>
      {tfDefault.map((tier, index) => (
        <Grid item md={6} sm={6} xs={12} key={index}>
          <TextField
            size='small'
            value={values[tier.value]}
            variant='outlined'
            fullWidth
            id={tier.value}
            InputLabelProps={{ shrink: true }}
            label={f({ id: tier.label })}
            onChange={handleChange(tier.value)}
            required
            error={error && values[tier.value] === ''}
            InputProps={{
              inputProps: {
                type: 'number',
                min: 0,
                maxLength: 100,
              },
              startAdornment: tier.value !== valueConstants.maxDeliveryDistance && (
                <InputAdornment position='start' disablePointerEvents>
                  <Typography className={classes.startAdornment}>$</Typography>
                </InputAdornment>
              ),
              endAdornment: tier.value === valueConstants.maxDeliveryDistance && (
                <InputAdornment position='end' disablePointerEvents>
                  <Typography className={classes.endAdornment}>({f({ id: 'miles-label' })})</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

TfDefault.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  f: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
}

export default TfDefault
