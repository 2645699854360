import { useEffect, useState } from 'react'

export const useFetchAllStores = (data, relatedService, condition) => {
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    let ignore = false
    setLoading(true)
    relatedService(data)
      .then(
        (res) => {
          if (!ignore) {
            setResult(res.data.content)
          }
        },
        (err) => setError(err.response)
      )
      .finally(() => setLoading(false))

    return () => {
      ignore = true
    }
  }, [data])

  return { result, loading, error }
}
