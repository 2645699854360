import { initialQuery } from '../../constants/search.constants'

const checkState = (query) => {
  const { categoryIds, dietaryIds, orderByKey, rating, distance, orderByValue, pricey } = query
  if (
    categoryIds.length !== initialQuery.categoryIds.length ||
    dietaryIds.length !== initialQuery.dietaryIds.length ||
    orderByKey !== initialQuery.orderByKey ||
    orderByValue !== initialQuery.orderByValue ||
    rating !== initialQuery.rating ||
    pricey !== initialQuery.pricey ||
    distance !== initialQuery.distance
  ) {
    return true
  } else {
    return false
  }
}

export { checkState }
