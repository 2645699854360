import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import { valueConstants } from '../../Helpers'

import Textfield from '../../Fields/TextField_'

const NameField = ({ classes, values, handleChange, error }) => {
  return (
    <Grid item md={6} sm={12} xs={12} className={classes.gridItem}>
      <Textfield
        values={values}
        prop={valueConstants.paymentContactName}
        required
        error={error}
        handleChange={handleChange}
        gridVal={12}
      />
    </Grid>
  )
}

NameField.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  error: PropTypes.bool,
}

export default NameField
