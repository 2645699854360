import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { deliverySubTypes, deliveryTypes } from '../../../../../constants'
import yesIcon from '../../../../../images/yes.svg'
import clsx from 'clsx'
import deliverDoorIcon from '../../../../../images/deliver-door.svg'
import deliverDoorIconSelected from '../../../../../images/deliver-door-selected.svg'

const DeliverToDoor = ({ typ, subTyp, subRow, icon, button, selectedButton, handleDeliverDoor, label }) => (
  <Grid item xs={12} className={subRow}>
    <img
      className={icon}
      src={subTyp === deliverySubTypes.DELIVER_DOOR ? deliverDoorIconSelected : deliverDoorIcon}
      alt='where'
      height='20'
      width='20'
    />
    <div className='centered-row full-width'>
      <Button
        disabled={typ !== deliveryTypes.DELIVERY}
        className={clsx({
          [button]: true,
          [selectedButton]: subTyp === deliverySubTypes.DELIVER_DOOR,
        })}
        onClick={handleDeliverDoor}
      >
        {label}
      </Button>
      <div className='grow' />
      {subTyp === deliverySubTypes.DELIVER_DOOR && (
        <img className={icon} src={yesIcon} alt='where' height='16' width='16' />
      )}
    </div>
  </Grid>
)

DeliverToDoor.propTypes = {
  typ: PropTypes.string.isRequired,
  subTyp: PropTypes.string.isRequired,
  subRow: PropTypes.string,
  icon: PropTypes.string,
  button: PropTypes.string,
  selectedButton: PropTypes.string,
  handleDelivery: PropTypes.func,
  label: PropTypes.string,
}

export default React.memo(DeliverToDoor)
