import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { deliveryTypes } from '../../../../constants'
import { ServiceHours } from '../../../merchant/stores/service_hours'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  gridItem: {
    maxWidth: 450,
  },
  errorText: {
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    fontWeight: 300,
    marginTop: theme.spacing(2.5),
    color: theme.palette.secondary.main,
  },
}))

const WorkingHours = ({ workingHours, values, setValues, error }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const updateHour = (hour, newHour) => {
    let _serviceHours = Object.assign({}, values?.workingHours)
    let hours = _serviceHours[hour.orderType][hour.day]
    let currentHour = hours.find((h) => h === hour)
    Object.assign(currentHour, newHour)
    _serviceHours[hour.orderType][hour.day] = hours
    setValues({
      ...values,
      workingHours: _serviceHours,
    })
  }

  const addHour = (serviceType, serviceDay) => {
    let serviceHours = values?.workingHours

    let item = {
      fromTime: '09:00',
      toTime: '18:00',
      orderType: serviceType,
      day: serviceDay,
    }

    let prevItem = serviceHours[serviceType][serviceDay].slice(-1)[0]

    if (prevItem) {
      return
    } else {
      let _serviceHours = serviceHoursHandler(serviceType, serviceDay, item)
      setValues({
        ...values,
        workingHours: _serviceHours,
      })
    }
  }

  const removeHour = (hour) => {
    let serviceHours = values.workingHours
    let _serviceHours = Object.assign({}, serviceHours)
    _serviceHours[hour.orderType][hour.day] = serviceHours[hour.orderType][hour.day].filter(function (h) {
      return h !== hour
    })
    setValues({
      ...values,
      workingHours: _serviceHours,
    })
  }

  const serviceHoursHandler = (serviceType, serviceDay, item) => {
    let serviceHours = values.workingHours
    let newServiceHours = Object.assign({}, serviceHours)
    newServiceHours[serviceType][serviceDay] = serviceHours[serviceType][serviceDay].concat(item)
    return newServiceHours
  }

  return (
    <Container maxWidth='md'>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='column' style={{ maxWidth: 450 }}>
          <ServiceHours
            variant={deliveryTypes.DELIVERY}
            data={workingHours.DELIVERY}
            add={addHour}
            remove={removeHour}
            update={updateHour}
            editting={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className='column' style={{ maxWidth: 450 }}>
          <ServiceHours
            variant={deliveryTypes.TAKEOUT}
            data={workingHours.TAKEOUT}
            add={addHour}
            remove={removeHour}
            update={updateHour}
            editting={true}
          />
        </Grid>
      </Grid>
      {error && <Typography className={classes.errorText}>{f({ id: 'working-hours-error-message' })}</Typography>}
    </Container>
  )
}

WorkingHours.propTypes = {
  workingHours: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
  error: PropTypes.bool,
}

export default WorkingHours
