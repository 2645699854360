import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { attributeActions, merchantActions, restaurantActions } from '../../actions'
import { makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
// import { useTheme } from '@material-ui/core/styles'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import Header from './merchant-home/Header'
import DrawerMenu from './merchant-home/DrawerMenu'
import { roles } from '../../constants'
import { merchantStoreActions } from '../../actions/merchant.store.actions'
import MerchantRoutes from './merchant-home/MerchantRoutes'
import '../../styles/merchant_global.css'
import { merchantRolesMaker } from '../../helpers/merchant/merchant-roles'
import { drawerWidth, storeInfoTabs } from '../../helpers/merchant/drawer-menu'
import MerchantHomeContext from '../../context/merchant/MerchantHome/main'
import { isMobile } from 'react-device-detect'

const path = process.env.PUBLIC_URL

const availableRoutes = [
  'menus',
  'reports',
  'payments',
  'reviews',
  'employees',
  'stores',
  'settings',
  'orders',
  'bankinfo',
  'campaigns',
  'restaurant-info',
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
    color: 'rgba(105, 231, 129, 1)',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: isMobile ? 0 : drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  pageContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: isMobile ? 0 : -drawerWidth,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  pageContentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  mobileSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  list: {
    width: drawerWidth,
  },
}))

export const MerchantHome = ({ history, location }) => {
  const classes = useStyles()
  // const theme = useTheme()
  // const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  //Global states
  const merchant = useSelector(({ merchant }) => merchant.merchantInfo)
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const stores = useSelector(({ merchantStores }) => merchantStores.stores)
  const restaurants = useSelector(({ merchantStores }) => merchantStores.business)
  const isRestaurantView = useSelector(({ merchantStores }) => merchantStores.isRestaurantView)
  // Local states
  const [firstActiveMenu, setFirstActiveMenu] = useState()
  const [openSwipeable, setOpenSwipeable] = useState(isMobile ? false : true)
  const [expand, setExpand] = useState(false)
  const merchantRole = useMemo(() => merchantRolesMaker(merchant), [merchant])
  const isRestaurantAdmin = merchantRole.includes(roles.REST_ADMIN)
  const isStoreAdmin = merchantRole.includes(roles.STORE_ADMIN)

  // Actions
  const dispatch = useDispatch()
  const userSignout = () => dispatch(merchantActions.signout())
  const getBusiness = async (page, size) => dispatch(merchantStoreActions.getBusiness(page, size))
  const clearRestaurantState = () => dispatch(restaurantActions.clearState())
  const clearMerchantStores = () => dispatch(merchantStoreActions.clearState())
  const clearAttributesState = () => dispatch(attributeActions.clearState())
  const setSelectedStore = (store) => dispatch(merchantStoreActions.setSelectedStore(store))
  const setSelectedRestaurant = (rest) => dispatch(merchantStoreActions.setSelectedRestaurant(rest))
  const changeView = (bool) => dispatch(merchantStoreActions.changeRestaurantAdminView(bool))

  useEffect(() => {
    const init = async () => {
      await getBusiness()
      parseUrl()
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    // it is necessary when user switches screen size from large screen to tablet size
    isMobile ? setOpenSwipeable(false) : setOpenSwipeable(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  useEffect(() => {
    if (firstActiveMenu !== '') {
      parseUrl()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstActiveMenu])

  const parseUrl = () => {
    let { parts } = splitUrl()
    let url = history.location.pathname
    const isStoreDetailIncluded = url.includes('stores/')
    // if (parts.length >= 2) setFirstActiveMenu(parts[1])
    if (parts.length >= 3 && !isStoreDetailIncluded) {
      availableRoutes.includes(parts[2]) && setFirstActiveMenu(parts[2])
    } else if (isStoreDetailIncluded) {
      setFirstActiveMenu(parts[3])
    } else {
      setFirstActiveMenu()
    }
  }

  const splitUrl = () => {
    let url = history.location.pathname
    var parts = url.split('/')
    return { parts }
  }

  const handleDrawerOpen = () => {
    setOpenSwipeable(true)
  }

  const handleDrawerClose = () => {
    setOpenSwipeable(false)
  }

  const signOutHandler = () => {
    setFirstActiveMenu('')
    cleanStateHandler()
    userSignout()
    history.replace(`${path}/merchant`)
  }

  const cleanStateHandler = () => {
    clearRestaurantState()
    clearMerchantStores()
    clearAttributesState()
  }

  const listItemHandleClick = (text) => {
    isMobile && handleDrawerClose()
    setFirstActiveMenu(text)
    if (storeInfoTabs.includes(text) && selectedStore) {
      text === 'store-info' && toggleExpand()
      let newText = text === 'store-info' ? 'store-details' : text
      text = `stores/${newText}?storeid=${selectedStore.storeId}`
    }
    history.push(`${path}/merchant/${text}`)
  }

  const handleRestaurantChange = (event) => {
    const id = event.target.value
    let restaurant_ = restaurants.find((e) => e.restaurantId === +id)
    setSelectedRestaurant(restaurant_)
    !isRestaurantView && changeView(true)
    resetUrl()
  }
  const handleStoreChange = (event) => {
    const id = event.target.value
    let store_ = stores.find((e) => e.storeId === +id)
    setSelectedStore(store_)
    resetUrl()
  }

  const onChangeView = (bool) => {
    changeView(bool)
    resetUrl()
  }

  const resetUrl = () => {
    history.push(`${path}/merchant`)
    setFirstActiveMenu()
  }

  const toggleExpand = () => setExpand((prev) => !prev)
  // MERCHANTROUTES PROPS WILL BE DELETED AFTER add_store component is converted to functional

  return (
    <MerchantHomeContext.Provider
      value={{
        values: {
          firstActiveMenu,
          openSwipeable,
          classesFromParent: classes,
          isRestaurantAdmin,
          isStoreAdmin,
          expand,
        },
        setters: {
          openDrawer: handleDrawerOpen,
          close: handleDrawerClose,
          signOutHandler,
          listItemHandleClick,
          handleRestaurantChange,
          handleStoreChange,
          setView: onChangeView,
          setFirstActiveMenu,
        },
      }}
    >
      <div className={classes.root}>
        <Header />
        <DrawerMenu />
        <MerchantRoutes classesFromParent={classes} openSwipeable={openSwipeable} />
      </div>
    </MerchantHomeContext.Provider>
  )
}

MerchantHome.propTypes = {
  intl: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(MerchantHome)
