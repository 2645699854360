import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { initialQuery } from '../../../../constants'
import { searchActions } from '../../../../actions'
import { idFinderFabs } from '../../../../helpers/labelForm/idFinder'
import Fabs from './common/fabs'

const Sort = () => {
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()

  const resetSort = () => dispatch(searchActions.removeSortBySearchFilter())
  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const [idLabel, setIdLabel] = useState('id-label')

  useEffect(() => {
    if (searchData) {
      const label = idFinderFabs(searchData.orderByKey + searchData.orderByValue)
      setIdLabel(label)
    }
  }, [searchData])
  const fabText = idLabel ? f({ id: idLabel }) : ''
  return (
    <>
      {searchData.orderByKey && searchData.orderByKey !== initialQuery.orderByKey && (
        <Fabs text={`Sort by ${fabText}`} onClick={resetSort} />
      )}
    </>
  )
}

export default Sort
