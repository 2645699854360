import React, { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { orderStatuses } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  button: {
    width: 75,
    textTransform: 'none',
    height: 24,
    fontSize: 11,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: 'white',
  },
  acceptedButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    color: 'white',
    fontWeight: 500,
  },
  rejectedButton: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    color: 'white',
    fontWeight: 500,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}))

export const AcceptRejectButtons = ({ order, changeOrderStatus }) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()
  const [acceptOrder, setAcceptOrder] = useState('')

  const onClickHandler = (e) => {
    setAcceptOrder(e.target.innerText)
    const status = e.currentTarget.getAttribute('status')
    changeOrderStatus(status, order.orderId)
  }

  return (
    <div className={classes.buttonGroup}>
      <Button
        variant='outlined'
        size='small'
        onClick={onClickHandler}
        status={orderStatuses.ORDER_APPROVED}
        className={clsx(classes.button, {
          [classes.acceptedButton]: acceptOrder === format({ id: 'accept-order' }),
        })}
      >
        {format({ id: 'accept-order' })}
      </Button>
      <Button
        variant='outlined'
        size='small'
        onClick={onClickHandler}
        status={orderStatuses.ORDER_REJECTED}
        className={clsx(classes.button, {
          [classes.rejectedButton]: acceptOrder === format({ id: 'reject-order' }),
        })}
      >
        {format({ id: 'reject-order' })}
      </Button>
    </div>
  )
}
