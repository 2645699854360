import { withStyles } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'

export const SearchFab = withStyles((theme) => ({
  root: {
    height: 48,
    width: 48,
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}))(Fab)
