import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { deliveryTypes } from '../../../../../constants'
import yesIcon from '../../../../../images/yes.svg'
import deliveryIcon from '../../../../../images/address-delivery.svg'
import deliveryIconSelected from '../../../../../images/address-delivery-selected.svg'
import clsx from 'clsx'

const DeliveryRow = ({ typ, row, icon, button, selectedButton, handleDelivery, label }) => (
  <Grid item xs={12} className={row}>
    <img
      className={icon}
      src={typ === deliveryTypes.DELIVERY ? deliveryIconSelected : deliveryIcon}
      alt='where'
      height='20'
      width='20'
    />
    <div className='centered-row full-width'>
      <Button
        className={clsx({
          [button]: true,
          [selectedButton]: typ === deliveryTypes.DELIVERY,
        })}
        onClick={handleDelivery}
      >
        {label}
      </Button>
      <div className='grow' />
      {typ === deliveryTypes.DELIVERY && <img className={icon} src={yesIcon} alt='where' height='16' width='16' />}
    </div>
  </Grid>
)

DeliveryRow.propTypes = {
  typ: PropTypes.string.isRequired,
  row: PropTypes.string,
  icon: PropTypes.string,
  button: PropTypes.string,
  selectedButton: PropTypes.string,
  handleDelivery: PropTypes.func,
  label: PropTypes.string,
}

export default React.memo(DeliveryRow)
