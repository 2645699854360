import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import MerchantNoResult from '../../common/MerchantNoResult'
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const NoResult = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MerchantNoResult text='no-attribute' />
    </div>
  )
}

const EditAttributeList = ({ menuAttributes, handleConfirm }) => {
  const classes = useStyles()
  const [open, setOpen] = useState()

  const toggleOpen = (id) => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }
  if (menuAttributes?.length === 0) return <NoResult />

  return (
    <div className={classes.demo}>
      <List>
        {menuAttributes?.map((attribute, i) => {
          return (
            <React.Fragment key={i}>
              <ListItem divider>
                <ListItemText primary={attribute.name} />
                <ListItemSecondaryAction>
                  <Tooltip title='Detach Attribute from Menu'>
                    <IconButton aria-label='delete' onClick={() => handleConfirm(attribute)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  {attribute.values.length > 0 ? (
                    open === attribute.id ? (
                      <IconButton aria-label='expand-less' onClick={() => toggleOpen(attribute.id)}>
                        <ExpandLess />
                      </IconButton>
                    ) : (
                      <IconButton aria-label='expand-more' onClick={() => toggleOpen(attribute.id)}>
                        <ExpandMore />
                      </IconButton>
                    )
                  ) : (
                    <IconButton aria-label='disabled' disabled>
                      <ExpandMore style={{ color: 'transparent' }} />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={open === attribute.id} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {attribute.values.map((value, ind) => (
                    <ListItem key={ind} className={classes.nested}>
                      <ListItemText primary={value.name} secondary={`Price: $${value.price}`} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          )
        })}
      </List>
    </div>
  )
}

EditAttributeList.propTypes = {
  menuAttributes: PropTypes.array,
  handleConfirm: PropTypes.func,
}

export default injectIntl(EditAttributeList)
