import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, makeStyles } from '@material-ui/core'
import Prices from '../../../../common/Prices'
import { deliveryTypes } from '../../../../../constants'
import Promo from './Promo'
import { checkDeliveryDiscount } from '../../../../../helpers/promotion/delivery-discount'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}))

const PriceContainer = ({ tipValue }) => {
  const classes = useStyles()

  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)
  const savedOrder = useSelector(({ orders }) => orders.savedOrder, shallowEqual)

  const hideDeliveryFee = cart.type === deliveryTypes.TAKEOUT

  return (
    <Box pb={1} className={classes.container}>
      {!!savedOrder?.promotion && !!savedOrder?.discountPrice && <Promo promotion={savedOrder?.promotion} />}
      {/* <Prices title='small-order-fee' price={} variant='body2' /> */}
      {/* <Prices title='service-fee' price={} variant='body2' /> */}
      {!hideDeliveryFee && (
        <Prices
          title='delivery-fee'
          oldPrice={checkDeliveryDiscount(savedOrder)}
          price={cart?.deliveryFee || 0}
          variant='body2'
        />
      )}
      {!hideDeliveryFee && <Prices title='tip-label' price={+tipValue || 0} variant='body2' />}
      <Prices title='tax-label' price={cart.tax || 0} variant='body2' />
      <Prices title='total-label' price={cart.totalPrice + +tipValue} variant='h6' />
    </Box>
  )
}

export default PriceContainer
