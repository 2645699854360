const attributeChoosenChecker = (attr, menuAttr) => {
  const isRequired = attr.required
  return isRequired ? requiredAttributeCondition(attr, menuAttr) : nonRequiredAttributeChecker(attr, menuAttr)
}
const requiredAttributeCondition = (attr, menuAttr) => {
  let bool = menuAttr.some((e) => e.attributeId === attr.id && attr.minimumCount <= e.attributeValueIds.length)
  return bool
}
const nonRequiredAttributeChecker = (attr, menuAttr) => {
  let bool = menuAttr.some((e) => e.attributeId === attr.id)
  return bool
}

export { attributeChoosenChecker }
