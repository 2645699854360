import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
  },
}))

const UserSettings = ({ open, anchor, handleClose, id }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const history = useHistory()

  const goToUpdatePassword = () => {
    history.push(`${path}/merchant/user-settings/update-password`)
    handleClose()
  }
  return (
    <Popover
      id={id}
      disableRestoreFocus
      open={open}
      onClose={handleClose}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Button onClick={goToUpdatePassword} className={classes.button}>
        {f({ id: 'update-password-label' })}
      </Button>
    </Popover>
  )
}

UserSettings.propTypes = {
  open: PropTypes.bool,
  anchor: PropTypes.object,
  handleClose: PropTypes.func,
  id: PropTypes.string,
}

export default UserSettings
