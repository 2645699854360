import React, { useState, useEffect, useRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'
import { getCardIcon } from '../../../../helpers/card-icons'
import CheckIcon from '@material-ui/icons/Check'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { generalConstants } from '../../../../constants'
import { customerService } from '../../../../services'
import backIcon from '../../../../images/black-back.svg'
import { Transition } from '../../../utils/tools'
import { Elements } from '@stripe/react-stripe-js'
import InjectedAddCard from '../../savecard/injectedAddCard'
import { loadStripe } from '@stripe/stripe-js'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import clsx from 'clsx'
import Swipeout from 'rc-swipeout'
import 'rc-swipeout/assets/index.css'
import Loading from '../../../common/loading'
import MobileDialog from '../../../common/mobile-dialog'

const path = process.env.PUBLIC_URL
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
// const height = window.innerHeight

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // height: height,
    // maxHeight: height,
  },
  content: {
    overflow: 'scroll',
    flex: '1 50 auto',
    margin: theme.spacing(),
  },
  cartContainer: {
    width: '100%',
    marginBottom: theme.spacing(),
  },
  buttonLabel: {
    fontSize: 16,
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
  },
  buttonRoot: {
    display: 'flex',
    flexDirection: 'row',
    height: 48,
    width: '100%',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    '&:disabled': {
      backgroundColor: '#e6e6e6',
    },
  },
  cart: {
    textTransform: 'capitalize',
    color: '#000',
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    borderRadius: 10,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 6,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
  addButton: {
    textTransform: 'capitalize',
    color: '#69e781',
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    borderRadius: 10,
    border: 'solid 1px #69e781',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: 'white',
    boxShadow: 'none',
    marginBottom: 50,
    marginTop: 10,
  },
  title: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  creditTitle: {
    color: 'white',
    fontWeight: 'bold',
    '&[sub=true]': {
      paddingTop: theme.spacing(2),
    },
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  dialog: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    width: '100%',
    borderRadius: 10,
    height: 48,
  },
  generalDialogRoot: {
    width: 315,
    height: 463,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '15px 10px 15px 10px',
  },
  credit: {
    backgroundColor: theme.palette.primary.main,
    height: 60,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  creditRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

const MobilePaymentMethods = ({ classes, intl, handleClose, history }) => {
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [cards, setCards] = useState([])
  const [selectedCard, setSelectedCard] = useState(null)
  const [activeSlide, setActiveSlide] = useState(null)
  const [errorDialog, setErrorDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [userAccount, setUseAccount] = useState({})

  const slider = useRef()

  useEffect(() => {
    getPaymentMethods()
  }, [])

  useEffect(() => {
    getUserBalance()
  }, [])

  const getUserBalance = async () => {
    await customerService.getUserBalance().then(
      (res) => {
        setUseAccount(res.data)
      },
      (err) => {
        //handle error here
      }
    )
  }

  const getPaymentMethods = async () => {
    setLoading(true)
    await customerService.getPaymentMethods().then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setCards(res.data.paymentMethods)
          setSelectedCard(res.data.defaultPaymentMethod && res.data.defaultPaymentMethod)
          setActiveSlide(
            res.data.paymentMethods.indexOf(
              res.data.paymentMethods.filter((card) => card.id === res.data.defaultPaymentMethod)[0]
            )
          )
          setLoading(false)
        }
      },
      (err) => {
        // handle error here
        setLoading(false)
      }
    )
  }

  const handlePageClose = () => {
    handleClose?.() ?? history.push(`${path}/customer/account`)
  }

  const closeDeleteDialog = () => {
    setDeleteDialog(false)
  }

  const selectCard = (item) => {
    const id = item.id
    selectPaymentMethod(id)
  }

  const selectPaymentMethod = async (id) => {
    setLoading(true)
    await customerService.defaultPaymentMethod(id).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setSelectedCard(id)
          setLoading(false)
        }
      },
      (err) => {
        console.log('err--', err)
      }
    )
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleAddCardClose = () => {
    getPaymentMethods()
    setShowAddDialog(false)
  }

  const handleErrorClose = () => {
    setErrorDialog(false)
  }

  const swipeHandler = (id) => {
    setItemId(id)
    setDeleteDialog(true)
  }

  const handleDeleteCard = async (id) => {
    setLoading(true)
    await customerService.deletePaymentMethod(id).then(
      (res) => {
        res.status === generalConstants.STATUS_OK && getPaymentMethods()
        setDeleteDialog(false)
      },
      (err) => {
        console.log('err--', err)
        setLoading(false)
      }
    )
  }

  return (
    <div className={classes.root}>
      <Loading open={loading} />
      <div className='mobile-top-bar'>
        <IconButton size='small' className={classes.back} onClick={() => handlePageClose()}>
          <img src={backIcon} alt='Back' />
        </IconButton>
        <Typography variant='h6'>{getLabel('payment-label')}</Typography>
      </div>
      <div className={classes.credit}>
        <div className={classes.creditRow}>
          <Typography variant='h6' className={classes.creditTitle}>
            {getLabel('tookfresh-credit')}
          </Typography>
          <Typography variant='h6' className={classes.creditTitle}>
            ${userAccount?.balance?.toFixed(2)}
          </Typography>
        </div>
        <Typography variant='caption' className={classes.creditTitle} sub='true'>
          {getLabel('tookfresh-credit-message')}
        </Typography>
      </div>
      <div style={{ marginTop: 15 }}>
        <Typography variant='h6' className={classes.title}>
          {getLabel('payment-methods')}
        </Typography>
      </div>
      <div className={classes.content}>
        {cards.length !== 0 && (
          <Box display='flex' flexDirection='column' mt={2}>
            {cards.map((item, index) => (
              <Swipeout
                key={index}
                right={[
                  {
                    text: getLabel('delete-label'),
                    onPress: () => swipeHandler(item.id),
                    className: clsx('custom-class-2', [classes.dialog]),
                  },
                ]}
              >
                <div key={index} className={classes.cartContainer}>
                  <ButtonBase className='full-width' onClick={() => selectCard(item)}>
                    <div
                      className={classes.cart}
                      style={{
                        backgroundColor: selectedCard !== item.id ? '#e6e6e6' : null,
                      }}
                    >
                      {getCardIcon(item.card) ? (
                        <img src={getCardIcon(item.card)} alt='cart' width='45' height='45' />
                      ) : (
                        <div style={{ width: 45, height: 45 }} />
                      )}

                      {/* <Typography>{item.card.brand}</Typography> */}
                      <div className='centered-row'>
                        {Array(12)
                          .fill(0)
                          .map((e, i) => (
                            <div
                              key={i}
                              className=''
                              // variant='h4'
                              style={{
                                color: 'white',
                                marginRight: (i + 1) % 4 === 0 ? 10 : 0,
                                fontSize: 28,
                                marginTop: 12,
                              }}
                            >
                              *
                            </div>
                          ))}
                        <Typography variant='h5' style={{ color: 'white' }}>
                          {item.card.last4}
                        </Typography>
                      </div>
                      <CheckIcon style={{ color: selectedCard && selectedCard === item.id ? '#fff' : 'transparent' }} />
                    </div>
                  </ButtonBase>
                </div>
              </Swipeout>
            ))}
          </Box>
        )}
        <div className={classes.cartContainer}>
          <Button
            disabled
            classes={{
              root: classes.buttonRoot,
              label: classes.buttonLabel,
            }}
            onClick={() => {}}
          >
            {getLabel('applepay-label')}
          </Button>
        </div>
        <div className={classes.cartContainer}>
          <Button
            disabled
            classes={{
              root: classes.buttonRoot,
              label: classes.buttonLabel,
            }}
            onClick={() => setShowAddDialog(true)}
          >
            {getLabel('paypal-label')}
          </Button>
        </div>
        <div className={classes.cartContainer}>
          <Button
            disabled
            classes={{
              root: classes.buttonRoot,
              label: classes.buttonLabel,
            }}
            onClick={() => setShowAddDialog(true)}
          >
            {getLabel('googlepay-label')}
          </Button>
        </div>
        <Button className={classes.addButton} onClick={() => setShowAddDialog(true)}>
          {getLabel('new-payment-method')}
        </Button>
      </div>
      <Dialog fullScreen open={showAddDialog} onClose={handleAddCardClose} TransitionComponent={Transition}>
        <Elements stripe={promise}>
          <InjectedAddCard handleClose={handleAddCardClose} />
        </Elements>
      </Dialog>
      <Dialog
        classes={{ paper: classes.generalDialogRoot }}
        open={deleteDialog}
        onClose={closeDeleteDialog}
        TransitionComponent={Transition}
      >
        <MobileDialog
          close={closeDeleteDialog}
          closeIcon={false}
          header={'are-you-sure'}
          mainText={'about-delete-card'}
          leftBtnAction={closeDeleteDialog}
          rightBtnAction={handleDeleteCard}
          id={itemId && itemId}
          sizeW={'200px'}
          sizeH={'200px'}
          img={'deleteCardImage'}
          greenButton={false}
          leftButton={'cancel-label'}
          rightButton={'delete-label'}
        />
      </Dialog>
      <Dialog
        classes={{ paper: classes.generalDialogRoot }}
        open={errorDialog}
        onClose={handleErrorClose}
        TransitionComponent={Transition}
      >
        <MobileDialog
          close={handleErrorClose}
          closeIcon={false}
          header={'no-response-received'}
          mainText={'not-add-card'}
          leftBtnAction={null}
          rightBtnAction={null}
          id={false}
          sizeW={'279px'}
          sizeH={'200px'}
          img={'noResponse'}
          greenButton={true}
          leftButton={null}
          rightButton={null}
        />
      </Dialog>
    </div>
  )
}
export default withStyles(styles)(withRouter(injectIntl(MobilePaymentMethods)))
