import React from 'react'
import clsx from 'clsx'

import StepperMain from './AddStore/StepperMain'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    position: 'relative',
  },
}))
const AddStore_ = ({ classFromParent, openSwipeable }) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.root, classFromParent.pageContent, {
        [classFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <StepperMain />
    </div>
  )
}

export default AddStore_
