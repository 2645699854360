import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import { tfDefault, valueConstants } from '../../Helpers'

import TierField from '../../Fields/TierField_'

const TfDefault = ({ values, handleChange, error }) => {
  return (
    <Grid container spacing={2} className='full-width' style={{ marginTop: 16 }}>
      {tfDefault.map((tier, index) => (
        <Grid item md={6} sm={6} xs={12} key={index}>
          <TierField
            prop={tier.value}
            gridVal={12}
            handleChange={handleChange}
            startAdornment_={tier.value === valueConstants.minOrderPrice}
            endAdornment_={tier.value === valueConstants.maxDeliveryDistance}
            values={values}
            error={error}
          />
        </Grid>
      ))}
    </Grid>
  )
}

TfDefault.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
}

export default TfDefault
