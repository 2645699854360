import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import { CustomFormatCurrencyNew } from '../../../../utils/tools'

const borderHeight = {
  height: 48,
  borderRadius: 10,
  border: '1.5px solid #c4c4c4',
}

const commonFont = {
  fontSize: 14,
  fontWeight: 300,
  color: '#707070',
}

const useStyles = makeStyles((theme) => ({
  customButton: {
    ...borderHeight,
    width: '100%',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
  container: {
    ...borderHeight,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: theme.spacing(),
    minWidth: 120,
  },
  textField: {
    '& .MuiInputBase-input': {
      ...commonFont,
      padding: '4px 0px 4px 2px',
      '&:not(:focus)': {
        cursor: 'pointer',
      },
    },
  },
  icon: {
    fill: theme.palette.primary.main,
    cursor: 'pointer',
    "&[is-clear='true']": {
      fill: '#c4c4c4',
    },
  },
  adornment: {
    ...commonFont,
  },
}))

const CustomTip = ({ tip, clearTip, handleTip, handleCustomTip }) => {
  const classes = useStyles()

  return (
    <Grid item xs={3}>
      {tip?.isCustom ? (
        <TextField
          autoFocus
          autoComplete={'off'}
          id='custom-tip'
          name='customTip'
          className={classes.container}
          value={tip?.value || ''}
          onChange={handleCustomTip}
          InputProps={{
            inputComponent: CustomFormatCurrencyNew,
            endAdornment: tip?.isCustom && !!tip?.value && (
              <Fade in={tip?.isCustom && !!tip?.value}>
                <InputAdornment position='end'>
                  <CloseIcon onClick={clearTip} className={classes.icon} is-clear='true' />
                </InputAdornment>
              </Fade>
            ),
          }}
        />
      ) : (
        <Button className={classes.customButton} onClick={() => handleTip('custom')}>
          <FormattedMessage id='custom-label' />
        </Button>
      )}
    </Grid>
  )
}

CustomTip.propTypes = {
  tip: PropTypes.any,
  customTip: PropTypes.any,
  setCustomTip: PropTypes.func,
  clearTip: PropTypes.func,
  calculateTip: PropTypes.func,
}

export default CustomTip
