import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'auto',
    padding: 0,
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    "&[data-content='title']": {
      justifyContent: 'flex-start',
      fontWeight: 'bold',
      paddingBottom: 20,
    },
    "&[data-content='content']": {
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      color: '#707070',
    },
  },
}))

const Row = ({ text, info, title }) => {
  const classes = useStyles()
  return (
    <div className={classes.root} data-content={info ? 'content' : 'title'}>
      {title && title}
      {text && text}
      {info && info}
    </div>
  )
}

export default Row
