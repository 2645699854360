import React from 'react'
import PropTypes from 'prop-types'

import FirstTier from './FirstTier'
import SecondTier from './SecondTier'
import ThirdTier from './ThirdTier'

const TiersContainer = ({ values, handleChange, secondTierState, thirdTierState, addTier, removeTier, classes }) => {
  return (
    <>
      <FirstTier
        classes={classes}
        handleChange={handleChange}
        thirdTierState={thirdTierState}
        secondTierState={secondTierState}
        addTier={addTier}
        values={values}
      />
      {secondTierState && (
        <SecondTier
          classes={classes}
          handleChange={handleChange}
          thirdTierState={thirdTierState}
          addTier={addTier}
          removeTier={removeTier}
          values={values}
        />
      )}
      {thirdTierState && (
        <ThirdTier classes={classes} handleChange={handleChange} removeTier={removeTier} values={values} />
      )}
    </>
  )
}

TiersContainer.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  secondTierState: PropTypes.bool,
  thirdTierState: PropTypes.bool,
  classes: PropTypes.object,
  removeTier: PropTypes.func,
  addTier: PropTypes.func,
}

export default TiersContainer
