import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { filter } from 'lodash'
import {
  generalConstants,
  initialStoreState,
  initialStoreInformation,
  initialServiceHours,
  initialFocusedState,
  initialInvalidState,
  ownBusinessFocusedStates,
  ownBusinessInvalidStates,
  initialTierInformation,
  bankInfoArr,
} from '../../../constants'
import { bankinfoInitialState, bankinfoInvalidState } from '../../../constants/bank-info/states'
import { storeService } from '../../../services'
import { BlackButton } from '../../utils/tools'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { workHoursMaker, removeWorkingHours } from '../../../helpers/merchant/work-hours'
import { editButtonsDisableConditions } from '../../../helpers/merchant/button-status'
import { useSelector } from 'react-redux'
import {
  serviceHoursMaker,
  storeStateMaker,
  convertTiersToObject,
  handleTierInfo,
  handleBankData,
} from '../../../helpers/merchant/edit-store'
import clsx from 'clsx'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { splitPhoneNo } from './../../../helpers/merchant/splitPhoneNo'
import { valueConstants } from '../../businessRegister/businessConstants'

import StoreDetails from './ShowEditStore/StoreDetails'
import DeliveryOptions from './ShowEditStore/DeliveryOptions'
import Notifications from './ShowEditStore/Notifications'
import PaymentContactInfo from './ShowEditStore/PaymentContactInfo'
import BankInfo from './ShowEditStore/BankInfo'
import DetailsActionButtons from './ShowEditStore/DetailsActionButtons'
import WorkingHours from './ShowEditStore/WorkingHours'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../../actions'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  header: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
  },
  switch: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  bankInfoText: {
    width: '100%',
    height: '100%',
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const ShowEditStore = ({ location, history, subProp }) => {
  const { formatMessage: f } = useIntl()
  const values = useMemo(() => queryString.parse(location.search), [location])
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()

  const classes = useStyles()

  const { selectedRestaurant, selectedStore } = useSelector(({ merchantStores }) => merchantStores)

  //Local states
  const [store, setStore] = useState(initialStoreState)
  const [isFocused, setIsFocused] = useState(initialFocusedState)
  const [isInvalid, setIsInvalid] = useState(initialInvalidState)
  const [businessInvalidState, setBusinessInvalidState] = useState(ownBusinessInvalidStates)
  const [businessFocusedState, setBusinessFocusedState] = useState(ownBusinessFocusedStates)
  const [storeInformation, setStoreInformation] = useState(initialStoreInformation)
  const [serviceHours, setServiceHours] = useState(initialServiceHours)
  const [aptSuite, setAptSuite] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [orderConfirmCode, setOrderConfirmCode] = useState('')
  const [orderConfirmPhone, setOrderConfirmPhone] = useState('')
  const [paymentContactCode, setPaymentContactCode] = useState('')
  const [paymentContactPhone, setPaymentContactPhone] = useState('')
  const [phone, setPhone] = useState('')
  const [showServiceHours, setShowServiceHours] = useState(true)
  const [isServiceHoursChanges, setIsServiceHoursChanged] = useState(false)
  const [editting, setEditting] = useState(false)
  const [storeId, setStoreId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isDiscarded, setIsDiscarded] = useState(0)
  const [tierValues, setTierValues] = useState(initialTierInformation)
  const [bankInfo, setBankInfo] = useState(bankinfoInitialState)
  const [bankInfoInvalid, setBankInfoInvalid] = useState(bankinfoInvalidState)
  const [disabled, setDisabled] = useState(false)

  const dispatch = useDispatch()
  const displayErrorAlert = (msg) => dispatch(alertActions.error(msg))

  useEffect(() => {
    let active = true
    if (active) {
      if (store.tierInformations) {
        let result = convertTiersToObject(store.tierInformations)
        setTierValues(result)
      }
    }
    return () => {
      active = false
    }
  }, [store])

  useEffect(() => {
    if (values.storeid) {
      fetchStoreInfo()
    } else {
      setLoading(false)
      history.push(`${path}/merchant`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.storeid, isDiscarded])

  useEffect(() => {
    let active = true
    if (active) {
      if (editting) {
        fetchStoreInfo()
        setEditting(false)
      }
    }
    return () => {
      active = false
    }
  }, [subProp])

  useEffect(() => {
    let active = true
    if (active) {
      if (editting) {
        let disabled_ = editButtonsDisableConditions(
          subProp,
          store,
          bankInfo,
          storeInformation,
          tierValues,
          orderConfirmPhone,
          paymentContactPhone,
          phone,
          serviceHours
        )
        setDisabled(disabled_)
      }
    }
    return () => {
      active = false
    }
  }, [
    subProp,
    store,
    bankInfo,
    storeInformation,
    editting,
    tierValues,
    orderConfirmPhone,
    paymentContactPhone,
    phone,
    serviceHours,
  ])

  const discardChanges = (e) => {
    setIsDiscarded((prev) => prev + 1)
    setEditting(false)
    setIsInvalid(initialInvalidState)
    setBankInfoInvalid(bankinfoInvalidState)
    setBusinessInvalidState(ownBusinessInvalidStates)
  }

  const fetchStoreInfo = async () => {
    setLoading(true)
    await storeService.findStoreMerchant(selectedStore?.storeId).then((res) => {
      if (res.status === generalConstants.STATUS_OK) {
        let store = res.data
        let [codePart, phonePart] = splitPhoneNo(store.storeInformation.phone)
        let [orderCodePart, orderPhonePart] = splitPhoneNo(store.orderConfirmationPhone)
        let [contactCode, contactPhone] = splitPhoneNo(store.bankInformation.paymentContactPhone)
        let storeState = storeStateMaker(store)
        let newServiceHours = serviceHoursMaker(store)
        let tierInfo = store.tierInformations ? handleTierInfo(store.tierInformations) : initialTierInformation
        let bankData = handleBankData(store)
        setStoreId(store.id)
        setStore(storeState)
        setStoreInformation(store.storeInformation)
        setTierValues(tierInfo)
        setCountryCode(codePart)
        setPhone(phonePart)
        setOrderConfirmCode(orderCodePart)
        setOrderConfirmPhone(orderPhonePart)
        setPaymentContactCode(contactCode)
        setPaymentContactPhone(contactPhone)
        setAptSuite(store.storeInformation.address.addressLine2)
        setServiceHours(newServiceHours)
        setBankInfo(bankData)
      }
      setLoading(false)
    })
  }

  const onFormSubmit = async (event) => {
    event.preventDefault()
    try {
      setLoading(true)
      const restId = selectedRestaurant.restaurantId
      let resArr = filter(tierValues, (item) => item.isActive === true)
      let store_ = { ...store }
      let bankInformation = { ...bankInfo }
      store_.storeInformation = { ...storeInformation }
      store_.storeInformation.phone = `${countryCode} ${phone}`
      store_.orderConfirmationPhone = `${orderConfirmCode} ${orderConfirmPhone}`
      store_.storeInformation.address.addressLine2 = aptSuite
      store_.tierInformations = store.deliveryBy === valueConstants.tookfresh ? [] : resArr
      store_.maxDeliveryDistance = store.deliveryBy === valueConstants.ownBusiness ? '' : store.maxDeliveryDistance
      store_.minOrderPrice = store.deliveryBy === valueConstants.ownBusiness ? '' : store.minOrderPrice
      bankInformation.paymentContactPhone = `${paymentContactCode} ${paymentContactPhone}`
      bankInformation.checkAddress = !bankInformation.address ? null : bankInformation.address
      bankInformation.paymentMethod = bankInfoArr.includes(bankInformation.paymentMethod)
        ? bankInformation.paymentMethod
        : null
      delete bankInformation.address
      store_.bankInformation = bankInformation
      isServiceHoursChanges
        ? (store_.storeInformation.workingHours = workHoursMaker(serviceHours))
        : (store_.storeInformation = removeWorkingHours(store_.storeInformation))
      const response = await storeService.update(restId, store_, storeId)
      if (response.status === generalConstants.STATUS_OK) {
        setLoading(false)
        setEditting(false)
        fetchStoreInfo()
        // history.push({ pathname: `${path}/merchant`, state: 'refresh' })
      } else {
        setLoading(false)
      }
    } catch (err) {
      // when email or phone is in use, 500 error code returns from backend
      // else block cannot catch this up. Because our service file always returns "res"
      setLoading(false)
      displayErrorAlert(err.response.data?.error_message.toString())
    }
  }

  const contents = {
    'store-details': (
      <StoreDetails
        classes={classes}
        store={store}
        storeInformation={storeInformation}
        isFocused={isFocused}
        isInvalid={isInvalid}
        editting={editting}
        setIsFocused={setIsFocused}
        setIsInvalid={setIsInvalid}
        setStore={setStore}
        setStoreInformation={setStoreInformation}
        countryCode={countryCode}
        phone={phone}
        setPhone={setPhone}
        setCountryCode={setCountryCode}
        aptSuite={aptSuite}
        setAptSuite={setAptSuite}
      />
    ),
    'delivery-options': (
      <DeliveryOptions
        classes={classes}
        store={store}
        setStore={setStore}
        isFocused={isFocused}
        isInvalid={isInvalid}
        setIsInvalid={setIsInvalid}
        setIsFocused={setIsFocused}
        editting={editting}
        businessInvalidState={businessInvalidState}
        setBusinessInvalidState={setBusinessInvalidState}
        businessFocusedState={businessFocusedState}
        setBusinessFocusedState={setBusinessFocusedState}
        tierValues={tierValues}
        setTierValues={setTierValues}
      />
    ),
    notifications: (
      <Notifications
        store={store}
        setStore={setStore}
        isInvalid={isInvalid}
        isFocused={isFocused}
        setIsInvalid={setIsInvalid}
        setIsFocused={setIsFocused}
        editting={editting}
        storeInformation={storeInformation}
        orderConfirmCode={orderConfirmCode}
        orderConfirmPhone={orderConfirmPhone}
        setOrderConfirmCode={setOrderConfirmCode}
        setOrderConfirmPhone={setOrderConfirmPhone}
      />
    ),
    'payment-contact-info': (
      <PaymentContactInfo
        editting={editting}
        storeInformation={storeInformation}
        bankInfo={bankInfo}
        setBankInfo={setBankInfo}
        paymentContactCode={paymentContactCode}
        paymentContactPhone={paymentContactPhone}
        setPaymentContactCode={setPaymentContactCode}
        setPaymentContactPhone={setPaymentContactPhone}
        bankInfoInvalid={bankInfoInvalid}
        setBankInfoInvalid={setBankInfoInvalid}
      />
    ),
    bankinfo: (
      <BankInfo
        classes={classes}
        editting={editting}
        setBankInfo={setBankInfo}
        bankInfo={bankInfo}
        bankInfoInvalid={bankInfoInvalid}
        setBankInfoInvalid={setBankInfoInvalid}
      />
    ),
    'working-hours': (
      <WorkingHours
        classes={classes}
        setServiceHours={setServiceHours}
        setIsServiceHoursChanged={setIsServiceHoursChanged}
        showServiceHours={showServiceHours}
        setShowServiceHours={setShowServiceHours}
        serviceHours={serviceHours}
        editting={editting}
      />
    ),
  }

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <form onSubmit={onFormSubmit} style={{ width: '100%' }}>
        <div className='page-header' style={{ marginTop: 70 }}>
          <Typography variant='h4'>{f({ id: 'store-label' })}</Typography>
          <div className='grow' />
          {!editting && <BlackButton onClick={() => setEditting(true)}>{f({ id: 'edit-store' })}</BlackButton>}
        </div>
        {contents[subProp]}
        {editting && <DetailsActionButtons disableStatus={disabled} discardChanges={discardChanges} />}
      </form>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

ShowEditStore.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  subProp: PropTypes.string,
}

export default withRouter(ShowEditStore)
