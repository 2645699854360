import { Button, withStyles } from '@material-ui/core'

export const SearchButton = withStyles((theme) => ({
  root: {
    height: 40,
    borderRadius: 20,
    textTransform: 'capitalize',
  },
  label: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    color: '#c4c4c4',
    justifyContent: 'left',
  },
}))(Button)
