import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import closeIcon from '../../../../../images/close-icon.svg'
import Title from '@material-ui/icons/Title'

const useStyles = makeStyles((theme) => ({
  stepTwoRow: {
    borderBottom: '1px solid #f7f7f7',
    padding: theme.spacing(),
    maxHeight: 46,
    display: 'flex',
    marginBottom: 50,
    "&[is-selected='true']": {
      marginBottom: 0,
    },
  },
  stepTwoIcon: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    fill: 'rgba(0, 0, 0, 0.55)',
  },
  field: {
    paddingLeft: theme.spacing(),
  },
}))

const AddressName = ({ intl, details, setDetails }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item xs={12} className={classes.stepTwoRow} is-selected={details.deliveryType === '' ? 'false' : 'true'}>
      <Title className={classes.stepTwoIcon} />
      <TextField
        value={details.addressName}
        fullWidth
        onChange={(e) => setDetails({ ...details, addressName: e.target.value })}
        InputProps={{
          disableUnderline: true,
          classes: { input: classes.field },
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton className={classes.iconButton} onClick={() => setDetails({ ...details, addressName: '' })}>
                <img src={closeIcon} alt='close' height='14px' width='14px' />
              </IconButton>
            </InputAdornment>
          ),
        }}
        placeholder={getLabel('address-name')}
      />
    </Grid>
  )
}

AddressName.propTypes = {
  details: PropTypes.object.isRequired,
  setDetails: PropTypes.func.isRequired,
}

export default injectIntl(AddressName)
