import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Chip from '@material-ui/core/Chip'
import { getDietaryIcon } from '../../../helpers/dietary-icon'
import { getImage } from '../../../helpers/get-image'
import Tooltip from '@material-ui/core/Tooltip'

const imageWidth = 160

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    height: imageWidth,
    backgroundColor: '#fff',
    borderRadius: 6,
    display: 'flex',
    border: '1.5 solid',
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.15)',
    position: 'relative',
    cursor: 'pointer',
  },
  boxRoot: {
    width: '100%',
    borderRadius: 6,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.3,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#707070',
    maxWidth: '14rem',
  },
  details: {
    height: imageWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: imageWidth,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(),
  },
  media: {
    backgroundColor: '#f7f7f7',
    height: imageWidth,
    width: imageWidth,
    minWidth: imageWidth,
    flex: 'initial',
  },
  button: {
    width: 41,
    height: 41,
    backgroundColor: '#fff',
    borderBottomRightRadius: 2,
    borderTopLeftRadius: 15,
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 40,
  },
  add: {
    width: 32,
    height: 32,
    borderRadius: 22,
    backgroundColor: '#c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  dietaryIcon: {
    width: 20,
    height: 20,
    marginTop: 4,
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
  },
}))

function MenuItem(props) {
  const classes = useStyles()
  const [orderIds, setOrderIds] = useState()
  const [currentStore, setCurrentStore] = useState(false)
  const dietaryExist = props.data.dietaries.length > 0

  useEffect(() => {
    props.storeId === props.store?.id ? setCurrentStore(true) : setCurrentStore(false)
    findOrderIds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const findOrderIds = () => {
    let menuIds = []
    props.orderMenus && props.orderMenus.length > 0 && props.orderMenus.map((menu) => menuIds.push(menu.menuId))
    return setOrderIds(menuIds)
  }

  const menuImg = getImage(props?.data?.images[0], 'thumb')

  return (
    <Box
      className={classes.boxRoot}
      border={2}
      borderColor={currentStore && orderIds && orderIds.includes(props.data.id) ? 'primary.main' : 'white'}
    >
      <Card className={classes.root} onClick={props.handleClick}>
        <CardActionArea className={classes.details}>
          <CardContent className={classes.content}>
            <Typography className={classes.title} noWrap>
              {props.data.name}
            </Typography>
            {dietaryExist && (
              <div className='centered-row'>
                {props.data.dietaries.map((dietary, index) => (
                  <Tooltip key={index} title={dietary.name} placement='bottom' arrow>
                    <img
                      alt=''
                      src={getDietaryIcon(dietary.name)}
                      width='20'
                      height='20'
                      className={classes.dietaryIcon}
                    />
                  </Tooltip>
                ))}
              </div>
            )}
            <Typography variant='body2' color='textPrimary' component='p' className='elipsis'>
              {props.data.description}
            </Typography>
            <div className='grow' />
            <Typography variant='body2' color='textSecondary' component='p'>
              ${props.data.price}
            </Typography>
          </CardContent>
        </CardActionArea>
        {props.data.images.length > 0 && (
          <CardMedia className={classes.media} image={menuImg} title={props.data.name} />
        )}
        <div className={classes.button}>
          {props.alreadyOrderedCount === 0 || !currentStore ? (
            <div className={classes.add}>
              <AddIcon />
            </div>
          ) : (
            <Chip color='primary' label={props.alreadyOrderedCount} style={{ margin: 3, cursor: 'pointer' }} />
          )}
        </div>
      </Card>
    </Box>
  )
}

MenuItem.propTypes = {
  data: PropTypes.object.isRequired,
  alreadyOrderedCount: PropTypes.number,
  handleClick: PropTypes.func,
}

const mapStateToProps = (state) => {
  const { orders } = state
  return {
    storeId: orders.cart.storeId,
    orderMenus: orders.cart.orderMenus,
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(MenuItem)))
