import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Divider, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import SearchAddress from '../../../utils/address_search'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
    margin: theme.spacing(),
  },
  address: {
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderRadius: 5,
    minHeight: 39,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#fff',
    '&:hover': {
      border: `1px solid rgba(0, 0, 0, 0.87)`,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}))

const Address = ({ storeInfo, aptSuite, intl, addressHandler, setAptSuite, editting, addStore, checkAddress }) => {
  const classes = useStyles()
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid container style={{ marginBottom: 50 }} className='full-width' spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4' className={classes.subtitle}>
          {checkAddress ? getLabel('check-address') : getLabel('address-label')}
        </Typography>
        <Divider />
      </Grid>
      <Grid item sm={6} xs={12}>
        <div className={classes.address}>
          {(addStore || storeInfo.address.formattedAddress || checkAddress) && (
            <SearchAddress
              value={storeInfo.address.formattedAddress || ''}
              onChange={addressHandler}
              placeholder={checkAddress ? getLabel('enter-check-address') : getLabel('enter-store-address')}
              readOnly={!editting}
              typesRef={['address']}
            />
          )}
        </div>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          size='small'
          variant='outlined'
          fullWidth
          id='aptNumber'
          label={getLabel('apt-suite')}
          value={aptSuite}
          onChange={(e) => setAptSuite(e.target.value)}
          inputProps={{ maxLength: 50, readOnly: !editting }}
        />
      </Grid>
    </Grid>
  )
}

Address.propTypes = {
  storeInfo: PropTypes.object,
  aptSuite: PropTypes.string,
  intl: PropTypes.object,
  addressHandler: PropTypes.func.isRequired,
  setAptSuite: PropTypes.func.isRequired,
  editting: PropTypes.bool,
  checkAddress: PropTypes.bool,
  addStore: PropTypes.bool,
}

export default injectIntl(Address)
