import React from 'react'
import ContentLoader from 'react-content-loader'

const CartMenuItemCL = () => {
  return (
    <ContentLoader viewBox='0 0 300 60'>
      <rect x='0' y='0' rx='5' ry='5' width='45' height='35' />
      <rect x='55' y='0' rx='4' ry='4' width='170' height='10' />
      <rect x='235' y='0' rx='4' ry='4' width='40' height='10' />
      <rect x='285' y='0' rx='4' ry='4' width='10' height='15' />
      <rect x='55' y='20' rx='3' ry='3' width='120' height='5' />
      <rect x='55' y='30' rx='3' ry='3' width='120' height='5' />
      <rect x='55' y='40' rx='3' ry='3' width='120' height='5' />
    </ContentLoader>
  )
}

export default CartMenuItemCL
