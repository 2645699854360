const greenButtonStatusChecker = (attributes, menuAttributes) => {
  //get only required attributes
  const requiredAttributes = attributes.filter((attr) => attr.required)

  //if requiredAttributes length is bigger than menuAttributes length which means
  //some of the required attributes has not been already selected.
  //so button should be disabled
  if (requiredAttributes.length > menuAttributes.length) return false

  //check how many of required attributes are included in menu attributes
  let reqAttrCountInMenuAttr = 0
  menuAttributes.forEach((menuAttr) => {
    requiredAttributes.forEach((reqAttr) => {
      if (reqAttr.id === menuAttr.attributeId) {
        reqAttrCountInMenuAttr += 1
      }
    })
  })

  //if all required attributes are included in menu attributes, set includes condition true
  let includes = false
  reqAttrCountInMenuAttr === requiredAttributes.length && (includes = true)

  //if menu attributes include required ones, then check if minimum count status of required attributes has been satisfied
  if (includes) {
    let requiredCondition = 0
    menuAttributes.forEach((menuAttr) => {
      requiredAttributes.forEach((reqAttr) => {
        if (reqAttr.id === menuAttr.attributeId) {
          if (reqAttr.minimumCount <= menuAttr.attributeValueIds.length) {
            requiredCondition += 1
          }
        }
      })
    })
    if (requiredCondition === requiredAttributes.length) return true
    return false
  }
  return false
}

export { greenButtonStatusChecker }

// const greenButtonStatusChecker = (attributes, menuAttributes) => {
//   let [greenButtonDisableStatus, requiredCount, selectedRequiredCount] = [false, 0, 0]

//   // get only required attributes
//   const requiredAttributes = attributes.filter((attr) => attr.required)
//   // what is the total minimumCount of required attribute
//   requiredAttributes.forEach((e) => {
//     requiredCount += e.minimumCount
//   })
//   // how many required attribute is selected
//   menuAttributes.forEach((menuAttr) => {
//     const includes = requiredAttributes.some((reqAttr) => reqAttr.id === menuAttr.attributeId)
//     includes && (selectedRequiredCount += menuAttr.attributeValueIds.length)
//   })
//   // if selectedCount === total required count, button gets activated
//   selectedRequiredCount === requiredCount && (greenButtonDisableStatus = true)
//   return greenButtonDisableStatus
// }

// export { greenButtonStatusChecker }
