import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import SearchResult from './content/SearchResult'
import { useSelector } from 'react-redux'
import RenderCampaigns from './content/RenderCampaigns'

const useStyles = makeStyles((theme) => ({
  content: {
    overflow: 'scroll',
    flex: '1 50 auto',
    marginTop: theme.spacing(),
    // marginTop: isIOS ? topHeight : theme.spacing(),
  },
  withCart: {
    marginBottom: 120,
  },
  withoutCart: {
    marginBottom: 60,
  },
}))

const Content = ({
  searchResult,
  changed,
  campaignTypes,
  fetchMoreData,
  favoriteHandler,
  favoriteStores,
  openStores,
}) => {
  const classes = useStyles()
  const orderMenu = useSelector(({ orders }) => orders.cart.orderMenus)
  const signinError = useSelector(({ customer }) => customer.error)
  if (signinError) return null
  return (
    <div className={classes.content}>
      <Grid
        container
        className={clsx(
          'vertical-scrolling-wrapper-flexbox',
          searchResult?.length > 0 && orderMenu?.length > 0 ? classes.withCart : classes.withoutCart
        )}
      >
        {!changed ? (
          campaignTypes?.length > 0 && <RenderCampaigns campaignTypes={campaignTypes} />
        ) : (
          <SearchResult
            searchResult={searchResult}
            fetchMoreData={fetchMoreData}
            favoriteHandler={favoriteHandler}
            favoriteStores={favoriteStores}
            openStores={openStores}
          />
        )}
      </Grid>
    </div>
  )
}

Content.propTypes = {
  searchResult: PropTypes.array,
  campaignTypes: PropTypes.array,
  fetchMoreData: PropTypes.func,
  favoriteHandler: PropTypes.func,
  favoriteStores: PropTypes.array,
  openStores: PropTypes.array,
}

export default Content
