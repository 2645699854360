import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Dialog } from '@material-ui/core'
import { LeftTransition, Transition } from '../../../utils/tools'
import { MobileStoreMore } from '../MobileStoreMore'
import MenuItemDialog from '../MenuItemDialog'
import MobileDialog from '../../../common/mobile-dialog'
import MobileSignin from '../../../authentication/MobileSignin'

const styles = () => ({
  generalDialogRoot: {
    width: 315,
    height: 463,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '15px 10px 15px 10px',
  },
})

export const RelatedDialogs = ({
  showMoreDialog,
  handleMoreClose,
  store,
  showMenuDialog,
  selectedMenu,
  closeMenuDialog,
  showNewOrderDialog,
  newOrder,
  idProp,
  closeNewOrderDialog,
  addMenuToCart,
  classes,
  storeOpened,
  showMobileSigninDialog,
  handleSigninModalClose,
  handleSignin,
}) => {
  return (
    <>
      <Dialog fullScreen open={showMoreDialog} onClose={handleMoreClose} TransitionComponent={LeftTransition}>
        <MobileStoreMore handleClose={handleMoreClose} store={store} storeOpen={storeOpened} />
      </Dialog>
      <MenuItemDialog
        open={showMenuDialog}
        data={selectedMenu}
        onClose={closeMenuDialog}
        onCreateNewOrder={newOrder}
        storeId={idProp}
        storeOpened={storeOpened}
      />
      <Dialog
        classes={{ paper: classes.generalDialogRoot }}
        open={showNewOrderDialog}
        onClose={closeNewOrderDialog}
        TransitionComponent={Transition}
      >
        <MobileDialog
          close={closeNewOrderDialog}
          closeIcon={false}
          header={'new-order-alert-title'}
          mainText={'new-order-alert-text'}
          leftBtnAction={closeNewOrderDialog}
          rightBtnAction={addMenuToCart}
          id={false}
          sizeW={'200px'}
          sizeH={'200px'}
          img={'newOrderImage'}
          greenButton={false}
          leftButton={'cancel-label'}
          rightButton={'create-new-order'}
        />
      </Dialog>
      <Dialog
        fullScreen
        open={showMobileSigninDialog}
        onClose={handleSigninModalClose}
        TransitionComponent={Transition}
      >
        <MobileSignin handleClose={handleSigninModalClose} handleSignin={handleSignin} />
      </Dialog>
    </>
  )
}

RelatedDialogs.propTypes = {
  // props: PropTypes,
}

export default withStyles(styles)(RelatedDialogs)
