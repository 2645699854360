import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'

import { searchActions } from '../../../../actions'
import { sortFilterLabels } from '../../../../constants/general.constants'
import { parameters } from '../../../../helpers/sort/parameters'
import LabelForm from './sort/labelForm'

import { Typography, FormLabel, FormControl, RadioGroup } from '@material-ui/core'

const Sort = ({ classes }) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)

  const setSortByOrder = (value, order) => dispatch(searchActions.setSearchSortByOrder(value, order))

  const handleSortByChange = (event) => {
    let key = event.target.name
    setSortByOrder(parameters(key).value, parameters(key).order)
  }

  const radioValue = searchData.orderByKey + searchData.orderByValue

  return (
    <FormControl component='fieldset' className={classes.formControl}>
      <FormLabel component='legend'>
        <Typography variant='subtitle1' className={classes.groupTitle}>
          {f({ id: 'sort-by' })}
        </Typography>
      </FormLabel>
      <RadioGroup aria-label='sort' name='orderByKey' value={radioValue} onChange={handleSortByChange}>
        {sortFilterLabels?.map((filter, index) => (
          <LabelForm key={index} classes={classes} value={filter} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default Sort
