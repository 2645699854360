import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { FormattedMessage } from 'react-intl'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { merchantStoreActions } from '../../../actions/merchant.store.actions'
import { generalConstants, rowsPerPageOptions } from '../../../constants'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { useToggle } from '../../../hooks/useToggle'
import { campaignsHeader } from '../../../helpers/merchant/table-header'
import { campaignService } from '../../../services'
import { GreenButton, RedButton } from '../../utils/tools'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginTop: 70,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  table: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  activity: {
    '&[active=true]': {
      color: theme.palette.primary.main,
    },
    '&[active=false]': {
      color: theme.palette.secondary.main,
    },
  },
  button: {
    height: 30,
    paddingRight: 0,
    paddingLeft: 0,
    fontSize: 14,
  },
}))

const Campaigns = () => {
  const classes = useStyles()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()
  // Global states
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const storeData = useSelector(({ merchantStores }) => merchantStores)

  // Local states
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [loading, setLoading] = useToggle()
  const [promotions, setPromotions] = useState([])
  const [isUpdated, setIsUpdated] = useState(0)
  // Actions
  const dispatch = useDispatch()
  const getBusiness = async (page, size) => dispatch(merchantStoreActions.getBusiness(page, size))

  useEffect(() => {
    const init = async () => {
      await getBusiness()
      await getCampaigns()
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated])

  const getCampaigns = async () => {
    setLoading()
    const res = await campaignService.getStorePromotions(selectedStore?.storeId)
    if (res.status === generalConstants.STATUS_OK) {
      setPromotions(res.data)
    } else {
      // handle error here
    }
    setLoading()
  }

  // const handleSelectClick = (event, item) => {
  //   event.target.checked ? setSelected(item.storeId) : setSelected()
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(+event.target.value)
  }

  const activatePromo = async (promoId) => {
    setLoading()
    const res = await campaignService.activatePromotion(selectedStore?.storeId, promoId)
    if (res.status === generalConstants.STATUS_OK) {
      setIsUpdated((prev) => prev + 1)
    } else {
      // handle error here
    }
    setLoading()
  }
  const deactivatePromo = async (promoId) => {
    setLoading()
    const res = await campaignService.deActivatePromotion(selectedStore?.storeId, promoId)
    if (res.status === generalConstants.STATUS_OK) {
      setIsUpdated((prev) => prev + 1)
    } else {
      // handle error here
    }
    setLoading()
  }

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby='Stores' size='medium' aria-label='stores table'>
            <TableHead>
              <TableRow>
                {campaignsHeader.map((header, i) => (
                  <TableCell key={header.label}>
                    <FormattedMessage id={header.label} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {promotions?.map((promo, i) => {
                return (
                  <TableRow key={promo?.name + promo?.id} hover>
                    <TableCell variant='body'>{promo?.name}</TableCell>
                    <TableCell variant='body' className={classes.activity} active={promo?.active.toString()}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <FiberManualRecordIcon style={{ marginRight: 10 }} />
                        {promo?.active ? 'Active' : 'Not Active'}
                      </div>
                    </TableCell>
                    <TableCell variant='body'>{promo?.startDate}</TableCell>
                    <TableCell variant='body'>{promo?.endDate}</TableCell>
                    <TableCell variant='body'>
                      {!promo?.active ? (
                        <GreenButton
                          variant='contained'
                          color='primary'
                          type='submit'
                          fullWidth
                          // disabled={!this.state.email || this.state.emailInvalid}
                          className={classes.button}
                          onClick={() => activatePromo(promo?.id)}
                        >
                          <FormattedMessage id='activate-label' />
                        </GreenButton>
                      ) : (
                        <RedButton
                          variant='contained'
                          color='primary'
                          type='submit'
                          fullWidth
                          // disabled={!this.state.email || this.state.emailInvalid}
                          className={classes.button}
                          onClick={() => deactivatePromo(promo?.id)}
                        >
                          <FormattedMessage id='deactivate-label' />
                        </RedButton>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={promotions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={storeData.loading || loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

Campaigns.propTypes = {
  history: PropTypes.object,
}

export default Campaigns
