import moment from 'moment'
import { orderStatuses } from '../../constants'

export const getPreviousOrderStatusLabel = (orderStatus) => {
  return previousOrderStatusLabels[orderStatus]
}

export const getDetailedOrderInfo = (order) => {
  const { orderStatusHistories: history } = order
  // orderStatusHistories implemented recently. So, it comes [] for orders before its implementation
  // Moment display the current date-time in this case
  const latestStatus = history[history?.length - 1]
  const date = moment(latestStatus?.transactionDate).format('YYYY-MM-DD')
  const time = moment(latestStatus?.transactionDate).format('HH:mm')
  return `${date} at ${time} Order Number ${order.orderNumber}`
}

// This object can be extended according to the new stutus
const previousOrderStatusLabels = {
  [orderStatuses.DELIVERED]: 'order-done',
  [orderStatuses.CANCELED]: 'order-canceled',
  [orderStatuses.ORDER_REJECTED]: 'order-rejected',
}

const getLatLongOfStore = (storeDetails, order) => {
  let latLong = ''
  const store = storeDetails.find((store) => store?.storeId === order?.store?.id)
  latLong += `${store?.address?.latitude},${store?.address?.longitude}`
  return latLong
}

export const mapsSelector = (storeDetails, order) => {
  const latLong = getLatLongOfStore(storeDetails, order)
  if (
    navigator.userAgent.includes('iPhone') ||
    navigator.userAgent.includes('iPad') ||
    navigator.userAgent.includes('iPod')
  ) {
    window.open(`maps://maps.google.com/maps?daddr=${latLong}&amp;ll=`)
  } else {
    window.open(`https://maps.google.com/maps?daddr=${latLong}&amp;ll=`)
  }
}
