import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter, Redirect } from 'react-router-dom'
import TopNavbar from '../top_navbar'
import Footer from '../Footers'
import FoodTypes from './food_types'
import Container from '@material-ui/core/Container'
import Banner from '../delivery_banner/banner'
import ScrollComponent from '../search/scroll_coponent'
import CampaignCarousel from '../campaigns/campaign_carousel'
import { campaignActions } from '../../../actions'
import Loading from '../../common/loading'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    height: 'auto',
    minHeight: '100vh',
  },
  foodTypes: {
    marginBottom: theme.spacing(4),
  },
  container: {
    minHeight: 400,
    height: 'auto',
  },
}))

const Food = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const getCampaigns = () => dispatch(campaignActions.getAllCampaigns())

  const categorySelection = useSelector(({ search }) => search.searchQuery.categoryIds)
  const searchQuery = useSelector(({ search }) => search.searchQuery)
  const campaigns = useSelector(({ campaigns }) => campaigns.data)
  const loading = useSelector(({ campaigns }) => campaigns.loading)

  useEffect(() => {
    let active = true
    if (active) {
      getCampaigns()
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!searchQuery.address) return <Redirect to={`${path}/customer`} />
  return (
    <div className={classes.root}>
      <Loading open={loading} />
      <Container maxWidth='lg' className='desktop'>
        <TopNavbar cartVisible={true} />
      </Container>
      <Container maxWidth={false} disableGutters>
        <Banner showMoreFilters={true} />
      </Container>
      <Container maxWidth='lg' className={classes.foodTypes}>
        <FoodTypes />
      </Container>
      <Container maxWidth='lg' className={clsx('desktop', [classes.container])}>
        {categorySelection?.length > 0 ? (
          <ScrollComponent foodComponent={true} />
        ) : (
          campaigns?.length > 0 &&
          campaigns?.map((campaign, index) => (
            <CampaignCarousel
              key={index}
              price={searchQuery.pricey}
              id={campaign.id}
              campaignTitle={campaign.name}
              description={campaign.description}
            />
          ))
        )}
      </Container>
      <div className='grow' />
      <Footer />
    </div>
  )
}

export default withRouter(Food)
