import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import TextField from '@material-ui/core/TextField'

const styles = (theme) => ({
  field: {
    border: '1.5px solid #c4c4c4',
    borderRadius: 10,
    height: 40,
    padding: '2px 5px 2px 5px',
    '&::placeholder': {
      fontSize: 12,
    },
  },
})

const Textfield = ({ classes, placeholder, value, onClear, onChange }) => {
  return (
    <TextField
      size='small'
      InputProps={{
        disableUnderline: true,
        classes: { input: classes.field },
      }}
      fullWidth={true}
      placeholder={placeholder}
      value={value}
      // onClear={onClear}
      onChange={onChange}
    />
  )
}

export default withStyles(styles)(injectIntl(withRouter(Textfield)))
