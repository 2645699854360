import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { GreenButton } from '../../../utils/tools'

const useStyles = makeStyles((theme) => ({
  buttonTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    "&[button-disabled='disabled']": {
      color: '#707070',
    },
  },
  greenButton: {
    margin: theme.spacing(1, 0, 1),
  },
}))

const CheckoutButton = ({ openCart, disableCondition, itemCount, totalPrice }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  return (
    <GreenButton onClick={openCart} fullWidth className={classes.greenButton} disabled={disableCondition}>
      <div className={classes.buttonTextContainer}>
        <div style={{ flex: '1 1 0' }}>
          <Typography className={classes.buttonText} button-disabled={disableCondition ? 'disabled' : 'notDisabled'}>
            {itemCount}
          </Typography>
        </div>
        <div style={{ flex: '7 1 0' }}>
          <Typography className={classes.buttonText} button-disabled={disableCondition ? 'disabled' : 'notDisabled'}>
            {f({ id: 'checkout-label' })}
          </Typography>
        </div>
        <div style={{ flex: '1 1 0' }}>
          <Typography className={classes.buttonText} button-disabled={disableCondition ? 'disabled' : 'notDisabled'}>
            ${totalPrice && totalPrice.toFixed(2)}
          </Typography>
        </div>
      </div>
    </GreenButton>
  )
}

CheckoutButton.propTypes = {
  openCart: PropTypes.func,
  disableCondition: PropTypes.bool,
  itemCount: PropTypes.number,
  totalPrice: PropTypes.number,
}

export default CheckoutButton
