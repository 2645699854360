export const attributeConstants = {
  GET_ATTRIBUTE_REQUEST: 'GET_ATTRIBUTE_REQUEST',
  GET_ATTRIBUTE_SUCCESS: 'GET_ATTRIBUTE_SUCCESS',
  GET_ATTRIBUTE_FAILURE: 'GET_ATTRIBUTE_FAILURE',

  CREATE_ATTRIBUTE_REQUEST: 'CREATE_ATTRIBUTE_REQUEST',
  CREATE_ATTRIBUTE_SUCCESS: 'CREATE_ATTRIBUTE_SUCCESS',
  CREATE_ATTRIBUTE_FAILURE: 'CREATE_ATTRIBUTE_FAILURE',

  UPDATE_ATTRIBUTE_REQUEST: 'UPDATE_ATTRIBUTE_REQUEST',
  UPDATE_ATTRIBUTE_SUCCESS: 'UPDATE_ATTRIBUTE_SUCCESS',
  UPDATE_ATTRIBUTE_FAILURE: 'UPDATE_ATTRIBUTE_FAILURE',

  CREATE_VALUE_REQUEST: 'CREATE_VALUE_REQUEST',
  CREATE_VALUE_SUCCESS: 'CREATE_VALUE_SUCCESS',
  CREATE_VALUE_FAILURE: 'CREATE_VALUE_FAILURE',

  UPDATE_VALUE_REQUEST: 'UPDATE_VALUE_REQUEST',
  UPDATE_VALUE_SUCCESS: 'UPDATE_VALUE_SUCCESS',
  UPDATE_VALUE_FAILURE: 'UPDATE_VALUE_FAILURE',

  DELETE_ATTRIBUTE_REQUEST: 'DELETE_ATTRIBUTE_REQUEST',
  DELETE_ATTRIBUTE_SUCCESS: 'DELETE_ATTRIBUTE_SUCCESS',
  DELETE_ATTRIBUTE_FAILURE: 'DELETE_ATTRIBUTE_FAILURE',

  DELETE_VALUE_REQUEST: 'DELETE_VALUE_REQUEST',
  DELETE_VALUE_SUCCESS: 'DELETE_VALUE_SUCCESS',
  DELETE_VALUE_FAILURE: 'DELETE_VALUE_FAILURE',

  UPDATE_REQUEST: 'UPDATE_ATTRIBUTE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_ATTRIBUTE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_ATTRIBUTE_FAILURE',

  GETALL_ATTRIBUTE_REQUEST: 'GETALL_ATTRIBUTE_REQUEST',
  GETALL_ATTRIBUTE_SUCCESS: 'GETALL_ATTRIBUTE_SUCCESS',
  GETALL_ATTRIBUTE_FAILURE: 'GETALL_ATTRIBUTE_FAILURE',

  CHANGE_SELECTED_ATTRIBUTE: 'CHANGE_SELECTED_ATTRIBUTE',

  CLEAR_STATE: 'CLEAR_STATE',
}

export const initialAttributeState = {
  name: '',
  description: '',
  minimumCount: '',
  maximumCount: '',
  freeCount: '',
  order_: 0,
  required: false,
}
export const initialOptionsState = {
  name: '',
  description: '',
  price: '',
  order_: 0,
}

export const limits = {
  MINCOUNT: 0,
  MAXCOUNT: 20,
}

export const initialError = {
  minimumCount: false,
  maximumCount: false,
  freeCount: false,
  name: false,
}
