import React from 'react'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import HalfStarRateIcon from '@material-ui/icons/StarHalf'

import { stars } from '../../../../../helpers/filterBox/stars'

const useStyles = makeStyles(() => ({
  starRateIcon: {
    marginRight: -5,
  },
  halfStar: {
    marginLeft: 1,
    marginBottom: 1,
  },
  text: {
    marginRight: 5,
    marginTop: 5,
  },
  stars: {
    marginTop: 10,
  },
}))

const FabRatingContent = ({ searchData }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <div className='row'>
      <div className={classes.text}>
        {searchData.rating.toString() === '5' ? f({ id: 'only-label' }) : f({ id: 'over-label' })}
      </div>
      <div className={classes.stars}>
        {stars(searchData, classes)}
        {searchData.rating % 1 === 0.5 && (
          <span>
            <HalfStarRateIcon className={classes.halfStar} style={{ width: 17 }} />
          </span>
        )}
      </div>
    </div>
  )
}

export default FabRatingContent
