import React, { useState, useEffect } from 'react'
import RichTooltip from './rich_tooltip'

const ClickableRichTooltip = ({ placement, arrow, content, children, defaultCard, status, cardId }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [defaultCard, status])

  const existingOnClick = children.props.onClick
  const newOnClick = (e) => {
    setOpen(!open)
    existingOnClick && existingOnClick(e)
  }

  const contentNode = typeof content === 'function' ? content() : content

  return (
    <RichTooltip
      open={open}
      onClose={() => setOpen(false)}
      arrow={arrow}
      placement={placement}
      content={contentNode}
      defaultCard={defaultCard}
      cardId={cardId}
    >
      {React.cloneElement(children, { ...children.props, onClick: newOnClick })}
    </RichTooltip>
  )
}

export default ClickableRichTooltip
