import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import rate from '../../../images/white-rate.svg'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  rating: {
    position: 'absolute',
    left: 10,
    top: 10,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    zIndex: 2,
    // "&[store-open='false']": {
    //   opacity: '0.8',
    // },
  },
  big: {
    width: 42,
    height: 22,
  },
  small: {
    width: 38.1,
    height: 19.6,
  },
  rateIcon: {
    marginLeft: 5,
  },
  storeRating: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.64,
    letterSpacing: 'normal',
    color: '#fff',
    marginTop: 0.2,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 1.4,
  },
  bigFont: {
    fontSize: 11,
  },
  smallFont: {
    fontSize: 11,
  },
}))

const StoreRating = ({ data, big, storeOpen }) => {
  const classes = useStyles()

  if (!data.ratingSummary || data.ratingSummary?.averageRating === 0) return null
  return (
    <div
      store-open={(!!storeOpen).toString()}
      className={clsx('centered-row', classes.rating, big ? classes.big : classes.small)}
    >
      <img
        src={rate}
        width={big ? '16.2' : '14.7'}
        height={big ? '15.1' : '13.7'}
        alt=''
        className={classes.rateIcon}
      />
      <Typography className={clsx(classes.storeRating, big ? classes.bigFont : classes.smallFont)}>
        {data?.ratingSummary?.averageRating?.toFixed(1)}
      </Typography>
    </div>
  )
}

StoreRating.propTypes = {
  data: PropTypes.object,
  storeOpen: PropTypes.bool,
}

export default StoreRating
