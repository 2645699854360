import React from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { GreenRadio } from '../../../utils/tools'
import { phoneRegex } from '../../../utils/regex_collections'

import { valueConstants, phoneConstants, notificationConstants } from '../../../businessRegister/businessConstants'

import Phone from './Phone'

const PhoneSelection = ({
  classes,
  orderConfirmation,
  handleCheck,
  orderConfirmPhone,
  orderConfirmCode,
  editting,
  setOrderConfirmCode,
  setOrderConfirmPhone,
  setIsFocused,
  setIsInvalid,
  isFocused,
  isInvalid,
  valProp,
}) => {
  const phoneNumberHandler = (e) => {
    setIsFocused({ ...isFocused, [valProp]: editting && true })
    setIsInvalid({
      ...isInvalid,
      [valProp]: !phoneRegex.test(e.target.value),
    })
    setOrderConfirmPhone(e.target.value)
  }

  return (
    <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
      <FormControl fullWidth style={{ marginBottom: 16 }}>
        {phoneConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            className={classes.label}
            control={<GreenRadio checked={orderConfirmation === item} onChange={handleCheck} value={item} />}
            label={<Typography>{item}</Typography>}
            disabled={!editting}
          />
        ))}
      </FormControl>
      <Phone
        countryCode={orderConfirmCode}
        phone={orderConfirmPhone}
        isFocused={isFocused}
        isInvalid={isInvalid}
        phoneHandler={phoneNumberHandler}
        editting={editting}
        setCode={setOrderConfirmCode}
        prop={valueConstants[valProp]}
        gridVal={12}
        disableArea={orderConfirmation === notificationConstants.storePhone}
      />
    </Grid>
  )
}

PhoneSelection.propTypes = {
  classes: PropTypes.object,
  orderConfirmation: PropTypes.string,
  handleCheck: PropTypes.func,
  orderConfirmPhone: PropTypes.string,
  orderConfirmCode: PropTypes.string,
  editting: PropTypes.bool,
  setOrderConfirmCode: PropTypes.func,
  setOrderConfirmPhone: PropTypes.func,
  setIsFocused: PropTypes.func,
  setIsInvalid: PropTypes.func,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  valProp: PropTypes.string,
}

export default PhoneSelection
