import React from 'react'
import { ElementsConsumer } from '@stripe/react-stripe-js'
// import Cart from './cart'
import Cart from './Cart_'

const InjectedCart = ({ closeBasket, openAddPhoneDialog, openVerifyDialog, cartRef, tabletView, showBasket }) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <Cart
          ref={cartRef}
          elements={elements}
          stripe={stripe}
          closeBasket={closeBasket}
          openAddPhoneDialog={openAddPhoneDialog}
          openVerifyDialog={openVerifyDialog}
          tabletView={tabletView}
          showBasket={showBasket}
        />
      )}
    </ElementsConsumer>
  )
}

export default InjectedCart
