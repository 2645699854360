import React from 'react'
import PropTypes from 'prop-types'

import { emailRegex } from '../../../utils/regex_collections'

import TextField from '@material-ui/core/TextField'

const EmailField = ({ email, isFocused, classes, handleChange }) => {
  return (
    <TextField
      size='small'
      variant='outlined'
      margin='normal'
      fullWidth
      id='email'
      label='Email Address'
      name='email'
      autoComplete='email'
      autoFocus
      value={email}
      onChange={handleChange}
      error={isFocused && !emailRegex.test(email)}
      className={classes.textField}
    />
  )
}

EmailField.propTypes = {
  email: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default EmailField
