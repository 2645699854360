const employeesHeader = [
  {
    label: 'first-name',
  },
  {
    label: 'last-name',
  },
  {
    label: 'email-label-short',
  },
  {
    label: 'role-label',
  },
]

const storesHeader = [
  {
    label: 'name-label',
  },
  {
    label: 'store-number',
  },
  {
    label: 'email-label-short',
  },
  {
    label: 'city-label',
  },
  {
    label: 'state-label',
  },
]

const ordersHeader = [
  {
    label: 'orderid-label',
  },
  {
    label: 'order-type',
  },
  {
    label: 'date-label',
  },
  {
    label: 'time-label',
  },
  {
    label: 'customer-label',
  },
  {
    label: 'order-status',
  },
  {
    label: 'estimated-payment',
  },
  {
    label: 'status-actions',
  },
  // {
  //   label: 'subtotal-label',
  // },
  // {
  //   label: 'taxes-label',
  // },
  // {
  //   label: 'commissions-label',
  // },
  // {
  //   label: 'fees-label',
  // },
]

const campaignsHeader = [
  {
    label: 'promotion-name',
  },
  {
    label: 'status-label',
  },
  {
    label: 'start-date',
  },
  {
    label: 'end-date',
  },
  {
    label: 'status-actions',
  },
]

export { employeesHeader, storesHeader, ordersHeader, campaignsHeader }
