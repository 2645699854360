import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Footer from '../Footers'
import backIcon from '../../../images/black-back.svg'
import clsx from 'clsx'
import { templateHeader, footerPages } from '../../../helpers'

const height = window.innerHeight
const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  mobileContent: {
    padding: `${theme.spacing(2)}px ${theme.spacing()}px ${theme.spacing(8)}px`,
    justifyContent: 'center',
    overflow: 'scroll',
    flex: '1 50 auto',
  },
  footer: {
    zIndex: 10,
  },
}))

const MobileFooterPages = () => {
  const history = useHistory()
  const match = useRouteMatch(`${path}/customer/:relativePath`)
  const realtivePath = match.params.relativePath
  const classes = useStyles()
  const Component = footerPages[realtivePath]

  return (
    <Box className={clsx('mobile', classes.mobileRoot)}>
      <Box className='mobile-top-bar'>
        <IconButton size='small' className={classes.back} onClick={() => history.goBack()}>
          <img src={backIcon} alt='Back' />
        </IconButton>
        <Typography variant='h6'>{templateHeader[realtivePath]}</Typography>
      </Box>
      <Box className={classes.mobileContent}>
        {' '}
        <Component />
      </Box>
      <Box className={clsx('sticky-bottom', classes.footer)}>
        <Footer />
      </Box>
    </Box>
  )
}

export default MobileFooterPages
