import React from 'react'
import { OAUTH20_CLIENT_ID } from '../../constants/general.constants'
import googleLogo from '../../images/google_logo.svg'
import GoogleLogin from 'react-google-login'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { customerActions } from '../../actions'
import { isMobileOnly } from 'react-device-detect'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  googleButton: {
    color: '#fff',
    height: 48,
    borderRadius: 10,
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    padding: 8,
    textTransform: 'none',
    marginBottom: theme.spacing(),
    width: '100%',
    backgroundColor: '#007aff',
    '&:hover': {
      backgroundColor: '#007aff',
    },
  },
}))

const LoginButton = ({ googleResponse, classes, label }) => {
  return (
    <GoogleLogin
      clientId={OAUTH20_CLIENT_ID}
      redirectUri='https://tookfresh.com/'
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          variant='contained'
          className={classes.googleButton}
          startIcon={<img src={googleLogo} alt='Google' height='22' />}
        >
          {label}
        </Button>
      )}
      buttonText='Login'
      onSuccess={googleResponse}
      onFailure={googleResponse}
      cookiePolicy={'single_host_origin'}
    />
  )
}

const GoogleAuth = () => {
  const classes = useStyles()
  const history = useHistory()
  // Global states
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  // Actions
  const dispatch = useDispatch()
  const social = (platform, token) => dispatch(customerActions.social(platform, token))

  useEffect(() => {
    signedIn && handleRedirection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn])

  const handleRedirection = () => {
    isMobileOnly ? goToMobileRoot() : goToDesktopRoot()
  }

  const googleResponse = (response) => {
    const token = response?.tokenId
    if (token) social('GOOGLE', token)
  }

  const goToMobileRoot = () => history.push(`${path}/customer/food`)
  const goToDesktopRoot = () => history.push(`${path}/customer`)

  return (
    <div style={{ width: '100%' }}>
      <LoginButton
        googleResponse={googleResponse}
        classes={classes}
        label={<FormattedMessage id='continue-google' />}
      />
    </div>
  )
}

export default GoogleAuth
