import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { GreenRadio } from '../../../../utils/tools'

import { paymentDetailConstants, valueConstants, labelConstants, nameAreaConstants } from '../../../businessConstants'

const NameField = ({ classes, values, handleChange, setValues, error }) => {
  const { formatMessage: f } = useIntl()

  const [nameField, setNameField] = useState('')

  useEffect(() => {
    if (values) {
      if (values.paymentContactName === `${values.firstName} ${values.lastName}`) {
        setNameField(nameAreaConstants[0])
      } else {
        setNameField(nameAreaConstants[1])
      }
    }
  }, [values])

  const handleNameField = (e) => {
    setNameField(e.target.value)
    handleContactName(e.target.value)
  }

  const handleContactName = (value) => {
    if (value === paymentDetailConstants.myName) {
      setValues({
        ...values,
        paymentContactName: `${values.firstName} ${values.lastName}`,
      })
    } else {
      setValues({
        ...values,
        paymentContactName: paymentDetailConstants.empty,
      })
    }
  }

  return (
    <Grid item md={6} sm={12} xs={12} className={classes.gridItem}>
      <FormControl fullWidth>
        {nameAreaConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            control={<GreenRadio checked={nameField === item} onChange={handleNameField} value={item} />}
            label={<Typography>{item}</Typography>}
          />
        ))}
      </FormControl>

      <TextField
        size='small'
        value={values.paymentContactName}
        variant='outlined'
        fullWidth
        id={valueConstants.paymentContactName}
        InputLabelProps={{ shrink: true }}
        label={f({ id: labelConstants.paymentContactName })}
        onChange={handleChange(valueConstants.paymentContactName)}
        className={classes.textfields}
        data-content='name'
        disabled={nameField !== nameAreaConstants[1]}
        error={error && values.paymentContactName.length === 0}
        inputProps={{
          maxLength: 100,
        }}
      />
    </Grid>
  )
}

NameField.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  setValues: PropTypes.func,
  error: PropTypes.bool,
}

export default NameField
