import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import StyleChip from './StyleChip'

const useStyles = makeStyles((theme) => ({
  sections: {
    height: 36,
    paddingTop: 2,
    paddingBottom: 2,
  },
}))

export const SlidingSectionRow = ({ sections, selectedSections, handleSectionSelect }) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.sections}>
      <Grid item xs={12}>
        <div className='scrolling-wrapper-flexbox'>
          {sections?.map((section) => {
            const activeMenuCount = section?.menus?.filter((m) => m.active).length
            if (activeMenuCount === 0) return null
            return (
              <StyleChip
                color={selectedSections.some((d) => d.sectionId === section.id) ? 'secondary' : 'primary'}
                key={section.id}
                onClick={() => handleSectionSelect(section.id)}
                label={section.name}
              />
            )
          })}
        </div>
      </Grid>
    </Grid>
  )
}

export default SlidingSectionRow
