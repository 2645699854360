import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { BlackButton, GreenButton } from '../../../utils/tools'
import { makeStyles } from '@material-ui/core'
import { withRouter } from 'react-router'
import { buttonStatus } from '../../../../helpers/merchant/button-status'

const useStyles = makeStyles((theme) => ({
  actionRow: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    direction: 'rtl',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    minWidth: 120,
    marginLeft: theme.spacing(3),
  },
}))

const ActionButtons = ({ user, intl, discard, text, update, disable }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const disableCondition = useMemo(() => buttonStatus(user, update, disable), [user, update, disable])

  return (
    <div className={classes.actionRow}>
      <GreenButton className={classes.button} type='submit' disabled={disableCondition}>
        {getLabel(text)}
      </GreenButton>
      <BlackButton className={classes.button} onClick={discard}>
        {getLabel('discard-label')}
      </BlackButton>
    </div>
  )
}

ActionButtons.propTypes = {
  user: PropTypes.object,
}

export default withRouter(injectIntl(ActionButtons))
