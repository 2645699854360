import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { getImage } from '../../../../helpers/get-image'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 70,
    height: 90,
    cursor: 'pointer',
    marginLeft: 3,
    marginRight: 3,
  },
  icon: {
    width: 70,
    maxWidth: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  name: {
    fontSize: 12,
    marginTop: 2,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000',
  },
  dialog: {
    borderRadius: 20,
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
}))

function FoodCategoryMobile(props) {
  const { data, isSelected, onSelect } = props
  const classes = useStyles()

  return (
    <div className={classes.root} onClick={() => onSelect(data.id)}>
      <div
        className={clsx(classes.icon, {
          [classes.selected]: isSelected,
        })}
      >
        {data?.icon && <img src={getImage(data?.icon)} alt='food-category' height='50' width='50' />}
      </div>
      <Typography className={classes.name}>{data.name}</Typography>
    </div>
  )
}

FoodCategoryMobile.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default FoodCategoryMobile
