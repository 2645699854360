import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CampaignCarousel from '../../../campaigns/campaign_carousel'

export const RenderCampaigns = ({ campaignTypes }) => {
  const searchQuery = useSelector(({ search }) => search.searchQuery)
  return (
    <>
      {campaignTypes?.map((campaign, index) => (
        <CampaignCarousel
          key={index}
          id={campaign.id}
          campaignTitle={campaign.name}
          description={campaign.description}
          price={searchQuery.pricey}
          mobile={true}
        />
      ))}
    </>
  )
}

RenderCampaigns.propTypes = {
  campaignTypes: PropTypes.array,
}

export default RenderCampaigns
