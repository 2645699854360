import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import backIcon from '../../images/black-back.svg'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
}))

const MobileTopHeader = ({ callback, title, name, storeMain }) => {
  const classes = useStyles()
  return (
    <div className='mobile-top-bar' style={{ borderBottom: storeMain && 0 }}>
      <IconButton size='small' className={classes.back} onClick={callback}>
        <img src={backIcon} alt='Back' />
      </IconButton>
      <Typography variant='h6'>{title ? <FormattedMessage id={title} /> : name}</Typography>
    </div>
  )
}

MobileTopHeader.propTypes = {
  title: PropTypes.string,
  callback: PropTypes.func.isRequired,
  name: PropTypes.string,
  storeMain: PropTypes.bool,
}

export default React.memo(MobileTopHeader)
