import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

import { secondTier, valueConstants } from '../../../businessConstants'

const SecondTier = ({ classes, handleChange, thirdTierState, addTier, removeTier, values }) => {
  const { formatMessage: f } = useIntl()

  let disableCondition =
    values.secondTierDeliveryFee === '' ||
    values.secondTierMinOrder === '' ||
    values.secondTierMaxDistance === '' ||
    +values.secondTierMinOrder <= +values.firstTierMinOrder ||
    +values.secondTierMaxDistance <= +values.firstTierMaxDistance

  return (
    <div className={classes.tier}>
      <Grid container spacing={2} className='full-width'>
        {secondTier.map((tier, index) => (
          <Grid key={index} item md={4} sm={6} xs={12} className={classes.gridItem}>
            <TextField
              size='small'
              value={values[tier.value]}
              variant='outlined'
              fullWidth
              id={tier.value}
              InputLabelProps={{ shrink: true }}
              label={f({ id: tier.label })}
              onChange={handleChange(tier.value)}
              disabled={thirdTierState}
              InputProps={{
                inputProps: {
                  type: 'number',
                  min: 0,
                  maxLength: 100,
                },
                startAdornment: tier.value !== valueConstants.secondTierMaxDistance && (
                  <InputAdornment position='start' disablePointerEvents>
                    <Typography className={classes.startAdornment}>$</Typography>
                  </InputAdornment>
                ),
                endAdornment: tier.value === valueConstants.secondTierMaxDistance && (
                  <InputAdornment position='end' disablePointerEvents>
                    <Typography className={classes.endAdornment}>({f({ id: 'miles-label' })})</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ))}
      </Grid>
      {!thirdTierState && (
        <div className={classes.buttonContainer}>
          <Button
            disableRipple
            variant='contained'
            color='primary'
            onClick={addTier}
            add-button='true'
            className={classes.button}
            disabled={disableCondition}
          >
            {f({ id: 'add-label' })}
          </Button>
          <Button disableRipple variant='contained' color='secondary' onClick={removeTier} className={classes.button}>
            {f({ id: 'remove-label' })}
          </Button>
        </div>
      )}
    </div>
  )
}

SecondTier.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  classes: PropTypes.object,
  removeTier: PropTypes.func,
  addTier: PropTypes.func,
  thirdTierState: PropTypes.bool,
}

export default SecondTier
