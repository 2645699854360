import { initialQuery } from '../../constants'

const isChanged = (query) => {
  if (
    query.rating !== initialQuery.rating ||
    query.pricey !== initialQuery.pricey ||
    query.distance !== initialQuery.distance ||
    query.dietaryIds.length !== initialQuery.dietaryIds.length ||
    query.onlyOpen !== initialQuery.onlyOpen
  ) {
    return true
  } else {
    return false
  }
}

export { isChanged }
