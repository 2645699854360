export const menuConstants = {
  // CREATE_SECTION_REQUEST: 'CREATE_SECTION_REQUEST',
  // CREATE_SECTION_SUCCESS: 'CREATE_SECTION_SUCCESS',
  // CREATE_SECTION_FAILURE: 'CREATE_SECTION_FAILURE',
  // UPDATE_SECTION_REQUEST: 'UPDATE_SECTION_REQUEST',
  // UPDATE_SECTION_SUCCESS: 'UPDATE_SECTION_SUCCESS',
  // UPDATE_SECTION_FAILURE: 'UPDATE_SECTION_FAILURE',
  // DELETE_SECTION_REQUEST: 'DELETE_SECTION_REQUEST',
  // DELETE_SECTION_SUCCESS: 'DELETE_SECTION_SUCCESS',
  // DELETE_SECTION_FAILURE: 'DELETE_SECTION_FAILURE',
  // GETALL_SECTIONS_REQUEST: 'GETALL_REQUEST',
  // GETALL_SECTIONS_SUCCESS: 'GETALL_SUCCESS',
  // GETALL_SECTIONS_FAILURE: 'GETALL_FAILURE',
  // CREATE_MENU_REQUEST: 'CREATE_MENU_REQUEST',
  // CREATE_MENU_SUCCESS: 'CREATE_MENU_SUCCESS',
  // CREATE_MENU_FAILURE: 'CREATE_MENU_FAILURE',
  // UPDATE_MENU_REQUEST: 'UPDATE_MENU_REQUEST',
  // UPDATE_MENU_SUCCESS: 'UPDATE_MENU_SUCCESS',
  // UPDATE_MENU_FAILURE: 'UPDATE_MENU_FAILURE',
  // DELETE_MENU_REQUEST: 'DELETE_MENU_REQUEST',
  // DELETE_MENU_SUCCESS: 'DELETE_MENU_SUCCESS',
  // DELETE_MENU_FAILURE: 'DELETE_MENU_FAILURE',
  // DELETE_IMAGE_REQUEST: 'DELETE_IMAGE_REQUEST',
  // DELETE_IMAGE_SUCCESS: 'DELETE_IMAGE_SUCCESS',
  // DELETE_IMAGE_FAILURE: 'DELETE_IMAGE_FAILURE',
  // SAVE_MENU_IMAGE_REQUEST: 'SAVE_MENU_IMAGE_REQUEST',
  // SAVE_MENU_IMAGE_SUCCESS: 'SAVE_MENU_IMAGE_SUCCESS',
  // SAVE_MENU_IMAGE_FAILURE: 'SAVE_MENU_IMAGE_FAILURE',
}
