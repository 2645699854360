import { Grid, makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import trackOrder from '../../../images/track-order.svg'
import MobileTopHeader from '../../common/MobileTopHeader'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Input from '@material-ui/core/Input'
import { alertActions } from '../../../actions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'center',
    paddingTop: theme.spacing(),
    overflow: 'scroll',
    flex: '1 50 auto',
  },
  upperImage: {
    height: 200,
    width: '100%',
  },
  centeredContainer: {
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
  centeredText: {
    textAlign: 'center',
    paddingTop: theme.spacing(),
    lineHeight: 1.6,
  },
  grayContainer: {
    // paddingHorizontal: theme.spacing(3),
    backgroundColor: '#e6e1de',
    height: 40,
    borderRadius: 5,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  copied: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    fontSize: 12,
    marginRight: 10,
    width: '100%',
  },
  iconButton: {
    // backgroundColor: 'salmon',
  },
}))

export const InviteFriends = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const reference = useSelector(({ customer }) => customer.customerInfo.referenceCode)
  const textAreaRef = useRef(null)
  const dispatch = useDispatch()

  const handlePageClose = () => history.goBack()

  const handleCopyToClipboard = (e) => {
    const copiedText = textAreaRef.current.value
    navigator.clipboard.writeText(copiedText)
    dispatch(alertActions.success('Copied successfully'))
  }

  return (
    <div className={classes.root}>
      <MobileTopHeader title={'invite-friends'} callback={handlePageClose} />
      <Grid container className={classes.content}>
        <img src={trackOrder} alt='invite-friend' className={classes.upperImage} />
        <Grid item className={classes.centeredContainer}>
          <Typography variant='h4' className={classes.centeredText}>
            <FormattedMessage id='invite-your-friend' />
          </Typography>
          <Typography variant='body1' className={classes.centeredText}>
            <FormattedMessage id='lorem-ipsum' />
          </Typography>
        </Grid>
        <Grid item style={{ width: '100%', marginTop: 20 }}>
          <Grid item className={classes.grayContainer}>
            <IconButton size='small' className={classes.iconButton} onClick={handleCopyToClipboard}>
              <FileCopyOutlinedIcon />
            </IconButton>
            <Grid item style={{ width: '80%' }}>
              <Input
                inputRef={textAreaRef}
                inputProps={{
                  className: classes.input,
                }}
                disableUnderline
                fullWidth
                defaultValue={`http://tookfresh.com/customer/signup/${reference}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default InviteFriends
