import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
}))

const ViewCities = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      <Chip className={classes.chip} label='San Francisco' />
      <Chip className={classes.chip} label='Palo Alto' />
      <Chip className={classes.chip} label='San Jose' />
      <Chip className={classes.chip} label='Mountain View' />
      <Chip className={classes.chip} label='Sunnyvale' />
      <Chip className={classes.chip} label='Santa Clara' />
      <Chip className={classes.chip} label='Menlo Park' />
      <Chip className={classes.chip} label='San Mateo' />
      <Chip className={classes.chip} label='Redwood City' />
      <Chip className={classes.chip} label='Fremont' />
      <Chip className={classes.chip} label='Oakland' />
      <Chip className={classes.chip} label='Berkeley' />
      <Chip className={classes.chip} label='Cupertino' />
      <Chip className={classes.chip} label='Pleasanton' />
      <Chip className={classes.chip} label='Campbell' />
      <Chip className={classes.chip} label='Los Altos' />
      <Chip className={classes.chip} label='Burlingame' />
      <Chip className={classes.chip} label='Milpitas' />
      <Chip className={classes.chip} label='Emeryville' />
      <Chip className={classes.chip} label='Los Gatos' />
      <Chip className={classes.chip} label='South San Francisco' />
      <Chip className={classes.chip} label='San Ramon' />
      <Chip className={classes.chip} label='San Carlos' />
      <Chip className={classes.chip} label='Foster City' />
      <Chip className={classes.chip} label='San Rafael' />
      <Chip className={classes.chip} label='San Bruno' />
      <Chip className={classes.chip} label='Walnut Creek' />
      <Chip className={classes.chip} label='Santa Cruz' />
      <Chip className={classes.chip} label='Hayward' />
      <Chip className={classes.chip} label='Alameda' />
      <Chip className={classes.chip} label='Sausalito' />
      <Chip className={classes.chip} label='Santa Rosa' />
      <Chip className={classes.chip} label='Mill Valley' />
      <Chip className={classes.chip} label='Redwood Shores' />
      <Chip className={classes.chip} label='Newark' />
      <Chip className={classes.chip} label='Petaluma' />
      <Chip className={classes.chip} label='Saratoga' />
      <Chip className={classes.chip} label='Livermore' />
      <Chip className={classes.chip} label='Stanford' />
      <Chip className={classes.chip} label='Concord' />
      <Chip className={classes.chip} label='Novato' />
      <Chip className={classes.chip} label='Belmont' />
      <Chip className={classes.chip} label='Brisbane' />
      <Chip className={classes.chip} label='Dublin' />
      <Chip className={classes.chip} label='Portola Valley' />
      <Chip className={classes.chip} label='Danville' />
      <Chip className={classes.chip} label='San Leandro' />
      <Chip className={classes.chip} label='Napa' />
      <Chip className={classes.chip} label='Larkspur' />
      <Chip className={classes.chip} label='Sebastopol' />
      <Chip className={classes.chip} label='Morgan Hill' />
      <Chip className={classes.chip} label='Richmond' />
      <Chip className={classes.chip} label='Union City' />
      <Chip className={classes.chip} label='Pleasant Hill' />
      <Chip className={classes.chip} label='Lafayette' />
      <Chip className={classes.chip} label='Daly City' />
      <Chip className={classes.chip} label='Sonoma' />
      <Chip className={classes.chip} label='Corte Madera' />
      <Chip className={classes.chip} label='Scotts Valley' />
      <Chip className={classes.chip} label='Belvedere Tiburon' />
      <Chip className={classes.chip} label='East Palo Alto' />
      <Chip className={classes.chip} label='Atherton' />
      <Chip className={classes.chip} label='Brentwood' />
      <Chip className={classes.chip} label='Rohnert Park' />
      <Chip className={classes.chip} label='Millbrae' />
      <Chip className={classes.chip} label='Los Altos Hills' />
      <Chip className={classes.chip} label='Fairfield' />
      <Chip className={classes.chip} label='Healdsburg' />
      <Chip className={classes.chip} label='Moraga' />
      <Chip className={classes.chip} label='Capitola' />
      <Chip className={classes.chip} label='Half Moon Bay' />
      <Chip className={classes.chip} label='Alamo' />
      <Chip className={classes.chip} label='Castro Valley' />
      <Chip className={classes.chip} label='Orinda' />
      <Chip className={classes.chip} label='San Anselmo' />
      <Chip className={classes.chip} label='Aptos' />
      <Chip className={classes.chip} label='Martinez' />
      <Chip className={classes.chip} label='Vacaville' />
      <Chip className={classes.chip} label='El Cerrito' />
      <Chip className={classes.chip} label='Benicia' />
      <Chip className={classes.chip} label='Gilroy' />
      <Chip className={classes.chip} label='Greenbrae' />
      <Chip className={classes.chip} label='Soquel' />
      <Chip className={classes.chip} label='Pacifica' />
      <Chip className={classes.chip} label='Fairfax' />
      <Chip className={classes.chip} label='Pittsburg' />
      <Chip className={classes.chip} label='Alviso' />
      <Chip className={classes.chip} label='Hercules' />
      <Chip className={classes.chip} label='Watsonville' />
      <Chip className={classes.chip} label='Dixon' />
      <Chip className={classes.chip} label='Vallejo' />
      <Chip className={classes.chip} label='Antioch' />
      <Chip className={classes.chip} label='San Pablo' />
      <Chip className={classes.chip} label='Piedmont' />
      <Chip className={classes.chip} label='Saint Helena' />
      <Chip className={classes.chip} label='Freedom' />
      <Chip className={classes.chip} label='Boulder Creek' />
      <Chip className={classes.chip} label='Graton' />
      <Chip className={classes.chip} label='Kenwood' />
      <Chip className={classes.chip} label='Fulton' />
      <Chip className={classes.chip} label='Guerneville' />
      <Chip className={classes.chip} label='Cotati' />
      <Chip className={classes.chip} label='American Canyon' />
      <Chip className={classes.chip} label='Boyes Hot Springs' />
      <Chip className={classes.chip} label='Albany' />
      <Chip className={classes.chip} label='Kentfield' />
      <Chip className={classes.chip} label='Felton' />
      <Chip className={classes.chip} label='El Granada' />
      <Chip className={classes.chip} label='El Sobrante' />
      <Chip className={classes.chip} label='Pescadero' />
      <Chip className={classes.chip} label='Diablo' />
      <Chip className={classes.chip} label='Ross' />
      <Chip className={classes.chip} label='San Lorenzo' />
      <Chip className={classes.chip} label='Nicasio' />
      <Chip className={classes.chip} label='Clayton' />
      <Chip className={classes.chip} label='Angwin' />
      <Chip className={classes.chip} label='San Martin' />
      <Chip className={classes.chip} label='Yountville' />
      <Chip className={classes.chip} label='Oakville' />
      <Chip className={classes.chip} label='Occidental' />
      <Chip className={classes.chip} label='Geyserville' />
      <Chip className={classes.chip} label='Rutherford' />
      <Chip className={classes.chip} label='Forestville' />
      <Chip className={classes.chip} label='Bodega Bay' />
      <Chip className={classes.chip} label='Bodega' />
      <Chip className={classes.chip} label='Glen Ellen' />
      <Chip className={classes.chip} label='Jenner' />
      <Chip className={classes.chip} label='Cloverdale' />
      <Chip className={classes.chip} label='Cazadero' />
      <Chip className={classes.chip} label='Calistoga' />
      <Chip className={classes.chip} label='Monte Rio' />
      <Chip className={classes.chip} label='Windsor' />
      <Chip className={classes.chip} label='Valley Ford' />
      <Chip className={classes.chip} label='Rio Del Mar' />
      <Chip className={classes.chip} label='Pinole' />
      <Chip className={classes.chip} label='Forest Knolls' />
      <Chip className={classes.chip} label='Oakley' />
      <Chip className={classes.chip} label='San Geronimo' />
      <Chip className={classes.chip} label='Moss Beach' />
      <Chip className={classes.chip} label='Rio Vista' />
      <Chip className={classes.chip} label='Point Reyes Station' />
      <Chip className={classes.chip} label='La Honda' />
      <Chip className={classes.chip} label='Olema' />
      <Chip className={classes.chip} label='Crockett' />
      <Chip className={classes.chip} label='Woodacre' />
      <Chip className={classes.chip} label='Mount Hermon' />
      <Chip className={classes.chip} label='Birds Landing' />
      <Chip className={classes.chip} label='Marshall' />
      <Chip className={classes.chip} label='Mount Hamilton' />
      <Chip className={classes.chip} label='Port Costa' />
      <Chip className={classes.chip} label='Inverness' />
      <Chip className={classes.chip} label='Rio Nido' />
      <Chip className={classes.chip} label='Redwood Estates' />
      <Chip className={classes.chip} label='Brookdale' />
      <Chip className={classes.chip} label='Eldridge' />
      <Chip className={classes.chip} label='Villa Grande' />
      <Chip className={classes.chip} label='Davenport' />
      <Chip className={classes.chip} label='Sunol' />
      <Chip className={classes.chip} label='Montara' />
      <Chip className={classes.chip} label='Rodeo' />
      <Chip className={classes.chip} label='Duncans Mills' />
      <Chip className={classes.chip} label='Lagunitas' />
      <Chip className={classes.chip} label='Camp Meeker' />
      <Chip className={classes.chip} label='Knightsen' />
      <Chip className={classes.chip} label='Tomales' />
      <Chip className={classes.chip} label='Byron' />
      <Chip className={classes.chip} label='Bolinas' />
      <Chip className={classes.chip} label='Elmira' />
      <Chip className={classes.chip} label='Penngrove' />
      <Chip className={classes.chip} label='New Almaden' />
      <Chip className={classes.chip} label='Vineburg' />
      <Chip className={classes.chip} label='Pope Valley' />
      <Chip className={classes.chip} label='Ben Lomond' />
      <Chip className={classes.chip} label='Holy City' />
      <Chip className={classes.chip} label='Ignacio' />
      <Chip className={classes.chip} label='Coyote' />
      <Chip className={classes.chip} label='San Quentin' />
      <Chip className={classes.chip} label='El Verano' />
      <Chip className={classes.chip} label='Stinson Beach' />
      <Chip className={classes.chip} label='Deer Park' />
      <Chip className={classes.chip} label='San Gregorio' />
      <Chip className={classes.chip} label='Annapolis' />
      <Chip className={classes.chip} label='Dillon Beach' />
      <Chip className={classes.chip} label='Bethel Island' />
      <Chip className={classes.chip} label='Loma Mar' />
      <Chip className={classes.chip} label='Stewarts Point' />
      <Chip className={classes.chip} label='Discovery Bay' />
      <Chip className={classes.chip} label='Canyon' />
    </>
  )
}

export default ViewCities
