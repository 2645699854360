import React from 'react'
import { Grid, TextField, MenuItem } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

const CategoryId = ({ value, handleChange, isFocused, isInvalid, editting }) => {
  const { formatMessage: f } = useIntl()
  const categories = useSelector(({ categories }) => categories.data)
  return (
    <Grid item md={6} sm={6} xs={12}>
      <TextField
        select
        defaultValue
        variant='outlined'
        size='small'
        fullWidth
        required
        value={value}
        onChange={handleChange}
        label={f({ id: 'category-id' })}
        error={isFocused.categoryId && isInvalid.categoryId}
        name='category'
        InputLabelProps={{ shrink: true }}
        inputProps={{ readOnly: !editting }}
      >
        {categories?.map((cat, index) => (
          <MenuItem key={index} value={cat.id}>
            {cat.name}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  )
}

export default CategoryId
