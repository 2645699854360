export const storeConstants = {
  CREATE_REQUEST: 'CREATE_STORE_REQUEST',
  CREATE_SUCCESS: 'CREATE_STORE_SUCCESS',
  CREATE_FAILURE: 'CREATE_STORE_FAILURE',

  UPDATE_REQUEST: 'UPDATE_STORE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_STORE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_STORE_FAILURE',

  GETALL_REQUEST: 'GETALL_STORE_REQUEST',
  GETALL_SUCCESS: 'GETALL_STORE_SUCCESS',
  GETALL_FAILURE: 'GETALL_STORE_FAILURE',

  // DELETE_STORE_REQUEST: 'DELETE_STORE_REQUEST',
  // DELETE_STORE_SUCCESS: 'DELETE_STORE_SUCCESS',
  // DELETE_STORE_FAILURE: 'DELETE_STORE_FAILURE',

  CUSTOMER_SEARCH_REQUEST: 'CUSTOMER_SEARCH_REQUEST',
  CUSTOMER_SEARCH_SUCCESS: 'CUSTOMER_SEARCH_SUCCESS',
  CUSTOMER_SEARCH_FAILURE: 'CUSTOMER_SEARCH_FAILURE',

  CUSTOMER_SEARCH_LOCAL_SUCCESS: 'CUSTOMER_SEARCH_LOCAL_SUCCESS',
  CUSTOMER_SEARCH_LOCAL_FAILURE: 'CUSTOMER_SEARCH_LOCAL_FAILURE',

  FIND_STORE_REQUEST: 'FIND_STORE_REQUEST',
  FIND_STORE_SUCCESS: 'FIND_STORE_SUCCESS',
  FIND_STORE_FAILURE: 'FIND_STORE_FAILURE',

  SET_CATEGORY: 'SET_CATEGORY',
  ADD_CATEGORY_FILTER: 'ADD_CATEGORY_FILTER',
  REMOVE_CATEGORY_FILTER: 'REMOVE_CATEGORY_FILTER',
  ADD_DIETARY_FILTER: 'ADD_DIETARY_FILTER',
  REMOVE_DIETARY_FILTER: 'REMOVE_DIETARY_FILTER',
  SET_PRICEY_VALUE: 'SET_PRICEY_VALUE',
  SET_RATING_VALUE: 'SET_RATING_VALUE',
  SET_DISTANCE_VALUE: 'SET_DISTANCE_VALUE',
  SET_SEARCH_SORTBY_VALUE: 'SET_SEARCH_SORTBY_VALUE',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_MORE_FILTERS: 'SET_MORE_FILTERS',
  SET_DELIVERY_TYPE: 'SET_DELIVERY_TYPE',
  SET_DELIVERYTIME: 'SET_DELIVERYTIME',
  SET_DELIVERYTIME_DETAIL: 'SET_DELIVERYTIME_DETAIL',
  SET_CURRENT_STORE: 'SET_CURRENT_STORE',
}
