export const tipButtons = [
  {
    value: 0.05,
    label: '%5',
    isCustom: false,
  },
  {
    value: 0.1,
    label: '%10',
    isCustom: false,
  },
  {
    value: 0.15,
    label: '%15',
    isCustom: false,
  },
  {
    value: 'custom',
    label: 'custom-label',
    isCustom: true,
  },
]

export const webTipButtons = [
  {
    value: 0.05,
    label: '%5',
    isCustom: false,
  },
  {
    value: 0.1,
    label: '%10',
    isCustom: false,
  },
  {
    value: 0.15,
    label: '%15',
    isCustom: false,
  },
]
