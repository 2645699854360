import React from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import MobileTopHeader from '../../../common/MobileTopHeader'
import SlidingSectionRow from './SlidingSectionRow'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 72,
    width: '100%',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: 'solid 1px #ededed',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: 36,
    width: '100%',
  },
  isSticky: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 100,
    boxShadow: '0 2px 24px 0 rgb(0 0 0 / 15%)',
    backgroundColor: '#ffffff !important',
    animation: '500ms ease-in-out 0s normal none 1 running fadeInDown',
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

export const StickyTopInfo = ({ isSticky, goBackHandler, sections, selectedSections, handleSectionSelect, name }) => {
  const classes = useStyles()
  if (!isSticky) return null
  return (
    <div className={clsx(classes.root, { [classes.isSticky]: isSticky })}>
      <div className={classes.row}>
        <MobileTopHeader name={name} callback={goBackHandler} storeMain />
      </div>
      <SlidingSectionRow
        sections={sections}
        selectedSections={selectedSections}
        handleSectionSelect={handleSectionSelect}
      />
    </div>
  )
}

StickyTopInfo.propTypes = {
  // second: PropTypes.third
}

export default StickyTopInfo
