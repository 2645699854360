import React, { useState, useEffect } from 'react'
import { filter } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import TierRow from './OwnBusinessDelivery/TierRow'

import { checkTierValues, confirmTierValues } from '../../../../../helpers/merchant/edit-store'

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  button: {
    textTransform: 'capitalize',
    width: 120,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    "&[is-remove='true']": {
      backgroundColor: theme.palette.secondary.main,
      marginLeft: theme.spacing(2),
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}))

const OwnBusinessDelivery = ({
  editting,
  businessInvalidState,
  setBusinessInvalidState,
  businessFocusedState,
  setBusinessFocusedState,
  tierValues,
  setTierValues,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  let resArr = filter(tierValues, (item) => item.isActive === true)

  const [error, setError] = useState(false)
  const [confirmError, setConfirmError] = useState(false)

  useEffect(() => {
    let active = true
    if (active) {
      if (tierValues) {
        if (error || confirmError) {
          let currentTier = tierValues[resArr.length]
          let isFilled = checkTierValues(currentTier)
          isFilled && setError(false)
          if (resArr.length > 1) {
            let confirmValues = confirmTierValues(tierValues, resArr.length)
            confirmValues && setConfirmError(false)
          }
        }
      } else return
    }
    return () => {
      active = false
    }
  }, [tierValues])

  const handleChange = (prop) => (e) => {
    const { name, value } = e.target
    setBusinessInvalidState({
      ...businessInvalidState,
      [prop]: { ...businessInvalidState[prop], [name]: !value },
    })
    setBusinessFocusedState({
      ...businessFocusedState,
      [prop]: { ...businessFocusedState[prop], [name]: true },
    })
    setTierValues({
      ...tierValues,
      [prop]: { ...tierValues[prop], [name]: +value },
    })
  }

  const addTier = () => {
    let prop = resArr.length + 1
    if (prop === 4) return
    let isFilled = checkTierValues(tierValues[prop - 1])
    if (isFilled) {
      if (prop < 3) {
        setTierValues({
          ...tierValues,
          [prop]: {
            isActive: true,
            minOrder: '',
            maxDistance: '',
            deliveryFee: '',
          },
        })
      } else {
        let confirmValues = confirmTierValues(tierValues, prop - 1)
        if (confirmValues) {
          setTierValues({
            ...tierValues,
            [prop]: {
              isActive: true,
              minOrder: '',
              maxDistance: '',
              deliveryFee: '',
            },
          })
        } else {
          setConfirmError(true)
        }
      }
    } else {
      setError(true)
    }
  }

  const removeTier = () => {
    let prop = resArr.length
    if (prop === 1) return
    setTierValues({
      ...tierValues,
      [prop]: {
        isActive: false,
        minOrder: '',
        maxDistance: '',
        deliveryFee: '',
      },
    })
  }

  return (
    <>
      {tierValues && tierValues[1].isActive && (
        <TierRow
          tierNum={1}
          tier={tierValues[1]}
          handleChange={handleChange}
          editting={editting}
          isInvalid={businessInvalidState[1]}
          isFocused={businessFocusedState[1]}
        />
      )}
      {tierValues && tierValues[2].isActive && (
        <TierRow
          tierNum={2}
          tier={tierValues[2]}
          handleChange={handleChange}
          editting={editting}
          isInvalid={businessInvalidState[2]}
          isFocused={businessFocusedState[2]}
        />
      )}
      {tierValues && tierValues[3].isActive && (
        <TierRow
          tierNum={3}
          tier={tierValues[3]}
          handleChange={handleChange}
          editting={editting}
          isInvalid={businessInvalidState[3]}
          isFocused={businessFocusedState[3]}
        />
      )}
      <div className={classes.buttonsContainer}>
        {tierValues && !tierValues[3].isActive && (
          <Button disabled={!editting} onClick={addTier} className={classes.button} variant='contained'>
            {f({ id: 'add-label' })}
          </Button>
        )}
        {tierValues && resArr.length > 1 && (
          <Button
            disabled={!editting}
            onClick={removeTier}
            className={classes.button}
            variant='contained'
            is-remove='true'
          >
            {f({ id: 'remove-label' })}
          </Button>
        )}
      </div>
      {error && <Typography color='secondary'>{f({ id: 'fill-necessary-fields' })}</Typography>}
      {confirmError && <Typography color='secondary'>{f({ id: 'tier-error' })}</Typography>}
    </>
  )
}

OwnBusinessDelivery.propTypes = {
  editting: PropTypes.bool,
  businessInvalidState: PropTypes.object,
  setBusinessInvalidState: PropTypes.func,
  businessFocusedState: PropTypes.object,
  setBusinessFocusedState: PropTypes.func,
  tierValues: PropTypes.object,
  setTierValues: PropTypes.func,
}

export default OwnBusinessDelivery
