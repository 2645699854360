import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { emailRegex, phoneRegex } from '../../../utils/regex_collections'
import { parseGoogleAPIAddress } from '../../../utils/common'
import NickName from '../common/NickName'
import StoreNumber from '../common/StoreNumber'
import Phone from '../common/Phone'
import Email from '../common/Email'
import SwitchButton from '../common/SwitchButton'
import Address from '../common/Address'
import Pricey from './Pricey'
import MinHeadCount from '../common/MinHeadCount'
import CategoryId from '../common/CategoryId'

const StoreDetails = ({
  classes,
  store,
  storeInformation,
  isFocused,
  isInvalid,
  editting,
  setIsFocused,
  setIsInvalid,
  setStore,
  setStoreInformation,
  countryCode,
  phone,
  setPhone,
  setCountryCode,
  aptSuite,
  setAptSuite,
}) => {
  const { formatMessage: f } = useIntl()

  const nicknameHandler = (e) => {
    setIsFocused({ ...isFocused, nickName: true })
    setIsInvalid({ ...isInvalid, nickName: !e.target.value })
    setStore({ ...store, nickname: e.target.value })
  }

  const minHeadCountHandler = (e) => {
    setIsFocused({ ...isFocused, minHeadCount: true })
    setIsInvalid({ ...isInvalid, minHeadCount: !e.target.value })
    setStore({ ...store, minHeadCount: e.target.value })
  }

  const categoryIdHandler = (e) => {
    setIsFocused({ ...isFocused, categoryId: true })
    setIsInvalid({ ...isInvalid, categoryId: !e.target.value })
    setStore({ ...store, categoryId: e.target.value })
  }

  const storeNumberHandler = (e) => {
    setStore({ ...store, storeNumber: e.target.value })
  }

  const phoneNumberHandler = (e) => {
    setIsFocused({ ...isFocused, phone: true })
    setIsInvalid({ ...isInvalid, phone: !phoneRegex.test(e.target.value) })
    setPhone(e.target.value)
  }

  const emailHandler = (e) => {
    setIsFocused({ ...isFocused, email: true })
    setIsInvalid({ ...isInvalid, email: !emailRegex.test(e.target.value) })
    setStoreInformation({ ...storeInformation, email: e.target.value })
  }

  const sameDayOrderHandler = (e) => {
    editting && setStore({ ...store, acceptSameDayOrder: e.target.checked })
  }
  const toggleStoreActivity = (e) => {
    editting && setStore({ ...store, active: e.target.checked })
  }

  const handleStoreAddressChange = (address) => {
    let parsedAddress = parseGoogleAPIAddress(address)

    if (parsedAddress) {
      let newStoreInformation = { ...storeInformation, address: parsedAddress }
      setStoreInformation(newStoreInformation)
    }
  }

  return (
    <>
      <div className={classes.header}>
        <Typography variant='h5'>{f({ id: 'store-details-label' })}</Typography>
      </div>
      <Grid container spacing={2} className='full-width'>
        <NickName
          store={store}
          isFocused={isFocused}
          isInvalid={isInvalid}
          nicknameHandler={nicknameHandler}
          editting={editting}
        />
        <StoreNumber store={store} storeNumberHandler={storeNumberHandler} editting={editting} />
        <Phone
          countryCode={countryCode}
          phone={phone}
          isFocused={isFocused}
          isInvalid={isInvalid}
          phoneHandler={phoneNumberHandler}
          editting={editting}
          setCode={setCountryCode}
          prop='phone'
          gridVal={6}
        />
        <Email
          storeInfo={storeInformation}
          isFocused={isFocused}
          isInvalid={isInvalid}
          emailHandler={emailHandler}
          editting={editting}
        />
        <CategoryId
          value={store?.categoryId}
          handleChange={categoryIdHandler}
          isFocused={isFocused}
          isInvalid={isInvalid}
          editting={editting}
        />
        <MinHeadCount
          value={store?.minHeadCount || ''}
          handleChange={minHeadCountHandler}
          isFocused={isFocused}
          isInvalid={isInvalid}
          editting={editting}
        />
        <Grid item xs={12} className={classes.switch}>
          <SwitchButton
            store={store}
            callback={sameDayOrderHandler}
            value='acceptSameDayOrder'
            label='accept-sameday-order'
          />
          <SwitchButton store={store} callback={toggleStoreActivity} value='active' label='store-active' />
        </Grid>
        {store?.pricey ? <Pricey pricey={store?.pricey} /> : null}
        <Address
          storeInfo={storeInformation}
          aptSuite={aptSuite || ''}
          setAptSuite={setAptSuite}
          addressHandler={handleStoreAddressChange}
          editting={editting}
        />
      </Grid>
    </>
  )
}

StoreDetails.propTypes = {
  classes: PropTypes.object,
  store: PropTypes.object,
  storeInformation: PropTypes.object,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  editting: PropTypes.bool,
  setIsFocused: PropTypes.func,
  setIsInvalid: PropTypes.func,
  setStore: PropTypes.func,
  setStoreInformation: PropTypes.func,
  countryCode: PropTypes.string,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  setCountryCode: PropTypes.func,
  aptSuite: PropTypes.string,
  setAptSuite: PropTypes.func,
}

export default StoreDetails
