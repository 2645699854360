import React from 'react'
import { useIntl } from 'react-intl'

import CloseIcon from '../../../../images/close.svg'

import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '40px',
  },
}))

const Header = ({ onClose, headerInfo }) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()

  const header = `${format({ id: 'orderid-label' })}: ${headerInfo}`

  return (
    <div className={classes.root}>
      <div>{header}</div>
      <IconButton onClick={onClose}>
        <img src={CloseIcon} height='22' width='22' alt='Back' />
      </IconButton>
    </div>
  )
}

export default Header
