const handleAddressName = (addresses = []) => {
  let textPart = 'My new address'
  let numArray = []
  let filteredAddressess = addresses.filter((address) => address.name && address.name.includes(textPart))
  filteredAddressess.length > 0 &&
    filteredAddressess.map((a) => numArray.push(parseInt(a.name.slice(a.name.lastIndexOf(' ') + 1))))
  numArray.sort((a, b) => a - b)
  let numberPart = numArray.length === 0 ? 1 : numArray[numArray.length - 1] + 1
  let newHeader = `${textPart} ${numberPart}`
  return newHeader
}

export { handleAddressName }
