import React from 'react'
import { Button, ButtonGroup, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useMerchantHomeContext } from '../../../../../../context/merchant/MerchantHome/main'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  button: {
    width: 75,
    textTransform: 'none',
    height: 24,
    fontSize: 11,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'center',
  },
  buttonSelected: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    color: 'black',
    fontWeight: 500,
  },
  buttonGroup: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}))

export const SelectView = (props) => {
  const classes = useStyles()
  const isRestaurant = useSelector(({ merchantStores }) => merchantStores.isRestaurantView)

  const {
    setters: { setView },
  } = useMerchantHomeContext()
  return (
    <ButtonGroup variant='outlined' size='small' className={classes.buttonGroup}>
      <Button
        onClick={() => setView(true)}
        className={clsx(classes.button, {
          [classes.buttonSelected]: isRestaurant,
        })}
        size='small'
      >
        Restaurant
      </Button>
      <Button
        onClick={() => setView(false)}
        className={clsx(classes.button, {
          [classes.buttonSelected]: !isRestaurant,
        })}
        size='small'
      >
        Store
      </Button>
    </ButtonGroup>
  )
}
