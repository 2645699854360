import React, { useRef } from 'react'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import InjectedCart from './injectedCart'

import { IconButton, Dialog, DialogContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const CartComponent = ({ classes, showBasket, closeBasket, Transition, openVerifyDialog, openAddPhoneDialog }) => {
  const cartRef = useRef()

  const handleClose = () => cartRef.current.onClose()

  return (
    <Dialog
      className={classes.dialog}
      maxWidth={'lg'}
      fullWidth={true}
      open={showBasket}
      TransitionComponent={Transition}
      classes={{ paper: classes.dialogPaper }}
    >
      <IconButton aria-label='Close' className={classes.customizedButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent style={{ padding: 0 }}>
        <Elements stripe={promise}>
          <InjectedCart
            cartRef={cartRef}
            closeBasket={closeBasket}
            openVerifyDialog={openVerifyDialog}
            openAddPhoneDialog={openAddPhoneDialog}
            showBasket={showBasket}
          />
        </Elements>
      </DialogContent>
    </Dialog>
  )
}

export default CartComponent
