import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { styles } from './styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles(styles)

const PrivacyPolicy = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const Text = (props) => (
    <Typography paragraph className={classes.text} {...props}>
      {props.children}
    </Typography>
  )

  const ListItem = (props) => (
    <li className={classes.text} {...props}>
      {props.children}
    </li>
  )

  const AddressText = (props) => (
    <Typography className={classes.addressText} {...props}>
      {props.children}
    </Typography>
  )

  return (
    <>
      <Text>Last updated July 14, 2022</Text>
      <Text>
        This privacy notice for TOOKFRESH LLC ("Company," "we," "us," or "our"), describes how and why we might collect,
        store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
      </Text>
      <ul>
        <ListItem>
          Visit our website at <a href='https://tookfresh.com'>https://tookfresh.com</a>, or any website of ours that
          links to this privacy notice
        </ListItem>
        <ListItem>
          Download and use our mobile application (TookFresh), or any other application of ours that links to this
          privacy notice
        </ListItem>
        <ListItem>Engage with us in other related ways, including any sales, marketing, or events</ListItem>
      </ul>
      <Text>
        Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If
        you do not agree with our policies and practices, please do not use our Services. If you still have any
        questions or concerns, please contact us at tookfreshapp@gmail.com.
      </Text>
      <Text title-text='true'>SUMMARY OF KEY POINTS</Text>
      <Text>
        What personal information do we process? When you visit, use, or navigate our Services, we may process personal
        information depending on how you interact with TOOKFRESH LLC and the Services, the choices you make, and the
        products and features you use.
      </Text>
      <Text>Do we process any sensitive personal information? We do not process sensitive personal information.</Text>
      <Text>
        Do we receive any information from third parties? We do not receive any information from third parties.
      </Text>
      <Text>
        How do we process your information? We process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process
        your information for other purposes with your consent. We process your information only when we have a valid
        legal reason to do so.
      </Text>
      <Text>
        In what situations and with which parties do we share personal information? We may share information in specific
        situations and with specific third parties.
      </Text>
      <Text>
        How do we keep your information safe? We have organizational and technical processes and procedures in place to
        protect your personal information. However, no electronic transmission over the internet or information storage
        technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our security and improperly collect, access,
        steal, or modify your information.
      </Text>
      <Text>
        What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you
        have certain rights regarding your personal information.
      </Text>
      <Text title-text='true'>1. WHAT INFORMATION DO WE COLLECT?</Text>
      <Text subtitle-text='true'>Personal information you disclose to us</Text>
      <Text>
        <i>In Short: We collect personal information that you provide to us.</i>
      </Text>
      <Text>
        We collect personal information that you voluntarily provide to us when you register on the Services, express an
        interest in obtaining information about us or our products and Services, when you participate in activities on
        the Services, or otherwise when you contact us.
      </Text>
      <Text>
        Personal Information Provided by You. The personal information that we collect depends on the context of your
        interactions with us and the Services, the choices you make, and the products and features you use. The personal
        information we collect may include the following:
      </Text>
      <ul>
        <ListItem>names </ListItem>
        <ListItem>phone numbers </ListItem>
        <ListItem>email addresses</ListItem>
        <ListItem>user names</ListItem>
        <ListItem>passwords</ListItem>
        <ListItem>contact preferences</ListItem>
      </ul>
      <Text subtitle-text='true'>Sensitive Information. We do not process sensitive information.</Text>
      <Text>
        Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment
        instrument number (such as a credit card number), and the security code associated with your payment instrument.
        All payment data is stored by Stripe. You may find their privacy notice link(s) here:{' '}
        <a href='https://stripe.com/privacy'>https://stripe.com/privacy</a>.
      </Text>
      <Text>
        Social Media Login Data. We may provide you with the option to register with us using your existing social media
        account details, like your Facebook, Twitter, or other social media account. If you choose to register in this
        way, we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
        below.
      </Text>
      <Text>
        Application Data. If you use our application(s), we also may collect the following information if you choose to
        provide us with access or permission:
      </Text>
      <ul>
        <ListItem>
          <i>Geolocation Information. </i>We may request access or permission to track location-based information from
          your mobile device, either continuously or while you are using our mobile application(s), to provide certain
          location-based services. If you wish to change our access or permissions, you may do so in your device's
          settings.
        </ListItem>
        <ListItem>
          <i>Mobile Device Access. </i>We may request access or permission to certain features from your mobile device,
          including your mobile device's social media accounts, and other features. If you wish to change our access or
          permissions, you may do so in your device's settings.
        </ListItem>
        <ListItem>
          <i>Mobile Device Data. </i>We automatically collect device information (such as your mobile device ID, model,
          and manufacturer), operating system, version information and system configuration information, device and
          application identification numbers, browser type and version, hardware model Internet service provider and/or
          mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we
          may also collect information about the phone network associated with your mobile device, your mobile device’s
          operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and
          information about the features of our application(s) you accessed.
        </ListItem>
        <ListItem>
          <i>Push Notifications. </i>We may request to send you push notifications regarding your account or certain
          features of the application(s). If you wish to opt out from receiving these types of communications, you may
          turn them off in your device's settings.
        </ListItem>
      </ul>
      <Text>
        This information is primarily needed to maintain the security and operation of our application(s), for
        troubleshooting, and for our internal analytics and reporting purposes.
      </Text>
      <Text>
        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of
        any changes to such personal information.
      </Text>
      <Text subtitle-text='true'>Information automatically collected</Text>
      <Text>
        <i>
          In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device
          characteristics — is collected automatically when you visit our Services.
        </i>
      </Text>
      <Text>
        We automatically collect certain information when you visit, use, or navigate the Services. This information
        does not reveal your specific identity (like your name or contact information) but may include device and usage
        information, such as your IP address, browser and device characteristics, operating system, language
        preferences, referring URLs, device name, country, location, information about how and when you use our
        Services, and other technical information. This information is primarily needed to maintain the security and
        operation of our Services, and for our internal analytics and reporting purposes.
      </Text>
      <Text>Like many businesses, we also collect information through cookies and similar technologies.</Text>
      <Text>The information we collect includes:</Text>
      <ul>
        <ListItem>
          <i>Log and Usage Data. </i>Log and usage data is service-related, diagnostic, usage, and performance
          information our servers automatically collect when you access or use our Services and which we record in log
          files. Depending on how you interact with us, this log data may include your IP address, device information,
          browser type, and settings and information about your activity in the Services (such as the date/time stamps
          associated with your usage, pages and files viewed, searches, and other actions you take such as which
          features you use), device event information (such as system activity, error reports (sometimes called "crash
          dumps"), and hardware settings).
        </ListItem>
        <ListItem>
          <i>Device Data. </i>We collect device data such as information about your computer, phone, tablet, or other
          device you use to access the Services. Depending on the device used, this device data may include information
          such as your IP address (or proxy server), device and application identification numbers, location, browser
          type, hardware model, Internet service provider and/or mobile carrier, operating system, and system
          configuration information.
        </ListItem>
        <ListItem>
          <i>Location Data. </i>We collect location data such as information about your device's location, which can be
          either precise or imprecise. How much information we collect depends on the type and settings of the device
          you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data
          that tells us your current location (based on your IP address). You can opt out of allowing us to collect this
          information either by refusing access to the information or by disabling your Location setting on your device.
          However, if you choose to opt out, you may not be able to use certain aspects of the Services.
        </ListItem>
      </ul>
      <Text title-text='true'>2. HOW DO WE PROCESS YOUR INFORMATION?</Text>
      <Text>
        <i>
          In Short: We process your information to provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also process your information for other
          purposes with your consent.
        </i>
      </Text>
      <Text>
        We process your personal information for a variety of reasons, depending on how you interact with our Services,
        including:
      </Text>
      <ul>
        <ListItem>
          To facilitate account creation and authentication and otherwise manage user accounts. We may process your
          information so you can create and log in to your account, as well as keep your account in working order.
        </ListItem>
        <ListItem>
          To deliver and facilitate delivery of services to the user. We may process your information to provide you
          with the requested service.
        </ListItem>
        <ListItem>
          To respond to user inquiries/offer support to users. We may process your information to respond to your
          inquiries and solve any potential issues you might have with the requested service.
        </ListItem>
        <ListItem>
          To send administrative information to you. We may process your information to send you details about our
          products and services, changes to our terms and policies, and other similar information.
        </ListItem>
        <ListItem>
          To fulfill and manage your orders. We may process your information to fulfill and manage your orders,
          payments, returns, and exchanges made through the Services.
        </ListItem>
        <ListItem>
          To request feedback. We may process your information when necessary to request feedback and to contact you
          about your use of our Services.
        </ListItem>
        <ListItem>
          To send you marketing and promotional communications. We may process the personal information you send to us
          for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our
          marketing emails at any time. For more information, see <u>"WHAT ARE YOUR PRIVACY RIGHTS?"</u> below).
        </ListItem>
        <ListItem>
          To protect our Services. We may process your information as part of our efforts to keep our Services safe and
          secure, including fraud monitoring and prevention.
        </ListItem>
        <ListItem>
          To evaluate and improve our Services, products, marketing, and your experience. We may process your
          information when we believe it is necessary to identify usage trends, determine the effectiveness of our
          promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.
        </ListItem>
        <ListItem>
          To identify usage trends. We may process information about how you use our Services to better understand how
          they are being used so we can improve them.
        </ListItem>
        <ListItem>
          To determine the effectiveness of our marketing and promotional campaigns. We may process your information to
          better understand how to provide marketing and promotional campaigns that are most relevant to you.
        </ListItem>
      </ul>
      <Text title-text='true'>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Text>
      <Text>
        <i>
          In Short: We may share information in specific situations described in this section and/or with the following
          third parties.
        </i>
      </Text>
      <Text>We may need to share your personal information in the following situations:</Text>
      <ul>
        <ListItem>
          Business Transfers. We may share or transfer your information in connection with, or during negotiations of,
          any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another
          company.
        </ListItem>
        <ListItem>
          When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs
          (e.g., Google Maps API, Places API). To find out more about Google’s Privacy Policy, please refer to this{' '}
          <a href='https://policies.google.com/privacy'>link</a>. We obtain and store on your device ("cache") your
          location. You may revoke your consent anytime by contacting us at the contact details provided at the end of
          this document.
        </ListItem>
      </ul>
      <Text title-text='true'>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Text>
      <Text>
        <i>In Short: We may use cookies and other tracking technologies to collect and store your information.</i>
      </Text>
      <Text>
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
        information. Specific information about how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </Text>
      <Text title-text='true'>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</Text>
      <Text>
        <i>
          In Short: If you choose to register or log in to our services using a social media account, we may have access
          to certain information about you.
        </i>
      </Text>
      <Text>
        Our Services offer you the ability to register and log in using your third-party social media account details
        (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information
        about you from your social media provider. The profile information we receive may vary depending on the social
        media provider concerned, but will often include your name, email address, friends list, and profile picture, as
        well as other information you choose to make public on such a social media platform.
      </Text>
      <Text>
        We will use the information we receive only for the purposes that are described in this privacy notice or that
        are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not
        responsible for, other uses of your personal information by your third-party social media provider. We recommend
        that you review their privacy notice to understand how they collect, use, and share your personal information,
        and how you can set your privacy preferences on their sites and apps.
      </Text>
      <Text title-text='true'>6. HOW LONG DO WE KEEP YOUR INFORMATION?</Text>
      <Text>
        <i>
          In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy
          notice unless otherwise required by law.
        </i>
      </Text>
      <Text>
        We will only keep your personal information for as long as it is necessary for the purposes set out in this
        privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or
        other legal requirements). No purpose in this notice will require us keeping your personal information for
        longer than the period of time in which users have an account with us.
      </Text>
      <Text>
        When we have no ongoing legitimate business need to process your personal information, we will either delete or
        anonymize such information, or, if this is not possible (for example, because your personal information has been
        stored in backup archives), then we will securely store your personal information and isolate it from any
        further processing until deletion is possible.
      </Text>
      <Text title-text='true'>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</Text>
      <Text>
        <i>
          In Short: We aim to protect your personal information through a system of organizational and technical
          security measures.
        </i>
      </Text>
      <Text>
        We have implemented appropriate and reasonable technical and organizational security measures designed to
        protect the security of any personal information we process. However, despite our safeguards and efforts to
        secure your information, no electronic transmission over the Internet or information storage technology can be
        guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or
        modify your information. Although we will do our best to protect your personal information, transmission of
        personal information to and from our Services is at your own risk. You should only access the Services within a
        secure environment.
      </Text>
      <Text title-text='true'>8. WHAT ARE YOUR PRIVACY RIGHTS?</Text>
      <Text>
        <i>In Short: You may review, change, or terminate your account at any time.</i>
      </Text>
      <Text>
        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you
        also have the right to complain to your local data protection supervisory authority. You can find their contact
        details here:{' '}
        <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
          https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
        .
      </Text>
      <Text>
        If you are located in Switzerland, the contact details for the data protection authorities are available here:{' '}
        <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
      </Text>
      <Text>
        <u>Withdrawing your consent:</u> If we are relying on your consent to process your personal information, which
        may be express and/or implied consent depending on the applicable law, you have the right to withdraw your
        consent at any time. You can withdraw your consent at any time by contacting us by using the contact details
        provided in the section <u>"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</u> below.
      </Text>
      <Text>
        However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when
        applicable law allows, will it affect the processing of your personal information conducted in reliance on
        lawful processing grounds other than consent.
      </Text>
      <Text subtitle-text='true'>Account Information</Text>
      <Text>
        If you would at any time like to review or change the information in your account or terminate your account, you
        can:
      </Text>
      <ul>
        <ListItem>Log in to your account settings and update your user account.</ListItem>
      </ul>
      <Text>
        Upon your request to terminate your account, we will deactivate or delete your account and information from our
        active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems,
        assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
      </Text>
      <Text title-text='true'>9. CONTROLS FOR DO-NOT-TRACK FEATURES</Text>
      <Text>
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
        feature or setting you can activate to signal your privacy preference not to have data about your online
        browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and
        implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any
        other mechanism that automatically communicates your choice not to be tracked online. If a standard for online
        tracking is adopted that we must follow in the future, we will inform you about that practice in a revised
        version of this privacy notice.
      </Text>
      <Text title-text='true'>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Text>
      <Text>
        <i>
          In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your
          personal information.
        </i>
      </Text>
      <Text>
        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
        California residents to request and obtain from us, once a year and free of charge, information about categories
        of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
        addresses of all third parties with which we shared personal information in the immediately preceding calendar
        year. If you are a California resident and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </Text>
      <Text>
        If you are under 18 years of age, reside in California, and have a registered account with Services, you have
        the right to request removal of unwanted data that you publicly post on the Services. To request removal of such
        data, please contact us using the contact information provided below and include the email address associated
        with your account and a statement that you reside in California. We will make sure the data is not publicly
        displayed on the Services, but please be aware that the data may not be completely or comprehensively removed
        from all our systems (e.g., backups, etc.).
      </Text>
      <Text subtitle-text='true'>CCPA Privacy Notice</Text>
      <Text>The California Code of Regulations defines a "resident" as:</Text>
      <ul>
        <ListItem>
          (1) every individual who is in the State of California for other than a temporary or transitory purpose and
        </ListItem>
        <ListItem>
          (2) every individual who is domiciled in the State of California who is outside the State of California for a
          temporary or transitory purpose
        </ListItem>
      </ul>
      <Text>All other individuals are defined as "non-residents."</Text>
      <Text>
        If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your
        personal information.
      </Text>
      <Text>What categories of personal information do we collect?</Text>
      <Text>We have collected the following categories of personal information in the past twelve (12) months:</Text>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Examples</TableCell>
            <TableCell>Collected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>A. Identifiers</TableCell>
            <TableCell>
              Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique
              personal identifier, online identifier, Internet Protocol address, email address, and account name
            </TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>B. Personal information categories listed in the California Customer Records statute</TableCell>
            <TableCell>
              Name, contact information, education, employment, employment history, and financial information
            </TableCell>
            <TableCell>YES</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>C. Protected classification characteristics under California or federal law</TableCell>
            <TableCell>Gender and date of birth</TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>D. Commercial information</TableCell>
            <TableCell>Transaction information, purchase history, financial details, and payment information</TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>E. Biometric information</TableCell>
            <TableCell>Fingerprints and voiceprints</TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>F. Internet or other similar network activity</TableCell>
            <TableCell>
              Browsing history, search history, online behavior, interest data, and interactions with our and other
              websites, applications, systems, and advertisements
            </TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>G. Geolocation data</TableCell>
            <TableCell>Device location</TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>H. Audio, electronic, visual, thermal, olfactory, or similar information</TableCell>
            <TableCell>
              Images and audio, video or call recordings created in connection with our business activities
            </TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>I. Professional or employment-related information</TableCell>
            <TableCell>
              Business contact details in order to provide you our services at a business level or job title, work
              history, and professional qualifications if you apply for a job with us
            </TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>J. Education Information</TableCell>
            <TableCell>Student records and directory information</TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>K. Inferences drawn from other personal information</TableCell>
            <TableCell>
              Inferences drawn from any of the collected personal information listed above to create a profile or
              summary about, for example, an individual’s preferences and characteristics
            </TableCell>
            <TableCell>NO</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Text>
        We may also collect other personal information outside of these categories instances where you interact with us
        in person, online, or by phone or mail in the context of:
      </Text>
      <ul>
        <ListItem>Receiving help through our customer support channels;</ListItem>
        <ListItem>Participation in customer surveys or contests; and</ListItem>
        <ListItem>Facilitation in the delivery of our Services and to respond to your inquiries.</ListItem>
      </ul>
      <Text>How do we use and share your personal information?</Text>
      <Text>More information about our data collection and sharing practices can be found in this privacy notice.</Text>
      <Text>
        You may contact us by email at tookfreshapp@gmail.com, or by referring to the contact details at the bottom of
        this document.
      </Text>
      <Text>
        If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized
        agent does not submit proof that they have been validly authorized to act on your behalf.
      </Text>
      <Text>Will your information be shared with anyone else?</Text>
      <Text>
        We may disclose your personal information with our service providers pursuant to a written contract between us
        and each service provider. Each service provider is a for-profit entity that processes the information on our
        behalf.
      </Text>
      <Text>
        We may use your personal information for our own business purposes, such as for undertaking internal research
        for technological development and demonstration. This is not considered to be "selling" your personal
        information.
      </Text>
      <Text>
        TOOKFRESH LLC has not disclosed or sold any personal information to third parties for a business or commercial
        purpose in the preceding twelve (12) months. TOOKFRESH LLC will not sell personal information in the future
        belonging to website visitors, users, and other consumers.
      </Text>
      <Text>Your rights with respect to your personal data</Text>
      <Text>
        <u>Right to request deletion of the data — Request to delete</u>
      </Text>
      <Text>
        You can ask for the deletion of your personal information. If you ask us to delete your personal information, we
        will respect your request and delete your personal information, subject to certain exceptions provided by law,
        such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance
        requirements resulting from a legal obligation, or any processing that may be required to protect against
        illegal activities.
      </Text>
      <Text>
        <u>Right to be informed — Request to know</u>
      </Text>
      <Text>Depending on the circumstances, you have a right to know:</Text>
      <ul>
        <ListItem>whether we collect and use your personal information;</ListItem>
        <ListItem>the categories of personal information that we collect;</ListItem>
        <ListItem>the purposes for which the collected personal information is used;</ListItem>
        <ListItem>whether we sell your personal information to third parties;</ListItem>
        <ListItem>the categories of personal information that we sold or disclosed for a business purpose;</ListItem>
        <ListItem>
          the categories of third parties to whom the personal information was sold or disclosed for a business purpose;
          and
        </ListItem>
        <ListItem>the business or commercial purpose for collecting or selling personal information.</ListItem>
      </ul>
      <Text>
        In accordance with applicable law, we are not obligated to provide or delete consumer information that is
        de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
      </Text>
      <Text>
        <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u>
      </Text>
      <Text>We will not discriminate against you if you exercise your privacy rights.</Text>
      <Text>
        <u>Verification process</u>
      </Text>
      <Text>
        Upon receiving your request, we will need to verify your identity to determine you are the same person about
        whom we have the information in our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously provided us. For instance, depending on
        the type of request you submit, we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or we may contact you through a
        communication method (e.g., phone or email) that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </Text>
      <Text>
        We will only use personal information provided in your request to verify your identity or authority to make the
        request. To the extent possible, we will avoid requesting additional information from you for the purposes of
        verification. However, if we cannot verify your identity from the information already maintained by us, we may
        request that you provide additional information for the purposes of verifying your identity and for security or
        fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying
        you.
      </Text>
      <Text>
        <u>Other privacy rights</u>
      </Text>
      <ul>
        <ListItem>You may object to the processing of your personal information.</ListItem>
        <ListItem>
          You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict
          the processing of the information.
        </ListItem>
        <ListItem>
          You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request
          from an authorized agent that does not submit proof that they have been validly authorized to act on your
          behalf in accordance with the CCPA.
        </ListItem>
        <ListItem>
          You may request to opt out from future selling of your personal information to third parties. Upon receiving
          an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15)
          days from the date of the request submission.
        </ListItem>
      </ul>
      <Text>
        To exercise these rights, you can contact us by email at tookfreshapp@gmail.com,{' '}
        <strong>
          or by referring to the contact details at the bottom of this document. If you have a complaint about how we
          handle your data, we would like to hear from you.
        </strong>
      </Text>
      <Text title-text='true'>11. DO WE MAKE UPDATES TO THIS NOTICE?</Text>
      <Text>
        <i>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</i>
      </Text>
      <Text>
        We may update this privacy notice from time to time. The updated version will be indicated by an updated
        "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
        directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of
        how we are protecting your information.
      </Text>
      <Text title-text='true'>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Text>
      <Text>
        If you have questions or comments about this notice, you may email us at tookfreshapp@gmail.com or by post to:
      </Text>
      <AddressText>TOOKFRESH LLC</AddressText>
      <AddressText>740 Alamo Dr</AddressText>
      <AddressText>Morgan Hill, CA 95037</AddressText>
      <AddressText is-last='true'>United States</AddressText>
      <Text title-text='true'>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</Text>
      <Text>
        Based on the applicable laws of your country, you may have the right to request access to the personal
        information we collect from you, change that information, or delete it.
      </Text>
    </>
  )
}

export default PrivacyPolicy
