import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Footer from '../Footers'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import locationIcon from '../../../images/pickup_location.svg'
import GoogleMapReact from 'google-map-react'
import { customerService, searchService, storeService } from '../../../services'
import StoreCard from '../../common/StoreCard'
import { generalConstants } from '../../../constants'
import { LeftTransition } from '../../utils/tools'
import Dialog from '@material-ui/core/Dialog'
import PickupListView from './pickup_list_view'
import { bottomNavItems } from '../../../constants'
import { setMainMenu } from '../../../actions/general.actions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SearchButton } from './mobile-pickup/SearchButton'
import { SearchFab } from './mobile-pickup/SearchFab'
import { HomeMarkerComponent } from './mobile-pickup/HomeMarker'
import { StoreMarkerComponent } from './mobile-pickup/StoreMarker'
import Carousel from 'nuka-carousel'
import VisibilitySensor from 'react-visibility-sensor'
import AddressDialog from '../../common/AddressDialog'
import { isMobileOnly } from 'react-device-detect'
import { getOCStatusQuery } from '../../../helpers/store/getOCStatusQuery'

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
const path = process.env.PUBLIC_URL
const height = window.innerHeight
const width = window.innerWidth

const initialSearchQuery = { lat1: null, lon1: null, lat2: null, lon2: null }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: '100vh',
    position: 'relative',
  },
  searchButton: {
    position: 'absolute',
    top: theme.spacing(3),
    left: '25%',
    width: '50%',
    zIndex: 2,
  },
  locationButton: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(2),
    zIndex: 2,
  },
  listView: {
    width: '100%',
    position: 'absolute',
    bottom: 400,
    backgroundColor: 'red',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.95,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333',
    marginLeft: theme.spacing(2),
  },
  all: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.44,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    marginRight: theme.spacing(2),
  },
  item: {
    marginLeft: theme.spacing(),
    marginBottom: theme.spacing(),
    width: '95%',
    minWidth: '95%',
  },
  mobileCarouselContainer: {
    paddingLeft: 0,
  },
}))

export const MobilePickup = ({ intl, history }) => {
  const classes = useStyles()

  const searchQuery = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const signedIn = useSelector(({ customer }) => customer.signedIn, shallowEqual)

  const [center, setCenter] = useState(null)
  const [stores, setStores] = useState([])
  const [favoriteStores, setFavoriteStores] = useState([])
  const [showLogin, setShowLogin] = useState(false)
  const [query, setQuery] = useState(initialSearchQuery)
  const [showListDialog, setShowListDialog] = useState(false)
  const [initialChange, setInitialChange] = useState(true)
  const [favoriteQuery] = useState({ page: 0, size: 100 })
  const [slideIndexPin, setSlideIndexPin] = useState(0)
  const [slideIndexCard, setSlideIndexCard] = useState(0)
  const [isPinTouched, setIsPinTouched] = useState(false)
  const [showAddressDialog, setShowAddressDialog] = useState(false)
  const [openStores, setOpenStores] = useState([])

  const dispatch = useDispatch()
  const setMainMenuAction = (menu) => dispatch(setMainMenu(menu))

  useEffect(() => {
    let active = true
    if (active) {
      if (!isMobileOnly) {
        goToHomePage()
        return
      } else if (!searchQuery.address) {
        toggleAddressDialog()
      } else {
        setMainMenuAction(bottomNavItems.PICKUP)
        setMapCenter()
        signedIn && getFavorites()
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isMobileOnly && signedIn && getFavorites()
    setMapCenter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn, JSON.stringify(searchQuery.address)])

  useEffect(() => {
    isMobileOnly && query.lat1 && initialChange && getStoresByBounds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query)])

  useEffect(() => {
    let active = true
    if (active) {
      stores?.length > 0 && getOpenStores(stores)
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores])

  const getOpenStores = async (stores) => {
    let storeIds = stores.map((store) => store.id)
    const query = getOCStatusQuery(searchQuery, storeIds)
    const response = await storeService.getStoreOCStatus(query)
    response && getOpenStoreIdsFromResponse(response)
  }

  const getOpenStoreIdsFromResponse = (response) => {
    const rawStoreOpenStatus = response?.filter((res) => res.open)
    const storeOpenStatus = rawStoreOpenStatus.map((item) => item.storeId)
    setOpenStores(storeOpenStatus)
  }

  const favoriteStateMaker = (data) => {
    //here we create a new local state level favorites store array
    let newStateArray = []
    data.forEach((elem) => {
      let newState = {}
      newState['entityId'] = elem.store.id
      newState['id'] = elem.id
      newStateArray.push(newState)
    })
    setFavoriteStores(newStateArray)
  }

  const setMapCenter = () => {
    searchQuery.address &&
      setCenter({
        lat: +searchQuery.address.latitude,
        lng: +searchQuery.address.longitude,
      })
  }

  const getFavorites = () => {
    customerService.getAllFavoriteStores(favoriteQuery).then((res) => {
      if (res.status === generalConstants.STATUS_OK) {
        const { content } = res.data
        content.length > 0 ? favoriteStateMaker(content) : setFavoriteStores([])
      }
    })
  }

  const favoriteHandler = (id) => {
    if (!signedIn) {
      setShowLogin(true)
      return
    }
    // if favorites array has already incoming id, delete it, else add to the list
    let includes = false
    let storeId
    if (favoriteStores.length > 0) {
      favoriteStores.forEach((store) => {
        store.entityId === id && ([includes, storeId] = [true, store.id])
      })
    }
    includes ? deleteFavorite(storeId) : saveFavorite(id)
  }

  const deleteFavorite = (id) => {
    customerService.deleteFavoriteStore(id).then((res) => {
      if (res === generalConstants.STATUS_OK) {
        getFavorites()
      }
    })
  }

  const saveFavorite = (id) => {
    customerService
      .saveFavoriteStore(id)
      .then((res) => {
        res.status === generalConstants.STATUS_OK && getFavorites()
      })
      .catch((e) => console.log('e ---', e.response))
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const onMapChange = (prop) => {
    setCenter(prop.center)
    // setBounds(prop.bounds)

    setQuery({
      ...query,
      lat1: prop.bounds.nw.lat,
      lon1: prop.bounds.nw.lng,
      lat2: prop.bounds.se.lat,
      lon2: prop.bounds.se.lng,
    })
    setInitialChange(false)
  }

  const getStoresByBounds = async () => {
    let searchedAddress = await JSON.parse(localStorage.getItem(generalConstants.SEARCHED_ADDRESSES))
    if (searchedAddress) {
      searchService.pickupSearch(query).then((res) => {
        res.status === generalConstants.STATUS_OK && setStores(res.data)
      })
    } else {
      toggleAddressDialog()
    }
  }

  const signInBackButtonHandler = () => setShowLogin(false)
  const toggleListDialog = () => setShowListDialog((prev) => !prev)
  const toggleAddressDialog = () => setShowAddressDialog((prev) => !prev)

  let slideIndex = useMemo(() => {
    return isPinTouched ? slideIndexPin : slideIndexCard
  }, [isPinTouched, slideIndexPin, slideIndexCard])

  const onMarkerPress = (i) => {
    setSlideIndexPin(i)
    setIsPinTouched(true)
  }

  const onCardChange = (isVisible, i) => {
    isVisible && setSlideIndexCard(i)
    setIsPinTouched(false)
  }

  const onSwipeHandler = () => {
    setIsPinTouched(false)
  }

  const goToHomePage = () => history.push(`${path}/customer`)

  if (!searchQuery.address) {
    return <AddressDialog open={showAddressDialog} close={toggleAddressDialog} />
  }

  return (
    <div className={classes.root}>
      <AddressDialog open={showAddressDialog} close={toggleAddressDialog} />
      <SearchButton variant='contained' color='primary' className={classes.searchButton} onClick={getStoresByBounds}>
        {getLabel('search-here')}
      </SearchButton>
      <SearchFab color='primary' aria-label='location' onClick={setMapCenter} className={classes.locationButton}>
        <img src={locationIcon} alt='' />
      </SearchFab>
      <div className='column grow full-width' style={{ width: width, height: height }}>
        {center && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            center={center}
            defaultZoom={11}
            //gestureHandling='greedy'
            //panControl={false}
            //options={{ fullscreenControl: false, zoomControl: false }}
            onChange={onMapChange}
          >
            <HomeMarkerComponent lat={searchQuery.address.latitude} lng={searchQuery.address.longitude} />
            {stores.length > 0 &&
              stores.map((store, i) => (
                <StoreMarkerComponent
                  onPress={onMarkerPress}
                  slideIndex={slideIndex}
                  index={i}
                  key={store.id}
                  lat={store.storeInformation.address.latitude}
                  lng={store.storeInformation.address.longitude}
                  logo={store.restaurant.logo}
                />
              ))}
          </GoogleMapReact>
        )}
      </div>
      <div className='sticky-bottom'>
        <div className='full-width column' style={{ paddingBottom: 8 }}>
          {stores.length > 0 && (
            <div className='centered-row' style={{ marginBottom: 8 }}>
              <Typography className={classes.title}>{getLabel('near-me')}</Typography>
              <div className='grow' />
              {stores.length > 1 && (
                <Button className={classes.all} onClick={toggleListDialog}>
                  {getLabel('see-all')}
                </Button>
              )}
            </div>
          )}
          <div className={classes.mobileCarouselContainer}>
            <Carousel
              transitionMode='scroll'
              slideIndex={slideIndex}
              slidesToShow={1.2}
              speed={500}
              cellSpacing={0}
              withoutControls
              onDragStart={onSwipeHandler}
              // style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              {stores.map((store, i) => {
                return (
                  <div
                    className={classes.item}
                    key={store.id}
                    style={{
                      paddingRight: i !== 0 && i === stores.length - 1 ? 10 : null,
                      width: stores.length === 1 && '100%',
                    }}
                  >
                    <VisibilitySensor onChange={(e) => onCardChange(e, i)} active={!isPinTouched}>
                      <StoreCard
                        data={store}
                        history={history}
                        onPress={favoriteHandler}
                        favorites={favoriteStores}
                        storeOpen={openStores?.includes(store.id)}
                      />
                    </VisibilitySensor>
                  </div>
                )
              })}
            </Carousel>
          </div>
        </div>

        <Footer showLogin={showLogin} goBackHandler={signInBackButtonHandler} />
      </div>
      <Dialog fullScreen open={showListDialog} onClose={toggleListDialog} TransitionComponent={LeftTransition}>
        <PickupListView
          handleClose={toggleListDialog}
          stores={stores}
          favorites={favoriteStores}
          onPress={favoriteHandler}
          openStores={openStores}
        />
      </Dialog>
    </div>
  )
}

export default withRouter(injectIntl(MobilePickup))
