import { searchQueryBasicState } from '../general.constants'

const showDialogInitialState = {
  filter: false,
  priceRange: false,
  distanceRange: false,
  deliveryDetails: false,
  dietary: false,
  sortBy: false,
}

const queryInitialState = {
  ...searchQueryBasicState,
  categoryIds: [],
  dietaryIds: [],
  pricey: null,
  rating: null,
  orderByKey: null,
  // minOrderPrice: 54,
  // orderTime: null,
  //orderTime is added in the helper function according to ASAP or SCHEDULED type
}

export { showDialogInitialState, queryInitialState }
