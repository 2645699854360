import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core'
import { orderActions, storeActions } from '../../../actions'
import { storeService } from '../../../services'
import { generalConstants } from '../../../constants'
import Footer from '../Footers'
import TopInfo from './mobile-store/TopInfo'
import Content from './mobile-store/Content'
import RelatedDialogs from './mobile-store/RelatedDialogs'
import NoMatch from '../no_match'
import { isEmpty } from 'lodash'
import { getOCStatusQuery } from '../../../helpers/store/getOCStatusQuery'
import { getCategories } from '../../../helpers/store/getCategories'
import StickyTopInfo from './mobile-store/StickyTopInfo'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    overflow: 'scroll',
    flex: '1 1 auto',
    paddingTop: theme.spacing(),
  },
  back: {
    color: '#fff',
  },

  dialog: {
    borderRadius: 20,
    width: '100%',
    maxWidth: '100%',
  },

  storeInfo: {},

  categoryRow: {
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
  },

  dialogTitle: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // borderBottom: '1px solid #f4f4f4',
  },
}))

let _isMounted = false

export const MobileStore = ({ match, intl, location, history }) => {
  const idProp = match.params.id

  const [store, setStore] = useState(null)
  const [selectedSections, setSelectedSections] = useState([])
  const [showMoreDialog, setShowMoreDialog] = useState(false)
  const [showMenuDialog, setShowMenuDialog] = useState(false)
  const [showNewOrderDialog, setShowNewOrderDialog] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [newOrderItem, setNewOrderItem] = useState(null)
  const [background, setBackground] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [logo, setLogo] = useState()
  const [error, setError] = useState(false)
  const [showMobileSigninDialog, setShowMobileSigninDialog] = useState(false)
  const [sections, setSections] = useState(null)
  const [ocStatus, setOCStatus] = useState(false)
  const [isStickyHeader, setIsSticky] = useState(false)

  const classes = useStyles()
  const ref = useRef({})

  const currentStore = useSelector(({ stores }) => stores.customerCurrentStore, shallowEqual)
  const menu = useSelector(({ orders }) => orders.cart.orderMenus, shallowEqual)
  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)
  const searchQuery = useSelector(({ search }) => search.searchQuery, shallowEqual)

  const dispatch = useDispatch()
  const setCurrentStore = (data) => dispatch(storeActions.setCurrentStore(data))
  const clearMenus = () => dispatch(orderActions.clearMenus())
  const addMenu = (id, name, item) => dispatch(orderActions.addMenuToOrder(id, name, item))

  useEffect(() => {
    _isMounted = true
    if (_isMounted) {
      storeService
        .findStore(idProp)
        .then((res) => {
          if (res.status === generalConstants.STATUS_OK) {
            setCurrentStore(res.data)
            setStore(res.data)
            setLogo(res.data.restaurant.logo)
            setBackground(res.data.restaurant.background)
            getSections(idProp)
            error && setError(false)
          }
        })
        .catch((err) => {
          setError(true)
        })
    }
    return () => {
      _isMounted = false
    }
  }, [])

  useEffect(() => {
    getOCStatus()
  }, [searchQuery.deliveryType, searchQuery.deliveryTimeDetail])

  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  const isSticky = (e) => {
    const scrollTop = window.scrollY
    if (scrollTop >= 244) {
      !isStickyHeader && setIsSticky((prev) => !prev)
    } else {
      isStickyHeader && setIsSticky((prev) => !prev)
    }
  }

  const getSections = async (id) => {
    await storeService
      .getAllSectionsUi(id)
      .then((res) => {
        if (!isEmpty(res)) {
          res.forEach((sec) => {
            ref.current[`${sec.id}_ref`] = React.createRef()
          })
          const sectionsAscending = [...res].sort((a, b) => a.order_ - b.order_)
          const activeSections = sectionsAscending.filter(
            (section) => section.active == true && section.menus.length > 0
          )
          setSections(activeSections)
          const { storeCategories } = getCategories(activeSections)
          setSelectedCategories(storeCategories)
        }
        // setLoading(false)
      })
      .catch((err) => {
        setError(true)
        // setLoading(false)
      })
  }

  const getOCStatus = async () => {
    let query = getOCStatusQuery(searchQuery, idProp)
    const response = await storeService.getStoreOCStatus(query)
    let status = response[0]?.open
    setOCStatus(status)
  }

  const handleSigninModalOpen = () => setShowMobileSigninDialog(true)

  const handleSigninModalClose = () => setShowMobileSigninDialog(false)

  const handleSectionSelect = (id) => {
    const el = ref.current[`${id}_ref`].current
    const yOffset = -75
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  const onMenuItemShow = (menu, id) => {
    setSelectedSections([...selectedSections, { sectionId: id, menuId: menu.id }])
  }

  const onMenuItemHide = (menu, id) => {
    let relatedMenuItem = selectedSections.find((elem) => elem.menuId === menu.id && elem.sectionId === id)
    if (relatedMenuItem) {
      let filteredSections = selectedSections.filter((item) => item.menuId !== menu.id)
      setSelectedSections(filteredSections)
    }
  }

  const toggleMoreDialog = () => {
    setShowMoreDialog((prev) => !prev)
  }

  const handleMenuItemClick = (item) => {
    setSelectedMenu(item)
    setShowMenuDialog(true)
  }

  const closeMenuDialog = () => {
    setShowMenuDialog(false)
  }

  const handleShowNewOrderDialog = (newOrderItem) => {
    setShowNewOrderDialog(true)
    setNewOrderItem(newOrderItem)
  }

  const closeNewOrderDialog = () => {
    setShowNewOrderDialog(false)
    setNewOrderItem(null)
  }

  const addMenuToCart = () => {
    clearMenus()
    addMenu(currentStore.id, currentStore.nickname, newOrderItem)
    setShowNewOrderDialog(false)
    setNewOrderItem(null)
  }

  const goBackHandler = () => {
    if (location.state) {
      const location_ = {
        pathname: location.state.pathname,
        state: { step: 3, category: location.state.category },
      }
      history.replace(location_)
    } else {
      history.goBack()
    }
  }

  if (error) return <NoMatch />
  return (
    <div className={classes.root}>
      <StickyTopInfo
        isSticky={isStickyHeader}
        sections={sections}
        selectedSections={selectedSections}
        handleSectionSelect={handleSectionSelect}
        goBackHandler={goBackHandler}
        name={store?.nickname}
      />
      <TopInfo
        store={store}
        background={background}
        classes={classes}
        goBackHandler={goBackHandler}
        logo={logo}
        handleSectionSelect={handleSectionSelect}
        selectedCategories={selectedCategories}
        selectedSections={selectedSections}
        handleMoreShow={toggleMoreDialog}
        storeOpened={ocStatus}
        id={idProp}
        handleSigninModalOpen={handleSigninModalOpen}
        sections={sections}
      />
      <div className={classes.content}>
        <Content
          storeId={store?.id}
          isMenuNotEmpty={menu?.length > 0}
          onMenuItemShow={onMenuItemShow}
          onMenuItemHide={onMenuItemHide}
          handleMenuItemClick={handleMenuItemClick}
          cart={cart}
          menus={menu}
          ref={ref}
          sections={sections}
        />
      </div>
      <div className='sticky-bottom'>
        <Footer invisible={true} />
      </div>
      <RelatedDialogs
        showMoreDialog={showMoreDialog}
        handleMoreClose={toggleMoreDialog}
        store={store}
        showMenuDialog={showMenuDialog}
        selectedMenu={selectedMenu}
        closeMenuDialog={closeMenuDialog}
        showNewOrderDialog={showNewOrderDialog}
        newOrder={handleShowNewOrderDialog}
        idProp={idProp}
        closeNewOrderDialog={closeNewOrderDialog}
        addMenuToCart={addMenuToCart}
        storeOpened={ocStatus}
        showMobileSigninDialog={showMobileSigninDialog}
        handleSigninModalClose={handleSigninModalClose}
        handleSignin={handleSigninModalClose}
      />
    </div>
  )
}

MobileStore.propTypes = {
  // props: PropTypes,
}

export default withRouter(MobileStore)
