import halal from '../images/halal-white.svg'
import vegan from '../images/vegan-white.svg'
import vegetarian from '../images/vegetarian-white.svg'
import glutenFree from '../images/gluten-free-white.svg'
import kosher from '../images/kosher-white.svg'
import allergyFriendly from '../images/allergy-friendly-white.svg'

const getDietaryIcon = (name) => {
  let relatedIcon
  name === 'Halal' && (relatedIcon = halal)
  name === 'Vegan' && (relatedIcon = vegan)
  name === 'Vegetarian' && (relatedIcon = vegetarian)
  name === 'Gluten-free' && (relatedIcon = glutenFree)
  name === 'Gluten Free' && (relatedIcon = glutenFree)
  name === 'Kosher' && (relatedIcon = kosher)
  name === 'Allergy Friendly' && (relatedIcon = allergyFriendly)

  return relatedIcon
}

export { getDietaryIcon }
