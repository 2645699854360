import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { orderActions, searchActions, customerActions } from '../../../../actions'
import { injectIntl } from 'react-intl'
import clsx from 'clsx'
import { GreenButton, Transition, TFDialog } from '../../../utils/tools'
import close from '../../../../images/close.svg'
import selectedAsapIcon from '../../../../images/asap.svg'
import selectedScheduleIcon from '../../../../images/schedule.svg'
import asapIcon from '../../../../images/asap-not-selected.svg'
import scheduleIcon from '../../../../images/schedule-not-selected.svg'
import whereIcon from '../../../../images/where.svg'
import howIcon from '../../../../images/delivery.svg'
import Button from '@material-ui/core/Button'
import { deliveryTypes, deliveryTimes } from '../../../../constants'
import DeliveryTime from './delivery_time'
import moment from 'moment'
import DeliveryTypeFilter from './DeliveryTypeFilter'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  row: {
    borderBottom: '1px solid #f7f7f7',
    paddingTop: 2,
    paddingBottom: 2,
  },
  titleRow: {
    borderBottom: '1px solid #f7f7f7',
    paddingTop: 20,
    paddingBottom: 6,
  },
  icon: {
    width: 50,
    color: '#c4c4c4',
  },
  activeIcon: {
    width: 50,
    color: '#707070',
  },
  link: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.56,
    letterSpacing: 0.03,
    textAlign: 'left',
    color: '#c4c4c4',
    padding: 0,
    height: 30,
  },
  activeLink: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.56,
    letterSpacing: 0.03,
    textAlign: 'left',
    color: '#707070',
    padding: 0,
    height: 30,
  },
  noWrapText: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 5,
  },
  dialog: {
    borderRadius: 20,
    width: '100%',
    maxWidth: '100%',
  },
}))

const MobileDeliveryDetails = ({ intl, handleClose, resetItems, toggleAddressDialog }) => {
  const classes = useStyles()
  // Global states
  const searchQuery = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const addresses = useSelector(({ customer }) => customer.savedAddresses, shallowEqual)
  const signedIn = useSelector(({ customer }) => customer.signedIn)

  // Local states
  const [when, setWhen] = useState(searchQuery?.deliveryTime)
  const [whenDetail, setWhenDetail] = useState(searchQuery?.deliveryTimeDetail)
  const [where, setWhere] = useState(searchQuery?.address)
  const [how, setHow] = useState(searchQuery?.deliveryType)
  const [subType, setSubType] = useState(null)
  const [instructions, setInstructions] = useState(null)
  const [showDeliveryTypeDialog, setShowDeliveryTypeDialog] = useState(false)
  const [showTimeDialog, setShowTimeDialog] = useState(false)
  const [howDialogData, setHowDialogData] = useState(null)
  // Actions
  const dispatch = useDispatch()
  const setDeliveryTime = (time) => dispatch(searchActions.setDeliveryTime(time))
  const setDeliveryTimeDetail = (detail) => dispatch(searchActions.setDeliveryTimeDetail(detail))
  const setDeliveryType = (type) => dispatch(searchActions.setDeliveryType(type))
  const setDeliverySubType = (subTyp) => dispatch(orderActions.setDeliverySubTypes(subTyp))
  const setDeliveryInstructions = (instr) => dispatch(orderActions.setInstructions(instr))
  const setAddress = (address) => dispatch(searchActions.setAddress(address))
  const updateAddress = (id, address) => dispatch(customerActions.updateAddress(id, address))

  useEffect(() => {
    setWhere(searchQuery.address)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(searchQuery.address)])

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleDeliveryTypeChange = (deliveryTyp, subTyp, instr) => {
    setHow(deliveryTyp)
    setSubType(subTyp)
    setInstructions(instr)
    setHowDialogData({ deliveryTyp, subTyp, instr })
  }

  const handleDeliveryTimeChange = (deliveryTime) => {
    setWhen(deliveryTimes.SCHEDULED)
    setWhenDetail(deliveryTime)
  }

  const handleWhereDialog = () => {
    toggleAddressDialog()
    handleClose('deliveryDetails')
  }

  const toggleDeliveryTimeDialog = () => setShowTimeDialog((prev) => !prev)
  const toggleDeliveryTypeDialog = () => setShowDeliveryTypeDialog((prev) => !prev)

  const handleDeliveryTimeASAP = () => {
    setWhen(deliveryTimes.ASAP)
    setWhenDetail(moment())
  }

  const applyDeliveryDetailsChange = () => {
    setDeliveryTimeDetail(whenDetail)
    setDeliveryTime(when)
    setDeliveryType(how)
    setDeliverySubType(subType)
    setDeliveryInstructions(instructions)
    setAddress(where)
    handleDetailsClose()
    resetItems()
    if (signedIn) {
      let address_ = addresses.find((item) => item.formattedAddress === where.formattedAddress)
      let id = address_.id
      let addressToBeUpdated = where
      addressToBeUpdated.deliveryInstruction = instructions
      addressToBeUpdated.deliveryOption = subType
      updateAddress(id, addressToBeUpdated)
    }
  }

  const handleDetailsClose = () => handleClose('deliveryDetails')

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12} className='flex-container flex-end'>
        <IconButton onClick={handleDetailsClose} size='small'>
          <img src={close} height='22' width='22' alt='close' />
        </IconButton>
      </Grid>
      <Grid item xs={12} className='flex-container flex-center'>
        <Typography align='center' variant='h3'>
          {getLabel('delivery-details')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={clsx('flex-container flex-left', [classes.titleRow])}>
        <Typography variant='h6'>{getLabel('when-label')}</Typography>
      </Grid>
      <Grid item xs={12} className={clsx('centered-row', [classes.row])}>
        <div className={clsx('flex-container flex-left', [classes.icon])}>
          <img src={when === deliveryTimes.ASAP ? selectedAsapIcon : asapIcon} alt='asap' height='20' width='17' />
        </div>
        <Button
          className={clsx(classes.link, { [classes.activeLink]: when === deliveryTimes.ASAP })}
          onClick={handleDeliveryTimeASAP}
        >
          {getLabel('as-soon-as')}
        </Button>
      </Grid>
      <Grid item xs={12} className={clsx('centered-row', [classes.row])}>
        <div className={clsx('flex-container flex-left', [classes.icon])}>
          <img
            src={when === deliveryTimes.SCHEDULED ? selectedScheduleIcon : scheduleIcon}
            alt='schedule'
            height='20'
            width='20'
          />
        </div>
        <Button
          className={clsx(classes.link, {
            [classes.activeLink]: when === deliveryTimes.SCHEDULED,
          })}
          onClick={toggleDeliveryTimeDialog}
        >
          {when === deliveryTimes.SCHEDULED ? moment(whenDetail).format('LLLL') : getLabel('schedule-order')}
        </Button>
        <TFDialog
          fullWidth
          maxWidth={false}
          className={classes.dialog}
          open={showTimeDialog}
          onClose={toggleDeliveryTimeDialog}
          TransitionComponent={Transition}
          keepMounted
        >
          <DeliveryTime handleClose={toggleDeliveryTimeDialog} handleValueChange={handleDeliveryTimeChange} />
        </TFDialog>
      </Grid>

      <Grid item xs={12} className={clsx('flex-container flex-left', [classes.titleRow])}>
        <Typography variant='h6'>{getLabel('where-label')}</Typography>
      </Grid>
      <Grid item xs={12} className={clsx('centered-row', [classes.row])}>
        <div className={clsx('flex-container flex-left', [classes.icon])}>
          <img src={whereIcon} alt='where' height='20' width='20' />
        </div>
        <Button
          className={classes.activeLink}
          classes={{
            label: classes.noWrapText,
          }}
          onClick={handleWhereDialog}
        >
          {where ? where.formattedAddress : getLabel('set-address')}
        </Button>
      </Grid>
      <Grid item xs={12} className={clsx('flex-container flex-left', [classes.titleRow])}>
        <Typography variant='h6'>{getLabel('how-label')}</Typography>
      </Grid>
      <Grid item xs={12} className={clsx('centered-row', [classes.row])}>
        <div className={clsx('flex-container flex-left', [classes.icon])}>
          <img src={howIcon} alt='how' height='20' width='20' />
        </div>
        <Button className={classes.activeLink} onClick={toggleDeliveryTypeDialog}>
          {getLabel(how === deliveryTypes.DELIVERY ? 'delivery-label' : 'pick-up')}
        </Button>
        <TFDialog
          fullWidth
          maxWidth={false}
          className={classes.dialog}
          open={showDeliveryTypeDialog}
          onClose={toggleDeliveryTypeDialog}
          TransitionComponent={Transition}
          // keepMounted
        >
          <DeliveryTypeFilter
            handleClose={toggleDeliveryTypeDialog}
            handleValueChange={handleDeliveryTypeChange}
            howDialogData={howDialogData}
          />
        </TFDialog>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 20 }}>
        <GreenButton fullWidth onClick={applyDeliveryDetailsChange}>
          {getLabel('done-label')}
        </GreenButton>
      </Grid>
    </Grid>
  )
}

MobileDeliveryDetails.propTypes = {
  intl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  resetItems: PropTypes.func,
}

export default injectIntl(MobileDeliveryDetails)
