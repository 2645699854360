import { restaurantConstants, generalConstants } from '../constants'
import { restaurantService, storeService } from '../services'
import { alertActions } from './alert.actions'

export const restaurantActions = {
  createRestaurant,
  getAll,
  getRestStores,
  saveLogo,
  deleteLogo,
  saveBackground,
  deleteBackground,
  clearState,
  clearBackground,
  updateRestaurant,
}

function getRestStores(page, size) {
  return (dispatch) => {
    dispatch(request())

    return storeService.getStores(page, size).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.response.data.error_message.toString()))
      }
    )
  }

  function request() {
    return { type: restaurantConstants.GET_REST_STORES_REQUEST, payload: null }
  }
  function success(stores) {
    return { type: restaurantConstants.GET_REST_STORES_SUCCESS, stores }
  }
  function failure(error) {
    return { type: restaurantConstants.GET_REST_STORES_FAILURE, error }
  }
}

function createRestaurant(name, desc) {
  return (dispatch) => {
    dispatch(request({ name }))

    restaurantService.create(name, desc).then(
      (restaurant) => {
        dispatch(success(restaurant))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(name) {
    return { type: restaurantConstants.CREATE_REQUEST, name }
  }
  function success(restaurant) {
    return { type: restaurantConstants.CREATE_SUCCESS, restaurant }
  }
  function failure(error) {
    return { type: restaurantConstants.CREATE_FAILURE, error }
  }
}

function updateRestaurant(data, id) {
  return (dispatch) => {
    dispatch(request())

    restaurantService.updateRestaurant(data, id).then(
      (restaurant) => dispatch(success(restaurant)),
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request() {
    return { type: restaurantConstants.UPDATE_REST_REQUEST, payload: null }
  }
  function success(restaurant) {
    return { type: restaurantConstants.UPDATE_REST_SUCCESS, restaurant }
  }
  function failure(error) {
    return { type: restaurantConstants.UPDATE_REST_FAILURE, error }
  }
}

function getAll(page, size) {
  return (dispatch) => {
    restaurantService.getAll(page, size).then(
      (restaurants) => {
        dispatch(success(restaurants))
        getRestStores()
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function success(restaurants) {
    return { type: restaurantConstants.GET_SUCCESS, restaurants }
  }
  function failure(error) {
    return { type: restaurantConstants.GET_FAILURE, error }
  }
}

function saveLogo(restaurantId, logo, imageType) {
  return (dispatch) => {
    dispatch(request({ restaurantId }))

    restaurantService.saveLogo(restaurantId, logo, imageType).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(id) {
    return { type: restaurantConstants.SAVE_LOGO_REQUEST, id }
  }
  function success(logo) {
    return { type: restaurantConstants.SAVE_LOGO_SUCCESS, logo }
  }
  function failure(error) {
    return { type: restaurantConstants.SAVE_LOGO_FAILURE, error }
  }
}

function deleteLogo(restaurantId) {
  return (dispatch) => {
    dispatch(request({ restaurantId }))

    restaurantService.deleteLogo(restaurantId).then(
      (res) => {
        if (res === generalConstants.STATUS_OK) dispatch(success(restaurantId))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(restaurantId) {
    return { type: restaurantConstants.DELETE_LOGO_REQUEST, restaurantId }
  }
  function success(restaurantId) {
    return { type: restaurantConstants.DELETE_LOGO_SUCCESS, restaurantId }
  }
  function failure(error) {
    return { type: restaurantConstants.DELETE_LOGO_FAILURE, error }
  }
}

function saveBackground(restaurantId, background, imageType) {
  return (dispatch) => {
    dispatch(request({ restaurantId }))

    restaurantService.saveBackground(restaurantId, background, imageType).then(
      (res) => {
        dispatch(success(res))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(id) {
    return { type: restaurantConstants.SAVE_BACKGROUND_REQUEST, id }
  }
  function success(background) {
    return { type: restaurantConstants.SAVE_BACKGROUND_SUCCESS, background }
  }
  function failure(error) {
    return { type: restaurantConstants.SAVE_BACKGROUND_FAILURE, error }
  }
}

function clearBackground() {
  return { type: restaurantConstants.CLEAR_BACKGROUND }
}

function deleteBackground(restaurantId) {
  return (dispatch) => {
    dispatch(request({ restaurantId }))

    restaurantService.deleteBackground(restaurantId).then(
      (res) => {
        if (res === generalConstants.STATUS_OK) dispatch(success(restaurantId))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request(restaurantId) {
    return {
      type: restaurantConstants.DELETE_BACKGROUND_REQUEST,
      restaurantId,
    }
  }
  function success(restaurantId) {
    return {
      type: restaurantConstants.DELETE_BACKGROUND_SUCCESS,
      restaurantId,
    }
  }
  function failure(error) {
    return { type: restaurantConstants.DELETE_BACKGROUND_FAILURE, error }
  }
}

function clearState() {
  return {
    type: restaurantConstants.CLEAR_STATE,
  }
}
