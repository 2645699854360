const attributesList = (menu) => {
  let attributes = []
  menu.attributes.forEach((m) => {
    m.attributeNames.forEach((attr) => {
      attributes.push(attr)
    })
  })

  return attributes
}

export { attributesList }
