import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core'
import { employeeRoles, roles } from '../../../../constants'
import { useSelector } from 'react-redux'
import { GreenCheckbox } from '../../../utils/tools'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#121212',
    margin: theme.spacing(),
  },
  checks: {
    maxWidth: 600,
  },
}))

const AssignRole = ({ intl, user, handleRoleChange }) => {
  const classes = useStyles()

  // const {
  //   values: { isRestaurantAdmin, isStoreAdmin },
  // } = useMerchantHomeContext()

  const selectedStoreRole = useSelector(({ merchantStores }) => merchantStores.selectedStore.roleName)
  const storeEmployees = useSelector(({ merchantStores }) => merchantStores.selectedStoreEmployees)

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid container spacing={2} className='full-width'>
      <Grid item sm={12}>
        <Typography className={classes.label}>{getLabel('role-label')}</Typography>
      </Grid>
      <Grid item sm={12}>
        <div className={classes.checks}>
          <Grid container>
            {employeeRoles.map((role) => {
              //this condition assures that store admin can only add store user and rest-admin can add both
              if (role.whoCanAdd.includes(selectedStoreRole)) {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={role.name}>
                    <FormControlLabel
                      // if store has an admin, rest-admin cannot add a new one without deleting the existing one
                      disabled={
                        role.label === roles.STORE_ADMIN && storeEmployees.some((i) => i.roleName === roles.STORE_ADMIN)
                      }
                      control={
                        <GreenCheckbox
                          checked={user.role === role.label}
                          onChange={handleRoleChange}
                          name={role.label.toString()}
                        />
                      }
                      label={role.name}
                    />
                  </Grid>
                )
              }
              return null //This line is added to remove console warning
            })}
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

AssignRole.propTypes = {
  user: PropTypes.object.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
}

export default injectIntl(AssignRole)
