import React from 'react'
import { Grid } from '@material-ui/core'
import OneSelection from './OneSelect'
import MultiSelection from './MultiSelect'

const Attributes = ({ attribute, value, handleAttributes, menuAttributes, checked }) => {
  return (
    <Grid item container xs={12} direction='row'>
      {attribute.maximumCount === 1 ? (
        <OneSelection
          value={value}
          attribute={attribute}
          handleAttributes={handleAttributes}
          menuAttributes={menuAttributes}
        />
      ) : (
        <MultiSelection
          value={value}
          attribute={attribute}
          handleAttributes={handleAttributes}
          menuAttributes={menuAttributes}
          checked={checked}
        />
      )}
    </Grid>
  )
}

export default Attributes
