import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import car from '../../../../../../images/delivery-car.svg'
import { FormattedMessage } from 'react-intl'
import { getRelatedMessage } from '../../../../../../helpers/orders/delivery-details'
import { orderStatuses } from '../../../../../../constants'

const DeliveryDetails = ({ order, web }) => {
  const id = getRelatedMessage(order)
  const isDelivered = order?.status?.name === orderStatuses.DELIVERED

  const containerStyle = { display: 'flex', alignItems: 'center', justifyContent: web ? 'flex-end' : null }
  return (
    <>
      {isDelivered && (
        <Grid item xs={isDelivered ? (web ? 4 : 2) : 0} style={{ ...containerStyle, minWidth: 20 }}>
          <img src={car} alt='' width='20' height='20' />
        </Grid>
      )}
      <Grid item xs={isDelivered ? (web ? 8 : 10) : 12} style={containerStyle}>
        <Typography variant='body1' style={{ color: '#000' }}>
          <FormattedMessage id={id} />
        </Typography>
      </Grid>
    </>
  )
}

export default React.memo(DeliveryDetails)
