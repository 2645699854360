import React from 'react'
import PropTypes from 'prop-types'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { GreenRadio } from '../../../../../utils/tools'

import { valueConstants, emailConstants, notificationConstants } from '../../Helpers'

import Textfield from '../../Fields/TextField_'

const EmailArea = ({ classes, email, emailSelection, values, error, handleChange }) => {
  return (
    <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
      <FormControl fullWidth>
        {emailConstants.map((item, index) => (
          <FormControlLabel
            key={index}
            className={classes.label}
            control={<GreenRadio checked={email === item} onChange={emailSelection} value={item} />}
            label={<Typography>{item}</Typography>}
          />
        ))}
      </FormControl>
      <Textfield
        values={values}
        prop={valueConstants.orderConfirmationEmail}
        error={error}
        handleChange={handleChange}
        gridVal={12}
        required
        disabled={email === notificationConstants.storeEmail}
      />
    </Grid>
  )
}

EmailArea.propTypes = {
  values: PropTypes.object,
  classes: PropTypes.object,
  email: PropTypes.string,
  emailSelection: PropTypes.func,
  error: PropTypes.bool,
  handleChange: PropTypes.func,
}

export default EmailArea
