/* eslint-disable no-unused-expressions */
import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { searchActions } from '../../../../actions'
import PropTypes from 'prop-types'
import close from '../../../../images/close.svg'
import { GreenButton } from '../../../utils/tools'
import Picker from 'rmc-picker/es/Picker'
import MultiPicker from 'rmc-picker/es/MultiPicker'
import 'rmc-picker/assets/index.css'
import { deliveryTimeIntervals, deliveryTimes } from '../../../../constants'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  row: {
    borderBottom: '1px solid #f7f7f7',
    paddingTop: 2,
    paddingBottom: 2,
  },
  titleRow: {
    borderBottom: '1px solid #f7f7f7',
    paddingTop: 20,
    paddingBottom: 6,
  },
  icon: {
    width: 50,
    color: '#c4c4c4',
  },
  activeIcon: {
    width: 50,
    color: '#707070',
  },
  link: {
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.56,
    letterSpacing: 0.03,
    textAlign: 'left',
    color: '#c4c4c4',
    padding: 0,
    height: 30,
  },
  activeLink: {
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.56,
    letterSpacing: 0.03,
    textAlign: 'left',
    color: '#707070',
    padding: 0,
    height: 30,
  },
})

class DeliveryTime extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      detail: ['', ''],
    }
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  onChange = (val) => {
    this.setState({ detail: val })
  }

  applyDeliveryTimeChange = () => {
    let date = moment(this.state.detail[0])
    let time = moment(this.state.detail[1], 'HH:mm')

    let deliveryTime = date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
    })

    if (this.props.handleValueChange) {
      this.props.handleValueChange(deliveryTime)
    } else {
      this.props.setDeliveryTimeDetail(deliveryTime)
      this.props.setDeliveryTime(deliveryTimes.SCHEDULED)
    }

    this.props.handleClose()
  }

  render() {
    const { classes, handleClose } = this.props
    const { detail } = this.state

    const days = Array.from({ length: 30 }, (v, i) => {
      return moment().add(i, 'days')
    })

    return (
      <Grid className={classes.root} container>
        <Grid item xs={12} className='flex-container flex-end'>
          <IconButton onClick={handleClose} size='small'>
            <img src={close} height='22' width='22' alt='close' />
          </IconButton>
        </Grid>
        <Grid item xs={12} className='flex-container flex-center'>
          <Typography align='center' variant='h3'>
            {this.getLabel('delivery-time')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MultiPicker selectedValue={detail} onValueChange={this.onChange}>
            <Picker indicatorClassName='my-picker-indicator'>
              {days.map((day, index) => (
                <Picker.Item key={index} className='my-picker-view-item' value={day.format('YYYY-MM-DD')}>
                  {day.format('ddd, MMM Do')}
                </Picker.Item>
              ))}
            </Picker>
            <Picker indicatorClassName='my-picker-indicator'>
              {deliveryTimeIntervals.map((interval) => (
                <Picker.Item key={interval.value} className='my-picker-view-item' value={interval.value}>
                  {interval.label}
                </Picker.Item>
              ))}
            </Picker>
          </MultiPicker>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 20 }}>
          <GreenButton fullWidth onClick={this.applyDeliveryTimeChange}>
            {this.getLabel('set-delivery-time')}
          </GreenButton>
        </Grid>
      </Grid>
    )
  }
}

DeliveryTime.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func,
}

const mapStateToProps = (state) => {
  const { search } = state

  return {
    searchQuery: search.searchQuery,
  }
}

const actionCreators = {
  setDeliveryTime: searchActions.setDeliveryTime,
  setDeliveryTimeDetail: searchActions.setDeliveryTimeDetail,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(DeliveryTime))))
