import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Typography from '@material-ui/core/Typography'

import { parseGoogleAPIAddress } from '../../../utils/common'

import { bankinfoFocusedState } from '../../../../constants/bank-info/states'

import RadioArea from './BankInfo/RadioArea'

import { valueConstants } from '../../../businessRegister/businessConstants'

import DirectDeposit from './BankInfo/DirectDeposit'
import Address from '../common/Address'

import { bankInfoArr } from '../../../../constants'

const BankInfo = ({ classes, editting, setBankInfo, bankInfo, bankInfoInvalid, setBankInfoInvalid }) => {
  const { formatMessage: f } = useIntl()

  const [isFocused, setIsFocused] = useState(bankinfoFocusedState)

  const setAptSuite = (val) => setBankInfo({ ...bankInfo, checkSuiteNo: val })

  const onChangeHandler = (e) => {
    const { name } = e.target
    const param = name || 'taxIdNumber'
    setIsFocused({ ...isFocused, [param]: true })
    setBankInfoInvalid({ ...bankInfoInvalid, [param]: !e.target.value })
    setBankInfo({ ...bankInfo, [param]: e.target.value })
  }

  const handleCheckAddressChange = (address) => {
    let parsedAddress = parseGoogleAPIAddress(address)
    if (parsedAddress) setBankInfo({ ...bankInfo, address: parsedAddress })
  }

  const handlePaymentMethod = (e) => {
    setBankInfo({ ...bankInfo, paymentMethod: e.target.value })
    setBankInfoInvalid({ ...bankInfoInvalid, paymentMethod: !e.target.value })
  }

  return (
    <>
      <div className={classes.header}>
        <Typography variant='h5'>{f({ id: 'bankinfo-label' })}</Typography>
      </div>
      <RadioArea editting={editting} handlePaymentMethod={handlePaymentMethod} bankInfo={bankInfo} />
      {bankInfo?.paymentMethod === valueConstants.directDeposit && (
        <DirectDeposit
          bankInfo={bankInfo}
          isFocused={isFocused}
          isInvalid={bankInfoInvalid}
          onChangeHandler={onChangeHandler}
          editting={editting}
        />
      )}
      {bankInfo?.paymentMethod === valueConstants.check && (
        <Address
          storeInfo={bankInfo}
          aptSuite={bankInfo.checkSuiteNo || ''}
          setAptSuite={setAptSuite}
          addressHandler={handleCheckAddressChange}
          editting={editting}
          checkAddress
        />
      )}
      {!bankInfoArr.includes(bankInfo?.paymentMethod) && !editting && (
        <div className={classes.bankInfoText}>
          <Typography variant='h5' color='secondary'>
            {f({ id: 'select-payment-transfer-method' })}
          </Typography>
        </div>
      )}
    </>
  )
}

BankInfo.propTypes = {
  classes: PropTypes.object,
  editting: PropTypes.bool,
  setBankInfo: PropTypes.func,
  bankInfo: PropTypes.object,
  bankInfoInvalid: PropTypes.object,
  setBankInfoInvalid: PropTypes.func,
}

export default BankInfo
