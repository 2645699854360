import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { store } from './helpers'
import { App } from './App/App'
import 'babel-polyfill'

import './index.css'
import 'typeface-roboto'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
