import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import RowContainer from './RowContainer'
import useStyles from './styles'
import clsx from 'clsx'
import { QuantityBox } from '../../../../../common/QuantityBox'
import { isEmpty } from 'lodash'
import AttributeDetails from '../../../../../common/AttributeDetails'

const OrderDetails = ({ order }) => {
  const classes = useStyles()
  return (
    <RowContainer>
      <div className='column' style={{ width: '100%' }}>
        {order?.orderDetails.map((item, ind) => (
          <div key={ind}>
            <Grid item xs={12} key={ind} className={clsx('centered-row', classes.orderDetailRow)}>
              <Grid item xs={1}>
                <QuantityBox>{item?.quantity}</QuantityBox>
              </Grid>
              <Grid item xs={11} className={classes.orderDetailText}>
                {item?.orderMenu?.name}
              </Grid>
            </Grid>
            {!isEmpty(item?.attributes) && <AttributeDetails i={ind} orderDetail={item} mobile />}
          </div>
        ))}
      </div>
    </RowContainer>
  )
}

OrderDetails.propTypes = {
  order: PropTypes.object,
}

export default React.memo(OrderDetails)
