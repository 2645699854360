import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'

import { storeActions } from '../../../../actions'
import { TFSlider } from '../../../utils/tools'
import { getSliderData } from '../../../../helpers/sliderFilters/getSliderData'

import { Typography, FormLabel, FormControl, FormGroup, Divider } from '@material-ui/core'

const SliderFilters = ({ classes, name }) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)

  const setPricey = (value) => dispatch(storeActions.setPriceyFilter(value))
  const setRating = (value) => dispatch(storeActions.setRatingFilter(value))
  const setDistance = (value) => dispatch(storeActions.setDistanceFilter(value))

  const [sliderData, set] = useState({
    label: null,
    value: null,
    marks: null,
    step: null,
    min: null,
    max: null,
  })

  useEffect(() => {
    set(getSliderData(name, searchData, sliderData))
  }, [searchData, name])

  const sliderChangeHandler = (event, newValue) => {
    if (sliderData?.value === newValue) return
    if (name === 'price') {
      return setPricey(newValue)
    } else if (name === 'rating') {
      return setRating(+newValue * -1)
    } else {
      return setDistance(newValue)
    }
  }

  return (
    <>
      <FormControl component='fieldset' className={classes.formControl}>
        <FormLabel component='legend' className='row'>
          <Typography variant='subtitle1' className={classes.groupTitle}>
            {f({ id: sliderData.label ? sliderData.label : 'id-label' })}
          </Typography>
        </FormLabel>
        <FormGroup>
          <TFSlider
            value={sliderData?.value}
            aria-labelledby='discrete-slider'
            onChange={sliderChangeHandler}
            style={{ width: '100%' }}
            step={sliderData?.step}
            min={sliderData?.min}
            max={sliderData?.max}
            marks={sliderData?.marks}
          />
        </FormGroup>
      </FormControl>
      <Divider className={classes.divider} style={{ marginTop: name === 'rating' && 20 }} />
    </>
  )
}

export default SliderFilters
