import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { deliveryTypes } from '../../../../../constants'
import addInstructionIcon from '../../../../../images/add-instruction.svg'
import addInstructionIconSelected from '../../../../../images/add-instruction-selected.svg'

const useStyles = makeStyles((theme) => ({
  stepTwoRow: {
    borderBottom: '1px solid #f7f7f7',
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  stepTwoSubRow: {
    borderBottom: '1px solid #f7f7f7',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  stepTwoIcon: {
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
    marginRight: theme.spacing(2),
  },
  button: {
    fontSize: 15,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(198, 198, 198, 0.87)',
    textTransform: 'capitalize',
  },
  selectedButton: {
    color: 'rgba(112, 112, 112, 0.87)',
  },
  addInstruction: {
    padding: theme.spacing(),
    fontSize: 15,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(198, 198, 198, 0.87)',
    textTransform: 'none',
  },
  selectedInstruction: {
    color: 'rgba(112, 112, 112, 0.87)',
  },
  field: {
    padding: theme.spacing(),
  },
}))

const Instruction = ({ intl, details, setDetails }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item xs={12} className={classes.stepTwoSubRow}>
      <img
        className={classes.stepTwoIcon}
        src={details.instruction ? addInstructionIconSelected : addInstructionIcon}
        alt='where'
        height='20'
        width='20'
      />
      <div className='column full-width'>
        <Typography
          className={clsx({
            [classes.addInstruction]: true,
            [classes.selectedInstruction]: details.deliveryType === deliveryTypes.DELIVERY,
          })}
          gutterBottom
        >
          {getLabel('add-instructions')}
        </Typography>
        <TextField
          id='instructions'
          value={details.instruction}
          disabled={details.deliveryType !== deliveryTypes.DELIVERY}
          onChange={(e) => setDetails({ ...details, instruction: e.target.value })}
          fullWidth
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.field },
          }}
          placeholder={getLabel('add-instruction-placeholder')}
          multiline
        />
      </div>
    </Grid>
  )
}

Instruction.propTypes = {
  details: PropTypes.object.isRequired,
  setDetails: PropTypes.func.isRequired,
}

export default injectIntl(Instruction)
