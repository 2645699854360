const convertOrderMenus = (orderMenus) => {
  let newOrderMenus = []
  orderMenus.forEach((menu) => {
    let newObject = {
      menuId: menu.menuId,
      quantity: menu.quantity,
      specialInstructions: menu.specialInstructions,
    }
    newObject.attributes = []
    menu.attributes.forEach((attr) => {
      let newAttrObj = {
        attributeId: attr.attributeId,
        attributeValueIds: attr.attributeValueIds,
      }
      newObject.attributes.push(newAttrObj)
    })
    newOrderMenus.push(newObject)
  })
  return newOrderMenus
}

export { convertOrderMenus }
