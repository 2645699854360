import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import MenuItem from '../menu_item'

const Sections = ({
  refs,
  section,
  windowHeight,
  classes,
  setSelectedSection,
  store,
  handleMenuItemClick,
  cart,
  selectedSection,
}) => {
  const observerMargin = windowHeight - 150.00001
  useEffect(() => {
    const observerConfig = {
      rootMargin: `-150px 0px -${observerMargin}px 0px`,
    }

    const handleIntersection = function (entries) {
      entries.forEach((entry) => {
        if (entry.target.id !== selectedSection && entry.isIntersecting) {
          setSelectedSection(entry.target.id)
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, observerConfig)

    observer.observe(refs[section.name].current)
    return () => observer.disconnect()
  }, [setSelectedSection, observerMargin, refs, section])

  const activeMenuCount = section?.menus?.filter((m) => m.active).length

  return (
    <div id={section.name} ref={refs[section.name]}>
      <Grid item xs={12}>
        {section.active && activeMenuCount !== 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle}>{section?.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.sectionDesc}>{section?.description}</Typography>
            </Grid>
            {section?.menus &&
              section?.menus
                .filter((m) => m.active)
                .map((menu) => {
                  return (
                    <Grid item md={4} sm={6} xs={12} key={menu.id}>
                      <MenuItem
                        store={store}
                        data={menu}
                        handleClick={() => handleMenuItemClick(menu)}
                        alreadyOrderedCount={cart.orderMenus
                          .filter((o) => o.menuId === menu.id)
                          .reduce((total, mo) => total + mo.quantity, 0)}
                      />
                    </Grid>
                  )
                })}
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default Sections
