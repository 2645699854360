import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import howAdornment from '../../../../images/how_adornment.svg'
import arrowDown from '../../../../images/arrow-down.svg'

import { deliveryTypes } from '../../../../constants'

const howInputCommon = {
  maxWidth: 143.5,
  fontFamily: 'Roboto',
  textTransform: 'capitalize',
  fontSize: 16,
  fontWeight: 'bold',
  color: '#707070',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}

const useStyles = makeStyles(() => ({
  howInput: {
    ...howInputCommon,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  howInputNavbar: {
    ...howInputCommon,
    fontStretch: 'normal',
    lineHeight: 1.31,
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
}))

const ArrowDown = () => (
  <img src={arrowDown} width={9.4} height={6.1} style={{ marginRight: 8.5, marginLeft: 8.5 }} alt='' />
)

const HowButton = ({ openHowPopover, searchQuery, navbar }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  let buttonText =
    searchQuery.deliveryType === deliveryTypes.TAKEOUT
      ? f({ id: 'pickup-label' })
      : searchQuery.deliveryType.toLowerCase()
  return (
    <Button
      className={navbar ? classes.howInputNavbar : classes.howInput}
      disableRipple
      onClick={openHowPopover}
      startIcon={
        <img
          src={howAdornment}
          alt='how'
          height={navbar ? 20 : 25}
          width={navbar ? 23 : 25}
          style={{ marginLeft: 8.5 }}
        />
      }
      endIcon={<ArrowDown />}
    >
      {buttonText}
    </Button>
  )
}

HowButton.propTypes = {
  openHowPopover: PropTypes.func,
  searchQuery: PropTypes.object,
  navbar: PropTypes.bool,
}

export default HowButton
