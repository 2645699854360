import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Geocode from 'react-geocode'
import { useSelector, useDispatch } from 'react-redux'

import NewInitialStep from './set-address/NewInitialStep'
import SecondStep from './set-address/SecondStep'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import { customerActions, orderActions, searchActions } from '../../../actions'

import backIcon from '../../../images/black-back.svg'

import { parseGoogleAPIAddress } from '../../utils/common'
import { deliverySubTypes, deliveryTypes, initialDetails } from '../../../constants'
import { handleAddressName } from '../../../helpers/address-header'
import { checkIfAddressSavedBefore } from '../../../helpers'
import { isSavedBefore } from '../../../helpers/address/checkIfAddressSavedBefore'
import { customerService } from '../../../services'

const height = window.innerHeight

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: height,
    maxHeight: height,
    overflow: 'hidden',
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  title: {
    color: theme.palette.primary.main,
    fontSize:18,
  },
}))

export const SetAddress = ({ intl, handleBack, goToScreen, resetItems }) => {
  const classes = useStyles()
  //Global States
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const addresses = useSelector(({ customer }) => customer.savedAddresses)

  //Local States
  const [step, setStep] = useState(0)
  const [currentLocation, setCurrentLocation] = useState(null)
  const [address, setAddress] = useState(null)
  const [details, setDetails] = useState(initialDetails)
  const [showCurrentLocation, setShowCurrentLocation] = useState(true)
  const [initialRender, setInitialRender] = useState(true)
  const [defaultAddressName, setDefaultAddressName] = useState('')

  //Actions
  const dispatch = useDispatch()
  const setUserAddress = (address) => dispatch(searchActions.setAddress(address))
  const setUserDeliveryType = (type) => dispatch(searchActions.setDeliveryType(type))
  const setUserDeliverySubTypes = (type) => dispatch(orderActions.setDeliverySubTypes(type))
  const setUserInstructions = (inst) => dispatch(orderActions.setInstructions(inst))
  const getAddresses = () => dispatch(customerActions.getAllSavedAddresses())

  useEffect(() => {
    initialRender && getGeoLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getGeoLocation = () => {
    //This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.
    navigator.geolocation.getCurrentPosition((position) => {
      if (position.coords) {
        Geocode.setApiKey(GOOGLE_API_KEY)
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          (response) => {
            let country = response.results[0].address_components.find((i) => i.types.includes('country')).short_name
            if (country === 'US') {
              const addressComponent = response.results[0]
              setCurrentLocation(addressComponent)
            }
          },
          (error) => {
            console.error(error)
          }
        )
      }
    })
    setInitialRender(false)
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handlePickup = () => {
    setDetails({ ...details, deliveryType: deliveryTypes.TAKEOUT, deliverySubType: '', instruction: '' })
  }

  const handleDelivery = () => {
    setDetails({ ...details, deliveryType: deliveryTypes.DELIVERY, deliverySubType: deliverySubTypes['DELIVER_DOOR'] })
  }

  const handleBackButton = () => {
    if (step === 0) {
      handleBack()
    } else if (step === 1) {
      setStep(0)
      setShowCurrentLocation(true)
    }
  }

  const selectAddressAsCurrent = (address) => {
    setAddress(address)
    setDetails({
      ...details,
      appsuite: address.addressLine2,
      instruction: address.deliveryInstruction || '',
      deliverySubType: address.deliveryOption,
      deliveryType: address.deliveryOption ? deliveryTypes.DELIVERY : '',
    })
    setStep(1)
  }

  const saveAddress = () => {
    let address_ = address
    let name = details.addressName
    address_.name = name && name.length ? name : defaultAddressName
    address_.addressLine2 = details.appSuite
    address_.deliveryOption = details.deliverySubType
    address_.deliveryInstruction = details.instruction
    if (signedIn) {
      let [savedBefore, savedAddress] = isSavedBefore(addresses, address_.formattedAddress)
      if (savedBefore) {
        customerService
          .updateAddress(savedAddress.id, address_)
          .then(() => getAddresses())
          .catch((err) => console.log(err))
      } else {
        customerService
          .createAddress(address_)
          .then(() => getAddresses())
          .catch((err) => console.log(err))
      }
    }
    setUserAddress(address_)
    setUserDeliveryType(details.deliveryType)
    setUserDeliverySubTypes(details.deliverySubType)
    setUserInstructions(details.instruction)
    resetItems && resetItems()
    setStep(0)
    goToScreen ? goToScreen() : handleBack()
  }

  const hideCurrentAddress = () => {
    setShowCurrentLocation(false)
  }
  const showCurrentAddress = () => {
    setShowCurrentLocation(true)
  }

  const handleDeliveryAddress = (newAddress) => {
    let parsedAddress = parseGoogleAPIAddress(newAddress)
    parsedAddress.name = handleAddressName(addresses)
    setAddress(parsedAddress)
    setStep(1)
  }

  let savedBefore = checkIfAddressSavedBefore(addresses, address)

  return (
    <div className={classes.root}>
      <div className='column grow'>
        <div className='mobile-top-bar' style={{ borderBottom: '1px solid #f4f4f4' }}>
          {!goToScreen && (
            <IconButton size='small' className={classes.back} onClick={handleBackButton} aria-label='close'>
              <img src={backIcon} alt='Back' />
            </IconButton>
          )}
          <Typography variant='subtitle1' className={classes.title}>
            {getLabel('add-address')}
          </Typography>
        </div>
        <div className='column grow'>
          {step === 0 && (
            <NewInitialStep
              handleDeliveryAddress={handleDeliveryAddress}
              hideCurrentAddress={hideCurrentAddress}
              showCurrentAddress={showCurrentAddress}
              currentLocation={currentLocation}
              showCurrentLocation={showCurrentLocation}
              selectAddressAsCurrent={selectAddressAsCurrent}
              setStep={setStep}
              handleClose={handleBack}
              signedIn={signedIn}
            />
          )}
          {step === 1 && (
            <SecondStep
              address={address}
              details={details}
              setDetails={setDetails}
              callbacks={{ handlePickup: handlePickup, handleDelivery: handleDelivery }}
              saveAddress={saveAddress}
              savedBefore={savedBefore}
              signedIn={signedIn}
              addresses={addresses}
              setDefaultAddressName={setDefaultAddressName}
            />
          )}
        </div>
      </div>
    </div>
  )
}

SetAddress.propTypes = {
  handleBack: PropTypes.func,
  goToScreen: PropTypes.func,
  resetItems: PropTypes.func,
}

export default injectIntl(SetAddress)
