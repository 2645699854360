import React from 'react'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { deliveryTypes } from '../../../../constants'
import Section from './Content/Section'
import Row from './Content/Row'
import Info from './Content/Info'
import Text from './Content/Text'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    height: 25,
    backgroundColor: '#f6f6f6',
    color: '#5e6472',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    padding: '2px 8px',
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: 12,
    "&[data-status='Completed']": {
      backgroundColor: '#C7F9CC',
      color: '#007f5f',
    },
  },
}))

const Content = ({ data }) => {
  const classes = useStyles()

  const { formatMessage: format } = useIntl()
  return (
    <div className={classes.root}>
      <Section first>
        <div data-status={data?.status.label} className={classes.content}>
          {data?.status.label}
        </div>
      </Section>
      <Section>
        <Row
          text={`${format({ id: 'customer-label' })}`}
          info={<Info main={`${data?.customerFirstName} ${data?.customerLastName}`} />}
        />
      </Section>
      {data && data.orderType === deliveryTypes.DELIVERY && (
        <Section>
          <Row text={`${format({ id: 'order-type' })}`} info={<Info main={data?.orderType} />} />
          <Row text={`${format({ id: 'delivery-date' })}`} info={<Info main={data.deliveryDate} />} />
          <Row
            text={`${format({ id: 'delivery-time' })}`}
            info={<Info main={moment(data.deliveryTime, 'HH:mm').subtract(1, 'hours').format('HH:mm')} />}
          />
        </Section>
      )}
      {data && data.orderType === deliveryTypes.TAKEOUT && (
        <Section>
          <Row text={`${format({ id: 'order-type' })}`} info={<Info main={data?.orderType} />} />
          <Row text={`${format({ id: 'pickup-date' })}`} info={<Info main={data.deliveryDate} />} />
          <Row text={`${format({ id: 'pickup-time' })}`} info={<Info main={data.deliveryTime} />} />
        </Section>
      )}

      <Section>
        {data &&
          data.orderDetails.map((order, index) => (
            <div key={index}>
              <Row
                key={index}
                text={<Text col1={`${order.quantity}x`} col2={order.orderMenu.name} />}
                info={<Info main={`$${order.price.toFixed(2)}`} />}
              />
              {order.orderMenu.specialInstructions && (
                <Row
                  key={index + order.orderMenu.specialInstructions}
                  info={<Text col2={order.orderMenu.specialInstructions} />}
                />
              )}
            </div>
          ))}
      </Section>
      <Section>
        <Row
          text={<Text col2={format({ id: 'subtotal-label' })} align={'right'} />}
          info={<Info main={`$${data?.subTotal.toFixed(2)}`} />}
        />
        <Row
          text={<Text col2={`${format({ id: 'tax-label' })} (%${data?.taxRate})`} align={'right'} />}
          info={<Info main={`$${data?.taxPrice.toFixed(2)}`} />}
        />
        <Row
          text={<Text col2={format({ id: 'tip-label' })} align={'right'} />}
          info={<Info main={`$${data?.staffTip.toFixed(2)}`} />}
        />
        <Row
          text={<Text col2={format({ id: 'discount-label' })} align={'right'} />}
          info={<Info main={`$${data.discount ? data.discount.toFixed(2) : 0}`} />}
        />
        <Row
          text={<Text col2={format({ id: 'total-payout' })} align={'right'} />}
          info={<Info main={`$${data?.totalPayout.toFixed(2)}`} />}
        />
        {data && data.orderType === deliveryTypes.DELIVERY && (
          <Row
            text={<Text col2={format({ id: 'delivery-fee' })} align={'right'} />}
            info={<Info main={`$${data?.deliveryFee?.toFixed(2)}`} />}
          />
        )}
        <Row
          text={<Text col2={format({ id: 'total-price' })} align={'right'} />}
          info={<Info main={`$${data?.totalPrice.toFixed(2)}`} />}
        />
      </Section>
      <Section last>
        <Row info={format({ id: 'associated-transactions' })} />
        {data?.orderPayments?.map(
          (orderPayment, index) =>
            orderPayment?.transactionId && (
              <Row
                key={index}
                text={`${orderPayment?.type.label} `}
                info={<Info main={`$${orderPayment?.amount.toFixed(2)}`} />}
              />
            )
        )}
      </Section>
    </div>
  )
}

export default Content
