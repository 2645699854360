import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { styles } from './styles'

const useStyles = makeStyles(styles)

const ViewCountries = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const Text = (props) => (
    <Typography paragraph className={classes.text} {...props}>
      {props.children}
    </Typography>
  )

  // const ListItem = (props) => (
  //   <li className={classes.text} {...props}>
  //     {props.children}
  //   </li>
  // )

  // const AddressText = (props) => (
  //   <Typography className={classes.addressText} {...props}>
  //     {props.children}
  //   </Typography>
  // )

  return (
    <>
      <Text title-text='true'>TookFresh: Your Premier Food Delivery Service in California</Text>
      <Text>
        TookFresh is your catering delivery platform, dedicated to bringing the best food right to your doorstep. With a
        commitment to excellence, we guarantee a tasteful experience that will leave you satisfied and craving more.
        Place your order today and embark on a culinary journey across all the flavors!
      </Text>
      <Text>
        TookFresh is committed to providing a seamless and convenient ordering experience. Our user-friendly app ensures
        effortless browsing and secure transactions. Sit back, relax, and let us handle the rest.
      </Text>
    </>
  )
}

export default ViewCountries
