import { deliveryTypes } from '../../constants'

const showWarnBox = (cart) => {
  if (cart?.distanceSuccess === false || cart?.senpexSuccess === false) {
    return 'visible'
  }
  return ''
}

const showMinHeadCountBox = (itemCount, store) => {
  if (store.minHeadCount && itemCount < store.minHeadCount) {
    return 'visible'
  }
  return ''
}

const checkDistanceForDelivery = (cart) => {
  if (cart.type === deliveryTypes.TAKEOUT) {
    return cart.distanceSuccess
  }
  return cart.distanceSuccess && cart.senpexSuccess
}

export { showWarnBox, showMinHeadCountBox, checkDistanceForDelivery }
