import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import { employeesHeader } from '../../../helpers/merchant/table-header'
import EmployeesTable from './EmployeesTable'
import { useDispatch, useSelector } from 'react-redux'
import { merchantStoreActions } from '../../../actions/merchant.store.actions'
import Confirmation from '../common/Confirmation'
import ActionButtons from '../common/ActionButtons'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginTop: 70,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const Employees = () => {
  const classes = useStyles()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()
  //Global states
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const employees = useSelector(({ merchantStores }) => merchantStores.selectedStoreEmployees)
  const loading = useSelector(({ merchantStores }) => merchantStores.loading)

  //Actions
  const dispatch = useDispatch()
  const getEmployees = async (id) => dispatch(merchantStoreActions.getEmployees(id))
  const deleteEmployee = async (id) => dispatch(merchantStoreActions.deleteEmployee(id))
  //Local states
  const [selected, setSelected] = useState()
  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    const init = async () => {
      selectedStore?.storeId && (await getEmployees(selectedStore?.storeId))
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectClick = (e, item) => {
    e.target.checked ? setSelected(item.id) : setSelected()
  }

  const handleConfirmClose = () => {
    setShowConfirm(false)
    setSelected(null)
  }

  const deleteStoreEmployee = async () => {
    await deleteEmployee(selected)
    setShowConfirm(false)
    setSelected()
  }

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <ActionButtons selected={selected} action={setShowConfirm} to='employees' text='employee' />
      <EmployeesTable
        headerData={employeesHeader}
        bodyData={employees || []}
        aria='employees'
        handleSelect={handleSelectClick}
        selected={selected}
      />
      <Confirmation
        open={showConfirm}
        close={handleConfirmClose}
        callback={deleteStoreEmployee}
        title='are-you-sure'
        text='about-delete-employee'
        rightButton='delete-label'
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

export default Employees
