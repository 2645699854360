import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { initialQuery } from '../../../../constants'
import { searchActions } from '../../../../actions'
import Fabs from './common/fabs'

const Pricey = () => {
  const dispatch = useDispatch()

  const resetPricey = () => dispatch(searchActions.removePriceySearchFilter())
  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)

  return (
    <>
      {searchData.pricey && searchData.pricey !== initialQuery.pricey && (
        <Fabs text={`Under ${'$'.repeat(searchData.pricey)}`} onClick={resetPricey} />
      )}
    </>
  )
}

export default Pricey
