import React, { Component } from 'react'
import { connect } from 'react-redux'
import { alertActions } from '../actions'
import { IntlProvider } from 'react-intl'
import messages from '../helpers/messages'
import Home from '../components/home'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import CssBaseline from '@material-ui/core/CssBaseline'

const theme = createTheme({
  palette: {
    primary: {
      main: '#69e781',
    },
    secondary: {
      main: '#ff3c5f',
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: [
      'Roboto',
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Montserrat',
    ].join(','),
    h3: {
      fontSize: 34,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.18,
      letterSpacing: 'normal',
      color: '#333333',
    },
    h5: {
      fontSize: 20,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.9,
      letterSpacing: 'normal',
      color: '#333333',
    },
    h6: {
      fontSize: 16,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.31,
      letterSpacing: 'normal',
      color: '#333333',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.19,
      letterSpacing: 'normal',
      color: '#707070',
    },
    body1: {
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.36,
      letterSpacing: 'normal',
      color: '#333333',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
        '&$focused': {
          backgroundColor: 'white',
        },
      },
    },
  },
})

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      lang: 'en',
    }

    // history.listen(() => {
    //     this.props.clearAlerts();
    // });
  }

  handleClose = () => {
    this.props.clearAlerts()
  }

  render() {
    const { alert } = this.props
    const { lang } = this.state

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Snackbar
          open={alert.message != null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity={alert.type}>
            {alert.message}
          </Alert>
        </Snackbar>
        <IntlProvider locale={lang} messages={messages[lang]}>
          <Home />
        </IntlProvider>
      </MuiThemeProvider>
    )
  }
}

function mapState(state) {
  const { alert } = state
  return { alert }
}

const actionCreators = {
  clearAlerts: alertActions.clear,
}

const connectedApp = connect(mapState, actionCreators)(App)

export { connectedApp as App }
