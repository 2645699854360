import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { servingTypes } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#121212',
    margin: theme.spacing(),
  },
}))

const ServingType = ({ menu, intl, handleTypeChange }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <>
      <Grid item sm={12}>
        <Typography className={classes.label}>{getLabel('serving-type')}</Typography>
      </Grid>
      <Grid item sm={12} className='row'>
        <RadioGroup
          aria-label='servingType'
          name='servingtype'
          row={true}
          value={menu.servingType}
          onChange={handleTypeChange}
        >
          <FormControlLabel
            value={servingTypes.PORTION}
            control={<Radio color='primary' />}
            label={getLabel('portion-label')}
          />
          <FormControlLabel
            value={servingTypes.WEIGHT}
            control={<Radio color='primary' />}
            label={getLabel('weight-label')}
          />
          <FormControlLabel
            value={servingTypes.BOTTLE}
            control={<Radio color='primary' />}
            label={getLabel('bottle-label')}
          />
          <FormControlLabel
            value={servingTypes.GLASS}
            control={<Radio color='primary' />}
            label={getLabel('glass-label')}
          />
        </RadioGroup>
      </Grid>
    </>
  )
}

ServingType.propTypes = {
  menu: PropTypes.object,
  handleTypeChange: PropTypes.func,
}

export default injectIntl(ServingType)
