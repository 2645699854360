import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import CheckFieldRadioButtons from './CheckFieldRadioButtons'
import SelectAddress from '../BusinessDetails/SelectAddress'

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    borderTop: '1px solid #e7e7e7',
  },
  text: {
    color: '#707070',
    marginBottom: theme.spacing(),
    paddingTop: theme.spacing(),
    borderTop: '2px solid #e7e7e7',
    maxWidth: 450,
  },
  gridContainer: {
    marginTop: theme.spacing(2.5),
  },
}))

const CheckFields = ({
  setCheckParsedAddress,
  checkParsedAddress,
  setUseStoreAddressForCheck,
  useStoreAddressForCheck,
  error,
  values,
  handleChange,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>{f({ id: 'specify-check-address' })} </Typography>{' '}
      <CheckFieldRadioButtons
        setUseStoreAddressForCheck={setUseStoreAddressForCheck}
        useStoreAddressForCheck={useStoreAddressForCheck}
      />
      <Grid container>
        <Grid item md={6} sm={6} xs={12}>
          <SelectAddress
            setParsedAddress={setCheckParsedAddress}
            parsedAddress={checkParsedAddress}
            error={error}
            otherAddress={!useStoreAddressForCheck}
            component='Check Fields'
          />
        </Grid>
      </Grid>
      {!useStoreAddressForCheck ? (
        <Grid container className={classes.gridContainer}>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              size='small'
              value={values.checkSuiteNo}
              variant='outlined'
              fullWidth
              id={values.checkSuiteNo}
              InputLabelProps={{ shrink: true }}
              label={f({ id: 'apt-suite' })}
              onChange={handleChange('checkSuiteNo')}
              inputProps={{
                maxLength: 10,
              }}
            />
          </Grid>
        </Grid>
      ) : null}
    </div>
  )
}

CheckFields.propTypes = {
  setCheckParsedAddress: PropTypes.func,
  checkParsedAddress: PropTypes.object,
  setUseStoreAddressForCheck: PropTypes.func,
  useStoreAddressForCheck: PropTypes.bool,
  error: PropTypes.bool,
  values: PropTypes.object,
  handleChange: PropTypes.func,
}

export default CheckFields
