import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { CardContent, makeStyles, Typography } from '@material-ui/core'
import deliveryTimeIcon from '../../../images/delivery-time.svg'
import priceFilter from '../../../helpers/price-icons'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { deliveryTypes, deliveryTimes } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingTop: 2,
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  deliveryBlock: {
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#333',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 1.29,
    },
  },
  icon: {
    width: 13.9,
    height: 13.9,
  },
  close: {
    color: 'rgb(97, 26, 21)',
    fontSize: 14,
    fontWeight: 500,
  },
}))

export const StoreDetails = ({ data, storeOpen }) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()
  const deliveryType = useSelector(({ search }) => search.searchQuery.deliveryType)
  const deliveryTime = useSelector(({ search }) => search.searchQuery.deliveryTime)

  const isDelivery = deliveryType === deliveryTypes.DELIVERY
  const isASAP = deliveryTime === deliveryTimes.ASAP

  return (
    <CardContent className={classes.content}>
      <div className={clsx('centered-row', classes.deliveryBlock)} style={{ marginTop: 5 }}>
        <Typography className={clsx('ellipsis', classes.title)}>{data.nickname}</Typography>
        <div className='centered-row'>{priceFilter(data.pricey, 15, false)}</div>
      </div>
      <div className='grow' />
      <div className={clsx('centered-row', classes.deliveryBlock)} style={{ paddingBottom: 8 }}>
        {storeOpen ? (
          data.deliveryTime !== -1 &&
          isDelivery &&
          isASAP && (
            <div className='centered-row'>
              <img
                src={deliveryTimeIcon}
                alt='Delivery time'
                width='13.9'
                height='13.9'
                className={classes.icon}
                style={{ opacity: 0.5, marginRight: 5 }}
              />
              <Typography variant='body2' color='textSecondary' component='p'>
                {`${data.deliveryTime} mins`}
              </Typography>
            </div>
          )
        ) : (
          <Typography variant='body2' component='p' className={classes.close}>
            {format({ id: 'closed-label' })}
          </Typography>
        )}
      </div>
    </CardContent>
  )
}

StoreDetails.propTypes = {
  data: PropTypes.object,
}

export default StoreDetails
