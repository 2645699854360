export const checkDeliveryDiscount = (order) => {
  // if the customer address is the same as the store address
  // both deliveryFee comes 0
  if (order?.deliveryFee === 0 && !!order?.originalDeliveryFee) return order?.originalDeliveryFee
  return null
}
export const checkSubtotalDiscount = (order) => {
  if (order?.finalSubtotalPrice !== order?.subTotal) return order?.subTotal
  return null
}
