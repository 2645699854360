const handlePhoneNo = (data) => {
  if (data.toString().length < 10) {
    return ''
  } else {
    let plus = '+'
    let newNo = plus.concat(data)
    return newNo
  }
}

export { handlePhoneNo }
