import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import MerchantNoResult from '../../common/MerchantNoResult'
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

const NoResult = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MerchantNoResult text='no-attribute-value' />
    </div>
  )
}

const AttributeValueList = ({ values, editValue, deleteValue }) => {
  const classes = useStyles()
  if (values?.length === 0) return <NoResult />
  return (
    <div className={classes.demo}>
      <List>
        {values?.map((value, i) => (
          <React.Fragment key={i}>
            <ListItem key={i} divider>
              <ListItemText primary={value.name} secondary={value.description ? value.description : ''} />
              <ListItemSecondaryAction>
                <IconButton edge='end' aria-label='edit' onClick={() => editValue(value)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge='end' aria-label='delete' onClick={() => deleteValue(value)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {/* <Divider /> */}
          </React.Fragment>
        ))}
      </List>
    </div>
  )
}

AttributeValueList.propTypes = {
  values: PropTypes.array,
  editValue: PropTypes.func,
  deleteValue: PropTypes.func,
}

export default injectIntl(AttributeValueList)
