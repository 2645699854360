export const restaurantConstants = {
  CREATE_REQUEST: 'CREATE_RESTAURANT_REQUEST',
  CREATE_SUCCESS: 'CREATE_RESTAURANT_SUCCESS',
  CREATE_FAILURE: 'CREATE_RESTAURANT_FAILURE',

  UPDATE_REST_REQUEST: 'UPDATE_RESTAURANT_REQUEST',
  UPDATE_REST_SUCCESS: 'UPDATE_RESTAURANT_SUCCESS',
  UPDATE_REST_FAILURE: 'UPDATE_RESTAURANT_FAILURE',

  GET_REQUEST: 'GET_RESTAURANT_REQUEST',
  GET_SUCCESS: 'GET_RESTAURANT_SUCCESS',
  GET_FAILURE: 'GET_RESTAURANT_FAILURE',

  SAVE_LOGO_REQUEST: 'SAVE_LOGO_REQUEST',
  SAVE_LOGO_SUCCESS: 'SAVE_LOGO_SUCCESS',
  SAVE_LOGO_FAILURE: 'SAVE_LOGO_FAILURE',
  CLEAR_LOGO: 'CLEAR_LOGO',

  DELETE_LOGO_REQUEST: 'DELETE_LOGO_REQUEST',
  DELETE_LOGO_SUCCESS: 'DELETE_LOGO_SUCCESS',
  DELETE_LOGO_FAILURE: 'DELETE_LOGO_FAILURE',

  SAVE_BACKGROUND_REQUEST: 'SAVE_BACKGROUND_REQUEST',
  SAVE_BACKGROUND_SUCCESS: 'SAVE_BACKGROUND_SUCCESS',
  SAVE_BACKGROUND_FAILURE: 'SAVE_BACKGROUND_FAILURE',
  CLEAR_BACKGROUND: 'CLEAR_BACKGROUND',

  DELETE_BACKGROUND_REQUEST: 'DELETE_BACKGROUND_REQUEST',
  DELETE_BACKGROUND_SUCCESS: 'DELETE_BACKGROUND_SUCCESS',
  DELETE_BACKGROUND_FAILURE: 'DELETE_BACKGROUND_FAILURE',

  CLEAR_STATE: 'CLEAR_STATE',
  // CHANGE_SELECTED_RESTAURANT: 'CHANGE_SELECTED_RESTAURANT',

  // CHANGE_SELECTED_REST_STORE: 'CHANGE_SELECTED_RESTAURANT_STORE',

  // GET_REST_STORES_REQUEST: 'GET_REST_STORES_REQUEST',
  // GET_REST_STORES_SUCCESS: 'GET_REST_STORES_SUCCESS',
  // GET_REST_STORES_FAILURE: 'GET_REST_STORES_FAILURE',
}
