import React from 'react'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import MobileCart from './MobileCart'

export default function InjectedMobileCart({ handleClose, nextButton, setOrderFees }) {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <MobileCart
          elements={elements}
          stripe={stripe}
          handleClose={handleClose}
          nextButton={nextButton}
          setOrderFees={setOrderFees}
        />
      )}
    </ElementsConsumer>
  )
}
