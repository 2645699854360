const showFilters = (searchData, initialQuery) => {
  return searchData.categoryIds.length === initialQuery.categoryIds.length &&
    searchData.dietaryIds.length === initialQuery.dietaryIds.length &&
    searchData.pricey === initialQuery.pricey &&
    searchData.rating === initialQuery.rating &&
    searchData.distance === initialQuery.distance &&
    searchData.orderByKey === initialQuery.orderByKey &&
    searchData.searchText === initialQuery.searchText
    ? false
    : true
}

export { showFilters }
