import mapValues from 'lodash/mapValues'

const handleWorkingHours = (data) => {
  let workingHoursArr = []
  let delivery = data.DELIVERY
  let takeout = data.TAKEOUT

  mapValues(delivery, function (item) {
    return item.length > 0 && workingHoursArr.push(item[0])
  })
  mapValues(takeout, function (item) {
    return item.length > 0 && workingHoursArr.push(item[0])
  })
  return workingHoursArr
}

export { handleWorkingHours }
