import React from 'react'
// import PropTypes from 'prop-types'
import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { secondDrawerMenu } from '../../../../../../helpers/merchant/drawer-menu'
import clsx from 'clsx'
import { useMerchantHomeContext } from '../../../../../../context/merchant/MerchantHome/main'

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(105, 231, 129, 0.3)',
    },
  },
  active: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  menuText: {
    color: '#292929',
    fontWeight: 300,
    fontSize: 19,
  },
}))

const SecondMenuSection = ({ intl }) => {
  const classes = useStyles()
  const {
    values: { firstActiveMenu },
    setters: { listItemHandleClick, signOutHandler },
  } = useMerchantHomeContext()
  const { formatMessage } = intl
  //Global state

  return (
    <List>
      {secondDrawerMenu.map((menu) => {
        const styleCondition =
          menu.name === 'signout'
            ? classes.listItem
            : clsx({
                [classes.listItem]: true,
                [classes.active]: firstActiveMenu === menu.name,
              })
        return (
          <ListItem
            button
            key={menu.name}
            className={styleCondition}
            onClick={() => (menu.name === 'signout' ? signOutHandler() : listItemHandleClick(menu.name))}
          >
            <ListItemIcon>
              <img src={menu.image} height='22' width='22' alt={menu.name} />
            </ListItemIcon>
            <ListItemText className={classes.menuText} primary={formatMessage({ id: menu.label })} />
          </ListItem>
        )
      })}
    </List>
  )
}

SecondMenuSection.propTypes = {
  // props: PropTypes,
}

export default injectIntl(SecondMenuSection)
