import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import { deliveryTypes, daysOfWeek } from '../../../constants'
import { Grid, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { BootstrapInput } from '../../utils/tools'
import { serviceHourIntervals } from '../../../constants/service_hours'
import MenuItem from '@material-ui/core/MenuItem'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
    margin: theme.spacing(),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  day: {
    fontSize: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
    margin: theme.spacing(),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  dayRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    // backgroundColor: 'blue',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  dayCol: {
    width: 100,
    // minWidth: 100,
    // backgroundColor: 'lightgreen',
    // alignItems: 'center',
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  addButton: {
    alignSelf: 'center',
    // margin: theme.spacing(),
    // backgroundColor: 'yellow',
  },
  iconButton: {
    margin: theme.spacing(),
  },
  hourRow: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

export function ServiceHours(props) {
  const { variant, data, add, remove, update, editting } = props
  const classes = useStyles()

  return (
    <div>
      <div className='centered-row'>
        <Typography variant='h5' className={classes.title}>
          <FormattedMessage id={variant + '-label'} defaultMessage={variant} />
        </Typography>
        <div className={classes.grow} />
      </div>
      {daysOfWeek.map((day, i) => (
        <ServiceDay
          key={i}
          data={data[`${day}`]}
          variant={variant}
          day={day}
          add={add}
          remove={remove}
          update={update}
          editting={editting}
        />
      ))}
    </div>
  )
}

ServiceHours.propTypes = {
  variant: PropTypes.oneOf([deliveryTypes.DELIVERY, deliveryTypes.TAKEOUT]).isRequired,
  data: PropTypes.object.isRequired,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func,
  editting: PropTypes.bool,
}

export function ServiceDay(props) {
  const { variant, day, data, add, remove, update, editting } = props
  const classes = useStyles()

  const sortedData = _.sortBy(data, 'toTime')

  return (
    <Grid container spacing={2} className={classes.dayRow}>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3} className={classes.dayCol}>
        <Typography variant='h5' className={classes.day}>
          <FormattedMessage id={day.toLowerCase() + '-label'} defaultMessage={day} />
        </Typography>
      </Grid>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={7} className='column' style={{ alignItems: 'center' }}>
        {sortedData.map(function (d, i) {
          return (
            <ServiceHour
              data={d}
              variant={variant}
              day={day}
              key={i}
              remove={remove}
              update={update}
              prev={sortedData[i - 1]}
              next={sortedData[i + 1]}
              editting={editting}
            />
          )
        })}
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1} xl={2} className={classes.actionRow}>
        {/* <div className={classes.grow} /> */}
        {editting && (
          <IconButton
            color='inherit'
            size='small'
            disabled={data.length === 3}
            className={classes.addButton}
            aria-label='add'
            onClick={() => add(variant, day)}
          >
            <AddIcon />
          </IconButton>
        )}
        {/* <div className={classes.grow} /> */}
      </Grid>
    </Grid>
  )
}

ServiceDay.propTypes = {
  variant: PropTypes.oneOf([deliveryTypes.DELIVERY, deliveryTypes.TAKEOUT]).isRequired,
  day: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func,
  editting: PropTypes.bool,
}

export function ServiceHour(props) {
  const { data, remove, update, prev, next, editting } = props
  const classes = useStyles()
  var moment = require('moment')

  let fromOptions = serviceHourIntervals.filter(function (h) {
    return moment(h.label, 'HH:mm a').isBefore(moment(data.toTime, 'HH:mm a'))
  })

  if (prev) {
    fromOptions = fromOptions.filter(function (h) {
      return moment(h.label, 'HH:mm a').isAfter(moment(prev.toTime, 'HH:mm a'))
    })
  }

  let toOptions = serviceHourIntervals.filter(function (h) {
    return moment(h.label, 'HH:mm a').isAfter(moment(data.fromTime, 'HH:mm a'))
  })

  if (next) {
    toOptions = toOptions.filter(function (h) {
      return moment(h.label, 'HH:mm a').isBefore(moment(next.fromTime, 'HH:mm a'))
    })
  }

  let newData = Object.assign({}, data)

  return (
    <div className={classes.hourRow}>
      <FormControl>
        <Select
          value={data.fromTime}
          onChange={(event) => {
            newData.fromTime = event.target.value
            update(data, newData)
          }}
          input={
            <BootstrapInput
              name='start'
              size='small'
              inputProps={{
                readOnly: !editting,
              }}
            />
          }
        >
          {fromOptions.map((item, i) => (
            <MenuItem key={i} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ marginLeft: 15 }}>
        <Select
          value={data.toTime}
          onChange={(event) => {
            newData.toTime = event.target.value
            update(data, newData)
          }}
          input={
            <BootstrapInput
              name='end'
              size='small'
              inputProps={{
                readOnly: !editting,
              }}
            />
          }
        >
          {toOptions.map((item, i) => (
            <MenuItem key={i} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {editting && (
        <IconButton
          edge='end'
          color='inherit'
          size='small'
          className={classes.iconButton}
          aria-label='close'
          onClick={() => remove(data)}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  )
}

ServiceHour.propTypes = {
  variant: PropTypes.oneOf([deliveryTypes.DELIVERY, deliveryTypes.TAKEOUT]).isRequired,
  data: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func,
  prev: PropTypes.object,
  next: PropTypes.object,
  editting: PropTypes.bool,
}
