import React from 'react'

import { AcceptRejectButtons } from './AccepReject'
import { deliveryTypes, orderStatuses } from '../../../../constants'
import { InProgressButtons } from './InProgressButtons'

export const ActionButtons = ({ order, changeOrderStatus }) => {
  const renderButtonGroups = () => {
    switch (order.status.name) {
      case orderStatuses.ORDER_RECEIVED:
        return <AcceptRejectButtons changeOrderStatus={changeOrderStatus} order={order} />
      case orderStatuses.ORDER_APPROVED:
        const label = order?.orderType === deliveryTypes.TAKEOUT ? 'set-as-delivered' : 'out-for-delivery'
        const nextStatus =
          order?.orderType === deliveryTypes.TAKEOUT ? orderStatuses.DELIVERED : orderStatuses.DRIVER_PICKED_UP
        return (
          <InProgressButtons
            changeOrderStatus={changeOrderStatus}
            order={order}
            nextStatus={nextStatus}
            label={label}
          />
        )
      case orderStatuses.DRIVER_PICKED_UP:
        return (
          <InProgressButtons
            changeOrderStatus={changeOrderStatus}
            order={order}
            nextStatus={orderStatuses.DELIVERED}
            label='set-as-delivered'
          />
        )
      default:
        return null
    }
  }

  return renderButtonGroups()
}
