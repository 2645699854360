import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'

import { Transition } from '../../../utils/tools'

const useStyles = makeStyles(() => ({
  dialogDiv: {
    overflow: 'hidden',
  },
}))

const AccountDialog = ({ open, onClose, children }) => {
  const classes = useStyles()
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <div className={classes.dialogDiv}>{children}</div>
    </Dialog>
  )
}

AccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AccountDialog
