import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { merchantStoreActions } from '../../../actions/merchant.store.actions'
import Confirmation from '../common/Confirmation'
import { generalConstants } from '../../../constants'
import StoresTable from './StoresTable'
import ActionButtons from '../common/ActionButtons'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { storeService } from '../../../services'
import { useToggle } from '../../../hooks/useToggle'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginTop: 70,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    height: 103,
    borderBottom: '1px solid #cdcdcd',
    marginBottom: theme.spacing(),
  },
  row: {
    cursor: 'pointer',
  },
  zeroPadding: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const Stores = () => {
  const classes = useStyles()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()
  // Global states
  const selectedRestaurant = useSelector(({ merchantStores }) => merchantStores.selectedRestaurant)
  const storeData = useSelector(({ merchantStores }) => merchantStores)

  // Local states
  const [selected, setSelected] = useState()
  const [showConfirm, setShowConfirm] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [loading, setLoading] = useToggle()
  const [deletedStore, setDeletedStore] = useState(null)
  // Actions
  const dispatch = useDispatch()
  const getBusiness = async (page, size) => dispatch(merchantStoreActions.getBusiness(page, size))

  useEffect(() => {
    getBusiness()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedStore])

  const handleSelectClick = (event, item) => {
    event.target.checked ? setSelected(item.storeId) : setSelected()
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(+event.target.value)
  }

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = stores.map((s) => s)
  //     setSelected(newSelecteds)
  //   } else {
  //     setSelected([])
  //   }
  // }

  const handleDelete = async () => {
    setLoading()
    setShowConfirm(false)
    // deleteStore(restaurant.id, selected)
    const response = await storeService.deleteStore(selectedRestaurant.restaurantId, selected)
    if (response === generalConstants.STATUS_OK) {
      setDeletedStore(selected)
      setSelected()
    } // handle error here
    setLoading()
  }

  const handleConfirmClose = () => {
    setShowConfirm(false)
    setSelected(null)
  }

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <ActionButtons selected={selected} action={setShowConfirm} to='stores' text='store' />
      <StoresTable
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        selected={selected}
        handleSelectClick={handleSelectClick}
        rowsPerPage={rowsPerPage}
        page={page}
      />
      <Confirmation
        open={showConfirm}
        close={handleConfirmClose}
        callback={handleDelete}
        title='are-you-sure'
        text='about-delete-store'
        rightButton='delete-label'
      />
      <Backdrop className={classes.backdrop} open={storeData.loading || loading}>
        <CircularProgress color='primary' />
      </Backdrop>
    </div>
  )
}

Stores.propTypes = {
  history: PropTypes.object,
}

export default injectIntl(Stores)
