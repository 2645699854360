import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import closeIcon from '../../../../../images/close-icon.svg'
import addAddressImage from '../../../../../images/add-address-image.svg'

import { Transition } from '../../../../utils/tools'
import { deliveryTypes, deliverySubTypes } from '../../../../../constants'
import { handleAddressName } from '../../../../../helpers/address-header'

import ButtonsArea from './AddAddressDialog/ButtonsArea'
import InputArea from './AddAddressDialog/InputArea'
import DeliveryPart from './AddAddressDialog/DeliveryPart'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '638px',
    height: 'auto',
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
  },
  cdIconButtonContainer: {
    width: '100%',
    height: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(),
  },
  cdIconButton: {
    width: 20,
    height: 20,
    padding: 0,
    margin: 0,
  },
  cdHeaderContainer: {
    width: '100%',
    height: 45,
    display: 'flex',
    justifyContent: 'center',
  },
  cdHeader: {
    width: '100%',
    height: 45,
    fontSize: 34,
    fontWeight: 'bold',
    lineHeight: 1.18,
    textAlign: 'center',
    color: '#333333',
  },
  cdImageContainer: {
    width: '100%',
    height: 222.3,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(),
  },
  cdText: {
    width: '100%',
    height: 21,
    fontSize: 16,
    textAlign: 'left',
    color: '#333333',
    padding: 0,
    marginBottom: theme.spacing(2),
    whiteSpace: 'pre',
  },
  label: {
    color: '#c4c4c4',
  },
}))

const AddAddressDialog = ({
  showAddDialog,
  handleDialogClose,
  handleAddClick,
  setName,
  newAddress,
  handleDeliveryAddress,
  setAddressLine2,
  handleSubType,
  subTyp,
  instruction,
  handleInstruction,
  addresses,
  setNewAddress,
  clearAddressLine2,
  clearName,
  clearInstruction,
  setDefaultAddressName,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const [addressSelected, setAddressSelected] = useState(false)
  const [active, setActive] = useState('false')
  const [typ, setTyp] = useState(deliveryTypes.DELIVERY)

  useEffect(() => {
    let active = true
    if (active) {
      if (typ === deliveryTypes.TAKEOUT) {
        setNewAddress({
          ...newAddress,
          deliveryInstruction: '',
        })
      }
    }
    return () => {
      active = false
    }
  }, [typ])

  useEffect(() => {
    let active = true
    if (active && addresses && showAddDialog) {
      let addressName = handleAddressName(addresses)
      setDefaultAddressName(addressName)
      setNewAddress({
        ...newAddress,
        name: addressName,
      })
    }
    return () => {
      active = false
    }
  }, [showAddDialog])

  const addressChangeListener = (currentAddress) => {
    currentAddress.length > 0 ? setAddressSelected(true) : setAddressSelected(false)
  }

  const handleBorderColor = () => setActive('true')
  const handleBorderColorBack = () => setActive('false')

  const handleSwitch = (e) => {
    if (e.target.checked) {
      setTyp(deliveryTypes.TAKEOUT)
      handleSubType('')
    } else {
      setTyp(deliveryTypes.DELIVERY)
      handleSubType(deliverySubTypes.DELIVER_DOOR)
    }
  }

  return (
    <Dialog
      open={showAddDialog}
      aria-labelledby='add-dialog-title'
      classes={{ paper: classes.root }}
      disableEscapeKeyDown
      TransitionComponent={Transition}
    >
      <div className={classes.cdIconButtonContainer}>
        <IconButton
          className={classes.cdIconButton}
          onClick={() => handleDialogClose()}
          style={{ backgroundColor: 'transparent' }}
        >
          <img src={closeIcon} alt='close-icon' height='20px' width='20px' />
        </IconButton>
      </div>
      <div className={classes.cdHeaderContainer}>
        <Typography className={classes.cdHeader}>{f({ id: 'add-address' })}</Typography>
      </div>
      <div className={classes.cdImageContainer}>
        <img src={addAddressImage} alt='new-order' height='222.3px' width='298.3px' />
      </div>
      <Typography className={classes.cdText}>{f({ id: 'new-address-dialog' })}</Typography>
      <InputArea
        newAddress={newAddress}
        setName={setName}
        handleBorderColorBack={handleBorderColorBack}
        handleBorderColor={handleBorderColor}
        active={active}
        addressChangeListener={addressChangeListener}
        handleDeliveryAddress={handleDeliveryAddress}
        setAddressLine2={setAddressLine2}
        clearName={clearName}
        clearAddressLine2={clearAddressLine2}
      />
      <DeliveryPart
        handleSwitch={handleSwitch}
        deliveryType={typ}
        handleSubType={handleSubType}
        subTyp={subTyp}
        instruction={instruction}
        handleInstruction={handleInstruction}
        typ={typ}
        clearInstruction={clearInstruction}
      />
      <ButtonsArea
        handleDialogClose={handleDialogClose}
        addressSelected={addressSelected}
        handleAddClick={handleAddClick}
      />
    </Dialog>
  )
}

AddAddressDialog.propTypes = {
  showAddDialog: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  handleAddClick: PropTypes.func,
  setName: PropTypes.func,
  newAddress: PropTypes.object,
  handleDeliveryAddress: PropTypes.func,
  setAddressLine2: PropTypes.func,
  handleSubType: PropTypes.func,
  subTyp: PropTypes.string,
  instruction: PropTypes.string,
  handleInstruction: PropTypes.func,
  address: PropTypes.array,
  setNewAddress: PropTypes.func,
  clearName: PropTypes.func,
  clearAddressLine2: PropTypes.func,
  clearInstruction: PropTypes.func,
  setDefaultAddressName: PropTypes.func,
}

export default AddAddressDialog
