import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import SettingsIcon from '@material-ui/icons/Settings'

import UserSettings from './user-email/UserSettings'

const useStyles = makeStyles((theme) => ({
  email: {
    textTransform: 'none',
    borderRadius: 25,
    backgroundColor: '#e7e7e7',
    padding: `0px ${theme.spacing(2)}px`,
    '&:hover': {
      backgroundColor: '#e7e7e7',
    },
  },
  container: {
    margin: `0px 0px ${theme.spacing()}px ${theme.spacing()}px`,
  },
}))

export const UserEmail = React.memo(() => {
  const classes = useStyles()

  const merchant = useSelector(({ merchant }) => merchant.merchantInfo)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (e) => setAnchorEl(e.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)
  const id = open ? 'user-settings-popover' : undefined

  return (
    <Grid container className={classes.container}>
      <Button onClick={handleClick} className={classes.email} endIcon={<SettingsIcon />}>
        {merchant.email}
      </Button>
      {open && <UserSettings open={open} anchor={anchorEl} handleClose={handleClose} id={id} />}
    </Grid>
  )
})
