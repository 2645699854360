import React from 'react'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginLeft: 20,
    width: '100%',
    height: '100%',
  },
  tab: {
    textTransform: 'uppercase',
  },
}))

const StyledTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
      backgroundColor: '#69e781',
    },
  },
  flexContainer: {
    height: '100%',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    color: '#676767',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    opacity: 1,
    '&:selected': {
      height: '100%',
    },
  },
}))((props) => <Tab disableRipple {...props} />)

const StoreTabs = ({ selectedSection, sections, handleSelectionChange }) => {
  const classes = useStyles()
  return (
    <StyledTabs className={classes.tabs} value={selectedSection} variant='scrollable' scrollButtons='auto' classes={{}}>
      <StyledTab
        style={{
          width: 0,
          minWidth: 0,
          maxWidth: 0,
          padding: 0,
        }}
        key='none'
        value='none'
      />
      {sections?.length > 0 &&
        sections.map((section) => {
          const activeMenuCount = section?.menus?.filter((m) => m.active).length
          if (!section.active || activeMenuCount === 0) return null
          return (
            <StyledTab
              label={section.name}
              key={section.id}
              value={section.name}
              className={classes.tab}
              onClick={() => handleSelectionChange(section.name)}
            />
          )
        })}
    </StyledTabs>
  )
}

export default StoreTabs
