import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { generalConstants } from '../../../../../constants'
import { alertActions } from '../../../../../actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    "&[input-container='true']": {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  },
  uploadButton: {
    width: 120,
    height: 40,
    backgroundColor: '#69e781',
    borderRadius: 4,
    display: 'flex',
    padding: ' 6px 12px',
    cursor: 'pointer',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow: '0 3px 3px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    fontSize: 16,
    fontWeight: 500,
    "&[data-content='delete']": {
      backgroundColor: '#E63946 !important',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    '&:hover': {
      boxShadow: '0 5px 5px 0 rgba(0,0,0,0.2)',
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    "&[error-text='true']": {
      fontWeight: 300,
      marginTop: theme.spacing(2.5),
      color: theme.palette.secondary.main,
    },
  },
  buttonsContainer: {
    display: 'flex',
    minWidth: 272,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  text: {
    color: '#707070',
    marginBottom: theme.spacing(),
  },
  divider: {
    marginTop: theme.spacing(3),
    width: '100%',
    maxWidth: 600,
  },
  container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}))

const UploadTaxDocument = ({ taxDocument, setTaxDocument, error }) => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  const dispatch = useDispatch()

  const warningMsg = (msg) => dispatch(alertActions.warning(msg))

  const selectPdf = (e) => {
    let selectedFile = e.target.files[0]
    if (!selectedFile) return
    if (selectedFile.size > generalConstants.MAX_IMAGE_SIZE) {
      return warningMsg('Max file size 5mb')
    } else if (selectedFile) {
      setTaxDocument(selectedFile)
    } else {
      console.log('Please select pdf file')
    }
  }

  const removePdf = () => setTaxDocument(null)

  return (
    <Box className={classes.gridItem} input-container='true'>
      <Typography className={classes.text}>{f({ id: 'upload-tax-document' })}</Typography>
      <Box className={classes.container}>
        <div className={classes.buttonsContainer}>
          <label htmlFor='file-upload' className={classes.uploadButton}>
            {f({ id: 'select-label' })}
          </label>
          <input
            id='file-upload'
            type='file'
            onChange={selectPdf}
            accept='application/pdf'
            className={classes.input}
            style={{ display: 'none' }}
          />
          <div className={classes.uploadButton} data-content='delete' onClick={() => removePdf()}>
            {f({ id: 'remove-label' })}
          </div>
        </div>
        {taxDocument && <Typography className={classes.fileName}>{taxDocument?.name}</Typography>}
      </Box>
      {error && !taxDocument && (
        <Typography className={classes.fileName} error-text='true'>
          {f({ id: 'upload-tax-document-error' })}
        </Typography>
      )}
      <Divider className={classes.divider} />
    </Box>
  )
}

UploadTaxDocument.propTypes = {
  setTaxDocument: PropTypes.func,
}

export default UploadTaxDocument
