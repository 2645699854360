import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter, Redirect } from 'react-router-dom'
import { initialQuery } from '../../../constants'
import { customerActions } from '../../../actions'
import { storeService } from '../../../services'
import { Transition } from '../../utils/tools'
import Dialog from '@material-ui/core/Dialog'
import MobileSignin from '../../authentication/MobileSignin'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import MobilePage from './welcome-page/MobilePage'
import DesktopPage from './welcome-page/DesktopPage'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import { getOCStatusQuery } from '../../../helpers/store/getOCStatusQuery'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      backgroundRepeat: 'repeat-x',
      backgroundPosition: 'left top',
    },
  },
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
  },
}))

const WelcomePage = ({ history }) => {
  const classes = useStyles()
  //Global states
  const search = useSelector(({ search }) => search, shallowEqual)
  //Local states
  const [locals, setLocals] = useState([])
  const [searchQuery, setSearchQuery] = useState(initialQuery)
  const [timeZoneOffset, setTimeZoneOffset] = useState(null)
  const [userLocationPreference, setUserLocationPreference] = useState(true)
  const [showMobileSigninDialog, setShowMobileSigninDialog] = useState(false)
  const [openStores, setOpenStores] = useState([])
  const mountedRef = useRef(false)

  const dispatch = useDispatch()
  const setGeolocation = (lat, long) => dispatch(customerActions.setGeolocation(lat, long))

  useEffect(() => {
    mountedRef.current = true
    getPosition().then(
      ({ coords }) => {
        if (!coords) return
        const { latitude, longitude } = coords
        setGeolocation(latitude, longitude)
        const timezoneOffset = new Date().getTimezoneOffset()
        setTimeZoneOffset(timezoneOffset)
        if (!mountedRef.current) return
        setSearchQuery({
          ...initialQuery,
          size: 6,
          latitude,
          longitude,
        })
      },
      (err) => setUserLocationPreference(false)
    )
    return () => {
      mountedRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchQuery !== initialQuery && !isMobileOnly && userLocationPreference) searchLocals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  useEffect(() => {
    let active = true
    if (!active) return
    locals.length > 0 && getOpenStores(locals)
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locals])

  const getOpenStores = async (stores) => {
    let storeIds = stores.map((store) => store.id)
    const query = getOCStatusQuery(searchQuery, storeIds)
    const response = await storeService.getStoreOCStatus(query)
    response && getOpenStoreIdsFromResponse(response)
  }

  const getOpenStoreIdsFromResponse = (response) => {
    const rawStoreOpenStatus = response?.filter((res) => res.open)
    const storeOpenStatus = rawStoreOpenStatus.map((item) => item.storeId)
    setOpenStores(storeOpenStatus)
  }

  const getPosition = () => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject))

  const searchLocals = () => {
    const { deliveryTime, ...rest } = searchQuery
    const newQuery = { ...rest, customOffset: 0 }
    const position = { timeZoneOffset, lat: rest.latitude, long: rest.longitude }
    storeService.customerStoreSearch(newQuery, position).then(
      (res) => {
        setLocals(res.data.stores)
      },
      (err) => {
        console.log('searchLocals -> err', err.response)
      }
    )
  }

  const handleMobileSigninClose = () => setShowMobileSigninDialog((prev) => !prev)

  const handleSignin = () => {
    setShowMobileSigninDialog((prev) => !prev)
    handleRedirection()
  }

  const handleRedirection = () => history.push(`${path}/customer/food`)

  if (search.searchQuery.address) return <Redirect to={`${path}/customer/food`} />

  return (
    <div className={isMobileOnly ? classes.mobileRoot : classes.root}>
      {!isMobileOnly ? (
        <DesktopPage locals={locals} openStores={openStores} />
      ) : (
        <MobilePage goToHome={handleRedirection} showDialog={handleMobileSigninClose} />
      )}
      <Dialog
        fullScreen
        open={showMobileSigninDialog}
        onClose={handleMobileSigninClose}
        TransitionComponent={Transition}
      >
        <MobileSignin handleClose={handleMobileSigninClose} handleSignin={handleSignin} goToHome={handleRedirection} />
      </Dialog>
    </div>
  )
}

WelcomePage.propTypes = {
  intl: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(WelcomePage)
