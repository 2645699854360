import PropTypes from 'prop-types'
import React from 'react'
import { identifyBgColor } from '../../../../helpers/reviews/identify-color'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import StarRatings from 'react-star-ratings'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  action: {
    alignSelf: 'center',
    color: 'white',
  },
  cardRoot: {
    width: '100%',
  },
  headerRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    color: 'white',
  },
  starContainer: {
    marginTop: theme.spacing(),
  },
  userComment: {
    fontWeight: 'bold',
  },
}))

export const RatingCard = ({ rating }) => {
  const classes = useStyles()
  return (
    <Grid item xl={4} lg={4} md={4} xs={12} sm={6}>
      <Card className={classes.cardRoot} raised>
        <CardHeader
          style={{ backgroundColor: identifyBgColor(rating?.orderRatingAverage) }}
          classes={{
            root: classes.headerRoot,
            action: classes.action,
            title: classes.headerText,
            subheader: classes.headerText,
          }}
          action={
            <Typography variant='h5' className={classes.headerText}>
              {rating?.orderRatingAverage.toFixed(1)}
            </Typography>
          }
          title={
            <Typography variant='h6' className={classes.headerText}>
              {`OrderId: ${rating.orderId}`}
            </Typography>
          }
          subheader={
            <div className='column'>
              <Typography variant='body1' className={classes.headerText}>
                {`Order Date: ${rating?.orderDate}`}
              </Typography>
              <Typography variant='body1' className={classes.headerText}>
                {`Order Number: ${rating?.orderNumber}`}
              </Typography>
            </div>
          }
        />
        <CardContent>
          {rating?.ratingAnswers.map((answer, i) => (
            <React.Fragment key={i}>
              <div className={classes.starContainer}>
                <Typography variant='h6'>{answer.question}</Typography>
                <StarRatings
                  name={answer?.question}
                  rating={answer.rating}
                  starSpacing='2px'
                  starRatedColor='#69e781'
                  starHoverColor='#69e781'
                  id={i}
                  numberOfStart={5}
                  starDimension='20px'
                />
              </div>
            </React.Fragment>
          ))}
          {rating?.orderComment && (
            <Grid container direction='column' className={classes.starContainer}>
              <div className='centered-row'>
                <Typography variant='body1' className={classes.userComment}>
                  User Comment
                </Typography>
              </div>
              <Typography variant='body2'>{rating?.orderComment}</Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}

RatingCard.propTypes = {
  rating: PropTypes.object,
}

export default React.memo(RatingCard)
