/* eslint-disable no-unused-expressions */
import React from 'react'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { storeActions, searchActions } from '../../../actions'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { getImage } from '../../../helpers/get-image'

const styles = () => ({
  root: {
    paddingTop: 10,
    width: '100%',
  },
  title: {
    fontSize: 50,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textAlign: 'center',
    letterSpacing: 'normal',
    color: '#333',
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    width: 170,
    height: 179,
    cursor: 'pointer',
    backgroundColor: '#f4f4f4',
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 8,
    color: '#000',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 20px 20px 0 rgba(196, 196, 196, 0.5)',
    objectFit: 'contain',
    // border: 'solid 8px #69e781',
    color: '#fff',
  },
  icon: {
    backgroundColor: '#fff',
    width: 112,
    maxWidth: 112,
    height: 112,
    borderRadius: 56,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    fontSize: 20,
    marginTop: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    textAlign: 'center',
  },
}))

function FoodCategory(props) {
  const { data, isSelected, onSelected } = props
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, {
        [classes.selected]: isSelected,
      })}
      onClick={() => onSelected(data.id)}
    >
      <div className={classes.icon}>{data.icon && <img alt='food-types' src={getImage(data?.icon)} />}</div>
      <div className={classes.name}>{data.name}</div>
    </div>
  )
}

FoodCategory.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired,
}

class FoodTypes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sliderMoving: false,
    }
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCategories !== this.props.selectedCategories) {
      // if (!isMobile) {
      //   this.props.history.push(`${path}/customer/search`)
      // }
    }
  }

  onCategorySelect(id) {
    if (!this.state.sliderMoving) this.props.setCategorySearchFilter(id)
  }
  render() {
    const { classes, categories, selectedCategories } = this.props
    const { sliderMoving } = this.state

    const comp = this

    var settings = {
      autoplay: false,
      centerMode: false,
      draggable: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 3,
      variableWidth: true,
      beforeChange: function (index) {
        comp.setState({ sliderMoving: true })
      },
      afterChange: function (index) {
        comp.setState({ sliderMoving: false })
      },
    }

    return (
      <div className={classes.root}>
        <Typography variant='h3' gutterBottom className={classes.title}>
          {this.getLabel('popular-categories')}
        </Typography>
        <Slider {...settings}>
          {categories &&
            this.props.categories.map((category) => (
              <FoodCategory
                data={category}
                key={category.id}
                isSelected={selectedCategories.some((sc) => sc === category.id)}
                onSelected={this.onCategorySelect.bind(this)}
              />
            ))}
        </Slider>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { categories, search } = state
  const { categoryIds } = search.searchQuery

  return {
    categories: categories.data,
    selectedCategories: categoryIds,
  }
}

const actionCreators = {
  setCategory: storeActions.searchByCategory,
  setCategorySearchFilter: searchActions.setCategorySearchFilter,
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, actionCreators)(FoodTypes))))
