import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import { GreenButton } from '../../../utils/tools'
import { deliveryDetailsList, deliveryTypesList } from '../../../../constants/set-address/second-step'
import DeliveryTypeList from './second-step/DeliveryTypeList'
import DeliverySubList from './second-step/DeliverySubList'
import Instruction from './second-step/Instruction'
import AptSuite from './second-step/AptSuite'
import AddressName from './second-step/AddressName'

import { deliveryTypes } from '../../../../constants'
import { handleAddressName } from '../../../../helpers/address-header'

const useStyles = makeStyles((theme) => ({
  stepTwoRow: {
    borderBottom: '1px solid #f7f7f7',
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  save: {
    borderColor: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: 18,
    color: theme.palette.primary.main,
    borderRadius: 10,
  },
}))

const SecondStep = ({
  intl,
  saveAddress,
  address,
  details,
  setDetails,
  callbacks,
  addresses,
  signedIn,
  setDefaultAddressName,
}) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  useEffect(() => {
    let active = true
    if (active) {
      if (addresses) {
        let name = handleAddressName(addresses)
        setDetails({ ...details, addressName: name })
        setDefaultAddressName(name)
      }
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses])

  return (
    <div className='column grow '>
      <Grid container>
        <AptSuite details={details} setDetails={setDetails} address={address} />
        {deliveryTypesList.map((item, i) => (
          <DeliveryTypeList item={item} details={details} key={i} callbacks={callbacks} />
        ))}

        <Grid item xs={12} className={classes.stepTwoRow} style={{ padding: 20, paddingTop: 10 }}>
          <Grid container>
            {deliveryDetailsList.map((item, i) => (
              <DeliverySubList item={item} details={details} setDetails={setDetails} key={item.label} />
            ))}
          </Grid>
        </Grid>
        <Instruction details={details} setDetails={setDetails} />
      </Grid>
      <div className='grow' />
      {signedIn && <AddressName details={details} setDetails={setDetails} />}
      {details.deliveryType && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className='full-width' style={{ padding: 8 }}>
            <GreenButton
              disabled={details?.deliveryType === deliveryTypes.TAKEOUT ? false : details?.deliverySubType === ''}
              fullWidth
              onClick={saveAddress}
            >
              {getLabel('search-label')}
            </GreenButton>
          </div>
        </div>
      )}
    </div>
  )
}
SecondStep.propTypes = {
  intl: PropTypes.object,
  saveAddress: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  setDetails: PropTypes.func.isRequired,
  callbacks: PropTypes.object.isRequired,
  addresses: PropTypes.array,
  signedIn: PropTypes.bool,
  setDefaultAddressName: PropTypes.func,
}

export default injectIntl(SecondStep)
