import { campaignConstants } from '../constants'

const INITIAL_STATE = {
  loading: false,
  data: [],
}

export function campaigns(state = INITIAL_STATE, action) {
  switch (action.type) {
    case campaignConstants.CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case campaignConstants.CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      }
    case campaignConstants.CAMPAIGN_FAILURE:
      return {
        loading: false,
      }
    default:
      return state
  }
}
