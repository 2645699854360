import { Grid, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { GreenButton } from '../../../utils/tools'

const MenuDialogActions = ({
  attributes,
  classes,
  buttonStatus,
  addMenuToCart,
  getLabel,
  data,
  quantity,
  attributePrice,
  storeOpened,
}) => {
  const checkAttributes = useMemo(() => {
    return !isEmpty(attributes) ? !buttonStatus : buttonStatus
  }, [attributes, buttonStatus])
  const buttonStatusCondition = useMemo(() => {
    return quantity ? checkAttributes : true
  }, [quantity, checkAttributes])

  return (
    <GreenButton
      variant='contained'
      disabled={buttonStatusCondition || !storeOpened}
      fullWidth
      className={classes.button}
      onClick={addMenuToCart}
    >
      <Grid container direction='row' justifyContent='space-between'>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} container alignItems='center' justifyContent='center'>
          <Typography className={classes.cartText}>{getLabel('add-to-cart')}</Typography>
        </Grid>
        <Grid item xs={4} container alignItems='center' justifyContent='flex-end'>
          <Typography className={classes.cartText}>
            ${((data?.price + attributePrice) * quantity).toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </GreenButton>
  )
}

export default MenuDialogActions
