import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import nothingThere from '../../images/nothing-there.svg'
import { injectIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontSize: 34,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#333333',
    lineHeight: 1.18,
    textAlign: 'center',
    width: '80%',
  },
  text: {
    fontSize: 16,
    fontWeight: 300,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    color: '#333333',
  },
  image: {
    width: 200,
    height: 250,
  },
}))

const MobileNoResult = ({ intl }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <div className={classes.root}>
      <div>
        <img src={nothingThere} className={classes.image} alt='cart' />
      </div>
      <span className={classes.title}>{getLabel('nothing-there')}</span>
      <div className='grow' />
      <span className={classes.text}>{getLabel('still-have-fresh-food')}</span>
    </div>
  )
}

MobileNoResult.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(MobileNoResult)
