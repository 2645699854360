import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import close from '../../../../../images/close.svg'

export const CloseIcon = ({ handleClose }) => (
  <Grid item xs={12} className='flex-container flex-end'>
    <IconButton onClick={handleClose} size='small'>
      <img src={close} height='22' width='22' alt='close' />
    </IconButton>
  </Grid>
)

CloseIcon.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default React.memo(CloseIcon)
