import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import { customerService } from '../../../../services'
import { generalConstants, deliveryTypes, deliverySubTypes } from '../../../../constants'

import Address from './Address'
import DeliveryType from './DeliveryType'

const path = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    textTransform: 'capitalize',
    color: '#fff',
    "&[save-button='true']": {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "&[cancel-button='true']": {
      backgroundColor: '#c4c4c4',
      marginRight: theme.spacing(2),
      '&:hover': {
        backgroundColor: '#c4c4c4',
      },
    },
  },
}))

let _isMounted = false

const PopoverContent = ({
  currentAddress,
  addressQuery,
  addressHeader,
  closeAddressPopover,
  addAddressToStore,
  setAddress,
  welcomePage,
  savedAddress,
  savedBefore,
  setUserDeliverySubTypes,
  setUserInstructions,
  setUserDeliveryType,
  setOpenAddressPopover,
  updateAddress,
  defaultAddressName,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const history = useHistory()

  const cart = useSelector(({ orders }) => orders.cart)

  const [addressName, setAddressName] = useState('')
  const [suiteNo, setSuiteNo] = useState('')
  const [deliveryType, setDeliveryType] = useState(deliveryTypes.DELIVERY)
  const [subType, setSubType] = useState(deliverySubTypes.DELIVER_DOOR)
  const [instruction, setInstruction] = useState('')

  useEffect(() => {
    _isMounted = true
    if (_isMounted) {
      if (savedBefore) {
        setAddressName(savedAddress.name)
        setSuiteNo(savedAddress.addressLine2)
        setInstruction(savedAddress.deliveryInstruction)
        if (savedAddress.deliveryOption === '') {
          setDeliveryType(deliveryTypes.TAKEOUT)
          setSubType('')
        } else {
          setDeliveryType(deliveryTypes.DELIVERY)
          setSubType(savedAddress.deliveryOption)
        }
      } else {
        if (!cart.type || cart.type === '') {
          setAddressName(addressHeader)
          setSuiteNo('')
          setDeliveryType(deliveryTypes.DELIVERY)
          setSubType(deliverySubTypes.DELIVER_DOOR)
          setInstruction('')
        } else if (cart.type === deliveryTypes.DELIVERY && cart.subType === '') {
          setAddressName(addressHeader)
          setSuiteNo('')
          setSubType(deliverySubTypes.DELIVER_DOOR)
          setInstruction(cart.instructions)
        } else {
          setAddressName(addressHeader)
          setSuiteNo('')
          setDeliveryType(cart.type)
          setSubType(cart.subType)
          setInstruction(cart.instructions)
        }
      }
    }
    return () => {
      _isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressHeader, savedBefore, savedAddress, cart])

  const clearInputField = () => setAddressName('')
  const clearAptNoField = () => setSuiteNo('')
  const handleSwitch = (e) => {
    if (e.target.checked) {
      setDeliveryType(deliveryTypes.TAKEOUT)
      setSubType('')
      setInstruction('')
    } else {
      setDeliveryType(deliveryTypes.DELIVERY)
      setSubType(deliverySubTypes.DELIVER_DOOR)
    }
  }

  const handleSubType = (prop) => setSubType(prop)
  const handleInstruction = (e) => setInstruction(e.target.value)
  const clearInstruction = () => setInstruction('')

  const handleAddAddress = async () => {
    let address = addressQuery
    const newAddress = {
      ...address,
      name: addressName && addressName.length > 0 ? addressName : defaultAddressName,
      addressLine2: suiteNo,
      deliveryOption: subType,
      deliveryInstruction: instruction,
    }
    setOpenAddressPopover(false)
    if (savedBefore) {
      await customerService
        .updateAddress(savedAddress.id, newAddress)
        .then((res) => handleResponse(res, newAddress))
        .catch((err) => console.log(err))
    } else {
      await customerService
        .createAddress(newAddress)
        .then((res) => handleResponse(res, newAddress))
        .catch((err) => console.log(err))
    }
  }

  const handleResponse = (res, newAddress) => {
    if (res.status === generalConstants.STATUS_OK) {
      savedBefore ? updateAddress(res.data) : addAddressToStore(res.data)
      updateStore()
      if (welcomePage) {
        setTimeout(() => {
          setAddress(newAddress)
        }, 200)
      } else {
        setAddress(newAddress)
      }
    }
  }

  const updateStore = () => {
    setUserDeliveryType(deliveryType)
    setUserDeliverySubTypes(subType)
    setUserInstructions(instruction)
    setTimeout(() => {
      routeToSearch()
    }, 400)
  }

  const routeToSearch = () => {
    !welcomePage && !history.location.pathname.includes('/customer/search') && history.push(`${path}/customer/search`)
  }

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Address
          addressName={addressName}
          setAddressName={setAddressName}
          clearInputField={clearInputField}
          clearAptNoField={clearAptNoField}
          setSuiteNo={setSuiteNo}
          currentAddress={currentAddress}
          suiteNo={suiteNo}
        />
        <Divider
          flexItem
          orientation='horizontal'
          style={{ backgroundColor: '#c4c4c4', width: '1px', opacity: '50%' }}
        />
        <DeliveryType
          handleSwitch={handleSwitch}
          deliveryType={deliveryType}
          handleSubType={handleSubType}
          subType={subType}
          instruction={instruction}
          handleInstruction={handleInstruction}
          clearInstruction={clearInstruction}
          setDeliveryType={setDeliveryType}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          disableRipple
          variant='contained'
          className={classes.button}
          cancel-button='true'
          onClick={closeAddressPopover}
        >
          {formatMessage({ id: 'close-label' })}
        </Button>
        <Button
          disableRipple
          variant='contained'
          className={classes.button}
          save-button='true'
          onClick={handleAddAddress}
        >
          {formatMessage({ id: 'save-label' })}
        </Button>
      </div>
    </div>
  )
}

PopoverContent.propTypes = {
  currentAddress: PropTypes.string,
  addressQuery: PropTypes.object,
  addressHeader: PropTypes.string,
  closeAddressPopover: PropTypes.func,
  addAddressToStore: PropTypes.func,
  setAddress: PropTypes.func,
  welcomePage: PropTypes.bool,
  savedAddress: PropTypes.object,
  savedBefore: PropTypes.bool,
  setUserDeliverySubTypes: PropTypes.func,
  setUserInstructions: PropTypes.func,
  setUserDeliveryType: PropTypes.func,
  defaultAddressName: PropTypes.string,
}

export default PopoverContent
