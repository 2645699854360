import { customerConstants } from '../constants'

export function signup(state = {}, action) {
  switch (action.type) {
    case customerConstants.SIGNUP_REQUEST:
      return { signingup: true }
    case customerConstants.SIGNUP_SUCCESS:
      return {}
    case customerConstants.SIGNUP_FAILURE:
      return {}
    default:
      return state
  }
}
