import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Button, makeStyles, Typography } from '@material-ui/core'
import plates from '../../../../images/plates.png'
import mobileBg from '../../../../images/mobile-signin-bg.png'
import mobileLogoIcon from '../../../../images/logo_text_white.svg'
import GoogleAuth from '../../../authentication/google_auth'
import FacebookAuth from '../../../authentication/facebook_auth'
import clsx from 'clsx'
import { GreenButton } from '../../../utils/tools'

const width = window.innerWidth

const useStyles = makeStyles((theme) => ({
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${mobileBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(3),
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  platesMobile: {
    width: '100%',
    height: '46%',
    left: 0,
    top: `6%`,
    backgroundImage: `url(${plates})`,
    position: 'absolute',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  favoriteMobile: {
    marginRight: '30%',
    zIndex: 100,
    fontSize: width < 400 ? 32 : 50,
    color: '#fff',
    textTransform: 'capitalize',
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.1,
    letterSpacing: -0.73,
    textAlign: 'left',
  },
  skip: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'capitalize',
    color: '#fff',
    height: 34,
    lineHeight: 1.38,
  },
  setLocation: {
    fontSize: 18,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    lettrSpacing: -0.41,
    color: '#fff',
    marginBottom: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}))

const MobilePage = ({ intl, goToHome, showDialog }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <div className={[classes.mobileRoot]}>
      <div className='centered-row' style={{ zIndex: 4 }}>
        <img src={mobileLogoIcon} alt='TookFresh' height='28' />
        <div className='grow' />
        <Button className={classes.skip} onClick={goToHome}>
          {getLabel('skip-label')}
        </Button>
      </div>
      <div className='grow' />
      <div className={classes.platesMobile} />
      <Typography className={classes.favoriteMobile}>{getLabel('favorable-restaurants')}</Typography>
      <Typography className={classes.setLocation}>{getLabel('set-exact-location')}</Typography>
      <GreenButton variant='contained' onClick={showDialog} className={clsx('full-width', [classes.marginBottom])}>
        {getLabel('sign-in')}
      </GreenButton>
      <GoogleAuth />
      {/* <FacebookAuth /> */}
    </div>
  )
}

MobilePage.propTypes = {
  goToHome: PropTypes.func,
  showDialog: PropTypes.func,
  intl: PropTypes.object,
}

export default injectIntl(MobilePage)
