import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Place from '@material-ui/icons/Place'

import useStyles from './styles'

const Names = ({ customerInfo, cart }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  return (
    <>
      {customerInfo?.firstName ? (
        <Grid item xs={12} className='row' style={{ paddingBottom: 4, flex: 1 }}>
          <div className={classes.nameContainer}>
            <Typography className={classes.name}>{`${customerInfo.firstName}'s Order`}</Typography>
          </div>
        </Grid>
      ) : null}
      <Grid item xs={12} className={classes.addressRow}>
        <Place className={classes.placeIcon} />
        <Typography className={classes.store}>{`${f({
          id: 'from-label',
        })} ${cart?.storeName}`}</Typography>
      </Grid>
    </>
  )
}

Names.propTypes = {
  customerInfo: PropTypes.object,
  cart: PropTypes.object,
}

export default Names
