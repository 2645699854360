// import { menuConstants, restaurantConstants } from '../constants'

const initialState = {
  loading: { deleteImage: false, updateMenu: false, saveMenuImage: false },
  error: false,
  stores: [],
}

export function menus(state = initialState, action) {
  switch (action.type) {
    // case menuConstants.CREATE_SECTION_SUCCESS:
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       sections: [...state.restaurants.sections, action.section],
    //     },
    //   }
    // case menuConstants.DELETE_SECTION_SUCCESS:
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       sections: [...state.restaurants.sections].filter((x) => x.id !== action.sectionId),
    //     },
    //   }
    // case menuConstants.DELETE_IMAGE_REQUEST:
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       loading: {
    //         ...state.restaurants.loading,
    //         deleteImage: true,
    //       },
    //     },
    //   }
    // case menuConstants.DELETE_IMAGE_SUCCESS:
    //   console.log('menus -> state', state)
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       loading: {
    //         ...state.restaurants.loading,
    //         deleteImage: false,
    //       },
    //       sections: [...state.restaurants.sections]
    //         .find((i) => i.id === action.sectionId)
    //         .menus.find((e) => e.id === action.menuId)
    //         .images.filter((x) => x.id !== action.imageId),
    //     },
    //   }
    // case menuConstants.UPDATE_SECTION_SUCCESS:
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       sections: state.restaurants.sections.map((section) => {
    //         if (section.id !== action.section.id) {
    //           return section
    //         }

    //         return {
    //           ...section,
    //           ...action.section,
    //         }
    //       }),
    //     },
    //   }
    // case menuConstants.CREATE_MENU_SUCCESS:
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       sections: state.restaurants.sections.map((section) => {
    //         if (section.id !== action.sectionId) {
    //           return section
    //         }

    //         return {
    //           ...section,
    //           menus: [...section.menus, action.menu],
    //         }
    //       }),
    //     },
    //   }
    // case menuConstants.DELETE_MENU_SUCCESS:
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       sections: state.restaurants.sections.map((section) => {
    //         if (section.id !== action.sectionId) {
    //           return section
    //         }

    //         return {
    //           ...section,
    //           menus: [...section.menus].filter((menu) => menu.id !== action.menuId),
    //         }
    //       }),
    //     },
    //   }
    // case menuConstants.UPDATE_MENU_REQUEST:
    //   console.log('menus -> state', state)
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       loading: {
    //         ...state.restaurants.loading,
    //         updateMenu: true,
    //       },
    //     },
    //   }
    // case menuConstants.UPDATE_MENU_SUCCESS:
    //   console.log('menus -> state', state)
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       loading: {
    //         ...state.restaurants.loading,
    //         updateMenu: false,
    //       },
    //       sections: state.restaurants.sections.map((section) => {
    //         if (section.id !== action.sectionId) {
    //           return section
    //         }

    //         return {
    //           ...section,
    //           menus: [...section.menus].map((menu) => {
    //             if (menu.id !== action.menu.id) {
    //               return menu
    //             }

    //             return {
    //               ...menu,
    //               ...action.menu,
    //             }
    //           }),
    //         }
    //       }),
    //     },
    //   }
    // case menuConstants.DELETE_STORE_SUCCESS:
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       stores: [...state.restaurants.stores].filter((x) => x.id !== action.storeId),
    //     },
    //   }
    // case restaurantConstants.GET_FAILURE:
    //   return {
    //     error: action.error,
    //   }
    // case menuConstants.SAVE_MENU_IMAGE_REQUEST:
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       loading: {
    //         ...state.restaurants.loading,
    //         saveMenuImage: true,
    //       },
    //     },
    //   }
    // case menuConstants.SAVE_MENU_IMAGE_SUCCESS:
    //   return {
    //     ...state,
    //     restaurants: {
    //       ...state.restaurants,
    //       loading: {
    //         ...state.restaurants.loading,
    //         saveMenuImage: false,
    //       },
    //     },
    //   }
    // case menuConstants.SAVE_MENU_IMAGE_FAILURE:

    default:
      return state
  }
}
