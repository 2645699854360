const messages = {
  en: {
    'took-fresh': 'TookFresh',
    'settings-label': 'Settings',
    'log-in': 'Login',
    'sign-up': 'Sign Up',
    'sign-out': 'Sign out',
    'login-facebook': 'Continue with Facebook',
    'login-google': 'Continue with Google',
    'menu-label': 'Menu',
    'menus-label': 'Menus',
    'stores-label': 'Stores',
    'store-info-label': 'Store Info',
    'reviews-label': 'Reviews',
    'reports-label': 'Reports',
    'payments-label': 'Payments',
    'cancel-label': 'Cancel',
    'edit-label': 'Edit',
    'save-label': 'Save',
    'add-store': 'Add Store',
    'add-category': 'Add Category',
    'selected-label': 'selected',
    'select-label': 'Select',
    'nick-name': 'Nickname',
    'store-number': 'Store Number',
    'enter-nickname': 'Enter a nickname',
    'enter-fullname': 'Enter your fullname',
    'enter-firstName': 'Enter a first name',
    'enter-lastName': 'Enter a last name',
    'firstName-label': 'First Name',
    'lastName-label': 'Last Name',
    'enter-number': 'Enter a store number',
    'invalid-phone': 'Invalid phone number',
    'address-required': 'Street address is required',
    'city-required': 'City is required',
    'address-label': 'Address',
    'addresses-label': 'Addresses',
    'street-address': 'Street Address',
    'street-address-optional': 'Street Address (optional)',
    'city-label': 'City',
    'food-label': 'Food',
    'search-label': 'Search',
    'orders-label': 'Orders',
    'account-label': 'Account',
    'state-label': 'State',
    'taxes-label': 'Taxes',
    'tax-label': 'Tax',
    'total-label': 'Total',
    'filter-and-sort': 'Filter & Sort',
    'zipcode-label': 'Zip Code',
    'postal-code': 'Postal Code',
    'country-label': 'Country',
    'address-book': 'Address Book',
    'address-name': 'Address Name',
    'no-address-yet': 'Whoops! No Address',
    'too-many-addresses': 'Whoops! Too Many Addresses',
    'reached-max-address': 'You have reached to maximum address amount',
    'not-add-card': 'Whoops! We couldn`t add your card',
    'no-orders-yet': 'Whoops! No Orders Here',
    'no-favorites-yet': 'Whoops! No favorites yet',
    'no-address-text': 'I see you haven`t added an address yet.',
    'new-address': 'New Address',
    'phone-number': 'Phone',
    'phone-number-label': 'Phone Number',
    'basket-label': 'Basket',
    'email-label-short': 'Email',
    'email-label': 'Email',
    'service-hours': 'Service Hours',
    'delivery-label': 'Delivery',
    'takeout-label': 'Takeout',
    'monday-label': 'Monday',
    'tuesday-label': 'Tuesday',
    'wednesday-label': 'Wednesday',
    'thursday-label': 'Thursday',
    'friday-label': 'Friday',
    'saturday-label': 'Saturday',
    'sunday-label': 'Sunday',
    'closed-whole-day': 'Closed for the whole day',
    'add-more': 'Add more',
    'closed-label': 'Closed',
    'accept-sameday-order': 'Accept same day orders',
    'test-label': 'Test',
    'still-empty': 'Oh! It’s Still Empty',
    'oh-label': 'Oh!',
    'still-emtpty-label': 'It’s Still Empty',
    'basket-no-item': 'There are no items inside. Start adding some.',
    'no-items-label': 'There are no items inside.',
    'start-add-label': 'Start adding some.',
    'go-shopping': 'Go shopping now',
    'go-home': 'Go to Home',
    'no-order-yet': 'You Have Not Order Yet',
    'no-order-text': 'Keep track of your orders and return.',
    'your-orders': 'Your Orders',
    'search-page-label': 'What is the fresh food you want to take?',
    'search-placeholder': 'Search my took fresh..',
    'asian-label': 'Asian',
    'bbq-label': 'BBQ',
    'breakfast-label': 'Breakfast',
    'burger-label': 'Burger',
    'chicken-label': 'Chicken',
    'dessert-label': 'Dessert',
    'gluten-free-label': 'Gluten Free',
    'greek-label': 'Greek',
    'hot-food-label': 'Hot Food',
    'indian-label': 'Indian',
    'italian-label': 'Italian',
    'japanese-label': 'Japanese',
    'kosher-label': 'Kosher',
    'mediterranean-label': 'Mediterranean',
    'mexican-label': 'Mexican',
    'middle-east-label': 'Middle East',
    'pizza-label': 'Pizza',
    'salad-label': 'Salad',
    'sandwich-label': 'Sandwich',
    'sea-food-label': 'Sea Food',
    'vegan-label': 'Vegan',
    'sushi-label': 'Sushi',
    'thai-label': 'Thai',
    'treat-label': 'Treat',
    'vegetarian-label': 'Vegetarian',
    'or-label': 'or',
    'or-continue': 'or continue with email',
    'forgot-password': 'Forgot your password?',
    'not-have-account': 'Don`t have an account?',
    'already-have-account': 'Already have an account?',
    'be-member': 'Be a member',
    'become-member': 'Become Partner',
    'no-connection': 'No Internet Connection',
    'no-connection-text': 'Something went wrong with your payment method, please review and take another try.',
    'profile-label': 'Profile',
    'profile-info': 'Profile Info',
    'saved-cards': 'Saved Cards',
    'coupons-label': 'Coupons',
    'privacy-policy': 'Privacy Policy',
    'other-label': 'Other',
    'send-feedback': 'Send Feedback',
    'about-us': 'About Us',
    'contact-us': 'Contact Us',
    'welcome-label': 'Welcome',
    'commission-text': 'Commission: The restaurants are charged a commission of up to 15% of the subtotal',
    'enter-email': 'Enter your email address',
    'enter-user-email': "Enter employee's email address",
    'invalid-email': 'Invalid email address',
    'enter-address': 'Enter an address',
    'merchant-signin': 'Merchant Signin',
    'tookfresh-dashboard': 'TookFresh Dashboard',
    'delete-label': 'Delete',
    'remove-label': 'Remove',
    'add-label': 'Add',
    'name-label': 'Name',
    'your-delivery-address': 'What is your delivery address?',
    'add-section': 'Add section',
    'no-section': 'No section added yet',
    'save-section': 'Save section',
    'description-label': 'Description',
    'add-food-under': 'Add Food under ',
    'edit-food-under': 'Edit Food under',
    'weight-label': 'Weight',
    'portion-label': 'Portion',
    'glass-label': 'Glass',
    'bottle-label': 'Bottle',
    'serving-size': 'Serving Size',
    'weight-person': 'person(s)',
    'weight-lb': 'lb',
    'weight-bottle': 'bottle',
    'weight-glass': 'glass',
    'serving-type': 'Serving Type',
    'price-label': 'Price',
    'pricey-label': 'Pricey',
    'person-label': 'person',
    'people-label': 'people',
    'categories-label': 'Categories',
    'min-head-count': 'Min Order Head Count',
    'dietaries-label': 'Dietaries',
    'discard-label': 'Discard',
    'save-changes': 'Save Changes',
    'about-delete-section': 'You are about to delete this section and all menus related to this section!',
    'about-delete-menu': 'You are about to delete this menu item from your menu!',
    'about-delete-store': 'You are about to delete the store you selected!',
    'about-delete-employee': 'You are about to delete the employee you selected!',
    'are-you-sure': 'Are you sure?',
    'sure-to-delete-favorite': 'Are you sure to remove this store from your favorites?',
    'sure-to-delete-store': 'Are you sure to remove this store?',
    'sure-to-delete-employee': 'Are you sure to remove this employee?',
    'sure-to-delete-image': 'Are you sure to delete this image?',
    'search-address': 'Search Address',
    'enter-store-address': 'Enter your store address',
    'caterer-cuisine': 'Caterer, cuisine, etc.',
    'dietary-label': 'Dietary',
    'min-delivery-fee': 'Minimum Delivery Fee',
    'small-order-fee': 'Small Order Fee',
    'service-fee': 'Service Fee',
    'delivery-fee': 'Delivery Fee',
    'sort-by': 'Sort by',
    'price-range': 'Price Range',
    'distance-label': 'Distance',
    'distance-label-mile': 'Distance, mi',
    'gluten-free': 'Gluten Free',
    'halal-label': 'Halal',
    'delivery-25': '$25 (or less)',
    'delivery-50': '$50 (or less)',
    'delivery-75': '$75 (or less)',
    'delivery-100': '$100 (or less)',
    'all-label': 'All',
    'rating-label': 'Rating',
    'star-rating-label': 'Star Rating',
    'delivery-time': 'Delivery Time',
    'to-label': 'to',
    'to-label-uppercase': 'To',
    'apply-label': 'Apply',
    'how-label': 'How',
    'where-label': 'Where',
    'when-label': 'When',
    'pick-up': 'Pick up',
    'asap-label': 'ASAP',
    'signin-text': 'Sign in with your TookFresh account',
    'scheduled-label': 'Scheduled',
    'first-name': 'First Name',
    'last-name': 'Last Name',
    'name-updated': 'Your name has been updated!',
    'lastname-updated': 'Your lastname has been updated!',
    'home-address': 'Home Address',
    'logo-label': 'Logo',
    'save-logo': 'Save Logo',
    'more-info': 'More info',
    'checkout-label': 'Checkout',
    'summary-label': 'Summary',
    'favorable-restaurants': 'The flavors of your wish',
    'address-zip': 'Enter a delivery address or zipcode',
    'local-favorites': 'Local Favorites',
    'become-driver': 'Become a Driver',
    'become-partner': 'Become a Partner',
    'try-app': 'Try the App',
    'become-driver-desc': 'As a delivery driver, you’ll make reliable money working anytime, anywhere.',
    'become-partner-desc': 'Grow your business and reach new customers by partnering with us.',
    'try-app-desc': 'Get the best TookFresh experience with live order tracking.',
    'start-earning': 'Start earning',
    'signup-store': 'Sign up your store',
    'get-app': 'Get the app',
    'get-15-off': '(Get $15.00 off)',
    'pickup-delivery': 'We bring people together with great food',
    'pickup-delivery-desc':
      'Choose delivery type, search your favorite cuisine and discover restaurants near you. Find a variety of great meals fast with lots of menu options. Order through the TookFresh platform. Enjoy eating the convenient way with places that deliver to your door.',
    'order-food-in-app': 'Order Food in Our App Easily',
    'app-store': 'App Store',
    'google-play': 'Google Play',
    'about-tookfresh': 'About TookFresh',
    'read-blog': 'Read our Blog',
    'buy-gift-card': 'Buy Gift Card',
    'create-business-account': 'Create Business Account',
    'add-your-restaurant': 'Add Your Restaurant',
    'signup-to-delivery': 'Sign up to Delivery',
    'get-help': 'Get Helps',
    'help-label': 'Help',
    'read-faq': 'Read FAQs',
    'view-cities': 'View All Cities',
    'view-countries': 'View All Countries',
    'view-all-label': 'View All',
    'restaurants-near-me': 'Restaurants Near Me',
    'support-center': 'Support Center',
    'fill-form': 'Fill out your information below to be signed up for our informative newsletter.',
    'email-address': 'Email',
    'who-delivers': 'Who delivers in your neighborhood?',
    'restaurants-label': 'Restaurants',
    'restaurants-section-desc': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    'fastest-deliver': 'Delivered Fastest to You',
    'best-deals': 'Best Deals for You',
    'more-filters': 'More Filters',
    'search-cuisine-item': 'Search cuisine or food',
    'show-more': 'Show more',
    'show-less': 'Show less',
    'clear-filters': 'Clear filters',
    'clear-label': 'Clear',
    'edit-details': 'Edit Delivery Details',
    'edit-pickup-details': 'Edit Pickup Details',
    'summary-items': 'Summary | Items',
    'add-item': 'Add Item',
    'add-items': 'Add Items',
    'place-order': 'Place Order',
    'pick-date-time': 'Pick a date & time',
    'take-out': 'Takeout',
    'quantity-label': 'Quantity',
    'add-to-cart': 'Add to Cart',
    'special-instructions': 'Special Instructions',
    'add-delivery-instructions': 'Add delivery instructions',
    'delivery-instructions': 'Delivery Instructions',
    'from-label': 'From',
    'new-order-alert-title': 'New Order?',
    'delete-favorite-alert-title': 'Delete Favorite?',
    'delete-employee-alert-title': 'Delete Employee?',
    'delete-store-alert-title': 'Delete Store?',
    'delete-menu-alert-title': 'Delete Menu?',
    'new-order-alert-text':
      'This item belongs to a restaurant other than the items you have in your cart. Are you sure to delete those so far and to create new order?',
    'new-order-alert-text-1': 'This item belongs to a restaurant other than the items you have in your cart.',
    'new-order-alert-text-2': 'Are you sure to delete those so far and to create new order?',
    'create-new-order': 'Create new order',
    'address-line1': 'Address Line 1',
    'address-line2': 'Address Line 2',
    'create-account': 'Create your account',
    'sign-in': 'Sign in',
    'agree-text': 'By creating TookFresh account, you agree to the ',
    'and-label': ' and ',
    'terms-of-use': 'Terms of Use',
    'terms-and-conditions': 'Terms and Conditions',
    'do-not-sell': 'Do not sell my info (California)',
    'copyright-label': '©TookFresh 2020',
    'password-eight-char': 'at least 8 characters',
    'either-not-eight-char-or-same': 'Either less than 8 characters or same with the current password',
    'my-profile': 'My Profile',
    'payment-methods': 'Payment Methods',
    'payment-method': 'Payment Method',
    'payment-label': 'Payment',
    'add-payment': 'Add Payment Method',
    'about-delete-card': 'You are about to delete this card from your card list!',
    'continue-label': 'Continue',
    'new-payment-method': 'Add New Payment Method',
    'cancel-add-new-card': 'Cancel Add New Card',
    'add-new-card': 'Add New Card',
    'add-new-method': 'Add New Method',
    'payment-method-added': 'Payment method has been added',
    'add-tip': 'Add A Tip For Your Driver',
    'app-promo': 'Add Promo Code',
    'add-gift-card': 'Add A New Gift Card',
    'favorite-restaurants': 'Favorite Restaurants',
    'favorites-label': 'Favorites',
    'order-number': 'Order Number',
    'store-label': 'Store',
    'status-label': 'Status',
    'total-price': 'Total Price',
    'delivery-type': 'Delivery Type',
    'saved-addresses': 'Saved Addresses',
    'add-address': 'Add Your Address',
    'apt-suite': 'Apt/Suite/Floor',
    'default-label': 'Default',
    'set-default': 'Set as default',
    'use-this-address': 'Use this address',
    'make-default': 'Make Default',
    'promotions-label': 'Promotions',
    'promotion-name': 'Promotion Name',
    'notifications-label': 'Notifications',
    'invite-friends': 'Invite Friends',
    'gift-cards': 'Gift Card',
    'date-label': 'Date',
    'min-order-price': 'Min Order Price',
    'max-delivery-distance': 'Max Delivery Distance',
    'cannot-null': 'Cannot be empty!!',
    'reset-password': 'Reset Password',
    'send-label': 'Send',
    'receipt-label': 'Receipt',
    'forgot-password-text':
      'Please enter your email address. You will receive a link to create a new password via email.',
    'password-hasbeen-reset': 'Your password has been reset',
    'password-updated': 'Your password has been updated!',
    'password-reset-text': 'You will shorlty receive an email to setup a new password.',
    'done-label': 'Done',
    'edit-store': 'Edit Store',
    'phone-label': 'Phone',
    'default-address': 'Default Address',
    'password-label': 'Password',
    'username-label': 'Username',
    'home-label': 'Home',
    'add-default-address': 'Add an address',
    'verify-your': 'Verify Your',
    'update-your': 'Update Your',
    'verify-label': 'Verify',
    'sent-sms-code': 'We will send you an SMS with a code to',
    'still-have-fresh-food': 'We still have some more fresh food!',
    'sent-email-code': 'We will send you an email with a code to',
    'number-label': 'Number',
    'email-label-capital': 'email address',
    'phone-verification': 'Phone Verification',
    'enter-otp': 'Enter your OTP code here',
    'not-received-code': "Didn't you receive any code?",
    'resend-code': 'Resend a new code',
    'verification-done': 'Verification Done',
    'payment-done': 'Payment Done',
    'success-label': 'Success',
    'payment-confirmed': 'Your payment has been successfully processed and completed.',
    'phone-approved': 'Your phone number has been approved!',
    'verify-email': 'Verify Your Email Address',
    'update-email': 'Update Email',
    'update-name': 'Update Name',
    'update-lastname': 'Update Lastname',
    'update-label': 'Update',
    'update-done': 'Update Done',
    'email-verification': 'Email Verification',
    'email-approved': 'Your email address has been approved!',
    'change-password': 'Change Password',
    'current-password': 'Current Password',
    'new-password': 'New Password',
    'confirm-password': 'Confirm Password',
    'change-label': 'Change',
    'password-not-match': "Password doesn't match!",
    'verified-label': 'Verified',
    'not-verified-label': 'Not Verified',
    'background-label': 'Background',
    'save-background': 'Save Background',
    'skip-label': 'Skip',
    'set-exact-location': 'Set exact location to find the best restaurants near you',
    'top-eats': 'Top Eats',
    'sort-label': 'Sort',
    'reset-filter': 'Reset Filter',
    'reset-label': 'Reset',
    'under-label': 'Under',
    'view-label': 'View',
    'delivery-details': 'Delivery Details',
    'as-soon-as': 'As soon as possible',
    'schedule-order': 'Schedule for order',
    'top-rated': 'Top Rated',
    'nearest-me': 'Nearest Me',
    'high-low-cost': 'Cost High to Low',
    'low-high-cost': 'Cost Low to High',
    'most-popular': 'Most Popular',
    'first-order-title': 'First Order, $0 Delivery Fee',
    'see-all': 'See All',
    'mobile-signin-text': 'Welcome Back',
    'continue-facebook': 'Continue with Facebook',
    'search-results-for': 'Search results for ',
    'no-result-found': 'No result found',
    'not-found': 'Page not found',
    'no-response-received': 'No Response Received',
    'continue-google': 'Continue with Google',
    'recents-label': 'Recents',
    'previous-label': 'Previous',
    'upcoming-label': 'Upcoming',
    'no-previous-orders': 'No previous orders',
    'no-upcoming-orders': 'No upcoming orders',
    'deliver-with-tookfresh': 'Deliver with TookFresh',
    'about-label': 'About',
    'places-near-you': 'Places Near You',
    'add-new-address': 'Add New Address',
    'add-new': 'Add New',
    'set-as-default': 'Set as my default address',
    'primary-label': 'Primary',
    'about-delete-address': 'You are about to delete this address from your account!',
    'your-account-profile': 'Your Account Profile',
    'your-payment-methods': 'Your Payment Methods',
    'search-here': 'Search Here',
    'we-show-you-addresses': 'We’ll show you restaurants nearby to order Delivery or Pickup.',
    'search-new-address': 'Search for a new address',
    'set-delivery-time': 'Set Delivery Time',
    'set-address': 'Set Address',
    'near-me': 'Near me',
    'view-cart': 'View Cart',
    'your-cart': 'Your Cart',
    'next-label': 'Next',
    'or-login': 'Or login with',
    'incorrect-credential': 'Incorrect Credential',
    'incorrect-credential-text': 'The information you have entered is incorrect. Please update and try again.',
    'check-credentials': 'Please Check Your Credentials',
    'wrong-code': 'The information you have entered is incorrect. Please update and try again.',
    'deliver-door': 'Deliver to door',
    'meet-door': 'Meet at door',
    'meet-outside': 'Meet outside',
    'add-instructions': 'Add instructions for delivery',
    'add-instruction-placeholder': 'Leave at front porch, ring bell Please!',
    'new-address-dialog': 'Please enter an address and save so that you can use it any time in the future.',
    'save-address': 'Save Address',
    'current-location': 'Current Location',
    'menu-attributes': 'Menu Attributes',
    'min-count': 'Minimum Count',
    'max-count': 'Maximum Count',
    'rate-order': 'Rate Your Order',
    'submit-label': 'Submit',
    'subtotal-label': 'Subtotal',
    'popular-categories': 'Popular Categories',
    'nothing-there': 'No Result Found',
    'new-label': 'new label to be deleted',
    'visa-primary': 'Visa (Primary)',
    'master-label': 'Master',
    'visa-label': 'Visa',
    'paypal-label': 'Paypal',
    'applepay-label': 'ApplePay',
    'tookfresh-credit': 'TookFresh Credit',
    'tookfresh-credit-message': 'Your credits will be automatically applied to your next order.',
    'card-number': 'Card Number',
    'cvv-label': 'CVV',
    'expiration-label': 'Expiration',
    'add-card': 'Add Card',
    'new-payment-method-added': 'New payment method has been added into your account',
    'you-have-added-payment-method': 'You have added a new payment method',
    'add-paypal-account': 'Add a Paypal account',
    'add-amazonpay-account': 'Add a AmazonPay',
    'add-applepay-account': 'Add a ApplePay',
    'employees-label': 'Employees',
    'add-employee': 'Add Employee',
    'update-employee': 'Update Employee',
    'role-label': 'Role',
    'no-phone-header': 'Whoops! No Phone Number',
    'no-phone-text': 'You should provide a valid phone number.',
    'go-to-profile': 'Go to Profile',
    'no-verified-header': 'Whoops! No Verified Phone Number',
    'no-verified-text': 'You should verify your phone number before checkout.',
    'RESTAURANT_ADMIN-label': 'Restaurant Admin',
    'STORE_ADMIN-label': 'Store Admin',
    'STORE_USER-label': 'Store User',
    'send-invite': 'Send Invitation',
    'employee-basic-info': 'Employee Basic Information',
    'unauthorized-label': 'Authorization required',
    'restaurantName-label': 'Restaurant Name',
    'restaurantAddress-label': 'Restaurant Address',
    'mobilePhone-label': 'Mobile Phone e.g. 4158888888',
    'request-registration': 'Request Registration',
    'zoom-label': 'Zoom',
    'rotation-label': 'Rotation',
    'preview-label': 'Preview',
    'change-image': 'Change Image',
    'choose-image': 'Choose Image',
    'change-logo': 'Change Logo',
    'choose-logo': 'Choose Logo',
    'store-background-preview': 'Store Background Preview',
    'add-attribute': 'Add Attribute',
    'no-attribute': 'No attribute added yet',
    'free-count': 'Free Count',
    'about-delete-attribute': 'You are about to delete this attribute and all its content!',
    'add-attribute-value': 'Add Attribute Value',
    'about-delete-attribute-value': 'You are about to delete this attribute value!',
    'no-attribute-value': 'No attribute value added yet',
    'add-value': 'Add Value',
    'enter-between': 'Enter a number between 0 and 20',
    'menu-image-selection': 'Menu Image Selection',
    'delete-logo': 'Delete Logo',
    'delete-image': 'Delete Image',
    'menu-sample': 'Menu Sample',
    'menu-dialog-sample': 'Menu Dialog Sample',
    'request-received': 'Your request has been successfully received. We will contact you very soon.',
    'request-not-received': 'An error occured while receiving your request',
    'enter-valid-price': 'Price must be equal or bigger than zero!',
    'edit-attribute': 'Edit Attribute',
    'edit-attribute-value': 'Edit Attribute Value',
    'edit-attributes': 'Edit Attributes',
    'remove-attribute': 'You are about to detach this attribute from your menu!',
    'detach-label': 'Detach',
    'attach-success': 'Attribute successfully attached to menu',
    'detach-success': 'Attribute successfully detached from menu',
    'bankinfo-label': 'Bank Info',
    'add-bank-details': 'Add a bank account to receive payments',
    'account-number': 'Account Number',
    'routing-number': 'Routing Number',
    'enter-accountNumber': 'Please enter a valid account number',
    'enter-routingNumber': 'Please enter a valid routing number',
    'id-label': 'id',
    'show-saved-addresses': 'Show Saved Addresses',
    'hide-saved-addresses': 'Hide Saved Addresses',
    'my-new-address': 'My new address',
    'only-label': 'Only',
    'over-label': 'Over',
    'no-bankinfo': 'Please add your legal and bank details',
    'add-bankinfo': 'Add Bank Info',
    'business-name': 'Legal Business Name',
    'taxId-number': 'EIN/Tax ID Number',
    'business-owner-name': "Business Owner's Legal Name",
    'business-owner-birth': "Business Owner's Date of Birth",
    'enter-businessName': 'Please enter a business name',
    'enter-taxIdNumber': 'Please enter a valid 9 digit EIN/Tax ID Number',
    'enter-businessOwnerName': 'Please enter an owner legal name',
    'enter-ownerName': 'Please enter an owner legal name',
    'enter-businessOwnerBirthDate': 'Please enter a valid birth date',
    'senpex-delivery-warn-text': 'Sorry! This address is out of the delivery area for this store.',
    'min-head-count-failure':
      'This store requires more items in one single order. Please check the store page to see their min head count for an order.',
    'gift-code-placeholder': 'Please enter your 10 character gift card code',
    'promo-code-label': 'Promo Code',
    'payment-info-warning': 'Please provide a valid payment method.',
    'orderid-label': 'Order ID',
    'customer-label': 'Customer',
    'order-status': 'Order Status',
    'commissions-label': 'Commissions',
    'commission-label': 'Commission',
    'fees-label': 'Fees',
    'estimated-payment': 'Estimated Payment',
    'pickup-time': 'Pickup Time',
    'pickup-location': 'Pickup Location',
    'associated-transactions': 'Associated Transactions',
    'marketing-fee': 'Marketing Fee',
    'staff-tip': 'Staff Tip',
    'total-payout': 'Total Payout',
    'legal-info': 'Legal Information',
    'bank-info': 'Bank Information',
    'update-bank': 'Update your bank account details',
    'update-legal': 'Update your legal details',
    'mobile-not-supported': 'Mobile version not supported yet.',
    'save-this-address': 'Do you want to save this address?',
    'rate-your-order': 'Please rate your order',
    'delivery-by': 'Your delivery by',
    'order-done': 'Order Completed',
    'order-upcoming': 'Order Upcoming',
    'receipt-greeting': 'Thanks for choosing TookFresh, ',
    'your-role-in-business': 'Your Role In Business',
    'business-address': 'Business Address',
    'business-phone': 'Business Phone No *',
    'business-email': 'Business Email',
    'different-from-primary-email': 'Different from primary email',
    'your-catering-menu': 'Your catering menu',
    'menu-details-text':
      "If you have one please provide a link to your online catering menu. If you don't have an online menu you can upload a pdf of your menu. If you don't have an online menu or a pdf an onboarding specialist will reach out.",
    'number-order-confirmation': 'Number for order confirmation *',
    'email-order-confirmation': 'Email for order confirmation',
    'number-prep-confirmation': 'Number for food prep confirmation',
    'delivery-fee-1': 'Delivery Fee 1',
    'min-order-1': 'Minimum Order Price 1',
    'max-distance-1': 'Maximum Delivery Distance 1',
    'delivery-fee-2': 'Delivery Fee 2',
    'min-order-2': 'Minimum Order Price 2',
    'max-distance-2': 'Maximum Delivery Distance 2',
    'delivery-fee-3': 'Delivery Fee 3',
    'min-order-3': 'Minimum Order Price 3',
    'max-distance-3': 'Maximum Delivery Distance 3',
    'back-label': 'Back',
    'legal-business-name': 'Legal Business Name',
    'name-same-bank-account': 'Provided business name should be same on your bank account',
    'payment-contact-info-label': 'Payment Contact Information',
    'my-name': 'My Name',
    'fullname-label': 'Full Name',
    'review-label': 'Review',
    'few-more-steps':
      'There are a few more steps to complete the register process of your business. Please use your email that you have used when signing up.',
    'thanks-provided-info': 'Thank you for provided information.',
    'submit-or-review': 'Now you can either submit or review your form.',
    'menu-details-error': 'Please either add an online menu link or select a pdf menu',
    'phone-no-star': 'Phone *',
    'store-phone-star': 'Store Phone *',
    'delivery-details-error':
      'If you choose to specify delivery details manually you should complete all fileds at least in one row above',
    'miles-label': 'miles',
    'complete-label': 'Complete',
    'submission-error': 'An error occured during your submission process!',
    'try-again': 'Please try again later.',
    'here-label': 'here',
    'restart-submission': 'Or you can restart your submission process by clicking',
    'thanks-for-submission': 'Thank you for your submission.',
    'reach-out-soon': 'One of our onboarding specialists will reach out soon.',
    'save-and-search': 'Save and Search',
    'close-label': 'Close',
    'token-not-valid': 'Your token is not valid',
    'store-name': 'Store Name',
    'store-address': 'Store Address',
    'store-phone': 'Store Phone',
    'store-email': 'Store Email',
    'check-label': 'Check',
    'direct-deposit-label': 'Direct Deposit',
    'select-payment-method': 'Please select a payment method below and fill the related necessary fields.',
    'account-number-confirm': 'Confirm Account Number',
    'specify-check-address': 'Please specify an address for check delivery',
    'other-address': 'Other Address',
    'pickup-label': 'Pickup',
    'instructions-label': 'Instructions',
    'no-saved-addresses': 'You will see your saved addresses when you have at least one.',
    'return-to-main-page': 'Return to Main Page',
    'toggle-store-activity': 'Change store activity',
    'status-actions': 'Actions',
    'order-type': 'Order Type',
    'accept-order': 'Accept',
    'reject-order': 'Reject',
    'out-for-delivery': 'Out for Delivery',
    'set-as-delivered': 'Set as Delivered',
    'delivery-address': 'Delivery Address',
    'restaurant-approval': 'Order Placed',
    'prepare-order': 'Preparing Order',
    'order-canceled': 'Order Canceled',
    'order-delivered': 'Order Delivered',
    'loading-message': '...Loading',
    'store-active': 'Active Store',
    'store-details-label': 'Store Details',
    'delivery-options-label': 'Delivery Options',
    'restaurant-info-label': 'Restaurant Info',
    'campaigns-label': 'Campaigns',
    'campaign-name': 'Campaign Name',
    'start-date': 'Start Date',
    'end-date': 'End Date',
    'activate-label': 'Activate',
    'deactivate-label': 'Deactivate',
    'tier-error': 'The specified values should be larger than the related former tier values',
    'googlepay-label': 'GooglePay',
    'custom-label': 'Custom',
    'select-address': 'Select Address',
    'tip-label': 'Tip',
    'promo-label': 'Promo',
    'text-message': 'Text Message',
    'notification-error': 'You should provide a valid notification method',
    'provide-valid-credentials': 'Please provide valid credentials',
    'available-phone-text-message': 'Please ensure that the phone you have provided is available for text messages.',
    'check-address': 'Check Address',
    'enter-check-address': 'Enter your check address',
    'select-payment-transfer-method': 'Plese select your payment method',
    'select-notification-method': 'Please select your notification method',
    'select-contact-info': 'Please select your payment contact method',
    'working-hours-label': 'Working Hours',
    'fill-necessary-fields': 'Please fill the necessary fields ',
    'who-we-are': 'Who we are',
    'activated-soon': 'Will be activated soon',
    'comments-on-order': 'Please write a comment about your order',
    'no-customer-feedback': 'No customer feedback added yet',
    'working-hours-error-message': 'Please select working hours for at least one day',
    'only-open-stores': 'Only Open Stores',
    'only-open-label': 'Only Open',
    'social-network': 'social network',
    'order-time': 'Order Time',
    'update-password-label': 'Update Password',
    'enter-current-password': 'Please enter your current password',
    'enter-new-password': 'Please enter your new password',
    'confirm-new-password': 'Please confirm your new password',
    'pricey-message': 'This value is calculated based on your menu price.',
    'pass-8-char': 'The password should contain at least 8 characters',
    'invite-your-friend': 'Invite Your Friend',
    'lorem-ipsum':
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed leo lorem, semper vitae semper a, mollis et enim. Aenean commodo orci lacus, non semper tellus efficitur vel. Ut vitae ultricies.',
    'about-to-signout': 'You are about to signout!',
    'copy-menu':
      'You are about to copy the menu of a store you select to this store. It will be reflected to this store immediately.',
    'copy-label': 'Copy',
    'copy-menu-label': 'Copy menu from a store',
    'only-once': 'This action can only be done once.',
    'abort-label': 'Abort',
    'about-to-delete {image_}': 'You are about to delete {image_}.',
    'max-be-greater-min': 'Maximum count needs to be greater than or equal to minimum count',
    'free-count-smaller-than': 'Free count needs to be smaller than or equal to minimum or maximum count',
    'estimated-delivery-time': 'Estimated Delivery Time',
    'cancel-order': 'Cancel order',
    'proceed-label': 'Proceed',
    'about-to-cancel-order': 'You are about to cancel your order!',
    'order-rejected': 'Order Rejected',
    'restaurant-signin': 'Merchant Signin',
    'subscribe-via-email': 'Subscribe to <b>TookFresh</b> via Email',
    'aboutus-first-p':
      'In 2022, TookFresh was founded with a simple mission: “Serve the best food.” At TookFresh, we believe there is always a better way to serve food. We are obsessively passionate about it, and our mission is to help people achieve it. We’re excited to simplify the delivery process for everyone through our platform. TookFresh helps you find and order food from wherever you are with a few simple steps.',
    'aboutus-second-p':
      'When: Order your food ASAP or set a future time.{br}Where: Simply type an address to find restaurants deliver to that local as well as pick up restaurants new to you.{br} How: Choose how you want your food to be delivered.',
    'aboutus-third-p':
      "If you want to be more specific, search by cuisine, restaurant name or menu items. We'll filter your results accordingly. When you find what you're looking for, just go ahead and place your order. Once you get your order, you will be able to review the restaurant which served you, so we will be sure you get exactly what you want.",
    'details-label': 'Details',
    'upload-tax-document': 'Please upload a file including your tax information',
    'upload-tax-document-error': 'You should upload a file including your tax information!',
    'optional-email-address-text': 'If you want to provide an email address please click on the checkbox',
    'category-id': 'Category Id',
    'store-is-closed': 'Store is currently closed. Please check your delivery details.',
    'your-rating-for-order': 'Your rating for this order: ',
    'delivery-date': 'Delivery Date',
    'time-label': 'Time',
    'discount-label': 'Discount',
    'pickup-date': 'Pickup Date'
  },
}

export default messages
