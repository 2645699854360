import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: 400,
    height: 510,
    borderRadius: 15,
  },
  store: {
    fontSize: 17,
    fontWeight: 'bold',
    lineHeight: 1.35,
    color: '#c4c4c4',
  },
  addressRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  selectContainerGrid: {
    padding: theme.spacing(1, 0, 0.5, 0),
  },
  selectContainer: {
    height: 'auto',
    overflow: 'auto',
    flexGrow: 1,
    margin: 0,
    padding: 0,
    display: 'block',
  },
  selectGrid: {
    minHeight: 40,
    borderBottom: '1px solid #f4f4f4',
    marginTop: theme.spacing(),
    display: 'flex',
    height: 'auto',
    paddingRight: 0,
    overflowX: 'hidden',
  },
  commissionText: {
    padding: 0,
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
    fontSize: 15,
    color: '#0d0d0d',
    lineHeight: 1.56,
  },
  cartItem: {
    borderBottom: '1px solid #f4f4f4',
    paddingLeft: theme.spacing(),
  },
  cartLeftPart: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    flex: 1,
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
  },
  name: {
    color: '#707070',
    width: '85%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
    fontSize: 25,
  },
  placeIcon: {
    opacity: 0.3,
    marginLeft: '15%',
  },
  emptyCartContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  warnBox: {
    width: '100%',
    height: '60px',
    backgroundColor: 'rgb(253,236,234)',
    color: 'rgb(97, 26, 21)',
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    padding: '10px 15px 10px 15px',
    visibility: 'hidden',
    "&[content-visible='visible']": {
      visibility: 'visible',
    },
  },
}))
