import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { BlackButton, RedButton } from '../../utils/tools'

const Confirmation = ({ open, close, callback, item, title, text, intl, rightButton }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='delete-dialog-title'>{getLabel(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{getLabel(text)}</DialogContentText>
        {item && <DialogContentText id='alert-dialog-description'>{item.name}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <BlackButton onClick={close}>{getLabel('cancel-label')}</BlackButton>
        <RedButton onClick={callback}>{getLabel(rightButton)}</RedButton>
      </DialogActions>
    </Dialog>
  )
}

Confirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  item: PropTypes.object,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default injectIntl(Confirmation)
