import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import TopNavbar from '../top_navbar'
import Footer from '../Footers'
import VerifyPhoneWeb from './verify-phone/verify-phone-web'
import VerifyEmailWeb from './verify-email/verify-email-web'
import ChangePasswordWeb from './change-password/change-password-web'
import ChangeName from './change-name/change-name'
import LeftSide from './web-profile/LeftSide'
import RightSide from './web-profile/RightSide'
import AccountDialog from './web-profile/AccountDialog'

import { makeStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { customerService } from '../../../services/customer.service'
import { generalConstants } from '../../../constants'
import Loading from '../../common/loading'

const path = process.env.PUBLIC_URL

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...flexColumn,
    width: '100%',
    flexGrow: 1,
  },
  headerContainer: {
    ...flexColumn,
    justifyContent: 'center',
    width: '100%',
    minHeight: 160,
    backgroundColor: '#f7f7f7',
  },
  headerText: {
    fontSize: 34,
    textAlign: 'left',
    lineHeight: 1.32,
    color: '#333333',
    transition: 'font-size 0.3s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minHeight: generalConstants.minPageHeight,
    display: 'flex',
    justifyContent: 'space-between',
  },
  block: {
    ...flexColumn,
    width: '50%',
    height: '100%',
    minWidth: 290,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(),
    [theme.breakpoints.down('xs')]: {
      minWidth: 220,
    },
  },
  flexSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    "&[is-max='true']": {
      maxWidth: 336,
    },
  },
  text: {
    width: '100%',
    fontSize: 16,
    lineHeight: 1.31,
    color: '#707070',
    "&[is-light='true']": {
      width: 'fit-content',
      color: '#c4c4c4',
    },
    "&[last-four='true']": {
      width: 'fit-content',
      fontSize: 14,
    },
    "&[star-pass='true']": {
      marginTop: 5,
      fontSize: 20,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    transition: 'font-size 0.3s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  greenButton: {
    width: 336,
    fontSize: 16,
    marginTop: theme.spacing(3.5),
    transition: 'font-size 0.3s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  stars: {
    marginRight: theme.spacing(2),
    marginTop: 5,
    fontWeight: 'bold',
    fontSize: 20,
    transition: 'font-size 0.3s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    "&[is-first='true']": {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
}))

const WebProfile = () => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const history = useHistory()

  const customer = useSelector(({ customer }) => customer)

  const [verifyPhoneDialog, setVerifyPhoneDialog] = useState(false)
  const [verifyEmailDialog, setVerifyEmailDialog] = useState(false)
  const [changePasswordDialog, setChangePasswordDialog] = useState(false)
  const [changeNameDialog, setChangeNameDialog] = useState(false)
  const [lastFour, setLastFour] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let active = true
    if (active) {
      getPaymentMethods()
    }
    return () => {
      active = false
    }
  }, [])

  useEffect(() => {
    let active = true
    if (active) {
      if (customer) {
        let signedIn = customer.signedIn
        if (!signedIn) {
          history.push(`${path}/customer`)
        } else {
          return
        }
      }
    }
    return () => {
      active = false
    }
  }, [customer])

  const getPaymentMethods = async () => {
    setLoading(true)
    const response = await customerService.getPaymentMethods()
    if (response.status === generalConstants.STATUS_OK) {
      let paymentMethods = response.data.paymentMethods
      let defaultPM = response.data.defaultPaymentMethod
      if (paymentMethods.length > 0 && defaultPM) {
        let selectedCard = paymentMethods.filter((card) => card.id === defaultPM)[0]
        setLastFour(selectedCard.card.last4)
      } else {
        setLastFour(null)
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handlePhoneVerification = () => setVerifyPhoneDialog(!verifyPhoneDialog)

  const handleEmailVerification = () => setVerifyEmailDialog(!verifyEmailDialog)

  const handlePasswordChange = () => setChangePasswordDialog(!changePasswordDialog)

  const handleNameChange = () => setChangeNameDialog(!changeNameDialog)

  const goToPayments = () => history.push(`${path}/customer/account/payment-methods`)

  const goToAddresses = () => history.push(`${path}/customer/account/addresses`)

  return (
    <Box className={classes.root}>
      <Loading open={loading} />
      <Container maxWidth='lg'>
        <TopNavbar cartVisible={true} />
      </Container>
      <Box className={classes.headerContainer}>
        <Container maxWidth='lg'>
          <Typography className={classes.headerText}>{f({ id: 'your-account-profile' })}</Typography>
        </Container>
      </Box>
      <Container maxWidth='lg' className={classes.contentContainer}>
        <LeftSide
          classes={classes}
          handleNameChange={handleNameChange}
          customer={customer}
          handleEmailVerification={handleEmailVerification}
          goToPayments={goToPayments}
          lastFour={lastFour}
          loading={loading}
        />
        <RightSide
          classes={classes}
          customer={customer}
          handlePasswordChange={handlePasswordChange}
          handlePhoneVerification={handlePhoneVerification}
          goToAddresses={goToAddresses}
          loading={loading}
        />
      </Container>
      <AccountDialog open={verifyPhoneDialog} onClose={handlePhoneVerification}>
        <VerifyPhoneWeb handleClose={handlePhoneVerification} />
      </AccountDialog>
      <AccountDialog open={verifyEmailDialog} onClose={handleEmailVerification}>
        <VerifyEmailWeb handleClose={handleEmailVerification} />
      </AccountDialog>
      <AccountDialog open={changePasswordDialog} onClose={handlePasswordChange}>
        <ChangePasswordWeb handleClose={handlePasswordChange} />
      </AccountDialog>
      <AccountDialog open={changeNameDialog} onClose={handleNameChange}>
        <ChangeName handleClose={handleNameChange} />
      </AccountDialog>
      <div className='grow' />
      <Footer invisible />
    </Box>
  )
}

export default WebProfile
