import { dateConstant, deliveryTimes, initialMobileSearchTextQuery } from '../../constants'
import { queryInitialState } from '../../constants/mobile-food/mobile-food'
import moment from 'moment'

export const stateMaker = (searchQuery) => {
  const newState = {
    ...queryInitialState,
    categoryIds: searchQuery.categoryIds,
    dietaryIds: searchQuery.dietaryIds,
    rating: searchQuery.rating,
    orderByKey: searchQuery.orderByKey,
    orderByValue: searchQuery.orderByValue,
    onlyOpen: searchQuery.onlyOpen,
    pricey: searchQuery.pricey,
  }
  getCommonStateFromSearcQuery(searchQuery, newState)
  checkScheduledOrAsap(searchQuery, newState)
  return newState
}

export const checkScheduledOrAsap = (searchQuery, state) => {
  if (searchQuery.deliveryTime === deliveryTimes.SCHEDULED) {
    const orderTime = moment(searchQuery.deliveryTimeDetail).format(dateConstant.dateFormat)
    state.orderTime = orderTime
  } else {
    state.orderTime && delete state.orderTime
  }
  return state
}

const getCommonStateFromSearcQuery = (searchQuery, state) => {
  state.latitude = searchQuery.address?.latitude
  state.longitude = searchQuery.address?.longitude
  state.orderType = searchQuery.deliveryType
  state.distance = searchQuery.distance
  return state
}

export const webStateMaker = (searchQuery) => {
  const newState = stateMaker(searchQuery)
  newState.searchText = searchQuery.searchText
  return newState
}

export const mobileSearchScreenStateMaker = (searchQuery, category = null) => {
  const newState = { ...initialMobileSearchTextQuery }
  getCommonStateFromSearcQuery(searchQuery, newState)
  checkScheduledOrAsap(searchQuery, newState)
  if (category) {
    newState.categoryIds = [category?.id]
  }
  return newState
}
