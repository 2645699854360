import { useState, useEffect } from 'react'
import { deliveryTimes } from '../constants'

const getMaxWidth = (currentWidth, deliveryTime) => {
  if (deliveryTime === deliveryTimes.ASAP) {
    if (currentWidth >= 1280) {
      return 336
    } else if (960 <= currentWidth && currentWidth <= 1280) {
      return 336 - (1280 - currentWidth) / 2
    } else if (currentWidth < 960) {
      return 639 - (959 - currentWidth)
    }
  } else {
    if (currentWidth >= 1280) {
      return 231
    } else if (960 <= currentWidth && currentWidth <= 1280) {
      return 226 - (1280 - currentWidth) / 2
    } else if (currentWidth < 960) {
      return 530 - (959 - currentWidth)
    }
  }
}

function useMaxWidth(currentWidth, deliveryTime) {
  let [width, setWidth] = useState(370)

  useEffect(() => {
    setWidth(getMaxWidth(currentWidth, deliveryTime))
  }, [currentWidth])

  return width
}

export { useMaxWidth }
