import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { TFDialog, Transition } from '../../../utils/tools'
import DistanceFilter from '../filters/distance_filter'
import DietaryFilter from '../filters/dietary_filter'
import MobileDeliveryDetails from '../filters/MobileDeliveryDetails'
// import MobileDeliveryDetails from '../filters/mobile_delivery_details'
import { Button, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
// import MainFilter from '../filters/main_filter'
import MainFilter from '../filters/MainFilter'
import Dialog from '@material-ui/core/Dialog'
import { injectIntl } from 'react-intl'
import SortBy from '../filters/sort_by'
import close from '../../../../images/close.svg'
import clsx from 'clsx'
import SetAddress from '../../mobile-address-set/SetAddress'
import MobileSignin from '../../../authentication/MobileSignin'

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #f4f4f4',
  },
  reset: {
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.58,
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#c4c4c4',
    textTransform: 'capitalize',
  },
  footer: {
    width: '100%',
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing(),
    bottom: 10,
  },
  button: {
    width: '100%',
    height: 48,
    fontSize: 16,
    backgroundColor: '#69e781',
    borderRadius: 10,
    color: '#fff',
    textTransform: 'capitalize',
  },
}))

const MFDialogs = ({
  intl,
  showDialog,
  handleDialogClose,
  toggleAddressDialog,
  showAddressDialog,
  resetItems,
  handleMainFilterDialogClose,
  showLogin,
  handleLoginDialogClose,
  handleSignin,
}) => {
  const classes = useStyles()
  const mainFilterRef = useRef()
  const [applied, setApplied] = useState(false)

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const setFilters = () => {
    mainFilterRef.current.setFilters()
    handleMainFilterDialogClose(true)
  }

  const _resetFilters = () => {
    mainFilterRef.current.resetFilters()
  }

  return (
    <div>
      {/* <TFDialog
          fullWidth
          maxWidth={false}
          className={classes.dialog}
          open={showPriceRangeDialog}
          onClose={this.handlePriceRangeDialogClose}
          TransitionComponent={Transition}
          // keepMounted
        >
          <PriceFilter handleClose={this.handlePriceRangeDialogClose} />
        </TFDialog> */}
      <TFDialog
        fullWidth
        className={classes.dialog}
        open={showDialog.distanceRange}
        onClose={() => handleDialogClose('distanceRange')}
        TransitionComponent={Transition}
      >
        <DistanceFilter handleClose={handleDialogClose} resetItems={resetItems} />
      </TFDialog>
      <TFDialog
        fullWidth
        maxWidth={false}
        className={classes.dialog}
        open={showDialog.dietary}
        onClose={() => handleDialogClose('dietary')}
        TransitionComponent={Transition}
        keepMounted
      >
        <DietaryFilter handleClose={handleDialogClose} resetItems={resetItems} />
      </TFDialog>
      <TFDialog
        fullWidth
        maxWidth={false}
        className={classes.dialog}
        open={showDialog.deliveryDetails}
        onClose={() => handleDialogClose('deliveryDetails')}
        TransitionComponent={Transition}
      >
        <MobileDeliveryDetails
          handleClose={handleDialogClose}
          resetItems={resetItems}
          toggleAddressDialog={toggleAddressDialog}
        />
      </TFDialog>
      <TFDialog
        fullWidth
        scroll='paper'
        maxWidth={false}
        className={classes.dialog}
        open={showDialog.filter}
        onClose={handleMainFilterDialogClose}
        TransitionComponent={Transition}
      >
        <DialogTitle disableTypography={true} className={classes.dialogTitle}>
          <Button
            className={classes.reset}
            disabled={!applied}
            onClick={_resetFilters}
            style={{ width: '30%', justifyContent: 'flex-start' }}
          >
            <Typography variant='body1' color={applied ? 'primary' : 'textSecondary'}>
              {getLabel('reset-filter')}
            </Typography>
          </Button>
          <Typography variant='h5'>{getLabel('filter-and-sort')}</Typography>
          <Button
            color='inherit'
            onClick={handleMainFilterDialogClose}
            aria-label='close'
            style={{ width: '30%', justifyContent: 'flex-end' }}
          >
            <img src={close} height='22' width='22' alt='Back' />
          </Button>
        </DialogTitle>
        <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
          <MainFilter
            handleClose={handleMainFilterDialogClose}
            resetItems={resetItems}
            ref={mainFilterRef}
            setApplied={setApplied}
          />
        </DialogContent>
        <div className={clsx('sticky-bottom', classes.footer)}>
          <Button className={classes.button} onClick={setFilters}>
            {getLabel('apply-label')}
          </Button>
        </div>
      </TFDialog>
      <TFDialog
        fullWidth
        maxWidth={false}
        className={classes.dialog}
        open={showDialog.sortBy}
        onClose={() => handleDialogClose('sortBy')}
        TransitionComponent={Transition}
        keepMounted
      >
        <SortBy handleClose={handleDialogClose} resetItems={resetItems} />
      </TFDialog>
      <Dialog
        fullScreen
        maxWidth={false}
        open={showAddressDialog}
        onClose={toggleAddressDialog}
        TransitionComponent={Transition}
      >
        <SetAddress resetItems={resetItems} handleBack={toggleAddressDialog} />
      </Dialog>
      <Dialog fullScreen open={showLogin} onClose={handleLoginDialogClose} TransitionComponent={Transition}>
        <MobileSignin handleClose={handleLoginDialogClose} handleSignin={handleSignin} />
      </Dialog>
    </div>
  )
}

MFDialogs.propTypes = {
  intl: PropTypes.object,
  showDialog: PropTypes.object,
  handleDialogClose: PropTypes.func,
  resetItems: PropTypes.func,
  handleMainFilterDialogClose: PropTypes.func,
  resetFilters: PropTypes.func,
  filterApplied: PropTypes.bool,
  filtersApplied: PropTypes.bool,
  reset: PropTypes.bool,
  toggleFilter: PropTypes.func,
  applyFilters: PropTypes.func,
  showLogin: PropTypes.bool,
}

export default injectIntl(MFDialogs)
