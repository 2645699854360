import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles((theme) => ({
  typeMain: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(),
  },
  switchContainer: {
    width: 'fit-content',
    display: 'flex',
  },
  switch: {
    marginTop: -theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.grey[500],
      borderRadius: 20 / 2,
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.grey[500],
        },
      },
    },
  },
  type: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    transition: theme.transitions.create(['color']),
    "&[selected-type='true']": {
      color: theme.palette.primary.main,
    },
  },
}))

const TfSwitch = ({ value, firstLabel, secondLabel, handleSwitch }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  return (
    <div className={classes.typeMain}>
      <div className={classes.switchContainer}>
        <Typography className={classes.type} selected-type={!value ? 'true' : 'false'}>
          {f({ id: firstLabel })}
        </Typography>
        <Switch checked={value} className={classes.switch} onChange={handleSwitch} />
        <Typography className={classes.type} selected-type={value ? 'true' : 'false'}>
          {f({ id: secondLabel })}
        </Typography>
      </div>
    </div>
  )
}

TfSwitch.propTypes = {
  value: PropTypes.bool.isRequired,
  firstLabel: PropTypes.string.isRequired,
  secondLabel: PropTypes.string.isRequired,
  handleSwitch: PropTypes.func.isRequired,
}

export default TfSwitch
