import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'

import { storeActions } from '../../../../actions'
import { GreenCheckbox } from '../../../utils/tools'
import Label from './common/label'

import { Typography, FormLabel, FormControl, FormControlLabel, FormGroup } from '@material-ui/core'

const Dietaries = ({ classes }) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const dietaries = useSelector(({ dietaries }) => dietaries.data, shallowEqual)
  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)

  const addDietaryFilter = (id) => dispatch(storeActions.searchWithNewDietary(id))
  const removeDietaryFilter = (id) => dispatch(storeActions.removeDietarySearchFilter(id))

  const handleDietaryChange = (event) => {
    let id = parseInt(event.target.name)

    if (event.target.checked) addDietaryFilter(id)
    else removeDietaryFilter(id)
  }

  return (
    <FormControl component='fieldset' className={classes.formControl}>
      <FormLabel component='legend'>
        <Typography variant='subtitle1' className={classes.groupTitle}>
          {f({ id: 'dietary-label' })}
        </Typography>
      </FormLabel>
      <FormGroup>
        {dietaries?.map((dietary) => (
          <FormControlLabel
            key={dietary.id}
            control={
              <GreenCheckbox
                className={classes.check}
                checked={searchData.dietaryIds.some((d) => d === dietary.id)}
                onChange={handleDietaryChange}
                name={dietary.id.toString()}
              />
            }
            label={<Label data={searchData.dietaryIds} id={dietary.id} name={dietary.name} />}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

export default Dietaries
