import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { initialQuery } from '../../../../constants'
import { searchActions } from '../../../../actions'
import Fabs from './common/fabs'
import FabRatingContent from './common/fabRatingContent'

const Distance = () => {
  const dispatch = useDispatch()

  const resetRating = () => dispatch(searchActions.removeRatingSearchFilter())
  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)

  return (
    <>
      {searchData.rating && searchData.rating !== initialQuery.rating && (
        <Fabs text={<FabRatingContent searchData={searchData} />} onClick={resetRating} />
      )}
    </>
  )
}

export default Distance
