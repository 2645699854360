import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'

import Signin from '../authentication/signin'

import closeIcon from '../../images/close-icon.svg'
import { Transition } from '../utils/tools'

const useStyles = makeStyles(() => ({
  signin: {
    width: 450,
    height: 'auto',
    padding: 15,
    borderRadius: 10,
  },
  iconButtonContainer: {
    width: '100%',
    height: 35,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
  },
  iconButton: {
    width: 35,
    height: 35,
    padding: 0,
    margin: 0,
  },
  signinContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const SigninModal = ({ open, onClose }) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      aria-labelledby='signin-dialog'
      classes={{ paper: classes.signin }}
      TransitionComponent={Transition}
    >
      <div className={classes.container}>
        <div className={classes.iconButtonContainer}>
          <IconButton className={classes.iconButton} onClick={onClose}>
            <img src={closeIcon} alt='close-icon' height='20px' width='20px' />
          </IconButton>
        </div>
        <div className={classes.signinContainer}>
          <Signin login={false} modal='inModal' />
        </div>
      </div>
    </Dialog>
  )
}

export default SigninModal
