import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import MobileTopHeader from '../../common/MobileTopHeader'
import { useHistory } from 'react-router'
import Scrollbars from 'react-custom-scrollbars'
import WhoWeAre from './WhoWeAre'
import Description from './Description'
import deliveryPickup from '../../../images/delivery-pickup.png'
import aboutUsBanner from '../../../images/about-us/about-us-banner.png'

const height = window.innerHeight
const width = window.innerWidth

const useStyles = makeStyles((theme) => ({
  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
  content: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    justifyContent: 'center',
    paddingTop: theme.spacing(),
    overflow: 'scroll',
    flex: '1 50 auto',
    // backgroundColor: 'salmon',
  },
  image: {
    width: width,
  },
  imageContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  beCustomerContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}))

const AboutUs = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const goBackHandler = () => history.goBack()
  return (
    <div className={classes.mobileRoot}>
      <MobileTopHeader callback={goBackHandler} title='about-us' />
      <img src={aboutUsBanner} alt='about-us' className={classes.image} />
      <div className={classes.content}>
        <Scrollbars style={{ height: '100%' }}>
          <WhoWeAre />
          {/* <Grid container className={clsx('full-width', [classes.beCustomerContainer])}>
            <BeCustomer title='become-driver' description='become-driver-desc' buttonTitle='start-earning' />
            <BeCustomer title='become-partner' description='become-partner-desc' buttonTitle='signup-store' />
            <BeCustomer title='try-app' description='try-app-desc' buttonTitle='get-app' />
          </Grid> */}
          <Grid className={classes.imageContainer}>
            <img src={deliveryPickup} alt='tookfresh-delivery' width='100%' />
          </Grid>
          <Description />
        </Scrollbars>
      </div>
    </div>
  )
}

AboutUs.propTypes = {
  // second: PropTypes.third,
}

export default React.memo(AboutUs)
