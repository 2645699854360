import React from 'react'
import ContentLoader from 'react-content-loader'

const CheckoutButtonCL = () => {
  return (
    <ContentLoader viewBox='0 0 300 60'>
      <rect x='0' y='10' rx='10' ry='10' width='300' height='40' />
    </ContentLoader>
  )
}

export default CheckoutButtonCL
