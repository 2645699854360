import axios from 'axios'
import { uiHeader, merchantHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL

export const campaignService = {
  getCamapignTypes,
  getStorePromotions,
  activatePromotion,
  deActivatePromotion,
}

function getCamapignTypes() {
  return axios.get(`${API_URL}/marketing/promotion/ui`, uiHeader()).then((res) => {
    return res
  })
}

async function getStorePromotions(id) {
  const res = await axios.get(`${API_URL}/store/${id}/promotion`, merchantHeader())
  return res
}

async function activatePromotion(storeId, promoId) {
  const res = await axios.get(`${API_URL}/store/${storeId}/promotion/${promoId}/activation`, merchantHeader())
  return res
}

async function deActivatePromotion(storeId, promoId) {
  const res = await axios.delete(`${API_URL}/store/${storeId}/promotion/${promoId}/deactivation`, merchantHeader())
  return res
}
