import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { GreenButton } from '../../../utils/tools'
import { showWarnBox } from '../../../../helpers/cart/showWarnBox'
import { FormattedMessage } from 'react-intl'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
  },
}))

const PlaceOrderButton = ({
  cardElement,
  selectedCard,
  selectedPaymentMethod,
  signedIn,
  handlePhoneCheck,
  cart,
  tipAmount,
}) => {
  const classes = useStyles()
  const [buttonStatus, setButtonStatus] = useState(true)
  const warnActive = showWarnBox(cart)

  useEffect(() => {
    if ((!cardElement && !selectedCard) || selectedPaymentMethod !== 'Card' || !signedIn || warnActive === 'visible') {
      setButtonStatus(true)
    } else {
      setButtonStatus(false)
    }
  }, [cardElement, selectedCard, selectedPaymentMethod, signedIn, warnActive])

  let itemCount = cart?.orderMenus?.reduce((total, menuItem) => total + menuItem.quantity, 0)

  let tabletLabel = (
    <Box className={classes.box}>
      <Typography className={classes.text}>{itemCount}</Typography>
      <FormattedMessage id='place-order' />
      <Typography className={classes.text}>{`$${(cart?.totalPrice + +tipAmount?.value).toFixed(2)}`}</Typography>
    </Box>
  )

  return (
    <GreenButton disabled={buttonStatus} onClick={(event) => handlePhoneCheck(event)} fullWidth>
      {tipAmount ? tabletLabel : <FormattedMessage id='place-order' />}
    </GreenButton>
  )
}

PlaceOrderButton.propTypes = {
  cardElement: PropTypes.object,
  selectedCard: PropTypes.string,
  selectedPaymentMethod: PropTypes.string,
  signedIn: PropTypes.bool,
  handlePhoneCheck: PropTypes.func,
  cart: PropTypes.object,
  tipAmount: PropTypes.object,
}

export default PlaceOrderButton
