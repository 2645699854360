const handleAddressQuery = (data) => {
  let query = {
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2,
    city: data.city,
    country: data.country,
    default_: data.default_,
    formattedAddress: data.formattedAddress,
    latitude: data.latitude,
    longitude: data.longitude,
    name: data.name,
    state: data.state,
    stateName: data.stateName,
    zipCode: data.zipCode,
    deliveryInstruction: data.deliveryInstruction,
    deliveryOption: data.deliveryOption,
  }
  return query
}

export { handleAddressQuery }
