const checkIfAddressSavedBefore = (addressArray, addressQuery) => {
  for (let address of addressArray) {
    if (address.formattedAddress === addressQuery?.formattedAddress) return true
  }
  return false
}

const isSavedBefore = (addressArray, formattedAddress) => {
  for (let address of addressArray) {
    if (address.formattedAddress === formattedAddress) {
      return [true, address]
    }
  }
  return [false, null]
}

export { checkIfAddressSavedBefore, isSavedBefore }
