import React, { useEffect, useState } from 'react'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import PasswordField from './PasswordField'

import { merchantService } from '../../../services'
import { alertActions } from '../../../actions'
import { generalConstants } from '../../../constants'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(9),
  },
  passwordFieldsContainer: {
    width: 400,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    boxShadow: 'rgb(0 0 0 / 5%) 0px 7px 16px 4px;',
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%',
    textTransform: 'none',
  },
}))

const UpdatePassword = () => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const successMsg = (msg) => dispatch(alertActions.success(msg))
  const errorMsg = (msg) => dispatch(alertActions.error(msg))

  const [lengthError, setLengthError] = useState(false)
  const [confirmError, setConfirmError] = useState(false)

  const {
    values: { classesFromParent, openSwipeable },
    setters: { setFirstActiveMenu },
  } = useMerchantHomeContext()

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  })
  const [isFocused, setIsFocused] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  })
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  useEffect(() => {
    setFirstActiveMenu('update-password')
  }, [])

  useEffect(() => {
    const { newPassword, confirmNewPassword } = values
    if (newPassword.length > 0) {
      lengthError && setLengthError(false)
    }
    if (confirmNewPassword === newPassword) {
      confirmError && setConfirmError(false)
    }
  }, [values])

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
    setIsFocused({ ...isFocused, [name]: true })
  }

  const togglePasswordVisibility = (prop) => {
    setShowPassword({ ...showPassword, [prop]: !showPassword[prop] })
  }

  const clearValues = () => {
    setValues({ currentPassword: '', newPassword: '', confirmNewPassword: '' })
    setIsFocused({ currentPassword: false, newPassword: false, confirmNewPassword: false })
    setShowPassword({ currentPassword: false, newPassword: false, confirmNewPassword: false })
  }

  const handleCheck = () => {
    const { newPassword, confirmNewPassword } = values
    let truthyCondition = newPassword.length < 8 || newPassword !== confirmNewPassword
    if (truthyCondition) {
      if (newPassword.length < 8) {
        setLengthError(true)
      }
      if (newPassword !== confirmNewPassword) {
        setConfirmError(true)
      }
      return true
    }
    return false
  }

  const handleSubmit = async () => {
    const { currentPassword, newPassword } = values
    let isError = handleCheck()
    if (isError) return
    let query = {
      currentPassword,
      newPassword,
    }
    const response = await merchantService.updatePassword(query)
    if (response?.status === generalConstants.STATUS_OK) {
      clearValues()
      successMsg(f({ id: 'password-updated' }))
    } else {
      errorMsg('Update password error')
    }
  }

  return (
    <Box
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <Box className={classes.passwordFieldsContainer}>
        <PasswordField
          showPassword={showPassword.currentPassword}
          password={values.currentPassword}
          handleChange={handleChange}
          isFocused={isFocused.currentPassword}
          togglePasswordVisibility={togglePasswordVisibility}
          name='currentPassword'
          label={f({ id: 'current-password' })}
          placeholder={f({ id: 'enter-current-password' })}
        />
        <PasswordField
          showPassword={showPassword.newPassword}
          password={values.newPassword}
          handleChange={handleChange}
          isFocused={isFocused.newPassword}
          togglePasswordVisibility={togglePasswordVisibility}
          name='newPassword'
          label={f({ id: 'new-password' })}
          placeholder={f({ id: 'enter-new-password' })}
          error={lengthError}
        />
        <PasswordField
          showPassword={showPassword.confirmNewPassword}
          password={values.confirmNewPassword}
          handleChange={handleChange}
          isFocused={isFocused.confirmNewPassword}
          togglePasswordVisibility={togglePasswordVisibility}
          name='confirmNewPassword'
          label={f({ id: 'confirm-password' })}
          placeholder={f({ id: 'confirm-new-password' })}
          error={confirmError}
        />
        <Button className={classes.submitButton} variant='contained' color='primary' onClick={handleSubmit}>
          {f({ id: 'submit-label' })}
        </Button>
      </Box>
    </Box>
  )
}

export default UpdatePassword
