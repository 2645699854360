import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { TFTextField } from '../../utils/tools'

const useStyles = makeStyles((theme) => ({
  borderRadius: 10,
  height: 48,
}))

const InputField = ({ intl, propss }) => {
  const classes = useStyles()
  const { isFocused, isInvalid, state, handleChange, field } = propss

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <TFTextField
      size='small'
      variant='outlined'
      margin='normal'
      classes={classes.input}
      // required
      fullWidth
      autoFocus={field === 'restaurantName'}
      id={field}
      name={field}
      placeholder={getLabel(field === 'aptSuite' ? 'apt-suite' : `${field}-label`)}
      value={state[field] || ''}
      onChange={handleChange}
      error={isFocused[field] && isInvalid[field]}
    />
  )
}

InputField.propTypes = {
  propss: PropTypes.object.isRequired,
}

export default injectIntl(InputField)
