import React from 'react'
import { connect } from 'react-redux'
import { customerActions } from '../../../../actions'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { injectIntl } from 'react-intl'
import { GreenButton } from '../../../utils/tools'
import { nameRegex } from '../../../utils/regex_collections'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { customerService } from '../../../../services'
import { generalConstants } from '../../../../constants'
import backIcon from '../../../../images/black-back.svg'
import profileUpdated from '../../../../images/profile-updated.svg'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { isMobileOnly } from 'react-device-detect'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    fontWeight: 500,
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  backButton: {
    margin: theme.spacing(),
  },
  dialog: {
    width: 240,
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
    height: 31,
    marginTop: 68,
  },
  backWeb: {
    position: 'absolute',
    top: 69,
    left: theme.spacing(),
    height: 31,
  },
  mobileTopBar: {
    borderBottomColor: 'white',
    marginTop: 68,
  },
  took: {
    fontSize: 23,
    fontWeight: 'bold',
  },
  fresh: {
    fontSize: 23,
  },
  dialogContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 330,
  },
  dialogContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 320,
  },
  dialogContentText: {
    fontWeight: 'bold',
    fontSize: 34,
    textAlign: 'center',
    color: 'black',
  },
  dialogContentText2: {
    fontWeight: 'normal',
    fontSize: 16,
    textAlign: 'center',
    color: '#333333',
  },
  dialogActions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogButton: {
    fontSize: 15,
    textTransform: 'capitalize',
    width: '100%',
    height: 48,
    borderRadius: 10,
    backgroundColor: '#69e781',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

class ChangeName extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDoneDialog: false,
      anchorEl: null,
      firstName: '',
      lastName: '',
      firstNameInvalid: null,
      lastNameInvalid: null,
    }
  }

  componentDidMount() {
    const { customer } = this.props
    if (customer) {
      this.setState({
        firstName: customer.customerInfo.firstName,
        lastName: customer.customerInfo.lastName,
      })
    }
    return null
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleSubmit(e) {
    const { customer, storeUpdatedName } = this.props
    const { firstName, lastName } = this.state
    e.preventDefault()
    const data = {
      firstName: firstName,
      lastName: lastName,
      phone: customer.customerInfo.phone,
      email: customer.customerInfo.email,
    }
    customerService.updateUser(data).then((res) => {
      if (res.status === generalConstants.STATUS_OK) {
        storeUpdatedName(firstName, lastName)
        this.setState({ showDoneDialog: true })
      }
    })
  }

  render() {
    const { classes, currentFirstName, currentLastName } = this.props
    const { showDoneDialog, firstName, lastName, firstNameInvalid, lastNameInvalid } = this.state

    return (
      <div className='column'>
        <div className='row'>
          {isMobileOnly ? (
            <div className={clsx('mobile-top-bar', [classes.mobileTopBar])}>
              <IconButton
                color='inherit'
                aria-label='close'
                className={classes.back}
                onClick={() => this.props.handleClose()}
              >
                <img src={backIcon} alt='Back' />
              </IconButton>
              <div className='row'>
                <Typography className={classes.took}>Took</Typography>
                <Typography className={classes.fresh}>Fresh</Typography>
              </div>
            </div>
          ) : (
            <div
              className={classes.mobileTopBar}
              style={{
                width: '100%',
              }}
            >
              <IconButton
                color='inherit'
                aria-label='close'
                className={classes.backWeb}
                onClick={() => this.props.handleClose()}
              >
                <img src={backIcon} alt='Back' />
              </IconButton>
              <div className={classes.headerContainer}>
                <div className='row'>
                  <Typography className={classes.took}>Took</Typography>
                  <Typography className={classes.fresh}>Fresh</Typography>
                </div>
              </div>
            </div>
          )}
        </div>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography variant='h4' gutterBottom className={classes.title} style={{ marginBottom: 30 }}>
              {this.getLabel('update-name')}
            </Typography>
            <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
              <div className='row'>
                <TextField
                  style={{ marginBottom: 20 }}
                  size='small'
                  variant='outlined'
                  fullWidth
                  id='firstName'
                  label='Firstname'
                  name='firstName'
                  autoComplete='firstName'
                  value={firstName}
                  onChange={(event) =>
                    this.setState({
                      firstNameFocused: true,
                      firstNameInvalid: !nameRegex.test(event.target.value),
                      firstName: event.target.value,
                    })
                  }
                  error={firstNameInvalid && this.state.lastNameFocused}
                />
              </div>
              <div className='row'>
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  id='lastName'
                  label='Lastname'
                  name='lastName'
                  autoComplete='lastName'
                  value={lastName}
                  onChange={(event) =>
                    this.setState({
                      lastNameFocused: true,
                      lastNameInvalid: !nameRegex.test(event.target.value),
                      lastName: event.target.value,
                    })
                  }
                  error={lastNameInvalid && this.state.lastNameFocused}
                />
              </div>
              <GreenButton
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
                disabled={firstName === currentFirstName && lastName === currentLastName}
                className={classes.button}
              >
                {this.getLabel('update-label')}
              </GreenButton>
            </form>
          </div>
        </Container>
        <div className={classes.dialogContainer}>
          <Dialog
            open={showDoneDialog}
            onClose={() => this.props.handleClose()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            style={{ width: '100%', padding: 10 }}
            PaperProps={{
              style: {
                borderRadius: 20,
                marginTop: 70,
                padding: 20,
              },
            }}
          >
            <DialogContent className={classes.dialogContent}>
              <img src={profileUpdated} alt='doneImage' />
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText}>
                {this.getLabel('update-done')}
              </DialogContentText>
              <DialogContentText id='alert-dialog-description' className={classes.dialogContentText2}>
                {this.getLabel('name-updated')}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={() => this.props.handleClose()} className={classes.dialogButton}>
                {this.getLabel('continue-label')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )
  }
}

function mapState(state) {
  const { customer } = state

  return {
    customer,
    currentFirstName: customer.customerInfo.firstName,
    currentLastName: customer.customerInfo.lastName,
  }
}

const actionCreators = {
  storeUpdatedName: customerActions.storeUpdatedName,
}

ChangeName.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(injectIntl(connect(mapState, actionCreators)(ChangeName)))
