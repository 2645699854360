import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import close from '../../../../images/close.svg'

const MenuDialogTitle = ({ showTitle, data, classes, onClose, web }) => {
  return (
    <Grid item xs={12} className='centered-row'>
      {showTitle && (
        <Typography variant='h5' component='h5' className={classes.title} style={{ fontSize: web ? 18 : 16 }}>
          {data?.name}
        </Typography>
      )}
      <div className='grow' />
      <IconButton size='small' aria-label='add to favorites' onClick={onClose}>
        <img src={close} height='22' width='22' alt='Back' />
      </IconButton>
    </Grid>
  )
}

export default MenuDialogTitle
