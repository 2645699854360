import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import Settings from './settings'
import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import StoreSettings from './StoreSettings'

export const SettingsMain = ({ match }) => {
  const subProp = useMemo(() => match.params.sub, [match])

  const {
    values: { isRestaurantAdmin, isStoreAdmin },
  } = useMerchantHomeContext()

  const isRestaurantView = useSelector(({ merchantStores }) => merchantStores.isRestaurantView)

  const getContent = () => {
    // if (!subProp) return <Settings />

    switch (subProp) {
      case 'add':
        return <Settings />
      default:
        if (isRestaurantAdmin && isRestaurantView) return <Settings />
        if ((isRestaurantAdmin && !isRestaurantView) || isStoreAdmin) return <StoreSettings />
        return null
    }
  }
  return <div>{getContent()}</div>
}

SettingsMain.propTypes = {
  match: PropTypes.object,
}

export default withRouter(SettingsMain)
