import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Button, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import searchIcon from '../../../../../images/mobile_search.svg'
import searchDeleteIcon from '../../../../../images/search_close.svg'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  appbar: {
    padding: theme.spacing(),
    backgroundColor: '#fff',
    borderBottom: '1px solid #f7f7f7',
  },
  search: {
    [`& fieldset`]: {
      borderRadius: 20,
    },
  },
  form: {
    flexGrow: 1,
  },
  input: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    color: '#707070',
  },
  cancel: {
    textTransform: 'capitalize',
    marginLeft: 6,
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    color: '#707070',
  },
}))

const InputForm = ({ handleSubmit, keyword, handleClose, setKeyword, intl }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <div className={clsx('centered-row', [classes.appbar])}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          variant='outlined'
          value={keyword}
          size='small'
          fullWidth
          autoFocus
          className={classes.search}
          placeholder={getLabel('search-label')}
          onChange={(evt) => setKeyword(evt.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={searchIcon} alt='' height='22' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton size='small' onClick={() => setKeyword('')} aria-label='close'>
                  {keyword.length > 0 && <img src={searchDeleteIcon} alt='' height='22' />}
                </IconButton>
              </InputAdornment>
            ),
            classes: {
              input: classes.input,
            },
          }}
        />
      </form>
      <Button className={classes.cancel} onClick={handleClose}>
        {getLabel('cancel-label')}
      </Button>
    </div>
  )
}

InputForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  intl: PropTypes.object,
}

export default injectIntl(InputForm)
