const newState = (query) => {
  return {
    categoryIds: query.categoryIds,
    dietaryIds: query.dietaryIds,
    orderByKey: query.orderByKey,
    rating: query.rating,
    distance: query.distance,
    pricey: query.pricey,
    orderByValue: query.orderByValue,
  }
}

export { newState }
