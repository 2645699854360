const categoryChangeHandler = (event, menu) => {
  let id = event.target.name
  let newState
  if (menu.categories.some((c) => c === +id)) {
    newState = { ...menu, categories: menu.categories.filter((e) => e !== +id) }
  } else {
    newState = { ...menu, categories: menu.categories.concat(+id) }
  }
  return newState
}
const dietaryChangeHandler = (event, menu) => {
  let id = event.target.name
  let newState
  if (menu.dietaries.some((d) => d === +id)) {
    newState = { ...menu, dietaries: menu.dietaries.filter((e) => e !== +id) }
  } else {
    newState = { ...menu, dietaries: menu.dietaries.concat(+id) }
  }
  return newState
}

export { categoryChangeHandler, dietaryChangeHandler }
