import React, { useRef } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import InjectedCart from './injectedCart'
import { DialogTitle } from '@material-ui/core'
import Header from './RightPart/Header'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const useStyles = makeStyles((theme) => ({
  root: {},
  dialog: {
    padding: 0,
  },
  dialogPaper: {
    minHeight: 400,
    height: 'auto',
    overflowY: 'unset',
    borderRadius: 25,
  },
  customizedButton: {
    position: 'absolute',
    left: '97.6%',
    top: '-3%',
    backgroundColor: 'white',
    color: 'gray',
    '& svg': {
      transform: 'scale(1)',
      transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
    },
    '&:hover': {
      '& svg': {
        transition: `all 300ms ${theme.transitions.easing.easeInOut}`,
        transform: 'scale(1.3)',
      },
      backgroundColor: 'white',
    },
  },
  dialogContent: {
    padding: '10px 20px 40px 20px',
    overflowX: 'hidden',
  },
}))

const TabletCart = ({ showBasket, closeBasket, Transition, openVerifyDialog, openAddPhoneDialog }) => {
  const classes = useStyles()
  const cartRef = useRef()
  const history = useHistory()

  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)

  const handleClose = () => cartRef.current.onClose()

  const handleAddItem = () => {
    history.push(`/customer/store/${cart?.storeId}`)
    handleClose()
  }

  const itemCount = cart.orderMenus.reduce((total, menuItem) => total + menuItem.quantity, 0)

  return (
    <Dialog
      className={classes.dialog}
      maxWidth={'lg'}
      fullWidth={true}
      open={showBasket}
      TransitionComponent={Transition}
      classes={{ paper: classes.dialogPaper }}
      scroll='paper'
    >
      <IconButton aria-label='Close' className={classes.customizedButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Header handleAddItem={handleAddItem} itemCount={itemCount} tabletView />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Elements stripe={promise}>
          <InjectedCart
            cartRef={cartRef}
            closeBasket={closeBasket}
            openVerifyDialog={openVerifyDialog}
            openAddPhoneDialog={openAddPhoneDialog}
            tabletView
            showBasket={showBasket}
          />
        </Elements>
      </DialogContent>
    </Dialog>
  )
}

export default TabletCart
