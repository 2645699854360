import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import MobileMenuItem from '../MobileMenuItem'
import IntersectionVisible from 'react-intersection-visible'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  scrollView: {
    marginBottom: 60,
  },
  container: {
    // marginLeft: theme.spacing(),
    // marginRight: theme.spacing(),
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing() / 2,
    marginTop: theme.spacing() / 2,
  },
  separator: {
    height: 3,
    width: '100%',
    backgroundColor: '#F5F5F5',
  },
  separatorContainer: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'red',
  },
}))

const Content = React.forwardRef(
  (
    {
      handleScrollMenus,
      storeId,
      isMenuNotEmpty,
      onMenuItemShow,
      onMenuItemHide,
      handleMenuItemClick,
      cart,
      menus,
      sections,
    },
    ref
  ) => {
    const classes = useStyles()

    const getAlreadyOrderedCount = (id) => {
      let totalCount = 0
      const relatedMenus = menus.filter((i) => i.menuId === id)
      relatedMenus.forEach((menu) => {
        totalCount += menu.quantity
      })
      return totalCount
    }

    return (
      <Grid
        container
        onScroll={handleScrollMenus}
        className={clsx('vertical-scrolling-wrapper-flexbox', isMenuNotEmpty && classes.scrollView)}
      >
        {sections?.map((section, i) => {
          return (
            <Fragment key={i}>
              <div id={`${section.id}_ref`} ref={ref?.current[`${section.id}_ref`]}>
                {section?.menus
                  .filter((m) => m.active)
                  ?.map((menu, index) => {
                    return (
                      <Grid item key={menu.id} className={classes.menuItem}>
                        <IntersectionVisible
                          onShow={() => onMenuItemShow(menu, section.id)}
                          onHide={() => onMenuItemHide(menu, section.id)}
                        >
                          <MobileMenuItem
                            data={menu}
                            handleClick={() => handleMenuItemClick(menu)}
                            menuItemStore={cart.storeId === storeId}
                            alreadyOrderedCount={getAlreadyOrderedCount(menu.id) || 0}
                          />
                        </IntersectionVisible>
                      </Grid>
                    )
                  })}
              </div>
              {i !== sections?.length - 1 && (
                <Grid className={classes.separatorContainer}>
                  <div className={classes.separator} />
                </Grid>
              )}
            </Fragment>
          )
        })}
      </Grid>
    )
  }
)

Content.propTypes = {
  handleScrollMenus: PropTypes.func,
  storeId: PropTypes.number,
  isMenuNotEmpty: PropTypes.bool,
  onMenuItemShow: PropTypes.func,
  onMenuItemHide: PropTypes.func,
  handleMenuItemClick: PropTypes.func,
  cart: PropTypes.object,
  menus: PropTypes.array,
  myRef: PropTypes.object,
}

export default Content
