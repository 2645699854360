import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'

import { GreenCheckbox } from '../../../utils/tools'
import CustomizedPhoneInput from './Common/CustomizedPhoneInput'
import SelectAddress from './BusinessDetails/SelectAddress'

import { businessDetailsData, valueConstants, labelConstants } from '../../businessConstants'
import { emailRegex } from '../../../utils/regex_collections'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  gridItem: {
    marginBottom: theme.spacing(2),
  },
  field: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  none: {},
  categories: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    marginTop: theme.spacing(),
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
    },
  },
}))

const BusinessDetails = ({
  handleChange,
  values,
  error,
  setValues,
  setBusinessDifferentEmail,
  differentBusinessEmail,
  setParsedAddress,
  parsedAddress,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const categories = useSelector(({ categories }) => categories.data)

  useEffect(() => {
    handleBusinessEmail(differentBusinessEmail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [differentBusinessEmail])

  const handleBusinessEmail = (bool) => {
    if (bool) {
      if (values.storeEmail.length > 0 && values.storeEmail !== values.email) {
        return
      } else {
        setValues({
          ...values,
          storeEmail: '',
        })
      }
    } else {
      setValues({
        ...values,
        storeEmail: values.email,
      })
    }
  }

  const handleCheck = () => setBusinessDifferentEmail(!differentBusinessEmail)

  const nonRequiredFields = [valueConstants.suiteNo, valueConstants.storeNumber]

  return (
    <Container maxWidth='md' className={classes.root}>
      <Grid container spacing={2} className='full-width'>
        <Tooltip title={f({ id: 'name-same-bank-account' })} placement='top' arrow>
          <Grid item md={12} sm={12} xs={12} className={classes.gridItem}>
            <TextField
              size='small'
              value={values.legalBusinessName}
              variant='outlined'
              fullWidth
              id={valueConstants.legalBusinessName}
              InputLabelProps={{ shrink: true }}
              label={f({ id: labelConstants.legalBusinessName })}
              required
              error={error && values.legalBusinessName.length === 0}
              onChange={handleChange(valueConstants.legalBusinessName)}
              inputProps={{
                maxLength: 150,
              }}
            />
          </Grid>
        </Tooltip>
        {businessDetailsData.map((item, index) =>
          item.value === valueConstants.storePhone ? (
            <Grid key={index} item md={item.width} sm={item.width} xs={12} className={classes.gridItem}>
              <CustomizedPhoneInput
                valueName={valueConstants.storePhone}
                values={values}
                item={item}
                setValues={setValues}
                label={f({ id: labelConstants.storePhoneStar })}
                error={error}
              />
            </Grid>
          ) : item.value === valueConstants.storeAddress ? (
            <Grid key={index} item md={item.width} sm={item.width} xs={12} className={classes.gridItem}>
              <SelectAddress
                key={index}
                setValues={setValues}
                values={values}
                setParsedAddress={setParsedAddress}
                parsedAddress={parsedAddress}
                error={error}
                component='Business Details'
              />
            </Grid>
          ) : (
            <Grid key={index} item md={item.width} sm={item.width} xs={12} className={classes.gridItem}>
              <TextField
                size='small'
                value={values[item.value]}
                variant='outlined'
                fullWidth
                id={item.value}
                InputLabelProps={{ shrink: true }}
                label={f({ id: item.label })}
                onChange={handleChange(item.value)}
                required={!nonRequiredFields.includes(item.value)}
                error={
                  item.value !== valueConstants.suiteNo && (error && values[item.value].length === 0 ? true : false)
                }
                className={item.value === valueConstants.storeNumber ? classes.field : classes.none}
                type={item.value === valueConstants.storeNumber ? 'number' : 'text'}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Grid>
          )
        )}
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            size='small'
            value={values.storeEmail}
            variant='outlined'
            fullWidth
            id={valueConstants.storeEmail}
            InputLabelProps={{ shrink: true }}
            label={f({ id: labelConstants.storeEmail })}
            onChange={handleChange(valueConstants.storeEmail)}
            required
            error={values.storeEmail.length === 0 ? true : emailRegex.test(values.storeEmail) ? false : true}
            disabled={!differentBusinessEmail}
            inputProps={{
              maxLength: 100,
            }}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <FormControl>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={differentBusinessEmail}
                  onChange={handleCheck}
                  name={f({ id: 'different-from-primary-email' })}
                />
              }
              label={
                <Typography variant='body2' color='textSecondary'>
                  {f({ id: 'different-from-primary-email' })}
                </Typography>
              }
            />
          </FormControl>
        </Grid>
        <Grid container spacing={2} className={classes.categories}>
          <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
            <TextField
              select
              defaultValue
              variant='outlined'
              size='small'
              fullWidth
              required
              value={values?.categoryId}
              onChange={handleChange(valueConstants.categoryId)}
              label={f({ id: labelConstants.categoryId })}
              error={error && values.categoryId.length === 0}
              name='category'
              InputLabelProps={{ shrink: true }}
            >
              {categories?.map((cat, index) => (
                <MenuItem key={index} value={cat.id}>
                  {cat.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={6} xs={12} className={classes.gridItem}>
            <TextField
              id={valueConstants.minHeadCount}
              variant='outlined'
              size='small'
              type='number'
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              fullWidth
              required
              value={values.minHeadCount}
              onChange={handleChange(valueConstants.minHeadCount)}
              label={f({ id: labelConstants.minHeadCount })}
              error={error && values.minHeadCount.length === 0}
              name='minHeadCount'
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

BusinessDetails.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  error: PropTypes.bool,
  setValues: PropTypes.func,
  setBusinessDifferentEmail: PropTypes.func,
  differentBusinessEmail: PropTypes.bool,
  parsedAddress: PropTypes.object,
  setParsedAddress: PropTypes.func,
}

export default BusinessDetails
