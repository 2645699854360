import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Grid, makeStyles } from '@material-ui/core'
import searchIcon from '../../../../images/mobile_search.svg'
import { SearchCategory, SearchButton } from './initial-step'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    width: '100%',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    backgroundColor: '#fff',
    flexShrink: 0,
  },
  container: {
    overflow: 'scroll',
    flex: '1 50 auto',
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  left: {
    paddingRight: 4,
    paddingBottom: theme.spacing(),
  },
  right: {
    paddingLeft: 4,
    paddingBottom: theme.spacing(),
  },
}))

const InitialStep = ({ setStep, intl, categories, handleSearch }) => {
  const classes = useStyles()

  const orderMenus = useSelector(({ orders }) => orders.cart.orderMenus)

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <>
      <div className={classes.buttonBar}>
        <SearchButton
          fullWidth
          variant='outlined'
          className={classes.search}
          startIcon={<img src={searchIcon} alt='' height='22' />}
          onClick={() => setStep(1)}
        >
          {getLabel('search-label')}
        </SearchButton>
      </div>
      <div className={classes.container} style={{ paddingBottom: orderMenus.length > 0 ? 120 : 60 }}>
        <Grid container>
          {categories &&
            categories.map((category, index) => (
              <Grid
                item
                xs={6}
                key={category.id}
                className={clsx({ [classes.left]: index % 2 === 0, [classes.right]: index % 2 === 1 })}
              >
                <SearchCategory data={category} onSelected={() => handleSearch(category)} />
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  )
}

InitialStep.propTypes = {
  setStep: PropTypes.func,
  handleSearch: PropTypes.func,
  categories: PropTypes.array,
}

export default injectIntl(InitialStep)
