export const templateHeader = {
  'read-blog': 'Read Our Blog',
  'buy-gift-card': 'Buy Gift Card',
  'create-business-account': 'Create Business Account',
  'add-your-restaurant': 'Add Your Restaurant',
  'signup-to-delivery': 'Signup to Delivery',
  'read-faq': 'Read FAQs',
  'view-cities': 'View All Cities',
  'view-countries': 'View All Countries',
  'restaurants-near-me': 'Restaurants Near Me',
  'support-center': 'Supports Center',
  'privacy-policy': 'Privacy Policy',
  'terms-and-conditions': 'Terms and Conditions',
}
