import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'

import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grow from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import MeetAtDoor from './DeliveryPart/MeetAtDoor'
import DeliveryToDoor from './DeliveryPart/DeliveryToDoor'
import MeetOutSide from './DeliveryPart/MeetOutSide'
import DeliverySubList from '../../../../mobile-address-set/set-address/second-step/DeliverySubList'

import closeIcon from '../../../../../../images/close-icon.svg'

import { deliveryTypes } from '../../../../../../constants'
import { deliveryDetailsList } from '../../../../../../constants/set-address/second-step'

import { isMobileOnly } from 'react-device-detect'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    height: 'auto',
  },
  typeMain: {
    display: 'flex',
    justifyContent: 'center',
  },
  switchContainer: {
    width: 'fit-content',
    display: 'flex',
  },
  switch: {
    marginTop: -theme.spacing(),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.grey[500],
      borderRadius: 20 / 2,
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.grey[500],
        },
      },
    },
  },
  type: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
    transition: theme.transitions.create(['color']),
    "&[selected-type='true']": {
      color: theme.palette.primary.main,
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(),
    height: isMobileOnly ? 'auto' : 40,
  },
  textfield: {
    height: 44,
    marginBottom: theme.spacing(),
    '& .MuiInputBase-input': {
      color: '#707070',
    },
  },
  button: {
    width: '100%',
    fontSize: 15,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(198, 198, 198, 0.87)',
    textTransform: 'capitalize',
  },
  selectedButton: {
    color: 'rgba(112, 112, 112, 0.87)',
  },
  iconButton: {
    width: 30,
    height: 30,
  },
  stepTwoRow: {
    borderBottom: '1px solid #f7f7f7',
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
}))

const DeliveryPart = ({
  handleSwitch,
  handleSubType,
  subTyp,
  instruction,
  handleInstruction,
  typ,
  clearInstruction,
  details,
  setDetails,
}) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <div className={classes.root}>
      <div className={classes.typeMain}>
        <div className={classes.switchContainer}>
          <Typography className={classes.type} selected-type={typ === deliveryTypes.DELIVERY ? 'true' : 'false'}>
            {f({ id: 'delivery-label' })}
          </Typography>
          <Switch checked={typ === deliveryTypes.TAKEOUT} className={classes.switch} onChange={handleSwitch} />
          <Typography className={classes.type} selected-type={typ === deliveryTypes.TAKEOUT ? 'true' : 'false'}>
            {f({ id: 'pick-up' })}
          </Typography>
        </div>
      </div>
      <AnimateHeight duration={300} height={typ === deliveryTypes.DELIVERY ? (isMobileOnly ? 210 : 120) : 0}>
        {typ === deliveryTypes.DELIVERY && (
          <Grow in={typ === deliveryTypes.DELIVERY}>
            {isMobileOnly ? (
              <Grid item xs={12} className={classes.stepTwoRow} style={{ padding: 20, paddingTop: 10 }}>
                <Grid container>
                  {deliveryDetailsList.map((item, i) => (
                    <DeliverySubList item={item} details={details} setDetails={setDetails} key={item.label} />
                  ))}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={0} className={classes.buttonsContainer}>
                <DeliveryToDoor
                  subTyp={subTyp}
                  handleSubType={handleSubType}
                  button={classes.button}
                  selectedButton={classes.selectedButton}
                  f={f}
                />
                <MeetAtDoor
                  subTyp={subTyp}
                  handleSubType={handleSubType}
                  button={classes.button}
                  selectedButton={classes.selectedButton}
                  f={f}
                />
                <MeetOutSide
                  subTyp={subTyp}
                  handleSubType={handleSubType}
                  button={classes.button}
                  selectedButton={classes.selectedButton}
                  f={f}
                />
              </Grid>
            )}
          </Grow>
        )}
        {typ === deliveryTypes.DELIVERY && (
          <Grow in={typ === deliveryTypes.DELIVERY}>
            <TextField
              variant='outlined'
              value={instruction}
              margin='dense'
              id='name'
              autoComplete='instruction'
              label={f({ id: 'delivery-instructions' })}
              className={classes.textfield}
              InputLabelProps={{
                style: {
                  color: '#c4c4c4',
                },
              }}
              fullWidth
              onChange={(event) => handleInstruction(event)}
              inputProps={{
                maxLength: 50,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton className={classes.iconButton} onClick={clearInstruction}>
                      <img src={closeIcon} alt='close' height='14px' width='14px' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grow>
        )}
      </AnimateHeight>
    </div>
  )
}

DeliveryPart.propTypes = {
  handleSwitch: PropTypes.func,
  handleSubType: PropTypes.func,
  subType: PropTypes.string,
  instruction: PropTypes.string,
  handleInstruction: PropTypes.func,
  typ: PropTypes.string,
  clearInstruction: PropTypes.func,
  details: PropTypes.object,
  setDetails: PropTypes.func,
}

export default DeliveryPart
