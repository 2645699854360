import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TopNavbar from '../../top_navbar'
import Grid from '@material-ui/core/Grid'
import Footer from '../../Footers'
import Dialog from '@material-ui/core/Dialog'
import { GreenButton, Transition } from '../../../utils/tools'
import closeIcon from '../../../../images/close-icon.svg'
import newPaymentMethod from '../../../../images/payment-method-added-image.svg'
import clsx from 'clsx'
import noResponse from '../../../../images/no_response.svg'
import addIcon from '../../../../images/add-icon.svg'
import applePayDark from '../../../../images/applepay-darkgray.svg'
import paypal from '../../../../images/payments/paypal.svg'
import applepay from '../../../../images/payments/applepay.svg'
import paypalDark from '../../../../images/paypal-icon.svg'
import amazon from '../../../../images/amazon-icon.svg'
import { customerService } from '../../../../services/customer.service'
import { generalConstants } from '../../../../constants'
import withWidth from '@material-ui/core/withWidth'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { Elements } from '@stripe/react-stripe-js'
import InjectedCustomizedWebAddCard from '../../savecard/injected_customized_web_add_card'
import { loadStripe } from '@stripe/stripe-js'
import Loading from '../../../common/loading'
import Card from './card'
import TookFreshCredit from './tookfresh_credit'
import useWindowSize from '../../../../helpers/use_window_size'
import WebConfirmationDialog from '../../../common/web-confirmation-dialog'
import { customerActions } from '../../../../actions/customer.actions'
import { isTablet } from 'react-device-detect'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

const path = process.env.PUBLIC_URL
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
  },
  headerContainer: {
    minHeight: 160,
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: isTablet && theme.spacing(2),
  },
  headerText: {
    fontSize: 34,
    textAlign: 'left',
    lineHeight: 1.32,
    color: '#333333',
  },
  subHeader: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333333',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    paddingTop: 20,
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: generalConstants.minPageHeight,
  },
  rectangleRight: {
    borderRadius: 5,
    border: 'solid 0.5px #c4c4c4',
    backgroundColor: '#ffffff',
    height: 48,
    marginTop: 8,
    marginLeft: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '12px 0px 12px 12px',
  },
  rectTextContainer: {
    height: 21,
    width: isTablet ? '81.5%' : '72%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  girdThirdRight: {
    height: 90,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 30,
  },
  button: {
    textTransform: 'capitalize',
    paddingRight: 0,
    height: 32,
  },
  addIcon: {
    marginLeft: 8,
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.36,
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#707070',
    width: 'auto',
    height: 19,
    padding: 0,
  },
  dialogContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 360,
    maxWidth: 315,
  },
  dialogContentText: {
    marginTop: 30,
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    color: 'black',
  },
  dialogImageContainer: {
    width: '90%',
    marginLeft: '15%',
  },
  dialogContentText2: {
    fontWeight: 'normal',
    fontSize: 16,
    textAlign: 'center',
    color: '#333333',
  },
  dialogActions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogButton: {
    fontSize: 15,
    textTransform: 'capitalize',
    width: '100%',
    height: 48,
    borderRadius: 10,
    backgroundColor: '#69e781',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  paper: {
    width: '638px',
    height: ' 463px',
    padding: '20px 19px 40px 19px',
    backgroundColor: '#ffffff',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperDoneDialog: {
    width: '315px',
    height: ' 465px',
    padding: '22px 21.5px 40.5px',
    backgroundColor: '#ffffff',
    borderRadius: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 350,
    width: 498,
    padding: 0,
  },
  dialogIconButtonContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  dialogTextContainer: {
    width: '100%',
    height: 45,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    marginBottom: 53,
  },
  dialogText: {
    fontFamily: 'Roboto',
    fontSize: 34,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.18,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#333333',
    width: 'auto',
    padding: 0,
  },
  doneImageContainer: {
    width: '90%',
    marginLeft: '10%',
  },
  doneDialogHeaderContainer: {
    width: 240,
    height: 85,
    padding: 0,
  },
  doneDialogHeader: {
    marginTop: 30,
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    color: 'black',
  },
  doneDialogTextContainer: {
    width: 272,
    height: 43,
    marginTop: 7,
    marginBottom: 31.5,
    padding: 0,
  },
  doneDialogText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.38,
    textAlign: 'center',
    color: '#333333',
    padding: 0,
  },
  doneDialogButtonContainer: {
    width: 263,
    height: 48,
  },
  doneDialogButton: {
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.31,
    textAlign: 'center',
    color: '#ffffff',
    width: '100%',
  },
  buttonsContainer: {
    width: 263,
    height: 174,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  methodButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize',
    width: 263,
    height: 48,
    borderRadius: 10,
    border: 'solid 1px #707070',
    backgroundColor: '#ffffff',
  },
  methodButtonTextContainer: {
    width: 'auto',
    height: 21,
    marginLeft: 10.7,
  },
  methodButtonText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.31,
    textAlign: 'center',
    color: '#707070',
  },
  addButton: {
    textTransform: 'capitalize',
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 263,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 48,
    marginTop: 30,
  },
  stars: {
    marginRight: 10,
    marginTop: 5,
    fontWeight: 'bold',
    fontSize: 20,
    color: '#ffffff',
  },
  starsGrey: {
    marginRight: 10,
    marginTop: 5,
    fontWeight: 'bold',
    fontSize: 20,
    color: '#c4c4c4',
  },
  text: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: 'left',
    color: '#ffffff',
  },
  textRight: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: 'left',
    color: '#ffffff',
  },
  rectangleContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  cardInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    padding: theme.spacing(),
  },
  rightRect: {
    width: isTablet ? '80%' : '71.5%',
    height: '100%',
  },
  mainGrid: {
    width: '100%',
    minHeight: 600,
    height: 'auto',
    margin: 0,
    padding: 0,
    marginBottom: 29,
  },
  addButtonsContainer: {
    width: '100%',
    height: 48,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  deleteCardPaper: {
    width: '638px',
    height: '503px',
    padding: '20px 20px 34px 20px',
    backgroundColor: '#ffffff',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    flexGrow: 1,
    // paddingLeft: isTablet && theme.spacing(3),
    // paddingRight: isTablet && theme.spacing(3),
  },
})

const PaymentMethods = ({ classes, signedIn, intl, history, defaultCard }) => {
  const dispatch = useDispatch()
  const defaultCardChange = (id) => dispatch(customerActions.defaultCardChange(id))

  const [showCardDialog, setShowCardDialog] = useState(false)
  const [showMethodDialog, setShowMethodDialog] = useState(false)
  const [showDoneDialog, setShowDoneDialog] = useState(false)
  const [cards, setCards] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [errorDialog, setErrorDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [cardId, setCardId] = useState(null)
  const [userAccount, setUseAccount] = useState({})
  const size = useWindowSize()

  useEffect(() => {
    signedIn ? getPaymentMethods() : goToHome()
  }, [signedIn])

  useEffect(() => {
    getUserBalance()
  }, [])

  useEffect(() => {
    if (defaultCard !== selectPaymentMethod) {
      defaultCard && setSelectedPaymentMethod(defaultCard)
    }
  }, [defaultCard])

  const getUserBalance = async () => {
    await customerService.getUserBalance().then(
      (res) => {
        setUseAccount(res.data)
      },
      (err) => {
        // handle error here
      }
    )
  }

  const goToHome = () => history.push(`${path}/customer/food`)

  const getPaymentMethods = () => {
    setLoading(true)
    customerService.getPaymentMethods().then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setCards(res.data.paymentMethods)
          setSelectedPaymentMethod(res.data.defaultPaymentMethod)
          setLoading(false)
          defaultCardChange(res.data.defaultPaymentMethod)
        }
      },
      (err) => {
        console.log('err--', err)
      }
    )
  }

  const handleDeleteCard = (id) => {
    setDeleteDialog(false)
    setLoading(true)
    customerService.deletePaymentMethod(id).then(
      (res) => {
        res.status === generalConstants.STATUS_OK && getPaymentMethods()
      },
      (err) => {
        console.log('err--', err)
      }
    )
  }

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleCardDialogClose = () => {
    setShowCardDialog(false)
  }

  const handleMethodDialogClose = () => {
    setShowMethodDialog(false)
  }

  const handleDoneDialogOpen = () => {
    setShowDoneDialog(true)
  }

  const handleDoneDialogClose = () => {
    getPaymentMethods()
    setShowDoneDialog(false)
  }

  const handleErrorClose = () => {
    setErrorDialog(false)
  }

  const openDeleteDialog = (id) => {
    setDeleteDialog(true)
    setCardId(id)
  }

  const closeDeleteDialog = () => {
    setDeleteDialog(false)
  }

  const selectPaymentMethod = async (id) => {
    setLoading(true)
    await customerService.defaultPaymentMethod(id).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          setSelectedPaymentMethod(id)
          setLoading(false)
        }
      },
      (err) => {
        console.log('err--', err)
      }
    )
  }

  const starsPass = '* * * * * * * *'

  return (
    <div className={classes.root}>
      <Loading open={loading} />
      <div className={clsx('grow desktop full-width', [classes.root])}>
        <Container maxWidth='lg'>
          <TopNavbar cartVisible={true} />
        </Container>
        <div className={classes.headerContainer}>
          <Container maxWidth='lg' className={classes.header}>
            <Typography className={classes.headerText}>{getLabel('your-payment-methods')}</Typography>
          </Container>
        </div>
        <div className={classes.container}>
          <Container maxWidth='lg' className={classes.contentContainer}>
            <TookFreshCredit balance={userAccount?.balance} />
            <Grid container spacing={4} className={classes.mainGrid}>
              <Grid item xs={6}>
                {cards.length > 0 &&
                  cards?.map((card, index) => (
                    <Card
                      key={index}
                      data={card}
                      deleteCard={openDeleteDialog}
                      selectPaymentMethod={selectPaymentMethod}
                      selectedPaymentMethod={selectedPaymentMethod}
                    />
                  ))}
                <div className={classes.addButtonsContainer} style={{ width: isTablet ? '80%' : '71.5%' }}>
                  <Button disableRipple className={classes.button} onClick={() => setShowCardDialog(true)}>
                    <Typography className={classes.buttonText}>{getLabel('add-new-card')}</Typography>
                    <img src={addIcon} alt='add-icon' height='32px' width='32px' className={classes.addIcon} />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6}>
                {cards?.length > 0 ? (
                  <Grid container spacing={0} className={classes.girdThirdRight}>
                    <div className={classes.rightRect}>
                      <div className={classes.rectTextContainer}>
                        <Typography className={classes.subHeader}>{getLabel('paypal-label')}</Typography>
                      </div>
                      <div
                        className={classes.rectangleRight}
                        id='3'
                        style={{
                          backgroundColor: '#e0dedc',
                        }}
                      >
                        <div className={classes.rectangleContentContainer}>
                          <img src={paypal} alt='paypal' width='45px' height='45px' />
                          <div className={classes.cardInfoContainer}>
                            {size.width > 800 && (
                              <Typography variant='body1' className={classes.stars}>
                                {starsPass}
                              </Typography>
                            )}
                            <Typography variant='body1' className={classes.textRight} style={{ marginRight: 12 }}>
                              rg@gmail.com
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ) : null}
                {cards?.length > 0 ? (
                  <Grid container spacing={0} className={classes.girdThirdRight}>
                    <div className={classes.rightRect}>
                      <div className={classes.rectTextContainer}>
                        <Typography className={classes.subHeader}>{getLabel('applepay-label')}</Typography>
                      </div>
                      <div
                        className={classes.rectangleRight}
                        id='4'
                        style={{
                          backgroundColor: '#e0dedc',
                        }}
                      >
                        <div className={classes.rectangleContentContainer}>
                          <img src={applepay} alt='applepay' width='45px' height='45px' />
                          <div className={classes.cardInfoContainer}>
                            {size.width > 500 && (
                              <Typography variant='body1' className={classes.stars}>
                                {starsPass}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ) : null}
                <div className={classes.addButtonsContainer}>
                  <Button disabled disableRipple className={classes.button} onClick={() => setShowMethodDialog(true)}>
                    <Typography className={classes.buttonText}>{getLabel('add-new-method')}</Typography>
                    <img src={addIcon} alt='add-icon' height='32px' width='32px' className={classes.addIcon} />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className='grow' />
        <Footer />
      </div>
      <Dialog
        classes={{ paper: classes.deleteCardPaper }}
        open={deleteDialog}
        onClose={closeDeleteDialog}
        TransitionComponent={Transition}
      >
        <WebConfirmationDialog
          close={closeDeleteDialog}
          header={getLabel('are-you-sure')}
          text={getLabel('about-delete-card')}
          text2={false}
          function1={closeDeleteDialog}
          function2={handleDeleteCard}
          img={'deleteCardImage'}
          button1={getLabel('cancel-label')}
          button2={getLabel('delete-label')}
          id={cardId}
        />
      </Dialog>
      <Dialog
        classes={{ paper: classes.paper }}
        open={showCardDialog}
        onClose={handleCardDialogClose}
        TransitionComponent={Transition}
      >
        <div className={classes.dialogIconButtonContainer}>
          <IconButton onClick={() => handleCardDialogClose()}>
            <img src={closeIcon} alt='close-icon' height='20px' width='20px' />
          </IconButton>
        </div>
        <div className={classes.dialogContentContainer}>
          <div className={classes.dialogTextContainer}>
            <Typography className={classes.dialogText}>{getLabel('add-new-card')}</Typography>
          </div>
          <Elements stripe={promise}>
            <InjectedCustomizedWebAddCard handleClose={handleCardDialogClose} handleDoneDialog={handleDoneDialogOpen} />
          </Elements>
        </div>
      </Dialog>
      <Dialog
        classes={{ paper: classes.paper }}
        open={showMethodDialog}
        onClose={handleMethodDialogClose}
        TransitionComponent={Transition}
      >
        <div className={classes.dialogIconButtonContainer}>
          <IconButton onClick={() => handleMethodDialogClose()}>
            <img src={closeIcon} alt='close-icon' height='20px' width='20px' />
          </IconButton>
        </div>
        <div className={classes.dialogContentContainer}>
          <div className={classes.dialogTextContainer}>
            <Typography className={classes.dialogText}>{getLabel('add-new-method')}</Typography>
          </div>
          <div className={classes.buttonsContainer}>
            <Button disableRipple className={classes.methodButton}>
              <img src={paypalDark} alt='paypal-icon' width='34.1' height='26.5' />
              <div className={classes.methodButtonTextContainer}>
                <Typography className={classes.methodButtonText}>{getLabel('add-paypal-account')}</Typography>
              </div>
            </Button>
            <Button disableRipple className={classes.methodButton}>
              <img src={amazon} alt='paypal-icon' width='34.1' height='26.5' />
              <div className={classes.methodButtonTextContainer}>
                <Typography className={classes.methodButtonText}>{getLabel('add-amazonpay-account')}</Typography>
              </div>
            </Button>
            <Button disableRipple className={classes.methodButton}>
              <img src={applePayDark} alt='paypal-icon' width='34.1' height='26.5' />
              <div className={classes.methodButtonTextContainer}>
                <Typography className={classes.methodButtonText}>{getLabel('add-applepay-account')}</Typography>
              </div>
            </Button>
          </div>
          <div className={classes.buttonContainer}>
            <GreenButton className={classes.addButton} type='submit'>
              {getLabel('add-payment')}
            </GreenButton>
          </div>
        </div>
      </Dialog>
      <Dialog
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        style={{ width: '100%', padding: 10 }}
        PaperProps={{
          style: {
            borderRadius: 20,
            marginTop: 90,
            padding: 20,
            height: 465,
          },
        }}
        open={showDoneDialog}
        onClose={handleDoneDialogClose}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.dialogContent}>
          <div className={classes.doneImageContainer}>
            <img src={newPaymentMethod} alt='new-payment-method-added' height='192px' width='212.2px' />
          </div>
          <DialogContentText id='alert-dialog-description' className={classes.dialogContentText}>
            {getLabel('success-label')}
          </DialogContentText>
          <DialogContentText id='alert-dialog-description' className={classes.dialogContentText2}>
            {getLabel('new-payment-method-added')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleDoneDialogClose} className={classes.dialogButton}>
            {getLabel('continue-label')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        style={{ width: '100%', padding: 10 }}
        PaperProps={{
          style: {
            borderRadius: 20,
            marginTop: 90,
            padding: 20,
            height: 465,
          },
        }}
        open={errorDialog}
        onClose={handleErrorClose}
        TransitionComponent={Transition}
      >
        <DialogContent className={classes.dialogContent}>
          <div className={classes.doneImageContainer}>
            <img src={noResponse} alt='no response' height='150px' width='279px' />
          </div>
          <DialogContentText id='alert-dialog-description' className={classes.dialogContentText}>
            {getLabel('no-response-received')}
          </DialogContentText>
          <DialogContentText id='alert-dialog-description' className={classes.dialogContentText2}>
            {getLabel('not-add-card')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleErrorClose} className={classes.dialogButton}>
            {getLabel('continue-label')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { customer } = state

  return {
    signedIn: customer.signedIn,
    customer: customer.customerInfo,
    defaultCard: customer.defaultCard,
  }
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, null)(withWidth()(PaymentMethods)))))
