import React from 'react'
import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    marginLeft: theme.spacing(2),
  },
}))

export const SelectStore = ({ stores, selectedStore, handleChange }) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor='age-native-simple'>Store</InputLabel>
      <Select
        native
        value={selectedStore?.storeId}
        onChange={handleChange}
        inputProps={{
          name: 'store',
          id: 'store-native-simple',
        }}
      >
        {stores
          .sort((a, b) => {
            return a.storeNickName > b.storeNickName ? 1 : -1
          })
          .map((store) => (
            <option key={store.storeId} value={store.storeId}>
              {store.storeNickName}
            </option>
          ))}
      </Select>
    </FormControl>
  )
}
