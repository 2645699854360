import React from 'react'
import { useSelector } from 'react-redux'
import { SelectRestaurant } from './restaurant-admin/SelectRestaurant'
import { Name } from './restaurant-admin/Name'
import { SelectStore } from './restaurant-admin/SelectStore'
import { Grid } from '@material-ui/core'
import { SelectView } from './restaurant-admin/SelectView'
import { useMerchantHomeContext } from '../../../../../context/merchant/MerchantHome/main'

export const RestAdminView = (props) => {
  const {
    values: { isRestaurantAdmin },
    setters: { handleStoreChange },
  } = useMerchantHomeContext()

  const restaurants = useSelector(({ merchantStores }) => merchantStores.business)
  const stores = useSelector(({ merchantStores }) => merchantStores.stores)
  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const isRestaurant = useSelector(({ merchantStores }) => merchantStores.isRestaurantView)

  if (!isRestaurantAdmin) return null

  return (
    <>
      <Grid item xs={12}>
        {restaurants?.length > 1 ? <SelectRestaurant /> : <Name name={restaurants && restaurants[0]?.restaurantName} />}
        {stores?.length > 0 && <SelectView />}
      </Grid>
      {!isRestaurant && (
        <Grid item xs={12}>
          {stores.length > 1 ? (
            <SelectStore stores={stores} selectedStore={selectedStore} handleChange={handleStoreChange} />
          ) : (
            <Name name={selectedStore?.storeNickName} />
          )}
        </Grid>
      )}
    </>
  )
}
