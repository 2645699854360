import FavoriteIcon from '@material-ui/icons/Favorite'
import AddressIcon from '@material-ui/icons/LocationOn'
import PaymentIcon from '@material-ui/icons/Payment'
import InviteIcon from '@material-ui/icons/HowToReg'
import SignoutIcon from '@material-ui/icons/ExitToApp'
import AboutIcon from '@material-ui/icons/TouchApp'

export const accountMenuItems = [
  {
    icon: <FavoriteIcon />,
    onClickPath: 'favorite-restaurants',
    label: 'favorites-label',
    bottomDivider: true,
  },
  {
    icon: <PaymentIcon />,
    onClickPath: 'payment-methods',
    label: 'payment-label',
    bottomDivider: true,
  },
  {
    icon: <AddressIcon />,
    onClickPath: 'addresses',
    label: 'addresses-label',
    bottomDivider: true,
  },
  // {
  //   icon: <InviteIcon />,
  //   onClickPath: 'invite-friends',
  //   label: 'invite-friends',
  //   bottomDivider: true,
  // },
  {
    icon: <SignoutIcon />,
    onClickPath: null,
    label: 'sign-out',
    bottomDivider: true,
  },
  {
    icon: <AboutIcon />,
    onClickPath: 'about-us',
    label: 'about-label',
    bottomDivider: true,
  },
]
