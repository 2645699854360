import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'

import { GreenRadio } from '../../../../utils/tools'

import { valueConstants, labelConstants } from '../../../../businessRegister/businessConstants'

const useStyles = makeStyles(() => ({
  label: {
    maxWidth: 150,
  },
}))

const RadioArea = ({ bankInfo, handlePaymentMethod, editting }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <Grid container spacing={2} className='full-width'>
      <Grid item md={4} sm={4} xs={12}>
        <FormControl fullWidth>
          <FormControlLabel
            className={classes.label}
            control={
              <GreenRadio
                checked={bankInfo.paymentMethod === valueConstants.directDeposit}
                onChange={handlePaymentMethod}
                value={valueConstants.directDeposit}
                disabled={!editting}
              />
            }
            label={<Typography>{f({ id: labelConstants.directDeposit })}</Typography>}
          />
        </FormControl>
      </Grid>
      <Grid item md={4} sm={4} xs={12}>
        <FormControl fullWidth>
          <FormControlLabel
            className={classes.label}
            control={
              <GreenRadio
                checked={bankInfo.paymentMethod === valueConstants.check}
                onChange={handlePaymentMethod}
                value={valueConstants.check}
                disabled={!editting}
              />
            }
            label={<Typography>{f({ id: labelConstants.check })}</Typography>}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

RadioArea.propTypes = {
  bankInfo: PropTypes.object,
  handlePaymentMethod: PropTypes.func,
  editting: PropTypes.bool,
}

export default RadioArea
