import React from 'react'
import PropTypes from 'prop-types'
import { Box, CardMedia, makeStyles } from '@material-ui/core'
import { getImage } from '../../../helpers/get-image'
import backgroundPlaceholder from '../../../images/restaurant-back.jpeg'

const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    height: 120,
    backgroundColor: '#f4f4f4',
    borderRadius: 6,
    // "&[store-open='false']": {
    //   opacity: '0.5',
    // },
  },
}))

const StoreImage = ({ data, storeOpen }) => {
  const classes = useStyles()
  const image = getImage(data.restaurant?.background, 'thumb') ?? backgroundPlaceholder
  return (
    <Box width={'30%'}>
      <CardMedia
        className={classes.media}
        component='img'
        alt={data.nickname}
        image={image}
        title={data.nickname}
        store-open={(!!storeOpen).toString()}
      />
    </Box>
  )
}

StoreImage.propTypes = {
  data: PropTypes.object,
  storeOpen: PropTypes.bool,
}

export default StoreImage
