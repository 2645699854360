import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import { CustomFormatCurrency } from '../../../../../utils/tools'

const TierRow = ({ tierNum, tier, handleChange, editting, isInvalid, isFocused }) => {
  const { formatMessage: f } = useIntl()
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          size='small'
          variant='outlined'
          required
          fullWidth
          id='deliveryFee'
          name='deliveryFee'
          label={f({ id: 'delivery-fee' })}
          value={tier.deliveryFee}
          onChange={handleChange(tierNum)}
          inputProps={{
            maxLength: 150,
            readOnly: !editting,
          }}
          InputProps={{
            inputComponent: CustomFormatCurrency,
          }}
          InputLabelProps={{ shrink: true }}
          error={isFocused.deliveryFee && isInvalid.deliveryFee}
          helperText={isFocused.deliveryFee && isInvalid.deliveryFee ? f({ id: 'cannot-null' }) : ' '}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          size='small'
          variant='outlined'
          required
          fullWidth
          id='minorder'
          name='minOrder'
          label={f({ id: 'min-order-price' })}
          value={tier.minOrder}
          onChange={handleChange(tierNum)}
          inputProps={{
            maxLength: 150,
            readOnly: !editting,
          }}
          InputProps={{
            inputComponent: CustomFormatCurrency,
          }}
          InputLabelProps={{ shrink: true }}
          error={isFocused.minOrder && isInvalid.minOrder}
          helperText={isFocused.minOrder && isInvalid.minOrder ? f({ id: 'cannot-null' }) : ' '}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          size='small'
          variant='outlined'
          required
          fullWidth
          id='maxDistance'
          name='maxDistance'
          label={f({ id: 'max-delivery-distance' })}
          value={tier.maxDistance}
          onChange={handleChange(tierNum)}
          inputProps={{
            maxLength: 150,
            readOnly: !editting,
            type: 'number',
          }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>mi</InputAdornment>,
          }}
          error={isFocused.maxDistance && isInvalid.maxDistance}
          helperText={isFocused.maxDistance && isInvalid.maxDistance ? f({ id: 'cannot-null' }) : ' '}
        />
      </Grid>
    </Grid>
  )
}

TierRow.propTypes = {
  tierNum: PropTypes.number,
  tier: PropTypes.object,
  handleChange: PropTypes.func,
  editting: PropTypes.bool,
  isInvalid: PropTypes.object,
  isFocused: PropTypes.object,
}

export default TierRow
