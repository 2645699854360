import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const fontProps = {
  fontSize: 16,
  fontWeight: 'bold',
}
const useStyles = makeStyles(() => ({
  grid: {
    paddingLeft: 5,
    paddingRight: 10,
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  name: {
    ...fontProps,
    color: '#0d0d0d',
  },
  promo: {
    ...fontProps,
  },
}))

const WebPromo = ({ promotion }) => {
  const classes = useStyles()
  return (
    <Grid container direction='row' alignItems='center' className={classes.grid}>
      <Grid item>
        <Typography className={classes.promo} color='primary'>
          <FormattedMessage id='promo-label' />
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.name} color='textPrimary'>
          {promotion?.name}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default React.memo(WebPromo)
