import { deliveryTypes } from '../../constants'

export const getRatingQuestions = (order, questions) => {
  const orderTypesToBeOmitted = {
    [deliveryTypes.DELIVERY]: deliveryTypes.TAKEOUT,
    [deliveryTypes.TAKEOUT]: deliveryTypes.DELIVERY,
  }
  const orderType = order.orderType
  const relatedQuestions = questions.filter((question) => question.ratingOrderType !== orderTypesToBeOmitted[orderType])
  return relatedQuestions
}
