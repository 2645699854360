import React, { useEffect } from 'react'
import Food from './food'
import MobileFood from './MobileFood'
import { useDispatch } from 'react-redux'
import { bottomNavItems } from '../../../constants'
import { isMobileOnly } from 'react-device-detect'
import { makeStyles } from '@material-ui/core'
import { setMainMenu } from '../../../actions/general.actions'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
}))

const FoodMain = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const setMenu = (menu) => dispatch(setMainMenu(menu))

  useEffect(() => {
    setMenu(bottomNavItems.FOOD)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderMobile = () => (
    <div className='mobile'>
      <MobileFood />
    </div>
  )
  const renderDesktop = () => (
    <div className='desktop full-height'>
      <Food />
    </div>
  )

  return <div className={classes.root}>{isMobileOnly ? renderMobile() : renderDesktop()}</div>
}

export default FoodMain
