import React from 'react'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontWeight: 400,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 30,
    },
  },
  text: {
    color: '#707070',
    fontWeight: 300,
    width: '80%',
    lineHeight: '2em',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}))

const InitialText = (props, ref) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  return (
    <div className={classes.root} ref={ref}>
      <Typography variant='h2' align='center' className={classes.header}>
        Welcome To <b>Took</b>Fresh
      </Typography>
      <Typography variant='h5' align='center' className={classes.text}>
        {f({ id: 'few-more-steps' })}
      </Typography>
    </div>
  )
}

export default React.forwardRef(InitialText)
