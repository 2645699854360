import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { AppBar, Box, IconButton, Typography } from '@material-ui/core'
import close from '../../images/close.svg'

const StickyHeader = ({ intl, callback, title }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <AppBar
      position='sticky'
      color='inherit'
      elevation={0}
      style={{ backgroundColor: '#fff', borderTopLeftRadius: 24, borderTopRightRadius: 24 }}
    >
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        style={{ borderBottom: '1px solid #ededed', height: 75 }}
      >
        <IconButton></IconButton>

        <Typography variant='h5'>{getLabel(title)}</Typography>

        <IconButton color='inherit' onClick={callback} aria-label='close'>
          <img src={close} height='22' width='22' alt='Back' />
        </IconButton>
      </Box>
    </AppBar>
  )
}

StickyHeader.propTypes = {
  callback: PropTypes.func,
  title: PropTypes.string,
}

export default injectIntl(StickyHeader)
