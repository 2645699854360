import React from 'react'
import { useIntl } from 'react-intl'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core'
import { Transition, BlackButton, RedButton } from '../../../../utils/tools'

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
}))

const ImageDeleteConfirmationDialog = ({ index, open, onClose, onDelete }) => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  const logoOrBackgroundText = { 0: 'logo', 1: 'background image' }
  return (
    <Dialog classes={{ paper: classes.dialog }} open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle id='delete-dialog-title'>{f({ id: 'are-you-sure' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {f({ id: 'about-to-delete {image_}' }, { image_: logoOrBackgroundText[index] })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BlackButton onClick={onClose}>{f({ id: 'cancel-label' })}</BlackButton>
        <RedButton onClick={onDelete}>{f({ id: 'delete-label' })}</RedButton>
      </DialogActions>
    </Dialog>
  )
}

export default ImageDeleteConfirmationDialog
