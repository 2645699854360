import { campaignConstants } from '../constants'
import { campaignService } from '../services'
import { alertActions } from './alert.actions'

export const campaignActions = {
  getAllCampaigns,
}

function getAllCampaigns() {
  return (dispatch) => {
    dispatch(request())
    campaignService.getCamapignTypes().then(
      (res) => {
        dispatch(success(res.data.content))
      },
      (error) => {
        dispatch(failure(error.toString()))
        dispatch(alertActions.error(error.toString()))
      }
    )
  }

  function request() {
    return { type: campaignConstants.CAMPAIGN_REQUEST }
  }
  function success(data) {
    return { type: campaignConstants.CAMPAIGN_SUCCESS, data }
  }
  function failure() {
    return { type: campaignConstants.CAMPAIGN_FAILURE }
  }
}
