import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

import { firstTier, valueConstants } from '../../../businessConstants'

const FirstTier = ({ classes, handleChange, thirdTierState, secondTierState, addTier, values }) => {
  const { formatMessage: f } = useIntl()

  let disableCondition =
    values.firstTierDeliveryFee === '' || values.firstTierMinOrder === '' || values.firstTierMaxDistance === ''

  return (
    <div className={classes.tier}>
      <Grid container spacing={2} className='full-width'>
        {firstTier.map((tier, index) => (
          <Grid key={index} item md={4} sm={6} xs={12} className={classes.gridItem}>
            <TextField
              size='small'
              value={values[tier.value]}
              variant='outlined'
              fullWidth
              id={tier.value}
              InputLabelProps={{ shrink: true }}
              label={f({ id: tier.label })}
              onChange={handleChange(tier.value)}
              disabled={secondTierState || thirdTierState}
              InputProps={{
                inputProps: {
                  type: 'number',
                  min: 0,
                  maxLength: 100,
                },
                startAdornment: tier.value !== valueConstants.firstTierMaxDistance && (
                  <InputAdornment position='start' disablePointerEvents>
                    <Typography className={classes.startAdornment}>$</Typography>
                  </InputAdornment>
                ),
                endAdornment: tier.value === valueConstants.firstTierMaxDistance && (
                  <InputAdornment position='end' disablePointerEvents>
                    <Typography className={classes.endAdornment}>({f({ id: 'miles-label' })})</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ))}
      </Grid>
      {!thirdTierState && !secondTierState && (
        <div className={classes.buttonContainer}>
          <Button
            disabled={disableCondition}
            disableRipple
            variant='contained'
            color='primary'
            onClick={addTier}
            className={classes.button}
          >
            {f({ id: 'add-label' })}
          </Button>
        </div>
      )}
    </div>
  )
}

FirstTier.propTypes = {
  classes: PropTypes.object,
  handleChange: PropTypes.func,
  thirdTierState: PropTypes.bool,
  secondTierState: PropTypes.bool,
  addTier: PropTypes.func,
  values: PropTypes.object,
}

export default FirstTier
