import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { firstTier, valueConstants } from '../../Helpers'
import TierField from '../../Fields/TierField_'

const FirstTier = ({ classes, handleChange, thirdTierState, secondTierState, addTier, values }) => {
  const { formatMessage: f } = useIntl()

  let disableCondition =
    values.firstTierDeliveryFee === '' || values.firstTierMinOrder === '' || values.firstTierMaxDistance === ''

  return (
    <div className={classes.tier}>
      <Grid container spacing={2} className='full-width'>
        {firstTier.map((tier, index) => (
          <Grid key={index} item md={4} sm={6} xs={12} className={classes.gridItem}>
            <TierField
              prop={tier.value}
              gridVal={12}
              handleChange={handleChange}
              startAdornment_={
                tier.value === valueConstants.firstTierDeliveryFee || tier.value === valueConstants.firstTierMinOrder
              }
              endAdornment_={tier.value === valueConstants.firstTierMaxDistance}
              values={values}
              disabled={secondTierState || thirdTierState}
            />
          </Grid>
        ))}
      </Grid>
      {!thirdTierState && !secondTierState && (
        <div className={classes.buttonContainer}>
          <Button
            disabled={disableCondition}
            disableRipple
            variant='contained'
            color='primary'
            onClick={addTier}
            className={classes.button}
          >
            {f({ id: 'add-label' })}
          </Button>
        </div>
      )}
    </div>
  )
}

FirstTier.propTypes = {
  classes: PropTypes.object,
  handleChange: PropTypes.func,
  thirdTierState: PropTypes.bool,
  secondTierState: PropTypes.bool,
  addTier: PropTypes.func,
  values: PropTypes.object,
}

export default FirstTier
