import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import MinPrice from '../../common/MinPrice'
import MaxDelivery from '../../common/MaxDelivery'

const SenpexDelivery = ({ store, isFocused, isInvalid, setIsFocused, setIsInvalid, setStore, editting }) => {
  const minOrderHandler = (e) => {
    setIsFocused({ ...isFocused, minOrderPrice: true })
    setStore({ ...store, minOrderPrice: +e.target.value })
    if (e.target.value > 0) {
      setIsInvalid({ ...isInvalid, minOrderPrice: !e.target.value })
    } else {
      setIsInvalid({ ...isInvalid, minOrderPrice: true })
    }
  }

  const maxDeliveryHandler = (e) => {
    setIsFocused({ ...isFocused, maxDeliveryDistance: true })
    setStore({ ...store, maxDeliveryDistance: +e.target.value })
    if (e.target.value > 0) {
      setIsInvalid({ ...isInvalid, maxDeliveryDistance: !e.target.value })
    } else {
      setIsInvalid({ ...isInvalid, maxDeliveryDistance: true })
    }
  }
  return (
    <Grid container spacing={2} className='full-width'>
      <MinPrice
        store={store}
        isFocused={isFocused}
        isInvalid={isInvalid}
        minOrderHandler={minOrderHandler}
        editting={editting}
      />
      <MaxDelivery
        store={store}
        isFocused={isFocused}
        isInvalid={isInvalid}
        maxDeliveryHandler={maxDeliveryHandler}
        editting={editting}
      />
    </Grid>
  )
}

SenpexDelivery.propTypes = {
  store: PropTypes.object,
  setStore: PropTypes.func,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  setIsFocused: PropTypes.func,
  setIsInvalid: PropTypes.func,
  editting: PropTypes.bool,
}

export default SenpexDelivery
