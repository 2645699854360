import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontSize: 30,
    lineHeight: 1.3,
  },
  desc: {
    fontSize: 16,
    paddingTop: theme.spacing(1),
  },
  justify: {
    textAlign: 'justify',
  },
}))

const WhoWeAre = ({ web }) => {
  const classes = useStyles()
  return (
    <Grid className={classes.container}>
      <Typography variant='h5' className={classes.title}>
        <FormattedMessage id='who-we-are' />
      </Typography>
      <Typography variant='body1' className={clsx(classes.desc, { [classes.justify]: web })}>
        <FormattedMessage id='aboutus-first-p' />
      </Typography>
      <Typography variant='body1' className={clsx(classes.desc, { [classes.justify]: web })}>
        <FormattedMessage
          id='aboutus-second-p'
          values={{
            br: <br />,
          }}
        />
      </Typography>
      <Typography variant='body1' className={clsx(classes.desc, { [classes.justify]: web })}>
        <FormattedMessage id='aboutus-third-p' />
      </Typography>
    </Grid>
  )
}

WhoWeAre.propTypes = {
  // second: PropTypes.third,
}

export default React.memo(WhoWeAre)
