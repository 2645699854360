import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import GoogleMapReact from 'google-map-react'
import HomeMarkerComponent from './HomeMarker'
import MarkerComponent from './Marker'
import deliveryTimeIcon from '../../../../images/delivery-time.svg'
import rate from '../../../../images/rate.svg'
import addressIcon from '../../../../images/store-address-more.svg'
import locationIcon from '../../../../images/pickup_location.svg'
import clsx from 'clsx'
import { getImage } from '../../../../helpers/get-image'
import PhoneIcon from '@material-ui/icons/Phone'
import { SearchFab } from '../../pickup/mobile-pickup/SearchFab'
import { useSelector } from 'react-redux'
import { deliveryTimes, deliveryTypes } from '../../../../constants'

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY

function StoreMoreHeader({ store, classes, center, zoom, onMapChange, searchQuery, reCenter, storeOpen }) {
  const deliveryType = useSelector(({ search }) => search.searchQuery.deliveryType)
  const deliveryTime = useSelector(({ search }) => search.searchQuery.deliveryTime)

  const isDelivery = deliveryType === deliveryTypes.DELIVERY
  const isASAP = deliveryTime === deliveryTimes.ASAP

  return (
    <Grid container>
      <Grid item xs={12} className={classes.banner}>
        <div className={clsx('column', [classes.banner])} style={{ position: 'relative' }}>
          <SearchFab color='primary' aria-label='location' onClick={reCenter} className={classes.reCenter}>
            <img src={locationIcon} alt='' />
          </SearchFab>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
            center={center}
            zoom={zoom}
            options={{ fullscreenControl: false, zoomControl: false }}
            onChange={onMapChange}
          >
            <HomeMarkerComponent lat={searchQuery.address.latitude} lng={searchQuery.address.longitude} />
            <MarkerComponent
              lat={store.storeInformation.address.latitude}
              lng={store.storeInformation.address.longitude}
              data={store}
            />
          </GoogleMapReact>
        </div>
        <div style={{ position: 'absolute', bottom: 0 }}>
          <div className={clsx('row', [classes.logoRow])}>
            {store?.restaurant?.logo && (
              <Box boxShadow={2} className={classes.paper}>
                <img src={getImage(store?.restaurant?.logo, 'thumb')} alt='restaurant-logo' height='122' width='122' />
              </Box>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.nameRow}>
        <Typography className={clsx(classes.storeName, { [classes.storeNameLong]: store.nickname.length > 27 })} noWrap>
          {store.nickname}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.addressRow}>
        <div className={clsx('row', [classes.iconContainer])}>
          {store.ratingSummary && (
            <div className={clsx('centered-row', [classes.rateIcon])} style={{ marginRight: 10 }}>
              <img src={rate} alt='Rate' width='20' className={classes.icon} style={{ marginBottom: 3 }} />
              <Typography className={classes.storeDetail}>
                {store.ratingSummary.averageRating?.toFixed(1)} ({store.ratingSummary.totalRatingCount})
              </Typography>
            </div>
          )}
          {storeOpen && isDelivery && isASAP && (
            <div className='centered-row'>
              <img src={deliveryTimeIcon} alt='Delivery time' width='17' className={classes.icon} />
              <Typography className={classes.storeDetail}>{store.deliveryTime} mins</Typography>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={12} className={classes.addressRow}>
        <div className='centered-row'>
          <PhoneIcon style={{ fontSize: 16 }} />
          <Typography className={classes.storeDetail}>{store.storeInformation.phone}</Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.addressRow}>
        <div className='row'>
          <img src={addressIcon} alt='' width='13' height='16' style={{ marginRight: 5 }} />
          <Typography variant='h6'>{store.storeInformation.address.formattedAddress}</Typography>
        </div>
      </Grid>
    </Grid>
  )
}

StoreMoreHeader.propTypes = {
  searchQuery: PropTypes.object,
  onMapChange: PropTypes.func,
  zoom: PropTypes.number,
  center: PropTypes.object,
  classes: PropTypes.object,
  store: PropTypes.object,
  reCenter: PropTypes.func,
  storeOpen: PropTypes.bool,
}

export default StoreMoreHeader
