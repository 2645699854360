import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'

import DeliveryTypeFilter from '../../food/filters/DeliveryTypeFilter'

const useStyles = makeStyles(() => ({
  howPopover: {
    width: 320,
    height: 450,
  },
}))

const HowPopover = ({ showHowPopover, anchorEl, closeHowPopover, handleUpdateAddress }) => {
  const classes = useStyles()
  return (
    <Popover
      id='how-popover'
      open={showHowPopover}
      anchorEl={anchorEl}
      onClose={closeHowPopover}
      classes={{ paper: classes.howPopover }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <DeliveryTypeFilter handleClose={closeHowPopover} web='true' handleUpdateAddress={handleUpdateAddress} />
    </Popover>
  )
}

HowPopover.propTypes = {
  showHowPopover: PropTypes.bool,
  anchorEl: PropTypes.object,
  closeHowPopover: PropTypes.func,
  handleUpdateAddress: PropTypes.func,
}

export default HowPopover
