import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import InputMask from 'react-input-mask'

const TaxInput = ({ intl, isFocused, isInvalid, callback, value, label, text, editting }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const standartError = isFocused.taxIdNumber && isInvalid.taxIdNumber
  const extendedError = standartError || (isFocused.taxIdNumber && value.length < 10)
  return (
    <InputMask mask='99-9999999' maskChar=' ' value={value} onChange={(e) => callback(e)}>
      {() => (
        <TextField
          size='small'
          variant='outlined'
          fullWidth
          required
          id={label}
          InputLabelProps={{ shrink: true }}
          label={getLabel(label)}
          error={extendedError}
          helperText={extendedError ? getLabel(`enter-${text}`) : ' '}
          inputProps={{
            readOnly: !editting,
          }}
        />
      )}
    </InputMask>
  )
}

TaxInput.propTypes = {
  intl: PropTypes.object,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  callback: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  editting: PropTypes.bool,
}

export default injectIntl(TaxInput)
