import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import Slider from 'react-slick'
import backIcon from '../../../../../images/back.svg'
import { getImage } from '../../../../../helpers/get-image'

const bannerHeight = 250

const useStyles = makeStyles((theme) => ({
  banner: {
    width: '100%',
    height: bannerHeight,
    position: 'relative',
    backgroundColor: '#e6e6e6',
  },
  category: {
    position: 'absolute',
    zIndex: 2,
    fontSize: 40,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textShadow: '2px 2px 4px #000000',
  },
  leftBackButton: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,0.5)',
    top: 5,
    left: 5,
  },
}))

const Banner = ({ category, goBack }) => {
  const classes = useStyles()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          position: 'absolute',
          bottom: 20,
        }}
      >
        <ul style={{ margin: '0px' }}>{dots}</ul>
      </div>
    ),
  }
  return (
    <div className={classes.banner}>
      <Typography variant='h6' gutterBottom className={classes.category}>
        {category.name}
      </Typography>
      <IconButton
        color='inherit'
        className={clsx('mobile', [classes.leftBackButton])}
        onClick={goBack}
        aria-label='close'
      >
        <img src={backIcon} alt='back' />
      </IconButton>
      <Slider {...settings}>
        {category?.images.map((image, index) => (
          <img src={getImage(image, 'thumb')} height='250' width='100%' alt='' key={index} /> //thumb: 281kb, medium:1.1mb
        ))}
      </Slider>
    </div>
  )
}

Banner.propTypes = {
  category: PropTypes.object,
  goBack: PropTypes.func,
}

export default Banner
