import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import deliveryTimeIcon from '../../../../images/white-delivery-time.svg'
// import deliveryFee from '../../../../images/white-delivery-fee.svg'
import rate from '../../../../images/white-rate.svg'
import back from '../../../../images/black-back.svg'
import backgroundPlaceholder from '../../../../images/restaurant-back.jpeg'
import notFavoritedIcon from '../../../../images/gray-favorite.svg'
import favoritedIcon from '../../../../images/green-favorite.svg'
import priceFilter from '../../../../helpers/price-icons'
import { customerActions } from '../../../../actions'
import { getImage } from '../../../../helpers/get-image'
import { FormattedMessage } from 'react-intl'
import SlidingSectionRow from './SlidingSectionRow'
import { deliveryTypes, deliveryTimes } from '../../../../constants'

const useStyles = makeStyles((theme) => ({
  banner: {
    width: '100%',
    height: 'auto',
  },
  logoRow: {
    marginBottom: 0,
    height: 122,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
  },
  paper: {
    width: 122,
    height: 122,
    marginTop: 10,
    backgroundColor: '#fff',
  },
  storeDetails: {
    paddingLeft: theme.spacing(),
    justifyContent: 'space-evenly',
    filter: 'drop-shadow(1px 1px 2px black)',
  },
  icon: {
    filter: 'drop-shadow(1px 1px 2px black)',
  },
  storeDetail: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#fff',
    // textShadow: '2px 2px 4px #000000',
    filter: 'drop-shadow(1px 1px 2px black)',
    marginLeft: 5,
    marginRight: 5,
  },
  more: {
    textTransform: 'capitalize',
    filter: 'drop-shadow(1px 1px 2px black)',
    height: 34,
    color: '#fff',
    marginTop: 'auto',
    marginBottom: theme.spacing(0),
  },
  nameRow: {
    paddingTop: 5,
    height: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
  },
  storeName: {
    fontSize: 28,
    // fontSize: '2.75vw',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.18,
    letterSpacing: 'normal',
    color: '#000',
    marginTop: theme.spacing(2),
  },
  minHeadCount: {
    fontSize: 20,
    // fontSize: '2.75vw',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.18,
    letterSpacing: 'normal',
    color: '#000',
    marginTop: theme.spacing(),
  },
  storeNameLong: {
    fontSize: 24,
  },
  sections: {
    height: 36,
  },
  closedContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  closedBox: {
    width: '97%',
    height: 'auto',
    backgroundColor: 'rgb(253,236,234)',
    color: 'rgb(97, 26, 21)',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 5,
    paddingLeft: 10,
    marginTop: theme.spacing(),
  },
  backButton: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    margin: '5px 0px 0px 5px',
  },
  favorite: {
    height: 40,
    width: 40,
    filter: 'drop-shadow(1px 1px 10px black)',
    marginRight: 5,
  },
  categoryContainer: {
    paddingTop: 5,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(),
    paddingBottom: 5,
  },
}))

export const TopInfo = ({
  store,
  background,
  goBackHandler,
  logo,
  handleSectionSelect,
  selectedCategories,
  selectedSections,
  handleMoreShow,
  storeOpened,
  id,
  handleSigninModalOpen,
  sections,
}) => {
  const image = getImage(background, 'thumb')
  const classes = useStyles()
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()

  const [isFavorite, setIsFavorite] = useState(false)

  //Redux store
  const favorites = useSelector(({ customer }) => customer.favoriteStores)
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const deliveryType = useSelector(({ search }) => search.searchQuery.deliveryType)
  const deliveryTime = useSelector(({ search }) => search.searchQuery.deliveryTime)

  const isDelivery = deliveryType === deliveryTypes.DELIVERY
  const isASAP = deliveryTime === deliveryTimes.ASAP

  //Actions
  const saveFavorite = (id) => dispatch(customerActions.saveFavoriteStore(id))
  const deleteFavorite = (id) => dispatch(customerActions.deleteFavoriteStore(id))

  useEffect(() => {
    signedIn && favorites?.some((favorite) => favorite.entityId === +id) ? setIsFavorite(true) : setIsFavorite(false)
  }, [favorites, id])

  const handleFavoriteIconClick = () => {
    if (!signedIn) {
      handleSigninModalOpen()
    } else {
      let includes = false
      let storeId
      if (favorites?.length > 0) {
        favorites.forEach((store) => {
          store.entityId === +id && ([includes, storeId] = [true, store.id])
        })
      }
      includes ? deleteFavorite(storeId) : saveFavorite(id)
    }
  }

  return (
    <Grid item xs={12}>
      {store && (
        <div
          className={clsx('column', [classes.banner])}
          style={{
            backgroundImage: image ? `url(${image})` : `url(${backgroundPlaceholder})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className='flex-container flex-between centered-row'>
            <IconButton className={classes.backButton} onClick={goBackHandler} aria-label='close'>
              <img src={back} height='22' width='22' alt='Back' />
            </IconButton>
            <IconButton className={classes.favorite} onClick={handleFavoriteIconClick}>
              <img src={isFavorite ? favoritedIcon : notFavoritedIcon} alt='' />
            </IconButton>
          </div>
          <div className='grow' />
          <div className={clsx('row', [classes.logoRow])}>
            {logo && (
              <Box boxShadow={2} className={classes.paper}>
                <img src={getImage(logo, 'thumb')} alt='' height='122' width='122' />
              </Box>
            )}
            <div className={clsx('column', [classes.storeDetails])}>
              {store.ratingSummary && (
                <div className='centered-row'>
                  <img src={rate} alt='Rate' width='20' className={classes.icon} />
                  <Typography className={classes.storeDetail}>
                    {store.ratingSummary.averageRating?.toFixed(1)} ({store.ratingSummary.totalRatingCount})
                  </Typography>
                </div>
              )}
              {storeOpened && isDelivery && isASAP && (
                <div className='centered-row'>
                  <img src={deliveryTimeIcon} alt='Delivery time' width='20' className={classes.icon} />
                  <Typography className={classes.storeDetail}>{store.deliveryTime} mins</Typography>
                </div>
              )}
              {/* <div className='centered-row'>
                <img src={deliveryFee} alt='Delivery fee' width='20' className={classes.icon} />
                <Typography className={classes.storeDetail}>{store.deliveryFee}</Typography>
              </div> */}
              <div className='centered-row' style={{ marginLeft: -2.6 }}>
                {priceFilter(store.pricey, 20, true)}
              </div>
            </div>
            <div className='grow' />
            <div className='column'>
              <Button className={classes.more} size='small' onClick={handleMoreShow}>
                <FormattedMessage id='more-info' />
              </Button>
            </div>
          </div>
        </div>
      )}
      {store && (
        <div className={classes.nameRow}>
          <Typography
            className={clsx(classes.storeName, { [classes.storeNameLong]: store.nickname.length > 27 })}
            noWrap
          >
            {store.nickname}
          </Typography>
          <Typography className={classes.minHeadCount}>
            {f({ id: 'min-head-count' })} : {store.minHeadCount}
          </Typography>
        </div>
      )}
      {!storeOpened && (
        <div className={classes.closedContainer}>
          <div className={classes.closedBox}>
            <FormattedMessage id='closed-label' />
          </div>
        </div>
      )}
      {selectedCategories && (
        <div className={classes.categoryContainer}>
          {selectedCategories?.map((category, index) => (
            <React.Fragment key={index}>
              <Typography variant='caption' noWrap>
                {category}
              </Typography>
              {index !== selectedCategories.length - 1 && <Typography variant='caption'>{` | `}</Typography>}
            </React.Fragment>
          ))}
        </div>
      )}
      <SlidingSectionRow
        sections={sections}
        selectedSections={selectedSections}
        handleSectionSelect={handleSectionSelect}
      />
    </Grid>
  )
}

TopInfo.propTypes = {
  store: PropTypes.object,
  background: PropTypes.object,
  classes: PropTypes.object,
}

export default TopInfo
