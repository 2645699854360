import React from 'react'
import { connect } from 'react-redux'
import { customerService } from '../../services'
import { generalConstants } from '../../constants'
import { alertActions } from '../../actions'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { injectIntl } from 'react-intl'
import { GreenButton, Transition, TFTextField } from '../utils/tools'
import { emailRegex } from '../utils/regex_collections'
import close from '../../images/close.svg'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import done from '../../images/password-resetted.svg'
import logoText from '../../images/logo_text_black.svg'
import clsx from 'clsx'
import backIcon from '../../images/black-back.svg'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    fontWeight: 500,
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 0),
  },
  top: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  leftBackButton: {
    top: theme.spacing(2),
    left: theme.spacing(2),
    position: 'absolute',
  },
  rightBackButton: {
    top: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
  },
  dialog: {
    width: 240,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  done: {
    marginBottom: theme.spacing(),
  },
  logo: {},
})

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      showDoneDialog: false,
    }
  }

  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  handleSubmit(e) {
    e.preventDefault()

    customerService.resetPassword(this.state.email).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) this.setState({ showDoneDialog: true })
      },
      (error) => {
        this.props.showError(error.toString())
      }
    )
  }

  render() {
    const { classes } = this.props
    const { email, showDoneDialog } = this.state

    return (
      <div className='column'>
        <div className={clsx('centered-row', [classes.top])}>
          <IconButton
            size='small'
            className={clsx('mobile', [classes.leftBackButton])}
            onClick={() => this.props.handleClose()}
          >
            <img src={backIcon} alt='' />
          </IconButton>
          <img src={logoText} alt='TookFresh' height='45' className='mobile-logo' />
          <IconButton
            color='inherit'
            className={clsx('desktop', [classes.rightBackButton])}
            onClick={() => this.props.handleClose()}
            aria-label='close'
          >
            <img src={close} height='22' width='22' alt='Back' />
          </IconButton>
        </div>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography variant='h4' gutterBottom className={classes.title}>
              {this.getLabel('reset-password')}
            </Typography>
            <Typography variant='subtitle2' gutterBottom className={classes.text}>
              {this.getLabel('forgot-password-text')}
            </Typography>
            <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
              <TFTextField
                size='small'
                variant='outlined'
                margin='normal'
                fullWidth
                id='email'
                placeholder={this.getLabel('email-address')}
                value={email}
                autoComplete='email'
                onChange={(event) =>
                  this.setState({
                    emailFocused: true,
                    emailInvalid: !emailRegex.test(event.target.value),
                    email: event.target.value,
                  })
                }
                error={this.state.emailInvalid && this.state.emailFocused}
              />
              <GreenButton
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
                disabled={!this.state.email || this.state.emailInvalid}
                className={classes.button}
                onClick={(e) => this.handleSubmit(e)}
              >
                {this.getLabel('send-label')}
              </GreenButton>
            </form>
          </div>
        </Container>
        <Dialog
          open={showDoneDialog}
          onClose={() => this.props.handleClose()}
          TransitionComponent={Transition}
          keepMounted
        >
          <div className={classes.dialog}>
            <img src={done} className={classes.done} alt='' />
            <Typography variant='h5' gutterBottom className={classes.title}>
              {this.getLabel('password-hasbeen-reset')}
            </Typography>
            <Typography variant='subtitle2' gutterBottom className={classes.text}>
              {this.getLabel('password-reset-text')}
            </Typography>
            <GreenButton
              variant='contained'
              color='primary'
              fullWidth
              className={classes.button}
              onClick={() => this.props.handleClose()}
            >
              {this.getLabel('done-label')}
            </GreenButton>
          </div>
        </Dialog>
      </div>
    )
  }
}

const actionCreators = {
  showError: alertActions.error,
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(injectIntl(connect(null, actionCreators)(ForgotPassword)))
