import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { GreenCheckbox } from '../../../../utils/tools'

import PhoneArea from './Notifications/PhoneArea'
import EmailArea from './Notifications/EmailArea'

import { valueConstants, notificationConstants, notificationValues, labelConstants } from '../Helpers'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  label: {
    maxWidth: 150,
  },
  errorText: {
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    fontWeight: 300,
    marginTop: theme.spacing(2.5),
    color: theme.palette.secondary.main,
  },
}))

const Notifications = ({ handleChange, values, setValues, error }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (values.notificationType.length === 2 || values.notificationType.length === 0) {
      handlePhone()
      handleEmail()
    }
    if (values.notificationType.includes(valueConstants.notificationPhone)) {
      handlePhone()
    } else if (values.notificationType.includes(valueConstants.notificationEmail)) {
      handleEmail()
    }
  }, [values])

  const handlePhone = () => {
    if (values.orderConfirmationPhone === values.storePhone) {
      setPhone(notificationConstants.storePhone)
    } else {
      setPhone(notificationConstants.otherPhone)
    }
  }

  const handleEmail = () => {
    if (values.orderConfirmationEmail === values.storeEmail) {
      setEmail(notificationConstants.storeEmail)
    } else {
      setEmail(notificationConstants.otherEmail)
    }
  }

  const handlePhoneValue = (value) => {
    if (value === notificationConstants.storePhone) {
      setValues({
        ...values,
        orderConfirmationPhone: values.storePhone,
      })
    } else {
      setValues({
        ...values,
        orderConfirmationPhone: '1',
      })
    }
  }

  const phoneSelection = (e) => {
    setPhone(e.target.value)
    handlePhoneValue(e.target.value)
  }

  const handleEmailValue = (value) => {
    if (value === notificationConstants.storeEmail) {
      setValues({
        ...values,
        orderConfirmationEmail: values.storeEmail,
      })
    } else {
      setValues({
        ...values,
        orderConfirmationEmail: '',
      })
    }
  }

  const emailSelection = (e) => {
    setEmail(e.target.value)
    handleEmailValue(e.target.value)
  }

  return (
    <Container maxWidth='md' className={classes.root}>
      <FormControl fullWidth>
        <FormGroup aria-label='position' row>
          {notificationValues.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <GreenCheckbox
                  checked={values?.notificationType.includes(item)}
                  onChange={handleChange(valueConstants.notificationType)}
                  value={item}
                />
              }
              label={
                <Typography>
                  {item === notificationValues[0]
                    ? f({ id: labelConstants.textMessage })
                    : f({ id: labelConstants.email })}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      </FormControl>
      {values.notificationType.includes(valueConstants.notificationPhone) && (
        <Typography>{f({ id: 'available-phone-text-message' })}</Typography>
      )}
      <Grid container spacing={2} className={clsx('full-width', [classes.gridContainer])}>
        {values.notificationType.includes(valueConstants.notificationPhone) && (
          <PhoneArea
            classes={classes}
            phone={phone}
            phoneSelection={phoneSelection}
            values={values}
            handleChange={handleChange}
            setValues={setValues}
            error={error}
          />
        )}
        {values.notificationType.includes(valueConstants.notificationEmail) && (
          <EmailArea
            classes={classes}
            email={email}
            emailSelection={emailSelection}
            values={values}
            handleChange={handleChange}
            setValues={setValues}
            error={error}
          />
        )}
      </Grid>
      {error && <Typography className={classes.errorText}>{f({ id: 'notification-error' })}</Typography>}
    </Container>
  )
}

Notifications.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.bool,
}

export default Notifications
