import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, TextField } from '@material-ui/core'
import { injectIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  nameDesc: {
    minWidth: 300,
    maxWidth: 600,
  },
}))

const MenuTextFields = ({ menu, handleChange, intl }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <>
      <Grid item sm={12} xs={12}>
        <TextField
          variant='outlined'
          className={classes.nameDesc}
          required
          fullWidth
          id='name'
          name='name'
          value={menu.name}
          label={getLabel('name-label')}
          autoFocus
          onChange={handleChange}
          inputProps={{
            maxLength: 50,
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <TextField
          variant='outlined'
          fullWidth
          required
          className={classes.nameDesc}
          multiline={true}
          maxRows={5}
          id='description'
          name='description'
          value={menu.description ? menu.description : ''}
          label={getLabel('description-label')}
          onChange={handleChange}
          helperText={250 - menu.description?.length + ' remains'}
          inputProps={{
            maxLength: 250,
          }}
        />
      </Grid>
    </>
  )
}

MenuTextFields.propTypes = {
  menu: PropTypes.object,
  handleChange: PropTypes.func,
}

export default injectIntl(MenuTextFields)
