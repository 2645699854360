import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import { uiHeader } from '../../../helpers/config'
import Loading from '../../common/loading'

import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'

import { GreenConnector } from '../../utils/tools'
import { alertActions } from '../../../actions'
import {
  zerothStepRequiredFieldsFilled,
  firstStepRequiredFieldsFilled,
  secondStepRequiredFieldsFilled,
  thirdStepRequiredFieldsFilled,
  fourthStepRequiredFieldsFilled,
  fifthStepRequiredFieldsFilled,
  sixthStepRequiredFieldsFilled,
  seventhStepRequiredFieldsFilled,
  handleWorkingHours,
  handlePhoneNo,
  tierControl,
} from '../businessHelpers'

import { valueConstants, steps, initialValues, initialPdfState, initialLayersState } from '../businessConstants'

import PersonalDetails from './StepContent/PersonalDetails'
import BusinessDetails from './StepContent/BusinessDetails'
import WorkingHours from './StepContent/WorkingHours'
import MenuDetails from './StepContent/MenuDetails'
import Notifications from './StepContent/Notifications'
import DeliveryDetails from './StepContent/DeliveryDetails'
import PaymentDetails from './StepContent/PaymentDetails'
import BankInfo from './StepContent/BankInfo'
import SubmissionPart from './SubmissionPart'

const API_URL = process.env.REACT_APP_API_URL

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    paddingBottom: theme.spacing(5),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepper: {
    '& .MuiStepContent-root': {
      marginLeft: theme.spacing(2.5),
      borderLeft: '3px solid #bdbdbd',
    },
    '& .MuiStepConnector-vertical': {
      marginLeft: theme.spacing(2.5),
    },
    '& .MuiStepConnector-lineVertical': {
      borderLeftWidth: 3,
    },
  },
  stepperLabel: {
    '& .MuiSvgIcon-root': {
      width: '2em',
      height: '2em',
    },
    '& .MuiStepLabel-labelContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      fontSize: '2rem',
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    '& .MuiStepLabel-label': {
      fontSize: '1rem',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 85,
    "&[add-button='true']": {
      marginRight: theme.spacing(),
    },
  },
}))

const StepperMain = ({ handleScroll, setCompleted, token }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const warningMsg = (msg) => dispatch(alertActions.warning(msg))

  const [activeStep, setActiveStep] = useState(0)
  const [stepSixError, setStepSixError] = useState(false)
  const [stepZeroError, setStepZeroError] = useState(false)
  const [stepOneError, setStepOneError] = useState(false)
  const [stepTwoError, setStepTwoError] = useState(false)
  const [stepThreeError, setStepThreeError] = useState(false)
  const [stepFourError, setStepFourError] = useState(false)
  const [stepFiveError, setStepFiveError] = useState(false)
  const [stepSevenError, setStepSevenError] = useState(false)
  const [tierError, setTierError] = useState(false)
  const [differentBusinessEmail, setBusinessDifferentEmail] = useState(false)
  const [secondTierState, setSecondTier] = useState(false)
  const [thirdTierState, setThirdTier] = useState(false)
  const [parsedAddress, setParsedAddress] = useState(null)
  const [checkParsedAddress, setCheckParsedAddress] = useState(null)
  const [useStoreAddressForCheck, setUseStoreAddressForCheck] = useState(true)
  const [clicked, setClicked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pdfFile, setPdfFile] = useState({ ...initialPdfState })
  const [layers, setLayers] = useState({ ...initialLayersState })
  const [values, setValues] = useState({ ...initialValues })
  const [taxDocument, setTaxDocument] = useState(null)
  const [emailChecked, setEmailChecked] = useState(false)

  useEffect(() => {
    let active = true
    if (active) {
      if (stepFiveError && fifthStepRequiredFieldsFilled(values, secondTierState, thirdTierState)) {
        setStepFiveError(false)
      }
      if (tierError && tierControl(values)) {
        setTierError(false)
      }
      if (stepFourError && fourthStepRequiredFieldsFilled(values)) {
        setStepFourError(false)
      }
      if (stepTwoError && secondStepRequiredFieldsFilled(values.workingHours)) {
        setStepTwoError(false)
      }
      if (stepSevenError && seventhStepRequiredFieldsFilled(values)) {
        setStepSevenError(false)
      }
    }
    return () => {
      active = false
    }
  }, [values])

  const handleChange = (prop) => (e) => {
    if (prop === valueConstants.notificationType) {
      let val = e.target.value
      if (values.notificationType.includes(val)) {
        let prop2 = val === valueConstants.notificationPhone ? 'orderConfirmationPhone' : 'orderConfirmationEmail'
        setValues({
          ...values,
          [prop]: values.notificationType.filter((nt) => nt !== val),
          [prop2]: '',
        })
      } else {
        setValues({
          ...values,
          [prop]: [...values.notificationType, val],
        })
      }
    } else {
      setValues({
        ...values,
        [prop]: e.target.value,
      })
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (!zerothStepRequiredFieldsFilled(values)) {
        setStepZeroError(true)
        return
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setStepZeroError(false)
      }
    } else if (activeStep === 1) {
      if (!firstStepRequiredFieldsFilled(values, parsedAddress)) {
        setStepOneError(true)
        return
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setStepOneError(false)
      }
    } else if (activeStep === 2) {
      if (!secondStepRequiredFieldsFilled(values.workingHours)) {
        setStepTwoError(true)
        return
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setStepTwoError(false)
      }
    } else if (activeStep === 3) {
      setClicked(true)
      if (!thirdStepRequiredFieldsFilled(values, pdfFile)) {
        setStepThreeError(true)
        return
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setStepThreeError(false)
        setClicked(false)
      }
    } else if (activeStep === 4) {
      if (!fourthStepRequiredFieldsFilled(values)) {
        setStepFourError(true)
        return
      } else {
        setStepFourError(false)
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    } else if (activeStep === 5) {
      if (fifthStepRequiredFieldsFilled(values, secondTierState, thirdTierState) && tierControl(values)) {
        setValues({
          ...values,
          paymentContactName:
            values.paymentContactName.length === 0
              ? `${values.firstName} ${values.lastName}`
              : values.paymentContactName,
          paymentContactPhone: values.paymentContactPhone.length < 2 ? values.phoneNo : values.paymentContactPhone,
          paymentContactEmail: values.paymentContactEmail.length === 0 ? values.email : values.paymentContactEmail,
        })
        setStepFiveError(false)
        setTierError(false)
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      } else {
        if (!tierControl(values)) {
          return setTierError(true)
        } else {
          return setStepFiveError(true)
        }
      }
    } else if (activeStep === 6) {
      if (!sixthStepRequiredFieldsFilled(values)) {
        setStepSixError(true)
        return
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setStepSixError(false)
      }
    } else if (activeStep === 7) {
      if (!seventhStepRequiredFieldsFilled(values, checkParsedAddress, taxDocument)) {
        setStepSevenError(true)
        return
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setStepSevenError(false)
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReview = () => {
    handleScroll()
    setActiveStep(0)
  }

  const handleSubmit = () => {
    setLoading(true)
    let addressObj = {
      ...parsedAddress,
      addressLine2: values.suiteNo,
    }

    let checkAddressObj = {
      ...checkParsedAddress,
      addressLine2: useStoreAddressForCheck ? values.suiteNo : values.checkSuiteNo,
    }

    let phoneNo = handlePhoneNo(values.phoneNo)
    let storePhone = handlePhoneNo(values.storePhone)
    let paymentContactPhone = handlePhoneNo(values.paymentContactPhone)
    let workingHours = handleWorkingHours(values.workingHours)

    delete values.suiteNo
    delete values.phoneNo
    delete values.storePhone
    delete values.paymentContactPhone
    delete values.workingHours
    delete values.confirmAccountNumber
    delete values.checkSuiteNo

    let query = {
      ...values,
      token,
      storeAddress: addressObj,
      phoneNo,
      storePhone,
      paymentContactPhone,
      workingHours,
      checkAddress: values.paymentMethod === valueConstants.directDeposit ? null : checkAddressObj,
    }

    const formData = new FormData()
    formData.append('requestDTO', JSON.stringify(query))
    pdfFile.first && formData.append('pdfFile', pdfFile.first)
    pdfFile.second && formData.append('pdfFile2', pdfFile.second)
    pdfFile.third && formData.append('pdfFile3', pdfFile.third)
    formData.append('taxDocument', taxDocument)

    axios
      .post(`${API_URL}/restaurant-request-register/ui`, formData, uiHeader())
      .then((res) => {
        if (res.status === 200) {
          setCompleted('completed')
        } else {
          setCompleted('error')
        }
        setLoading(false)
      })
      .catch((err) => {
        setCompleted('error')
        setLoading(false)
      })
  }

  const stepContent = {
    0: <PersonalDetails handleChange={handleChange} values={values} error={stepZeroError} setValues={setValues} />,
    1: (
      <BusinessDetails
        handleChange={handleChange}
        values={values}
        error={stepOneError}
        setValues={setValues}
        differentBusinessEmail={differentBusinessEmail}
        setBusinessDifferentEmail={setBusinessDifferentEmail}
        setParsedAddress={setParsedAddress}
        parsedAddress={parsedAddress}
      />
    ),
    2: <WorkingHours workingHours={values.workingHours} values={values} setValues={setValues} error={stepTwoError} />,
    3: (
      <MenuDetails
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        warningMsg={warningMsg}
        error={stepThreeError}
        pdfFile={pdfFile}
        setPdfFile={setPdfFile}
        layers={layers}
        setLayers={setLayers}
        setStepThreeError={setStepThreeError}
        clicked={clicked}
      />
    ),
    4: (
      <Notifications
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        error={stepFourError}
        emailChecked={emailChecked}
        setEmailChecked={setEmailChecked}
      />
    ),
    5: (
      <DeliveryDetails
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        secondTierState={secondTierState}
        setSecondTier={setSecondTier}
        thirdTierState={thirdTierState}
        setThirdTier={setThirdTier}
        error={stepFiveError}
        tierError={tierError}
      />
    ),
    6: <PaymentDetails handleChange={handleChange} values={values} setValues={setValues} error={stepSixError} />,
    7: (
      <BankInfo
        handleChange={handleChange}
        values={values}
        setValues={setValues}
        error={stepSevenError}
        setCheckParsedAddress={setCheckParsedAddress}
        checkParsedAddress={checkParsedAddress}
        useStoreAddressForCheck={useStoreAddressForCheck}
        setUseStoreAddressForCheck={setUseStoreAddressForCheck}
        parsedAddress={parsedAddress}
        setTaxDocument={setTaxDocument}
        taxDocument={taxDocument}
      />
    ),
  }

  return (
    <Container className={classes.root} maxWidth='md'>
      <Loading open={loading} />
      <Stepper
        activeStep={activeStep}
        orientation='vertical'
        className={classes.stepper}
        connector={<GreenConnector />}
      >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel className={classes.stepperLabel}>{label}</StepLabel>
            <StepContent className={classes.stepContent}>
              <div>{stepContent[index]}</div>
              <div className={classes.actionsContainer}>
                <div className={classes.buttonContainer}>
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    {f({ id: 'back-label' })}
                  </Button>
                  <Button variant='contained' color='primary' onClick={handleNext} className={classes.button}>
                    {activeStep === steps.length - 1 ? f({ id: 'complete-label' }) : f({ id: 'next-label' })}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && <SubmissionPart handleReview={handleReview} handleSubmit={handleSubmit} />}
    </Container>
  )
}

StepperMain.propTypes = {
  handleScroll: PropTypes.func,
  setCompleted: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
}

export default StepperMain
