import React from 'react'
import PropTypes from 'prop-types'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { identifyBgColor } from '../../../../helpers/reviews/identify-color'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: '100%',
  },
  headerRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  action: {
    alignSelf: 'center',
    color: 'white',
  },
}))

const OverallCard = ({ avatar, action, count, getPeriod, title }) => {
  const classes = useStyles()
  return (
    <Grid item xl={6} lg={6} md={6} xs={12} sm={6}>
      <Card className={classes.cardRoot} raised>
        <CardHeader
          classes={{
            root: classes.headerRoot,
            action: classes.action,
          }}
          avatar={avatar}
          action={
            action && (
              <Typography variant='h4' style={{ color: identifyBgColor(action), fontWeight: 'bold' }}>
                {count ? action : +action?.toFixed(1)}
              </Typography>
            )
          }
          title={<Typography variant='h6'>{`${getPeriod()} ${title}`}</Typography>}
        />
      </Card>
    </Grid>
  )
}

OverallCard.propTypes = {
  avatar: PropTypes.node,
  action: PropTypes.number,
  count: PropTypes.bool,
  title: PropTypes.string,
}

export default React.memo(OverallCard)
