import React from 'react'
import { useIntl } from 'react-intl'

import FormGroupItems from './categories/formGroupItems'

import { Typography, FormLabel, FormControl, Collapse } from '@material-ui/core'

const Categories = ({ classes, more }) => {
  const { formatMessage: f } = useIntl()

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>
        <Typography variant='subtitle1' className={classes.groupTitle}>
          {f({ id: 'categories-label' })}
        </Typography>
      </FormLabel>
      <FormGroupItems sliceFrom={0} length={5} classes={classes} />
      <Collapse in={more} timeout='auto' unmountOnExit>
        <FormGroupItems sliceFrom={5} classes={classes} />
      </Collapse>
    </FormControl>
  )
}

export default Categories
