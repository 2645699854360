import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontWeight: 400,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  text: {
    color: '#707070',
    fontWeight: 300,
    width: '80%',
    lineHeight: '2em',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  button: {
    textTransform: 'none',
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
    color: theme.palette.primary.main,
    fontSize: '1.5em',
    marginTop: theme.spacing(),
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}))

const path = process.env.PUBLIC_URL

const CompleteText = () => {
  const classes = useStyles()
  const history = useHistory()
  const { formatMessage: f } = useIntl()

  return (
    <div className={classes.root}>
      <Typography variant='h4' align='center' className={classes.header}>
        {f({ id: 'thanks-for-submission' })}
      </Typography>
      <Typography variant='h5' align='center' className={classes.text}>
        {f({ id: 'reach-out-soon' })}
      </Typography>
      <Button className={classes.button} variant='outlined' disableRipple onClick={() => history.push(`${path}/`)}>
        {f({ id: 'return-to-main-page' })}
      </Button>
    </div>
  )
}

export default React.memo(CompleteText)
