const favoriteStateMaker = (data) => {
  //here we create a new local state level favorites store array
  let newStateArray = []
  data.forEach((elem) => {
    let newState = {}
    newState['entityId'] = elem.store.id
    newState['id'] = elem.id
    newStateArray.push(newState)
  })
  return newStateArray
}

const favoriteStoreCheck = (id, favorites) => {
  // if favorites array has already incoming id, delete it, else add to the list
  let includes = false
  let storeId
  if (favorites.length > 0) {
    favorites.forEach((store) => {
      store.entityId === id && ([includes, storeId] = [true, store.id])
    })
  }
  return { includes, storeId }
}

export { favoriteStateMaker, favoriteStoreCheck }
