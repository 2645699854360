import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: 14,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: -0.24,
    color: '#121212',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

export const Name = ({ name }) => {
  const classes = useStyles()
  return (
    <Typography variant='h4' className={classes.name}>
      {name}
    </Typography>
  )
}

Name.propTypes = {
  name: PropTypes.string,
}
