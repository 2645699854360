import React from 'react'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import priceFilter from '../../../../helpers/price-icons'

const useStyles = makeStyles((theme) => ({
  box: {
    width: 'fit-content',
    borderRadius: 20,
    height: 30,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    boxShadow: 'rgb(0 0 0 / 5%) 0px 7px 16px 5px;',
    marginBottom: theme.spacing(),
  },
  text: {
    marginRight: theme.spacing(),
  },
  iconContainer: {
    marginTop: 4,
  },
}))

const Pricey = ({ pricey }) => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  return (
    <Tooltip title={f({ id: 'pricey-message' })} placement='top' arrow>
      <Box className={classes.box}>
        <Typography className={classes.text}>{f({ id: 'pricey-label' })}</Typography>
        <Box className={classes.iconContainer}>{priceFilter(pricey, 15, false)}</Box>
      </Box>
    </Tooltip>
  )
}

export default Pricey
