import { attributeConstants } from '../constants'

const INITIAL_STATE = {
  loading: false,
  error: null,
  data: [],
  selectedAttribute: null,
}

export function attributes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case attributeConstants.DELETE_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case attributeConstants.DELETE_VALUE_SUCCESS:
      const newStateAfterDeleteValue = state.selectedAttribute.values.filter((v) => v.id !== action.valueId)
      return {
        ...state,
        loading: false,
        error: null,
        selectedAttribute: {
          ...state.selectedAttribute,
          values: [...newStateAfterDeleteValue],
        },
      }
    case attributeConstants.DELETE_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case attributeConstants.UPDATE_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case attributeConstants.UPDATE_VALUE_SUCCESS:
      const relatedValue = (elem) => elem.id === action.value.id
      const indexOfValue = state.selectedAttribute.values.findIndex(relatedValue)
      state.selectedAttribute.values[indexOfValue] = action.value
      return {
        ...state,
        loading: false,
        error: null,
        selectedAttribute: {
          ...state.selectedAttribute,
          values: [...state.selectedAttribute.values],
        },
      }
    case attributeConstants.UPDATE_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case attributeConstants.CREATE_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case attributeConstants.CREATE_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        selectedAttribute: {
          ...state.selectedAttribute,
          values: [action.value, ...state.selectedAttribute.values],
        },
      }
    case attributeConstants.CREATE_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case attributeConstants.UPDATE_ATTRIBUTE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case attributeConstants.UPDATE_ATTRIBUTE_SUCCESS:
      const relatedAttribute = (elem) => elem.id === action.attribute.id
      const indexOfAttribute = state.data.findIndex(relatedAttribute)
      state.data[indexOfAttribute] = action.attribute
      return {
        ...state,
        loading: false,
        error: null,
        data: [...state.data],
      }
    case attributeConstants.UPDATE_ATTRIBUTE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case attributeConstants.CREATE_ATTRIBUTE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case attributeConstants.CREATE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: [action.attribute, ...state.data],
      }
    case attributeConstants.CREATE_ATTRIBUTE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case attributeConstants.DELETE_ATTRIBUTE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case attributeConstants.DELETE_ATTRIBUTE_SUCCESS:
      const stateAfterDelete = state.data.filter((i) => i.id !== action.attributeId)
      return {
        ...state,
        loading: false,
        error: null,
        data: stateAfterDelete,
      }
    case attributeConstants.DELETE_ATTRIBUTE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case attributeConstants.GET_ATTRIBUTE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case attributeConstants.GET_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.attributes,
      }
    case attributeConstants.GET_ATTRIBUTE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case attributeConstants.CHANGE_SELECTED_ATTRIBUTE:
      return {
        ...state,
        selectedAttribute: action.item,
      }
    case attributeConstants.CLEAR_STATE:
      return {
        ...state,
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}
