import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { deliverySubTypes } from '../../../../../../../constants'

import yesIcon from '../../../../../../../images/yes.svg'
import deliverDoorIcon from '../../../../../../../images/deliver-door.svg'
import deliverDoorIconSelected from '../../../../../../../images/deliver-door-selected.svg'

const DeliveryToDoor = ({ subTyp, handleSubType, button, selectedButton, f }) => (
  <Grid item xs={4}>
    <div className='centered-row full-width'>
      <Button
        startIcon={
          <img
            src={subTyp === deliverySubTypes.DELIVER_DOOR ? deliverDoorIconSelected : deliverDoorIcon}
            alt='where'
            height='20'
            width='20'
          />
        }
        endIcon={subTyp === deliverySubTypes.DELIVER_DOOR && <img src={yesIcon} alt='where' height='16' width='16' />}
        className={clsx({
          [button]: true,
          [selectedButton]: subTyp === deliverySubTypes.DELIVER_DOOR,
        })}
        onClick={() => handleSubType(deliverySubTypes.DELIVER_DOOR)}
      >
        {f({ id: 'deliver-door' })}
      </Button>
    </div>
  </Grid>
)

DeliveryToDoor.propTypes = {
  subTyp: PropTypes.string.isRequired,
  handleSubType: PropTypes.func,
  button: PropTypes.string,
  selectedButton: PropTypes.string,
  f: PropTypes.func,
}

export default React.memo(DeliveryToDoor)
