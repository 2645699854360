import { searchConstants, generalConstants } from '../constants'
import { populateAddress } from '../helpers/redux/search-address/populate-address'
import { searchService } from '../services'
import { customerActions } from './customer.actions'
import { orderActions } from './order.actions'

export const searchActions = {
  resetSearch,
  searchByCategory,
  addCategorySearchFilter,
  removeCategorySearchFilter,
  setCategorySearchFilter,
  addDietarySearchFilter,
  removeDietarySearchFilter,
  setDietaryFilters,
  setPriceyFilter,
  removePriceySearchFilter,
  setRatingFilter,
  removeRatingSearchFilter,
  setDistanceFilter,
  removeDistanceSearchFilter,
  setSearchSortBy,
  removeSortBySearchFilter,
  setSearchTextFilter,
  setMoreFilters,
  setMoreFiltersMobile,
  setDeliveryType,
  setAddress,
  setDeliveryTime,
  setDeliveryTimeDetail,
  search,
  removeSearchTextFilter,
  setSearchSortByOrder,
  populateQueryAddress,
  toggleOpenFilter,
  resetSearchQueryWhenSignout,
}

function search(query) {
  return (dispatch) => {
    dispatch(request())

    searchService.search(query).then(
      (res) => {
        if (res.status === generalConstants.STATUS_OK) {
          dispatch(success(res.data))
        }
      },
      (error) => {
        dispatch(failure(error.toString()))
      }
    )
  }

  function request() {
    return { type: searchConstants.DO_SEARCH_REQUEST }
  }
  function success(result) {
    return { type: searchConstants.DO_SEARCH_SUCCESS, result }
  }
  function failure(error) {
    return { type: searchConstants.DO_SEARCH_FAILURE, error }
  }
}

function resetSearch() {
  return {
    type: searchConstants.RESET_SEARCH,
  }
}

function resetSearchQueryWhenSignout() {
  return {
    type: searchConstants.RESET_SEARCH_QUERY_WHEN_SIGNOUT,
  }
}

function searchByCategory(categoryId) {
  return {
    type: searchConstants.SET_CATEGORY,
    categoryId,
  }
}

function addCategorySearchFilter(categoryId) {
  return {
    type: searchConstants.ADD_CATEGORY_FILTER,
    categoryId,
  }
}

function removeCategorySearchFilter(categoryId) {
  return {
    type: searchConstants.REMOVE_CATEGORY_FILTER,
    categoryId,
  }
}

function setCategorySearchFilter(categoryId) {
  return {
    type: searchConstants.SET_CATEGORY_FILTER,
    categoryId,
  }
}

function addDietarySearchFilter(dietaryId) {
  return {
    type: searchConstants.ADD_DIETARY_FILTER,
    dietaryId,
  }
}

function removeDietarySearchFilter(dietaryId) {
  return {
    type: searchConstants.REMOVE_DIETARY_FILTER,
    dietaryId,
  }
}

function setDietaryFilters(dietaryIds) {
  return {
    type: searchConstants.SET_DIETARY_FILTERS,
    dietaryIds,
  }
}

function setPriceyFilter(value) {
  return {
    type: searchConstants.SET_PRICEY_VALUE,
    value,
  }
}

function removePriceySearchFilter() {
  return {
    type: searchConstants.REMOVE_PRICEY_FILTER,
  }
}

function setRatingFilter(value) {
  return {
    type: searchConstants.SET_RATING_VALUE,
    value,
  }
}

function removeRatingSearchFilter() {
  return {
    type: searchConstants.REMOVE_RATING_FILTER,
  }
}

function setDistanceFilter(value) {
  return {
    type: searchConstants.SET_DISTANCE_VALUE,
    value,
  }
}

function removeDistanceSearchFilter() {
  return {
    type: searchConstants.REMOVE_DISTANCE_FILTER,
  }
}

function setSearchTextFilter(text) {
  return {
    type: searchConstants.SET_SEARCH_TEXT,
    text,
  }
}

function removeSearchTextFilter() {
  return {
    type: searchConstants.REMOVE_SEARCH_TEXT,
  }
}

//Will be deleted because of implementation of new sort action sortByOrder
function setSearchSortBy(value) {
  return {
    type: searchConstants.SET_SEARCH_SORTBY_VALUE,
    value,
  }
}

function setSearchSortByOrder(value, order) {
  return {
    type: searchConstants.SET_SEARCH_SORTBY_ORDER,
    payload: { value, order },
  }
}

function removeSortBySearchFilter() {
  return {
    type: searchConstants.REMOVE_SORTBY_FILTER,
  }
}

function setDeliveryType(deliveryType) {
  return (dispatch) => {
    dispatch(success())
    // we need to change orders.cart.type according to searchQuery.deliveryType
    // we may think of combining deliveryType, deliverySubType and deliveryInstructions underneath searchQuery
    dispatch(orderActions.setOrderType(deliveryType))
  }
  function success() {
    return {
      type: searchConstants.SET_DELIVERY_TYPE,
      deliveryType,
    }
  }
}

function setAddress(address) {
  let searchedAddresses = JSON.parse(localStorage.getItem(generalConstants.SEARCHED_ADDRESSES))
  searchedAddresses = Array.isArray(searchedAddresses) ? searchedAddresses.slice(0, 3) : []
  searchedAddresses = [address, ...searchedAddresses]
  localStorage.setItem(generalConstants.SEARCHED_ADDRESSES, JSON.stringify(searchedAddresses))

  return {
    type: searchConstants.SET_ADDRESS,
    address,
  }
}

function setDeliveryTime(deliveryTime) {
  return {
    type: searchConstants.SET_DELIVERYTIME,
    deliveryTime,
  }
}

function setDeliveryTimeDetail(deliveryTimeDetail) {
  return {
    type: searchConstants.SET_DELIVERYTIME_DETAIL,
    deliveryTimeDetail,
  }
}

function populateQueryAddress(addresses) {
  return (dispatch) => {
    const { populatedAddress } = populateAddress(addresses)
    dispatch(setAddress(populatedAddress))
    dispatch(customerActions.changeAddressState())
  }
}

function setMoreFilters(dietaryIds, rating, pricey, distance, onlyOpen) {
  return {
    type: searchConstants.SET_MORE_FILTERS,
    dietaryIds,
    rating,
    pricey,
    distance,
    onlyOpen,
  }
}

function setMoreFiltersMobile(filters) {
  return {
    type: searchConstants.SET_MORE_FILTERS_MOBILE,
    categoryIds: filters.categoryIds,
    dietaryIds: filters.dietaryIds,
    rating: filters.rating,
    pricey: filters.pricey,
    distance: filters.distance,
    orderByKey: filters.orderByKey,
    orderByValue: filters.orderByValue,
  }
}

function toggleOpenFilter() {
  return {
    type: searchConstants.TOGGLE_OPEN_FILTER,
  }
}
