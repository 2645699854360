import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import StickyHeader from '../../../common/StickyHeader'
import AddressContainer from './check-out/AddressContainer'
import { makeStyles } from '@material-ui/core'
import PaymentContainer from './check-out/PaymentContainer'
import PriceContainer from './check-out/PriceContainer'
import { CardElement, useElements } from '@stripe/react-stripe-js'

import { deliveryTypes } from '../../../../constants/general.constants'
import { TipContainer } from './check-out/TipContainer'

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    paddingRight: theme.spacing(2),
    bottom: 10,
  },
  button: {
    width: '100%',
    height: 48,
    fontSize: 16,
    backgroundColor: '#69e781',
    borderRadius: 10,
    color: '#000',
    textTransform: 'capitalize',
    '&:disabled': {
      backgroundColor: '#f7f7f7',
      zIndex: 2,
    },
  },
}))

const CheckOut = ({ handleClose, handleSubmit, loading, clientSecret, intl, cards, ...props }) => {
  const classes = useStyles()
  const elements = useElements()
  //Global states
  const savedOrder = useSelector(({ orders }) => orders.savedOrder)
  // Local state
  const [newCardCompleted, setNewCardCompleted] = useState(false)
  const [changePaymentActive, setChangePaymentActive] = useState(false)

  const closeDialogHandler = () => handleClose()

  const onChange = () => {
    if (elements) {
      const cardElement = elements.getElement(CardElement)
      cardElement.on('change', function (event) {
        if (event.complete) {
          setNewCardCompleted(event.complete)
        } else {
          setNewCardCompleted(false)
        }
      })
    }
  }
  const changeCardHandler = () => {
    setChangePaymentActive(true)
    props.toggleCheckoutForm()
  }
  const paymentButtonDisableCondition =
    loading || !clientSecret || cards.length === 0 || (changePaymentActive && !newCardCompleted)

  const hideAddressHeader = savedOrder?.orderType === deliveryTypes.TAKEOUT

  return (
    <React.Fragment>
      <StickyHeader callback={closeDialogHandler} title='checkout-label' />
      {!hideAddressHeader && <AddressContainer handleAddressChange={props.handleAddressChange} />}
      <PaymentContainer
        showCheckoutForm={props.showCheckoutForm}
        toggleCheckoutForm={changeCardHandler}
        handleSubmit={props.handleSubmit}
        cards={cards}
        onChange={onChange}
        selectedCard={props.selectedCard}
        selectPaymentMethod={props.selectPaymentMethod}
      />
      <TipContainer
        driverTipPrice={props.driverTipPrice}
        handleTip={props.handleTip}
        handleCustomTip={props.handleCustomTip}
        customTip={props.customTip}
      />
      <PriceContainer tipValue={props.driverTipPrice.value} />

      <div className={clsx('sticky-bottom', classes.footer)}>
        <Button className={classes.button} onClick={handleSubmit} disabled={paymentButtonDisableCondition}>
          <FormattedMessage id='payment-label' />
        </Button>
      </div>
    </React.Fragment>
  )
}

CheckOut.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  clientSecret: PropTypes.string,
  toggleCheckoutForm: PropTypes.func,
  intl: PropTypes.object,
  showCheckoutForm: PropTypes.bool,
  cards: PropTypes.array,
  selectPaymentMethod: PropTypes.func,
  selectedCard: PropTypes.string,
}

export default injectIntl(CheckOut)
