import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useToggle } from '../../../../hooks/useToggle'
import BeMemberDialog from '../../../merchant/authentication/BeMemberDialog'

const useStyles = makeStyles((theme) => ({
  boldLink: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#fff',
    marginBottom: theme.spacing(5),
    '&:hover': {
      textDecoration: 'none',
    },
    // pointerEvents: 'none', //disable link
    textDecoration: 'none',
  },
  link: {
    fontSize: 15,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#fff',
    marginBottom: theme.spacing(),
    '&:hover': {
      textDecoration: 'none',
    },
    // pointerEvents: 'none', //diasable link
    textDecoration: 'none',
  },
}))

const path = process.env.PUBLIC_URL

const FooterLinks = ({ data, isTablet }) => {
  const classes = useStyles()
  const [showBeMemberDialog, setShowBeMemberDialog] = useToggle()

  const addRestaurantId = 'add-your-restaurant'

  return (
    <Grid item lg={2} md={3} style={{ marginBottom: isTablet && 30 }}>
      <div className='column'>
        {data.map((item, i) => (
          <Link
            key={i}
            to={`${path}${item.link}`}
            className={item.bold ? classes.boldLink : classes.link}
            onClick={item.label === addRestaurantId ? setShowBeMemberDialog : null}
          >
            <FormattedMessage id={item.label} />
          </Link>
        ))}
      </div>
      <BeMemberDialog isOpen={showBeMemberDialog} handleClose={setShowBeMemberDialog} />
    </Grid>
  )
}

FooterLinks.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.array,
}

export default React.memo(FooterLinks)
