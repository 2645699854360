export const getCategories = (sections) => {
  let categoryNames = []

  sections?.forEach((section) => {
    section?.menus.forEach((menu) => {
      menu?.categories.forEach((category) => {
        categoryNames.push(category.name)
      })
    })
  })
  const storeCategories = [...new Set(categoryNames)]
  return { storeCategories }
}
