import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { deliveryTypes } from '../../../../../constants'
import addressIcon from '../../../../../images/address.svg'

const useStyles = makeStyles((theme) => ({
  stepTwoRow: {
    borderBottom: '1px solid #f7f7f7',
    padding: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  stepTwoSubRow: {
    borderBottom: '1px solid #f7f7f7',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  stepTwoIcon: {
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
    marginRight: theme.spacing(2),
  },
  field: {
    padding: theme.spacing(),
  },
}))

const AptSuite = ({ intl, details, setDetails, address }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item xs={12} className={classes.stepTwoRow}>
      <img className={classes.stepTwoIcon} src={addressIcon} alt='where' height='20' width='20' />
      <div className='column'>
        <Typography variant='h6'>{address.formattedAddress}</Typography>
        <TextField
          disabled={details.deliveryType === deliveryTypes.TAKEOUT}
          value={details.appSuite}
          onChange={(e) => setDetails({ ...details, appSuite: e.target.value })}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.field },
          }}
          placeholder={getLabel('apt-suite')}
        />
      </div>
    </Grid>
  )
}

AptSuite.propTypes = {
  address: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  setDetails: PropTypes.func.isRequired,
}

export default injectIntl(AptSuite)
