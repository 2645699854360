/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useImperativeHandle } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { searchActions } from '../../../../actions'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import { TFSlider, LeftLabelButton } from '../../../utils/tools'
import { sortBy, distanceMarks, starMarks, priceMarks } from '../../../../constants'
import { mainFilterInitState } from '../../../../constants/main-filter/initial-state'
import { checkState } from '../../../../helpers/mainFilter/checkState'
import { newState } from '../../../../helpers/mainFilter/newState'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    paddingBottom: 60,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  gridContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  chip: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(),
    fontSize: 16,
  },
  selected: {
    color: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  reset: {
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.58,
    letterSpacing: 'normal',
    textAlign: 'right',
    color: '#c4c4c4',
    textTransform: 'capitalize',
  },
  sortButton: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.56,
    letterSpacing: -0.39,
    textAlign: 'left',
    color: '#c4c4c4',
    textTransform: 'capitalize',
    height: 40,
  },
  sortRow: {
    borderBottom: '1px solid #f7f7f7',
  },
  selectedSort: {
    color: '#000',
    fontWeight: 600,
  },
  slider: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(4),
  },
}))

const MainFilter = (props, ref) => {
  const { handleClose, setApplied, resetItems } = props
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()

  const [filterQuery, setFilterQuery] = useState(mainFilterInitState)

  const searchQuery = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const categories = useSelector(({ categories }) => categories.data, shallowEqual)
  const dietaries = useSelector(({ dietaries }) => dietaries.data, shallowEqual)

  useEffect(() => {
    if (checkState(searchQuery)) setFilterQuery(newState(searchQuery))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (checkState(filterQuery)) {
      setApplied(true)
    } else {
      setApplied(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery])

  const setMoreFiltersMobile = (query) => dispatch(searchActions.setMoreFiltersMobile(query))

  const handleCategoryClick = (id) => {
    filterQuery.categoryIds.includes(id) ? removeCategory(id) : addCategory(id)
  }

  const handleDietaryClick = (id) => {
    filterQuery.dietaryIds.includes(id) ? removeDietary(id) : addDietary(id)
  }

  const handleRatingRangeChange = (event, newValue) => {
    setFilterQuery({ ...filterQuery, rating: +newValue * -1 })
  }

  const handlePriceRangeChange = (event, newValue) => {
    setFilterQuery({ ...filterQuery, pricey: newValue })
  }

  const handleDistanceRangeChange = (event, newValue) => {
    setFilterQuery({ ...filterQuery, distance: newValue })
  }

  const removeCategory = (id) => {
    const newArray = filterQuery.categoryIds.filter((i) => i !== id)
    setFilterQuery({ ...filterQuery, categoryIds: newArray })
  }

  const addCategory = (id) => {
    const newArray = [id]
    setFilterQuery({ ...filterQuery, categoryIds: newArray })
  }

  const removeDietary = (id) => {
    const newArray = filterQuery.dietaryIds.filter((i) => i !== id)
    setFilterQuery({ ...filterQuery, dietaryIds: newArray })
  }

  const addDietary = (id) => {
    const newArray = [...filterQuery.dietaryIds, id]
    setFilterQuery({ ...filterQuery, dietaryIds: newArray })
  }

  const setFilters = () => {
    setMoreFiltersMobile(filterQuery)
    handleClose(true)
    resetItems()
  }

  const resetFilters = () => {
    setFilterQuery(mainFilterInitState)
  }

  useImperativeHandle(ref, () => ({
    setFilters: () => {
      setFilters()
    },
    resetFilters: () => {
      resetFilters()
    },
  }))

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} className='centered-row'>
          <Typography variant='h6' gutterBottom className={classes.title}>
            {f({ id: 'categories-label' })}
          </Typography>
          <div className='grow' />
        </Grid>
        <Grid item xs={12}>
          {categories?.map((category) => (
            <Chip
              clickable
              className={clsx(classes.chip, {
                [classes.selected]: filterQuery.categoryIds.includes(category.id),
              })}
              key={category.id}
              variant={filterQuery.categoryIds.includes(category.id) ? 'default' : 'outlined'}
              color={filterQuery.categoryIds.includes(category.id) ? 'primary' : 'default'}
              label={category.name}
              onClick={() => handleCategoryClick(category.id)}
            />
          ))}
        </Grid>
        <Grid item xs={12} className='flex-container flex-start'>
          <Typography variant='h6' gutterBottom className={classes.title}>
            {f({ id: 'dietaries-label' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {dietaries?.map((dietary) => (
            <Chip
              clickable
              className={clsx(classes.chip, {
                [classes.selected]: filterQuery.dietaryIds.includes(dietary.id),
              })}
              key={dietary.id}
              variant={filterQuery.dietaryIds.includes(dietary.id) ? 'default' : 'outlined'}
              color={filterQuery.dietaryIds.includes(dietary.id) ? 'primary' : 'default'}
              label={dietary.name}
              onClick={() => handleDietaryClick(dietary.id)}
            />
          ))}
        </Grid>

        <Grid item xs={12} className='flex-container flex-start'>
          <Typography variant='h6' gutterBottom className={classes.title}>
            {f({ id: 'sort-by' })}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selectedSort]: filterQuery.orderByKey == sortBy.NAME && filterQuery.orderByValue == sortBy.ASC,
            })}
            onClick={() => {
              setFilterQuery({
                ...filterQuery,
                orderByKey: sortBy.NAME,
                orderByValue: sortBy.ASC,
              })
            }}
          >
            {f({ id: 'name-label' })}
          </LeftLabelButton>
        </Grid>
        <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selectedSort]:
                filterQuery.orderByKey == sortBy.TOP_RATED && filterQuery.orderByValue == sortBy.DESC,
            })}
            onClick={() => {
              setFilterQuery({
                ...filterQuery,
                orderByKey: sortBy.TOP_RATED,
                orderByValue: sortBy.DESC,
              })
            }}
          >
            {f({ id: 'top-rated' })}
          </LeftLabelButton>
        </Grid>
        {/* <Grid item xs={12} className={classes.sortRow}>
            <LeftLabelButton
                fullWidth
                className={clsx(classes.sortButton, {
                [classes.selectedSort]: filterQuery.orderByKey == sortBy.NEAREST,
                })}
                onClick={() => {
                setFilterQuery({{ ...filterQuery, orderByKey: sortBy.NEAREST })
                }}
            >
                {f({ id: "nearest-me" })}
            </LeftLabelButton>
        </Grid> */}
        <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selectedSort]:
                filterQuery.orderByKey == sortBy.COST_HIGH_TO_LOW && filterQuery.orderByValue == sortBy.DESC,
            })}
            onClick={() => {
              setFilterQuery({
                ...filterQuery,
                orderByKey: sortBy.COST_HIGH_TO_LOW,
                orderByValue: sortBy.DESC,
              })
            }}
          >
            {f({ id: 'high-low-cost' })}
          </LeftLabelButton>
        </Grid>
        <Grid item xs={12} className={classes.sortRow}>
          <LeftLabelButton
            fullWidth
            className={clsx(classes.sortButton, {
              [classes.selectedSort]:
                filterQuery.orderByKey == sortBy.COST_LOW_TO_HIGH && filterQuery.orderByValue == sortBy.ASC,
            })}
            onClick={() => {
              setFilterQuery({
                ...filterQuery,
                orderByKey: sortBy.COST_LOW_TO_HIGH,
                orderByValue: sortBy.ASC,
              })
            }}
          >
            {f({ id: 'low-high-cost' })}
          </LeftLabelButton>
        </Grid>

        {/* Price filter will be activated later*/}
        <Grid item xs={12} className='flex-container flex-start'>
          <Typography variant='h6' gutterBottom className={classes.title}>
            {f({ id: 'price-range' })}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.slider}>
          <TFSlider
            value={filterQuery.pricey}
            aria-labelledby='pricey-slider'
            onChange={handlePriceRangeChange}
            step={1}
            min={1}
            max={4}
            marks={priceMarks}
          />
        </Grid>
        <Grid item xs={12} className='flex-container flex-start'>
          <Typography variant='h6' gutterBottom className={classes.title}>
            {f({ id: 'rating-label' })}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.slider}>
          <TFSlider
            value={+filterQuery.rating * -1}
            aria-labelledby='rating-slider'
            onChange={handleRatingRangeChange}
            step={0.5}
            min={-5}
            max={-2}
            marks={starMarks.slice(1)}
            // track='inverted'
          />
        </Grid>

        <Grid item xs={12} className='flex-container flex-start'>
          <Typography variant='h6' gutterBottom className={classes.title}>
            {f({ id: 'distance-label' })}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.slider}>
          <TFSlider
            value={filterQuery.distance}
            aria-labelledby='distance-slider'
            onChange={handleDistanceRangeChange}
            step={5}
            min={5}
            max={25}
            marks={distanceMarks}
          />
        </Grid>
      </Grid>
    </div>
  )
}

MainFilter.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

export default React.forwardRef(MainFilter)
