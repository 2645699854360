import { generalConstants } from '../constants'

const recentlySearchedWords = (keyword) => {
  let searchedKeywords = JSON.parse(localStorage.getItem(generalConstants.SEARCHED_KEYWORDS)) || []
  searchedKeywords = searchedKeywords.some((key) => key.toLowerCase() === keyword.toLowerCase())
    ? searchedKeywords
    : [keyword, ...searchedKeywords.slice(0, 9)]
  localStorage.setItem(generalConstants.SEARCHED_KEYWORDS, JSON.stringify(searchedKeywords))
}

const fetchMoreCheck = (size, customOffset, searchResult) => {
  let fetchMore = true
  if (size > customOffset) {
    // During initialFetch, size 50, we get 20 results. So do not trigger doSearch()
    fetchMore = false
  }
  if (searchResult.length % size !== 0) {
    // if we get less than size during any step, do not fetch more
    fetchMore = false
  }
  return fetchMore
}

export { recentlySearchedWords, fetchMoreCheck }
