import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Transition } from '../../../utils/tools'
import { Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import AddAttributeValue from './AddAttributeValue'

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 240,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogTitle: {
    // fontSize: 23,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.32,
    letterSpacing: 'normal',
    color: '#000',
    display: 'flex',
    flexDirection: 'row',
    alignIntems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignIntems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  row: {
    paddingBottom: theme.spacing(),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
    minWidth: 90,
  },
}))

const AttributeValueDialog = ({ intl, showDialog, closeDialog, item }) => {
  const classes = useStyles()

  const [editValue, setEditValue] = useState(false)

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Dialog open={showDialog} onClose={closeDialog} TransitionComponent={Transition} fullWidth maxWidth={'md'}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <div className={classes.titleContainer}>
          <Typography variant='h6'>{getLabel(!editValue ? 'add-attribute-value' : 'edit-attribute-value')}</Typography>
          <Typography variant='h6' color='textSecondary' style={{ marginLeft: 10 }}>
            ({item?.name})
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <AddAttributeValue onClose={closeDialog} item={item} setEditValue={setEditValue} />
      </DialogContent>
    </Dialog>
  )
}

AttributeValueDialog.propTypes = {
  intl: PropTypes.object,
  showDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  item: PropTypes.object,
}

export default injectIntl(AttributeValueDialog)
