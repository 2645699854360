import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { injectIntl } from 'react-intl'
import emptyCart from '../../../images/empty_cart.svg'
import { GreenButton } from '../../utils/tools'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    padding: theme.spacing(),
  },
  title: {
    fontSize: 34,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#333333',
    lineHeight: 1.18,
  },
  text: {
    fontSize: 16,
    fontWeight: 300,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    color: '#333333',
  },
  grow: {
    flexGrow: 1,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
  },
})

export class EmptyQuickCart extends Component {
  getLabel(labelId) {
    const { formatMessage } = this.props.intl
    return formatMessage({ id: labelId })
  }

  closeBasket() {
    this.props.callbackParent()
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.grow} />
        <img src={emptyCart} height='163' width='192' alt='Cart' style={{ marginBottom: 20 }} />
        <span className={classes.title}>{this.getLabel('oh-label')}</span>
        <span className={classes.title} style={{ marginBottom: 20 }}>
          {this.getLabel('still-emtpty-label')}
        </span>
        <span className={classes.text}>{this.getLabel('no-items-label')}</span>
        <span className={classes.text} style={{ marginBottom: 10 }}>
          {this.getLabel('start-add-label')}
        </span>
        <GreenButton fullWidth onClick={this.closeBasket.bind(this)} style={{ marginBottom: 30 }}>
          <Typography className={classes.buttonText}>{this.getLabel('continue-label')}</Typography>
        </GreenButton>
      </div>
    )
  }
}

export default withStyles(styles)(injectIntl(EmptyQuickCart))
