import React from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'

import { labelConstants } from '../Helpers'

const useStyles = makeStyles((theme) => ({
  startAdornment: {
    fontWeight: 500,
    paddingTop: 2,
    opacity: '0.5',
  },
  endAdornment: {
    fontWeight: 500,
    opacity: '0.5',
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
}))

const TierField_ = ({ prop, gridVal, handleChange, startAdornment_, endAdornment_, values, error, disabled }) => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  return (
    <Grid item xs={gridVal} className={classes.grid}>
      <TextField
        size='small'
        value={values[prop]}
        variant='outlined'
        fullWidth
        id={prop}
        InputLabelProps={{ shrink: true }}
        label={f({ id: labelConstants[prop] })}
        onChange={handleChange(prop)}
        error={error && values[prop].length === 0}
        disabled={disabled}
        InputProps={{
          inputProps: {
            type: 'number',
            min: 0,
            maxLength: 100,
          },
          startAdornment: startAdornment_ && (
            <InputAdornment position='start' disablePointerEvents>
              <Typography className={classes.startAdornment}>$</Typography>
            </InputAdornment>
          ),
          endAdornment: endAdornment_ && (
            <InputAdornment position='end' disablePointerEvents>
              <Typography className={classes.endAdornment}>
                <FormattedMessage id='miles-label' />
              </Typography>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  )
}

TierField_.propTypes = {
  prop: PropTypes.string,
  gridVal: PropTypes.number,
  handleChange: PropTypes.func,
  startAdornment_: PropTypes.bool,
  endAdornment: PropTypes.bool,
  values: PropTypes.object,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default TierField_
