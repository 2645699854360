import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import closeIcon from '../../../../images/close-icon.svg'

const useStyles = makeStyles((theme) => ({
  addressPart: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
    paddingRight: theme.spacing(),
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
  },
  iconButton: {
    width: 30,
    height: 30,
  },
  textField: {
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input': {
      padding: '4px 0px 4px 0px',
      fontSize: 16,
      fontWeight: 300,
      fontStyle: 'italic',
      color: '#707070',
      '&:not(:focus)': {
        cursor: 'pointer',
      },
    },
  },
  placeHolderTextField: {
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input': {
      fontStyle: 'italic',
      color: '#707070',
      '&:not(:focus)': {
        cursor: 'pointer',
      },
      '&::placeholder': {
        fontSize: 14,
        fontWeight: 300,
        fontStyle: 'italic',
      },
    },
  },
  currentAddress: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    color: '#707070',
    fontSize: 14,
    fontStyle: 'normal',
    marginBottom: theme.spacing(),
  },
}))

const Address = ({
  addressName,
  setAddressName,
  clearInputField,
  clearAptNoField,
  setSuiteNo,
  currentAddress,
  suiteNo,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const adornment = (func) => {
    return (
      <InputAdornment position='end'>
        <IconButton className={classes.iconButton} onClick={func}>
          <img src={closeIcon} alt='close' height='14px' width='14px' />
        </IconButton>
      </InputAdornment>
    )
  }
  return (
    <div className={classes.addressPart}>
      <Typography className={classes.header}>{currentAddress}</Typography>
      <TextField
        id='address-name'
        className={classes.textField}
        value={addressName}
        onChange={(e) => setAddressName(e.currentTarget.value)}
        InputProps={{ endAdornment: adornment(clearInputField) }}
      />
      <TextField
        id='apt-suite-floor'
        className={classes.placeHolderTextField}
        placeholder={formatMessage({ id: 'apt-suite' })}
        value={suiteNo}
        onChange={(e) => setSuiteNo(e.currentTarget.value)}
        content-type='placeholder'
        InputProps={{ endAdornment: adornment(clearAptNoField) }}
      />
    </div>
  )
}

Address.propTypes = {
  addressName: PropTypes.string,
  setAddressName: PropTypes.func,
  clearInputField: PropTypes.func,
  clearAptNoField: PropTypes.func,
  setSuiteNo: PropTypes.func,
  currentAddress: PropTypes.string,
  suiteNo: PropTypes.string,
}

export default Address
