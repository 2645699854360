import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { isMobileOnly } from 'react-device-detect'

const useStyles = makeStyles((theme) => ({
  select: {
    borderRadius: 10,
    marginTop: 5,
    objectFit: 'contain',
    border: 'solid 1px #69e781',
    fontSize: 16,
    textAlign: 'right',
    fontFamily: 'Roboto',
    width: 55,
    height: 30,
    color: 'white',
    backgroundColor: '#69e781',
    paddingLeft: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    '&:focus': {
      borderRadius: 10,
      background: '#69e781',
      borderColor: '#69e781',
    },
  },
  paper: {
    borderRadius: 12,
    width: 55,
    height: 110,
    marginTop: 5,
    left: isMobileOnly && '8px !important',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  list: {
    maxHeight: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
    '& li': {
      paddingTop: 15,
      paddingBottom: 12,
      color: '#707070',
    },
  },
  icon: {
    color: 'white',
    top: 15,
    right: 0,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none',
  },
}))

export const GreenSelect = ({ loading, menu, index, handleQuantityChange }) => {
  const classes = useStyles()

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  }

  return (
    <FormControl style={{ maxWidth: 55 }}>
      <Select
        disableUnderline
        disabled={loading}
        classes={{ root: classes.select }}
        MenuProps={menuProps}
        IconComponent={() => <ExpandMoreIcon className={classes.icon} />}
        value={menu.quantity}
        onChange={(e) => handleQuantityChange(e, index)}
      >
        {Array.from({ length: 100 }, (_, idx) => `${++idx}`).map((count, index) => (
          <MenuItem style={{ backgroundColor: 'transparent', height: 50 }} value={count} key={index} disableGutters>
            {count}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

Select.propTypes = {
  loading: PropTypes.bool,
  menu: PropTypes.object,
  index: PropTypes.number,
  handleQuantityChange: PropTypes.func,
}

export default GreenSelect
