import axios from 'axios'
import { uiHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL

export const dietaryService = {
  getAll,
}

function getAll() {
  return axios.get(`${API_URL}/dietary/ui`, uiHeader()).then((dietaries) => {
    return dietaries.data
  })
}
