import moment from 'moment'

export const createStartDate = (period) => {
  let newStartDate = ''
  if (period === 100) {
    newStartDate = '2021-01-01'
  } else {
    newStartDate = moment().subtract(period, 'days').format('YYYY-MM-DD')
  }
  return { newStartDate }
}
