import applepay from '../images/payments/applepay.svg'
import mastercard from '../images/payments/mastercard.svg'
import visa from '../images/payments/visa.svg'
import paypal from '../images/payments/paypal.svg'
import amex from '../images/payments/amex.svg'
import diners from '../images/payments/diners.svg'
import discover from '../images/payments/discover.svg'
import jcb from '../images/payments/jcb.svg'
import googlepay from '../images/payments/googlepay.svg'
import unionpay from '../images/payments/unionpay.svg'

const cards = {
  visa: visa,
  mastercard: mastercard,
  paypal: paypal, // TODO: check brand name
  applepay: applepay, // TODO: check brand name
  googlepay: googlepay, //TODO: check brand name
  amex: amex,
  diners: diners,
  discover: discover,
  jcb: jcb,
  unionpay: unionpay,
}

const getCardIcon = (card) => cards[card?.brand]

export { getCardIcon }
