import React from 'react'

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Transition } from '../../utils/tools'
import Header from './OrderDialog/Header'
import Content from './OrderDialog/Content'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    height: '90vh',
    maxHeight: 1000,
  },
  headerShadow: {
    boxShadow: '0 4px 2px -2px lightgrey',
  },
  dialogContent: {
    padding: 0,
  },
}))

const OrderDialog = ({ data, onClose, openDialog, target }) => {
  const classes = useStyles()

  const currentData = data.length > 0 ? data.filter((item) => item.orderNumber === target)[0] : null

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      keepMounted={false}
      scroll='paper'
      TransitionComponent={Transition}
      classes={{ paper: classes.root }}
    >
      <DialogTitle className={classes.headerShadow}>
        <Header onClose={onClose} headerInfo={currentData?.orderNumber} />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Content data={currentData} />
      </DialogContent>
    </Dialog>
  )
}

export default OrderDialog
