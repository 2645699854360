import { initialQuery } from '../search.constants'

export const mainFilterInitState = {
  categoryIds: [],
  dietaryIds: [],
  orderByKey: initialQuery.orderByKey,
  pricey: initialQuery.pricey,
  rating: initialQuery.rating,
  distance: initialQuery.distance,
  orderByValue: initialQuery.orderByValue,
}
