import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'

import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { useMerchantHomeContext } from '../../../context/merchant/MerchantHome/main'
import { restaurantService } from '../../../services'
import { generalConstants } from '../../../constants'
import Loading from '../../common/loading'
import { alertActions } from '../../../actions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    marginTop: 70,
    height: '90vh',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
}))

const RestInfo = () => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const {
    values: { classesFromParent, openSwipeable },
  } = useMerchantHomeContext()
  const dispatch = useDispatch()

  const warningMsg = (msg) => dispatch(alertActions.error(msg))

  const restId = useSelector(({ merchantStores }) => merchantStores?.selectedRestaurant?.restaurantId)

  const [restData, setRestData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let active = true
    if (active) {
      restId && getRestInfo(restId)
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restId])

  const getRestInfo = (id) => {
    setLoading(true)
    restaurantService
      .getImageData(id)
      .then(
        (res) => {
          if (res.status === generalConstants.STATUS_OK) {
            setRestData(res.data)
            setLoading(false)
          }
          setLoading(false)
        },
        (error) => {
          let err = JSON.parse(JSON.stringify(error))
          warningMsg(err?.message)
          setLoading(false)
        }
      )
      .catch((err) => console.log(err))
  }

  const fieldsData = [
    {
      width: 12,
      label: 'restaurantName-label',
      prop: 'restaurantName',
    },
    {
      width: 12,
      label: 'description-label',
      prop: 'restaurantDescription',
    },
  ]

  return (
    <div
      className={clsx(classes.root, classesFromParent.pageContent, {
        [classesFromParent.pageContentShift]: openSwipeable,
      })}
    >
      <Loading open={loading} />
      <Grid container spacing={2}>
        {fieldsData.map((data, index) => (
          <Grid item key={index} xs={12} sm={12} md={data.width} lg={data.width} className={classes.gridItem}>
            <TextField
              size='small'
              variant='outlined'
              fullWidth
              label={f({ id: data.label })}
              value={restData ? restData[data.prop] : ''}
              inputProps={{
                readOnly: true,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default RestInfo
