import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import MerchantNoResult from '../../common/MerchantNoResult'
import {
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import close from '../../../../images/close.svg'
import Tooltip from '@material-ui/core/Tooltip'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Scrollbars } from 'react-custom-scrollbars'
import { Transition } from '../../../utils/tools'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    color: '#000',
    fontSize: 20,
  },
  addMenuDialog: {
    maxWidth: 450,
    minWidth: 450,
    maxHeight: '90%',
    minHeight: '80%',
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const NoResult = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <MerchantNoResult text='no-attribute' />
    </div>
  )
}

const AttributeListDialog = ({ isOpen, closeModal, attributes, menuAttributes, handleAttach }) => {
  const classes = useStyles()
  const [open, setOpen] = useState()

  const toggleOpen = (id) => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }
  if (attributes?.length === 0) return <NoResult />

  return (
    <div className={classes.root}>
      <Scrollbars style={{ height: '100%' }}>
        <Dialog
          maxWidth={'md'}
          scroll='paper'
          classes={{ paper: classes.addMenuDialog }}
          open={isOpen}
          onClose={closeModal}
          TransitionComponent={Transition}
          keepMounted={false}
        >
          <DialogTitle style={{ boxShadow: '0px 3px 2px 1px rgba(0, 0, 0, .1)' }}>
            <Grid item xs={12} className='centered-row'>
              <Typography variant='h5' component='h5' className={classes.title} style={{ fontSize: 18 }}>
                Attach attributes to menu
              </Typography>

              <div className='grow' />
              <IconButton size='small' aria-label='add to favorites' onClick={closeModal}>
                <img src={close} height='22' width='22' alt='Back' />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
            <List>
              {attributes?.map((attribute, i) => {
                const isAddedToMenu = menuAttributes?.some((m) => m.id === attribute.id)
                if (isAddedToMenu) {
                  return null
                }
                return (
                  <React.Fragment key={i}>
                    <ListItem divider>
                      <ListItemText primary={attribute.name} />
                      <ListItemSecondaryAction>
                        <Tooltip title='Attach Attribute to Menu'>
                          <IconButton aria-label='menu' onClick={() => handleAttach(attribute.id)}>
                            <PlaylistAddIcon />
                          </IconButton>
                        </Tooltip>
                        {attribute.values.length > 0 ? (
                          open === attribute.id ? (
                            <IconButton aria-label='expand-less' onClick={() => toggleOpen(attribute.id)}>
                              <ExpandLess />
                            </IconButton>
                          ) : (
                            <IconButton aria-label='expand-more' onClick={() => toggleOpen(attribute.id)}>
                              <ExpandMore />
                            </IconButton>
                          )
                        ) : (
                          <IconButton aria-label='disabled' disabled>
                            <ExpandMore style={{ color: 'transparent' }} />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                    {/* <Divider /> */}
                    <Collapse in={open === attribute.id} timeout='auto' unmountOnExit>
                      <List component='div' disablePadding>
                        {attribute.values.map((value, ind) => (
                          <ListItem key={ind} className={classes.nested}>
                            <ListItemText primary={value.name} secondary={`Price: $${value.price}`} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                )
              })}
            </List>
          </DialogContent>
        </Dialog>
      </Scrollbars>
    </div>
  )
}

AttributeListDialog.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  attributes: PropTypes.array,
  menuAttributes: PropTypes.array,
  handleAttach: PropTypes.func,
}

export default injectIntl(AttributeListDialog)
