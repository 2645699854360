import { bottomNavItems } from '../general.constants'
import food from '../../images/food-bottom.svg'
import foodActive from '../../images/food-bottom-active.svg'
import pickup from '../../images/pickup-bottom.svg'
import pickupActive from '../../images/pickup-bottom-active.svg'
import search from '../../images/search-bottom.svg'
import searchActive from '../../images/search-bottom-active.svg'
import orders from '../../images/orders-bottom.svg'
import ordersActive from '../../images/orders-bottom-active.svg'
import account from '../../images/account-bottom.svg'
import accountActive from '../../images/account-bottom-active.svg'

const tabBarMenu = [
  {
    value: bottomNavItems.FOOD,
    label: 'food-label',
    iconActive: foodActive,
    icon: food,
    path: 'food',
    isSigninRequired: false,
  },
  {
    value: bottomNavItems.PICKUP,
    label: 'pick-up',
    iconActive: pickupActive,
    icon: pickup,
    path: 'pickup',
    isSigninRequired: false,
  },
  {
    value: bottomNavItems.SEARCH,
    label: 'search-label',
    iconActive: searchActive,
    icon: search,
    path: 'search',
    isSigninRequired: false,
  },
  {
    value: bottomNavItems.ORDERS,
    label: 'orders-label',
    iconActive: ordersActive,
    icon: orders,
    path: 'orders',
    isSigninRequired: true,
  },
  {
    value: bottomNavItems.ACCOUNT,
    label: 'account-label',
    iconActive: accountActive,
    icon: account,
    path: 'account',
    isSigninRequired: true,
  },
]

export { tabBarMenu }
