import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { injectIntl } from 'react-intl'
import Footer from '../customer/Footers'
import logoIcon from '../../images/logo_text_white.svg'
import Signin from './signin'
import signinHamburger from '../../images/signin-hamburger.png'
import headerBg from '../../images/signin-header-pattern.svg'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'

const path = process.env.PUBLIC_URL
const height = window.innerHeight

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#fff',
    minHeight: height,
  },
  bar: {
    height: 80,
    width: '100%',
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${headerBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  container: {
    flexGrow: 1,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    margin: 'auto',
    left: 0,
    bottom: 0,
    right: 0,
  },
})

class SigninPage extends React.Component {
  render() {
    const { classes, signedIn } = this.props
    const from = this.props.location.state

    if (signedIn) {
      if (from !== undefined) {
        return <Redirect to={from} />
      }
      return <Redirect to='/customer' />
    }

    return (
      <React.Fragment>
        {/* <div className='mobile'>
      <MobileSignin />
    </div> */}
        <div className={clsx('desktop', [classes.root])}>
          <div className={classes.bar}>
            <div className='grow justify-content full-width'>
              <IconButton
                color='inherit'
                className={classes.logo}
                onClick={() => this.props.history.push(`${path}/`)}
                disableFocusRipple
                disableRipple
                aria-label='back'
              >
                <img src={logoIcon} alt='TookFresh' height='36' />
              </IconButton>
            </div>
          </div>
          <div className={clsx('justify-content', [classes.container])}>
            <Container maxWidth='lg'>
              <Grid container>
                <Hidden only='sm'>
                  <Grid item md={6} className={classes.column}>
                    <img src={signinHamburger} alt='signinHamburger' style={{ maxWidth: '100%' }} />
                  </Grid>
                </Hidden>
                <Grid item md={6} sm={12} className={classes.column}>
                  <Signin />
                </Grid>
              </Grid>
            </Container>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    signedIn: state.customer.signedIn,
  }
}

export default withStyles(styles)(withRouter(injectIntl(connect(mapStateToProps, null)(SigninPage))))
