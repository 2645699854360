import React from 'react'
import { history } from '../helpers'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
//import { Router, Route, Redirect, Switch } from 'react-router-dom'
import CustomerMain from './customer/customer_main'
import MerchantMain from './merchant/MerchantMain'
import BusinessRegister from './businessRegister/BusinessRegister'
import NoMatch from './customer/no_match'

const REL_P = process.env.PUBLIC_URL

class Home extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Redirect exact from={`${REL_P}/`} to={`${REL_P}/customer`} />
          <Route path={`${REL_P}/customer`} component={CustomerMain} />
          <Route path='/merchant' component={MerchantMain} />
          <Route path='/business-register/:token?' component={BusinessRegister} />
          <Route path='*' component={NoMatch} />
          {/* <Redirect exact from={`${REL_P}/`} to={`${REL_P}/merchant/signin`} />
          <Route path='/merchant' component={MerchantMain} />
          <Route path='/business-register/:token?' component={BusinessRegister} />
          <Route path='*' component={NoMatch} /> */}
        </Switch>
      </Router>
    )
  }
}

export default Home
