import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import verifiedIcon from '../../../../images/verified.svg'
import notVerifiedIcon from '../../../../images/not-verified.svg'
import VisaBlack from '../../../../images/visa-black.svg'

import { GreenButton } from '../../../utils/tools'

import Fields from './Fields'

const stars = '* * * *'

const LeftSide = ({
  classes,
  handleNameChange,
  customer,
  handleEmailVerification,
  goToPayments,
  loading,
  lastFour,
}) => {
  const { formatMessage: f } = useIntl()

  return (
    <Box className={classes.block}>
      <Box className={classes.flexSpace} is-max='true'>
        <Fields isSmall label={f({ id: 'first-name' })} firstName handleClick={handleNameChange}>
          <Typography className={classes.text} noWrap>
            {customer?.customerInfo.firstName}
          </Typography>
        </Fields>
        <Fields isSmall label={f({ id: 'last-name' })} handleClick={handleNameChange}>
          <Typography className={classes.text} noWrap>
            {customer?.customerInfo.lastName}
          </Typography>
        </Fields>
      </Box>
      <Fields label={f({ id: 'email-label-short' })} handleClick={handleEmailVerification} isSpace>
        <Typography className={classes.text} is-light='true'>
          {customer?.customerInfo.email}
        </Typography>
        <img
          src={customer?.customerInfo.emailVerified ? verifiedIcon : notVerifiedIcon}
          alt=''
          height='18'
          width='18'
        />
      </Fields>
      {lastFour ? (
        <Fields label={f({ id: 'payment-label' })} handleClick={goToPayments} isSpace>
          <img src={VisaBlack} alt='' style={{ opacity: 0.7 }} />
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.stars} is-first='true'>
              {stars}
            </Typography>
            <Typography className={classes.stars}>{stars}</Typography>
            <Typography className={classes.stars}>{stars}</Typography>
            <Typography className={classes.text} last-four='true'>
              {lastFour}
            </Typography>
          </Box>
        </Fields>
      ) : (
        !loading && (
          <GreenButton fullWidth className={classes.greenButton} onClick={goToPayments}>
            {f({ id: 'new-payment-method' })}
          </GreenButton>
        )
      )}
    </Box>
  )
}

LeftSide.propTypes = {
  classes: PropTypes.object,
  handleNameChange: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  handleEmailVerification: PropTypes.func.isRequired,
  goToPayments: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  lastFour: PropTypes.string,
}

export default LeftSide
