import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setMainMenu, alertActions } from '../../../actions'
import { orderStatuses, bottomNavItems, generalConstants, deliveryTypes, PENDING_PAYMENT } from '../../../constants'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import { orderService, storeService } from '../../../services'
import { ratingService } from '../../../services/rating.services'
import { Transition } from '../../utils/tools'
import RateOrders from './orders-main/RateOrders'
import Receipt from './orders-main/Receipt'
import Dialog from '@material-ui/core/Dialog'
import withWidth from '@material-ui/core/withWidth'
import { ratingCreator } from '../../../helpers/orders'
import Loading from '../../common/loading'
import useStyles from './styles'
import DesktopOrders from './orders-main/DesktopOrders'
import MobileOrders from './orders-main/MobileOrders'
import MobileDialog from '../../common/mobile-dialog'
import { getRatingQuestions } from '../../../helpers/orders/get-rating-questions'

const path = process.env.PUBLIC_URL

export const OrdersMain = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const setMenu = (menu) => dispatch(setMainMenu(menu))
  const errorMsg = (msg) => dispatch(alertActions.error(msg))

  const signedIn = useSelector(({ customer }) => customer.signedIn)

  const [mobileTabValue, setMobileTabValue] = useState(1)
  const [previousOrders, setPreviousOrders] = useState([])
  const [upcomingOrders, setUpcomingOrders] = useState([])
  const [showRatingDialog, setShowRatingDialog] = useState(false)
  const [showReceiptDialog, setShowReceiptDialog] = useState(false)
  const [showCancelOrderDialog, setShowCancelOrderDialog] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [questions, setQuestions] = useState([])
  const [verticalTabValue, setVerticalTabValue] = useState(0)
  const [relatedQuestions, setRelatedQuestions] = useState(null)
  const [ratings, setRatings] = useState({})
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [storeDetails, setStoreDetails] = useState(null)

  useEffect(() => {
    !signedIn && history.push(`${path}/customer/food`)
  }, [signedIn])

  useEffect(() => {
    setMenu(bottomNavItems.ORDERS)
    getUserOrders()
  }, [refresh])

  const getUserOrders = async () => {
    try {
      setLoading(true)
      const response = await orderService.getAllOrders()
      if (response.status === generalConstants.STATUS_OK) {
        const nonPendingDeliveryOrders = response.data.filter(
          (order) => !(order.orderType === deliveryTypes.DELIVERY && order.status.label === PENDING_PAYMENT)
        )
        const previous = nonPendingDeliveryOrders.filter(
          (order) =>
            order.status.name === orderStatuses.DELIVERED ||
            order.status.name === orderStatuses.CANCELED ||
            order.status.name === orderStatuses.ORDER_REJECTED
        )
        const upcoming = nonPendingDeliveryOrders.filter(
          (order) =>
            order.status.name !== orderStatuses.DELIVERED &&
            order.status.name !== orderStatuses.CANCELED &&
            order.status.name !== orderStatuses.ORDER_REJECTED
        )
        setPreviousOrders(previous)
        setUpcomingOrders(upcoming)
        if (upcoming.length > 0) {
          const { storeIds } = getPreviousOrderIds(upcoming)
          const response = await storeService.getStoreAddressDetails(storeIds)
          if (response.status === generalConstants.STATUS_OK) {
            setStoreDetails(response.data)
          }
        }
      }
      await getOrderQuestions()
    } catch (error) {
      // handle error here
    } finally {
      setLoading(false)
    }
  }

  const getPreviousOrderIds = (orders) => {
    let storeIds = ''
    orders?.forEach((order, i) => {
      storeIds += `${order?.store?.id}${i !== orders.length - 1 ? ',' : ''}`
    })
    return { storeIds }
  }

  const getOrderQuestions = async () => {
    setLoading(true)
    const response = await ratingService.getOrderQuestions()
    if (response.status === generalConstants.STATUS_OK) {
      setQuestions(response.data.content)
    } else {
      //handle error here
    }
    setLoading(false)
  }

  const handleOrderRating = (order) => {
    const relatedQuestions = getRatingQuestions(order, questions)
    let ratingss = ratingCreator(relatedQuestions)
    setSelectedOrder(order)
    setRelatedQuestions(relatedQuestions)
    setRatings(ratingss)
    setTimeout(() => {
      toggleRatingDialog()
    }, 100)
  }

  const handleOrderReceipt = (order) => {
    setSelectedOrder(order)
    toggleReceiptDialog()
  }

  const handleTabChange = (e, newValue) => setMobileTabValue(newValue)

  const handleVerticalTabChange = (e, newValue) => setVerticalTabValue(newValue)

  // const handleTabIndexChange = (i) => setMobileTabValue(i)

  const toggleRatingDialog = (i) => setShowRatingDialog((prev) => !prev)

  const toggleReceiptDialog = (i) => setShowReceiptDialog((prev) => !prev)

  const handleOpenCancelOrderDialog = (order) => {
    setShowCancelOrderDialog(true)
    setSelectedOrder(order)
  }

  const handleCloseCancelOrderDialog = () => {
    setShowCancelOrderDialog(false)
    setSelectedOrder(null)
  }

  const handleCancelOrder = async () => {
    try {
      setLoading(true)
      const response = await orderService.cancelOrder(selectedOrder.id)
      if (response.status === generalConstants.STATUS_OK) {
        closeOrderAndRefresh()
      }
    } catch (error) {
      errorMsg(error.response?.data?.error_message)
      setTimeout(() => {
        closeOrderAndRefresh()
      }, 2000)
    } finally {
      setLoading(false)
    }
  }

  const closeOrderAndRefresh = () => {
    handleCloseCancelOrderDialog()
    setRefresh((prev) => +prev + 1)
  }

  return (
    <div className={isMobileOnly ? classes.mobileRoot : classes.root}>
      <Loading open={loading} />
      {!isMobileOnly ? (
        <DesktopOrders
          loading={loading}
          verticalTabValue={verticalTabValue}
          upcomingOrders={upcomingOrders}
          previousOrders={previousOrders}
          handleVerticalTabChange={handleVerticalTabChange}
          handleOrderRating={handleOrderRating}
          getUserOrders={getUserOrders}
          handleOrderReceipt={handleOrderReceipt}
          openCancelDialog={handleOpenCancelOrderDialog}
          storeDetails={storeDetails}
        />
      ) : (
        <MobileOrders
          mobileTabValue={mobileTabValue}
          handleTabChange={handleTabChange}
          upcomingOrders={upcomingOrders}
          previousOrders={previousOrders}
          handleOrderRating={handleOrderRating}
          handleOrderReceipt={handleOrderReceipt}
          openCancelDialog={handleOpenCancelOrderDialog}
          storeDetails={storeDetails}
        />
      )}
      <Dialog
        fullScreen={isMobileOnly}
        maxWidth={isMobileOnly ? null : 'lg'}
        className={classes.dialog}
        open={showRatingDialog}
        onClose={toggleRatingDialog}
        TransitionComponent={Transition}
      >
        <RateOrders
          handleClose={toggleRatingDialog}
          order={selectedOrder}
          questions={relatedQuestions}
          rating={ratings}
          getOrders={getUserOrders}
        />
      </Dialog>
      <Dialog
        fullScreen={isMobileOnly}
        maxWidth={isMobileOnly ? null : 'lg'}
        open={showReceiptDialog}
        onClose={toggleReceiptDialog}
        TransitionComponent={Transition}
        scroll='paper'
      >
        <Receipt handleClose={toggleReceiptDialog} order={selectedOrder} />
      </Dialog>
      <Dialog
        classes={{ paper: classes.generalDialogRoot }}
        open={showCancelOrderDialog}
        onClose={handleCloseCancelOrderDialog}
        TransitionComponent={Transition}
      >
        <MobileDialog
          close={handleCloseCancelOrderDialog}
          closeIcon={false}
          header={'are-you-sure'}
          mainText={'about-to-cancel-order'}
          leftBtnAction={handleCloseCancelOrderDialog}
          rightBtnAction={handleCancelOrder}
          // id={address.id}
          sizeW={'200px'}
          sizeH={'200px'}
          img={'deleteAddressImage'}
          greenButton={false}
          leftButton={'cancel-label'}
          rightButton={'proceed-label'}
        />
      </Dialog>
    </div>
  )
}

OrdersMain.propTypes = {
  history: PropTypes.object,
}

export default withRouter(withWidth()(OrdersMain))
