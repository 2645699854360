import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { deliveryTypes } from '../../../../../../constants'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 21,
    width: '100%',
    marginLeft: theme.spacing(4),
  },
  cancelButton: {
    width: 44,
    padding: 0,
    marginRight: theme.spacing(2),
  },
  addButton: {
    width: 32,
    padding: 0,
  },
}))

const ButtonsArea = ({ handleDialogClose, addressSelected, handleAddClick, typ, subTyp }) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()

  let buttonDisableStatus = !addressSelected ? true : typ === deliveryTypes.DELIVERY && subTyp === '' ? true : false

  return (
    <div className={classes.buttonContainer}>
      <Button disableRipple className={classes.cancelButton} onClick={handleDialogClose}>
        {f({ id: 'cancel-label' })}
      </Button>
      <Button disableRipple className={classes.addButton} onClick={handleAddClick} disabled={buttonDisableStatus}>
        {f({ id: 'add-label' })}
      </Button>
    </div>
  )
}

ButtonsArea.propTypes = {
  handleDialogClose: PropTypes.func,
  addressSelected: PropTypes.bool,
  handleAddClick: PropTypes.func,
  typ: PropTypes.string,
  subTyp: PropTypes.string,
}

export default ButtonsArea
