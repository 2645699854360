import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { initialOptionsState } from '../../../../constants'
import { Grid, makeStyles } from '@material-ui/core'
import { BlackButton, GreenButton } from '../../../utils/tools'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { attributeActions } from '../../../../actions'
import AttributeValueList from './AttributeValueList'
import { useToggle } from '../../../../hooks/useToggle'
import DeleteDialog from './DeleteDialog'
import AddValueFields from './add-value/AddValueFields'

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
  row: {
    paddingBottom: theme.spacing(),
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
    minWidth: 90,
  },
}))

const AddAttributeValue = ({ onClose, intl, item, setEditValue }) => {
  const classes = useStyles()
  const [attributeOptions, setAttributeOptions] = useState(initialOptionsState)
  const [benchmark, setBenchmark] = useState()
  const [showValueDeleteDialog, setShowValueDeleteDialog] = useToggle()
  const [isEditActive, setIsEditActive] = useState(false)
  const [showAddAttribute, setShowAddAttribute] = useState(false)
  // const [error, setError] = useState(false)

  const selectedStore = useSelector(({ merchantStores }) => merchantStores.selectedStore)
  const selectedAttributeValues = useSelector(({ attributes }) => attributes.selectedAttribute?.values)

  const dispatch = useDispatch()
  const createValue = async (storeId, attributeId, value) =>
    dispatch(attributeActions.createAttributeValue(storeId, attributeId, value))
  const update = async (storeId, attributeId, valueId, value) =>
    dispatch(attributeActions.updateAttributeValue(storeId, attributeId, valueId, value))
  const deleteValue = async (storeId, attributeId, valueId) =>
    dispatch(attributeActions.deleteAttributeValue(storeId, attributeId, valueId))

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    // if (name === 'price') {
    //   if (!value || value < limits.MINCOUNT) {
    //     setError(true)
    //   } else {
    //     error && setError(false)
    //   }
    // }
    setAttributeOptions({ ...attributeOptions, [name]: value })
  }

  const onFormSubmit = async () => {
    isEditActive ? await updateValue() : await createNewValue()
  }

  const createNewValue = async () => {
    attributeOptions.order_ = selectedAttributeValues.length
    await createValue(selectedStore?.storeId, item.id, attributeOptions)
    setAttributeOptions(initialOptionsState)
    // setError(false)
  }

  const updateValue = async () => {
    await update(selectedStore?.storeId, item.id, attributeOptions.id, attributeOptions)
    setBenchmark()
    setIsEditActive(false)
    setShowAddAttribute(false)
    setAttributeOptions(initialOptionsState)
    // setError(false)
    setEditValue(false)
  }

  const showDeleteAttributeValueDialog = (val) => {
    setAttributeOptions(val)
    setShowValueDeleteDialog()
  }

  const editAttributeValue = (val) => {
    setShowAddAttribute(true)
    setIsEditActive(true)
    setAttributeOptions(val)
    setBenchmark(val)
    setEditValue(true)
  }

  const handleDeleteValue = async () => {
    await deleteValue(selectedStore?.storeId, item.id, attributeOptions.id)
    setAttributeOptions(initialOptionsState)
    setShowValueDeleteDialog()
    // setError(false)
  }

  const closeDeleteDialog = () => {
    setShowValueDeleteDialog()
    setAttributeOptions(initialOptionsState)
    // setError(false)
  }

  const handleCancelButton = () => {
    setShowAddAttribute(false)
    setAttributeOptions(initialOptionsState)
    isEditActive && setIsEditActive(false)
    // onClose()
  }

  return (
    <Grid container className={classes.root}>
      <Grid xs={12} item className={classes.row}>
        <AttributeValueList
          values={selectedAttributeValues}
          editValue={editAttributeValue}
          deleteValue={showDeleteAttributeValueDialog}
        />
      </Grid>
      {showAddAttribute ? (
        <AddValueFields
          attributeOptions={attributeOptions}
          benchmark={benchmark}
          handleChange={handleChange}
          onFormSubmit={onFormSubmit}
          isEditActive={isEditActive}
          onClose={handleCancelButton}
          // error={error}
        />
      ) : (
        <Grid xs={12} item className={classes.actionRow}>
          <BlackButton className={classes.button} onClick={onClose}>
            {getLabel('cancel-label')}
          </BlackButton>
          <GreenButton className={classes.button} onClick={setShowAddAttribute}>
            {getLabel('add-value')}
          </GreenButton>
        </Grid>
      )}

      <DeleteDialog
        showConfirm={showValueDeleteDialog}
        handleConfirmClose={closeDeleteDialog}
        handleDelete={handleDeleteValue}
        item={attributeOptions?.name}
        text='about-delete-attribute-value'
      />
    </Grid>
  )
}

AddAttributeValue.propTypes = {
  onClose: PropTypes.func,
  setEditValue: PropTypes.func,
  intl: PropTypes.object,
  item: PropTypes.object,
}

export default injectIntl(AddAttributeValue)
