import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Typography from '@material-ui/core/Typography'

import { valueConstants } from '../../../businessRegister/businessConstants'

import CheckBoxArea from './DeliveryOptions/CheckBoxArea'
import SenpexDelivery from './DeliveryOptions/SenpexDelivery'
import OwnBusinessDelivery from './DeliveryOptions/OwnBusinessDelivery'

const DeliveryOptions = ({
  classes,
  store,
  setStore,
  isFocused,
  isInvalid,
  setIsFocused,
  setIsInvalid,
  editting,
  businessInvalidState,
  setBusinessInvalidState,
  businessFocusedState,
  setBusinessFocusedState,
  tierValues,
  setTierValues,
}) => {
  const { formatMessage: f } = useIntl()

  return (
    <>
      <div className={classes.header}>
        <Typography variant='h5'>{f({ id: 'delivery-options-label' })}</Typography>
      </div>
      <CheckBoxArea store={store} setStore={setStore} editting={editting} />
      {store.deliveryBy === valueConstants.tookfresh ? (
        <SenpexDelivery
          store={store}
          isFocused={isFocused}
          isInvalid={isInvalid}
          setIsFocused={setIsFocused}
          setIsInvalid={setIsInvalid}
          setStore={setStore}
          editting={editting}
        />
      ) : (
        <OwnBusinessDelivery
          editting={editting}
          businessInvalidState={businessInvalidState}
          setBusinessInvalidState={setBusinessInvalidState}
          businessFocusedState={businessFocusedState}
          setBusinessFocusedState={setBusinessFocusedState}
          tierValues={tierValues}
          setTierValues={setTierValues}
        />
      )}
    </>
  )
}
DeliveryOptions.propTypes = {
  classes: PropTypes.object,
  store: PropTypes.object,
  setStore: PropTypes.func,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  setIsFocused: PropTypes.func,
  setIsInvalid: PropTypes.func,
  editting: PropTypes.bool,
  businessInvalidState: PropTypes.object,
  setBusinessInvalidState: PropTypes.func,
  businessFocusedState: PropTypes.object,
  setBusinessFocusedState: PropTypes.func,
  tierValues: PropTypes.object,
  setTierValues: PropTypes.func,
}

export default DeliveryOptions
