import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import { GreenCheckbox } from '../../../utils/tools'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#121212',
    margin: theme.spacing(),
  },
  checks: {
    maxWidth: 600,
  },
}))

const Categories = ({ categories, menu, intl, handleChange }) => {
  const classes = useStyles()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <>
      <Grid item sm={12}>
        <Typography className={classes.label}>{getLabel('categories-label')}</Typography>
      </Grid>
      <Grid item sm={12}>
        <div className={classes.checks}>
          <Grid container>
            {categories.map((category) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={category.id}>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={menu.categories.some((c) => c === category.id)}
                      onChange={handleChange}
                      name={category.id.toString()}
                    />
                  }
                  label={category.name}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </>
  )
}

Categories.propTypes = {
  categories: PropTypes.array,
  menu: PropTypes.object,
  intl: PropTypes.object,
  handleChange: PropTypes.func,
}

export default injectIntl(Categories)
