import { isEqual } from 'lodash'

const deepCheck = (itemToBeAdded, currentItems) => {
  let result = [false, null]

  //If there is no menuItems in cart, newMenuItem can be added without deep check
  if (currentItems.length === 0) return result

  //Check in cart, is there any menuItem whose menuId equals to newMenuItem's id
  let included = false
  currentItems.forEach((item) => {
    item.menuId === itemToBeAdded.menuId && (included = true)
  })

  //If there is no match btw menuItems's id in cart and newMenuItem's id, add newMenuItem to cart without deep check
  if (!included) return result

  if (included) {
    //find matched items by comparing ids
    let menuItemsMatched = currentItems.filter((item) => item.menuId === itemToBeAdded.menuId)

    //find matched items among id-matched ones by comparing special instructions parts
    let siMatchedItems = menuItemsMatched.filter(
      (item) => item.specialInstructions === itemToBeAdded.specialInstructions
    )
    if (siMatchedItems.length === 0) return result

    //find matched items among special-instructions-matched items by comparing attribute lengths
    let attrLengthMatchedItems = siMatchedItems.filter(
      (item) => item.attributes.length === itemToBeAdded.attributes.length
    )
    if (attrLengthMatchedItems.length === 0) return result

    //check if attributes are same
    attrLengthMatchedItems.forEach((attrLengthMatchedItem) => {
      if (isEqual(attrLengthMatchedItem.attributes, itemToBeAdded.attributes)) {
        result = [true, attrLengthMatchedItem]
      }
    })
    return result
  }
}

export { deepCheck }
