import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography, ButtonBase } from '@material-ui/core'
import { getCardIcon } from '../../../../../helpers/card-icons'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles((theme) => ({
  cartContainer: {
    width: '100%',
    // paddingLeft: theme.spacing(),
    // paddingRight: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  cart: {
    textTransform: 'capitalize',
    color: '#000',
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    borderRadius: 10,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 6,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
}))

const AvailableCards = ({ item, selectedCard, selectPaymentMethod }) => {
  const classes = useStyles()
  return (
    <div className={classes.cartContainer}>
      <ButtonBase className='full-width' onClick={() => selectPaymentMethod(item.id)}>
        <div className={classes.cart} style={{ backgroundColor: selectedCard !== item.id ? '#e6e6e6' : null }}>
          <img src={getCardIcon(item.card)} alt='cart' width='45' height='45' />

          {/* <Typography>{item.card.brand}</Typography> */}
          <div className='centered-row'>
            {Array(12)
              .fill(0)
              .map((e, i) => (
                <div
                  key={i}
                  className=''
                  // variant='h4'
                  style={{
                    color: 'white',
                    marginRight: (i + 1) % 4 === 0 ? 10 : 0,
                    fontSize: 28,
                  }}
                >
                  *
                </div>
              ))}
            <Typography variant='h5' style={{ color: 'white' }}>
              {item.card.last4}
            </Typography>
          </div>
          {selectedCard === item.id ? (
            <CheckIcon style={{ color: '#fff' }} />
          ) : (
            <CheckIcon style={{ color: 'transparent' }} />
          )}
        </div>
      </ButtonBase>
    </div>
  )
}

AvailableCards.propTypes = {
  item: PropTypes.object,
  selectedCard: PropTypes.string,
  selectPaymentMethod: PropTypes.func,
}

export default AvailableCards
