import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import SectionHeader from './SectionHeader'
import { CardElement } from '@stripe/react-stripe-js'
import AvailableCards from './AvailableCards'
import clsx from 'clsx'
import { Scrollbars } from 'react-custom-scrollbars'

const cardHeightWithMargin = 56

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  cardsContainer: {
    height: (props) => props.length * cardHeightWithMargin,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(),
    maxHeight: 168,
  },
}))

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

export const PaymentContainer = ({ showCheckoutForm, cards, selectedCard, onChange, ...props }) => {
  const classes = useStyles({ length: cards?.length })

  return (
    <Box className={classes.container}>
      <SectionHeader header='payment-methods' callback={props.toggleCheckoutForm} />
      {showCheckoutForm && (
        <Box mt={1}>
          <form onSubmit={props.handleSubmit}>
            <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChange} />
          </form>
        </Box>
      )}
      <Box className={clsx('centered-row', [classes.cardsContainer])}>
        <Scrollbars>
          {cards.map((item, i) => (
            <AvailableCards
              key={i}
              item={item}
              selectedCard={selectedCard}
              selectPaymentMethod={props.selectPaymentMethod}
            />
          ))}
        </Scrollbars>
      </Box>
    </Box>
  )
}

PaymentContainer.propTypes = {
  showCheckoutForm: PropTypes.bool,
  cards: PropTypes.array,
  selectedCard: PropTypes.string,
  onChange: PropTypes.func,
  props: PropTypes.object,
}

export default PaymentContainer
