import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Signin from './signin'
// import { Transition } from '../utils/tools'
import logo from '../../images/tookfrsh_logo.svg'
import backIcon from '../../images/white-back.svg'
import logoText from '../../images/logo_text_black.svg'
import bg from '../../images/mobile-signin-bg.png'
// import Dialog from '@material-ui/core/Dialog'
// import SetAddress from '../customer/mobile-address-set/SetAddress'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  back: {
    color: '#fff',
  },
  paper: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: '#fff',
    padding: theme.spacing(2),
  },
  skip: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'capitalize',
    color: '#fff',
    lineHeight: 1.38,
    marginRight: theme.spacing(),
    height: 34,
  },
}))

const MobileSignin = ({ intl, handleClose, handleSignin, goToHome }) => {
  const classes = useStyles()

  const searchQuery = useSelector(({ search }) => search.searchQuery)

  const handleSigninClose = () => handleSignin()

  const handleBackButton = () => handleClose()

  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }
  return (
    <div className={clsx('mobile-fullscreen', classes.root)}>
      <div className='centered-row'>
        <IconButton onClick={handleBackButton} className={classes.back}>
          <img src={backIcon} alt='back-button' />
        </IconButton>
        <div className='grow' />
        {!searchQuery.address && (
          <Button className={classes.skip} onClick={goToHome}>
            {/* redirect to main screen */}
            {getLabel('skip-label')}
          </Button>
        )}
      </div>
      <div className='grow justify-center'>
        <div className='centered-row'>
          <img src={logo} alt='TookFresh' height='60' style={{ marginRight: 5 }} />
          <img src={logoText} alt='TookFresh' height='28' style={{ marginLeft: 5 }} />
        </div>
      </div>
      <div className={classes.paper}>
        <Signin handleClose={handleSigninClose} handleDialogClose={handleClose} />
      </div>
    </div>
  )
}

MobileSignin.propTypes = {
  intl: PropTypes.object,
  handleClose: PropTypes.func,
}

export default withRouter(injectIntl(MobileSignin))
