import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core'
import { searchService, storeService } from '../../../../services'
import { generalConstants } from '../../../../constants'
import { fetchMoreCheck } from '../../../../helpers/search'
import { favoriteStoreCheck } from '../../../../helpers/favorite-store'
import { categorySearchQueryState } from '../../../../constants/general.constants'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { AvailableStores, Banner, Title } from './category-search'
import Loading from '../../../common/loading'
import { useToggle } from '../../../../hooks/useToggle'
import AddressDialog from '../../../common/AddressDialog'
import { customerActions } from '../../../../actions'
import { mobileSearchScreenStateMaker } from '../../../../helpers/mobile-food/state-maker'
import { getOCStatusQuery } from '../../../../helpers/store/getOCStatusQuery'

const width = window.innerWidth

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: width,
    maxWidth: width,
    height: '100vh',
  },
}))

export const CategorySearchResult = ({ category, goBack, showSignin }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const searchQuery = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const signedIn = useSelector(({ customer }) => customer.signedIn)
  const favoriteStores = useSelector(({ customer }) => customer.favoriteStores, shallowEqual)
  const globalLoading = useSelector(({ customer }) => customer.loading)

  const saveFavorite = (id) => dispatch(customerActions.saveFavoriteStore(id))
  const deleteFavorite = (id) => dispatch(customerActions.deleteFavoriteStore(id))

  const [fetchMoreData, setFetchMoreData] = useState(true)
  const [query, setQuery] = useState(categorySearchQueryState)
  const [searchResult, setSearchResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [showNoResult, setShowNoResult] = useToggle()
  const [showAddressDialog, setShowAddressDialog] = useState(false)
  const [openStores, setOpenStores] = useState([])

  useEffect(() => {
    const init = async () => {
      const searchedAddress = await JSON.parse(localStorage.getItem(generalConstants.SEARCHED_ADDRESSES))
      if (searchedAddress) {
        queryMaker()
      } else {
        toggleAddressDialog()
        return null
      }
    }
    init()
  }, [searchQuery.address])

  useEffect(() => {
    query.categoryIds?.length > 0 && doSearch()
  }, [query.categoryIds?.length])

  const toggleAddressDialog = () => setShowAddressDialog((prev) => !prev)

  const queryMaker = () => {
    const newState = mobileSearchScreenStateMaker(searchQuery, category)
    setQuery(newState)
  }

  useEffect(() => {
    let active = true
    if (active) {
      searchResult?.length > 0 && getOpenStores(searchResult)
    }
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult])

  const getOpenStores = async (stores) => {
    let storeIds = stores.map((store) => store.id)
    const query = getOCStatusQuery(searchQuery, storeIds)
    const response = await storeService.getStoreOCStatus(query)
    response && getOpenStoreIdsFromResponse(response)
  }

  const getOpenStoreIdsFromResponse = (response) => {
    const rawStoreOpenStatus = response?.filter((res) => res.open)
    const storeOpenStatus = rawStoreOpenStatus.map((item) => item.storeId)
    setOpenStores(storeOpenStatus)
  }

  const doSearch = async () => {
    setLoading(true)
    await searchService.search(query).then((res) => {
      if (res.status === generalConstants.STATUS_OK) {
        res.data.stores.length === 0 && setShowNoResult()
        let newSearchResult = query.customOffset === 0 ? res.data.stores : [...searchResult, ...res.data.stores]
        let newSearchQuery = { ...query, customOffset: res.data.customOffset }
        setSearchResult(newSearchResult)
        setQuery(newSearchQuery)
        res.data.stores.length === 0 && setFetchMoreData(false)
      }
    })
    setLoading(false)
  }

  const fetchMore = () => {
    const { size, customOffset } = query
    const fetchMore = fetchMoreCheck(size, customOffset, searchResult)
    if (!fetchMore || !fetchMoreData) return
    doSearch()
  }

  const favoriteHandler = (id) => {
    if (!signedIn) {
      showSignin()
      return null
    }
    const { includes, storeId } = favoriteStoreCheck(id, favoriteStores)
    includes ? deleteFavorite(storeId) : saveFavorite(id)
  }

  return (
    <div className={classes.root}>
      <AddressDialog open={showAddressDialog} close={toggleAddressDialog} />
      <Loading open={loading || globalLoading} />
      <Banner category={category} goBack={goBack} />
      <Title searchResult={searchResult} />
      <AvailableStores
        showNoResult={showNoResult}
        searchResult={searchResult}
        category={category}
        favoriteHandler={favoriteHandler}
        favoriteStores={favoriteStores}
        fetchMore={fetchMore}
        openStores={openStores}
      />
    </div>
  )
}

CategorySearchResult.propTypes = {
  category: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
}

export default withRouter(injectIntl(CategorySearchResult))
