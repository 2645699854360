import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Box, makeStyles, Typography } from '@material-ui/core'
import favoritedIcon from '../../../images/green-favorite.svg'
import address from '../../../images/marker-gray.svg'
import deliveryTimeIcon from '../../../images/delivery-time.svg'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { deliveryTypes, deliveryTimes } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  back: {
    position: 'absolute',
    top: 5,
    left: theme.spacing(),
  },
  mobileContent: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    justifyContent: 'center',
    paddingTop: theme.spacing(),
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
  },
  content: {
    height: '100%',
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
  },
  favorite: {
    height: 20,
    width: 20,
    filter: 'drop-shadow(1px 1px 5px gray)',
  },
  favoriteBack: {
    height: 20,
    width: 40,
  },
  favoriteButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    height: 30,
    width: 30,
    zIndex: 10,
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  categoryBlock: {
    // paddingRight: theme.spacing(1),
  },
  deliveryBlock: {
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#333',
  },
  category: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#333',
    opacity: 0.5,
  },
  dialog: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    marginBottom: theme.spacing(2),
    height: 120,
  },
  close: {
    color: 'rgb(97, 26, 21)',
    fontSize: 14,
    fontWeight: 500,
  },
}))

const StoreDetails = ({ data, favorite, search, storeOpen }) => {
  const classes = useStyles()
  const { formatMessage: format } = useIntl()
  const deliveryType = useSelector(({ search }) => search.searchQuery.deliveryType)
  const deliveryTime = useSelector(({ search }) => search.searchQuery.deliveryTime)

  const isDelivery = deliveryType === deliveryTypes.DELIVERY
  const isASAP = deliveryTime === deliveryTimes.ASAP

  return (
    <Box width={'70%'} className={clsx('column-space ', classes.content)}>
      <div className={clsx('centered-row', classes.titleBlock)}>
        <Typography className={classes.title} noWrap>
          {data.nickname}
        </Typography>
        {favorite && <img src={favoritedIcon} alt='' className={classes.favorite} />}
        {search && <div className={classes.favoriteBack} />}
      </div>

      <div className={clsx('centered-row', classes.categoryBlock)}>
        <Typography noWrap display='initial' variant='caption' className={classes.category}>
          {data.categories.map((category, i) => {
            return i === data.categories.length - 1 ? category.name : `${category.name}, `
          })}
        </Typography>
      </div>

      <div className={clsx('centered-row', classes.categoryBlock)}>
        <img src={address} alt='' style={{ opacity: 0.8 }} height='16' />
        <Typography noWrap display='initial' variant='caption' className={classes.category}>
          {data.storeInformation.address.zipCode} {data.storeInformation.address.addressLine1}
        </Typography>
      </div>

      <div className={clsx('centered-row', classes.deliveryBlock)}>
        {storeOpen ? (
          data.deliveryTime !== -1 &&
          isDelivery &&
          isASAP && (
            <div className='centered-row'>
              <img
                src={deliveryTimeIcon}
                alt='Delivery time'
                width='14.2'
                height='14.2'
                className={classes.icon}
                style={{ opacity: 0.5, marginRight: 5 }}
              />
              <Typography variant='body2' color='textSecondary' component='p'>
                {`${data.deliveryTime} mins`}
              </Typography>
            </div>
          )
        ) : (
          <Typography variant='body2' component='p' className={classes.close}>
            {format({ id: 'closed-label' })}
          </Typography>
        )}

        {/* <div className='centered-row'>
            <img
              src={deliveryFee}
              alt='Delivery fee'
              width='16'
              className={classes.icon}
              style={{ opacity: 0.5, marginRight: 5 }}
            />
            <Typography variant='body2' color='textSecondary' component='p'>
              {data.deliveryFee} delivery fee
            </Typography>
          </div> */}
      </div>
    </Box>
  )
}

StoreDetails.propTypes = {
  data: PropTypes.object,
  search: PropTypes.bool,
  favorite: PropTypes.bool,
  storeOpen: PropTypes.bool,
}

export default StoreDetails
