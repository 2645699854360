import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { searchActions } from '../../../../..//actions'
import { GreenRadio } from '../../../..//utils/tools'
import Label from '../common/label'

import { FormControlLabel, FormGroup } from '@material-ui/core'

const FormGroupItems = ({ length, classes, sliceFrom }) => {
  const dispatch = useDispatch()

  const categories = useSelector(({ categories }) => categories.data, shallowEqual)
  const searchData = useSelector(({ search }) => search.searchQuery, shallowEqual)

  const setCategorySearchFilter = (id) => dispatch(searchActions.setCategorySearchFilter(id))

  const handleCategoryChange = (event) => {
    let id = parseInt(event.target.name)
    setCategorySearchFilter(id)
  }

  return (
    <FormGroup>
      {categories?.slice(sliceFrom, length ? 5 : categories.length).map((category) => (
        <FormControlLabel
          key={category.id}
          control={
            <GreenRadio
              className={classes.check}
              checked={searchData.categoryIds.some((d) => d === category.id)}
              onChange={handleCategoryChange}
              name={category.id.toString()}
            />
          }
          label={<Label data={searchData.categoryIds} id={category.id} name={category.name} />}
        />
      ))}
    </FormGroup>
  )
}

export default FormGroupItems
