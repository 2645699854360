import { generalConstants } from '../../constants'

const createLatLongObject = (address) => {
  const latLong = { lat: +address.latitude, long: +address.longitude }
  return { latLong }
}

export const setLocalStorageLatLong = (address) => {
  const { latLong } = createLatLongObject(address)
  localStorage.setItem(generalConstants.LAT_LONG, JSON.stringify(latLong))
  localStorage.setItem(generalConstants.TIME_ZONE_OFFSET, JSON.stringify(address.timeZoneOffset))
}
