import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Grid, TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'

const MaxDelivery = ({ store, isFocused, isInvalid, intl, maxDeliveryHandler, editting }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl
    return formatMessage({ id: labelId })
  }

  return (
    <Grid item sm={6} xs={12}>
      <TextField
        size='small'
        variant='outlined'
        required
        fullWidth
        id='maxDelivery'
        label={getLabel('max-delivery-distance')}
        value={store.maxDeliveryDistance}
        onChange={maxDeliveryHandler}
        inputProps={{
          maxLength: 150,
          readOnly: !editting,
          type: 'number',
        }}
        InputProps={{
          startAdornment: <InputAdornment position='start'>mi</InputAdornment>,
        }}
        error={isFocused.maxDeliveryDistance && isInvalid.maxDeliveryDistance}
        helperText={isFocused.maxDeliveryDistance && isInvalid.maxDeliveryDistance ? getLabel('cannot-null') : ' '}
      />
    </Grid>
  )
}

MaxDelivery.propTypes = {
  store: PropTypes.object,
  isFocused: PropTypes.object,
  isInvalid: PropTypes.object,
  intl: PropTypes.object,
  maxDeliveryHandler: PropTypes.func.isRequired,
  editting: PropTypes.bool,
}

export default injectIntl(MaxDelivery)
