import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core'
import { generalConstants } from '../../../../constants'

import clsx from 'clsx'
import { InputForm, Recents, Suggestions } from './first-step'

const height = window.innerHeight

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: height,
    maxHeight: height,
  },
}))

const FirstStep = ({ keywordSearch, handleClose, intl }) => {
  const classes = useStyles()

  //Local states
  const [keyword, setKeyword] = useState('')
  const [searchOptions, setSearchOptions] = useState('')
  const [searchedKeywords, setSearchedKeywords] = useState(
    JSON.parse(localStorage.getItem(generalConstants.SEARCHED_KEYWORDS))
  )

  useEffect(() => {
    searchOptions && keywordSearch(keyword)
  }, [searchOptions])

  const selectWithKeyword = (key) => {
    setKeyword(key)
    //To fire keywordSearch func in useEffect
    setSearchOptions(key)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    keywordSearch(keyword)
  }

  const searchWithSuggestion = () => {
    keywordSearch(keyword)
  }

  const deleteRecentSearch = () => {
    localStorage.removeItem(generalConstants.SEARCHED_KEYWORDS)
    setSearchedKeywords(null)
  }

  return (
    <div className={clsx('column', [classes.root])}>
      <InputForm handleSubmit={handleSubmit} keyword={keyword} handleClose={handleClose} setKeyword={setKeyword} />
      <Suggestions keyword={keyword} search={searchWithSuggestion} />
      <Recents searchedKeywords={searchedKeywords} deleteRecents={deleteRecentSearch} search={selectWithKeyword} />
    </div>
  )
}

FirstStep.propTypes = {
  keywordSearch: PropTypes.func,
  handleClose: PropTypes.func,
  intl: PropTypes.object,
}

export default injectIntl(FirstStep)
