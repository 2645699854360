const workHoursMaker = (serviceHours) => {
  let workHours = []
  workHours.push.apply(workHours, serviceHours.DELIVERY.MONDAY)
  workHours.push.apply(workHours, serviceHours.DELIVERY.TUESDAY)
  workHours.push.apply(workHours, serviceHours.DELIVERY.WEDNESDAY)
  workHours.push.apply(workHours, serviceHours.DELIVERY.THURSDAY)
  workHours.push.apply(workHours, serviceHours.DELIVERY.FRIDAY)
  workHours.push.apply(workHours, serviceHours.DELIVERY.SATURDAY)
  workHours.push.apply(workHours, serviceHours.DELIVERY.SUNDAY)
  workHours.push.apply(workHours, serviceHours.TAKEOUT.MONDAY)
  workHours.push.apply(workHours, serviceHours.TAKEOUT.TUESDAY)
  workHours.push.apply(workHours, serviceHours.TAKEOUT.WEDNESDAY)
  workHours.push.apply(workHours, serviceHours.TAKEOUT.THURSDAY)
  workHours.push.apply(workHours, serviceHours.TAKEOUT.FRIDAY)
  workHours.push.apply(workHours, serviceHours.TAKEOUT.SATURDAY)
  workHours.push.apply(workHours, serviceHours.TAKEOUT.SUNDAY)
  return workHours
}

const removeWorkingHours = (str) => {
  const { workingHours, ...rest } = str
  const newObject = { ...rest }
  return newObject
}

export { workHoursMaker, removeWorkingHours }
