import axios from 'axios'
import { merchantHeader, merchantImageHeader } from '../helpers/config'

const API_URL = process.env.REACT_APP_API_URL

export const menuService = {
  createSection,
  deleteSection,
  updateSection,
  getAllSections,
  createMenu,
  deleteMenu,
  updateMenu,
  saveMenuImage,
  deleteMenuImage,
  copyMenu,
}

function createSection(storeId, data) {
  return axios.post(`${API_URL}/store/${storeId}/section`, data, merchantHeader()).then((res) => {
    return res
  })
}
function copyMenu(sourceStoreId, targetStoreId) {
  return axios.post(`${API_URL}/store/${sourceStoreId}/copy/${targetStoreId}`, null, merchantHeader()).then((res) => {
    return res
  })
}

function deleteSection(storeId, sectionId) {
  return axios.delete(`${API_URL}/store/${storeId}/section/${sectionId}`, merchantHeader()).then((res) => {
    return res.status
  })
}

function updateSection(storeId, sectionId, data) {
  return axios.put(`${API_URL}/store/${storeId}/section/${sectionId}`, data, merchantHeader()).then((res) => {
    return res
  })
}

function getAllSections(restaurantId) {
  return axios.get(`${API_URL}/restaurant/${restaurantId}/section`, merchantHeader()).then((sections) => {
    return sections.data
  })
}

function createMenu(storeId, sectionId, data) {
  let options = merchantHeader()
  options.data = data
  return axios
    .post(`${API_URL}/store/${storeId}/section/${sectionId}/menu`, JSON.stringify(data), merchantHeader())
    .then((res) => {
      return res
    })
}

function deleteMenu(storeId, sectionId, menuId) {
  return axios
    .delete(`${API_URL}/store/${storeId}/section/${sectionId}/menu/${menuId}`, merchantHeader())
    .then((res) => {
      return res.status
    })
}

function updateMenu(storeId, sectionId, menuId, menu) {
  return axios
    .put(`${API_URL}/store/${storeId}/section/${sectionId}/menu/${menuId}`, menu, merchantHeader())
    .then((res) => {
      return res
    })
}

function saveMenuImage(storeId, sectionId, menuId, imageFile) {
  var bodyFormData = new FormData()
  bodyFormData.append('file', imageFile)

  let options = merchantImageHeader()
  // options.headers['Authorization'] = 'vgk2SqrZpqfVtkTwdQO1tlXINLN95Q7UxBZb7CFO6Fusg4Bs3XvqhO2C0bF9oael'

  return axios
    .post(`${API_URL}/store/${storeId}/section/${sectionId}/menu/${menuId}/image`, bodyFormData, options)
    .then((res) => {
      return res
    })
}

function deleteMenuImage(storeId, sectionId, menuId, imageId) {
  return axios
    .delete(`${API_URL}/store/${storeId}/section/${sectionId}/menu/${menuId}/image/${imageId}`, merchantHeader())
    .then((res) => {
      return res.status
    })
}
