/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import footerbg from '../../images/footer-bg.svg'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { orderActions } from '../../actions'
import { priceQtyMaker } from '../../helpers/footer/total-price'
import { createRequestBody } from '../../helpers/order-menu/request-body'
import MobileFooter from './footer/MobileFooter'
import MobileDialogs from './footer/MobileDialogs'
import WebFooter from './footer/WebFooter'
import { isMobileOnly } from 'react-device-detect'
import { generalConstants } from '../../constants'
import { useCurrentWidth } from '../../hooks/useCurrentWidth'

const path = process.env.PUBLIC_URL

const footerHeight = 400

const useStyles = makeStyles((theme) => ({
  desktopRoot: {
    width: '100%',
    height: footerHeight,
    paddingTop: 66,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: `url(${footerbg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginTop: theme.spacing(5),
    "&[is-tablet='true']": {
      height: footerHeight * 2.565,
      paddingTop: 21,
      backgroundSize: 'auto 110%',
    },
    "&[is-mediumsize='true']": {
      height: footerHeight * 1.02,
      backgroundSize: 'auto 110%',
    },
    "&[is-xs='true']": {
      backgroundSize: 'auto 110%',
    },
  },
  mobileRoot: {
    // backgroundColor: '#fff',
    position: 'relative',
    borderTop: '#f9f9f9',
  },
  iconButton: {
    marginTop: theme.spacing(),
    marginRight: theme.spacing(2),
  },
}))

const Footer = ({ showLogin, goBackHandler, invisible, history }) => {
  const classes = useStyles()
  let width = useCurrentWidth()

  //Global states
  const cart = useSelector(({ orders }) => orders.cart, shallowEqual)
  const savedOrder = useSelector(({ orders }) => orders.savedOrder, shallowEqual)
  const query = useSelector(({ search }) => search.searchQuery, shallowEqual)
  const customerInfo = useSelector(({ customer }) => customer.customerInfo, shallowEqual)
  //Local states
  // const [email, setEmail] = useState('')
  const [showMobileSigninDialog, setShowMobileSigninDialog] = useState(false)
  const [showMobileCartDialog, setShowMobileCartDialog] = useState(false)
  const [totalPrice, setTotalPrice] = useState(null)
  // const [totalQty, setTotalQty] = useState(0)
  const [mobileDirection, setMobileDirection] = useState()
  const [showAddressDialog, setShowAddressDialog] = useState(false)
  //Actions
  const dispatch = useDispatch()
  const setOrderFees = async (data) => dispatch(orderActions.setOrderFees(data))
  // const setOrderAddress = () => dispatch(orderActions.setOrderAddress())
  const clearOrderFees = () => dispatch(orderActions.clearOrderFees())
  const deleteSavedOrder = (id) => dispatch(orderActions.deleteOrder(id))

  const totalQuantity = useMemo(() => {
    let qty = 0
    cart.orderMenus.forEach((item) => {
      qty += item.quantity
    })
    return qty
  }, [JSON.stringify(cart.orderMenus)])

  useEffect(() => {
    getTotalPrice()
  }, [cart.storeId, totalQuantity])

  useEffect(() => {
    showLogin && setShowMobileSigninDialog(true)
  }, [showLogin])

  const getTotalPrice = () => {
    const { price } = priceQtyMaker(cart.orderMenus)
    setTotalPrice(+price.toFixed(2))
    // setTotalQty(qty)
  }

  const handleSignin = () => {
    setShowMobileSigninDialog(false)
    if (mobileDirection) {
      history.push(`${path}/customer/${mobileDirection}`)
    }
  }
  const handleMobileSigninClose = () => {
    setShowMobileSigninDialog(false)
    goBackHandler && goBackHandler()
  }

  const handleMobileCartClose = () => {
    savedOrder?.id && deleteSavedOrder(savedOrder.id)
    clearOrderFees()
    setShowMobileCartDialog(false)
  }

  const openSigninDialog = (to) => {
    setShowMobileSigninDialog(true)
    setMobileDirection(to)
  }

  const viewCartHandler = async () => {
    const searchedAddress = await JSON.parse(localStorage.getItem(generalConstants.SEARCHED_ADDRESSES))
    searchedAddress ? proceedToCart() : toggleAddressDialog()
  }

  const signinCheck = () => setShowMobileSigninDialog(true)

  const proceedToCart = async () => {
    const requestBody = createRequestBody(query, customerInfo, cart)
    await setOrderFees(requestBody)
    setShowMobileCartDialog(true)
  }

  // const nextButtonWithoutUserHandler = () => setShowMobileSigninDialog(true)

  const toggleAddressDialog = () => setShowAddressDialog((prev) => !prev)
  let isTablet = !isMobileOnly && width < 960
  const isMediumSize = width >= 1280 && width < 2010
  const isXs = width > 960 && width < 1280
  return (
    <div className='full-width'>
      <div className={clsx('mobile', [classes.mobileRoot])}>
        <MobileFooter
          totalPrice={totalPrice}
          invisible={invisible}
          viewCartHandler={viewCartHandler}
          openSignin={openSigninDialog}
        />
        <MobileDialogs
          showMobileSigninDialog={showMobileSigninDialog}
          handleSignin={handleSignin}
          handleSigninModalClose={handleMobileSigninClose}
          showMobileCartDialog={showMobileCartDialog}
          handleMobileCartClose={handleMobileCartClose}
          signinCheck={signinCheck}
          viewCartHandler={viewCartHandler}
          showAddressDialog={showAddressDialog}
          toggleAddressDialog={toggleAddressDialog}
        />
      </div>

      {!isMobileOnly && (
        <div
          className={classes.desktopRoot}
          is-tablet={isTablet.toString()}
          is-mediumsize={isMediumSize.toString()}
          is-xs={isXs.toString()}
        >
          <WebFooter isTablet={isTablet} />
        </div>
      )}
    </div>
  )
}

Footer.propTypes = {
  showLogin: PropTypes.bool,
  goBackHandler: PropTypes.func,
  history: PropTypes.object,
}

export default withRouter(Footer)
