import React from 'react'
import { makeStyles } from '@material-ui/core'
import markerIcon from '../../../../images/marker.svg'

const useStyles = makeStyles((theme) => ({
  marker: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: 24,
    height: 38,
    transform: 'translate(-50%, 0%)',
  },
}))

function MarkerComponent({ lat, lng, data }) {
  const classes = useStyles()

  return (
    <div className={classes.marker}>
      <img src={markerIcon} alt='home' width='24' height='38' />
    </div>
  )
}

MarkerComponent.propTypes = {}

export default MarkerComponent
