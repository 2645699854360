import React from 'react'
import { makeStyles } from '@material-ui/core'
import authRequired from '../../../images/auth-required.svg'

const height = window.innerHeight

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: height,
    maxHeight: height,
    paddingTop: 100,
  },
  text: {
    textAlign: 'center',
    marginTop: 30,
    textDecoration: 'none',
    color: '#69e781',
    fontSize: 25,
    fontWeight: 500,
  },
}))

const AuthRequired = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img src={authRequired} alt='no-response' width='600px' height='416px' />
    </div>
  )
}

export default AuthRequired
