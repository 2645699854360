import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    maxWidth: 336,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    "&[is-small='true']": {
      maxWidth: 160,
    },
    "&[is-firstname='true']": {
      marginRight: theme.spacing(2),
    },
  },
  label: {
    fontSize: 16,
    lineHeight: 1.31,
    color: '#333333',
    marginBottom: theme.spacing(),
    transition: 'font-size 0.3s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  button: {
    border: '1px solid rgba(196, 196, 196, 0.5)',
    height: 48,
    textAlign: 'left',
    textTransform: 'none',
    backgroundColor: '#fff',
    padding: theme.spacing(),
    display: 'flex',
    borderRadius: 5,
    alignItems: 'center',
    cursor: 'pointer',
    "&[is-space='true']": {
      justifyContent: 'space-between',
    },
  },
}))

const Fields = ({ isSmall, label, handleClick, children, isSpace, firstName }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root} is-small={isSmall ? 'true' : 'false'} is-firstname={firstName ? 'true' : 'false'}>
      <Typography className={classes.label}>{label}</Typography>
      <Box is-space={isSpace ? 'true' : 'false'} onClick={() => handleClick()} className={classes.button}>
        {children}
      </Box>
    </Box>
  )
}

Fields.propTypes = {
  isSmall: PropTypes.bool,
  label: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  isSpace: PropTypes.bool,
  firstName: PropTypes.bool,
}

export default Fields
